/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React from 'react';
import {Card} from 'react-bootstrap';
import {Checkbox, TextField, InputAdornment, Button} from '@material-ui/core';
import api from '@app/utils/api';
import moment from 'moment';

import icone from 'src/img/OPTI_logo.avif';
// import cancel from 'src/img/icone-cancelLaranja.avif';
// import check from 'src/img/icone-check.avif';
import './modalstyles.css';

export default function ConfigModal({
    classes,
    setOpen,
    perfilDesativado,
    setPerfilDesativado,
    setPerfiladm
}) {
    const handleChange = (id) => {
        api.admin
            .turnOn(id)
            .then((res) => {
                if (res.status === 200) {
                    api.admin
                        .findPerfil()
                        .then((res) => {
                            setPerfiladm(res.data[0]);
                            setPerfilDesativado(res.data[1]);
                        })
                        .catch((err) => console.log(err));
                }
            })
            .catch((e) => console.log(e));
    };

    return (
        <>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontFamily: 'Roboto'
                }}
            >
                <Card
                    style={{
                        backgroundColor: '#cac6c2',
                        width: '55%',
                        height: '40%',
                        display: 'flex',
                        flexDirection: 'row',
                        borderRadius: '15px',
                        fontSize: 24
                    }}
                >
                    <div style={{padding: '1rem', width: '100%'}}>
                        <div>PERFIS CANCELADOS</div>
                        <div
                            style={{
                                margin: '0.5rem 0rem 0rem 1rem',
                                width: '100%',
                                display: 'flex',
                                // justifyContent: 'space-around',
                                fontSize: 22,
                                borderBottom: '1px solid #d36233',
                                height: '40px'
                            }}
                        >
                            <div className="perfil-id">ID</div>
                            <div className="perfil-nome">NOME</div>
                            <div className="perfil-perfil">PERFIL</div>
                            <div className="perfil-data">DATA CANCELAMENTO</div>
                            <div className="perfil-reativar">REATIVAR</div>
                        </div>
                        <div style={{margin: '0.5rem 0rem 0rem 1rem'}}>
                            {perfilDesativado.map((value) => (
                                <div
                                    style={{
                                        // margin: '0.5rem 0rem 0rem 1rem',
                                        width: '100%',
                                        display: 'flex',
                                        // justifyContent: 'space-around',
                                        fontSize: 18,
                                        borderBottom: '1px solid #313031',
                                        alignItems: 'center'
                                    }}
                                >
                                    <div className="perfil-id">{value.id}</div>
                                    <div className="perfil-nome">
                                        {value.nome}
                                    </div>
                                    <div className="perfil-perfil">
                                        {value.perfil}
                                    </div>
                                    <div className="perfil-data">
                                        {moment(value.data_closed).format(
                                            'DD/MM/YYYY'
                                        )}
                                    </div>
                                    <div className="perfil-reativar">
                                        <Checkbox
                                            style={{color: '#d36233'}}
                                            onChange={() =>
                                                handleChange(value.id)
                                            }
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div style={{margin: '-5px -5px 0px 0px'}}>
                        <ion-icon
                            style={{
                                fontSize: 32,
                                color: '#d36233',
                                backgroundColor: '#cac6c2',
                                borderRadius: '50%',
                                cursor: 'pointer'
                            }}
                            onClick={() => setOpen(false)}
                            name="close-circle-outline"
                        />
                    </div>
                </Card>
            </div>
        </>
    );
}
