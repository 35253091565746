/* eslint-disable no-unused-vars */

import React, {useState, useEffect} from 'react';
import WebFont from 'webfontloader';
import {
    Avatar,
    TextField,
    InputAdornment,
    Modal,
    Button
} from '@material-ui/core';
import routing from '@app/routes/routing';
import {useHistory} from 'react-router-dom';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import {Card} from 'react-bootstrap';

import api from '../utils/api';
import AdminSidebar from '../components/adm-comp/AdminSidebar';
import Resumo from '../components/adm-comp/AdminResumo';
import AdminUsuarios from '../components/adm-comp/AdminUsuarios';
import AdminCancelamento from '../components/adm-comp/AdminCancelamento';
import AdminConfig from '../components/adm-comp/AdminConfig';

export default function Admin() {
    const history = useHistory();
    const [page, setPage] = useState('resumo');
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [usuarios, setUsuarios] = useState();
    const [resumo, setResumo] = useState();
    const [cancelled, setCancelled] = useState();
    const [perfiladm, setPerfiladm] = useState();
    const [perfilDesativado, setPerfilDesativado] = useState();
    const [password, setPassword] = useState('');
    const [auth, setAuth] = useState(false);
    const [erro, setErro] = useState('');

    const useStyles = makeStyles(() =>
        createStyles({
            profilePicture: {
                width: '80px',
                height: '80px',
                fontSize: 30,
                backgroundColor: '#e37039'
            },
            myButton: {
                width: '140px',
                height: '32px',
                color: '#d36233',
                border: '3px solid #d36233',
                borderRadius: '20px',
                fontSize: 14,
                fontWeight: 700,
                marginTop: '15px'
            },
            myButton1920: {
                width: '200px',
                height: '40px',
                color: '#d36233',
                border: '3px solid #d36233',
                borderRadius: '20px',
                fontSize: 18,
                fontWeight: 700,
                marginTop: '30px'
            },
            configButton: {
                width: '180px',
                height: '32px',
                color: '#d36233',
                border: '3px solid #d36233',
                borderRadius: '20px',
                fontSize: 14,
                fontWeight: 700
            },
            configButton1920: {
                width: '240px',
                height: '40px',
                color: '#d36233',
                border: '3px solid #d36233',
                borderRadius: '20px',
                fontSize: 18,
                fontWeight: 700
            },
            buttonModal: {
                // width: '45%',
                height: '50px',
                color: '#d36233',
                border: '3px solid #d36233',
                borderRadius: '25px',
                fontSize: 24,
                fontWeight: 700
            },
            buttonCancel: {
                width: '25%',
                height: '60px',
                color: '#000',
                background: '#d36233',
                borderRadius: '25px',
                fontSize: 30,
                fontWeight: 700
            },
            myTextField: {
                backgroundColor: '#f4f5f7',
                width: '100%',
                height: '45px',
                borderRadius: '20px',
                marginTop: '0.5rem'
            },
            dtNascimento: {
                backgroundColor: '#f4f5f7',
                width: '57%',
                height: '45px',
                borderRadius: '20px',
                marginTop: '0.5rem'
            },
            cpf: {
                backgroundColor: '#f4f5f7',
                width: '40%',
                height: '45px',
                borderRadius: '20px',
                marginTop: '0.5rem'
            },
            pais: {
                backgroundColor: '#f4f5f7',
                width: '35%',
                height: '45px',
                borderRadius: '20px',
                marginTop: '0.5rem'
            },
            citystate: {
                backgroundColor: '#f4f5f7',
                width: '62%',
                height: '45px',
                borderRadius: '20px',
                marginTop: '0.5rem'
            },
            textfieldMedium: {
                backgroundColor: '#f4f5f7',
                width: '55%',
                height: '45px',
                borderRadius: '20px',
                marginTop: '0.5rem'
            },
            textfieldSmall: {
                backgroundColor: '#f4f5f7',
                width: '43%',
                height: '45px',
                borderRadius: '20px',
                marginTop: '0.5rem'
            },
            observation: {
                backgroundColor: '#f4f5f7',
                width: '100%',
                height: '120px',
                borderRadius: '25px',
                marginTop: '0.5rem'
            },
            myInput: {
                disableUnderline: true,
                height: '45px',
                color: '#d36233',
                fontSize: 24,
                '& .MuiTypography-colorTextSecondary': {
                    color: '#d36233',
                    fontSize: 24,
                    margin: '10px'
                }
            },
            inputPassword: {
                disableUnderline: true,
                height: '45px',
                color: '#d36233',
                textAlign: 'center',
                fontSize: 24,
                '& .MuiInputBase-input': {
                    textAlign: 'center'
                },
                '& .MuiTypography-colorTextSecondary': {
                    color: '#d36233',
                    fontSize: 24,
                    margin: '10px'
                }
            }
        })
    );

    const classes = useStyles();

    window.addEventListener('resize', () => {
        setWindowHeight(window.innerHeight);
        setWindowWidth(window.innerWidth);
    });

    const handleKeyUp = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            event.target.blur();
        }
    };

    const handleChange = () => {
        api.admin
            .checkPassword(api.currentUser.user_id, password)
            .then((res) => {
                if (res.status === 200) setAuth(true);
                if (res.response.status === 401) setErro('SENHA INVÁLIDA');
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto']
            }
        });
    }, []);

    useEffect(() => {
        if (auth) {
            api.admin
                .requestResumo()
                .then((res) => setResumo(res.data))
                .catch((e) => console.log(e));
            api.admin
                .requestUsuarios()
                .then((res) => {
                    console.log(res);
                    setUsuarios(res.data[0]);
                    setCancelled(res.data[1]);
                })
                .catch((e) => console.log(e));
            api.admin
                .findPerfil()
                .then((res) => {
                    setPerfiladm(res.data[0]);
                    setPerfilDesativado(res.data[1]);
                })
                .catch((e) => console.log(e));
        }
    }, [auth]);

    return (
        <>
            <AdminSidebar page={page} setPage={setPage} />

            <div
                className="content-wrapper"
                style={{
                    background: '#0e0b0f',
                    height: windowHeight,
                    width: windowWidth,
                    position: 'absolute',
                    fontFamily: 'Roboto'
                }}
            >
                {auth ? (
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: windowWidth > 1600 ? '90%' : '',
                                marginTop: '1rem',
                                marginLeft: '5rem',
                                marginRight: '8rem'
                            }}
                        >
                            <div style={{color: '#d36233', fontSize: '36px'}}>
                                Dashboard
                            </div>
                            <div>
                                <TextField
                                    InputProps={{
                                        disableUnderline: true,
                                        className: classes.myInput,
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <ion-icon name="search-outline" />
                                            </InputAdornment>
                                        )
                                    }}
                                    // defaultValue={profile?.birth}
                                    placeholder="Pesquise aqui"
                                    variant="standard"
                                    className={classes.myTextField}
                                    type="text"
                                    // onBlur={(event) => handleChange(event, 'birth')}
                                    // onKeyUp={handleKeyUp}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        color: '#d36233',
                                        textAlign: 'right',
                                        marginRight: '5px'
                                    }}
                                >
                                    <div>Nome do Admin</div>
                                    <div>Administrador</div>
                                </div>
                                <Avatar className={classes.profilePicture}>
                                    JF
                                </Avatar>
                            </div>
                        </div>
                        {page === 'resumo' ? (
                            <div>
                                <Resumo
                                    size={windowWidth}
                                    classes={classes}
                                    data={resumo}
                                />
                            </div>
                        ) : null}
                        {page === 'usuarios' ? (
                            <div
                                style={{
                                    marginLeft:
                                        windowWidth > 1600 ? '100px' : '50px',
                                    marginTop: '20px'
                                }}
                            >
                                <AdminUsuarios
                                    size={windowWidth}
                                    classes={classes}
                                    // setOpen={setOpenAltera}
                                    usuarios={usuarios}
                                />
                            </div>
                        ) : null}
                        {page === 'cancelamentos' ? (
                            <div
                                style={{
                                    marginLeft:
                                        windowWidth > 1600 ? '100px' : '50px',
                                    marginTop: '20px'
                                }}
                            >
                                <AdminCancelamento
                                    size={windowWidth}
                                    classes={classes}
                                    // setOpen={setOpenCad}
                                    cancelled={cancelled}
                                />
                            </div>
                        ) : null}
                        {page === 'config' ? (
                            <div>
                                <AdminConfig
                                    size={windowWidth}
                                    classes={classes}
                                    perfiladm={perfiladm}
                                    setPerfiladm={setPerfiladm}
                                    perfilDesativado={perfilDesativado}
                                    setPerfilDesativado={setPerfilDesativado}
                                    // setCreate={setCreate}
                                    // setChange={setChange}
                                    // setCancelados={setCancelados}
                                />
                            </div>
                        ) : null}
                    </div>
                ) : (
                    <Modal open={!auth} style={{zIndex: 9999}}>
                        <>
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    fontFamily: 'Roboto'
                                }}
                            >
                                <Card
                                    style={{
                                        backgroundColor: '#cac6c2',
                                        color: '#313031',
                                        width: '30%',
                                        height: '30%',
                                        display: 'flex',
                                        // flexDirection: 'row',
                                        borderRadius: '15px',
                                        fontSize: 26
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            flexDirection: 'column',
                                            marginTop: '5rem'
                                        }}
                                    >
                                        <h1>AREA RESTRITA!</h1>
                                        <div
                                        /* style={{
                                        display: 'flex',
                                        width: 400,
                                        alignItems: 'center',
                                        marginLeft: 80
                                    }} */
                                        >
                                            <TextField
                                                InputProps={{
                                                    disableUnderline: true,
                                                    className:
                                                        classes.inputPassword
                                                }}
                                                variant="standard"
                                                className={classes.myTextField}
                                                value={password}
                                                onChange={(event) =>
                                                    setPassword(
                                                        event.target.value
                                                    )
                                                }
                                                onBlur={handleChange}
                                                onKeyUp={handleKeyUp}
                                            />

                                            {/* <Button
                                        style={{
                                            marginTop: '0.5rem',
                                            marginLeft: '10px',
                                            borderRadius: '55%'
                                        }}
                                    >
                                        <ion-icon
                                            name="arrow-forward-outline"
                                            style={{
                                                color: '#d36233',
                                                fontSize: 30
                                            }}
                                        />
                                    </Button> */}
                                        </div>
                                        <div
                                            style={{
                                                fontSize: 20,
                                                color: '#e37039'
                                            }}
                                        >
                                            {erro}
                                        </div>
                                    </div>
                                </Card>
                                <div
                                    style={{
                                        position: 'relative',
                                        top: '-14.5%',
                                        left: '-1.5%'
                                    }}
                                >
                                    <ion-icon
                                        style={{
                                            fontSize: 32,
                                            color: '#d36233',
                                            backgroundColor: '#cac6c2',
                                            borderRadius: '50%',
                                            cursor: 'pointer'
                                        }}
                                        onClick={() => {
                                            history.push(routing.home);
                                        }}
                                        name="close-circle-outline"
                                    />
                                </div>
                            </div>
                        </>
                    </Modal>
                )}
            </div>
        </>
    );
}
