/* eslint-disable no-unused-vars */

import React from 'react';
import {Card} from 'react-bootstrap';
import {Modal, Grid, Button} from '@material-ui/core';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import logo from 'src/img/OPTI_logo.avif';

const useStyles = makeStyles((theme) =>
    createStyles({
        myGrid: {
            width: 340,
            height: 60,
            margin: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            textAlign: 'center',
            marginLeft: '-5px',

            cursor: 'pointer',
            fontSize: 22,
            borderRadius: '20px',
            border: '2px solid #e37039',
            color: '#e37039',
            '&:hover': {
                backgroundColor: '#fff',
                color: '#e37039'
            }
        },
        myGridSelected: {
            width: 340,
            height: 60,
            backgroundColor: '#e37039',
            margin: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            textAlign: 'center',
            cursor: 'pointer',
            marginLeft: '-5px',
            fontSize: 22,
            border: '2px solid #e37039',
            borderRadius: '20px',
            color: '#fff',
            marginTop: '10px',
            '&:hover': {
                backgroundColor: '#fff',
                color: '#e37039'
            }
        }
    })
);

export default function PaymentStatus({
    setOpenCheck,
    approved,
    plano,
    errorlog,
    setFinish,
    setApproved
}) {
    const classes = useStyles();
    const handleClose = () => {
        setApproved(false);
        setFinish(false);
        setOpenCheck(false);
    };
    return (
        <>
            {approved ? (
                <Modal
                    disableEnforceFocus
                    open={approved}
                    PaperProps={{
                        style: {
                            backgroundColor: 'rgba(211, 211, 211, 0.4)',
                            boxShadow: 'none'
                        }
                    }}
                    className={classes.modal}
                    style={{
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        outline: 0,
                        backdropFilter: 'blur(5px)'
                    }}
                >
                    <Card
                        style={{
                            borderRadius: '20px',
                            background: '#cac6c0',
                            width: '450px',
                            height: '520px',
                            FontFamily: 'Roboto'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                // alignItems: 'center',
                                marginLeft: '-250px'
                            }}
                        >
                            <div style={{marginTop: '15rem'}}>
                                <div
                                    style={{
                                        transform: 'rotate(270deg)',
                                        textAlign: 'center',
                                        width: '530px',
                                        border: '0.2px solid #313031',
                                        borderRadius: '30px',
                                        fontSize: 26,
                                        fontWeight: 700,
                                        background: '#313031',
                                        color: '#cac6c0'
                                    }}
                                >
                                    PAGAMENTO EFETUADO
                                </div>
                            </div>
                            <div
                                style={{
                                    textAlign: 'start',
                                    marginLeft: '-200px'
                                }}
                            >
                                <div
                                    style={{
                                        marginTop: '15px',
                                        marginBottom: '15px',
                                        marginLeft: '-10px',
                                        textAlign: 'center'
                                    }}
                                >
                                    <img
                                        src={logo}
                                        alt="Logo"
                                        style={{width: '60px'}}
                                    />
                                </div>

                                <div
                                    style={{
                                        fontSize: '36px',
                                        fontWeight: 700,
                                        color: '#e37039',
                                        fontFamily: 'Roboto'
                                    }}
                                >
                                    PAGAMENTO EFETUADO COM SUCESSO!
                                </div>
                                <div
                                    style={{
                                        fontSize: '20px',
                                        color: '#000',
                                        marginTop: '10px',
                                        fontWeight: 'bold',
                                        fontFamily: 'Roboto'
                                    }}
                                >
                                    {plano === 'mensal' ? (
                                        <p>
                                            PLANO ATUAL
                                            <br />
                                            sardinha mensal - R$ 9,90
                                        </p>
                                    ) : (
                                        <p>
                                            PLANO ATUAL
                                            <br />
                                            sardinha anual - R$ 69,90
                                        </p>
                                    )}
                                </div>
                                <div
                                    style={{
                                        borderBottom: '3px solid #e37039',
                                        width: '90%',
                                        marginLeft: '10px',
                                        marginTop: '10px',
                                        marginBottom: '10px'
                                    }}
                                />
                                <div
                                    style={{
                                        fontSize: '24px',
                                        color: '#74737c',
                                        fontWeight: 'bold',
                                        fontFamily: 'Roboto'
                                    }}
                                >
                                    *Sua Conta foi Criada na opti, enviamos uma
                                    senha temporária para alterar mais tarde nas
                                    configurações.
                                    <br />
                                    *Para sua comodidade, enviamos a Nota Fiscal
                                    eletrônica para o email cadastrado no
                                    pagamento.
                                </div>
                            </div>
                            <div
                                style={{
                                    fontSize: 36,
                                    marginRight: '-16px',
                                    marginTop: '-10px'
                                }}
                            >
                                <ion-icon
                                    style={{
                                        cursor: 'pointer',
                                        color: '#e37039',
                                        background: '#cac6c0',
                                        border: '0.2px solid #cac6c0',
                                        borderRadius: '20px'
                                    }}
                                    onClick={handleClose}
                                    name="close-circle-outline"
                                />
                            </div>
                        </div>
                    </Card>
                </Modal>
            ) : (
                <Card
                    style={{
                        borderRadius: '20px',
                        background: '#cac6c0',
                        width: '450px',
                        height: '500px',
                        FontFamily: 'Roboto'
                    }}
                >
                    <div style={{display: 'flex'}}>
                        <div
                            style={{
                                textAlign: 'center',
                                justifyContent: 'center',
                                display: 'flex',
                                alignItems: 'center'
                                // marginTop: '60px'
                            }}
                        >
                            <div
                                style={{
                                    transform: 'rotate(270deg)',
                                    textAlign: 'center',
                                    border: '0.2px solid #313031',
                                    borderRadius: '30px',
                                    // width: '400px',
                                    // height: '2.3rem',
                                    marginTop: '20px',
                                    marginLeft: '-250px',
                                    paddingRight: '226px',
                                    paddingLeft: '226px',
                                    fontSize: 26,
                                    fontWeight: 700,
                                    background: '#313031',
                                    color: '#cac6c0'
                                    // letterSpacing: '5px'
                                }}
                            >
                                ERRO
                            </div>
                        </div>
                        <div
                            style={{
                                marginLeft: '-190px',
                                marginTop: '50px',
                                textAlign: 'start'
                            }}
                        >
                            <div
                                style={{
                                    textAlign: 'center',
                                    marginLeft: '-40px'
                                }}
                            >
                                <img
                                    src="./img/NaoAprovado.png"
                                    alt="Não Aprovado"
                                    style={{width: '150px'}}
                                />
                            </div>
                            <div
                                style={{
                                    fontSize: '36px',
                                    fontWeight: 700,
                                    color: '#e37039',
                                    textAlign: 'center',
                                    marginLeft: '-40px',
                                    fontFamily: 'Roboto'
                                }}
                            >
                                PAGAMENTO NÃO EFETUADO!
                            </div>
                            <div
                                style={{
                                    fontSize: '20px',
                                    color: '#74737c',
                                    textAlign: 'justify',
                                    width: '300px',
                                    marginLeft: '20px',
                                    fontWeight: 'bold',
                                    height: '90px',
                                    fontFamily: 'Roboto'
                                }}
                            >
                                * {errorlog}
                            </div>
                            {/* <Grid
                                className={classes.myGridSelected}
                                onClick={() => setFinish(true)}
                            >
                                TENTAR NOVAMENTE
                            </Grid> */}
                            <Button
                                onClick={() => setFinish(false)}
                                className={classes.myGridSelected}
                            >
                                TENTAR NOVAMENTE
                            </Button>
                            <Button
                                className={classes.myGrid}
                                onClick={() => {
                                    window.location.href =
                                        'mailto:contato@useopti.com.br';
                                }}
                            >
                                FALE CONOSCO
                            </Button>
                        </div>
                        <div
                            style={{
                                fontSize: 36,
                                marginLeft: '-18px',
                                marginRight: '-18px',
                                marginTop: '-15px'
                            }}
                        >
                            <ion-icon
                                style={{
                                    cursor: 'pointer',
                                    color: '#e37039',
                                    background: '#cac6c0',
                                    border: '0.2px solid #cac6c0',
                                    borderRadius: '20px'
                                }}
                                onClick={handleClose}
                                name="close-circle-outline"
                            />
                        </div>
                    </div>
                </Card>
            )}
        </>
    );
}
