// import api from '@app/utils/api';
// import storage from '@app/utils/storage';
import React from 'react';
import {NavLink, Link} from 'react-router-dom';
// import AlertSave from '@app/components/AlertSave';
// import {useTranslation} from 'react-i18next';
import logo from 'src/img/OPTI_logo.avif';
import routing from '../../../routes/routing';
// import app from '../../../firebase/base';

import './myStyle.css';

export default function MenuSidebar() {
    // const [t] = useTranslation();
    // const history = useHistory();

    /* const handleExit = () => {
        storage.auth.token = null;
        history.push(routing.home);
        window.location.reload();
        // history.push(routing.home);
        // window.rounting.home.reload();
    }; */

    return (
        <aside
            className="main-sidebar sidebar-dark-primary elevation-4"
            style={{position: window.location.pathname === '/' && 'fixed'}}
        >
            <Link to={routing.home} className="brand-link">
                <img
                    src={logo}
                    alt=""
                    style={{
                        width: 40,
                        height: 40,
                        marginBottom: -10,
                        marginTop: -10,
                        marginLeft: 10
                    }}
                />
                <span className="brand-text font-weight-light" />
            </Link>
            <div className="sidebar">
                <nav
                    className="mt-2"
                    style={{overflowY: 'hidden', overflowX: 'hidden'}}
                >
                    <ul
                        className="nav nav-pills nav-sidebar flex-column"
                        data-widget="treeview"
                        role="menu"
                        data-accordion="false"
                    >
                        <li className="nav-item">
                            <NavLink
                                to={routing.mercado}
                                exact
                                className="nav-link"
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    <ion-icon
                                        style={{marginRight: 10}}
                                        name="analytics-outline"
                                        size="large"
                                    />
                                    <p>Mercado</p>
                                </div>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to={routing.dashboard}
                                exact
                                className="nav-link"
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    <ion-icon
                                        style={{marginRight: 10}}
                                        name="grid-outline"
                                        size="large"
                                    />
                                    <p>Grade de Opções</p>
                                </div>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to={routing.gradeepm}
                                exact
                                className="nav-link"
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    <ion-icon
                                        style={{marginRight: 10}}
                                        size="large"
                                        name="list-outline"
                                    />

                                    <p>Grade EPM</p>
                                </div>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to={routing.charts}
                                exact
                                className="nav-link"
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    <ion-icon
                                        style={{marginRight: 10}}
                                        name="bar-chart-outline"
                                        size="large"
                                    />
                                    <p>Mapa de Opções</p>
                                </div>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to={routing.money}
                                exact
                                className="nav-link"
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    <ion-icon
                                        style={{marginRight: 10}}
                                        name="wallet-outline"
                                        size="large"
                                    />
                                    <p>Carteira</p>
                                </div>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to={routing.roex}
                                exact
                                className="nav-link"
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    <ion-icon
                                        style={{marginRight: 10}}
                                        name="sync-circle-outline"
                                        size="large"
                                    />
                                    <p>Roex</p>
                                </div>
                            </NavLink>
                        </li>

                        {/*  <li className="nav-item">
                            {api.currentSession ? (
                                <NavLink
                                    to={routing.home}
                                    exact
                                    className="nav-link"
                                    onClick={handleExit}
                                    // {...window.location.reload(true)}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <ion-icon
                                            style={{marginRight: 10}}
                                            name="exit-outline"
                                            size="large"
                                        />
                                        <p>Sair</p>
                                    </div>
                                </NavLink>
                            ) : (
                                <NavLink
                                    to={routing.login}
                                    exact
                                    className="nav-link"
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <ion-icon
                                            style={{marginRight: 10}}
                                            name="log-in-outline"
                                            size="large"
                                        />
                                        <p>Login</p>
                                    </div>
                                </NavLink>
                            )}
                        </li> */}
                        {/* <li className="nav-item">
                            <NavLink
                                to={routing.learning}
                                exact
                                className="nav-link"
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    <ion-icon
                                        style={{
                                            marginRight: 10
                                        }}
                                        size="large"
                                        name="school-outline"
                                    />
                                    <p>Wiki Caju</p>
                                </div>
                            </NavLink>
                        </li> */}
                        {/* <li className="nav-item">
                            <NavLink to="/profile" exact className="nav-link">
                                <i className="nav-icon fas fa-user" />
                                <p>Profile</p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                // to={() => console.log('SAIR')}
                                onClick={() => app.auth().signOut()}
                                exact
                                to="/login"
                                className="nav-link"
                            >
                                <i className="nav-icon  fas fa-door-open" />
                                <p>Sair</p>
                            </NavLink>
                        </li> */}
                    </ul>
                </nav>
            </div>
        </aside>
    );
}

// export default MenuSidebar;
