import React from 'react';
import {Grid} from '@material-ui/core';

import {makeStyles, createStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
    createStyles({
        myGrid: {
            display: 'flow-root',
            minWidth: 110,
            height: 60,
            maxWidth: 110,
            // color: '#d36233',
            // backgroundColor: '#f4f4f4',
            // margin: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            cursor: 'pointer',
            fontSize: 14,
            borderRight: '2px solid #c5c5c5'
        },
        myGridEnd: {
            minWidth: 110,
            height: 60,
            maxWidth: 110,
            // color: '#d36233',
            // backgroundColor: '#f4f4f4',
            // margin: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            cursor: 'pointer',
            fontSize: 14
        },
        myGridSelected: {
            display: 'flow-root',
            minWidth: 110,
            height: 60,
            maxWidth: 110,
            color: '#d36233',
            // backgroundColor: '#f4f4f4',
            // margin: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            cursor: 'pointer',
            fontSize: 14,
            borderRight: '2px solid #c5c5c5'
        },
        myGridEndSelected: {
            minWidth: 110,
            height: 60,
            maxWidth: 110,
            color: '#d36233',
            // backgroundColor: '#f4f4f4',
            // margin: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            cursor: 'pointer',
            fontSize: 14
        }
    })
);

// eslint-disable-next-line no-unused-vars
export default function SettingsTable({tableLabel, setTableLabel}) {
    const classes = useStyles();
    return (
        <>
            <Grid
                container
                spacing={1}
                style={{
                    justifyContent: 'center',
                    marginBottom: 10
                }}
            >
                {/* <Grid
                    className={
                        tableLabel.ticker.selected
                            ? classes.myGridSelected
                            : classes.myGrid
                    }
                    onClick={() =>
                        setTableLabel((prev) => ({
                            ...prev,
                            ticker: {
                                value: 'TICKER',
                                selected: !tableLabel.ticker.selected
                            }
                        }))
                    }
                >
                    {tableLabel.ticker.selected ? (
                        <ion-icon size="large" name="eye-outline" />
                    ) : (
                        <ion-icon size="large" name="eye-off-outline" />
                    )}
                    <div>TICKER</div>
                </Grid> 
                <Grid
                    className={
                        tableLabel.fm.selected
                            ? classes.myGridSelected
                            : classes.myGrid
                    }
                    onClick={() =>
                        setTableLabel((prev) => ({
                            ...prev,
                            fm: {
                                value: 'FM',
                                selected: !tableLabel.fm.selected
                            }
                        }))
                    }
                >
                    {tableLabel.fm.selected ? (
                        <ion-icon size="large" name="eye-outline" />
                    ) : (
                        <ion-icon size="large" name="eye-off-outline" />
                    )}
                    <div>FM</div>
                </Grid> */}
                <Grid
                    className={
                        tableLabel.tipo.selected
                            ? classes.myGridSelected
                            : classes.myGrid
                    }
                    onClick={() =>
                        setTableLabel((prev) => ({
                            ...prev,
                            tipo: {
                                value: 'TIPO',
                                selected: !tableLabel.tipo.selected
                            }
                        }))
                    }
                >
                    {tableLabel.tipo.selected ? (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-outline"
                            />
                        </span>
                    ) : (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-off-outline"
                            />
                        </span>
                    )}
                    <div>TIPO</div>
                </Grid>
                <Grid
                    className={
                        tableLabel.mod.selected
                            ? classes.myGridSelected
                            : classes.myGrid
                    }
                    onClick={() =>
                        setTableLabel((prev) => ({
                            ...prev,
                            mod: {
                                value: 'MOD.',
                                selected: !tableLabel.mod.selected
                            }
                        }))
                    }
                >
                    {tableLabel.mod.selected ? (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-outline"
                            />
                        </span>
                    ) : (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-off-outline"
                            />
                        </span>
                    )}
                    <div>MOD</div>
                </Grid>
                <Grid
                    className={
                        tableLabel.money.selected
                            ? classes.myGridSelected
                            : classes.myGrid
                    }
                    onClick={() =>
                        setTableLabel((prev) => ({
                            ...prev,
                            money: {
                                value: 'A/I/OTM',
                                selected: !tableLabel.money.selected
                            }
                        }))
                    }
                >
                    {tableLabel.money.selected ? (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-outline"
                            />
                        </span>
                    ) : (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-off-outline"
                            />
                        </span>
                    )}
                    <div>A/I/OTM</div>
                </Grid>
                <Grid
                    className={
                        tableLabel.strike.selected
                            ? classes.myGridSelected
                            : classes.myGrid
                    }
                    onClick={() =>
                        setTableLabel((prev) => ({
                            ...prev,
                            strike: {
                                value: 'STRIKE',
                                selected: !tableLabel.strike.selected
                            }
                        }))
                    }
                >
                    {tableLabel.strike.selected ? (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-outline"
                            />
                        </span>
                    ) : (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-off-outline"
                            />
                        </span>
                    )}
                    <div>STRIKE</div>
                </Grid>
                <Grid
                    className={
                        tableLabel.intrinseco.selected
                            ? classes.myGridSelected
                            : classes.myGrid
                    }
                    onClick={() =>
                        setTableLabel((prev) => ({
                            ...prev,
                            intrinseco: {
                                value: 'VI',
                                selected: !tableLabel.intrinseco.selected
                            }
                        }))
                    }
                >
                    {tableLabel.intrinseco.selected ? (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-outline"
                            />
                        </span>
                    ) : (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-off-outline"
                            />
                        </span>
                    )}
                    <div>VI</div>
                </Grid>
                <Grid
                    className={
                        tableLabel.extriseco.selected
                            ? classes.myGridSelected
                            : classes.myGrid
                    }
                    onClick={() =>
                        setTableLabel((prev) => ({
                            ...prev,
                            extriseco: {
                                value: 'VE',
                                selected: !tableLabel.extriseco.selected
                            }
                        }))
                    }
                >
                    {tableLabel.extriseco.selected ? (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-outline"
                            />
                        </span>
                    ) : (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-off-outline"
                            />
                        </span>
                    )}
                    <div>VE</div>
                </Grid>
                <Grid
                    className={
                        tableLabel.ultimo.selected
                            ? classes.myGridSelected
                            : classes.myGrid
                    }
                    onClick={() =>
                        setTableLabel((prev) => ({
                            ...prev,
                            ultimo: {
                                value: 'ÚLTIMO',
                                selected: !tableLabel.ultimo.selected
                            }
                        }))
                    }
                >
                    {tableLabel.ultimo.selected ? (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-outline"
                            />
                        </span>
                    ) : (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-off-outline"
                            />
                        </span>
                    )}
                    <div>ÚLTIMO</div>
                </Grid>
                <Grid
                    className={
                        tableLabel.negocio.selected
                            ? classes.myGridEndSelected
                            : classes.myGridEnd
                    }
                    onClick={() =>
                        setTableLabel((prev) => ({
                            ...prev,
                            negocio: {
                                value: 'NÚMERO DE NEGÓCIOS',
                                selected: !tableLabel.negocio.selected
                            }
                        }))
                    }
                >
                    {tableLabel.negocio.selected ? (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-outline"
                            />
                        </span>
                    ) : (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-off-outline"
                            />
                        </span>
                    )}
                    <div>NEGÓCIOS</div>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={1}
                style={{
                    justifyContent: 'center'
                }}
            >
                {/* <Grid
                    className={
                        tableLabel.dist.selected
                            ? classes.myGridSelected
                            : classes.myGrid
                    }
                    onClick={() =>
                        setTableLabel((prev) => ({
                            ...prev,
                            dist: {
                                value: 'DISTÂNCIA % DO STRIKE',
                                selected: !tableLabel.dist.selected
                            }
                        }))
                    }
                >
                    {tableLabel.dist.selected ? (
                        <ion-icon size="large" name="eye-outline" />
                    ) : (
                        <ion-icon size="large" name="eye-off-outline" />
                    )}
                    <div>DISTÂNCIA % DO STRIKE</div>
                </Grid> */}
                {/* <Grid
                    className={
                        tableLabel.ultimo.selected
                            ? classes.myGridSelected
                            : classes.myGrid
                    }
                    onClick={() =>
                        setTableLabel((prev) => ({
                            ...prev,
                            ultimo: {
                                value: 'ÚLTIMO',
                                selected: !tableLabel.ultimo.selected
                            }
                        }))
                    }
                >
                    {tableLabel.ultimo.selected ? (
                        <ion-icon size="large" name="eye-outline" />
                    ) : (
                        <ion-icon size="large" name="eye-off-outline" />
                    )}
                    <div>ÚLTIMO</div>
                </Grid> */}
                <Grid
                    className={
                        tableLabel.vol.selected
                            ? classes.myGridSelected
                            : classes.myGrid
                    }
                    onClick={() =>
                        setTableLabel((prev) => ({
                            ...prev,
                            vol: {
                                value: 'VOLUME',
                                selected: !tableLabel.vol.selected
                            }
                        }))
                    }
                >
                    {tableLabel.vol.selected ? (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-outline"
                            />
                        </span>
                    ) : (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-off-outline"
                            />
                        </span>
                    )}
                    <div>VOLUME</div>
                </Grid>
                <Grid
                    className={
                        tableLabel.data.selected
                            ? classes.myGridSelected
                            : classes.myGrid
                    }
                    onClick={() =>
                        setTableLabel((prev) => ({
                            ...prev,
                            data: {
                                value: 'DATA',
                                selected: !tableLabel.data.selected
                            }
                        }))
                    }
                >
                    {tableLabel.data.selected ? (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-outline"
                            />
                        </span>
                    ) : (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-off-outline"
                            />
                        </span>
                    )}
                    <div>DATA</div>
                </Grid>
                <Grid
                    className={
                        tableLabel.implicita.selected
                            ? classes.myGridSelected
                            : classes.myGrid
                    }
                    onClick={() =>
                        setTableLabel((prev) => ({
                            ...prev,
                            implicita: {
                                value: 'VOL. IMPLÍCITA',
                                selected: !tableLabel.implicita.selected
                            }
                        }))
                    }
                >
                    {tableLabel.implicita.selected ? (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-outline"
                            />
                        </span>
                    ) : (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-off-outline"
                            />
                        </span>
                    )}
                    <div>VOL. IMP</div>
                </Grid>
                <Grid
                    className={
                        tableLabel.delta.selected
                            ? classes.myGridSelected
                            : classes.myGrid
                    }
                    onClick={() =>
                        setTableLabel((prev) => ({
                            ...prev,
                            delta: {
                                value: 'DELTA',
                                selected: !tableLabel.delta.selected
                            }
                        }))
                    }
                >
                    {tableLabel.delta.selected ? (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-outline"
                            />
                        </span>
                    ) : (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-off-outline"
                            />
                        </span>
                    )}
                    <div>DELTA</div>
                </Grid>
                <Grid
                    className={
                        tableLabel.gamma.selected
                            ? classes.myGridSelected
                            : classes.myGrid
                    }
                    onClick={() =>
                        setTableLabel((prev) => ({
                            ...prev,
                            gamma: {
                                value: 'GAMMA',
                                selected: !tableLabel.gamma.selected
                            }
                        }))
                    }
                >
                    {tableLabel.gamma.selected ? (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-outline"
                            />
                        </span>
                    ) : (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-off-outline"
                            />
                        </span>
                    )}
                    <div>GAMMA</div>
                </Grid>
                <Grid
                    className={
                        tableLabel.theta.selected
                            ? classes.myGridSelected
                            : classes.myGrid
                    }
                    onClick={() =>
                        setTableLabel((prev) => ({
                            ...prev,
                            theta: {
                                value: 'THETA',
                                selected: !tableLabel.theta.selected
                            }
                        }))
                    }
                >
                    {tableLabel.theta.selected ? (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-outline"
                            />
                        </span>
                    ) : (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-off-outline"
                            />
                        </span>
                    )}
                    <div>THETA</div>
                </Grid>
                <Grid
                    className={
                        tableLabel.vega.selected
                            ? classes.myGridSelected
                            : classes.myGrid
                    }
                    onClick={() =>
                        setTableLabel((prev) => ({
                            ...prev,
                            vega: {
                                value: 'VEGA',
                                selected: !tableLabel.vega.selected
                            }
                        }))
                    }
                >
                    {tableLabel.vega.selected ? (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-outline"
                            />
                        </span>
                    ) : (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-off-outline"
                            />
                        </span>
                    )}
                    <div>VEGA</div>
                </Grid>
                <Grid
                    className={
                        tableLabel.pteorico.selected
                            ? classes.myGridEndSelected
                            : classes.myGridEnd
                    }
                    onClick={() =>
                        setTableLabel((prev) => ({
                            ...prev,
                            pteorico: {
                                value: 'P.TEÓRICO',
                                selected: !tableLabel.pteorico.selected
                            }
                        }))
                    }
                >
                    {tableLabel.pteorico.selected ? (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-outline"
                            />
                        </span>
                    ) : (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-off-outline"
                            />
                        </span>
                    )}
                    <div>P.TEÓRICO</div>
                </Grid>
            </Grid>
        </>
    );
}
