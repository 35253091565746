/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import {formatMoney, cleanNumber} from '../../../utils/myaux';

const useStyles = makeStyles(() => ({
    top: {
        display: 'flex',
        fontSize: '12px',
        justifyContent: 'space-between',
        borderTop: '1px solid #c5c5c5',
        padding: 0
    },
    top1: {
        display: 'flex',
        fontSize: '12px',
        justifyContent: 'space-between',
        padding: 0
    },
    item: {
        // width: '100%',
        // float: 'left',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
        // '&, &:after': {
        //     content:
        //         '".................................................................................................................................................................................................................................................................................................................................................................................................................................."',
        //     whiteSpace: 'nowrap'
        // }
    },
    text: {
        margin: '0px 0px 0px 0px'
    },
    footer: {
        color: '#d36233',
        textAlign: 'center',
        marginTop: 15
    }
}));

export default function Extrato({strategy}) {
    const classes = useStyles();

    const [captialAloc, setCapitalAloc] = useState(0);
    const [lucroBruto, setLucroBruto] = useState(0);

    useEffect(() => {
        let totMont = 0;
        let totDesm = 0;
        strategy.forEach((s) => {
            totMont += s.total ? cleanNumber(s.total) : 0;
            totDesm += s.totalDesmont ? cleanNumber(s.totalDesmont) : 0;
        });
        if (totMont < 0 && totDesm < 0) {
            //  lucro
            totMont *= -1;
            setLucroBruto(totMont - totDesm);
            // credito e debito
        } else if (totMont < 0 && totDesm > 0) {
            totMont *= -1;
            if (totMont > totDesm) {
                // -> Lucro
                setLucroBruto(totMont - totDesm);
            } else {
                // -> Prejuizo
                setLucroBruto(totMont - totDesm);
            }
        } else if (totMont > 0 && totDesm > 0) {
            // Prejuizo
            totMont *= -1;
            totDesm *= -1;
            setLucroBruto(totMont + totDesm);
        } else if (totMont > 0 && totDesm < 0) {
            totDesm *= -1;
            if (totMont < totDesm) {
                // -> lucro
                setLucroBruto(totDesm - totMont);
            } else {
                // -> prejuizo
                setLucroBruto(totDesm - totMont);
            }
        }
        setCapitalAloc(totMont);
    }, [strategy]);
    // console.log('CAPITAL ALOCADO', totMont);
    // setCapitalAloc(totMont);
    /* if (totMont < 0) {
            totMont *= -1;
            if (totDesm < 0) {
                totDesm *= -1;
            }
            setLucroBruto(totMont - totDesm);
        } else {
            if (totDesm < 0) {
                totDesm *= -1;
            }
            setLucroBruto(totDesm - totMont);
        }
        setCapitalAloc(totMont); */

    // credito e credito

    return (
        <div style={{fontFamily: 'Roboto'}}>
            <ReactTooltip />
            <div className={classes.top}>
                <div className={classes.item}>
                    <p className={classes.text}>CAPITAL ALOCADO</p>
                    <span
                        style={{cursor: 'pointer'}}
                        data-tip="Capital alocado na estrutura registrada."
                    >
                        <ion-icon
                            style={{
                                marginBottom: 2,
                                fontSize: 12,
                                color: '#d36233',
                                pointerEvents: 'none'
                            }}
                            name="help-circle-outline"
                        />
                    </span>
                </div>
                <p style={{margin: '0px 0px 0px 0px', color: '#d36233'}}>
                    {new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                    })
                        .format(captialAloc)
                        .replace(',', '.')
                        .replace('-', '')}
                </p>
            </div>
            <div className={classes.top1}>
                <div className={classes.item}>
                    <p className={classes.text}>
                        {Number(lucroBruto) >= 0 ? 'LUCRO BRUTO' : 'PREJUÍZO'}
                    </p>
                    <span
                        style={{cursor: 'pointer'}}
                        data-tip="Lucro sem considerar os impostos."
                    >
                        <ion-icon
                            style={{
                                marginBottom: 2,
                                fontSize: 12,
                                color: '#d36233',
                                pointerEvents: 'none'
                            }}
                            name="help-circle-outline"
                        />
                    </span>
                </div>
                <p style={{margin: '0px 0px 0px 0px', color: '#d36233'}}>
                    {new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                    })
                        .format(Number(lucroBruto))
                        .replace(',', '.')
                        .replace('-', '')}
                </p>
            </div>
            <div className={classes.top1}>
                <div className={classes.item}>
                    <p className={classes.text}>IMPOSTO EST.</p>
                    <span
                        style={{cursor: 'pointer'}}
                        data-tip="Impostos estimados para a estrutura registrada. Consideramos 15% sobre o lucro obtido. Para calculo exato e emissão de documentos fiscais, indicamos que procure um contador especializado."
                    >
                        <ion-icon
                            style={{
                                marginBottom: 2,
                                fontSize: 12,
                                color: '#d36233',
                                pointerEvents: 'none'
                            }}
                            name="help-circle-outline"
                        />
                    </span>
                </div>
                <p style={{margin: '0px 0px 0px 0px', color: '#d36233'}}>
                    {lucroBruto >= 0
                        ? new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                          })
                              .format(Number(lucroBruto) * 0.15)
                              .replace(',', '.')
                        : new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                          })
                              .format(0)
                              .replace(',', '.')}
                </p>
            </div>
            <div className={classes.top1}>
                <div className={classes.item}>
                    <p className={classes.text}>ROI</p>
                    <span
                        style={{cursor: 'pointer'}}
                        data-tip={`${'"Return over investment" - Retorno sobre o investimento, ou quanto de lucro você obteve com o capital alocado.'}`}
                    >
                        <ion-icon
                            style={{
                                marginBottom: 2,
                                fontSize: 12,
                                color: '#d36233',
                                pointerEvents: 'none'
                            }}
                            name="help-circle-outline"
                        />
                    </span>
                </div>
                <p style={{margin: '0px 0px 0px 0px', color: '#d36233'}}>
                    {/* {lucroBruto >= 0 */}
                    {/* ? */}
                    {new Intl.NumberFormat('pt-BR', {
                        style: 'percent',
                        minimumFractionDigits: 2,
                        currency: 'BRL'
                    })
                        .format(
                            Number.isNaN((lucroBruto * 0.85) / captialAloc)
                                ? 0
                                : (lucroBruto * 0.85) / captialAloc
                        )
                        .replace(',', '.')}
                    {/* : new Intl.NumberFormat('pt-BR', {
                              style: 'percent',
                              minimumFractionDigits: 2,
                              currency: 'BRL'
                          })
                              .format(0)
                              .replace(',', '.')} */}
                </p>
            </div>
            <div className={classes.top1}>
                <div className={classes.item}>
                    <p className={classes.text}>LUCRO LIQUIDO</p>
                    <span
                        style={{cursor: 'pointer'}}
                        data-tip="Lucro obtido na estrutura registrada subtraído dos impostos - Consideramos 15% de imposto sobre o lucro obtido. Para calculo exato e emissão de documentos fiscais, indicamos que procure um contador especializado."
                    >
                        <ion-icon
                            style={{
                                marginBottom: 2,
                                fontSize: 12,
                                color: '#d36233',
                                pointerEvents: 'none'
                            }}
                            name="help-circle-outline"
                        />
                    </span>
                </div>
                <p style={{margin: '0px 0px 0px 0px', color: '#d36233'}}>
                    {lucroBruto >= 0
                        ? new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                          })
                              .format(lucroBruto * 0.85)
                              .replace(',', '.')
                        : new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                          })
                              .format(0)
                              .replace(',', '.')}
                </p>
            </div>
            {/* <div className="col-sm-12">
                <div className={classes.footer}>
                    <p>extrato detalhado {'>'}</p>
                </div>
            </div> */}
        </div>
    );
}
