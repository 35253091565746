/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, {useState, useEffect} from 'react';
import ReactTooltip from 'react-tooltip';
import Pagination from '@material-ui/lab/Pagination';

import {makeStyles} from '@material-ui/core/styles';
// import moment from 'moment';

const useStyles = makeStyles(() => ({
    root: {
        '& .Mui-selected': {
            backgroundColor: '#e37039',
            '&:hover': {
                backgroundColor: '#e37039'
            }
        }
    },
    tableRow: {
        height: 10,
        padding: '0px 16px',
        '&:hover': {
            background: '#efefef'
        }
    }
}));

export default function Table({active, dt1, notify}) {
    const classes = useStyles();
    const [page, setPage] = useState(1);
    const rowsPerPage = 25;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(() => {
        if (active && page !== 1) {
            setPage(1);
        }
    }, [active]);

    return (
        <>
            <table className="table">
                <thead>
                    <tr
                        style={{
                            whiteSpace: 'nowrap'
                        }}
                    >
                        <th
                            style={{
                                color: '#d36233'
                            }}
                        >
                            <ReactTooltip id="tic" />
                            TICKER
                            <span
                                style={{cursor: 'pointer'}}
                                data-for="tic"
                                data-tip="TICKER - Abreviação utilizada para identificar exclusivamente contratos de opções"
                            >
                                <ion-icon
                                    style={{
                                        marginBottom: 2,
                                        fontSize: 12,
                                        color: '#d36233',
                                        pointerEvents: 'none'
                                    }}
                                    name="help-circle-outline"
                                />
                            </span>
                        </th>
                        <th
                            style={{
                                color: '#d36233'
                            }}
                        >
                            <ReactTooltip id="strike" />
                            STRIKE
                            <span
                                style={{cursor: 'pointer'}}
                                data-for="strike"
                                data-tip="STRIKE - Preço de exercício do contrato de opções."
                            >
                                <ion-icon
                                    style={{
                                        marginBottom: 2,
                                        fontSize: 12,
                                        color: '#d36233',
                                        pointerEvents: 'none'
                                    }}
                                    name="help-circle-outline"
                                />
                            </span>
                        </th>
                        <th
                            style={{
                                color: '#d36233'
                            }}
                        >
                            <ReactTooltip id="cob" />
                            COBERTO
                            <span
                                style={{cursor: 'pointer'}}
                                data-for="cob"
                                data-tip="COBERTO - Venda de opção com ativo para cobrir a operação."
                            >
                                <ion-icon
                                    style={{
                                        marginBottom: 2,
                                        fontSize: 12,
                                        color: '#d36233',
                                        pointerEvents: 'none'
                                    }}
                                    name="help-circle-outline"
                                />
                            </span>
                        </th>
                        <th
                            style={{
                                color: '#d36233'
                            }}
                        >
                            <ReactTooltip id="trava" />
                            TRAVA
                            <span
                                style={{cursor: 'pointer'}}
                                data-for="trava"
                                data-tip="TRAVA - Venda de opção e compra de outra para travar lucro e prejuízo da operação."
                            >
                                <ion-icon
                                    style={{
                                        marginBottom: 2,
                                        fontSize: 12,
                                        color: '#d36233',
                                        pointerEvents: 'none'
                                    }}
                                    name="help-circle-outline"
                                />
                            </span>
                        </th>
                        <th
                            style={{
                                color: '#d36233'
                            }}
                        >
                            <ReactTooltip id="desc" />
                            DESCOBERTO
                            <span
                                style={{cursor: 'pointer'}}
                                data-for="desc"
                                data-tip="DESCOBERTO - Venda de opção sem ativo ou trava para cobrir a operação."
                            >
                                <ion-icon
                                    style={{
                                        marginBottom: 2,
                                        fontSize: 12,
                                        color: '#d36233',
                                        pointerEvents: 'none'
                                    }}
                                    name="help-circle-outline"
                                />
                            </span>
                        </th>
                        <th
                            style={{
                                color: '#d36233'
                            }}
                        >
                            <ReactTooltip id="tot" />
                            TOTAL
                            <span
                                style={{cursor: 'pointer'}}
                                data-for="tot"
                                data-tip="TOTAL - Soma dos lançamentos COBERTOS, TRAVAS e DESCOBERTOS."
                            >
                                <ion-icon
                                    style={{
                                        marginBottom: 2,
                                        fontSize: 12,
                                        color: '#d36233',
                                        pointerEvents: 'none'
                                    }}
                                    name="help-circle-outline"
                                />
                            </span>
                        </th>
                        <th
                            style={{
                                color: '#d36233'
                            }}
                        >
                            <ReactTooltip id="tit" />
                            TITULAR
                            <span
                                style={{cursor: 'pointer'}}
                                data-for="tit"
                                data-tip="TITULAR - Número de compradores de contratos de opções."
                            >
                                <ion-icon
                                    style={{
                                        marginBottom: 2,
                                        fontSize: 12,
                                        color: '#d36233',
                                        pointerEvents: 'none'
                                    }}
                                    name="help-circle-outline"
                                />
                            </span>
                        </th>

                        <th
                            style={{
                                color: '#d36233',
                                width: '10%'
                            }}
                        >
                            <ReactTooltip id="lanc" />
                            LAN
                            <span
                                style={{
                                    fontFamily: 'Rubik',
                                    fontWeight: '300',
                                    fontSize: 16.5
                                }}
                            >
                                Ç
                            </span>
                            ADOR
                            <span
                                style={{cursor: 'pointer'}}
                                data-for="lanc"
                                data-tip="LANÇADOR - Número de vendedores de contratos de opções."
                            >
                                <ion-icon
                                    style={{
                                        marginBottom: 2,
                                        fontSize: 12,
                                        color: '#d36233',
                                        pointerEvents: 'none'
                                    }}
                                    name="help-circle-outline"
                                />
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody
                    style={{
                        fontFamily: 'Roboto',
                        fontWeight: 400,
                        fontSize: 14
                    }}
                >
                    {dt1
                        .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                        .map((v, ii) => (
                            <tr key={ii} className={classes.tableRow}>
                                <td
                                    style={{
                                        whiteSpace: 'nowrap'
                                    }}
                                >
                                    {v.ticker}
                                    <span
                                        title="Copiar"
                                        style={{cursor: 'pointer'}}
                                        // onClick={() => notify(v.ticker)}
                                        onClick={() => notify(v.ticker)}
                                    >
                                        <ion-icon
                                            // onClick={() => notify(v.ticker)}
                                            style={{
                                                fontSize: 12,
                                                color: '#d36233',
                                                pointerEvents: 'none',
                                                marginLeft: 5
                                            }}
                                            name="copy-outline"
                                        />
                                    </span>
                                    <ReactTooltip />
                                    {/* {v.fm !== '0' ? (
                                    <ion-icon
                                        data-tip="FM - Formador de mercado"
                                        name="people-circle"
                                        style={{
                                            marginLeft: 4,
                                            marginBottom: -4.5,
                                            fontSize: 20
                                        }}
                                    />
                                ) : (
                                    ''
                                )} */}
                                </td>
                                <td>R${v.strike}</td>
                                {/* <td>{v.coberto}</td> */}
                                <td>
                                    {new Intl.NumberFormat().format(v.coberto)}
                                </td>
                                <td>
                                    {new Intl.NumberFormat().format(v.trava)}
                                </td>
                                <td>
                                    {new Intl.NumberFormat().format(
                                        v.descoberto
                                    )}
                                </td>
                                <td>
                                    {new Intl.NumberFormat().format(v.total)}
                                </td>
                                <td>{v.titular}</td>
                                <td>{v.lancador}</td>
                            </tr>
                        ))}
                </tbody>
            </table>
            <Pagination
                count={Math.ceil(Number(dt1.length / rowsPerPage))}
                page={page}
                onChange={handleChangePage}
                // onChangeRowsPerPage={handleChangeRowsPerPage}
                // rowsPerPage={rowsPerPage}
                className={classes.root}
            />
        </>
    );
}
