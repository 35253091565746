const citiesSul = [
    {state: 'RS', value: 'Aceguá'},
    {state: 'RS', value: 'Água Santa'},
    {state: 'RS', value: 'Agudo'},
    {state: 'RS', value: 'Ajuricaba'},
    {state: 'RS', value: 'Alecrim'},
    {state: 'RS', value: 'Alegrete'},
    {state: 'RS', value: 'Alegria'},
    {state: 'RS', value: 'Almirante Tamandaré do Sul'},
    {state: 'RS', value: 'Alpestre'},
    {state: 'RS', value: 'Alto Alegre'},
    {state: 'RS', value: 'Alto Feliz'},
    {state: 'RS', value: 'Alvorada'},
    {state: 'RS', value: 'Amaral Ferrador'},
    {state: 'RS', value: 'Ametista do Sul'},
    {state: 'RS', value: 'André da Rocha'},
    {state: 'RS', value: 'Anta Gorda'},
    {state: 'RS', value: 'Antônio Prado'},
    {state: 'RS', value: 'Arambaré'},
    {state: 'RS', value: 'Araricá'},
    {state: 'RS', value: 'Aratiba'},
    {state: 'RS', value: 'Arroio do Meio'},
    {state: 'RS', value: 'Arroio do Padre'},
    {state: 'RS', value: 'Arroio do Sal'},
    {state: 'RS', value: 'Arroio do Tigre'},
    {state: 'RS', value: 'Arroio dos Ratos'},
    {state: 'RS', value: 'Arroio Grande'},
    {state: 'RS', value: 'Arvorezinha'},
    {state: 'RS', value: 'Augusto Pestana'},
    {state: 'RS', value: 'Áurea'},
    {state: 'RS', value: 'Bagé'},
    {state: 'RS', value: 'Balneário Pinhal'},
    {state: 'RS', value: 'Barão'},
    {state: 'RS', value: 'Barão de Cotegipe'},
    {state: 'RS', value: 'Barão do Triunfo'},
    {state: 'RS', value: 'Barra do Guarita'},
    {state: 'RS', value: 'Barra do Quaraí'},
    {state: 'RS', value: 'Barra do Ribeiro'},
    {state: 'RS', value: 'Barra do Rio Azul'},
    {state: 'RS', value: 'Barra Funda'},
    {state: 'RS', value: 'Barracão'},
    {state: 'RS', value: 'Barros Cassal'},
    {state: 'RS', value: 'Benjamin Constant do Sul'},
    {state: 'RS', value: 'Bento Gonçalves'},
    {state: 'RS', value: 'Boa Vista das Missões'},
    {state: 'RS', value: 'Boa Vista do Buricá'},
    {state: 'RS', value: 'Boa Vista do Cadeado'},
    {state: 'RS', value: 'Boa Vista do Incra'},
    {state: 'RS', value: 'Boa Vista do Sul'},
    {state: 'RS', value: 'Bom Jesus'},
    {state: 'RS', value: 'Bom Princípio'},
    {state: 'RS', value: 'Bom Progresso'},
    {state: 'RS', value: 'Bom Retiro do Sul'},
    {state: 'RS', value: 'Boqueirão do Leão'},
    {state: 'RS', value: 'Bossoroca'},
    {state: 'RS', value: 'Bozano'},
    {state: 'RS', value: 'Braga'},
    {state: 'RS', value: 'Brochier'},
    {state: 'RS', value: 'Butiá'},
    {state: 'RS', value: 'Caçapava do Sul'},
    {state: 'RS', value: 'Cacequi'},
    {state: 'RS', value: 'Cachoeira do Sul'},
    {state: 'RS', value: 'Cachoeirinha'},
    {state: 'RS', value: 'Cacique Doble'},
    {state: 'RS', value: 'Caibaté'},
    {state: 'RS', value: 'Caiçara'},
    {state: 'RS', value: 'Camaquã'},
    {state: 'RS', value: 'Camargo'},
    {state: 'RS', value: 'Cambará do Sul'},
    {state: 'RS', value: 'Campestre da Serra'},
    {state: 'RS', value: 'Campina das Missões'},
    {state: 'RS', value: 'Campinas do Sul'},
    {state: 'RS', value: 'Campo Bom'},
    {state: 'RS', value: 'Campo Novo'},
    {state: 'RS', value: 'Campos Borges'},
    {state: 'RS', value: 'Candelária'},
    {state: 'RS', value: 'Cândido Godói'},
    {state: 'RS', value: 'Candiota'},
    {state: 'RS', value: 'Canela'},
    {state: 'RS', value: 'Canguçu'},
    {state: 'RS', value: 'Canoas'},
    {state: 'RS', value: 'Canudos do Vale'},
    {state: 'RS', value: 'Capão Bonito do Sul'},
    {state: 'RS', value: 'Capão da Canoa'},
    {state: 'RS', value: 'Capão do Cipó'},
    {state: 'RS', value: 'Capão do Leão'},
    {state: 'RS', value: 'Capela de Santana'},
    {state: 'RS', value: 'Capitão'},
    {state: 'RS', value: 'Capivari do Sul'},
    {state: 'RS', value: 'Caraá'},
    {state: 'RS', value: 'Carazinho'},
    {state: 'RS', value: 'Carlos Barbosa'},
    {state: 'RS', value: 'Carlos Gomes'},
    {state: 'RS', value: 'Casca'},
    {state: 'RS', value: 'Caseiros'},
    {state: 'RS', value: 'Catuípe'},
    {state: 'RS', value: 'Caxias do Sul'},
    {state: 'RS', value: 'Centenário'},
    {state: 'RS', value: 'Cerrito'},
    {state: 'RS', value: 'Cerro Branco'},
    {state: 'RS', value: 'Cerro Grande do Sul'},
    {state: 'RS', value: 'Cerro Grande'},
    {state: 'RS', value: 'Cerro Largo'},
    {state: 'RS', value: 'Chapada'},
    {state: 'RS', value: 'Charqueadas'},
    {state: 'RS', value: 'Charrua'},
    {state: 'RS', value: 'Chiapetta'},
    {state: 'RS', value: 'Chuí'},
    {state: 'RS', value: 'Chuvisca'},
    {state: 'RS', value: 'Cidreira'},
    {state: 'RS', value: 'Ciríaco'},
    {state: 'RS', value: 'Colinas'},
    {state: 'RS', value: 'Colorado'},
    {state: 'RS', value: 'Condor'},
    {state: 'RS', value: 'Constantina'},
    {state: 'RS', value: 'Coqueiro Baixo'},
    {state: 'RS', value: 'Coqueiros do Sul'},
    {state: 'RS', value: 'Coronel Barros'},
    {state: 'RS', value: 'Coronel Bicaco'},
    {state: 'RS', value: 'Coronel Pilar'},
    {state: 'RS', value: 'Cotiporã'},
    {state: 'RS', value: 'Coxilha'},
    {state: 'RS', value: 'Crissiumal'},
    {state: 'RS', value: 'Cristal'},
    {state: 'RS', value: 'Cristal do Sul'},
    {state: 'RS', value: 'Cruz Alta'},
    {state: 'RS', value: 'Cruzaltense'},
    {state: 'RS', value: 'Cruzeiro do Sul'},
    {state: 'RS', value: 'David Canabarro'},
    {state: 'RS', value: 'Derrubadas'},
    {state: 'RS', value: 'Dezesseis de Novembro'},
    {state: 'RS', value: 'Dilermando de Aguiar'},
    {state: 'RS', value: 'Dois Irmãos das Missões'},
    {state: 'RS', value: 'Dois Irmãos'},
    {state: 'RS', value: 'Dois Lajeados'},
    {state: 'RS', value: 'Dom Feliciano'},
    {state: 'RS', value: 'Dom Pedrito'},
    {state: 'RS', value: 'Dom Pedro de Alcântara'},
    {state: 'RS', value: 'Dona Francisca'},
    {state: 'RS', value: 'Doutor Maurício Cardoso'},
    {state: 'RS', value: 'Doutor Ricardo'},
    {state: 'RS', value: 'Eldorado do Sul'},
    {state: 'RS', value: 'Encantado'},
    {state: 'RS', value: 'Encruzilhada do Sul'},
    {state: 'RS', value: 'Engenho Velho'},
    {state: 'RS', value: 'Entre Rios do Sul'},
    {state: 'RS', value: 'Entre-Ijuís'},
    {state: 'RS', value: 'Erebango'},
    {state: 'RS', value: 'Erechim'},
    {state: 'RS', value: 'Ernestina'},
    {state: 'RS', value: 'Erval Grande'},
    {state: 'RS', value: 'Erval Seco'},
    {state: 'RS', value: 'Esmeralda'},
    {state: 'RS', value: 'Esperança do Sul'},
    {state: 'RS', value: 'Espumoso'},
    {state: 'RS', value: 'Estação'},
    {state: 'RS', value: 'Estância Velha'},
    {state: 'RS', value: 'Esteio'},
    {state: 'RS', value: 'Estrela'},
    {state: 'RS', value: 'Estrela Velha'},
    {state: 'RS', value: 'Eugênio de Castro'},
    {state: 'RS', value: 'Fagundes Varela'},
    {state: 'RS', value: 'Farroupilha'},
    {state: 'RS', value: 'Faxinal do Soturno'},
    {state: 'RS', value: 'Faxinalzinho'},
    {state: 'RS', value: 'Fazenda Vilanova'},
    {state: 'RS', value: 'Feliz'},
    {state: 'RS', value: 'Flores da Cunha'},
    {state: 'RS', value: 'Floriano Peixoto'},
    {state: 'RS', value: 'Fontoura Xavier'},
    {state: 'RS', value: 'Formigueiro'},
    {state: 'RS', value: 'Forquetinha'},
    {state: 'RS', value: 'Fortaleza dos Valos'},
    {state: 'RS', value: 'Frederico Westphalen'},
    {state: 'RS', value: 'Garibaldi'},
    {state: 'RS', value: 'Garruchos'},
    {state: 'RS', value: 'Gaurama'},
    {state: 'RS', value: 'General Câmara'},
    {state: 'RS', value: 'Gentil'},
    {state: 'RS', value: 'Getúlio Vargas'},
    {state: 'RS', value: 'Giruá'},
    {state: 'RS', value: 'Glorinha'},
    {state: 'RS', value: 'Gramado dos Loureiros'},
    {state: 'RS', value: 'Gramado Xavier'},
    {state: 'RS', value: 'Gramado'},
    {state: 'RS', value: 'Gravataí'},
    {state: 'RS', value: 'Guabiju'},
    {state: 'RS', value: 'Guaíba'},
    {state: 'RS', value: 'Guaporé'},
    {state: 'RS', value: 'Guarani das Missões'},
    {state: 'RS', value: 'Harmonia'},
    {state: 'RS', value: 'Herval'},
    {state: 'RS', value: 'Herveiras'},
    {state: 'RS', value: 'Horizontina'},
    {state: 'RS', value: 'Hulha Negra'},
    {state: 'RS', value: 'Humaitá'},
    {state: 'RS', value: 'Ibarama'},
    {state: 'RS', value: 'Ibiaçá'},
    {state: 'RS', value: 'Ibiraiaras'},
    {state: 'RS', value: 'Ibirapuitã'},
    {state: 'RS', value: 'Ibirubá'},
    {state: 'RS', value: 'Igrejinha'},
    {state: 'RS', value: 'Ijuí'},
    {state: 'RS', value: 'Ilópolis'},
    {state: 'RS', value: 'Imbé'},
    {state: 'RS', value: 'Imigrante'},
    {state: 'RS', value: 'Independência'},
    {state: 'RS', value: 'Inhacorá'},
    {state: 'RS', value: 'Ipê'},
    {state: 'RS', value: 'Ipiranga do Sul'},
    {state: 'RS', value: 'Iraí'},
    {state: 'RS', value: 'Itaara'},
    {state: 'RS', value: 'Itacurubi'},
    {state: 'RS', value: 'Itapuca'},
    {state: 'RS', value: 'Itaqui'},
    {state: 'RS', value: 'Itati'},
    {state: 'RS', value: 'Itatiba do Sul'},
    {state: 'RS', value: 'Ivorá'},
    {state: 'RS', value: 'Ivoti'},
    {state: 'RS', value: 'Jaboticaba'},
    {state: 'RS', value: 'Jacuizinho'},
    {state: 'RS', value: 'Jacutinga'},
    {state: 'RS', value: 'Jaguarão'},
    {state: 'RS', value: 'Jaguari'},
    {state: 'RS', value: 'Jaquirana'},
    {state: 'RS', value: 'Jari'},
    {state: 'RS', value: 'Jóia'},
    {state: 'RS', value: 'Júlio de Castilhos'},
    {state: 'RS', value: 'Lagoa Bonita do Sul'},
    {state: 'RS', value: 'Lagoa dos Três Cantos'},
    {state: 'RS', value: 'Lagoa Vermelha'},
    {state: 'RS', value: 'Lagoão'},
    {state: 'RS', value: 'Lajeado'},
    {state: 'RS', value: 'Lajeado do Bugre'},
    {state: 'RS', value: 'Lavras do Sul'},
    {state: 'RS', value: 'Liberato Salzano'},
    {state: 'RS', value: 'Lindolfo Collor'},
    {state: 'RS', value: 'Linha Nova'},
    {state: 'RS', value: 'Maçambará'},
    {state: 'RS', value: 'Machadinho'},
    {state: 'RS', value: 'Mampituba'},
    {state: 'RS', value: 'Manoel Viana'},
    {state: 'RS', value: 'Maquiné'},
    {state: 'RS', value: 'Maratá'},
    {state: 'RS', value: 'Marau'},
    {state: 'RS', value: 'Marcelino Ramos'},
    {state: 'RS', value: 'Mariana Pimentel'},
    {state: 'RS', value: 'Mariano Moro'},
    {state: 'RS', value: 'Marques de Souza'},
    {state: 'RS', value: 'Mata'},
    {state: 'RS', value: 'Mato Castelhano'},
    {state: 'RS', value: 'Mato Leitão'},
    {state: 'RS', value: 'Mato Queimado'},
    {state: 'RS', value: 'Maximiliano de Almeida'},
    {state: 'RS', value: 'Minas do Leão'},
    {state: 'RS', value: 'Miraguaí'},
    {state: 'RS', value: 'Montauri'},
    {state: 'RS', value: 'Monte Alegre dos Campos'},
    {state: 'RS', value: 'Monte Belo do Sul'},
    {state: 'RS', value: 'Montenegro'},
    {state: 'RS', value: 'Mormaço'},
    {state: 'RS', value: 'Morrinhos do Sul'},
    {state: 'RS', value: 'Morro Redondo'},
    {state: 'RS', value: 'Morro Reuter'},
    {state: 'RS', value: 'Mostardas'},
    {state: 'RS', value: 'Muçum'},
    {state: 'RS', value: 'Muitos Capões'},
    {state: 'RS', value: 'Muliterno'},
    {state: 'RS', value: 'Não-Me-Toque'},
    {state: 'RS', value: 'Nicolau Vergueiro'},
    {state: 'RS', value: 'Nonoai'},
    {state: 'RS', value: 'Nova Alvorada'},
    {state: 'RS', value: 'Nova Araçá'},
    {state: 'RS', value: 'Nova Bassano'},
    {state: 'RS', value: 'Nova Boa Vista'},
    {state: 'RS', value: 'Nova Bréscia'},
    {state: 'RS', value: 'Nova Candelária'},
    {state: 'RS', value: 'Nova Esperança do Sul'},
    {state: 'RS', value: 'Nova Hartz'},
    {state: 'RS', value: 'Nova Pádua'},
    {state: 'RS', value: 'Nova Palma'},
    {state: 'RS', value: 'Nova Petrópolis'},
    {state: 'RS', value: 'Nova Prata'},
    {state: 'RS', value: 'Nova Ramada'},
    {state: 'RS', value: 'Nova Roma do Sul'},
    {state: 'RS', value: 'Nova Santa Rita'},
    {state: 'RS', value: 'Novo Barreiro'},
    {state: 'RS', value: 'Novo Cabrais'},
    {state: 'RS', value: 'Novo Hamburgo'},
    {state: 'RS', value: 'Novo Machado'},
    {state: 'RS', value: 'Novo Tiradentes'},
    {state: 'RS', value: 'Novo Xingu'},
    {state: 'RS', value: 'Osório'},
    {state: 'RS', value: 'Paim Filho'},
    {state: 'RS', value: 'Palmares do Sul'},
    {state: 'RS', value: 'Palmeira das Missões'},
    {state: 'RS', value: 'Palmitinho'},
    {state: 'RS', value: 'Panambi'},
    {state: 'RS', value: 'Pantano Grande'},
    {state: 'RS', value: 'Paraí'},
    {state: 'RS', value: 'Paraíso do Sul'},
    {state: 'RS', value: 'Pareci Novo'},
    {state: 'RS', value: 'Parobé'},
    {state: 'RS', value: 'Passa-Sete'},
    {state: 'RS', value: 'Passo do Sobrado'},
    {state: 'RS', value: 'Passo Fundo'},
    {state: 'RS', value: 'Paulo Bento'},
    {state: 'RS', value: 'Paverama'},
    {state: 'RS', value: 'Pedras Altas'},
    {state: 'RS', value: 'Pedro Osório'},
    {state: 'RS', value: 'Pejuçara'},
    {state: 'RS', value: 'Pelotas'},
    {state: 'RS', value: 'Picada Café'},
    {state: 'RS', value: 'Pinhal'},
    {state: 'RS', value: 'Pinhal da Serra'},
    {state: 'RS', value: 'Pinhal Grande'},
    {state: 'RS', value: 'Pinheirinho do Vale'},
    {state: 'RS', value: 'Pinheiro Machado'},
    {state: 'RS', value: 'Pinto Bandeira'},
    {state: 'RS', value: 'Pirapó'},
    {state: 'RS', value: 'Piratini'},
    {state: 'RS', value: 'Planalto'},
    {state: 'RS', value: 'Poço das Antas'},
    {state: 'RS', value: 'Pontão'},
    {state: 'RS', value: 'Ponte Preta'},
    {state: 'RS', value: 'Portão'},
    {state: 'RS', value: 'Porto Alegre'},
    {state: 'RS', value: 'Porto Lucena'},
    {state: 'RS', value: 'Porto Mauá'},
    {state: 'RS', value: 'Porto Vera Cruz'},
    {state: 'RS', value: 'Porto Xavier'},
    {state: 'RS', value: 'Pouso Novo'},
    {state: 'RS', value: 'Presidente Lucena'},
    {state: 'RS', value: 'Progresso'},
    {state: 'RS', value: 'Protásio Alves'},
    {state: 'RS', value: 'Putinga'},
    {state: 'RS', value: 'Quaraí'},
    {state: 'RS', value: 'Quatro Irmãos'},
    {state: 'RS', value: 'Quevedos'},
    {state: 'RS', value: 'Quinze de Novembro'},
    {state: 'RS', value: 'Redentora'},
    {state: 'RS', value: 'Relvado'},
    {state: 'RS', value: 'Restinga Sêca'},
    {state: 'RS', value: 'Rio dos Índios'},
    {state: 'RS', value: 'Rio Grande'},
    {state: 'RS', value: 'Rio Pardo'},
    {state: 'RS', value: 'Riozinho'},
    {state: 'RS', value: 'Roca Sales'},
    {state: 'RS', value: 'Rodeio Bonito'},
    {state: 'RS', value: 'Rolador'},
    {state: 'RS', value: 'Rolante'},
    {state: 'RS', value: 'Ronda Alta'},
    {state: 'RS', value: 'Rondinha'},
    {state: 'RS', value: 'Roque Gonzales'},
    {state: 'RS', value: 'Rosário do Sul'},
    {state: 'RS', value: 'Sagrada Família'},
    {state: 'RS', value: 'Saldanha Marinho'},
    {state: 'RS', value: 'Salto do Jacuí'},
    {state: 'RS', value: 'Salvador das Missões'},
    {state: 'RS', value: 'Salvador do Sul'},
    {state: 'RS', value: 'Sananduva'},
    {state: 'RS', value: 'Santa Bárbara do Sul'},
    {state: 'RS', value: 'Santa Cecília do Sul'},
    {state: 'RS', value: 'Santa Clara do Sul'},
    {state: 'RS', value: 'Santa Cruz do Sul'},
    {state: 'RS', value: 'Santa Margarida do Sul'},
    {state: 'RS', value: 'Santa Maria'},
    {state: 'RS', value: 'Santa Maria do Herval'},
    {state: 'RS', value: 'Santa Rosa'},
    {state: 'RS', value: 'Santa Tereza'},
    {state: 'RS', value: 'Santa Vitória do Palmar'},
    {state: 'RS', value: 'Santana da Boa Vista'},
    {state: 'RS', value: "Sant'Ana do Livramento"},
    {state: 'RS', value: 'Santiago'},
    {state: 'RS', value: 'Santo Ângelo'},
    {state: 'RS', value: 'Santo Antônio da Patrulha'},
    {state: 'RS', value: 'Santo Antônio das Missões'},
    {state: 'RS', value: 'Santo Antônio do Palma'},
    {state: 'RS', value: 'Santo Antônio do Planalto'},
    {state: 'RS', value: 'Santo Augusto'},
    {state: 'RS', value: 'Santo Cristo'},
    {state: 'RS', value: 'Santo Expedito do Sul'},
    {state: 'RS', value: 'São Borja'},
    {state: 'RS', value: 'São Domingos do Sul'},
    {state: 'RS', value: 'São Francisco de Assis'},
    {state: 'RS', value: 'São Francisco de Paula'},
    {state: 'RS', value: 'São Gabriel'},
    {state: 'RS', value: 'São Jerônimo'},
    {state: 'RS', value: 'São João da Urtiga'},
    {state: 'RS', value: 'São João do Polêsine'},
    {state: 'RS', value: 'São Jorge'},
    {state: 'RS', value: 'São José das Missões'},
    {state: 'RS', value: 'São José do Herval'},
    {state: 'RS', value: 'São José do Hortêncio'},
    {state: 'RS', value: 'São José do Inhacorá'},
    {state: 'RS', value: 'São José do Norte'},
    {state: 'RS', value: 'São José do Ouro'},
    {state: 'RS', value: 'São José do Sul'},
    {state: 'RS', value: 'São José dos Ausentes'},
    {state: 'RS', value: 'São Leopoldo'},
    {state: 'RS', value: 'São Lourenço do Sul'},
    {state: 'RS', value: 'São Luiz Gonzaga'},
    {state: 'RS', value: 'São Marcos'},
    {state: 'RS', value: 'São Martinho'},
    {state: 'RS', value: 'São Martinho da Serra'},
    {state: 'RS', value: 'São Miguel das Missões'},
    {state: 'RS', value: 'São Nicolau'},
    {state: 'RS', value: 'São Paulo das Missões'},
    {state: 'RS', value: 'São Pedro da Serra'},
    {state: 'RS', value: 'São Pedro das Missões'},
    {state: 'RS', value: 'São Pedro do Butiá'},
    {state: 'RS', value: 'São Pedro do Sul'},
    {state: 'RS', value: 'São Sebastião do Caí'},
    {state: 'RS', value: 'São Sepé'},
    {state: 'RS', value: 'São Valentim'},
    {state: 'RS', value: 'São Valentim do Sul'},
    {state: 'RS', value: 'São Valério do Sul'},
    {state: 'RS', value: 'São Vendelino'},
    {state: 'RS', value: 'São Vicente do Sul'},
    {state: 'RS', value: 'Sapiranga'},
    {state: 'RS', value: 'Sapucaia do Sul'},
    {state: 'RS', value: 'Sarandi'},
    {state: 'RS', value: 'Seberi'},
    {state: 'RS', value: 'Sede Nova'},
    {state: 'RS', value: 'Segredo'},
    {state: 'RS', value: 'Selbach'},
    {state: 'RS', value: 'Senador Salgado Filho'},
    {state: 'RS', value: 'Sentinela do Sul'},
    {state: 'RS', value: 'Serafina Corrêa'},
    {state: 'RS', value: 'Sério'},
    {state: 'RS', value: 'Sertão'},
    {state: 'RS', value: 'Sertão Santana'},
    {state: 'RS', value: 'Sete de Setembro'},
    {state: 'RS', value: 'Severiano de Almeida'},
    {state: 'RS', value: 'Silveira Martins'},
    {state: 'RS', value: 'Sinimbu'},
    {state: 'RS', value: 'Sobradinho'},
    {state: 'RS', value: 'Soledade'},
    {state: 'RS', value: 'Tabaí'},
    {state: 'RS', value: 'Tapejara'},
    {state: 'RS', value: 'Tapera'},
    {state: 'RS', value: 'Tapes'},
    {state: 'RS', value: 'Taquara'},
    {state: 'RS', value: 'Taquari'},
    {state: 'RS', value: 'Taquaruçu do Sul'},
    {state: 'RS', value: 'Tavares'},
    {state: 'RS', value: 'Tenente Portela'},
    {state: 'RS', value: 'Terra de Areia'},
    {state: 'RS', value: 'Teutônia'},
    {state: 'RS', value: 'Tio Hugo'},
    {state: 'RS', value: 'Tiradentes do Sul'},
    {state: 'RS', value: 'Toropi'},
    {state: 'RS', value: 'Torres'},
    {state: 'RS', value: 'Tramandaí'},
    {state: 'RS', value: 'Travesseiro'},
    {state: 'RS', value: 'Três Arroios'},
    {state: 'RS', value: 'Três Cachoeiras'},
    {state: 'RS', value: 'Três Coroas'},
    {state: 'RS', value: 'Três de Maio'},
    {state: 'RS', value: 'Três Forquilhas'},
    {state: 'RS', value: 'Três Palmeiras'},
    {state: 'RS', value: 'Três Passos'},
    {state: 'RS', value: 'Trindade do Sul'},
    {state: 'RS', value: 'Triunfo'},
    {state: 'RS', value: 'Tucunduva'},
    {state: 'RS', value: 'Tunas'},
    {state: 'RS', value: 'Tupanci do Sul'},
    {state: 'RS', value: 'Tupanciretã'},
    {state: 'RS', value: 'Tupandi'},
    {state: 'RS', value: 'Tuparendi'},
    {state: 'RS', value: 'Turuçu'},
    {state: 'RS', value: 'Ubiretama'},
    {state: 'RS', value: 'União da Serra'},
    {state: 'RS', value: 'Unistalda'},
    {state: 'RS', value: 'Uruguaiana'},
    {state: 'RS', value: 'Vacaria'},
    {state: 'RS', value: 'Vale do Sol'},
    {state: 'RS', value: 'Vale Real'},
    {state: 'RS', value: 'Vale Verde'},
    {state: 'RS', value: 'Vanini'},
    {state: 'RS', value: 'Venâncio Aires'},
    {state: 'RS', value: 'Vera Cruz'},
    {state: 'RS', value: 'Veranópolis'},
    {state: 'RS', value: 'Vespasiano Corrêa'},
    {state: 'RS', value: 'Viadutos'},
    {state: 'RS', value: 'Viamão'},
    {state: 'RS', value: 'Vicente Dutra'},
    {state: 'RS', value: 'Victor Graeff'},
    {state: 'RS', value: 'Vila Flores'},
    {state: 'RS', value: 'Vila Lângaro'},
    {state: 'RS', value: 'Vila Maria'},
    {state: 'RS', value: 'Vila Nova do Sul'},
    {state: 'RS', value: 'Vista Alegre do Prata'},
    {state: 'RS', value: 'Vista Alegre'},
    {state: 'RS', value: 'Vista Gaúcha'},
    {state: 'RS', value: 'Vitória das Missões'},
    {state: 'RS', value: 'Westfália'},
    {state: 'RS', value: 'Xangri-lá'},
    {state: 'SC', value: 'Abdon Batista'},
    {state: 'SC', value: 'Abelardo Luz'},
    {state: 'SC', value: 'Agrolândia'},
    {state: 'SC', value: 'Agronômica'},
    {state: 'SC', value: 'Água Doce'},
    {state: 'SC', value: 'Águas de Chapecó'},
    {state: 'SC', value: 'Águas Frias'},
    {state: 'SC', value: 'Águas Mornas'},
    {state: 'SC', value: 'lfredo Wagner'},
    {state: 'SC', value: 'Alto Bela Vista'},
    {state: 'SC', value: 'Anchieta'},
    {state: 'SC', value: 'Angelina'},
    {state: 'SC', value: 'Anita Garibaldi'},
    {state: 'SC', value: 'nitápolis'},
    {state: 'SC', value: 'Antônio Carlos'},
    {state: 'SC', value: 'Apiúna'},
    {state: 'SC', value: 'Arabutã'},
    {state: 'SC', value: 'Araquari'},
    {state: 'SC', value: 'raranguá'},
    {state: 'SC', value: 'Armazém'},
    {state: 'SC', value: 'Arroio Trinta'},
    {state: 'SC', value: 'Arvoredo'},
    {state: 'SC', value: 'Ascurra'},
    {state: 'SC', value: 'Atalanta'},
    {state: 'SC', value: 'Aurora'},
    {state: 'SC', value: 'Balneário Arroio do Silva'},
    {state: 'SC', value: 'Balneário Barra do Sul'},
    {state: 'SC', value: 'Balneário Camboriú'},
    {state: 'SC', value: 'Balneário Gaivota'},
    {state: 'SC', value: 'Balneário Piçarras'},
    {state: 'SC', value: 'Balneário Rincão'},
    {state: 'SC', value: 'Bandeirante'},
    {state: 'SC', value: 'Barra Bonita'},
    {state: 'SC', value: 'Barra Velha'},
    {state: 'SC', value: 'Bela Vista do Toldo'},
    {state: 'SC', value: 'Belmonte'},
    {state: 'SC', value: 'Benedito Novo'},
    {state: 'SC', value: 'Biguaçu'},
    {state: 'SC', value: 'Blumenau'},
    {state: 'SC', value: 'Bocaina do Sul'},
    {state: 'SC', value: 'Bom Jardim da Serra'},
    {state: 'SC', value: 'Bom Jesus'},
    {state: 'SC', value: 'Bom Jesus do Oeste'},
    {state: 'SC', value: 'Bom Retiro'},
    {state: 'SC', value: 'Bombinhas'},
    {state: 'SC', value: 'Botuverá'},
    {state: 'SC', value: 'Braço do Norte'},
    {state: 'SC', value: 'Braço do Trombudo'},
    {state: 'SC', value: 'Brunópolis'},
    {state: 'SC', value: 'Brusque'},
    {state: 'SC', value: 'Caçador'},
    {state: 'SC', value: 'Caibi'},
    {state: 'SC', value: 'Calmon'},
    {state: 'SC', value: 'Camboriú'},
    {state: 'SC', value: 'Campo Alegre'},
    {state: 'SC', value: 'Campo Belo do Sul'},
    {state: 'SC', value: 'Campo Erê'},
    {state: 'SC', value: 'Campos Novos'},
    {state: 'SC', value: 'Canelinha'},
    {state: 'SC', value: 'Canoinhas'},
    {state: 'SC', value: 'Capão Alto'},
    {state: 'SC', value: 'Capinzal'},
    {state: 'SC', value: 'Capivari de Baixo'},
    {state: 'SC', value: 'Catanduvas'},
    {state: 'SC', value: 'Caxambu do Sul'},
    {state: 'SC', value: 'Celso Ramos'},
    {state: 'SC', value: 'Cerro Negro'},
    {state: 'SC', value: 'Chapadão do Lageado'},
    {state: 'SC', value: 'Chapecó'},
    {state: 'SC', value: 'Cocal do Sul'},
    {state: 'SC', value: 'Concórdia'},
    {state: 'SC', value: 'Cordilheira Alta'},
    {state: 'SC', value: 'Coronel Freitas'},
    {state: 'SC', value: 'Coronel Martins'},
    {state: 'SC', value: 'Correia Pinto'},
    {state: 'SC', value: 'Corupá'},
    {state: 'SC', value: 'Criciúma'},
    {state: 'SC', value: 'Cunha Porã'},
    {state: 'SC', value: 'Cunhataí'},
    {state: 'SC', value: 'Curitibanos'},
    {state: 'SC', value: 'Descanso'},
    {state: 'SC', value: 'Dionísio Cerqueira'},
    {state: 'SC', value: 'Dona Emma'},
    {state: 'SC', value: 'Doutor Pedrinho'},
    {state: 'SC', value: 'Entre Rios'},
    {state: 'SC', value: 'Ermo'},
    {state: 'SC', value: 'Erval Velho'},
    {state: 'SC', value: 'Faxinal dos Guedes'},
    {state: 'SC', value: 'Flor do Sertão'},
    {state: 'SC', value: 'Florianópolis'},
    {state: 'SC', value: 'Formosa do Sul'},
    {state: 'SC', value: 'Forquilhinha'},
    {state: 'SC', value: 'Fraiburgo'},
    {state: 'SC', value: 'Frei Rogério'},
    {state: 'SC', value: 'Galvão'},
    {state: 'SC', value: 'Garopaba'},
    {state: 'SC', value: 'Garuva'},
    {state: 'SC', value: 'Gaspar'},
    {state: 'SC', value: 'Governador Celso Ramos'},
    {state: 'SC', value: 'Grão-Pará'},
    {state: 'SC', value: 'Gravatal'},
    {state: 'SC', value: 'Guabiruba'},
    {state: 'SC', value: 'Guaraciaba'},
    {state: 'SC', value: 'Guaramirim'},
    {state: 'SC', value: 'Guarujá do Sul'},
    {state: 'SC', value: 'Guatambu'},
    {state: 'SC', value: "Herval d'Oeste"},
    {state: 'SC', value: 'Ibiam'},
    {state: 'SC', value: 'Ibicaré'},
    {state: 'SC', value: 'Ibirama'},
    {state: 'SC', value: 'Içara'},
    {state: 'SC', value: 'Ilhota'},
    {state: 'SC', value: 'Imaruí'},
    {state: 'SC', value: 'Imbituba'},
    {state: 'SC', value: 'Imbuia'},
    {state: 'SC', value: 'Indaial'},
    {state: 'SC', value: 'Iomerê'},
    {state: 'SC', value: 'Ipira'},
    {state: 'SC', value: 'Iporã do Oeste'},
    {state: 'SC', value: 'Ipuaçu'},
    {state: 'SC', value: 'Ipumirim'},
    {state: 'SC', value: 'Iraceminha'},
    {state: 'SC', value: 'Irani'},
    {state: 'SC', value: 'Irati'},
    {state: 'SC', value: 'Irineópolis'},
    {state: 'SC', value: 'Itá'},
    {state: 'SC', value: 'Itaiópolis'},
    {state: 'SC', value: 'Itajaí'},
    {state: 'SC', value: 'Itapema'},
    {state: 'SC', value: 'Itapiranga'},
    {state: 'SC', value: 'Itapoá'},
    {state: 'SC', value: 'Ituporanga'},
    {state: 'SC', value: 'Jaborá'},
    {state: 'SC', value: 'Jacinto Machado'},
    {state: 'SC', value: 'Jaguaruna'},
    {state: 'SC', value: 'Jaraguá do Sul'},
    {state: 'SC', value: 'Jardinópolis'},
    {state: 'SC', value: 'Joaçaba'},
    {state: 'SC', value: 'Joinville'},
    {state: 'SC', value: 'José Boiteux'},
    {state: 'SC', value: 'Jupiá'},
    {state: 'SC', value: 'Lacerdópolis'},
    {state: 'SC', value: 'Lages'},
    {state: 'SC', value: 'Laguna'},
    {state: 'SC', value: 'Lajeado Grande'},
    {state: 'SC', value: 'Laurentino'},
    {state: 'SC', value: 'Lauro Müller'},
    {state: 'SC', value: 'Lebon Régis'},
    {state: 'SC', value: 'Leoberto Leal'},
    {state: 'SC', value: 'Lindóia do Sul'},
    {state: 'SC', value: 'Lontras'},
    {state: 'SC', value: 'Luiz Alves'},
    {state: 'SC', value: 'Luzerna'},
    {state: 'SC', value: 'Macieira'},
    {state: 'SC', value: 'Mafra'},
    {state: 'SC', value: 'Major Gercino'},
    {state: 'SC', value: 'Major Vieira'},
    {state: 'SC', value: 'Maracajá'},
    {state: 'SC', value: 'Maravilha'},
    {state: 'SC', value: 'Marema'},
    {state: 'SC', value: 'Massaranduba'},
    {state: 'SC', value: 'Matos Costa'},
    {state: 'SC', value: 'Meleiro'},
    {state: 'SC', value: 'Mirim Doce'},
    {state: 'SC', value: 'Modelo'},
    {state: 'SC', value: 'Mondaí'},
    {state: 'SC', value: 'Monte Carlo'},
    {state: 'SC', value: 'Monte Castelo'},
    {state: 'SC', value: 'Morro da Fumaça'},
    {state: 'SC', value: 'Morro Grande'},
    {state: 'SC', value: 'Navegantes'},
    {state: 'SC', value: 'Nova Erechim'},
    {state: 'SC', value: 'Nova Itaberaba'},
    {state: 'SC', value: 'Nova Trento'},
    {state: 'SC', value: 'Nova Veneza'},
    {state: 'SC', value: 'Novo Horizonte'},
    {state: 'SC', value: 'Orleans'},
    {state: 'SC', value: 'Otacílio Costa'},
    {state: 'SC', value: 'Ouro'},
    {state: 'SC', value: 'Ouro Verde'},
    {state: 'SC', value: 'Paial'},
    {state: 'SC', value: 'Painel'},
    {state: 'SC', value: 'Palhoça'},
    {state: 'SC', value: 'Palma Sola'},
    {state: 'SC', value: 'Palmeira'},
    {state: 'SC', value: 'Palmitos'},
    {state: 'SC', value: 'Papanduva'},
    {state: 'SC', value: 'Paraíso'},
    {state: 'SC', value: 'Passo de Torres'},
    {state: 'SC', value: 'Passos Maia'},
    {state: 'SC', value: 'Paulo Lopes'},
    {state: 'SC', value: 'Pedras Grandes'},
    {state: 'SC', value: 'Penha'},
    {state: 'SC', value: 'Peritiba'},
    {state: 'SC', value: 'Pescaria Brava'},
    {state: 'SC', value: 'Petrolândia'},
    {state: 'SC', value: 'Pinhalzinho'},
    {state: 'SC', value: 'Pinheiro Preto'},
    {state: 'SC', value: 'Piratuba'},
    {state: 'SC', value: 'Planalto Alegre'},
    {state: 'SC', value: 'Pomerode'},
    {state: 'SC', value: 'Ponte Alta'},
    {state: 'SC', value: 'Ponte Alta do Norte'},
    {state: 'SC', value: 'Ponte Serrada'},
    {state: 'SC', value: 'Porto Belo'},
    {state: 'SC', value: 'Porto União'},
    {state: 'SC', value: 'Pouso Redondo'},
    {state: 'SC', value: 'Praia Grande'},
    {state: 'SC', value: 'Presidente Castello Branco'},
    {state: 'SC', value: 'Presidente Getúlio'},
    {state: 'SC', value: 'Presidente Nereu'},
    {state: 'SC', value: 'Princesa'},
    {state: 'SC', value: 'Quilombo'},
    {state: 'SC', value: 'Rancho Queimado'},
    {state: 'SC', value: 'Rio das Antas'},
    {state: 'SC', value: 'Rio do Campo'},
    {state: 'SC', value: 'Rio do Oeste'},
    {state: 'SC', value: 'Rio do Sul'},
    {state: 'SC', value: 'Rio dos Cedros'},
    {state: 'SC', value: 'Rio Fortuna'},
    {state: 'SC', value: 'Rio Negrinho'},
    {state: 'SC', value: 'Rio Rufino'},
    {state: 'SC', value: 'Riqueza'},
    {state: 'SC', value: 'Rodeio'},
    {state: 'SC', value: 'Romelândia'},
    {state: 'SC', value: 'Salete'},
    {state: 'SC', value: 'Saltinho'},
    {state: 'SC', value: 'Salto Veloso'},
    {state: 'SC', value: 'Sangão'},
    {state: 'SC', value: 'Santa Cecília'},
    {state: 'SC', value: 'Santa Helena'},
    {state: 'SC', value: 'Santa Rosa de Lima'},
    {state: 'SC', value: 'Santa Rosa do Sul'},
    {state: 'SC', value: 'Santa Terezinha'},
    {state: 'SC', value: 'Santa Terezinha do Progresso'},
    {state: 'SC', value: 'Santiago do Sul'},
    {state: 'SC', value: 'Santo Amaro da Imperatriz'},
    {state: 'SC', value: 'São Bento do Sul'},
    {state: 'SC', value: 'São Bernardino'},
    {state: 'SC', value: 'São Bonifácio'},
    {state: 'SC', value: 'São Carlos'},
    {state: 'SC', value: 'São Cristóvão do Sul'},
    {state: 'SC', value: 'São Domingos'},
    {state: 'SC', value: 'São Francisco do Sul'},
    {state: 'SC', value: 'São João Batista'},
    {state: 'SC', value: 'São João do Itaperiú'},
    {state: 'SC', value: 'São João do Oeste'},
    {state: 'SC', value: 'São João do Sul'},
    {state: 'SC', value: 'São Joaquim'},
    {state: 'SC', value: 'São José'},
    {state: 'SC', value: 'São José do Cedro'},
    {state: 'SC', value: 'São José do Cerrito'},
    {state: 'SC', value: 'São Lourenço do Oeste'},
    {state: 'SC', value: 'São Ludgero'},
    {state: 'SC', value: 'São Martinho'},
    {state: 'SC', value: 'São Miguel da Boa Vista'},
    {state: 'SC', value: 'São Miguel do Oeste'},
    {state: 'SC', value: 'São Pedro de Alcântara'},
    {state: 'SC', value: 'Saudades'},
    {state: 'SC', value: 'Schroeder'},
    {state: 'SC', value: 'Seara'},
    {state: 'SC', value: 'Serra Alta'},
    {state: 'SC', value: 'Siderópolis'},
    {state: 'SC', value: 'Sombrio'},
    {state: 'SC', value: 'Sul Brasil'},
    {state: 'SC', value: 'Taió'},
    {state: 'SC', value: 'Tangará'},
    {state: 'SC', value: 'Tigrinhos'},
    {state: 'SC', value: 'Tijucas'},
    {state: 'SC', value: 'Timbé do Sul'},
    {state: 'SC', value: 'Timbó'},
    {state: 'SC', value: 'Timbó Grande'},
    {state: 'SC', value: 'Três Barras'},
    {state: 'SC', value: 'Treviso'},
    {state: 'SC', value: 'Treze de Maio'},
    {state: 'SC', value: 'Treze Tílias'},
    {state: 'SC', value: 'Trombudo Central'},
    {state: 'SC', value: 'Tubarão'},
    {state: 'SC', value: 'Tunápolis'},
    {state: 'SC', value: 'Turvo'},
    {state: 'SC', value: 'União do Oeste'},
    {state: 'SC', value: 'Urubici'},
    {state: 'SC', value: 'Urupema'},
    {state: 'SC', value: 'Urussanga'},
    {state: 'SC', value: 'Vargeão'},
    {state: 'SC', value: 'Vargem'},
    {state: 'SC', value: 'Vargem Bonita'},
    {state: 'SC', value: 'Vidal Ramos'},
    {state: 'SC', value: 'Videira'},
    {state: 'SC', value: 'Vitor Meireles'},
    {state: 'SC', value: 'Witmarsum'},
    {state: 'SC', value: 'Xanxerê'},
    {state: 'SC', value: 'Xavantina'},
    {state: 'SC', value: 'Xaxim'},
    {state: 'SC', value: 'Zortéa'},
    {state: 'PR', value: 'Abatiá'},
    {state: 'PR', value: 'Adrianópolis'},
    {state: 'PR', value: 'Agudos do Sul'},
    {state: 'PR', value: 'Almirante Tamandaré'},
    {state: 'PR', value: 'Altamira do Paraná'},
    {state: 'PR', value: 'Alto Paraíso'},
    {state: 'PR', value: 'Alto Paraná'},
    {state: 'PR', value: 'Alto Piquiri'},
    {state: 'PR', value: 'Altônia'},
    {state: 'PR', value: 'Alvorada do Sul'},
    {state: 'PR', value: 'Amaporã'},
    {state: 'PR', value: 'Ampére'},
    {state: 'PR', value: 'Anahy'},
    {state: 'PR', value: 'Andirá'},
    {state: 'PR', value: 'Ângulo'},
    {state: 'PR', value: 'Antonina'},
    {state: 'PR', value: 'Antônio Olinto'},
    {state: 'PR', value: 'Apucarana'},
    {state: 'PR', value: 'Arapongas'},
    {state: 'PR', value: 'Arapoti'},
    {state: 'PR', value: 'Arapuã'},
    {state: 'PR', value: 'Araruna'},
    {state: 'PR', value: 'Araucária'},
    {state: 'PR', value: 'Ariranha do Ivaí'},
    {state: 'PR', value: 'Assaí'},
    {state: 'PR', value: 'Assis Chateaubriand'},
    {state: 'PR', value: 'Astorga'},
    {state: 'PR', value: 'Atalaia'},
    {state: 'PR', value: 'Balsa Nova'},
    {state: 'PR', value: 'Bandeirantes'},
    {state: 'PR', value: 'Barbosa Ferraz'},
    {state: 'PR', value: 'Barra do Jacaré'},
    {state: 'PR', value: 'Barracão'},
    {state: 'PR', value: 'Bela Vista da Caroba'},
    {state: 'PR', value: 'Bela Vista do Paraíso'},
    {state: 'PR', value: 'Bituruna'},
    {state: 'PR', value: 'Boa Esperança'},
    {state: 'PR', value: 'Boa Esperança do Iguaçu'},
    {state: 'PR', value: 'Boa Ventura de São Roque'},
    {state: 'PR', value: 'Boa Vista da Aparecida'},
    {state: 'PR', value: 'Bocaiúva do Sul'},
    {state: 'PR', value: 'Bom Jesus do Sul'},
    {state: 'PR', value: 'Bom Sucesso'},
    {state: 'PR', value: 'Bom Sucesso do Sul'},
    {state: 'PR', value: 'Borrazópolis'},
    {state: 'PR', value: 'Braganey'},
    {state: 'PR', value: 'Brasilândia do Sul'},
    {state: 'PR', value: 'Cafeara'},
    {state: 'PR', value: 'Cafelândia'},
    {state: 'PR', value: 'Cafezal do Sul'},
    {state: 'PR', value: 'Califórnia'},
    {state: 'PR', value: 'Cambará'},
    {state: 'PR', value: 'Cambé'},
    {state: 'PR', value: 'Cambira'},
    {state: 'PR', value: 'Campina da Lagoa'},
    {state: 'PR', value: 'Campina do Simão'},
    {state: 'PR', value: 'Campina Grande do Sul'},
    {state: 'PR', value: 'Campo Bonito'},
    {state: 'PR', value: 'Campo do Tenente'},
    {state: 'PR', value: 'Campo Largo'},
    {state: 'PR', value: 'Campo Magro'},
    {state: 'PR', value: 'Campo Mourão'},
    {state: 'PR', value: 'Cândido de Abreu'},
    {state: 'PR', value: 'Candói'},
    {state: 'PR', value: 'Cantagalo'},
    {state: 'PR', value: 'Capanema'},
    {state: 'PR', value: 'Capitão Leônidas Marques'},
    {state: 'PR', value: 'Carambeí'},
    {state: 'PR', value: 'Carlópolis'},
    {state: 'PR', value: 'Cascavel'},
    {state: 'PR', value: 'Castro'},
    {state: 'PR', value: 'Catanduvas'},
    {state: 'PR', value: 'Centenário do Sul'},
    {state: 'PR', value: 'Cerro Azul'},
    {state: 'PR', value: 'Céu Azul'},
    {state: 'PR', value: 'Chopinzinho'},
    {state: 'PR', value: 'Cianorte'},
    {state: 'PR', value: 'Cidade Gaúcha'},
    {state: 'PR', value: 'Clevelândia'},
    {state: 'PR', value: 'Colombo'},
    {state: 'PR', value: 'Colorado'},
    {state: 'PR', value: 'Congonhinhas'},
    {state: 'PR', value: 'Conselheiro Mairinck'},
    {state: 'PR', value: 'Contenda'},
    {state: 'PR', value: 'Corbélia'},
    {state: 'PR', value: 'Cornélio Procópio'},
    {state: 'PR', value: 'Coronel Domingos Soares'},
    {state: 'PR', value: 'Coronel Vivida'},
    {state: 'PR', value: 'Corumbataí do Sul'},
    {state: 'PR', value: 'Cruz Machado'},
    {state: 'PR', value: 'Cruzeiro do Iguaçu'},
    {state: 'PR', value: 'Cruzeiro do Oeste'},
    {state: 'PR', value: 'Cruzeiro do Sul'},
    {state: 'PR', value: 'Cruzmaltina'},
    {state: 'PR', value: 'Curitiba'},
    {state: 'PR', value: 'Curiúva'},
    {state: 'PR', value: 'Diamante do Norte'},
    {state: 'PR', value: 'Diamante do Sul'},
    {state: 'PR', value: "Diamante d'Oeste"},
    {state: 'PR', value: 'Dois Vizinhos'},
    {state: 'PR', value: 'Douradina'},
    {state: 'PR', value: 'Doutor Camargo'},
    {state: 'PR', value: 'Doutor Ulysses'},
    {state: 'PR', value: 'Enéas Marques'},
    {state: 'PR', value: 'Engenheiro Beltrão'},
    {state: 'PR', value: 'Entre Rios do Oeste'},
    {state: 'PR', value: 'Esperança Nova'},
    {state: 'PR', value: 'Espigão Alto do Iguaçu'},
    {state: 'PR', value: 'Farol'},
    {state: 'PR', value: 'Faxinal'},
    {state: 'PR', value: 'Fazenda Rio Grande'},
    {state: 'PR', value: 'Fênix'},
    {state: 'PR', value: 'Fernandes Pinheiro'},
    {state: 'PR', value: 'Figueira'},
    {state: 'PR', value: 'Flor da Serra do Sul'},
    {state: 'PR', value: 'Floraí'},
    {state: 'PR', value: 'Floresta'},
    {state: 'PR', value: 'Florestópolis'},
    {state: 'PR', value: 'Flórida'},
    {state: 'PR', value: 'Formosa do Oeste'},
    {state: 'PR', value: 'Foz do Iguaçu'},
    {state: 'PR', value: 'Foz do Jordão'},
    {state: 'PR', value: 'Francisco Alves'},
    {state: 'PR', value: 'Francisco Beltrão'},
    {state: 'PR', value: 'General Carneiro'},
    {state: 'PR', value: 'Godoy Moreira'},
    {state: 'PR', value: 'Goioerê'},
    {state: 'PR', value: 'Goioxim'},
    {state: 'PR', value: 'Grandes Rios'},
    {state: 'PR', value: 'Guaíra'},
    {state: 'PR', value: 'Guairaçá'},
    {state: 'PR', value: 'Guamiranga'},
    {state: 'PR', value: 'Guapirama'},
    {state: 'PR', value: 'Guaporema'},
    {state: 'PR', value: 'Guaraci'},
    {state: 'PR', value: 'Guaraniaçu'},
    {state: 'PR', value: 'Guarapuava'},
    {state: 'PR', value: 'Guaraqueçaba'},
    {state: 'PR', value: 'Guaratuba'},
    {state: 'PR', value: 'Honório Serpa'},
    {state: 'PR', value: 'Ibaiti'},
    {state: 'PR', value: 'Ibema'},
    {state: 'PR', value: 'Ibiporã'},
    {state: 'PR', value: 'Icaraíma'},
    {state: 'PR', value: 'Iguaraçu'},
    {state: 'PR', value: 'Iguatu'},
    {state: 'PR', value: 'Imbaú'},
    {state: 'PR', value: 'Imbituva'},
    {state: 'PR', value: 'Inácio Martins'},
    {state: 'PR', value: 'Inajá'},
    {state: 'PR', value: 'Indianópolis'},
    {state: 'PR', value: 'Ipiranga'},
    {state: 'PR', value: 'Iporã'},
    {state: 'PR', value: 'Iracema do Oeste'},
    {state: 'PR', value: 'Irati'},
    {state: 'PR', value: 'Iretama'},
    {state: 'PR', value: 'Itaguajé'},
    {state: 'PR', value: 'Itaipulândia'},
    {state: 'PR', value: 'Itambaracá'},
    {state: 'PR', value: 'Itambé'},
    {state: 'PR', value: "Itapejara d'Oeste"},
    {state: 'PR', value: 'Itaperuçu'},
    {state: 'PR', value: 'Itaúna do Sul'},
    {state: 'PR', value: 'Ivaí'},
    {state: 'PR', value: 'Ivaiporã'},
    {state: 'PR', value: 'Ivaté'},
    {state: 'PR', value: 'Ivatuba'},
    {state: 'PR', value: 'Jaboti'},
    {state: 'PR', value: 'Jacarezinho'},
    {state: 'PR', value: 'Jaguapitã'},
    {state: 'PR', value: 'Jaguariaíva'},
    {state: 'PR', value: 'Jandaia do Sul'},
    {state: 'PR', value: 'Janiópolis'},
    {state: 'PR', value: 'Japira'},
    {state: 'PR', value: 'Japurá'},
    {state: 'PR', value: 'Jardim Alegre'},
    {state: 'PR', value: 'Jardim Olinda'},
    {state: 'PR', value: 'Jataizinho'},
    {state: 'PR', value: 'Jesuítas'},
    {state: 'PR', value: 'Joaquim Távora'},
    {state: 'PR', value: 'Jundiaí do Sul'},
    {state: 'PR', value: 'Juranda'},
    {state: 'PR', value: 'Jussara'},
    {state: 'PR', value: 'Kaloré'},
    {state: 'PR', value: 'Lapa'},
    {state: 'PR', value: 'Laranjal'},
    {state: 'PR', value: 'Laranjeiras do Sul'},
    {state: 'PR', value: 'Leópolis'},
    {state: 'PR', value: 'Lidianópolis'},
    {state: 'PR', value: 'Lindoeste'},
    {state: 'PR', value: 'Loanda'},
    {state: 'PR', value: 'Lobato'},
    {state: 'PR', value: 'Londrina'},
    {state: 'PR', value: 'Luiziana'},
    {state: 'PR', value: 'Lunardelli'},
    {state: 'PR', value: 'Lupionópolis'},
    {state: 'PR', value: 'Mallet'},
    {state: 'PR', value: 'Mamborê'},
    {state: 'PR', value: 'Mandaguaçu'},
    {state: 'PR', value: 'Mandaguari'},
    {state: 'PR', value: 'Mandirituba'},
    {state: 'PR', value: 'Manfrinópolis'},
    {state: 'PR', value: 'Mangueirinha'},
    {state: 'PR', value: 'Manoel Ribas'},
    {state: 'PR', value: 'Marechal Cândido Rondon'},
    {state: 'PR', value: 'Maria Helena'},
    {state: 'PR', value: 'Marialva'},
    {state: 'PR', value: 'Marilândia do Sul'},
    {state: 'PR', value: 'Marilena'},
    {state: 'PR', value: 'Mariluz'},
    {state: 'PR', value: 'Maringá'},
    {state: 'PR', value: 'Mariópolis'},
    {state: 'PR', value: 'Maripá'},
    {state: 'PR', value: 'Marmeleiro'},
    {state: 'PR', value: 'Marquinho'},
    {state: 'PR', value: 'Marumbi'},
    {state: 'PR', value: 'Matelândia'},
    {state: 'PR', value: 'Matinhos'},
    {state: 'PR', value: 'Mato Rico'},
    {state: 'PR', value: 'Mauá da Serra'},
    {state: 'PR', value: 'Medianeira'},
    {state: 'PR', value: 'Mercedes'},
    {state: 'PR', value: 'Mirador'},
    {state: 'PR', value: 'Miraselva'},
    {state: 'PR', value: 'Missal'},
    {state: 'PR', value: 'Moreira Sales'},
    {state: 'PR', value: 'Morretes'},
    {state: 'PR', value: 'Munhoz de Mello'},
    {state: 'PR', value: 'Nossa Senhora das Graças'},
    {state: 'PR', value: 'Nova Aliança do Ivaí'},
    {state: 'PR', value: 'Nova América da Colina'},
    {state: 'PR', value: 'Nova Aurora'},
    {state: 'PR', value: 'Nova Cantu'},
    {state: 'PR', value: 'Nova Esperança do Sudoeste'},
    {state: 'PR', value: 'Nova Esperança'},
    {state: 'PR', value: 'Nova Fátima'},
    {state: 'PR', value: 'Nova Laranjeiras'},
    {state: 'PR', value: 'Nova Londrina'},
    {state: 'PR', value: 'Nova Olímpia'},
    {state: 'PR', value: 'Nova Prata do Iguaçu'},
    {state: 'PR', value: 'Nova Santa Bárbara'},
    {state: 'PR', value: 'Nova Santa Rosa'},
    {state: 'PR', value: 'Nova Tebas'},
    {state: 'PR', value: 'Novo Itacolomi'},
    {state: 'PR', value: 'Ortigueira'},
    {state: 'PR', value: 'Ourizona'},
    {state: 'PR', value: 'Ouro Verde do Oeste'},
    {state: 'PR', value: 'Paiçandu'},
    {state: 'PR', value: 'Palmas'},
    {state: 'PR', value: 'Palmeira'},
    {state: 'PR', value: 'Palmital'},
    {state: 'PR', value: 'Palotina'},
    {state: 'PR', value: 'Paraíso do Norte'},
    {state: 'PR', value: 'Paranacity'},
    {state: 'PR', value: 'Paranaguá'},
    {state: 'PR', value: 'Paranapoema'},
    {state: 'PR', value: 'Paranavaí'},
    {state: 'PR', value: 'Pato Bragado'},
    {state: 'PR', value: 'Pato Branco'},
    {state: 'PR', value: 'Paula Freitas'},
    {state: 'PR', value: 'Paulo Frontin'},
    {state: 'PR', value: 'Peabiru'},
    {state: 'PR', value: 'Perobal'},
    {state: 'PR', value: 'Pérola'},
    {state: 'PR', value: "Pérola d'Oeste"},
    {state: 'PR', value: 'Piên'},
    {state: 'PR', value: 'Pinhais'},
    {state: 'PR', value: 'Pinhal de São Bento'},
    {state: 'PR', value: 'Pinhalão'},
    {state: 'PR', value: 'Pinhão'},
    {state: 'PR', value: 'Piraí do Sul'},
    {state: 'PR', value: 'Piraquara'},
    {state: 'PR', value: 'Pitanga'},
    {state: 'PR', value: 'Pitangueiras'},
    {state: 'PR', value: 'Planaltina do Paraná'},
    {state: 'PR', value: 'Planalto'},
    {state: 'PR', value: 'Ponta Grossa'},
    {state: 'PR', value: 'Pontal do Paraná'},
    {state: 'PR', value: 'Porecatu'},
    {state: 'PR', value: 'Porto Amazonas'},
    {state: 'PR', value: 'Porto Barreiro'},
    {state: 'PR', value: 'Porto Rico'},
    {state: 'PR', value: 'Porto Vitória'},
    {state: 'PR', value: 'Prado Ferreira'},
    {state: 'PR', value: 'Pranchita'},
    {state: 'PR', value: 'Presidente Castelo Branco'},
    {state: 'PR', value: 'Primeiro de Maio'},
    {state: 'PR', value: 'Prudentópolis'},
    {state: 'PR', value: 'Quarto Centenário'},
    {state: 'PR', value: 'Quatiguá'},
    {state: 'PR', value: 'Quatro Barras'},
    {state: 'PR', value: 'Quatro Pontes'},
    {state: 'PR', value: 'Quedas do Iguaçu'},
    {state: 'PR', value: 'Querência do Norte'},
    {state: 'PR', value: 'Quinta do Sol'},
    {state: 'PR', value: 'Quitandinha'},
    {state: 'PR', value: 'Ramilândia'},
    {state: 'PR', value: 'Rancho Alegre'},
    {state: 'PR', value: "Rancho Alegre d'Oeste"},
    {state: 'PR', value: 'Realeza'},
    {state: 'PR', value: 'Rebouças'},
    {state: 'PR', value: 'Renascença'},
    {state: 'PR', value: 'Reserva'},
    {state: 'PR', value: 'Reserva do Iguaçu'},
    {state: 'PR', value: 'Ribeirão Claro'},
    {state: 'PR', value: 'Ribeirão do Pinhal'},
    {state: 'PR', value: 'Rio Azul'},
    {state: 'PR', value: 'Rio Bom'},
    {state: 'PR', value: 'Rio Bonito do Iguaçu'},
    {state: 'PR', value: 'Rio Branco do Ivaí'},
    {state: 'PR', value: 'Rio Branco do Sul'},
    {state: 'PR', value: 'Rio Negro'},
    {state: 'PR', value: 'Rolândia'},
    {state: 'PR', value: 'Roncador'},
    {state: 'PR', value: 'Rondon'},
    {state: 'PR', value: 'Rosário do Ivaí'},
    {state: 'PR', value: 'Sabáudia'},
    {state: 'PR', value: 'Salgado Filho'},
    {state: 'PR', value: 'Salto do Itararé'},
    {state: 'PR', value: 'Salto do Lontra'},
    {state: 'PR', value: 'Santa Amélia'},
    {state: 'PR', value: 'Santa Cecília do Pavão'},
    {state: 'PR', value: 'Santa Cruz de Monte Castelo'},
    {state: 'PR', value: 'Santa Fé'},
    {state: 'PR', value: 'Santa Helena'},
    {state: 'PR', value: 'Santa Inês'},
    {state: 'PR', value: 'Santa Isabel do Ivaí'},
    {state: 'PR', value: 'Santa Izabel do Oeste'},
    {state: 'PR', value: 'Santa Lúcia'},
    {state: 'PR', value: 'Santa Maria do Oeste'},
    {state: 'PR', value: 'Santa Mariana'},
    {state: 'PR', value: 'Santa Mônica'},
    {state: 'PR', value: 'Santa Tereza do Oeste'},
    {state: 'PR', value: 'Santa Terezinha de Itaipu'},
    {state: 'PR', value: 'Santana do Itararé'},
    {state: 'PR', value: 'Santo Antônio da Platina'},
    {state: 'PR', value: 'Santo Antônio do Caiuá'},
    {state: 'PR', value: 'Santo Antônio do Paraíso'},
    {state: 'PR', value: 'Santo Antônio do Sudoeste'},
    {state: 'PR', value: 'Santo Inácio'},
    {state: 'PR', value: 'São Carlos do Ivaí'},
    {state: 'PR', value: 'São Jerônimo da Serra'},
    {state: 'PR', value: 'São João'},
    {state: 'PR', value: 'São João do Caiuá'},
    {state: 'PR', value: 'São João do Ivaí'},
    {state: 'PR', value: 'São João do Triunfo'},
    {state: 'PR', value: 'São Jorge do Ivaí'},
    {state: 'PR', value: 'São Jorge do Patrocínio'},
    {state: 'PR', value: "São Jorge d'Oeste"},
    {state: 'PR', value: 'São José da Boa Vista'},
    {state: 'PR', value: 'São José das Palmeiras'},
    {state: 'PR', value: 'São José dos Pinhais'},
    {state: 'PR', value: 'São Manoel do Paraná'},
    {state: 'PR', value: 'São Mateus do Sul'},
    {state: 'PR', value: 'São Miguel do Iguaçu'},
    {state: 'PR', value: 'São Pedro do Iguaçu'},
    {state: 'PR', value: 'São Pedro do Ivaí'},
    {state: 'PR', value: 'São Pedro do Paraná'},
    {state: 'PR', value: 'São Sebastião da Amoreira'},
    {state: 'PR', value: 'São Tomé'},
    {state: 'PR', value: 'Sapopema'},
    {state: 'PR', value: 'Sarandi'},
    {state: 'PR', value: 'Saudade do Iguaçu'},
    {state: 'PR', value: 'Sengés'},
    {state: 'PR', value: 'Serranópolis do Iguaçu'},
    {state: 'PR', value: 'Sertaneja'},
    {state: 'PR', value: 'Sertanópolis'},
    {state: 'PR', value: 'Siqueira Campos'},
    {state: 'PR', value: 'Sulina'},
    {state: 'PR', value: 'Tamarana'},
    {state: 'PR', value: 'Tamboara'},
    {state: 'PR', value: 'Tapejara'},
    {state: 'PR', value: 'Tapira'},
    {state: 'PR', value: 'Teixeira Soares'},
    {state: 'PR', value: 'Telêmaco Borba'},
    {state: 'PR', value: 'Terra Boa'},
    {state: 'PR', value: 'Terra Rica'},
    {state: 'PR', value: 'Terra Roxa'},
    {state: 'PR', value: 'Tibagi'},
    {state: 'PR', value: 'Tijucas do Sul'},
    {state: 'PR', value: 'Toledo'},
    {state: 'PR', value: 'Tomazina'},
    {state: 'PR', value: 'Três Barras do Paraná'},
    {state: 'PR', value: 'Tunas do Paraná'},
    {state: 'PR', value: 'Tuneiras do Oeste'},
    {state: 'PR', value: 'Tupãssi'},
    {state: 'PR', value: 'Turvo'},
    {state: 'PR', value: 'Ubiratã'},
    {state: 'PR', value: 'Umuarama'},
    {state: 'PR', value: 'União da Vitória'},
    {state: 'PR', value: 'Uniflor'},
    {state: 'PR', value: 'Uraí'},
    {state: 'PR', value: 'Ventania'},
    {state: 'PR', value: 'Vera Cruz do Oeste'},
    {state: 'PR', value: 'Verê'},
    {state: 'PR', value: 'Virmond'},
    {state: 'PR', value: 'Vitorino'},
    {state: 'PR', value: 'Wenceslau Braz'},
    {state: 'PR', value: 'Xambrê'}
];

export default citiesSul;
