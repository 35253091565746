/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {cleanNumber} from '@app/utils/myaux';

export default function WalletHeader({cards}) {
    const [capAloc, setCapAloc] = useState();
    const [roi, setRoi] = useState();
    const [profit, setProfit] = useState();

    const calcDesmont = (strategy) => {
        let totDesMont = 0;
        strategy.forEach((s) => {
            if (s.type === 'V') {
                totDesMont += cleanNumber(s.preco_desmont) * cleanNumber(s.qtd);
            } else {
                totDesMont +=
                    cleanNumber(s.preco_desmont) * cleanNumber(s.qtd) * -1;
            }
        });
        // return totMont;
        return totDesMont;
    };

    const calcLucro = (totMont, totDesm) => {
        let lucro = 0;
        // credito e credito
        if (totMont < 0 && totDesm < 0) {
            //  lucro
            totMont *= -1;
            lucro = totMont - totDesm;
        } else if (totMont < 0 && totDesm > 0) {
            totMont *= -1;
            if (totMont > totDesm) {
                lucro = totMont - totDesm;
            } else {
                // -> Prejuizo
                lucro = totMont - totDesm;
            }
        } else if (totMont > 0 && totDesm > 0) {
            // Prejuizo

            lucro = (totMont + totDesm) * -1;
        } else if (totMont > 0 && totDesm < 0) {
            // lucro

            totDesm *= -1;
            if (totMont < totDesm) {
                // -> lucro

                lucro = totDesm - totMont;
            } else {
                lucro = totDesm - totMont;
            }
        }
        return lucro;
    };

    const calcDataMont = (strategy) => {
        let myDate = new Date(strategy[0]?.date_mont);
        strategy.forEach((s) => {
            myDate = moment(s.date_mont).isBefore(myDate)
                ? s.date_mont
                : myDate;
        });
        return myDate;
    };

    const calcCredito = (strategy) => {
        let totMont = 0;
        let desMont = 0;
        strategy.forEach((s) => {
            if (s.type === 'V') {
                totMont += cleanNumber(s.preco_mont) * cleanNumber(s.qtd) * -1;
                desMont +=
                    cleanNumber(s.preco_desmont) * cleanNumber(s.qtd) * -1;
            } else {
                totMont += cleanNumber(s.preco_mont) * cleanNumber(s.qtd);
                desMont += cleanNumber(s.preco_desmont) * cleanNumber(s.qtd);
            }
        });
        if (desMont < 0) totMont = 0;
        return totMont;
    };
    const calcAloc = (strategy) => {
        let totMont = 0;
        strategy.forEach((s) => {
            if (s.type === 'V') {
                totMont += cleanNumber(s.preco_mont) * cleanNumber(s.qtd) * -1;
            } else {
                totMont += cleanNumber(s.preco_mont) * cleanNumber(s.qtd);
            }
        });

        return totMont;
    };
    const calcRoi = (strategy) => {
        let capital = calcAloc(strategy);
        if (capital < 0) {
            capital *= -1;
        }
        const lucro = calcLucro(calcAloc(strategy), calcDesmont(strategy));
        let tmp = 0;
        tmp = (lucro * 0.85) / capital;
        if (Number.isNaN(tmp)) {
            return 0;
        }
        return tmp;
    };

    useEffect(() => {
        if (cards) {
            const listTmp = [];
            cards.forEach((c) => {
                listTmp.push(c.Strategys);
            });
            setCapAloc(calcCredito(listTmp.flat()));
            setProfit(
                calcLucro(calcAloc(listTmp.flat()), calcDesmont(listTmp.flat()))
            );
            setRoi(calcRoi(listTmp.flat()));
        }
    }, [cards]);

    return capAloc && profit && roi ? (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontWeight: 700
                }}
            >
                <div>
                    <span> Cap. alocado: </span>
                    <span style={{color: '#e37039'}}>
                        {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            minimumFractionDigits: 2,
                            currency: 'BRL'
                        })
                            .format(capAloc)
                            .replace(',', '.')}
                    </span>
                </div>
                <div style={{marginLeft: '40px'}}>
                    <span>Lucro: </span>
                    <span style={{color: '#e37039'}}>
                        {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            minimumFractionDigits: 2,
                            currency: 'BRL'
                        })
                            .format(profit)
                            .replace(',', '.')}
                    </span>
                </div>
                <div style={{marginLeft: '40px'}}>
                    <span>ROI: </span>
                    <span style={{color: '#e37039'}}>
                        {new Intl.NumberFormat('pt-BR', {
                            style: 'percent',
                            minimumFractionDigits: 2,
                            currency: 'BRL'
                        })
                            .format(roi)
                            .replace(',', '.')}
                    </span>
                </div>
            </div>
        </>
    ) : (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    fontWeight: 700
                }}
            >
                <div>
                    <span> Cap. alocado: </span>
                    <span style={{color: '#e37039'}}>0.000.00</span>
                </div>
                <div style={{marginLeft: '40px'}}>
                    <span>Lucro: </span>
                    <span style={{color: '#e37039'}}>0.000.00</span>
                </div>
                <div style={{marginLeft: '40px'}}>
                    <span>ROI: </span>
                    <span style={{color: '#e37039'}}>0.00%</span>
                </div>
            </div>
        </>
    );
}
