/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, {useEffect, useState, useRef} from 'react';
import {Card, Modal} from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import {Grid} from '@material-ui/core';
import moment from 'moment';
import {cleanNumber} from '@app/utils/myaux';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import api from '@app/utils/api';
import NewStrategy from '@app/pages/wallet/strateggi/NewStrategy';
import Loading from '@app/components/loading/Loading';
import _, {initial} from 'lodash';

export default function RoexExercicio({
    titulo,
    icone,
    notify,
    card,
    cards,
    setCards,
    size
}) {
    const [tipo, setTipo] = useState([]);
    const [vencimento, setVencimento] = useState([]);
    const [initialValue, setInitialValue] = useState();
    const [strategies, setStrategies] = useState();

    // const stonks = ((1000 - 1) / 1) * 100;
    const useStyles = makeStyles(() =>
        createStyles({
            myGridVC: {
                minWidth: 15,
                maxWidth: 15,
                maxHeight: 15,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                textAlign: 'center',
                // cursor: 'pointer',
                border: '2px solid #c5c5c5'
            },
            myGridVCSelected: {
                minWidth: 15,
                maxWidth: 15,
                maxHeight: 15,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                textAlign: 'center',
                // cursor: 'pointer',
                backgroundColor: '#e37039',
                color: '#fff'
            },
            myGrid: {
                height: 38,
                width: 110,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                textAlign: 'center',
                border: '1px solid #000',
                borderRadius: '5px'
            },
            myGridSelected: {
                width: 85,
                height: 20,
                backgroundColor: '#e37039',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                textAlign: 'center',
                border: '1px solid #e37039',
                borderRadius: '2px',
                color: '#fff'
            },
            myTable: {}
        })
    );
    const classes = useStyles();
    const defaultFilters = {
        btnType: {call: true, put: true},
        btnModel: {europe: true, america: true},
        btnMoneyness: {ITM: false, ATM: false, OTM: false},
        strike: {min: 0, start: 0, end: 5000, max: 5000},
        strikeList: [],
        tikers: [],
        venc: []
    };

    const calcAloc = (strategy) => {
        let totMont = 0;
        strategy.forEach((s) => {
            if (s.type === 'V') {
                totMont += cleanNumber(s.preco_mont) * cleanNumber(s.qtd) * -1;
            } else {
                totMont += cleanNumber(s.preco_mont) * cleanNumber(s.qtd);
            }
        });

        return totMont;
    };

    const calcCredito = (strategy) => {
        let totMont = 0;
        let desMont = 0;
        strategy.forEach((s) => {
            if (s.type === 'V') {
                totMont += cleanNumber(s.preco_mont) * cleanNumber(s.qtd) * -1;
                desMont +=
                    cleanNumber(s.preco_desmont) * cleanNumber(s.qtd) * -1;
            } else {
                totMont += cleanNumber(s.preco_mont) * cleanNumber(s.qtd);
                desMont += cleanNumber(s.preco_desmont) * cleanNumber(s.qtd);
            }
        });
        if (desMont < 0) totMont = 0;
        return totMont;
    };

    const calcDesmont = (strategy) => {
        let totDesMont = 0;
        strategy.forEach((s) => {
            if (s.type === 'V') {
                totDesMont += cleanNumber(s.preco_desmont) * cleanNumber(s.qtd);
            } else {
                totDesMont +=
                    cleanNumber(s.preco_desmont) * cleanNumber(s.qtd) * -1;
            }
        });

        return totDesMont;
    };

    const calcLucro = (totMont, totDesm) => {
        let lucro = 0;
        if (totMont < 0 && totDesm < 0) {
            totMont *= -1;
            lucro = totMont - totDesm;
        } else if (totMont < 0 && totDesm > 0) {
            totMont *= -1;
            if (totMont > totDesm) {
                lucro = totMont - totDesm;
            } else {
                lucro = totMont - totDesm;
            }
        } else if (totMont > 0 && totDesm > 0) {
            lucro = (totMont + totDesm) * -1;
        } else if (totMont > 0 && totDesm < 0) {
            totDesm *= -1;
            if (totMont < totDesm) {
                lucro = totDesm - totMont;
            } else {
                lucro = totDesm - totMont;
            }
        }
        return lucro;
    };

    const calcRoi = (strategy) => {
        let capital = calcAloc(strategy);
        if (capital < 0) {
            capital *= -1;
        }
        const lucro = calcLucro(calcAloc(strategies), calcDesmont(strategies));
        let tmp = 0;
        // if (lucro >= 0) {
        tmp = (lucro * 0.85) / capital;
        // } else {
        //     tmp = 0;
        // }
        if (Number.isNaN(tmp)) {
            return 0;
        }
        return tmp;
    };

    useEffect(() => {
        if (card) {
            const copy = _.cloneDeep(card.Strategys);
            const list = [];
            copy.forEach((c) => {
                if (c.must_updated) list.push(c);
            });
            calcAloc(list);
            calcDesmont(list);
            const listType = [];
            list.forEach((t) => {
                listType.push(t.type);
            });
            setTipo(listType);

            const listVenc = [];
            list.forEach((v) => {
                listVenc.push(v.venc);
            });
            setVencimento(listVenc);
            setInitialValue(_.cloneDeep(list));
            setStrategies(list);
        }
    }, [card]);

    function Total() {
        if (calcAloc) {
            if (1 > 0) {
                return (
                    <td
                        style={{
                            fontSize: 12,
                            paddingTop: '5px',
                            display: 'flex'
                        }}
                    >
                        <ReactTooltip />
                        CRÉDITO
                        <ion-icon
                            data-tip="CRÉDITO - Para rolar ou remontar a estrutura registrada
                            houve recebimento financeiro."
                            style={{
                                marginBottom: 2,
                                fontSize: 12,
                                color: '#d36233',
                                cursor: 'pointer'
                            }}
                            name="help-circle-outline"
                        />
                        <span
                            style={{
                                borderBottom: '1px dotted #000',
                                width: '100%',
                                display: 'flex'
                            }}
                        />
                    </td>
                );
            }
            return (
                <td
                    style={{
                        fontSize: 12,
                        paddingTop: '5px',
                        display: 'flex'
                    }}
                >
                    <ReactTooltip />
                    DÉBITO
                    <ion-icon
                        data-tip="DEBITO - Para rolar ou remontar a estrutura é necessário
                        desembolso financeiro.
                        "
                        style={{
                            marginBottom: 2,
                            fontSize: 12,
                            color: '#d36233',
                            cursor: 'pointer'
                        }}
                        name="help-circle-outline"
                    />
                    <span
                        style={{
                            borderBottom: '1px dotted #000',
                            width: '100%',
                            display: 'flex'
                        }}
                    />
                </td>
            );
        }
    }

    function Roi() {
        if (calcRoi) {
            if (1 > 0) {
                return (
                    (
                        <span
                            style={{
                                color: '#e37039',
                                paddingRight: 5,
                                paddingLeft: 2
                            }}
                        >
                            {/* + */}
                        </span>
                    ),
                    new Intl.NumberFormat('pt-BR', {
                        style: 'percent',
                        minimumFractionDigits: 2,
                        currency: 'BRL'
                    }).format(calcRoi(strategies))
                );
            }
            return (
                (
                    <span
                        style={{
                            color: '#e37039',
                            paddingRight: 5,
                            paddingLeft: 2
                        }}
                    >
                        -
                    </span>
                ),
                new Intl.NumberFormat('pt-BR', {
                    style: 'percent',
                    minimumFractionDigits: 2,
                    currency: 'BRL'
                }).format(calcRoi(strategies))
            );
        }
    }

    function TableStrategiesActual() {
        if (initialValue) {
            return initialValue.map((t, i) => (
                <tr
                    key={t.id}
                    style={{
                        height: '30px',
                        borderBottom: ' 0.5px solid lightgrey',
                        borderTop: '0.5px solid lightgrey',
                        fontFamily: 'Roboto',
                        fontWeight: 400
                        // borderRight: '0.5px solid lightgrey'
                        // marginRight: -10
                    }}
                >
                    <td
                        key={t.id}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-evenly',
                            marginTop: '4px'
                        }}
                    >
                        {tipo[i] === 'V' ? (
                            <Grid
                                className={classes.myGridVC}
                                style={{
                                    fontSize: 12
                                    // fontWeight: 700
                                }}
                            >
                                V
                            </Grid>
                        ) : (
                            <Grid
                                className={classes.myGridVCSelected}
                                style={{
                                    fontSize: 12
                                    // fontWeight: 700
                                }}
                            >
                                C
                            </Grid>
                        )}
                        <span
                            style={{
                                // marginRight: '10px ',
                                width: '96px',
                                fontSize: 15,
                                textAlign: 'center'
                                // fontWeight: 'bold'
                            }}
                        >
                            {t.ticker}
                            <ion-icon
                                onClick={() => notify(t.ticker)}
                                style={{
                                    fontSize: 12,
                                    color: '#d36233',
                                    cursor: 'pointer',
                                    marginLeft: 5
                                }}
                                name="copy-outline"
                            />
                        </span>
                        <span
                            style={{
                                fontSize: 15
                                // fontWeight: 'bold'
                            }}
                        >
                            {t.strike ? t.strike.replace('.', ',') : null}
                        </span>
                    </td>
                </tr>
            ));
        }
    }

    return (
        <>
            <Card
                style={{
                    width: size <= 1366 ? '26rem' : '30rem',
                    padding: 0,
                    maxHeight: '40rem',
                    height: '100%',
                    marginBottom: '10px'
                }}
            >
                <Card.Body
                    style={{
                        color: '#000',
                        paddingRight: 0,
                        paddingLeft: 0
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#d36233',
                            marginLeft: 'auto'
                        }}
                    >
                        <ion-icon
                            name={icone}
                            style={{
                                display: 'flex',
                                paddingRight: '15px',
                                paddingLeft: '25px',
                                color: '#d36233',
                                fontSize: '20px'
                            }}
                        />

                        <span style={{fontSize: 19}}>{titulo}</span>
                        {/* {!card ? (
                            <ion-icon
                                // onClick={() => handleOpen(card)}
                                name="save-outline"
                                style={{
                                    display: 'flex',
                                    marginLeft: 'auto',
                                    paddingRight: '10px',
                                    fontSize: '18px',
                                    cursor: 'pointer',
                                    color: '#e37039'
                                }}
                            />
                        ) : (
                            <ion-icon
                                onClick={() => handleOpen(card)}
                                name="save-outline"
                                style={{
                                    display: 'flex',
                                    marginLeft: 'auto',
                                    paddingRight: '10px',
                                    fontSize: '18px',
                                    cursor: 'pointer',
                                    color: '#e37039'
                                }}
                            />
                        )} */}
                    </div>
                    <div>
                        <div
                            style={{
                                justifyContent: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: 30,
                                marginBottom: 10
                            }}
                        >
                            <div>
                                {!card ? (
                                    <Grid
                                        className={classes.myGrid}
                                        style={{fontSize: 16, fontWeight: 600}}
                                    >
                                        --/--/----
                                    </Grid>
                                ) : (
                                    <Grid
                                        className={classes.myGrid}
                                        style={{fontSize: 16, fontWeight: 600}}
                                    >
                                        {moment(vencimento[0]).format(
                                            'DD/MM/YYYY'
                                        )}
                                    </Grid>
                                )}
                            </div>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <ReactTooltip />

                        <table
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: 200
                            }}
                        >
                            <thead>
                                <tr
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: 12,
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <td />
                                    <td style={{marginLeft: '60px'}}>
                                        TICKER
                                        <ion-icon
                                            data-tip="Ticker - Abreviação utilizada para identificar exclusivamente contratos de opções negociadas publicamente."
                                            style={{
                                                marginBottom: 5,
                                                fontSize: 12,
                                                color: '#d36233',
                                                cursor: 'pointer'
                                            }}
                                            name="help-circle-outline"
                                        />
                                    </td>
                                    <td style={{marginLeft: '40px'}}>
                                        STRIKE
                                        <ion-icon
                                            data-tip="Strike - Preço de exercício do contrato de opções."
                                            style={{
                                                marginBottom: 5,
                                                fontSize: 12,
                                                color: '#d36233',
                                                cursor: 'pointer'
                                            }}
                                            name="help-circle-outline"
                                        />
                                    </td>
                                </tr>
                            </thead>
                            {!initialValue ? (
                                <tbody>
                                    <tr
                                        style={{
                                            height: '20px',
                                            borderBottom:
                                                ' 0.5px solid lightgrey',
                                            borderTop: '0.5px solid lightgrey'
                                            // borderRight: '0.5px solid lightgrey'
                                            // marginRight: -10
                                        }}
                                    >
                                        <td
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-evenly',
                                                marginTop: '4px'
                                            }}
                                        >
                                            <Grid
                                                className={classes.myGridVC}
                                                style={{
                                                    fontSize: 12,
                                                    fontWeight: 500
                                                }}
                                            >
                                                C
                                            </Grid>
                                            <span
                                                style={{
                                                    // marginLeft: '20px ',
                                                    width: '85px',
                                                    fontSize: 14,
                                                    fontWeight: 'bold',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                Empty
                                                <ion-icon
                                                    onClick={() => notify()}
                                                    style={{
                                                        fontSize: 12,
                                                        color: '#d36233',
                                                        cursor: 'pointer',
                                                        marginLeft: 5
                                                    }}
                                                    name="copy-outline"
                                                />
                                            </span>
                                            <span
                                                style={{
                                                    width: '35px',
                                                    fontSize: 14,
                                                    fontWeight: 'bold'
                                                }}
                                            >
                                                00,00
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody>
                                    <TableStrategiesActual />
                                </tbody>
                            )}
                        </table>
                    </div>
                    <table
                        style={{
                            marginTop: 20,
                            fontWeight: 'bold',
                            // width: '600px',
                            display: 'flex',
                            textAlign: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <thead>
                            <tr>
                                <td
                                    style={{
                                        fontSize: 12,
                                        // marginRight: 190
                                        display: 'flex',
                                        width: '330px'
                                        // borderBottom: '1px dotted #000'
                                        // minWidth: 120,
                                        // maxWidth: 260
                                    }}
                                >
                                    CAPITAL ALOCADO
                                    <ion-icon
                                        data-tip="Cap alocado - Abreviação utilizada para identificar exclusivamente contratos de opções negociadas publicamente."
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            cursor: 'pointer'
                                        }}
                                        name="help-circle-outline"
                                    />
                                    <span
                                        style={{
                                            borderBottom: '1px dotted #000',
                                            width: 220,
                                            display: 'flex'
                                        }}
                                    />
                                </td>
                                <td
                                    style={{
                                        fontSize: 12,
                                        color: '#d36233',
                                        fontFamily: 'Roboto'
                                    }}
                                >
                                    {!initialValue
                                        ? 'R$ 000,00'
                                        : new Intl.NumberFormat('pt-BR', {
                                              style: 'currency',
                                              currency: 'BRL'
                                          })
                                              .format(calcCredito(initialValue))
                                              .replace('.', '')
                                              .replace('-', '')}
                                </td>
                            </tr>
                            <tr>
                                {!initialValue ? (
                                    <td
                                        style={{
                                            fontSize: 12,
                                            paddingTop: '5px',
                                            display: 'flex'
                                        }}
                                    >
                                        <ReactTooltip />
                                        CRÉDITO
                                        <ion-icon
                                            data-tip="Crédito"
                                            style={{
                                                marginBottom: 2,
                                                fontSize: 12,
                                                color: '#d36233',
                                                cursor: 'pointer'
                                            }}
                                            name="help-circle-outline"
                                        />
                                        <span
                                            style={{
                                                borderBottom: '1px dotted #000',
                                                width: '100%',
                                                display: 'flex'
                                            }}
                                        />
                                    </td>
                                ) : (
                                    <Total card={initialValue} />
                                )}
                                <td
                                    style={{
                                        fontSize: 12,
                                        color: '#d36233',
                                        fontFamily: 'Roboto'
                                    }}
                                >
                                    {!initialValue
                                        ? 'R$ 000,00'
                                        : new Intl.NumberFormat('pt-BR', {
                                              style: 'currency',
                                              currency: 'BRL'
                                          })
                                              .format(calcAloc(initialValue))
                                              .replace('.', '')
                                              .replace('-', '')}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        fontSize: 12,
                                        paddingTop: '5px',
                                        display: 'flex'
                                    }}
                                >
                                    {titulo}
                                    <ion-icon
                                        data-tip="EXERCíCIO - Resumo da estrutura se levada até o
                                        exercício."
                                        style={{
                                            marginBottom: 5,
                                            fontSize: 14,
                                            color: '#d36233',
                                            cursor: 'pointer'
                                        }}
                                        name="help-circle-outline"
                                    />
                                    <span
                                        style={{
                                            borderBottom: '1px dotted #000',
                                            width: '100%',
                                            display: 'flex'
                                        }}
                                    />
                                </td>
                                <td
                                    style={{
                                        fontSize: 12,
                                        color: '#d36233',
                                        fontFamily: 'Roboto'
                                    }}
                                >
                                    R${' '}
                                    {!initialValue
                                        ? '000,00'
                                        : calcLucro(
                                              calcAloc(initialValue),
                                              calcDesmont(initialValue)
                                          )
                                              .toFixed(2)
                                              .replace('.', ',')}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        fontSize: 12,
                                        paddingTop: '5px',
                                        display: 'flex'
                                    }}
                                >
                                    ROI
                                    <ion-icon
                                        data-tip='"Return over investment" - Retorno sobre o investimento, ou quanto de lucro você obteve com o capital alocado.'
                                        style={{
                                            marginBottom: 5,
                                            fontSize: 12,
                                            color: '#d36233',
                                            cursor: 'pointer'
                                        }}
                                        name="help-circle-outline"
                                    />
                                    <span
                                        style={{
                                            borderBottom: '1px dotted #000',
                                            width: '100%',
                                            display: 'flex'
                                        }}
                                    />
                                </td>
                                <td
                                    style={{
                                        fontSize: 12,
                                        color: '#d36233',
                                        fontFamily: 'Roboto'
                                    }}
                                >
                                    {!initialValue ? '0.00%' : <Roi />}
                                </td>
                            </tr>
                        </thead>
                    </table>
                </Card.Body>
            </Card>
        </>
    );
}
