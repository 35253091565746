// eslint-disable-next-line no-unused-vars
import React, {useState} from 'react';

export function useFormInput(initialValue = '') {
    const [value, setValue] = useState(initialValue);

    function handleChange(e) {
        setValue(e.target.value);
    }

    return {
        value,
        onChange: handleChange
    };
}

export function useLowercaseFormInput(initialValue = '') {
    const [value, setValue] = useState(initialValue);

    function handleChange(e) {
        setValue(e.target.value.toLowerCase());
    }

    return {
        value,
        onChange: handleChange
    };
}

export function useNumericFormInput(initialValue = '') {
    const [value, setValue] = useState(initialValue);

    function handleChange(e) {
        // eslint-disable-next-line no-shadow
        let {value} = e.target;
        value = value.replace(/[^0-9]/g, '');
        setValue(value);
    }

    return {
        value,
        onChange: handleChange
    };
}

export function useFormCheckbox(initialChecked = false) {
    const [checked, setChecked] = useState(initialChecked);

    function handleChange(e) {
        setChecked(e.target.checked);
    }

    return {
        checked,
        onChange: handleChange
    };
}

export function useUsernameFormInput(initialValue = '') {
    const [value, setValue] = useState(initialValue);

    function handleChange(e) {
        const name = e.target.value.toLowerCase();
        setValue(name);
    }

    return {
        value,
        onChange: handleChange
    };
}

export function useFullnameFormInput(initialValue = '') {
    const [value, setValue] = useState(initialValue);

    function handleChange(e) {
        let name = e.target.value;
        name = name
            .replace(/ +/g, ' ')
            .replace(/'+/g, "'")
            .replace(/,+/g, ',')
            .replace(/\.+/g, '.')
            .replace(/-+/g, '-')
            .replace(/ [',.-]+/g, ' ');
        setValue(name);
    }

    return {
        value,
        onChange: handleChange
    };
}
