/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect, useRef, useContext} from 'react';
import {Card, Image} from 'react-bootstrap';
import {useMercadopago} from 'react-sdk-mercadopago';
import api from '@app/utils/api';
import {useHistory} from 'react-router-dom';
import {
    Modal,
    Dialog,
    DialogContent,
    DialogActions,
    Typography
} from '@material-ui/core';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import Login from '@app/pages/Login/Login';
import routing from '@app/routes/routing';
import botaoComecar from 'src/img/botaoComecar.avif';
import botaoComecarHover from 'src/img/botaoComecarHover.avif';
import botaoAssinar from 'src/img/botaoAssinar.avif';
import botaoAssinarHover from 'src/img/botaoAssinarHover.avif';
import Loading from '@app/components/loading/Loading';
import PlanosPayment from '@app/components/PlanosPayment';
import CheckoutMP from '../components/CheckoutMP';
import titulo from '../img/planosAssinaturasOpti.avif';
import MP from '../img/mercado-pago.png';
import logo from '../img/OPTI_logo1_claro.avif';

import './Planos.css';

function Planos() {
    const useStyles = makeStyles((theme) =>
        createStyles({
            logo: {
                display: 'flex',

                marginLeft: '20%',
                width: '65%'
            },
            title: {
                cursor: 'pointer',
                margin: 30,
                color: '#0e0b0f',
                fontWeight: 'bold'
            },
            modal: {
                outline: 0
            }
        })
    );

    const history = useHistory();
    const mp = useMercadopago.v2(
        'APP_USR-f19c30ce-4a67-43bd-91e2-cc3cf856391b',
        {
            locale: 'pt-BR'
        }
    );

    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openCheck, setOpenCheck] = useState(false);
    const [loginBtn, setLoginBtn] = useState(false);
    const [planoSelected, setPlanoSelected] = useState();
    const [openDialog, setOpenDialog] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpenCheck(true);
    };

    const emptyString = () => {
        return '';
    };

    /*  const handlePlano = (id) => {
        if (id === 'mensal')
            window.open(
                'https://www.mercadopago.com.br/subscriptions/checkout?preapproval_plan_id=2c9380848a85cc78018a8f0507bf062e'
            );
        else if (id === 'anual')
            window.open(
                'https://www.mercadopago.com.br/subscriptions/checkout?preapproval_plan_id=2c9380848d698d98018d89a8c79915d3'
            );
        else
            window.open(
                'https://www.mercadopago.com.br/subscriptions/checkout?preapproval_plan_id=2c9380848d698da5018d6a6241c400ef'
            );
    }; */

    return (
        <>
            <Typography
                variant="inherit"
                className={classes.title}
                onClick={() => setOpenDialog(true)}
            >
                PLANOS
            </Typography>
            <Modal
                disableEnforceFocus
                open={openDialog}
                PaperProps={{
                    style: {
                        backgroundColor: 'rgba(211, 211, 211, 0.4)',
                        boxShadow: 'none'
                    }
                }}
                className={classes.modal}
                style={{
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    outline: 0,
                    backdropFilter: 'blur(5px)'
                }}
            >
                <Card
                    style={{
                        backgroundColor: 'rgba(211, 211, 211, 0.4)',
                        height: '38rem',
                        borderRadius: '20px'
                    }}
                    className={classes.modal}
                >
                    <div
                        style={{
                            textAlign: 'right',
                            fontSize: 32,
                            marginRight: '5px',
                            marginTop: '-15px'
                        }}
                    >
                        <ion-icon
                            style={{
                                backgroundColor: '#e37039',
                                color: '#fff',
                                borderRadius: '50%',
                                cursor: 'pointer'
                            }}
                            onClick={() => setOpenDialog(false)}
                            name="close-circle-outline"
                        />
                    </div>
                    <Card.Body style={{fontFamily: 'Roboto'}}>
                        <div
                            style={{display: 'flex', justifyContent: 'center'}}
                        >
                            <img alt="" style={{width: '900px'}} src={titulo} />
                        </div>
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    margin: '15px 15px '
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Card
                                            style={{
                                                height: '21rem',
                                                width: '22rem',
                                                marginBottom: 10,
                                                justifyContent: 'center',
                                                background: '#2c2c2c'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <div>
                                                    <div
                                                        style={{
                                                            transform:
                                                                'rotate(270deg)',
                                                            textAlign: 'center',
                                                            border: '0.2px solid white',
                                                            borderRadius:
                                                                '30px',
                                                            width: '21.5rem',
                                                            height: '2.3rem',
                                                            marginRight:
                                                                '-200px',
                                                            marginLeft:
                                                                '-160px',
                                                            fontSize: 26,
                                                            fontWeight: 700,
                                                            background: '#fff',
                                                            color: '#e37039',
                                                            letterSpacing:
                                                                '5px',
                                                            fontFamily:
                                                                'Text me one'
                                                        }}
                                                    >
                                                        GRATUITO
                                                    </div>
                                                </div>
                                                <div>
                                                    <div
                                                        style={{
                                                            alignItems:
                                                                'center',
                                                            height: '138px',
                                                            margin: '100px 0px 100px 50px'
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                fontSize: 20,
                                                                color: '#fff'
                                                            }}
                                                        >
                                                            <p>
                                                                • TELAS
                                                                PERSONALIZAVEIS
                                                            </p>
                                                            <p>
                                                                • ATÉ 3 ATIVOS
                                                                FAVORITOS
                                                            </p>
                                                            <p>
                                                                • CARTEIRA COM
                                                                ATÉ 3 ESTRUTURAS
                                                            </p>
                                                        </div>
                                                        <div
                                                            style={{
                                                                color: '#e37039',
                                                                textAlign:
                                                                    'right',
                                                                fontSize: 28,
                                                                fontWeight: 600,
                                                                marginRight:
                                                                    '20px'
                                                            }}
                                                        >
                                                            É GRATIS MESMO{' '}
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            marginLeft: '100px'
                                                        }}
                                                    >
                                                        {api.currentSession ? (
                                                            <button
                                                                type="button"
                                                                style={{
                                                                    backgroundColor:
                                                                        'transparent',
                                                                    border: 0
                                                                }}
                                                                onClick={() =>
                                                                    history.push(
                                                                        routing.dashboard
                                                                    )
                                                                }
                                                            >
                                                                <img
                                                                    src={
                                                                        botaoComecar
                                                                    }
                                                                    alt=""
                                                                    width="150px"
                                                                    onFocus={
                                                                        emptyString
                                                                    }
                                                                    onBlur={
                                                                        emptyString
                                                                    }
                                                                    onMouseOver={(
                                                                        e
                                                                    ) => {
                                                                        e.currentTarget.src =
                                                                            botaoComecarHover;
                                                                    }}
                                                                    onMouseOut={(
                                                                        e
                                                                    ) => {
                                                                        e.currentTarget.src =
                                                                            botaoComecar;
                                                                    }}
                                                                />
                                                            </button>
                                                        ) : (
                                                            <button
                                                                type="button"
                                                                style={{
                                                                    backgroundColor:
                                                                        'transparent',
                                                                    border: 0
                                                                }}
                                                                onClick={() => {
                                                                    setOpen(
                                                                        true
                                                                    );
                                                                    setLoginBtn(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    src={
                                                                        botaoComecar
                                                                    }
                                                                    alt=""
                                                                    width="150px"
                                                                    onFocus={
                                                                        emptyString
                                                                    }
                                                                    onBlur={
                                                                        emptyString
                                                                    }
                                                                    onMouseOver={(
                                                                        e
                                                                    ) => {
                                                                        e.currentTarget.src =
                                                                            botaoComecarHover;
                                                                    }}
                                                                    onMouseOut={(
                                                                        e
                                                                    ) => {
                                                                        e.currentTarget.src =
                                                                            botaoComecar;
                                                                    }}
                                                                />
                                                            </button>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginLeft: '60px'
                                    }}
                                >
                                    <Card
                                        style={{
                                            height: '21rem',
                                            width: '22rem',
                                            marginBottom: 10,
                                            justifyContent: 'center',
                                            background: '#2c2c2c'
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <div>
                                                <div
                                                    style={{
                                                        transform:
                                                            'rotate(270deg)',
                                                        textAlign: 'center',
                                                        border: '0.2px solid white',
                                                        borderRadius: '30px',
                                                        width: '21.5rem',
                                                        height: '2.3rem',
                                                        marginRight: '-200px',
                                                        marginLeft: '-160px',
                                                        fontSize: 26,
                                                        fontWeight: 700,
                                                        background: '#fff',
                                                        color: '#e37039',
                                                        letterSpacing: '5px',
                                                        fontFamily:
                                                            'Text me one'
                                                    }}
                                                >
                                                    SARDINHA MENSAL
                                                </div>
                                            </div>
                                            <div>
                                                <div
                                                    style={{
                                                        alignItems: 'center',
                                                        height: '140px',
                                                        margin: '100px 0px 100px 50px'
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            fontSize: 20,
                                                            color: '#fff'
                                                        }}
                                                    >
                                                        <p>
                                                            • TELAS
                                                            PERSONALIZAVEIS
                                                        </p>
                                                        <p>
                                                            • ATIVOS FAVORITOS
                                                            ILIMITADOS
                                                        </p>
                                                        <p>
                                                            • CARTEIRA E
                                                            ESTRUTURAS
                                                            ILIMITADAS
                                                        </p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            color: '#e37039',
                                                            textAlign: 'right',
                                                            fontSize: 28,
                                                            fontWeight: 600,
                                                            marginRight: '20px'
                                                        }}
                                                    >
                                                        R$ 9,90 / mês*
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        marginLeft: '105px'
                                                    }}
                                                >
                                                    {/* <button
                                    type="button"
                                    onClick={() => checkoutAssinatura.open()}
                                >
                                    teste
                                </button> */}

                                                    {api.currentSession ? (
                                                        <button
                                                            type="button"
                                                            style={{
                                                                backgroundColor:
                                                                    'transparent',
                                                                border: 0
                                                            }}
                                                            onClick={() => {
                                                                setPlanoSelected(
                                                                    'mensal'
                                                                );
                                                                handleOpen();
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    botaoAssinar
                                                                }
                                                                alt=""
                                                                width="150px"
                                                                onFocus={
                                                                    emptyString
                                                                }
                                                                onBlur={
                                                                    emptyString
                                                                }
                                                                onMouseOver={(
                                                                    e
                                                                ) => {
                                                                    e.currentTarget.src =
                                                                        botaoAssinarHover;
                                                                }}
                                                                onMouseOut={(
                                                                    e
                                                                ) => {
                                                                    e.currentTarget.src =
                                                                        botaoAssinar;
                                                                }}
                                                            />
                                                        </button>
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            style={{
                                                                backgroundColor:
                                                                    'transparent',
                                                                border: 0
                                                            }}
                                                            onClick={() =>
                                                                setOpen(true)
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    botaoAssinar
                                                                }
                                                                alt=""
                                                                width="150px"
                                                                onFocus={
                                                                    emptyString
                                                                }
                                                                onBlur={
                                                                    emptyString
                                                                }
                                                                onMouseOver={(
                                                                    e
                                                                ) => {
                                                                    e.currentTarget.src =
                                                                        botaoAssinarHover;
                                                                }}
                                                                onMouseOut={(
                                                                    e
                                                                ) => {
                                                                    e.currentTarget.src =
                                                                        botaoAssinar;
                                                                }}
                                                            />
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginLeft: '60px'
                                    }}
                                >
                                    <Card
                                        style={{
                                            height: '21rem',
                                            width: '22rem',
                                            marginBottom: 10,
                                            justifyContent: 'center',
                                            background: '#2c2c2c'
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <div>
                                                <div
                                                    style={{
                                                        transform:
                                                            'rotate(270deg)',
                                                        textAlign: 'center',
                                                        border: '0.2px solid white',
                                                        borderRadius: '30px',
                                                        width: '21.5rem',
                                                        height: '2.3rem',
                                                        marginRight: '-200px',
                                                        marginLeft: '-160px',
                                                        fontSize: 26,
                                                        fontWeight: 700,
                                                        background: '#fff',
                                                        color: '#e37039',
                                                        letterSpacing: '5px',
                                                        fontFamily:
                                                            'Text me one'
                                                    }}
                                                >
                                                    SARDINHA ANUAL
                                                </div>
                                            </div>
                                            <div>
                                                <div
                                                    style={{
                                                        alignItems: 'center',
                                                        height: '140px',
                                                        margin: '100px 0px 100px 50px'
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            fontSize: 20,
                                                            color: '#fff'
                                                        }}
                                                    >
                                                        <p>
                                                            • TELAS
                                                            PERSONALIZAVEIS
                                                        </p>
                                                        <p>
                                                            • ATIVOS FAVORITOS
                                                            ILIMITADOS
                                                        </p>
                                                        <p>
                                                            • CARTEIRA E
                                                            ESTRUTURAS
                                                            ILIMITADAS
                                                        </p>
                                                    </div>
                                                    <div
                                                        style={{
                                                            color: '#e37039',
                                                            textAlign: 'right',
                                                            fontSize: 30,
                                                            fontWeight: 600,
                                                            marginRight: '20px'
                                                        }}
                                                    >
                                                        R$ 69,90 / Ano*
                                                    </div>
                                                    <div
                                                        style={{
                                                            color: '#fff',
                                                            fontSize: 18
                                                        }}
                                                    >
                                                        Economize R$48,90
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        marginLeft: '105px'
                                                    }}
                                                >
                                                    {api.currentSession ? (
                                                        <button
                                                            type="button"
                                                            style={{
                                                                backgroundColor:
                                                                    'transparent',
                                                                border: 0
                                                            }}
                                                            onClick={() => {
                                                                setPlanoSelected(
                                                                    'anual'
                                                                );
                                                                handleOpen();
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    botaoAssinar
                                                                }
                                                                alt=""
                                                                width="150px"
                                                                onFocus={
                                                                    emptyString
                                                                }
                                                                onBlur={
                                                                    emptyString
                                                                }
                                                                onMouseOver={(
                                                                    e
                                                                ) => {
                                                                    e.currentTarget.src =
                                                                        botaoAssinarHover;
                                                                }}
                                                                onMouseOut={(
                                                                    e
                                                                ) => {
                                                                    e.currentTarget.src =
                                                                        botaoAssinar;
                                                                }}
                                                            />
                                                        </button>
                                                    ) : (
                                                        <button
                                                            type="button"
                                                            style={{
                                                                backgroundColor:
                                                                    'transparent',
                                                                border: 0
                                                            }}
                                                            onClick={() =>
                                                                setOpen(true)
                                                            }
                                                        >
                                                            <img
                                                                src={
                                                                    botaoAssinar
                                                                }
                                                                alt=""
                                                                width="150px"
                                                                onFocus={
                                                                    emptyString
                                                                }
                                                                onBlur={
                                                                    emptyString
                                                                }
                                                                onMouseOver={(
                                                                    e
                                                                ) => {
                                                                    e.currentTarget.src =
                                                                        botaoAssinarHover;
                                                                }}
                                                                onMouseOut={(
                                                                    e
                                                                ) => {
                                                                    e.currentTarget.src =
                                                                        botaoAssinar;
                                                                }}
                                                            />
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Modal>
            <Dialog
                // fullWidth
                maxWidth="lg"
                open={open}
                onClose={handleClose}
                classes={{
                    paper: classes.dialog
                }}
            >
                {loginBtn ? (
                    <Login setOpen={setOpen} redirect={loginBtn} />
                ) : (
                    <Login setOpen={setOpen} />
                )}
            </Dialog>
            <PlanosPayment
                open={openCheck}
                setOpenCheck={setOpenCheck}
                planoSelected={planoSelected}
                reRender={open}
            />
        </>
    );
}
export default Planos;
