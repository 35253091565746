/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
// import {Link} from 'react-scroll';
// import {useHistory} from 'react-router-dom';
// import Button from '@material-ui/core/Button';
// import {makeStyles, createStyles} from '@material-ui/core/styles';
// import routing from '../routes/routing';
import fadeIcon from '../img/fadeoutlogo-opti.avif';
// import seta from '../img/arrowHome.gif';

export default function FAQ() {
    // const history = useHistory();
    const handlePrivacidade = () => {
        window.location.reload();
    };
    /* const useStyles = makeStyles(() =>
        createStyles({
            setaDown: {
                margin: 'auto',
                display: 'flex',
                cursor: 'pointer',
                width: '60px',
                transform: 'rotate(180deg)'
            }
        })
    ); */
    // const classes = useStyles();
    return (
        <div style={{fontSize: 24}} name="header">
            <div style={{display: 'flex', alignItems: 'center'}}>
                <img alt="" src={fadeIcon} style={{width: 150}} />
                <span style={{fontSize: 34, margin: '2rem'}}>
                    Perguntas Frequentes (FAQ)
                </span>
            </div>
            <div style={{marginTop: '2rem'}}>
                <div name="first" style={{display: 'flex'}}>
                    <div>
                        <p>Conta e Acesso:</p>
                        <p>
                            ● Como faço para criar uma conta na Opti?
                            <br />
                            Para criar uma conta na Opti, clique em "PLANOS" no
                            menu superior na página da home, escolha qual plano
                            melhor se encaixa às suas, clique no botão "CRIAR",
                            siga as instruções fornecidas e prontinho! Sua conta
                            Opti está pronta para decolar.
                        </p>
                        <p>
                            ● Esqueci minha senha. Como posso redefinir a mesma?
                            <br /> Clique no botão de "LOGIN" no canto superior
                            direito na página da home, em seguida vá para opção
                            de já possuir conta e clique em "Esqueceu sua
                            senha?", então siga as etapas para redefinição de
                            senha.
                        </p>
                        <p>
                            ● Posso alterar minhas informações de perfil após o
                            registro?
                            <br />
                            Sim, você pode atualizar suas informações de perfil
                            a qualquer momento acessando a página de
                            configurações do usuário, para isso acesse sua conta
                            e clique no círculo do seu avatar localizado no
                            canto superior da página, em seguida clique no ícone
                            de engrenagem. Agora é só adicionar ou alterar as
                            informações.
                        </p>
                        <p>
                            ● Como navego pelas diferentes seções do site?
                            <br />
                            Use o menu de navegação no topo da página para
                            acessar as diferentes seções, como "GRADE DE
                            OPÇÕES", "MAPA DE OPÇÕES" além de "VANTAGENS" e
                            "PLANOS" do site Opti. Você também pode acessar
                            "CARTEIRA", "ROEX" e "MERCADO" ao clicar no logo
                            Opti no canto superior esquerdo da página.
                        </p>
                        <div>
                            ● Para que servem as ferramentas no painel de
                            controle horizontal abaixo do logo Opti?
                        </div>
                        <p style={{marginTop: '-2rem', marginLeft: '3rem'}}>
                            <br />○ O "GRADE DE OPÇÕES" serve para você
                            encontrar as opções disponíveis para as ações da
                            bolsa brasileira.
                        </p>
                        <p style={{marginTop: '-3rem', marginLeft: '3rem'}}>
                            <br />○ O "MAPA DE OPÇÕES" mostra as posições
                            abertas em operações com opções.
                            <br />
                            ○ A "CARTEIRA" serve para você registrar e
                            acompanhar suas operações com opções. ○ Já o "ROEX"
                            foi feito para facilitar a rolagem de suas
                            estruturas de opções.
                            <br />○ E o "MERCADO" é para acompanhar as ações da
                            bolsa, visualizando a volatilidade implícita e
                            histórica dos ativos.
                        </p>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'end',
                            marginRight: '-50px',
                            marginLeft: '20px'
                            // marginBottom: '60px'
                        }}
                    >
                        {/* <Link activeClass="active" to="second" spy smooth>
                            <img
                                src={seta}
                                // style={{marginTop: '-350px', marginLeft: '60%'}}
                                alt=""
                                className={classes.setaDown}
                            />
                        </Link> */}
                    </div>
                </div>
                {/* SECTION 1 */}
                <div name="second" style={{display: 'flex'}}>
                    <div>
                        <p>Ferramentas e Recursos:</p>
                        <div>
                            ● Quais ferramentas de análise estão disponíveis?
                        </div>
                        <p style={{marginTop: '0.5rem', marginLeft: '3rem'}}>
                            ○ Gráfico de payoff: utilizado para visualizar os
                            pontos de lucro e prejuízo das estruturas com
                            opções.
                            <br />
                            ○ Grade de opções: É uma representação organizada
                            das opções de compra (call) e venda (put)
                            disponíveis, listando informações importantes sobre
                            cada uma delas
                            <br />○ Mapa de opções: oferece uma visualização
                            rápida e concisa das opções disponíveis para um
                            ativo subjacente específico. Isso pode ser
                            especialmente útil para traders que desejam avaliar
                            rapidamente suas opções de negociação.
                        </p>
                        <p>
                            ● Posso configurar alertas para ser notificado sobre
                            movimentos de preços?
                            <br />
                            Ferramenta em construção no momento, em breve você
                            terá acesso a essa entre outras novas facilidades ao
                            operar opções.
                        </p>
                        <p>Planos e Pagamentos:</p>
                        <p>
                            ● Quais são os planos disponíveis e suas
                            características?
                            <br />
                            Temos três planos que oferecem diferentes níveis de
                            acesso a recursos avançados, confira a página de
                            "Planos" para mais detalhes.
                        </p>
                        <p>
                            ● Como saber a data final da vigência do meu plano?
                            <br />A data de vencimento dos planos pode ser
                            visualizada na página de configurações do usuário,
                            para isso acesse sua conta e clique no círculo do
                            seu avatar localizado no canto superior da página,
                            em seguida clique no ícone de engrenagem. As
                            informações sobre o plano estão à direita da tela.
                        </p>
                        <p>
                            ● Como posso renovar meu plano?
                            <br />
                            Ao vencer a vigência dos planos "SARDINHA MENSAL" e
                            "SARDINHA ANUAL" sua conta volta para o plano
                            "GRATUITO" se a renovação automática não esteja
                            ativada, neste caso é só entrar novamente na página
                            de "Planos" e assinar novamente.
                        </p>
                        <p>
                            ● Como posso aprimorar meu plano?
                            <br />
                            Para fazer o upgrade basta entrar na página de
                            "Planos" ao fim da vigência do "SARDINHA MENSAL" e
                            assinar o "SARDINHA ANUAL".
                        </p>
                        <p>
                            ● Como cancelar minha assinatura?
                            <br />
                            Acesse a página de configurações do usuário clicando
                            no círculo do seu avatar localizado no canto
                            superior da página, em seguida clique no ícone de
                            engrenagem. O botão de "cancelar assinatura" se
                            encontra no canto inferior esquerdo da tela.
                            *Lembre-se: ao cancelar assinatura seu plano
                            continua vigente até a data de vencimento do mesmo.
                            Sua conta e informações não serão deletadas neste
                            processo.
                        </p>
                        <p>Suporte e Contato:</p>
                        <p>
                            ● Como posso entrar em contato com o suporte ao
                            cliente?
                            <br />
                            Para obter suporte, você pode enviar um e-mail para
                            nossa equipe em contato@useopti.com.br , geralmente
                            responderemos em até 24 horas levando em conta nosso
                            horário de atendimento de segunda a sexta, das 9h às
                            17h (exceto feriados).
                        </p>
                        <p>
                            ● Vocês oferecem suporte telefônico?
                            <br />
                            Atualmente, nosso suporte é oferecido por e-mail.
                        </p>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'end',
                            marginRight: '-50px',
                            marginLeft: '20px',
                            marginBottom: '60px'
                        }}
                    >
                        {/*  <Link activeClass="active" to="last" spy smooth>
                            <img
                                src={seta}
                                // style={{marginTop: '-350px', marginLeft: '60%'}}
                                alt=""
                                className={classes.setaDown}
                            />
                        </Link> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
