/* eslint-disable prefer-const */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import React, {useState, useEffect} from 'react';
import {Box, Grid} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import moment from 'moment';

// import Slider from 'react-slick';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const useStyles = makeStyles((theme) =>
    createStyles({
        myButton: {
            color: '#2a3176',
            backgroundColor: '#f4f4f4',
            fontWeight: 'bold'
        },
        myBox: {
            marginLeft: theme.spacing(1.2),
            marginBottom: -18
        },
        myGrid: {
            minWidth: 90,
            maxHeight: 30,
            maxWidth: 90,
            margin: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            textAlign: 'center',
            cursor: 'pointer',
            fontSize: 14,
            border: '2px solid #c5c5c5'
        },
        myGridSelected: {
            minWidth: 90,
            maxWidth: 90,
            maxHeight: 30,
            backgroundColor: '#e37039',
            margin: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 14,
            display: 'flex',
            textAlign: 'center',
            cursor: 'pointer',
            border: '2px solid #e37039',
            color: '#fff'
        },
        myLabel: {
            color: '#d36233',
            fontSize: 14
        },
        myLabelSelected: {
            padding: theme.spacing(6),
            color: '#fff',
            fontWeight: 'bold'
        },
        mySlider: {
            color: '#e37039',
            valueLabel: {
                zIndex: 10,
                left: 'calc(-50% + 12px)',
                top: -22,
                '& *': {
                    background: 'transparent',
                    color: '#000'
                }
            }
        }
    })
);
function ValueLabelComponent(props) {
    const {children, open, value} = props;
    if (children.props['data-index'] === 1) {
        return (
            <Tooltip
                open={open}
                enterTouchDelay={0}
                title={value}
                arrow
                // style={{backgroundColor: '#e37039', color: '#e37039'}}
                // placement="center"
                placement="top"
            >
                {children}
            </Tooltip>
        );
    }
    return (
        <Tooltip
            open={open}
            enterTouchDelay={0}
            title={value}
            arrow
            // style={{backgroundColor: '#e37039', color: '#e37039'}}
            // placement="center"
            placement="bottom"
        >
            {children}
        </Tooltip>
    );
}

ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired
};

// eslint-disable-next-line no-unused-vars
export default function Filters({data, setData, active, size}) {
    const classes = useStyles();
    const atual = moment().month();

    // eslint-disable-next-line no-unused-vars
    const [months, setMonths] = useState([
        {
            month: 'JANEIRO',
            status: !!(atual === 0 || atual - 1 === 0)
        },
        {
            month: 'FEVEREIRO',
            status: !!(atual === 1 || atual - 1 === 1)
        },
        {
            month: 'MARÇO',
            status: !!(atual === 2 || atual - 1 === 2)
        },
        {
            month: 'ABRIL',
            status: !!(atual === 3 || atual - 1 === 3)
        },
        {
            month: 'MAIO',
            status: !!(atual === 4 || atual - 1 === 4)
        },
        {
            month: 'JUNHO',
            status: !!(atual === 5 || atual - 1 === 5)
        },
        {
            month: 'JULHO',
            status: !!(atual === 6 || atual - 1 === 6)
        },
        {
            month: 'AGOSTO',
            status: !!(atual === 7 || atual - 1 === 7)
        },
        {
            month: 'SETEMBRO',
            status: !!(atual === 8 || atual - 1 === 8)
        },
        {
            month: 'OUTUBRO',
            status: !!(atual === 9 || atual - 1 === 9)
        },
        {
            month: 'NOVEMBRO',
            status: !!(atual === 10 || atual - 1 === 10)
        },
        {
            month: 'DEZEMBRO',
            status: !!(atual === 11 || atual - 1 === 11)
        }
    ]);
    const [status, setStatus] = useState([
        {
            label: 'ABERTA',
            status: true
        },
        {label: 'FECHADA', status: false}
    ]);
    const [lucro, setLucro] = useState([
        {
            label: 'CRESCENTE',
            status: true
        },
        {label: 'DECRESCENTE', status: false}
    ]);
    const myVenc = data[active]?.filters?.history?.find((e) => e.status).label;

    const inTheMonth = (mydata, list) => {
        const month = moment(mydata.filterDataMont).month();
        months.forEach((fm, i) => {
            if (fm.status) {
                if (i === month) {
                    list.push(mydata);
                }
            }
        });
    };

    useEffect(() => {
        const listFiltered = [];
        data.forEach((d) => {
            status.forEach((s) => {
                if (s.status) {
                    if (s.label === 'ABERTA' && d.status) {
                        inTheMonth(d, listFiltered);
                        // listFiltered.push(d);
                    } else if (s.label === 'FECHADA' && !d.status) {
                        // listFiltered.push(d);
                        inTheMonth(d, listFiltered);
                    }
                }
            });
        });

        const order = lucro.find((l) => l.status === true);

        if (order.label === 'CRESCENTE') {
            listFiltered.sort((a, b) => a.lucro - b.lucro);
            // listFiltered.sort((a, b) => a.lucro - b.lucro);
        } else {
            listFiltered.sort((a, b) => b.lucro - a.lucro);
            // listFiltered.sort((a, b) => b.lucro - a.lucro);
        }

        setData(listFiltered);
    }, [lucro, status, months, data]);

    const changeStatus = (e) => {
        const orig = _.cloneDeep(status);
        const find = orig.find((el) => el.label === e.label);
        if (!e.status) {
            find.status = !e.status;
        } else if (orig[0].status && orig[1].status) {
            find.status = !find.status;
        }
        setStatus(orig);
    };

    const changeMonth = (e) => {
        const orig = _.cloneDeep(months);
        orig.forEach((el) => {
            if (el.month !== e.month && el.status) {
                const find = orig.find((o) => o.month === e.month);
                find.status = !e.status;
            }
        });
        setMonths(orig);
    };

    const changeLucro = (e) => {
        if (!e.status) {
            const orig = _.cloneDeep(lucro);
            const other = orig.find((el) => el.label !== e.label);
            const my = orig.find((el) => el.label === e.label);
            my.status = !e.status;
            other.status = e.status;
            setLucro(orig);
        }
    };

    return (
        <>
            <Grid
                container
                spacing={1}
                style={{
                    marginTop: -10,
                    marginBottom: 10,
                    justifyContent: 'space-between'
                }}
            >
                <Grid item xs={12} sm={6} md={6} lg={4} xl={6}>
                    <Grid
                        container
                        spacing={1}
                        style={{
                            justifyContent: 'flex-start'
                        }}
                    >
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <ReactTooltip />
                            <ReactTooltip id="montagem" place="right" />
                            <Box
                                className={classes.myBox}
                                style={{marginLeft: 5}}
                            >
                                <label
                                    className={classes.myLabel}
                                    style={{marginBottom: '15px'}}
                                >
                                    STATUS
                                </label>
                                <span
                                    style={{cursor: 'pointer'}}
                                    data-tip="Status: Aberto - carteiras registradas ainda em andamento. Fechado -
                                    carteiras registradas encerradas."
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    alignItems: 'center'
                                }}
                            >
                                {status.map((s) => (
                                    <Grid
                                        key={s.label}
                                        className={
                                            s.status
                                                ? classes.myGridSelected
                                                : classes.myGrid
                                        }
                                        onClick={() => changeStatus(s)}
                                    >
                                        {s.label}
                                    </Grid>
                                ))}
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={10}
                            md={6}
                            lg={4}
                            xl={4}
                            style={{
                                marginLeft:
                                    size >= 1920
                                        ? '22px'
                                        : size >= 1600
                                        ? '156px'
                                        : '75px'
                            }}
                        >
                            <Box className={classes.myBox}>
                                <label
                                    className={classes.myLabel}
                                    style={{marginBottom: '15px'}}
                                >
                                    LUCRO
                                </label>
                                <span
                                    style={{cursor: 'pointer'}}
                                    data-tip="Lucro: Filtro para carteiras utilizando Lucro/Prejuízo como referência. "
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    alignItems: 'center'
                                }}
                            >
                                {lucro.map((l) => (
                                    <Grid
                                        key={l.label}
                                        className={
                                            l.status
                                                ? classes.myGridSelected
                                                : classes.myGrid
                                        }
                                        onClick={() => changeLucro(l)}
                                    >
                                        {l.label}
                                    </Grid>
                                ))}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={1}
                style={{
                    marginTop: size > 599 && -10,
                    marginBottom:
                        data.length > 0 &&
                        data[active]?.values[myVenc]?.venc?.length > 0 &&
                        -20,
                    justifyContent: 'space-between'
                }}
            >
                <Grid item xs={12}>
                    <>
                        <Grid item sm={12} style={{marginBottom: -10}}>
                            <label
                                className={classes.myLabel}
                                style={{marginLeft: '6px'}}
                            >
                                MONTAGEM
                            </label>
                            <span
                                data-for="montagem"
                                style={{cursor: 'pointer'}}
                                data-tip="Montagem: Mês de montagem da carteira"
                            >
                                <ion-icon
                                    style={{
                                        marginBottom: 2,
                                        fontSize: 12,
                                        color: '#d36233',
                                        pointerEvents: 'none'
                                    }}
                                    name="help-circle-outline"
                                />
                            </span>
                        </Grid>
                        <Grid
                            container
                            style={{
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'center'
                            }}
                        >
                            {months.map((m) => (
                                <Grid
                                    key={m.month}
                                    item
                                    className={
                                        m.status
                                            ? classes.myGridSelected
                                            : classes.myGrid
                                    }
                                    onClick={() => changeMonth(m)}
                                >
                                    {m.month}
                                </Grid>
                            ))}
                        </Grid>
                    </>
                </Grid>
            </Grid>
        </>
    );
}
