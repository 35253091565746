/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events  */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {useState, useEffect} from 'react';
import {NavLink, Link, useHistory} from 'react-router-dom';
import routing from '@app/routes/routing';

import {Button, Checkbox} from '@material-ui/core';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import iconeResumo from 'src/img/icone-resumo.avif';
import iconeUsuario from 'src/img/icone-usuario.avif';
import iconeCancel from 'src/img/icone-cancelamento.avif';
import iconeConfig from 'src/img/icone-configuracoes.avif';
import iconeSair from 'src/img/icone-sair.avif';
import logo from 'src/img/OPTI_logo1_claro.avif';

const useStyles = makeStyles((theme) =>
    createStyles({
        myButton: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '33vh',
            borderRadius: '10vh',
            marginTop: '20px',
            marginLeft: '8px',
            color: '#e37039',
            fontFamily: 'Roboto',
            fontSize: 18
            // fontWeight: '700'
        }
    })
);

export default function AdminSidebar({page, setPage}) {
    const classes = useStyles();
    const history = useHistory();

    const handleChange = (name) => {
        setPage(name);
    };

    return (
        <aside
            className="main-sidebar sidebar-dark-primary "
            style={{
                position: 'fixed'
                // backgroundColor: '#313031',
                // height: '100%'
                // zIndex: 1,
                // width: '140px'
            }}
        >
            <div className="sidebar">
                <div style={{marginTop: '40px', overflowX: 'hidden'}}>
                    <img
                        src={logo}
                        alt=""
                        style={{width: 160, cursor: 'pointer'}}
                        onClick={() => history.push(routing.home)}
                    />
                </div>
                <div
                    className="mt-2"
                    style={{overflowY: 'hidden', overflowX: 'hidden'}}
                >
                    <div
                        className="nav nav-pills nav-sidebar flex-column"
                        data-widget="treeview"
                        role="menu"
                        data-accordion="false"
                        style={{marginTop: '40px'}}
                    >
                        <div>
                            <Button
                                className={classes.myButton}
                                onClick={() => handleChange('resumo')}
                            >
                                <div>
                                    <img
                                        style={{
                                            width: '25px',
                                            height: '25px',
                                            marginRight: '20px'
                                        }}
                                        alt="resumo"
                                        src={iconeResumo}
                                    />
                                    Resumo
                                </div>
                            </Button>
                        </div>

                        <div>
                            <Button
                                className={classes.myButton}
                                onClick={() => handleChange('usuarios')}
                            >
                                <div>
                                    <img
                                        style={{
                                            width: '30px',
                                            height: '30px',
                                            marginRight: '15px'
                                        }}
                                        alt="resumo"
                                        src={iconeUsuario}
                                    />
                                    Usuários
                                </div>
                            </Button>
                        </div>

                        <Button
                            className={classes.myButton}
                            onClick={() => handleChange('cancelamentos')}
                        >
                            <div>
                                <img
                                    style={{
                                        width: '25px',
                                        height: '25px',
                                        marginRight: '20px'
                                    }}
                                    alt="resumo"
                                    src={iconeCancel}
                                />
                                Cancelamentos
                            </div>
                        </Button>

                        <div
                            style={{
                                borderBottom: '2px solid #d36233',
                                marginTop: '20px'
                            }}
                        />

                        <div>
                            <Button
                                className={classes.myButton}
                                onClick={() => handleChange('config')}
                            >
                                <div>
                                    <img
                                        style={{
                                            width: '25px',
                                            height: '25px',
                                            marginRight: '20px'
                                        }}
                                        alt="resumo"
                                        src={iconeConfig}
                                    />
                                    Configurações
                                </div>
                            </Button>
                        </div>
                        <div>
                            <Button
                                className={classes.myButton}
                                onClick={() => handleChange('sair')}
                            >
                                <div>
                                    <img
                                        style={{
                                            width: '25px',
                                            height: '25px',
                                            marginRight: '20px'
                                        }}
                                        alt="resumo"
                                        src={iconeSair}
                                    />
                                    Sair
                                </div>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </aside>
        /*  <aside
            className="main-sidebar sidebar-dark-primary elevation-4"
            style={{position: window.location.pathname === '/' && 'fixed'}}
        >
            <div style={{marginTop: '40px'}}>
                <img src={logo} alt="" style={{width: '50%'}} />
            </div>
            <div className="sidebar">
                <div
                    className="mt-2"
                    style={{overflowY: 'hidden', overflowX: 'hidden'}}
                >
                    <div
                        className="nav nav-pills nav-sidebar flex-column"
                        data-widget="treeview"
                        role="menu"
                        data-accordion="false"
                    >
                        <Button className="nav-item">
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    color: '#fff'
                                }}
                            >
                                <img
                                    style={{
                                        width: '25px',
                                        height: '25px',
                                        marginLeft: '10px',
                                        marginRight: '20px'
                                    }}
                                    alt="resumo"
                                    src={iconeResumo}
                                />
                                RESUMO
                            </div>
                        </Button>
                    </div>
                </div>
            </div>
            {/* <Link to={routing.home} className="brand-link">
                <img
                    src="/img/OPTI_logo.png"
                    alt="Logo"
                    style={{
                        height: 40,
                        marginBottom: -10,
                        marginTop: -10,
                        marginLeft: 10
                    }}
                />
                <span className="brand-text font-weight-light" />
            </Link>
            <div className="sidebar">
                <nav
                    className="mt-2"
                    style={{overflowY: 'hidden', overflowX: 'hidden'}}
                >
                    <ul
                        className="nav nav-pills nav-sidebar flex-column"
                        data-widget="treeview"
                        role="menu"
                        data-accordion="false"
                    >
                        <li className="nav-item">
                            <NavLink
                                to={routing.mercado}
                                exact
                                className="nav-link"
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    <ion-icon
                                        style={{marginRight: 10}}
                                        name="analytics-outline"
                                        size="large"
                                    />
                                    <p>Mercado</p>
                                </div>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to={routing.dashboard}
                                exact
                                className="nav-link"
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    <ion-icon
                                        style={{marginRight: 10}}
                                        name="grid-outline"
                                        size="large"
                                    />
                                    <p>Grade de Opções</p>
                                </div>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to={routing.gradeepm}
                                exact
                                className="nav-link"
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    <ion-icon
                                        style={{marginRight: 10}}
                                        size="large"
                                        name="list-outline"
                                    />

                                    <p>Grade EPM</p>
                                </div>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to={routing.charts}
                                exact
                                className="nav-link"
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    <ion-icon
                                        style={{marginRight: 10}}
                                        name="bar-chart-outline"
                                        size="large"
                                    />
                                    <p>Mapa de Opções</p>
                                </div>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to={routing.money}
                                exact
                                className="nav-link"
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    <ion-icon
                                        style={{marginRight: 10}}
                                        name="wallet-outline"
                                        size="large"
                                    />
                                    <p>Carteira</p>
                                </div>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to={routing.roex}
                                exact
                                className="nav-link"
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    <ion-icon
                                        style={{marginRight: 10}}
                                        name="sync-circle-outline"
                                        size="large"
                                    />
                                    <p>Roex</p>
                                </div>
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </div> 
        </aside> */
    );
}
