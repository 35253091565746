/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React, {ReactDOM, useState, useEffect} from 'react';
import {Button, Modal, Popover, IconButton, Avatar} from '@material-ui/core';
import {useHistory, useLocation, Redirect, NavLink} from 'react-router-dom';
import api from '@app/utils/api';
import app from '@app/firebase/base';
import moment from 'moment';
import routing from '@app/routes/routing';
import WebFont from 'webfontloader';
import {
    makeStyles,
    createStyles,
    createTheme,
    ThemeProvider
} from '@material-ui/core/styles';
import {Card} from 'react-bootstrap';
import storage from '@app/utils/storage';
import botaoAssinar from 'src/img/botaoAssinar.avif';
import botaoAssinarHover from 'src/img/botaoAssinarHover.avif';
import PlanosButton from '@app/pages/PlanosButton';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import Dialog from '@material-ui/core/Dialog';
import logo from 'src/img/OPTI_logo1_claro.avif';
import titulo from '../img/planosAssinaturasOpti.avif';

export default function Usuario() {
    const [email, setEmail] = useState();
    const [usuario, setUsuario] = useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [plano, setPlano] = useState();
    const [vencimento, setVencimento] = useState(false);
    const [photo, setPhoto] = useState(null);
    const [dataPgto, setDataPgto] = useState(false);
    const open = Boolean(anchorEl);

    const history = useHistory();
    const location = useLocation();

    const handleClick = (event) => {
        // console.log(event.currentTarget, 'teste');
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [openDialog, setOpenDialog] = useState(false);
    const handleDialogOpen = () => {
        setOpenDialog(true);
    };
    const handleDialogClose = () => {
        setOpenDialog(false);
    };

    const useStyles = makeStyles(() =>
        createStyles({
            menuPaper: {
                backgroundColor: '#3b3841',
                BackdropFilter: 'blur(0px)'
            },
            logo: {
                display: 'flex',
                // margin: 'auto',
                marginLeft: '20%',
                width: '65%'
                // paddingTop: 350
            }
        })
    );
    const styles = {
        margin: 0,
        // top: 'auto',
        right: 60,
        bottom: 0,
        // left: 'auto',
        position: 'absolute',
        width: '10px'
    };
    const classes = useStyles();
    const color = createTheme({
        palette: {
            primary: {
                main: '#cac6c2'
            },
            secondary: {
                main: '#000'
            }
        }
    });

    useEffect(() => {
        if (!api.currentSession) {
            app.auth()
                .signOut()
                .then(() => {
                    // Sign-out successful.
                })
                .catch(() => {
                    // An error happened.
                });
        }
        setEmail(api.currentUser.email);
        setUsuario(api.currentUser.name);
        setPhoto(api.currentUser.photo);
        if (api.currentSession && api.currentUser.assinatura)
            setPlano(api.currentUser.assinatura.plano);

        if (api.currentUser?.assinatura?.vencimento) {
            if (moment() <= moment(api.currentUser?.assinatura.vencimento)) {
                /* if (api.currentUser.plano?.status_assinatura === 'ativo') {
                    setVencimento(
                        moment(api.currentUser.plano?.vencimento).format(
                            'DD/MM/YYYY'
                        )
                    );
                    setDataPgto(
                        moment(api.currentUser.plano?.vencimento).format(
                            'DD/MM/YYYY'
                        )
                    );
                    
                } */ if (api.currentUser?.assinatura?.status_sub === 'ACTIVE') {
                    setVencimento(
                        moment(api.currentUser.assinatura?.vencimento).format(
                            'DD/MM/YYYY'
                        )
                    );
                    setDataPgto(
                        moment(api.currentUser.assinatura?.vencimento).format(
                            'DD/MM/YYYY'
                        )
                    );
                } else {
                    /*  setVencimento(
                        moment(api.currentUser.plano?.vencimento).format(
                            'DD/MM/YYYY'
                        )
                    ); */
                    setVencimento(
                        moment(api.currentUser.assinatura?.vencimento).format(
                            'DD/MM/YYYY'
                        )
                    );
                }
            }
        }
        WebFont.load({
            google: {
                families: ['Roboto']
            }
        });
    }, []);

    const handleExit = () => {
        storage.auth.token = null;
        if (location.pathname === '/') {
            window.location.reload(true);
        } else {
            history.push(routing.home);
            window.location.reload(true);
        }
    };

    const handleUserPage = () => {
        history.push(routing.perfil);
    };

    let names;
    let initials;
    const getInitials = function (string) {
        names = string.split(' ');
        initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

    return (
        <div>
            <ThemeProvider theme={color}>
                <IconButton
                    style={styles}
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    color="primary"
                >
                    {!photo ? (
                        <Avatar style={{backgroundColor: '#e37039'}}>
                            {getInitials(usuario)}
                        </Avatar>
                    ) : (
                        <Avatar alt="Perfil" src={photo} />
                    )}
                </IconButton>

                <Popover
                    id="basic-menu"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    PaperProps={{
                        style: {
                            backgroundColor: 'transparent',
                            boxShadow: 'none'
                        }
                    }}
                    className="muipopover"
                    classes={{paper: classes.menuPaper}}
                    style={{marginTop: 60}}
                    open={open}
                    onClose={handleClose}
                >
                    <div style={{width: '19rem', height: '30rem'}}>
                        <Card
                            style={{
                                marginTop: '20px',
                                // marginLeft: '-20px',
                                width: '16rem',
                                height:
                                    usuario?.length > 19 ? '29rem' : '28rem',
                                display: 'flex',
                                borderRadius: '20px',
                                backgroundColor: '#cac6c2'
                            }}
                        >
                            <Card.Header
                                style={{
                                    border: 'none',
                                    boxShadow: 'none',
                                    color: '#000'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        fontSize: 34,
                                        fontFamily: 'Roboto'
                                        // fontWeight: 700
                                    }}
                                >
                                    <div>BEM-VINDO</div>
                                </div>

                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                        // marginTop: '-10px'
                                    }}
                                >
                                    {!photo ? (
                                        <Avatar
                                            style={{backgroundColor: '#e37039'}}
                                        >
                                            {getInitials(usuario)}
                                        </Avatar>
                                    ) : (
                                        <Avatar alt="Perfil" src={photo} />
                                    )}
                                    <div
                                        style={{
                                            fontSize: 18,
                                            marginLeft: '10px',
                                            fontFamily: 'Roboto'
                                        }}
                                    >
                                        {usuario}
                                    </div>
                                </div>
                            </Card.Header>
                            <div style={{display: 'flex'}}>
                                <Card.Body
                                    style={{
                                        border: 'none',
                                        boxShadow: 'none',
                                        color: '#000',
                                        marginTop: '-25px'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div
                                            style={{
                                                color: '#53565B',
                                                fontSize: 18,
                                                width: '50px'
                                            }}
                                        >
                                            LOGIN
                                        </div>
                                        <span
                                            style={{
                                                width: '166px',
                                                borderBottom:
                                                    '0.5px solid DarkGrey'
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            fontWeight: 500,
                                            fontSize: 16,
                                            fontFamily: 'Roboto'
                                        }}
                                    >
                                        {email}
                                    </div>
                                    <div
                                        style={{
                                            marginTop: '10px',
                                            width: '216px',
                                            borderBottom: '0.5px solid DarkGrey'
                                        }}
                                    />
                                    {vencimento ? (
                                        <>
                                            <div style={{marginTop: '10px'}}>
                                                <div
                                                    style={{
                                                        fontSize: 16,
                                                        color: '#53565B'
                                                    }}
                                                >
                                                    PLANO CONTRATADO
                                                </div>
                                                {plano === 'MENSAL' ? (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'center',
                                                            marginTop: '5px',
                                                            color: '#000',
                                                            fontWeight: 500,
                                                            fontFamily: 'Roboto'
                                                        }}
                                                    >
                                                        SARDINHA MENSAL
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'center',
                                                            marginTop: '5px',
                                                            color: '#000',
                                                            fontWeight: 500,
                                                            fontFamily: 'Roboto'
                                                        }}
                                                    >
                                                        SARDINHA ANUAL
                                                    </div>
                                                )}
                                            </div>
                                            <div
                                                style={{
                                                    marginTop: '10px',
                                                    width: '216px',
                                                    borderBottom:
                                                        '0.5px solid DarkGrey'
                                                }}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <div style={{marginTop: '10px'}}>
                                                <div
                                                    style={{
                                                        fontSize: 16,
                                                        color: '#53565B'
                                                    }}
                                                >
                                                    PLANO CONTRATADO
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        marginTop: '5px',
                                                        color: '#000',
                                                        fontWeight: 500,
                                                        fontFamily: 'Roboto'
                                                    }}
                                                >
                                                    GRATUITO
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    marginTop: '10px',
                                                    width: '216px',
                                                    borderBottom:
                                                        '0.5px solid DarkGrey'
                                                }}
                                            />
                                        </>
                                    )}

                                    {vencimento ? (
                                        <>
                                            <div>
                                                {dataPgto ? (
                                                    <div
                                                        style={{
                                                            marginTop: '10px'
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                fontSize: 16,
                                                                color: '#53565B'
                                                            }}
                                                        >
                                                            DATA DE PAGAMENTO
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'center',
                                                                marginTop:
                                                                    '5px',
                                                                color: '#000',
                                                                fontWeight: 500,
                                                                fontFamily:
                                                                    'Roboto'
                                                            }}
                                                        >
                                                            {dataPgto}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            marginTop: '10px'
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                fontSize: 16,
                                                                color: '#53565B'
                                                            }}
                                                        >
                                                            DATA DE PAGAMENTO
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                justifyContent:
                                                                    'center',
                                                                marginTop:
                                                                    '5px',
                                                                color: '#000',
                                                                fontWeight: 500,
                                                                fontFamily:
                                                                    'Roboto'
                                                            }}
                                                        >
                                                            --/--/----
                                                        </div>
                                                    </div>
                                                )}
                                                <div
                                                    style={{
                                                        marginTop: '10px',
                                                        width: '216px',
                                                        borderBottom:
                                                            '0.5px solid DarkGrey'
                                                    }}
                                                />

                                                <div
                                                    style={{
                                                        marginTop: '10px'
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            fontSize: 16,
                                                            color: '#53565B'
                                                        }}
                                                    >
                                                        PRAZO DO PLANO
                                                    </div>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'center',
                                                            marginTop: '5px',
                                                            color: '#000',
                                                            fontWeight: 500,
                                                            fontFamily: 'Roboto'
                                                        }}
                                                    >
                                                        {vencimento}
                                                    </div>
                                                </div>

                                                <div
                                                    style={{
                                                        marginTop: '10px',
                                                        width: '216px',
                                                        borderBottom:
                                                            '0.5px solid DarkGrey'
                                                    }}
                                                />
                                            </div>
                                            {/* <div style={{marginTop: '10px'}}>
                                                <div
                                                    style={{
                                                        fontSize: 16,
                                                        color: '#53565B'
                                                    }}
                                                >
                                                    DATA DE PAGAMENTO
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        marginTop: '5px',
                                                        color: '#000',
                                                        fontWeight: 500,
                                                        fontFamily: 'Roboto'
                                                    }}
                                                >
                                                    {vencimento}
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    marginTop: '10px',
                                                    width: '216px',
                                                    borderBottom:
                                                        '0.5px solid DarkGrey'
                                                }}
                                            />
                                            <div style={{marginTop: '10px'}}>
                                                <div
                                                    style={{
                                                        fontSize: 16,
                                                        color: '#53565B'
                                                    }}
                                                >
                                                    PRAZO DO PLANO
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        marginTop: '5px',
                                                        color: '#000',
                                                        fontWeight: 500,
                                                        fontFamily: 'Roboto'
                                                    }}
                                                >
                                                    {vencimento}
                                                </div>
                                            </div>
                                            <div
                                                style={{
                                                    marginTop: '10px',
                                                    width: '216px',
                                                    borderBottom:
                                                        '0.5px solid DarkGrey'
                                                }}
                                            /> */}
                                        </>
                                    ) : (
                                        <>
                                            <div
                                                style={{
                                                    marginTop: '40px',
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <button
                                                    type="button"
                                                    style={{
                                                        backgroundColor:
                                                            'transparent',
                                                        border: 0
                                                    }}
                                                    onClick={handleDialogOpen}
                                                >
                                                    <img
                                                        src={botaoAssinarHover}
                                                        alt=""
                                                        width="171px"
                                                        height="57px"
                                                    />
                                                </button>
                                            </div>
                                            <div
                                                style={{
                                                    marginTop: '48px',
                                                    width: '216px',
                                                    borderBottom:
                                                        '0.5px solid DarkGrey'
                                                }}
                                            />
                                        </>
                                    )}

                                    <div
                                        style={{
                                            marginTop: '12.5px',
                                            display: 'flex',
                                            justifyContent: 'flex-end'
                                        }}
                                    >
                                        <NavLink to={routing.perfil} exact>
                                            <span title="Configurações">
                                                <ion-icon
                                                    name="settings-outline"
                                                    style={{
                                                        fontSize: 22,
                                                        marginRight: '10px',
                                                        color: '#525152',
                                                        cursor: 'pointer',
                                                        pointerEvents: 'none'
                                                    }}

                                                    /* onClick={handleUserPage} */
                                                />
                                            </span>
                                        </NavLink>
                                        <span
                                            title="Sair"
                                            style={{cursor: 'pointer'}}
                                            onClick={handleExit}
                                        >
                                            <ion-icon
                                                name="log-out-outline"
                                                style={{
                                                    fontSize: 22,
                                                    cursor: 'pointer',
                                                    marginRight: '-5px',
                                                    pointerEvents: 'none',
                                                    color: '#525152'
                                                }}
                                            />
                                        </span>
                                    </div>
                                </Card.Body>
                                <div
                                    style={{
                                        borderRadius: '50%',
                                        width: '28px',
                                        height: '28px',
                                        backgroundColor: '#cac6c2',
                                        marginLeft: '-25px',
                                        marginTop:
                                            usuario?.length > 19
                                                ? '-130px'
                                                : '-115px',
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <ion-icon
                                        name="close-circle-outline"
                                        style={{
                                            fontSize: 28,
                                            color: '#e37039',
                                            cursor: 'pointer'
                                        }}
                                        onClick={handleClose}
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>
                </Popover>
            </ThemeProvider>
            <Modal
                disableEnforceFocus
                open={openDialog}
                PaperProps={{
                    style: {
                        backgroundColor: 'rgba(211, 211, 211, 0.4)',
                        boxShadow: 'none'
                    }
                }}
                className={classes.modal}
                style={{
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    outline: 0,
                    backdropFilter: 'blur(5px)'
                }}
            >
                <Card
                    style={{
                        backgroundColor: 'rgba(211, 211, 211, 0.4)',
                        height: '38rem',
                        borderRadius: '20px'
                    }}
                    className={classes.modal}
                >
                    <div
                        style={{
                            textAlign: 'right',
                            fontSize: 32,
                            marginRight: '5px',
                            marginTop: '-15px'
                        }}
                    >
                        <ion-icon
                            style={{
                                backgroundColor: '#e37039',
                                color: '#fff',
                                borderRadius: '50%',
                                cursor: 'pointer'
                            }}
                            onClick={() => setOpenDialog(false)}
                            name="close-circle-outline"
                        />
                    </div>
                    <Card.Body style={{fontFamily: 'Roboto'}}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <img alt="" style={{width: '900px'}} src={titulo} />
                        </div>
                        <PlanosButton />
                    </Card.Body>
                </Card>
            </Modal>
        </div>
    );
}
