/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {useState, useEffect} from 'react';
import {Accordion, Card, Modal} from 'react-bootstrap';
import {useAccordionToggle} from 'react-bootstrap/AccordionToggle';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Text
} from 'recharts';
import WebFont from 'webfontloader';
import Select from 'react-select';
import moment from 'moment';
import 'bootstrap/js/src/collapse';
import _ from 'lodash';
import Calculadora from '@app/pages/Calculadora';
import MobileDetecter from '@app/components/MobileDetecter';
import api from '@app/utils/api';
import {Grid} from '@material-ui/core';
import toast, {Toaster, ToastBar} from 'react-hot-toast';
import Table from '../components/TableChartsW';
import Filters from '../components/FiltersChart';
import Loading from '../components/loading/Loading';
import AlertSave from '../components/AlertSave';

// const dataChart = [
//     {
//         name: 'Page A',
//         coberto: 4000,
//         descoberto: 2400,
//         trava: 2400
//     },
//     {
//         name: 'Page B',
//         uv: 3000,
//         pv: 1398,
//         amt: 2210
//     },
//     {
//         name: 'Page C',
//         uv: 2000,
//         pv: 9800,
//         amt: 2290
//     },
//     {
//         name: 'Page D',
//         uv: 2780,
//         pv: 3908,
//         amt: 2000
//     },
//     {
//         name: 'Page E',
//         uv: 1890,
//         pv: 4800,
//         amt: 2181
//     },
//     {
//         name: 'Page F',
//         uv: 2390,
//         pv: 3800,
//         amt: 2500
//     },
//     {
//         name: 'Page G',
//         uv: 3490,
//         pv: 4300,
//         amt: 2100
//     }
// ];

const Dashboard = ({setHeaderValues}) => {
    const [active, setActive] = useState();
    const [expand, setExpand] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    // const ativos = ati;
    const [ativos, setAtivos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sliderLabel, setSliderLabel] = useState('on');

    const [size, setSize] = useState(window.innerWidth);
    const [expandChart, setExpandChart] = useState(true);
    // Willian data
    const [dataChart, setDataChart] = useState([]);
    const [data, setData] = useState({});
    const [fechamento, setFechamento] = useState();
    const [dataTable, setDataTable] = useState([]);
    const [numLabels, setNumLabels] = useState(3);
    const [labels, setLabels] = useState([
        {name: 'PETR4'},
        {name: 'VALE3'},
        {name: 'BOVA11'}
    ]);
    const [detectUnload, setDetectUnload] = useState(false);
    const [userLabels, setUserLabels] = useState([]);
    const isCalls = [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L'
    ];
    const isPuts = ['M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'X', 'Y'];
    const [pref, setPref] = useState();
    const [open, setOpen] = useState(false);
    const [newUserLabels, setNewUserLabels] = useState();
    const [ilimitado, setIlimitado] = useState(false);

    const user = api.currentUser;

    const notifySave = () => {
        toast.success('Preferências Salvas!', {
            duration: 5000,
            position: 'top-center',
            iconTheme: {
                primary: '#d36233',
                secondary: '#fff'
            },
            style: {
                fontSize: '18px',
                minHeight: '60px',
                minWidth: '180px',
                color: '#fff',
                background: '#d36233'
            }
        });
    };

    const collapseChart = () => {
        setExpandChart(!expandChart);
    };

    const DataFormater = (number) => {
        if (number > 1000000000) {
            return `${(number / 1000000000).toString()}B`;
        }
        if (number > 1000000) {
            return `${(number / 1000000).toString()}M`;
        }
        if (number > 1000) {
            return `${(number / 1000).toString()}K`;
        }
        return number.toString();
    };

    window.addEventListener('resize', () => {
        setSize(window.innerWidth);
    });

    const notify = (ticker) => {
        toast.success(`Ticker ${ticker} copiado!`, {
            iconTheme: {
                primary: '#d36233',
                secondary: '#fff'
            }
        });
        navigator.clipboard.writeText(ticker);
    };

    const defaultFilters = {
        btnType: {call: true, put: false},
        btnModel: {europe: true, america: true},
        btnMoneyness: {ITM: false, ATM: false, OTM: false},
        strike: {min: 0, start: 0, end: 5000, max: 5000},
        history: [],
        venc: []
    };

    const getHeaders = () => {
        setLoading(true);
        let volh = '';
        api.options
            .volTabela({ativo: active})
            .then((res) => {
                if (Number(Number(res.retorno.volH * 100).toFixed(2)) > 150.0)
                    volh = Number(res.retorno.volH * 10).toFixed(2);
                else volh = Number(res.retorno.volH * 100).toFixed(2);

                setHeaderValues({
                    ativo: active,
                    data: res.retorno.date,
                    valor: `R$${res.retorno.value}`,
                    desvio: '30%',
                    volT: volh,
                    call: Number(res.retorno.volIC * 100).toFixed(2),
                    put: Number(res.retorno.volIP * 100).toFixed(2),
                    min: `R$${res.retorno.min}`,
                    max: `R$${res.retorno.max}`,
                    fechD: res.retorno.fechD
                    // fechD: `${Number(res.retorno.fechD * 100).toFixed(2)}%`
                });
                setFechamento(res.retorno.value);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    function getCallPut(dt, myFilter, list) {
        if (myFilter.btnType.call) {
            const find = isCalls.find((e) => e === dt.ticker.substring(4, 5));
            if (find) {
                list.push(dt);
            }
        } else {
            const find = isPuts.find((e) => e === dt.ticker.substring(4, 5));
            if (find) {
                list.push(dt);
            }
        }
    }

    function getFilteredData(myData, venc, myFilter) {
        const list = [];
        myData.forEach((dt) => {
            // VENCIMENTO
            if (
                Number(
                    dt.strike > myFilter.strike.start &&
                        Number(dt.strike < myFilter.strike.end)
                )
            ) {
                venc.forEach((ven) => {
                    if (ven.status) {
                        if (ven.label === dt.venc) {
                            getCallPut(dt, myFilter, list);
                        }
                    }
                });
            }
        });
        return list;
    }

    function comparar(a, b) {
        // eslint-disable-next-line no-nested-ternary
        return a > b ? 1 : a < b ? -1 : 1;
    }

    async function getCotacao(dataHora) {
        setLoading(true);
        if (!data[active]) {
            setDataTable([]);
            setDataChart([]);
            api.options
                .getChartsValues(active)
                .then((res) => {
                    // console.log(res);
                    const newData = {
                        filters: defaultFilters,
                        values: {}
                    };
                    newData.filters.strike.start = (
                        Number(fechamento) -
                        Number(fechamento) * 0.5
                    ).toFixed(2);
                    newData.filters.strike.end = (
                        Number(fechamento) +
                        Number(fechamento) * 0.5
                    ).toFixed(2);
                    const listHistory = [];
                    let dataRef = '';
                    // console.log(res.data.retorno.dates);
                    res.data.retorno.dates.forEach((e, i) => {
                        if (i === 0) {
                            dataRef = e.datas;
                            listHistory.push({label: e.datas, status: true});
                            // console.log('aqui 0', listHistory);
                        } else {
                            listHistory.push({label: e.datas, status: false});
                            // console.log('aqui 1', listHistory);
                        }
                    });
                    newData.values[dataRef] = [];
                    const listVenc = [];
                    const resultData = [];
                    const listStrikes = [];
                    res.data.retorno.data.forEach((resp, ii) => {
                        listStrikes.push(resp.pr_ex);
                        if (ii === 0) {
                            newData.filters.strike.min = Number(resp.pr_ex);
                            // newData.filters.strike.max = Number(
                            //     res.data.retorno.data[
                            //         res.data.retorno.data.length
                            //     ].pr_ex
                            // );
                        }

                        if (ii + 1 === res.data.retorno.data.length)
                            newData.filters.strike.max = Number(resp.pr_ex);

                        resultData.push({
                            ticker: resp.ser.split('_')[0],
                            strike: Number(resp.pr_ex),
                            coberto: resp.po_cob,
                            trava: resp.pos_tr,
                            descoberto: resp.pos_de,
                            total: resp.pos_to,
                            titular: resp.qtd_cl_tit,
                            lancador: resp.qtd_cl_lan,
                            dataHora: resp.data_hora,
                            venc: resp.dt_ven
                        });
                        listVenc.push(resp.dt_ven);
                    });
                    listStrikes.sort();
                    const uniqueVenc = [...new Set(listVenc)];
                    uniqueVenc.sort();
                    const listVencFinal = [];
                    uniqueVenc.forEach((u, i) => {
                        if (i === 0) {
                            newData.filters.venc.push({label: u, status: true});
                            listVencFinal.push({label: u, status: true});
                        } else {
                            newData.filters.venc.push({
                                label: u,
                                status: false
                            });
                            listVencFinal.push({label: u, status: false});
                        }
                    });
                    newData.filters.history = listHistory;
                    newData.values[dataRef].data = resultData;
                    newData.values[dataRef].venc = listVencFinal;
                    setData({...data, [active]: newData});
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        } else if (dataHora) {
            api.options
                .getSpecificChartsValues(active, dataHora)
                .then((res) => {
                    const resultData = [];
                    const listVenc = [];
                    let strikeMin = 0;
                    let strikeMax = 0;
                    res.data.retorno.data.forEach((resp, ii) => {
                        if (ii === 0) strikeMin = Number(resp.pr_ex);
                        if (ii + 1 === res.data.retorno.data.length)
                            strikeMax = Number(resp.pr_ex);
                        resultData.push({
                            ticker: resp.ser.split('_')[0],
                            strike: Number(resp.pr_ex),
                            coberto: resp.po_cob,
                            trava: resp.pos_tr,
                            descoberto: resp.pos_de,
                            total: resp.pos_to,
                            titular: resp.qtd_cl_tit,
                            lancador: resp.qtd_cl_lan,
                            dataHora: resp.data_hora,
                            venc: resp.dt_ven
                        });
                        listVenc.push(resp.dt_ven);
                    });
                    const uniqueVenc = [...new Set(listVenc)];
                    uniqueVenc.sort(comparar);
                    const listVencFinal = [];
                    uniqueVenc.forEach((u, i) => {
                        if (i === 0) {
                            listVencFinal.push({label: u, status: true});
                        } else {
                            listVencFinal.push({label: u, status: false});
                        }
                    });
                    const oldData = {};
                    const keys = Object.keys(data[active]?.values);
                    keys.forEach((key) => {
                        oldData[key] = {data: [], venc: []};
                        oldData[key].data = _.cloneDeep(
                            data[active].values[key].data
                        );
                        oldData[key].venc = _.cloneDeep(
                            data[active].values[key].venc
                        );
                    });
                    // --
                    oldData[dataHora] = {
                        data: resultData,
                        venc: listVencFinal
                    };
                    // --
                    const orig = _.cloneDeep(data);
                    orig[active].values = oldData;
                    orig[active].filters.strike.min =
                        strikeMin < orig[active].filters.strike.min
                            ? strikeMin
                            : orig[active].filters.strike.min;
                    orig[active].filters.strike.max =
                        strikeMax > orig[active].filters.strike.max
                            ? strikeMax
                            : orig[active].filters.strike.max;
                    setData(orig);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        } else {
            data[active].filters.history.forEach((dt) => {
                if (dt.status) {
                    const result = getFilteredData(
                        data[active].values[dt.label].data,
                        data[active].values[dt.label].venc,
                        data[active].filters
                    );
                    const listGraf = [];
                    result.forEach((e) => {
                        listGraf.push({
                            strike: e.strike,
                            coberto: e.coberto,
                            descoberto: e.descoberto,
                            trava: e.trava
                        });
                    });
                    setDataChart(listGraf);
                    setDataTable(result);
                    setLoading(false);
                }
            });
        }
    }

    useEffect(() => {
        if (active) {
            getHeaders();
        }
    }, [active]);

    useEffect(() => {
        if (fechamento) {
            getCotacao();
        }
    }, [fechamento]);

    const tableLabel = {
        tipo: {
            value: 'TIPO',
            selected: true
        },
        mod: {
            value: 'MODALIDADE',
            selected: true
        },
        money: {
            value: 'MONEYNESS',
            selected: true
        },
        strike: {
            value: 'STRIKE',
            selected: true
        },
        intrinseco: {
            value: 'VI',
            selected: false
        },
        extriseco: {
            value: 'VE',
            selected: false
        },
        ultimo: {
            value: 'ÚLTIMO',
            selected: true
        },
        negocio: {
            value: 'NEGÓCIOS',
            selected: true
        },
        vol: {
            value: 'VOLUME',
            selected: false
        },
        data: {
            value: 'DATA',
            selected: false
        },
        implicita: {
            value: 'VOL. IMPLÍCITA',
            selected: false
        },
        ticker: {
            value: 'OPÇÃO',
            selected: true
        },
        pteorico: {
            value: 'P.TEÓRICO',
            selected: true
        },
        delta: {
            value: 'DELTA',
            selected: false
        },
        gamma: {
            value: 'GAMMA',
            selected: false
        },
        theta: {
            value: 'THETA',
            selected: false
        },
        vega: {
            value: 'VEGA',
            selected: false
        }
    };

    const alterTab = (e) => {
        document.getElementById(e.target.id).classList.add('active');
        document.getElementById(active).classList.remove('active');
        setActive(e.target.id);
    };

    const collapse = () => {
        setExpand(!expand);
    };

    const deleteTab = (i, name) => {
        const origLabels = _.cloneDeep(labels);
        if (labels.length > 1) {
            const idx = origLabels.indexOf(
                origLabels.find((e) => e.name === name)
            );
            origLabels.splice(idx, 1);
            ativos.push({label: name, value: name});
            // console.log(ativos)
            // setAtivos(ativos)
            setLabels(origLabels);
            setActive(origLabels[0].name);
            setDetectUnload(true);
        } else {
            console.log('Não deixa excluir');
        }
    };

    const selectAtiv = (e) => {
        if (labels.find((el) => el.name === e)) {
            console.log('nada a fazer');
        } else {
            const search = e.value || e;
            const found = ativos.indexOf(
                ativos.find((el) => el.value === search)
            );
            ativos.splice(found, 1);
            setLabels((prev) => [...prev, {name: search}]);
            setActive(search);
            setDetectUnload(true);
            setOpenModal(false);
        }
    };
    function ContextAwareToggle({children, eventKey, callback}) {
        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey)
        );
        return (
            <button
                type="button"
                style={{backgroundColor: 'white', border: 'none'}}
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    }

    const handleClose = () => {
        setOpen(false);
    };

    const savePreference = () => {
        if (api.currentUser && api.currentSession) {
            if (labels.length < 4 || ilimitado) {
                const list = [];
                labels.forEach((l) => {
                    console.log(l);
                    list.push(l);
                });
                console.log(' final', list);

                const userPreference = {
                    page: 'map',
                    actives: list
                };

                api.user
                    .createPreference({preference: userPreference})
                    .then((res) => {
                        setDetectUnload(false);
                        setOpen(false);
                        notifySave();
                        console.log(res);
                    })
                    .catch((err) => {
                        toast.error('Erro ao Salvar Preferências', {
                            duration: 6000,
                            position: 'top-center',
                            style: {
                                fontSize: '18px',
                                minHeight: '60px',
                                minWidth: '180px',
                                color: '#fff',
                                background: '#e84c3d'
                            }
                        });
                        console.log(err);
                    });
            } else {
                toast.error('Seu plano está limitado a três ativos salvos');
            }
        } else {
            toast.error('Você não está logado!');
        }
    };

    useEffect(() => {
        if (user) {
            setPref(user.preference.map.actives);
            if (
                user?.assinatura?.vencimento &&
                moment() <= moment(user?.assinatura?.vencimento)
            ) {
                setIlimitado(true);
            }
        } else {
            setActive('PETR4');
        }

        api.charts
            .moreLiquid()
            .then((res) => {
                const list = [];
                res.retorno.forEach((a) => {
                    if (a?.ativo) {
                        list.push({label: a?.ativo, value: a?.ativo});
                    }
                });
                setAtivos(list);
            })
            .catch((err) => console.log(err));
        WebFont.load({
            google: {
                families: ['Roboto', 'Rubik']
            }
        });
    }, []);

    useEffect(() => {
        if (pref) {
            const listName = [];
            pref.forEach((p) => listName.push(p.name));
            setUserLabels(listName);
        }
    }, [pref]);

    useEffect(() => {
        if (user && userLabels) {
            // console.log('aqui');
            const prefLabels = [];
            userLabels.forEach((k) => {
                prefLabels.push({name: k});
            });
            const listUserLabels = [];
            userLabels.forEach((b) => {
                listUserLabels.push({name: b});
            });
            setLabels(prefLabels);
            setActive(userLabels[0]);
            setNewUserLabels(listUserLabels);
            setNumLabels(userLabels.length);
        }
    }, [userLabels]);

    useEffect(() => {
        if (labels.length > numLabels) {
            const found = labels[labels.length - 1];
            labels.forEach((l) => {
                document.getElementById(l.name).classList.remove('active');
            });
            document.getElementById(found.name).classList.add('active');
            setNumLabels(numLabels + 1);
        } else if (labels.length < numLabels) {
            const found = labels[0];
            labels.forEach((l) => {
                document.getElementById(l.name).classList.remove('active');
            });
            document.getElementById(found.name).classList.add('active');
            setNumLabels(numLabels - 1);
        }
    }, [labels]);

    useEffect(() => {
        const myDate = data[active]?.filters.history.find((e) => e.status);
        if (data[active]?.values[myDate.label]?.data?.length > 0) {
            const result = getFilteredData(
                data[active].values[myDate.label].data,
                data[active].values[myDate.label].venc,
                data[active].filters
            );
            const listGraf = [];
            // const start = fechamento - fechamento * 0.5;
            // const end = fechamento + fechamento * 0.5;
            result.forEach((e) => {
                // if (e.strike < end && e.strike > start) {
                listGraf.push({
                    strike: e.strike,
                    coberto: e.coberto,
                    descoberto: e.descoberto,
                    trava: e.trava
                });
                // }
            });
            setDataChart(listGraf);
            setDataTable(result);
        } else if (myDate) {
            getCotacao(myDate.label);
        }
    }, [data]);

    useEffect(() => {
        if (expand === false) {
            setSliderLabel('off');
        } else {
            setSliderLabel('on');
        }
    }, [expand]);

    useEffect(() => {
        if (detectUnload && user && user.preference.show_alert_map === 0) {
            if (JSON.stringify(newUserLabels) !== JSON.stringify(labels))
                setOpen(true);
            else setOpen(false);
        }
    }, [labels]);

    return (
        <>
            <MobileDetecter />
            <Loading open={loading} msg="Buscando dados ativo..." />
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <Accordion defaultActiveKey="1">
                                <Card>
                                    <Card.Header>
                                        <div className="container-fluid">
                                            <div
                                                className="row"
                                                style={{
                                                    alignItems: 'space-between',
                                                    justifyContent:
                                                        'space-between'
                                                }}
                                            >
                                                <ContextAwareToggle eventKey="0">
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            direction: 'row',
                                                            alignItems:
                                                                'center',
                                                            color: '#d36233'
                                                        }}
                                                        onClick={collapse}
                                                    >
                                                        <ion-icon
                                                            size="large"
                                                            name="options-outline"
                                                            style={{
                                                                color: '#d36233',
                                                                marginRight: 15
                                                            }}
                                                        />
                                                        FILTROS
                                                    </div>
                                                </ContextAwareToggle>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        direction: 'row',
                                                        justifyContent: 'end'
                                                    }}
                                                >
                                                    <ContextAwareToggle eventKey="0">
                                                        {expand ? (
                                                            <span
                                                                title=""
                                                                onClick={
                                                                    collapse
                                                                }
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    height: '100%'
                                                                }}
                                                            >
                                                                <ion-icon
                                                                    id="collapse"
                                                                    size="large"
                                                                    name="chevron-down-outline"
                                                                    style={{
                                                                        color: '#d36233',
                                                                        pointerEvents:
                                                                            'none'
                                                                    }}
                                                                />
                                                            </span>
                                                        ) : (
                                                            <span
                                                                title=""
                                                                onClick={
                                                                    collapse
                                                                }
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    height: '100%'
                                                                }}
                                                            >
                                                                <ion-icon
                                                                    id="collapse"
                                                                    size="large"
                                                                    name="chevron-back-outline"
                                                                    style={{
                                                                        color: '#d36233',
                                                                        pointerEvents:
                                                                            'none'
                                                                    }}
                                                                />
                                                            </span>
                                                        )}
                                                    </ContextAwareToggle>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <Filters
                                                setLoading={setLoading}
                                                data={data}
                                                setData={setData}
                                                active={active}
                                                sliderLabel={sliderLabel}
                                            />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                        <div className="col-lg-12">
                            <Accordion defaultActiveKey="0">
                                <Card>
                                    {/* <Accordion.Toggle
                                        as={Card.Header}
                                        eventKey="0"
                                        onClick={() => setExpandChart(!expandChart)}
                                                            > */}
                                    <Card.Header>
                                        <div className="container-fluid">
                                            <div
                                                className="row"
                                                style={{
                                                    alignItems: 'space-between',
                                                    justifyContent:
                                                        'space-between'
                                                }}
                                            >
                                                <ContextAwareToggle eventKey="0">
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            direction: 'row',
                                                            alignItems:
                                                                'center',

                                                            color: '#d36233'
                                                        }}
                                                        onClick={collapseChart}
                                                    >
                                                        CHARTS
                                                    </div>
                                                </ContextAwareToggle>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        direction: 'row',
                                                        justifyContent: 'end'
                                                    }}
                                                >
                                                    <ContextAwareToggle eventKey="0">
                                                        {expandChart && (
                                                            <span
                                                                title=""
                                                                onClick={
                                                                    collapseChart
                                                                }
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    height: '100%'
                                                                }}
                                                            >
                                                                <ion-icon
                                                                    id="collapse"
                                                                    size="large"
                                                                    name="chevron-down-outline"
                                                                    style={{
                                                                        color: '#d36233',
                                                                        pointerEvents:
                                                                            'none'
                                                                    }}
                                                                />
                                                            </span>
                                                        )}
                                                        {!expandChart && (
                                                            <span
                                                                title=""
                                                                onClick={
                                                                    collapseChart
                                                                }
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    height: '100%'
                                                                }}
                                                            >
                                                                <ion-icon
                                                                    id="collapse"
                                                                    size="large"
                                                                    name="chevron-back-outline"
                                                                    style={{
                                                                        color: '#d36233',
                                                                        pointerEvents:
                                                                            'none'
                                                                    }}
                                                                />
                                                            </span>
                                                        )}
                                                    </ContextAwareToggle>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Header>
                                    {/* </Accordion.Toggle> */}
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            {/* <ResponsiveContainer width="90vw" height="100%"> */}
                                            <BarChart
                                                width={size - 170}
                                                height={300}
                                                data={dataChart}
                                                // margin={{
                                                //     top: 20,
                                                //     right: 30,
                                                //     left: 20,
                                                //     bottom: 5
                                                // }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis
                                                    dataKey="strike"
                                                    angle={-35}
                                                    textAnchor="end"
                                                    tick={(e) => {
                                                        const {
                                                            payload: {value}
                                                        } = e;
                                                        // const color = value === "17.51" ? "red" : "#666";
                                                        e.fontSize = 12;
                                                        // e.fill = color;
                                                        return (
                                                            <Text {...e}>
                                                                {`R$${value}`}
                                                            </Text>
                                                        );
                                                    }}
                                                />
                                                <YAxis
                                                    tickFormatter={DataFormater}
                                                />
                                                <Tooltip
                                                    labelFormatter={(v) =>
                                                        new Intl.NumberFormat(
                                                            'pt-BR',
                                                            {
                                                                style: 'currency',
                                                                currency: 'BRL'
                                                            }
                                                        ).format(v)
                                                    }
                                                    formatter={(value) =>
                                                        new Intl.NumberFormat(
                                                            'pt-BR',
                                                            {
                                                                style: 'currency',
                                                                currency: 'BRL'
                                                            }
                                                        ).format(value)
                                                    }
                                                />
                                                <Legend
                                                    wrapperStyle={{
                                                        paddingTop: '15px'
                                                    }}
                                                />
                                                <Bar
                                                    dataKey="descoberto"
                                                    name="DESCOBERTO"
                                                    stackId="a"
                                                    barSize={100}
                                                    fill="#e37039"
                                                />
                                                <Bar
                                                    dataKey="trava"
                                                    name="TRAVA"
                                                    stackId="a"
                                                    barSize={100}
                                                    fill="#cac6c2"
                                                />
                                                <Bar
                                                    dataKey="coberto"
                                                    name="COBERTO"
                                                    stackId="a"
                                                    barSize={100}
                                                    fill="#0e0b0f"
                                                />
                                            </BarChart>
                                            {/* </ResponsiveContainer> */}
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>

                        <div className="col-lg-12">
                            <div className="card">
                                <ul
                                    className="nav nav-tabs"
                                    id="myTab"
                                    role="tablist"
                                    style={{
                                        display: 'flex',
                                        marginLeft: 7,
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <div className="row">
                                        {labels.length > 0 &&
                                            labels &&
                                            labels.map((dt, i) =>
                                                i === 0 ? (
                                                    <li
                                                        key={i}
                                                        className="nav-item"
                                                    >
                                                        {active === dt.name && (
                                                            <span
                                                                style={{
                                                                    marginRight:
                                                                        -25,
                                                                    color: '#d36233',
                                                                    cursor: 'pointer',
                                                                    paddingLeft:
                                                                        '4rem'
                                                                }}
                                                                onClick={() =>
                                                                    deleteTab(
                                                                        i,
                                                                        dt.name
                                                                    )
                                                                }
                                                            >
                                                                x
                                                            </span>
                                                        )}
                                                        <span
                                                            className="nav-link active"
                                                            id={dt.name}
                                                            data-toggle="tab"
                                                            onClick={alterTab}
                                                            role="tab"
                                                            aria-controls="home"
                                                            aria-selected="false"
                                                            style={{
                                                                cursor: 'pointer',
                                                                marginTop:
                                                                    active ===
                                                                    dt.name
                                                                        ? -24
                                                                        : 0
                                                            }}
                                                        >
                                                            {dt.name}
                                                        </span>
                                                    </li>
                                                ) : (
                                                    <li
                                                        key={i}
                                                        className="nav-item"
                                                    >
                                                        {active === dt.name && (
                                                            <span
                                                                style={{
                                                                    marginRight:
                                                                        -25,
                                                                    color: '#d36233',
                                                                    cursor: 'pointer',
                                                                    paddingLeft:
                                                                        '4rem'
                                                                }}
                                                                onClick={() =>
                                                                    deleteTab(
                                                                        i,
                                                                        dt.name
                                                                    )
                                                                }
                                                            >
                                                                x
                                                            </span>
                                                        )}
                                                        <span
                                                            className="nav-link"
                                                            id={dt.name}
                                                            data-toggle="tab"
                                                            onClick={alterTab}
                                                            role="tab"
                                                            aria-controls="home"
                                                            aria-selected="false"
                                                            style={{
                                                                cursor: 'pointer',
                                                                marginTop:
                                                                    active ===
                                                                    dt.name
                                                                        ? -24
                                                                        : 0
                                                            }}
                                                        >
                                                            {dt.name}
                                                        </span>
                                                    </li>
                                                )
                                            )}
                                        <span
                                            // className="nav-link"
                                            id="plus"
                                            // data-toggle="tab"
                                            onClick={() => setOpenModal(true)}
                                            role="tab"
                                            style={{
                                                height:
                                                    labels.length === 0 && 60,
                                                cursor: 'pointer',
                                                fontWeight: 'bold',
                                                fontSize: 20,
                                                color: '#d36233',
                                                paddingTop: '0.4rem',
                                                paddingLeft: '1rem'
                                                // padding: '0.6rem 1rem'
                                            }}
                                        >
                                            +
                                        </span>
                                    </div>
                                    <div>
                                        <span
                                            title="Salvar"
                                            onClick={savePreference}
                                            role="tab"
                                            style={{
                                                height:
                                                    labels.length === 0 && 60,
                                                marginRight: 20,
                                                cursor: 'pointer',
                                                // fontWeight: 'bold',
                                                fontSize: 20,
                                                color: '#d36233',
                                                paddingTop: '0.4rem',
                                                paddingLeft: '1rem'
                                                // padding: '0.6rem 1rem'
                                            }}
                                        >
                                            <ion-icon
                                                name="save-outline"
                                                style={{pointerEvents: 'none'}}
                                            />
                                        </span>
                                    </div>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div
                                        className="tab-pane fade show active"
                                        role="tabpanel"
                                        aria-labelledby="profile-tab"
                                    >
                                        <div className="card-body">
                                            <div
                                                className="d-flex"
                                                style={{
                                                    direction: 'row',
                                                    alignItems: 'center',
                                                    marginBottom: 30
                                                }}
                                            >
                                                <div
                                                    className="card-body p-0"
                                                    style={{overflow: 'scroll'}}
                                                >
                                                    <div className="container-fluid">
                                                        <Table
                                                            active={active}
                                                            dt1={dataTable}
                                                            tableLabel={
                                                                tableLabel
                                                            }
                                                            notify={notify}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="d-flex justify-content-between align-items-center border-top"
                                    style={{marginTop: -15}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AlertSave
                when={open}
                title="DESEJA SAIR SEM SALVAR?"
                pageAlert="map"
            />
            <Calculadora />
            <Modal
                size="lg"
                show={openModal}
                backdrop="static"
                style={{zIndex: 9999}}
            >
                <form className="form-horizontal">
                    <Modal.Body>
                        <div className="container-fluid">
                            <div
                                className="row"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    color: '#d36233'
                                }}
                            >
                                <p>PERSONALIZAÇÃO GRADE DE OPÇÕES</p>

                                <p
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => setOpenModal(false)}
                                >
                                    X
                                </p>
                            </div>
                            <div
                                className="card-body"
                                style={{marginTop: -30, fontFamily: 'Roboto'}}
                            >
                                <div
                                    className="form-group row"
                                    id="dadosBasicos"
                                >
                                    <div className="col-sm-12">
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            placeholder="escreva aqui o ticker da ação"
                                            onChange={(e) => selectAtiv(e)}
                                            isSearchable
                                            options={ativos}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="row"
                                style={{
                                    marginTop: -10,
                                    display: 'flex',
                                    justifyContent: 'start',
                                    color: '#d36233'
                                }}
                            >
                                <p>MAIS LÍQUIDAS NO MOMENTO</p>
                            </div>
                            <div className="card-body">
                                <div
                                    className="form-group row"
                                    id="dadosBasicos"
                                >
                                    <Grid
                                        container
                                        spacing={1}
                                        style={{
                                            justifyContent: 'space-around',
                                            alignItems: 'center',
                                            marginBottom: -50,
                                            marginTop: -55
                                        }}
                                    >
                                        <Grid>
                                            <img
                                                style={{
                                                    width: 100,
                                                    cursor: labels.find(
                                                        (el) =>
                                                            el.name === 'VALE3'
                                                    )
                                                        ? 'not-allowed'
                                                        : 'pointer'
                                                }}
                                                src="./img/vale-sa.svg"
                                                alt="vale"
                                                onClick={() =>
                                                    selectAtiv('VALE3')
                                                }
                                            />
                                        </Grid>
                                        <Grid>
                                            <img
                                                style={{
                                                    width: 100,
                                                    cursor: labels.find(
                                                        (el) =>
                                                            el.name === 'PETR4'
                                                    )
                                                        ? 'not-allowed'
                                                        : 'pointer'
                                                }}
                                                src="./img/petrobras-logo.svg"
                                                alt="Petrobras"
                                                onClick={() =>
                                                    selectAtiv('PETR4')
                                                }
                                            />
                                        </Grid>
                                        <Grid>
                                            <img
                                                style={{
                                                    width: 100,
                                                    cursor: labels.find(
                                                        (el) =>
                                                            el.name === 'BBAS3'
                                                    )
                                                        ? 'not-allowed'
                                                        : 'pointer'
                                                }}
                                                src="./img/banco-do-brasil.svg"
                                                alt="Banco-Brasil"
                                                onClick={() =>
                                                    selectAtiv('BBAS3')
                                                }
                                            />
                                        </Grid>
                                        <Grid>
                                            <img
                                                style={{
                                                    width: 100,
                                                    cursor: labels.find(
                                                        (el) =>
                                                            el.name === 'MGLU3'
                                                    )
                                                        ? 'not-allowed'
                                                        : 'pointer'
                                                }}
                                                src="./img/magalu.svg"
                                                alt="Magalu"
                                                onClick={() =>
                                                    selectAtiv('MGLU3')
                                                }
                                            />
                                        </Grid>
                                        <Grid>
                                            <img
                                                style={{
                                                    width: 100,
                                                    cursor: labels.find(
                                                        (el) =>
                                                            el.name === 'B3SA3'
                                                    )
                                                        ? 'not-allowed'
                                                        : 'pointer'
                                                }}
                                                src="./img/bovespa1.svg"
                                                alt="Bovespa"
                                                onClick={() =>
                                                    selectAtiv('B3SA3')
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </form>
            </Modal>
            <Toaster />
        </>
    );
};

export default Dashboard;
