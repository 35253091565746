/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
import React, {useState} from 'react';
import {Card} from 'react-bootstrap';
import {TextField, InputAdornment, Button} from '@material-ui/core';
import moment from 'moment';
import api from '@app/utils/api';

import icone from 'src/img/OPTI_logo.avif';
// import cancel from 'src/img/icone-cancelLaranja.avif';
// import check from 'src/img/icone-check.avif';
import './modalstyles.css';

export default function ConfigModal({
    classes,
    create,
    change,
    setChange,
    setCreate,
    profile,
    perfiladm,
    setPerfiladm,
    setPerfilDesativado
}) {
    const handleClose = () => {
        if (create) setCreate(false);
        else setChange(false);
    };

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [senha, setSenha] = useState('');
    const [perfil, setPerfil] = useState('');
    const [obs, setObs] = useState('');
    const [id, setId] = useState('');
    const user = api.currentUser.name;

    const handleAdd = () => {
        api.admin
            .addPerfil(name, email, senha, perfil, obs, user)
            .then((res) => {
                if (res.status === 200) {
                    api.admin
                        .findPerfil()
                        .then((res) => {
                            setPerfiladm(res.data[0]);
                        })
                        .catch((err) => console.log(err));
                    handleClose();
                }
            })
            .catch((e) => console.log(e));
    };

    const handleAlter = () => {
        api.admin
            .updatePerfil(name, email, senha, perfil, obs, id)
            .then((res) => {
                if (res.status === 200) {
                    api.admin
                        .findPerfil()
                        .then((res) => {
                            setPerfiladm(res.data[0]);
                        })
                        .catch((err) => console.log(err));
                    handleClose();
                }
            })
            .catch((e) => console.log(e));
    };

    const turnOff = () => {
        api.admin
            .turnOff(id, obs)
            .then((res) => {
                if (res.status === 200) {
                    api.admin
                        .findPerfil()
                        .then((res) => {
                            setPerfiladm(res.data[0]);
                            setPerfilDesativado(res.data[1]);
                            handleClose();
                        })
                        .catch((err) => console.log(err));
                }
            })
            .catch((err) => console.log(err));
    };

    React.useEffect(() => {
        if (profile) {
            setName(profile.nome);
            setEmail(profile.email);
            setSenha(profile.senha);
            setPerfil(profile.perfil);
            setObs(profile.observacao);
            setId(profile.id);
        }
    }, [profile]);

    return (
        <>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontFamily: 'Roboto'
                }}
            >
                <Card
                    style={{
                        backgroundColor: '#cac6c2',
                        width: '40%',
                        height: '80%',
                        display: 'flex',
                        flexDirection: 'row',
                        borderRadius: '15px',
                        fontSize: 26
                    }}
                >
                    <div style={{padding: '2.5rem', width: '100%'}}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                // justifyContent: 'space-between',
                                width: '54%'
                            }}
                        >
                            <img src={icone} alt="" style={{width: '50px'}} />
                            <div
                                style={{
                                    fontWeight: 700,
                                    fontSize: 26,
                                    marginLeft: '5%'
                                }}
                            >
                                {create
                                    ? 'ADICIONAR PERFIL'
                                    : change
                                    ? 'ALTERAÇÃO DE PERFIL'
                                    : null}
                            </div>
                        </div>
                        <div
                            style={{
                                marginTop: '1rem',
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div className="dadostext">
                                ID:{' '}
                                <span style={{color: '#d36233'}}>
                                    {create
                                        ? perfiladm.at(-1).id + 1
                                        : change
                                        ? profile.id
                                        : null}
                                </span>
                            </div>
                            <div style={{color: '#d36233'}}>
                                |{'   '}*
                                <span style={{fontSize: 15}}>
                                    CAMPOS OBRIGATÓRIOS
                                </span>
                                {'   '}|
                            </div>
                        </div>
                        <div>
                            <TextField
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.myInput,
                                    startAdornment: (
                                        <InputAdornment>NOME:*</InputAdornment>
                                    )
                                }}
                                value={name}
                                onChange={(event) =>
                                    setName(event.target.value)
                                }
                                variant="standard"
                                className={classes.myTextField}
                            />
                        </div>
                        <div>
                            <TextField
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.myInput,
                                    startAdornment: (
                                        <InputAdornment>
                                            E-MAIL:*
                                        </InputAdornment>
                                    )
                                }}
                                value={email}
                                onChange={(event) =>
                                    setEmail(event.target.value)
                                }
                                variant="standard"
                                className={classes.myTextField}
                            />
                        </div>
                        <div>
                            <div>
                                <TextField
                                    InputProps={{
                                        disableUnderline: true,
                                        className: classes.myInput,
                                        startAdornment: (
                                            <InputAdornment>
                                                SENHA:*
                                            </InputAdornment>
                                        )
                                    }}
                                    value={senha}
                                    onChange={(event) =>
                                        setSenha(event.target.value)
                                    }
                                    variant="standard"
                                    className={classes.myTextField}
                                />
                            </div>

                            <div>
                                <TextField
                                    InputProps={{
                                        disableUnderline: true,
                                        className: classes.myInput,
                                        startAdornment: (
                                            <InputAdornment>
                                                PERFIL:*
                                            </InputAdornment>
                                        )
                                    }}
                                    value={perfil}
                                    onChange={(event) =>
                                        setPerfil(event.target.value)
                                    }
                                    variant="standard"
                                    className={classes.myTextField}
                                />
                            </div>
                            <div className="dadostext">
                                CADASTRADO POR:{' '}
                                <span style={{color: '#d36233'}}>
                                    {' '}
                                    {api.currentUser.name}
                                </span>
                            </div>
                            <div className="dadostext">
                                DATA CADASTRO:{' '}
                                <span style={{color: '#d36233'}}>
                                    {' '}
                                    {moment().format('DD/MM/YYYY')}
                                </span>
                            </div>

                            <div>
                                <TextField
                                    InputProps={{
                                        disableUnderline: true,
                                        className: classes.myInput,
                                        startAdornment: (
                                            <InputAdornment>
                                                OBS:
                                            </InputAdornment>
                                        )
                                    }}
                                    value={obs}
                                    onChange={(event) =>
                                        setObs(event.target.value)
                                    }
                                    variant="standard"
                                    className={classes.observation}
                                />
                            </div>
                            {change ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-around',
                                        margin: '2rem 1rem 1rem 1rem'
                                    }}
                                >
                                    <Button
                                        className={classes.buttonModal}
                                        onClick={() => handleAlter()}
                                    >
                                        ALTERAR PERFIL
                                    </Button>

                                    <Button
                                        className={classes.buttonModal}
                                        onClick={() => turnOff()}
                                    >
                                        DESATIVAR PERFIL
                                    </Button>
                                </div>
                            ) : create ? (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-around',
                                        margin: '2rem 1rem 1rem 1rem'
                                    }}
                                >
                                    <Button
                                        className={classes.buttonModal}
                                        onClick={() => handleAdd()}
                                    >
                                        CRIAR PERFIL
                                    </Button>
                                </div>
                            ) : null}
                        </div>
                    </div>

                    <div style={{margin: '-5px -5px 0px 0px'}}>
                        <ion-icon
                            style={{
                                fontSize: 32,
                                color: '#d36233',
                                backgroundColor: '#cac6c2',
                                borderRadius: '50%',
                                cursor: 'pointer'
                            }}
                            onClick={handleClose}
                            name="close-circle-outline"
                        />
                    </div>
                </Card>
            </div>
        </>
    );
}
