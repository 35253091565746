import React from 'react';
import {useTranslation} from 'react-i18next';
import {DateTime} from 'luxon';
import packageJson from '../../../../package.json';

const {version} = packageJson;

const Footer = () => {
    const [t] = useTranslation();

    return (
        <footer className="main-footer">
            <div className="float-right d-none d-sm-block">
                <b>{t('footer.version')}</b>
                <b> </b>
                <span>{version}</span>
            </div>
            <strong>
                <span>Copyright © {DateTime.now().toFormat('y')} </span>
                <a
                    href="http://www.useopti.com.br"
                    target="_blank"
                    rel="itfast solucoes noreferrer"
                >
                    useopti.com.br
                </a>
                <span>.</span>
            </strong>
            <span> </span>
            <span>{t('footer.copyright')}</span>
        </footer>
    );
};

export default Footer;
