/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, {useState} from 'react';

import {toast} from 'react-toastify';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import app from '../firebase/base';

export default function RecoverPassword({forgotPassword, setForgotPassword}) {
    const [emailResPassword, setEmailResPassword] = useState();
    const [close, setClose] = useState(false);

    const useStyles = makeStyles((theme) => ({
        input: {
            input: {color: '#e37039'},
            '& label': {
                color: '#cac6c2'
            },
            '& input': {
                color: '#37039'
            },
            '&:hover label': {
                fontWeight: 700
            },
            '& label.Mui-focused': {
                color: '#e37039'
            },
            '& .MuiInput-underline:after': {
                borderBottomColor: '#e37039'
            },
            '& .MuiInput-underline:before': {
                borderBottomColor: '#cac6c2'
            }
        },
        multilineColor: {
            color: '#e37039'
        }
    }));

    const classes = useStyles();

    const resetPassword = (email) => {
        console.log(email);
        const actionCodeSettings = {
            url: window.location.href,
            handleCodeInApp: true
        };
        try {
            app.auth()
                .sendPasswordResetEmail(email, actionCodeSettings)
                .then((res) => {
                    // setForgotPassword(false);
                    setClose(true);
                })
                .catch((error) => {
                    console.log(error);
                    if (error.code === 'auth/invalid-email') {
                        toast.error('Email inserido inválido.', {
                            position: 'top-center',
                            autoClose: false,
                            hideProgressBar: false,
                            closeOnClick: true
                        });
                    }
                    if (error.code === 'auth/user-not-found') {
                        toast.error('Usuário não encontrado.', {
                            position: 'top-center',
                            autoClose: false,
                            hideProgressBar: false,
                            closeOnClick: true
                        });
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };

    const handleChange = (e) => {
        setEmailResPassword(e.target.value);
    };

    const handleClose = () => setForgotPassword(false);

    const closeDialog = () => setClose(false);

    return (
        <>
            <Dialog
                open={forgotPassword}
                onClose={handleClose}
                className={classes.dialog}
                PaperProps={{
                    style: {
                        backgroundColor: '#313031',
                        boxShadow: 'none',
                        color: '#e37039'
                    }
                }}
            >
                <DialogTitle>REDEFINIR SENHA?</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{color: '#e37039'}}>
                        Insira um email válido para enviarmos sua redefinição de
                        senha.
                    </DialogContentText>
                    <TextField
                        className={classes.input}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Endereço de email"
                        type="email"
                        fullWidth
                        color="#e37039"
                        onChange={handleChange}
                        InputProps={{
                            style: {
                                color: '#e37039'
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setForgotPassword(false)}
                        style={{color: '#cac6c2'}}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => resetPassword(emailResPassword)}
                        style={{color: '#cac6c2'}}
                    >
                        Enviar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={close}
                onClose={closeDialog}
                PaperProps={{
                    style: {
                        backgroundColor: '#313031',
                        boxShadow: 'none',
                        color: '#e37039'
                    }
                }}
            >
                <DialogTitle>REDEFINIÇÃO DE SENHA ENVIADO!</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{color: '#e37039'}}>
                        Verifique seu email para redefinir sua senha.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        style={{color: '#cac6c2'}}
                        onClick={() => {
                            setClose(false);
                            setForgotPassword(false);
                        }}
                    >
                        Fechar
                    </Button>
                    <Button
                        style={{color: '#cac6c2'}}
                        onClick={() => setClose(false)}
                    >
                        Tentar Novamente
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
