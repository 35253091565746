/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {Modal} from 'react-bootstrap';
import {Grid} from '@material-ui/core';

import Select from 'react-select';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ati from '../utils/ativos';

export default function SelectWalletAtiv({close, setAtiv}) {
    return (
        <>
            <Modal.Body>
                <div className="container-fluid">
                    <div
                        className="row"
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            color: '#d36233'
                        }}
                    >
                        <p>PERSONALIZAÇÃO DA CARTEIRA</p>
                        <p
                            style={{cursor: 'pointer'}}
                            onClick={() => close(false)}
                        >
                            X
                        </p>
                    </div>
                    <div className="card-body" style={{marginTop: -30}}>
                        <div className="form-group row" id="dadosBasicos">
                            <div className="col-sm-12">
                                <Select
                                    // width="300"
                                    // styles={customStyles}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    placeholder="escreva aqui o nome da ação"
                                    onChange={(e) => setAtiv(e.value)}
                                    isSearchable
                                    options={ati}
                                />

                                {/* {message === true ? (
                                    <span style={{color: 'red'}}>
                                        Escolha um ativo para continuar
                                    </span>
                                ) : (
                                    ''
                                )} */}
                            </div>
                        </div>
                    </div>
                    <div
                        className="row"
                        style={{
                            marginTop: -10,
                            display: 'flex',
                            justifyContent: 'start',
                            color: '#d36233'
                        }}
                    >
                        <p>MAIS LÍQUIDAS NO MOMENTO</p>
                    </div>

                    <div className="card-body">
                        <div className="form-group row" id="dadosBasicos">
                            <Grid
                                container
                                spacing={1}
                                style={{
                                    justifyContent: 'space-around',
                                    alignItems: 'center',
                                    marginBottom: -50,
                                    marginTop: -55
                                }}
                            >
                                <Grid>
                                    <img
                                        style={{
                                            width: 100,
                                            cursor: 'pointer'
                                        }}
                                        src="./img/vale-sa.svg"
                                        alt="vale"
                                        onClick={() => setAtiv('VALE3')}
                                    />
                                </Grid>
                                <Grid>
                                    <img
                                        style={{
                                            width: 100,
                                            cursor: 'pointer'
                                        }}
                                        src="./img/petrobras-logo.svg"
                                        alt="Petrobras"
                                        onClick={() => setAtiv('PETR4')}
                                    />
                                </Grid>
                                <Grid>
                                    <img
                                        style={{
                                            width: 100,
                                            cursor: 'pointer'
                                        }}
                                        src="./img/banco-do-brasil.svg"
                                        alt="Banco-Brasil"
                                        onClick={() => setAtiv('BBAS3')}
                                    />
                                </Grid>
                                <Grid>
                                    <img
                                        style={{
                                            width: 100,
                                            cursor: 'pointer'
                                        }}
                                        src="./img/magalu.svg"
                                        alt="Magalu"
                                        onClick={() => setAtiv('MGLU3')}
                                    />
                                </Grid>
                                <Grid>
                                    <img
                                        style={{
                                            width: 100,
                                            cursor: 'pointer'
                                        }}
                                        src="./img/bovespa1.svg"
                                        alt="Bovespa"
                                        onClick={() => setAtiv('B3SA3')}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </>
    );
}
