/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable for-direction */

import React, {useState, useEffect} from 'react';
import {Card} from 'react-bootstrap';
import {Grid} from '@material-ui/core';
import {AreaChart, Area, XAxis, YAxis, Tooltip} from 'recharts';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import api from '@app/utils/api';
import moment from 'moment';

const useStyles = makeStyles((theme) =>
    createStyles({
        myGrid: {
            minWidth: 65,
            maxHeight: 30,
            maxWidth: 65,
            margin: theme.spacing(1),
            marginLeft: '-3px',
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            textAlign: 'center',
            cursor: 'pointer',
            fontSize: 12,
            border: '2px solid #c5c5c5',
            borderRadius: '2px'
        },
        myGridSelected: {
            minWidth: 65,
            maxWidth: 65,
            maxHeight: 30,
            backgroundColor: '#e37039',
            alignItems: 'center',
            justifyContent: 'center',
            margin: theme.spacing(1),
            marginLeft: '-3px',
            fontSize: 12,
            display: 'flex',
            textAlign: 'center',
            cursor: 'pointer',
            border: '2px solid #e37039',
            borderRadius: '2px',
            color: '#fff'
        }
    })
);

export default function MercadoTable({
    active,
    setFavoriteAtivo,
    setRepetido,
    dataActives,
    user,
    setLoading,
    assinatura,
    ativos,
    setNotify,
    screen,
    tmpTimeLabels
}) {
    const classes = useStyles();
    const [labels, setLabels] = useState([
        {
            name: '7',
            status: true
        },
        {
            name: '30',
            status: false
        },
        {
            name: '365',
            status: false
        }
    ]);
    const [up, setUp] = useState(false);
    const [close, setClose] = useState([]);
    const [data, setData] = useState([]);
    const [filled, setFilled] = useState(false);
    const [dominio, setDominio] = useState();
    const [dataTable, setDataTable] = useState();

    const changeFilled = () => {
        if (!filled) {
            setFilled(true);
        } else {
            setFilled(false);
        }
    };

    const getData = () => {
        dataActives.forEach((d) => {
            if (d.name === active) {
                const list = [];
                list.push({
                    max: d.maxSemanal,
                    maxMensal: d.maxMensal,
                    maxAnual: d.maxAnual,
                    min: d.minSemanal,
                    minMensal: d.minMensal,
                    minAnual: d.minAnual,
                    value: d.fechDiario,
                    fechamento: d.fechamento,
                    date: d.data,
                    logo: d.logo,
                    chart: d.closeSemanal,
                    implMax: d.volIpMax,
                    implMensalMax: d.volIpMensalMax,
                    implAnualMax: d.volIpAnualMax,
                    implMin: d.volIpMin,
                    implMensalMin: d.volIpMensalMin,
                    implAnualMin: d.volIpAnualMin,
                    hisMax: d.volHisMax,
                    hisMensalMax: d.volHisMensalMax,
                    hisAnualMax: d.volHisAnualMax,
                    hisMin: d.volHisMin,
                    hisMensalMin: d.volHisMensalMin,
                    hisAnualMin: d.volHisAnualMin
                });
                setDataTable(list);
            }
        });
    };

    const changeLabels = (name) => {
        const tmpLabels = [];
        labels.forEach((l) => {
            if (l.name === name && !l.status) {
                tmpLabels.push({
                    name: l.name,
                    status: true
                });
            } else if (l.name === name && l.status) {
                tmpLabels.push({
                    name: l.name,
                    status: true
                });
            } else {
                tmpLabels.push({
                    name: l.name,
                    status: false
                });
            }
        });
        // console.log(tmpLabels);
        setLabels(tmpLabels);
        // setTmpTimeLabels(tmpLabels);
    };

    const saveFavorite = () => {
        if (api.currentUser && api.currentSession) {
            if (ativos.length < 3 || assinatura) {
                setFavoriteAtivo({name: active});
                changeFilled();
            } else {
                setNotify(true);
            }
        } else {
            setNotify(true);
        }
    };

    const deleteSave = () => {
        if (api.currentUser && api.currentSession) {
            setRepetido({name: active});
            changeFilled();
        }
    };
    useEffect(() => {
        if (user) {
            user.forEach((ativo) => {
                if (ativo.name === active) {
                    setFilled(true);
                }
            });
        }
    }, [user]);

    useEffect(() => {
        if (dataActives) getData();
    }, [dataActives]);

    useEffect(() => {
        if (dataTable) {
            const tam = dataTable[0].chart.length;
            let start;
            if (labels[0].status) {
                start = 7;
            } else if (labels[1].status) {
                start = 30;
            } else {
                start = tam;
            }
            const tmpChart = [];
            const tmpDominio = [];
            const listData = [];
            console.log(tam, dataTable, start);
            for (let i = start - 1; i >= 0; i--) {
                console.log(dataTable[0].chart[i]);
                tmpChart.push(dataTable[0].chart[i]);
                listData.push({
                    dia: dataTable[0].date[i],
                    valor: dataTable[0].chart[i]
                });
            }
            const negative = -0.0001;
            if (Number(dataTable[0].fechamento) >= negative) setUp(true);
            setClose(dataTable[0].fechamento.replace('.', ','));
            setData(listData);
            tmpDominio.push(Math.max(...tmpChart));
            tmpDominio.push(Math.min(...tmpChart));
            setLoading(false);
            setDominio(tmpDominio);
        }
    }, [dataTable, labels]);

    useEffect(() => {
        if (tmpTimeLabels) {
            tmpTimeLabels.forEach((t) => {
                // console.log(t);
                if (t.label === '7 DIAS' && t.status) {
                    setLabels([
                        {
                            name: '7',
                            status: true
                        },
                        {
                            name: '30',
                            status: false
                        },
                        {
                            name: '365',
                            status: false
                        }
                    ]);
                } else if (t.label === '30 DIAS' && t.status) {
                    setLabels([
                        {
                            name: '7',
                            status: false
                        },
                        {
                            name: '30',
                            status: true
                        },
                        {
                            name: '365',
                            status: false
                        }
                    ]);
                } else if (t.label === '365 DIAS' && t.status) {
                    setLabels([
                        {
                            name: '7',
                            status: false
                        },
                        {
                            name: '30',
                            status: false
                        },
                        {
                            name: '365',
                            status: true
                        }
                    ]);
                }
            });
        }
    }, [tmpTimeLabels]);

    return dataTable ? (
        <>
            {/* <Loading open={loading} msg="Buscando dados ativo..." /> */}
            {screen ? (
                <Card
                    style={{
                        height: '20.5rem',
                        width: screen >= 1920 ? '27rem' : '25rem',
                        marginBottom: 10
                    }}
                >
                    <Card.Header style={{border: 'none', boxShadow: 'none'}}>
                        <div style={{display: 'flex'}}>
                            <div>
                                <img
                                    style={{width: 45}}
                                    src={dataTable[0].logo}
                                    alt=""
                                />
                            </div>
                            <div
                                style={{
                                    textAlign: 'left',
                                    marginLeft: 10,
                                    width: '250px'
                                }}
                            >
                                <div
                                    style={{
                                        fontSize: 28,
                                        // fontWeight: 600,
                                        color: '#e37039'
                                    }}
                                >
                                    {active}
                                </div>
                                <div
                                    style={{
                                        fontSize: 12,
                                        fontWeight: 700,
                                        marginTop: -8
                                    }}
                                >
                                    {/* name */}
                                </div>
                            </div>
                            <div style={{textAlign: 'right'}}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <div
                                        style={{
                                            marginBottom: -5,
                                            fontSize: 14,
                                            fontWeight: 700,
                                            color: '#e37039'
                                        }}
                                    >
                                        R$
                                    </div>
                                    <div
                                        style={{
                                            fontSize: 24,
                                            fontWeight: 700,
                                            color: '#e37039'
                                        }}
                                    >
                                        {new Intl.NumberFormat('pt-BR', {
                                            minimumFractionDigits: 2
                                        }).format(dataTable[0].value)}
                                    </div>
                                    <div style={{width: '35px'}}>
                                        {!filled ? (
                                            <ion-icon
                                                // slot="icon-only"
                                                name="star-outline"
                                                style={{
                                                    fontSize: 22,
                                                    color: '#e37039',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {
                                                    saveFavorite();
                                                }}
                                            />
                                        ) : (
                                            <ion-icon
                                                // slot="icon-only"
                                                name="star"
                                                style={{
                                                    fontSize: 22,
                                                    color: '#e37039',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => {
                                                    // changeFilled();
                                                    deleteSave();
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        marginTop: -8,
                                        alignItems: 'center',
                                        marginLeft: 15
                                    }}
                                >
                                    <div>
                                        {!up ? (
                                            <ion-icon
                                                name="arrow-down-outline"
                                                style={{
                                                    fontSize: 12,
                                                    color: '#e37039'
                                                }}
                                            />
                                        ) : (
                                            <ion-icon
                                                name="arrow-up-outline"
                                                style={{
                                                    fontSize: 12,
                                                    color: '#e37039'
                                                }}
                                            />
                                        )}
                                    </div>
                                    <div
                                        style={{fontSize: 12, fontWeight: 700}}
                                    >
                                        {close}%
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body style={{marginTop: -25}}>
                        <div style={{marginLeft: '-60px'}}>
                            <AreaChart
                                width={screen >= 1920 ? 452 : 420}
                                height={130}
                                data={data}
                            >
                                <defs>
                                    <linearGradient
                                        id="colorUv"
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                    >
                                        <stop
                                            offset="5%"
                                            stopColor="#e37039"
                                            stopOpacity={0.8}
                                        />
                                        <stop
                                            offset="95%"
                                            stopColor="#e37039"
                                            stopOpacity={0}
                                        />
                                    </linearGradient>
                                </defs>
                                <Tooltip />
                                <XAxis
                                    dataKey="dia"
                                    axisLine={false}
                                    tickLine={false}
                                    tick={false}
                                />
                                <YAxis
                                    datakey="valor"
                                    domain={
                                        dominio
                                            ? [dominio[1], dominio[0]]
                                            : [data]
                                    }
                                    axisLine={false}
                                    tickLine={false}
                                    tick={false}
                                />

                                <Area
                                    // type="monotone"
                                    dataKey="valor"
                                    stroke="#e37039"
                                    fillOpacity={1}
                                    fill="url(#colorUv)"
                                />
                            </AreaChart>
                        </div>
                        <div style={{display: 'flex', marginTop: '10px'}}>
                            <Grid
                                className={
                                    labels[0].status
                                        ? classes.myGridSelected
                                        : classes.myGrid
                                }
                                style={{
                                    // fontSize: 12,
                                    fontWeight: 600,
                                    // marginLeft: 15,
                                    marginTop: -10
                                }}
                                onClick={() => changeLabels(labels[0].name)}
                            >
                                7 DIAS
                            </Grid>
                            <Grid
                                className={
                                    labels[1].status
                                        ? classes.myGridSelected
                                        : classes.myGrid
                                }
                                style={{
                                    // fontSize: 12,
                                    fontWeight: 600,
                                    // marginLeft: 15,
                                    marginTop: -10
                                }}
                                onClick={() => changeLabels(labels[1].name)}
                            >
                                30 DIAS
                            </Grid>
                            <Grid
                                className={
                                    labels[2].status
                                        ? classes.myGridSelected
                                        : classes.myGrid
                                }
                                style={{
                                    // fontSize: 12,
                                    fontWeight: 600,
                                    // marginLeft: 15,
                                    marginTop: -10
                                }}
                                onClick={() => changeLabels(labels[2].name)}
                            >
                                365 DIAS
                            </Grid>
                        </div>
                        <div
                            style={{display: 'flex', justifyContent: 'center'}}
                        >
                            <div
                                style={{
                                    textAlign: 'center',
                                    borderRight: '0.2px solid #b9b9b9',
                                    height: '88px'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        // alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginTop: '5px'
                                    }}
                                >
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            marginLeft: '10px',
                                            marginRight: '15px'
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontSize: 18,
                                                fontWeight: 700,
                                                color: '#e37039'
                                            }}
                                        >
                                            R$
                                            {new Intl.NumberFormat('pt-BR', {
                                                minimumFractionDigits: 2
                                            }).format(
                                                labels[0].status
                                                    ? dataTable[0].max
                                                    : labels[1].status
                                                    ? dataTable[0].maxMensal
                                                    : dataTable[0].maxAnual
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                fontSize: 18,
                                                fontWeight: 700,
                                                color: '#e37039'
                                            }}
                                        >
                                            R$
                                            {new Intl.NumberFormat('pt-BR', {
                                                minimumFractionDigits: 2
                                            }).format(
                                                labels[0].status
                                                    ? dataTable[0].min
                                                    : labels[1].status
                                                    ? dataTable[0].minMensal
                                                    : dataTable[0].minAnual
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            marginRight: '15px'
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontSize: 12,
                                                marginTop: '5px'
                                            }}
                                        >
                                            MAX.
                                        </div>
                                        <div
                                            style={{
                                                fontSize: 12,
                                                marginTop: '8px'
                                            }}
                                        >
                                            MIN.
                                        </div>
                                    </div>
                                </div>

                                <div style={{fontSize: 10}}>
                                    <span style={{fontWeight: 700}}>COTA</span>
                                    <span
                                        style={{
                                            fontFamily: 'Rubik',
                                            fontWeight: '300',
                                            fontSize: 10.5
                                        }}
                                    >
                                        Ç
                                    </span>
                                    <span style={{fontWeight: 700}}>ÃO</span>
                                </div>
                            </div>
                            <div
                                style={{
                                    textAlign: 'center',
                                    borderRight: '0.2px solid #b9b9b9',
                                    height: '88px',
                                    marginLeft: '15px'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        // alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginTop: '5px'
                                    }}
                                >
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            marginLeft: '10px',
                                            marginRight: '15px'
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontSize: 18,
                                                fontWeight: 700,
                                                color: '#e37039'
                                            }}
                                        >
                                            {new Intl.NumberFormat('pt-BR', {
                                                style: 'percent',
                                                minimumFractionDigits: 2,
                                                currency: 'BRL'
                                            }).format(
                                                labels[0].status
                                                    ? dataTable[0].implMax
                                                    : labels[1].status
                                                    ? dataTable[0].implMensalMax
                                                    : dataTable[0].implAnualMax
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                fontSize: 18,
                                                fontWeight: 700,
                                                color: '#e37039'
                                            }}
                                        >
                                            {new Intl.NumberFormat('pt-BR', {
                                                style: 'percent',
                                                minimumFractionDigits: 2,
                                                currency: 'BRL'
                                            }).format(
                                                labels[0].status
                                                    ? dataTable[0].implMin
                                                    : labels[1].status
                                                    ? dataTable[0].implMensalMin
                                                    : dataTable[0].implAnualMin
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            marginRight: '15px'
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontSize: 12,
                                                marginTop: '5px'
                                            }}
                                        >
                                            MAX.
                                        </div>
                                        <div
                                            style={{
                                                fontSize: 12,
                                                marginTop: '8px'
                                            }}
                                        >
                                            MIN.
                                        </div>
                                    </div>
                                </div>
                                <div style={{fontSize: 10, fontWeight: 700}}>
                                    VOL. IMP
                                </div>
                            </div>
                            <div
                                style={{
                                    textAlign: 'center',
                                    height: '88px',
                                    marginLeft: '15px'
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        // alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginTop: '5px'
                                    }}
                                >
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            marginLeft: '10px',
                                            marginRight: '15px'
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontSize: 18,
                                                fontWeight: 700,
                                                color: '#e37039'
                                            }}
                                        >
                                            {new Intl.NumberFormat('pt-BR', {
                                                style: 'percent',
                                                minimumFractionDigits: 2,
                                                currency: 'BRL'
                                            }).format(
                                                labels[0].status
                                                    ? dataTable[0].hisMax
                                                    : labels[1].status
                                                    ? dataTable[0].hisMensalMax
                                                    : dataTable[0].hisAnualMax
                                            )}
                                        </div>
                                        <div
                                            style={{
                                                fontSize: 18,
                                                fontWeight: 700,
                                                color: '#e37039'
                                            }}
                                        >
                                            {new Intl.NumberFormat('pt-BR', {
                                                style: 'percent',
                                                minimumFractionDigits: 2,
                                                currency: 'BRL'
                                            }).format(
                                                labels[0].status
                                                    ? dataTable[0].hisMin
                                                    : labels[1].status
                                                    ? dataTable[0].hisMensalMin
                                                    : dataTable[0].hisAnualMin
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            textAlign: 'left',
                                            marginRight: '15px'
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontSize: 12,
                                                marginTop: '5px'
                                            }}
                                        >
                                            MAX.
                                        </div>
                                        <div
                                            style={{
                                                fontSize: 12,
                                                marginTop: '8px'
                                            }}
                                        >
                                            MIN.
                                        </div>
                                    </div>
                                </div>
                                <div style={{fontSize: 10, fontWeight: 700}}>
                                    VOL. HIS
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            ) : null}
        </>
    ) : null;
}
