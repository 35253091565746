/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import React, {useCallback, useRef, useEffect, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {useHistory} from 'react-router-dom';
import api from '@app/utils/api';
import {createBrowserHistory} from 'history';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import {Button, Checkbox} from '@material-ui/core';

export default function AlertSave({when, title, pageAlert}) {
    const [selected, setSelected] = useState();
    const [currentPath, setCurrentPath] = useState('');
    const [showPrompt, setShowPrompt] = useState(false);

    const history = useHistory();

    const unblockRef = useRef();

    const handleChange = () => {
        if (!selected) setSelected(1);
        else setSelected(0);
    };

    useEffect(() => {
        if (selected) {
            const changePreference = {
                change: selected,
                page: pageAlert
            };

            api.user
                .AlertPreference({changePreference})
                .then((res) => console.log(res))
                .catch((err) => console.log(err));
        }
    }, [selected]);

    const handleShowModal = () => {
        setShowPrompt(true);
    };

    const onCancel = () => {
        setShowPrompt(false);
    };

    const handleConfirm = () => {
        if (unblockRef) {
            unblockRef.current();
        }
        setShowPrompt(false);
        history.push(currentPath);
    };

    useEffect(() => {
        unblockRef.current = history.block((location) => {
            if (when) {
                setCurrentPath(location.pathname);
                handleShowModal();
                return false;
            }
            return true;
        });
        return () => {
            unblockRef.current && unblockRef.current();
        };
    }, [when]);

    return (
        <Dialog
            open={showPrompt}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" style={{color: '#e37039'}}>
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div>
                        <div>
                            Você está saindo sem salvar suas preferências. Tem
                            certeza de que deseja sair dessa Página?
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '5px'
                            }}
                        >
                            <span style={{marginRight: '10px'}}>
                                Para salvá-las basta clicar no ícone:
                            </span>
                            <ion-icon
                                style={{
                                    color: '#e37039',
                                    fontSize: 18
                                }}
                                name="save-outline"
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '20px',
                            marginBottom: '-20px'
                        }}
                    >
                        <Checkbox
                            style={{color: '#e37039'}}
                            onChange={handleChange}
                        />
                        <span style={{fontSize: 16}}>
                            Não mostre essa mensagem novamente
                        </span>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button style={{color: '#e37039'}} onClick={onCancel}>
                    Cancelar
                </Button>
                <Button
                    style={{color: '#e37039'}}
                    onClick={handleConfirm}
                    autoFocus
                >
                    Sair
                </Button>
            </DialogActions>
        </Dialog>
    );
}
