/*
 * Server-side restrictions:
 *   - string max length: 255 characters | reason: database limitation
 *   - password max length: 36 characters (72 bytes) | reason: bcrypt input limitation
 */

const rules = {
    email: {
        validators: ['isEmail', 'maxStringLength: 255'],
        errorMessages: ['Deve ser um email válido', 'No máximo 255 caracteres']
    },
    fullname: {
        validators: [
            'matchRegexp:^[A-ZÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]',
            'matchRegexp:[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]$',
            'maxStringLength:255',
            "matchRegexp:^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ',.\\- ]+$"
        ],
        errorMessages: [
            'Deve começar com uma letra maiúscula',
            'Deve terminar com uma letra',
            'No máximo 255 caracteres',
            "Apenas letras, espaços e pontuação (',.-)"
        ]
    },
    password: {
        validators: [
            'minStringLength:8',
            'maxStringLength:36',
            'matchRegexp:(?=.*[a-z])',
            'matchRegexp:(?=.*[A-Z])',
            'matchRegexp:(?=.*[0-9])',
            'matchRegexp:(?=.*[^A-Za-z0-9])'
        ],
        errorMessages: [
            'No mínimo 8 caracteres',
            'No máximo 36 caracteres',
            'Deve conter pelo menos 1 letra minúscula',
            'Deve conter pelo menos 1 letra maiúscula',
            'Deve conter pelo menos 1 caractere numérico',
            'Deve conter pelo menos 1 caractere especial'
        ]
    },
    username: {
        validators: [
            'matchRegexp:^[a-z]',
            'matchRegexp:[a-z0-9]$',
            'minStringLength:4',
            'maxStringLength:255',
            'matchRegexp:^[a-z0-9]+$'
        ],
        errorMessages: [
            'Deve começar com uma letra',
            'Deve terminar com uma letra ou número',
            'No mínimo 4 caracteres',
            'No máximo 255 caracteres',
            'Apenas letras e números'
        ]
    }
};

export default {
    ...rules,
    required: {
        validators: ['required'],
        errorMessages: ['Preencha este campo']
    },
    requiredEmail: {
        validators: ['required', ...rules.email.validators],
        errorMessages: ['Preencha este campo', ...rules.email.errorMessages]
    },
    requiredFullname: {
        validators: ['required', ...rules.fullname.validators],
        errorMessages: ['Preencha este campo', ...rules.fullname.errorMessages]
    },
    requiredPassword: {
        validators: ['required', ...rules.password.validators],
        errorMessages: ['Preencha este campo', ...rules.password.errorMessages]
    },
    requiredUsername: {
        validators: ['required', ...rules.username.validators],
        errorMessages: ['Preencha este campo', ...rules.username.errorMessages]
    }
};
