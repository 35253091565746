/* eslint-disable no-plusplus */
/* eslint-disable import/prefer-default-export */
export const formatMoney = (money) => {
    if (money) {
        return Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        })
            .format(money)
            .replace(',', '.');
    }
    return money;
};

const removeDot = (num) => {
    const frase = num;
    const letra = '.';
    let tmp = num;
    let quantidade = 0;

    for (let i = 0; i < frase.length; i++) {
        if (frase[i] === letra) {
            quantidade++;
        }
    }

    if (quantidade > 1) {
        for (let y = 0; y < quantidade; y++) {
            tmp = num.replace('.', '');
        }
    }
    return tmp;
};

export const cleanNumber = (myNum) => {
    let retNum = myNum;
    if (myNum) {
        if (typeof myNum === 'number') {
            retNum = myNum;
        } else {
            const ini = myNum.replace('R$', '');
            if (ini.indexOf('-') !== -1) {
                const intern = ini.replace('-', '');
                const intern2 = removeDot(intern);
                retNum = Number(intern2) * -1;
            } else {
                const ponto = removeDot(ini);
                retNum = Number(ponto);
            }
        }
    }
    return retNum;
};
