import Axios from 'axios';
import jwtDecode from 'jwt-decode';
import {decodeToken} from 'react-jwt';
import axiosConfig from '../config/axios';
import storage from './storage';
import apiRoutes from '../config/axiosRouting';

function storeSessionData(token) {
    storage.auth.token = token;
}

function getDataFromSession(token) {
    const data = {};
    if (token) {
        try {
            const session = decodeToken(token);
            // console.log(session);
            data.user = session;
        } catch (err) {
            console.debug(err);
        }
    }
    return data;
}

function getDataForUser(token) {
    const data = {};
    if (token) {
        try {
            const session = jwtDecode(token);
            // console.log(session);
            data.user = session;
        } catch (err) {
            console.debug(err);
        }
    }
    return data;
}
class Api {
    constructor() {
        this.axios = Axios.create(axiosConfig.api);

        // console.log(decodeToken(storage.auth.token));

        this.currentSession = storage.auth.token;
        // this.currentUser = this.currentSession?.user;

        // console.log('Decodificando....', decodeToken(storage.auth.token));

        if (this.currentSession) {
            this.currentUser = jwtDecode(storage.auth.token);
        } else {
            this.currentUser = decodeToken(storage.auth.token);
        }

        this.currentAuthorizationHeader = this.currentSession
            ? `Bearer ${this.currentSession}`
            : '';

        this.axios.interceptors.request.use((request) => {
            if (this.currentSession) {
                request.headers.Authorization = this.currentAuthorizationHeader;
            }
            return request;
        });

        // eslint-disable-next-line no-unused-vars
        const setSession = (token) => {
            this.currentSession = token;
            this.currentAuthorizationHeader = token ? `Bearer ${token}` : '';
            const result = getDataFromSession(token);
            const resultUser = getDataForUser(token);
            this.currentUser = resultUser.user;
            storeSessionData(token);
            // console.log('DADOS DESCRIP', user)
            // if (user) {
            //   this.currentCondoDetails = user.condoDetails
            //   this.currentUserDetails = user.details
            // } else {
            //   this.currentCondoDetails = user
            //   this.currentUserDetails = user
            // }
        };
        /* const logoutSession = (token) => {
            this.currentSession = token;
            this.currentAuthorizationHeader = token ? `Bearer ${token}` : '';

            DeleteSession(token);
        }; */

        this.admin = {
            requestResumo: async () => {
                try {
                    const response = await this.axios.get(
                        `${apiRoutes.admin}/resumo`
                    );
                    return response;
                } catch (e) {
                    console.log(e);
                    return e;
                }
            },
            requestUsuarios: async () => {
                try {
                    const response = await this.axios.get(
                        `${apiRoutes.admin}/usuarios`
                    );
                    return response;
                } catch (e) {
                    console.log(e);
                    return e;
                }
            },
            changeplano: async (id, formdata, check) => {
                try {
                    const response = await this.axios.post(
                        `${apiRoutes.admin}/cancel`,
                        {id, formdata, check}
                    );
                    return response;
                } catch (e) {
                    console.log(e);
                    return e;
                }
            },
            cancelAccount: async (id) => {
                try {
                    const response = await this.axios.post(
                        `${apiRoutes.admin}/cancel`,
                        {id}
                    );
                    return response;
                } catch (e) {
                    console.log(e);
                    return e;
                }
            },
            reativarAccount: async (id) => {
                try {
                    const response = await this.axios.post(
                        `${apiRoutes.admin}/reativar`,
                        {id}
                    );
                    return response;
                } catch (e) {
                    return e;
                }
            },
            addPerfil: async (name, email, senha, perfil, obs, user) => {
                try {
                    const response = await this.axios.post(
                        `${apiRoutes.admin}/perfil`,
                        {name, email, senha, perfil, obs, user}
                    );
                    return response;
                } catch (e) {
                    return e;
                }
            },
            findPerfil: async () => {
                try {
                    const response = await this.axios.get(
                        `${apiRoutes.admin}/getperfil`
                    );
                    return response;
                } catch (e) {
                    return e;
                }
            },
            updatePerfil: async (name, email, senha, perfil, obs, id) => {
                try {
                    const response = await this.axios.post(
                        `${apiRoutes.admin}/updateperfil`,
                        {name, email, senha, perfil, obs, id}
                    );
                    return response;
                } catch (e) {
                    return e;
                }
            },
            turnOff: async (id, obs) => {
                try {
                    const response = await this.axios.post(
                        `${apiRoutes.admin}/turnoff`,
                        {id, obs}
                    );
                    return response;
                } catch (e) {
                    return e;
                }
            },
            turnOn: async (id) => {
                try {
                    const response = await this.axios.post(
                        `${apiRoutes.admin}/turnon`,
                        {id}
                    );
                    return response;
                } catch (e) {
                    return e;
                }
            },
            checkPassword: async (id, password) => {
                try {
                    const res = await this.axios.post(
                        `${apiRoutes.admin}/requestacess`,
                        {id, password}
                    );
                    return res;
                } catch (e) {
                    return e;
                }
            }
        };

        this.assinatura = {
            checkForClient: async (id) => {
                try {
                    const response = await this.axios.post(
                        `${apiRoutes.planos}/check_client`,
                        {id}
                    );
                    return response;
                } catch (error) {
                    console.log(error);
                    return error;
                }
            },

            updateClient: async (formData, email, id, client) => {
                try {
                    const response = await this.axios.post(
                        `${apiRoutes.planos}/update_client`,
                        {formData, email, id, client}
                    );
                    return response;
                } catch (error) {
                    console.log(error);
                    return error;
                }
            },

            createClient: async (formData, email, id) => {
                try {
                    const response = await this.axios.post(
                        `${apiRoutes.planos}/create_client`,
                        {formData, email, id}
                    );
                    return response;
                } catch (error) {
                    console.log(error);
                    return error;
                }
            },

            createAssinatura: async (cardForm, plano, id) => {
                try {
                    const response = await this.axios.post(
                        `${apiRoutes.planos}/create_assinatura`,
                        {cardForm, plano, id}
                    );
                    setSession(response.data);
                    return response;
                } catch (error) {
                    console.log(error);
                    return error;
                }
            },

            pause: async (id) => {
                try {
                    const response = await this.axios.post(
                        `${apiRoutes.planos}/pause_assinatura`,
                        {
                            id
                        }
                    );
                    setSession(response.data);
                    return response;
                } catch (e) {
                    return e;
                }
            },
            cancelment: async (id, reason) => {
                try {
                    // console.log(id, reason);
                    const response = await this.axios.post(
                        `${apiRoutes.planos}/cancel_assinatura`,
                        {
                            id,
                            reason
                        }
                    );
                    setSession(response.data);
                    return response;
                } catch (e) {
                    console.log(e);
                    return e;
                }
            },

            // MP
            requestPlano: async (amount, frequency, frequencyType) => {
                try {
                    const response = await this.axios.post(
                        `${apiRoutes.planos}/getPlano`,
                        {amount, frequency, frequencyType}
                    );
                    return response;
                } catch (err) {
                    console.log(err);
                    return err;
                }
            },
            requestAssinaturaPlano: async (formData, plano, name) => {
                try {
                    const response = await this.axios.post(
                        `${apiRoutes.planos}/assinatura`,
                        {
                            formData,
                            plano,
                            name
                        }
                    );
                    setSession(response.data[0]);
                    return response;
                } catch (err) {
                    return err;
                }
            },
            recurrencyOff: async (recurrency) => {
                try {
                    const response = await this.axios.post(
                        `${apiRoutes.planos}/recurrency`,
                        {
                            recurrency
                        }
                    );
                    setSession(response.data);
                    return response;
                } catch (e) {
                    return e;
                }
            },

            getPaymentId: async () => {
                try {
                    const response = await this.axios.post(
                        `${apiRoutes.planos}/payment_notification`
                    );
                    return response;
                } catch (err) {
                    return err;
                }
            },
            getPaymentInfo: async (url) => {
                try {
                    const response = await this.axios.get(
                        `${apiRoutes.planos}/payment_info`,
                        {url}
                    );
                    return response;
                } catch (err) {
                    return err;
                }
            },
            cancelAssinatura: async (reason) => {
                try {
                    const response = await this.axios.post(
                        `${apiRoutes.planos}/cancel`,
                        reason
                    );
                    return response;
                } catch (e) {
                    console.log(e);
                    return e;
                }
            }
        };

        this.mercado = {
            getUserActives: async () => {
                try {
                    const response = await this.axios.get(
                        `${apiRoutes.mercado}/userActive`
                    );
                    return response;
                } catch (err) {
                    return err;
                }
            },
            getDefaultActives: async () => {
                try {
                    const response = await this.axios.get(
                        `${apiRoutes.mercado}/default`
                    );
                    return response;
                } catch (err) {
                    return err;
                }
            }
        };

        this.roex = {
            getData: async ({ativo, vencimento, dataRoex}) => {
                // console.log(ativo, vencimento, dataRoex);
                try {
                    const response = await this.axios.post(
                        `${apiRoutes.roex}/roexData`,
                        {
                            ativo,
                            vencimento,
                            dataRoex
                        }
                    );
                    return response;
                } catch (e) {
                    return e;
                }
            },

            getRolagem: async (ativo, vencimento, dataRoex) => {
                try {
                    const response = await this.axios.post(
                        `${apiRoutes.roex}/rolagem`,
                        {
                            ativo,
                            vencimento,
                            dataRoex
                        }
                    );
                    return response;
                } catch (e) {
                    return e;
                }
            },
            getRemontagem: async (ativo, vencimento, dataRoex) => {
                try {
                    const response = await this.axios.post(
                        `${apiRoutes.roex}/remontagem`,
                        {
                            ativo,
                            vencimento,
                            dataRoex
                        }
                    );
                    return response;
                } catch (err) {
                    return err;
                }
            }
        };

        this.user = {
            getUser: async () => {
                const response = await this.axios.get(`${apiRoutes.user}`);
                return response;
            },

            sendEmail: async (username, email, password) => {
                try {
                    const resp = await this.axios.post(
                        `${apiRoutes.users}/send_password`,
                        {
                            username,
                            email,
                            password
                        }
                    );
                    return resp;
                } catch (e) {
                    console.log(e);
                }
            },

            create: async ({name, email, uid}) => {
                const resp = await this.axios.post(
                    `${apiRoutes.users}/register`,
                    {name, email, uid}
                );
                return resp.data;
            },

            createUserPlano: async ({name, email, uid}) => {
                const resp = await this.axios.post(
                    `${apiRoutes.users}/registerPlano`,
                    {name, email, uid}
                );
                setSession(resp.data);
                return resp.data;
            },

            createByGoogle: async ({name, email, uid}) => {
                const resp = await this.axios.post(
                    `${apiRoutes.users}/registerByGoogle`,
                    {name, email, uid}
                );
                setSession(resp.data);
                return resp.data;
            },

            login: async ({uid}) => {
                try {
                    const resp = await this.axios.post(
                        `${apiRoutes.users}/login`,
                        {
                            uid
                        }
                    );
                    setSession(resp.data);
                    return resp.data;
                } catch (err) {
                    console.log(err.response);
                    return err;
                }
            },

            createPreference: async ({preference}) => {
                try {
                    const res = await this.axios.post(
                        `${apiRoutes.preference}/preference`,
                        {
                            preference
                        }
                    );
                    setSession(res.data);
                    return res;
                } catch (err) {
                    console.log(err);
                    return err;
                }
            },

            testEmail: async ({username, email, arte}) => {
                try {
                    const res = await this.axios.post(
                        `${apiRoutes.users}/testEmail`,
                        {
                            username,
                            email,
                            arte
                        }
                    );
                    return res;
                } catch (e) {
                    console.log(e);
                    return e;
                }
            },

            AlertPreference: async ({changePreference}) => {
                try {
                    const res = await this.axios.post(
                        `${apiRoutes.preference}/changeAlert`,
                        {
                            changePreference
                        }
                    );
                    setSession(res.data);
                    return res;
                } catch (err) {
                    console.log(err);
                    return err;
                }
            },

            createMembership: async (payment, endDate, plan) => {
                try {
                    const resp = await this.axios.post(`${apiRoutes.planos}/`, {
                        payment,
                        endDate,
                        plan
                    });
                    setSession(resp.data);
                    return resp;
                } catch (err) {
                    return err;
                }
            },

            profileUpdate: async ({
                uid,
                birth,
                city,
                country,
                state,
                phone,
                email,
                name,
                gender,
                nivel,
                cpf,
                photo
            }) => {
                try {
                    const resp = await this.axios.post(
                        `${apiRoutes.users}/profileUpdate`,
                        {
                            uid,
                            birth,
                            city,
                            country,
                            state,
                            phone,
                            email,
                            name,
                            gender,
                            nivel,
                            cpf,
                            photo
                        }
                    );
                    setSession(resp.data);
                    return resp;
                } catch (err) {
                    return err;
                }
            },
            changeImage: async (file) => {
                try {
                    const res = await this.axios.post(
                        `${apiRoutes.users}/changeImage`,
                        file
                    );
                    setSession(res.data[0]);
                    return res.data[1];
                } catch (e) {
                    console.log(e);
                    return e;
                }
            }
        };
        this.gatewayGroup = {
            create: async (name) => {
                const response = await this.axios.post(
                    `${apiRoutes.gatewayGroup}`,
                    {
                        name,
                        companyId: 1
                    }
                );
                return response;
            },
            getGroupsFromCompanyId: async () => {
                const response = await this.axios.get(
                    `${apiRoutes.gatewayGroup}/company/1`
                );
                return response.data.data;
            }
        };

        this.wallet = {
            new: async ({
                name,
                ativo,
                status,
                cotacaoStart,
                cotacaoEnd,
                closedAt,
                strategy
            }) => {
                try {
                    const resposta = await this.axios.post(
                        `${apiRoutes.wallet}/`,
                        {
                            name,
                            ativo,
                            status,
                            cotacao_start: cotacaoStart,
                            cotacao_end: cotacaoEnd,
                            closed_at: closedAt,
                            strategys: strategy
                        }
                    );
                    return resposta;
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.log(error);
                    return error;
                }
            },
            edit: async ({
                id,
                name,
                ativo,
                status,
                cotacaoStart,
                cotacaoEnd,
                closedAt,
                strategy
            }) => {
                try {
                    const resposta = await this.axios.put(
                        `${apiRoutes.wallet}/${id}`,
                        {
                            name,
                            ativo,
                            status,
                            cotacao_start: cotacaoStart,
                            cotacao_end: cotacaoEnd,
                            closed_at: closedAt,
                            strategys: strategy
                        }
                    );
                    return resposta;
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.log(error);
                    return error;
                }
            },
            getMyWallets: async () => {
                try {
                    const resposta = await this.axios.get(
                        `${apiRoutes.wallet}/`
                    );
                    return resposta;
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.log(error);
                    return error;
                }
            },
            delete: async ({id}) => {
                try {
                    const resposta = await this.axios.delete(
                        `${apiRoutes.wallet}/${id}`
                    );
                    return resposta;
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.log(error);
                    return error;
                }
            }
        };

        this.calculator = {
            newData: async (ticker) => {
                try {
                    const resposta = await this.axios.get(
                        `${apiRoutes.calculator}/new?ticker=${ticker}`
                    );
                    return resposta;
                } catch (error) {
                    return error;
                }
            },

            update: async (
                strike,
                cotacao,
                date,
                type,
                volImplicita,
                pteorico,
                vencimento,
                ultimo,
                impliedVol,
                juros
            ) => {
                try {
                    const res = await this.axios.post(
                        `${apiRoutes.calculator}/update`,
                        {
                            strike,
                            cotacao,
                            date,
                            type,
                            volImplicita,
                            pteorico,
                            vencimento,
                            ultimo,
                            impliedVol,
                            juros
                        }
                    );
                    return res;
                } catch (e) {
                    return e;
                }
            },

            updateCot: async (
                pteorico,
                strike,
                date,
                type,
                volImplicita,
                vencimento,
                ultimo,
                impliedVol,
                juros
            ) => {
                try {
                    const res = await this.axios.post(
                        `${apiRoutes.calculator}/updateCot`,
                        {
                            pteorico,
                            strike,
                            date,
                            type,
                            volImplicita,
                            vencimento,
                            ultimo,
                            impliedVol,
                            juros
                        }
                    );
                    return res;
                } catch (e) {
                    return e;
                }
            }
        };

        this.cards = {
            getCardsInfo: async (id) => {
                try {
                    const resposta = await this.axios.get(
                        `${apiRoutes.cards}/list/${id}`
                    );
                    return resposta;
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.log(error);
                    return error;
                }
            },

            getCardById: async (id, tickers) => {
                try {
                    const resposta = await this.axios.get(
                        `${apiRoutes.cards}/card/${id}?tickers=${tickers}`
                    );
                    return resposta;
                } catch (error) {
                    return error;
                }
            },

            getDataByTicker: async (ticker, ativo) => {
                try {
                    const resposta = await this.axios.get(
                        `${apiRoutes.cards}/check?ticker=${ticker}&ativo=${ativo}`
                    );
                    return resposta;
                } catch (error) {
                    return error;
                }
            },

            getDataCalculator: async (ticker) => {
                try {
                    const resposta = await this.axios.get(
                        `${apiRoutes.cards}/calculator?ticker=${ticker}`
                    );
                    return resposta;
                } catch (error) {
                    return error;
                }
            },

            getNewDataBs: async (
                strike,
                cotacao,
                date,
                type,
                volImplicita,
                pteorico,
                vencimento,
                ultimo,
                impliedVol,
                juros
            ) => {
                try {
                    const res = await this.axios.post(
                        `${apiRoutes.cards}/userChange`,
                        {
                            strike,
                            cotacao,
                            date,
                            type,
                            volImplicita,
                            pteorico,
                            vencimento,
                            ultimo,
                            impliedVol,
                            juros
                        }
                    );
                    return res;
                } catch (e) {
                    return e;
                }
            },

            getCardByStrikeOrType: async (form) => {
                try {
                    const resposta = await this.axios.get(
                        `${apiRoutes.cards}/search?strike=${form.strike}&tipo=${form.tipo}&ativo=${form.ativo}`
                    );
                    return resposta;
                } catch (error) {
                    return error;
                }
            },

            getCotacaoByTicker: async (ticker, date) => {
                try {
                    const resposta = await this.axios.get(
                        `${apiRoutes.cards}/volat?ticker=${ticker}&date=${date}`
                    );
                    return resposta;
                } catch (error) {
                    return error;
                }
            },

            createNewCard: async (form) => {
                try {
                    await this.axios.post(`${apiRoutes.cards}/create`, form);
                    return form;
                } catch (error) {
                    return error;
                }
            },

            deleteCard: async (id) => {
                try {
                    await this.axios.delete(`${apiRoutes.cards}/delete/${id}`);
                    return;
                } catch (error) {
                    // eslint-disable-next-line consistent-return
                    return error;
                }
            },

            deleteAllCardRows: async (id) => {
                try {
                    await this.axios.delete(`${apiRoutes.cards}/deletes/${id}`);
                    return;
                } catch (error) {
                    // eslint-disable-next-line consistent-return
                    return error;
                }
            },

            editCard: async (id, form) => {
                try {
                    await this.axios.put(`${apiRoutes.cards}/edit/${id}`, form);
                    return;
                } catch (error) {
                    // eslint-disable-next-line consistent-return
                    return error;
                }
            },

            editStatus: async (id, status) => {
                try {
                    await this.axios.put(
                        `${apiRoutes.cards}/status/${id}?status=${status}`
                    );
                    return;
                } catch (error) {
                    // eslint-disable-next-line consistent-return
                    return error;
                }
            },

            getCotacaoOnly: async (ativo) => {
                try {
                    const resposta = await this.axios.get(
                        `${apiRoutes.cards}/cotacao?ativo=${ativo}`
                    );
                    return resposta;
                } catch (error) {
                    return error;
                }
            },

            getTickersList: async (ativo, day) => {
                try {
                    const resposta = await this.axios.get(
                        `${apiRoutes.cards}/tickers?ativo=${ativo}&day=${day}`
                    );
                    return resposta;
                } catch (error) {
                    return error;
                }
            }
        };

        this.money = {
            getMoneyInfos: async (ativo, dataHora) => {
                try {
                    const resposta = await this.axios.get(
                        `${apiRoutes.options}/money/${ativo}?myData=${dataHora}` /* ?limit=${limit}&page=${page} */
                    );
                    // console.log(resposta);
                    return resposta;
                } catch (err) {
                    // eslint-disable-next-line no-console
                    console.log(err);
                    return err;
                }
            }
        };

        this.charts = {
            getHistory: async (ativo) => {
                try {
                    const response = await this.axios.get(
                        `${apiRoutes.charts}/data/${ativo}`
                    );
                    return response.data;
                } catch (e) {
                    return e;
                }
            },
            moreLiquid: async () => {
                try {
                    const response = await this.axios.get(
                        `${apiRoutes.options}/moreLiquid?qtd=50`
                    );
                    return response.data;
                } catch (e) {
                    return e;
                }
            }
        };
        this.options = {
            getDataMercado: async (ativo) => {
                try {
                    const response = await this.axios.get(
                        `${apiRoutes.options}/dataMercado/${ativo.ativo}`
                    );
                    return response.data;
                } catch (e) {
                    return e;
                }
            },

            getSelic: async () => {
                try {
                    const response = await this.axios.get(
                        `${apiRoutes.options}/selic`
                    );
                    return response.data.retorno;
                } catch (error) {
                    return error;
                }
            },
            getVencRoex: async (ativo, vencimento, dataRoex) => {
                try {
                    const response = await this.axios.post(
                        `${apiRoutes.options}/vencRoex`,
                        {
                            ativo,
                            vencimento,
                            dataRoex
                        }
                    );
                    return response;
                } catch (e) {
                    return e;
                }
            },

            volTabela: async (ativo) => {
                try {
                    const response = await this.axios.get(
                        `${apiRoutes.options}/table/${ativo.ativo}`
                    );
                    return response.data;
                } catch (e) {
                    return e;
                }
            },

            getCotacoesPorAtivo: async (ativo, dataHora, page, start, end) => {
                // console.log('api data hora', dataHora);
                try {
                    const resposta = await this.axios.get(
                        `${apiRoutes.options}/selected/${ativo}?page=${page}&start=${start}&end=${end}&myData=${dataHora}` /* ?limit=${limit}&page=${page} */
                    );
                    // console.log(resposta);
                    return resposta;
                } catch (err) {
                    // eslint-disable-next-line no-console
                    console.log(err);
                    return err;
                }
            },
            getChartsValues: async (ativo) => {
                try {
                    const resposta = await this.axios.get(
                        `${apiRoutes.options}/charts/${ativo}`
                    );
                    return resposta;
                } catch (error) {
                    // console.log(error);
                    return error;
                }
            },
            getSpecificChartsValues: async (ativo, dataHora) => {
                try {
                    const resposta = await this.axios.get(
                        `${apiRoutes.options}/specificCharts/${ativo}?dataHora=${dataHora}`
                    );
                    return resposta;
                } catch (error) {
                    // console.log(error);
                    return error;
                }
            },
            getAll: async () => {
                try {
                    const resposta = await this.axios.get(
                        `${apiRoutes.options}/all` /* ?limit=10&page=0 */
                    );
                    return resposta;
                } catch (err) {
                    // eslint-disable-next-line no-console
                    console.log(err);
                    return err;
                }
            },
            getSingle: async ({ativ, cache}) => {
                try {
                    const resposta = await this.axios.get(
                        `${apiRoutes.options}/single?ativ=${ativ}&cache=${cache}`
                    );
                    return resposta;
                } catch (err) {
                    // eslint-disable-next-line no-console
                    console.log(err);
                    return err;
                }
            },

            getChart: async ({ativo, mes, data}) => {
                try {
                    const resposta = await this.axios.get(
                        `${apiRoutes.options}/chart?ativo=${ativo}&mes=${mes}&data=${data}`
                    );
                    return resposta;
                } catch (err) {
                    // eslint-disable-next-line no-console
                    console.log(err);
                    return err;
                }
            },

            getTickerVol: async ({listTicker}) => {
                try {
                    const response = await this.axios.post(
                        `${apiRoutes.options}/getTickerVol`,
                        {ticker: listTicker}
                    );
                    return response;
                } catch (e) {
                    console.log(e);
                    return e;
                }
            }
        };

        this.gateway = {
            getGateways: async () => {
                const response = await this.axios.get(`${apiRoutes.gateways}`);
                return response.data.data;
            },

            createGateways: async (
                groupId,
                numSerie,
                name,
                end,
                endNum,
                bairro,
                cidade,
                uf,
                cep
            ) => {
                try {
                    const response = await this.axios.post(
                        `${apiRoutes.gateways}`,
                        {
                            numSerie,
                            name,
                            companyId: 1,
                            address: end,
                            number: endNum,
                            distric: bairro,
                            city: cidade,
                            state: uf,
                            cep,
                            groupId
                        }
                    );
                    return response.data.data;
                } catch (error) {
                    // console.log(error.response.data.message);
                    throw error.response.data.message;
                }
            },

            getGatewayDetails: async (id) => {
                const response = await this.axios.get(
                    `${apiRoutes.gateways}/${id}`
                );
                return response.data.data;
            },

            deleteGateway: async (id) => {
                const response = await this.axios.delete(
                    `${apiRoutes.gateways}/${id}`
                );
                return response.data.data;
            },

            sendCommand: async (gatewayId, data, numSerie) => {
                const response = await this.axios.post(
                    `${apiRoutes.gateways}/command/${gatewayId}`,
                    {
                        data,
                        numSerie
                    }
                );

                return response;
            }
        };
    }
}

const api = new Api();
export default api;
