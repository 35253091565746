import React from 'react';
import {cleanNumber} from '@app/utils/myaux';
// import ReactTooltip from 'react-tooltip';

/* const calcAloc = (strategy) => {
    let totMont = 0;
    strategy.forEach((s) => {
        if (s.type === 'V') {
            totMont += cleanNumber(s.preco_mont) * cleanNumber(s.qtd) * -1;
        } else {
            totMont += cleanNumber(s.preco_mont) * cleanNumber(s.qtd);
        }
    });
    return totMont;
}; */

const calcDesmont = (strategy) => {
    let totDesMont = 0;
    strategy.forEach((s) => {
        if (s.type === 'V') {
            totDesMont +=
                cleanNumber(s.preco_desmont) * cleanNumber(s.qtd) * -1;
        } else {
            totDesMont += cleanNumber(s.preco_desmont) * cleanNumber(s.qtd);
        }
    });

    return totDesMont;
};

export default function Total({card}) {
    if (calcDesmont) {
        const minus = calcDesmont(card);
        if (minus < 0) {
            return (
                <td
                    style={{
                        fontSize: 12,
                        paddingTop: '5px',
                        display: 'flex'
                    }}
                >
                    CRÉDITO
                    <ion-icon
                        data-tip="CRÉDITO - Para rolar ou remontar a estrutura registrada
                        houve recebimento financeiro."
                        style={{
                            marginBottom: 2,
                            fontSize: 12,
                            color: '#d36233',
                            cursor: 'pointer'
                        }}
                        name="help-circle-outline"
                    />
                    <span
                        style={{
                            borderBottom: '1px dotted #000',
                            width: '100%',
                            display: 'flex'
                        }}
                    />
                </td>
            );
        }
        return (
            <td
                style={{
                    fontSize: 12,
                    paddingTop: '5px',
                    display: 'flex'
                }}
            >
                DÉBITO
                <ion-icon
                    data-tip="DEBITO - Para rolar ou remontar a estrutura é necessário
                    desembolso financeiro."
                    style={{
                        marginBottom: 2,
                        fontSize: 12,
                        color: '#d36233',
                        cursor: 'pointer'
                    }}
                    name="help-circle-outline"
                />
                <span
                    style={{
                        borderBottom: '1px dotted #000',
                        width: '100%',
                        display: 'flex'
                    }}
                />
            </td>
        );
    }
}
