/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import avisoMobile from 'src/img/avisoMobile.avif';
import Dialog from '@material-ui/core/Dialog';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import {DialogActions, Button} from '@material-ui/core/';

const useStyles = makeStyles((theme) =>
    createStyles({
        dialog: {
            background: 'transparent',
            border: 0
        }
    })
);

export default function MobileDetecter() {
    const classes = useStyles();
    const [showMobileWarning, setShowMobileWarning] = useState(false);
    const [open, setOpen] = useState(true);
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (window.innerWidth <= 500) setShowMobileWarning(true);
    }, []);
    return showMobileWarning ? (
        <Dialog
            PaperProps={{
                style: {
                    backgroundColor: 'rgba(0, 0, 0, 0.0)',
                    boxShadow: 'none'
                }
            }}
            maxWidth="600px"
            open={open}
            // onClose={() => setOpen(false)}
        >
            <DialogActions>
                <Button
                    style={{borderRadius: 30, height: '40px', width: '40px'}}
                >
                    <ion-icon
                        name="close-circle-outline"
                        style={{
                            // fontSize: 20,
                            color: '#e37039',
                            borderRadius: '12.5px',
                            width: '30px',
                            height: '30px'
                        }}
                        onClick={() => setOpen(false)}
                    />
                </Button>
            </DialogActions>
            <img src={avisoMobile} alt="" style={{width: '300px'}} />
        </Dialog>
    ) : (
        ''
    );
}
