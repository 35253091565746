import React from 'react';

export default function HeaderTable() {
    return (
        <thead>
            <tr /* style={{whiteSpace: 'nowrap'}} */>
                <th
                    style={{
                        whiteSpace: 'nowrap',
                        minWidth: '50px',
                        borderTop: '1px solid #fff'
                    }}
                >
                    {' '}
                </th>
                <th
                    // align="left"
                    style={{
                        whiteSpace: 'nowrap',
                        borderTop: '1px solid #fff'
                        // maxWidth: 35
                    }}
                >
                    DATA
                    <span
                        style={{cursor: 'pointer'}}
                        data-tip="Data que o registro do ativo ou opção foi feito."
                    >
                        <ion-icon
                            style={{
                                marginBottom: 2,
                                fontSize: 12,
                                color: '#d36233',
                                pointerEvents: 'none'
                            }}
                            name="help-circle-outline"
                        />
                    </span>
                </th>
                <th style={{whiteSpace: 'nowrap', borderTop: '1px solid #fff'}}>
                    QTD
                    <span
                        style={{cursor: 'pointer'}}
                        data-tip="Quantidade de contratos de opção ou ativos incluídos na carteira."
                    >
                        <ion-icon
                            style={{
                                marginBottom: 2,
                                fontSize: 12,
                                color: '#d36233',
                                pointerEvents: 'none'
                            }}
                            name="help-circle-outline"
                        />
                    </span>
                </th>
                <th
                    style={{
                        whiteSpace: 'nowrap',
                        minWidth: '100',
                        borderTop: '1px solid #fff'
                    }}
                >
                    <div>
                        TIPO
                        <span
                            style={{cursor: 'pointer'}}
                            data-tip="Tipo da opção, CALL - contrato de compra ou PUT - contrato de venda."
                        >
                            <ion-icon
                                style={{
                                    marginBottom: 2,
                                    fontSize: 12,
                                    color: '#d36233',
                                    pointerEvents: 'none'
                                }}
                                name="help-circle-outline"
                            />
                        </span>
                    </div>
                </th>
                <th style={{whiteSpace: 'nowrap', borderTop: '1px solid #fff'}}>
                    VENC
                    <span
                        style={{cursor: 'pointer'}}
                        data-tip="Data de vencimento dos contratos de opção."
                    >
                        <ion-icon
                            style={{
                                marginBottom: 2,
                                fontSize: 12,
                                color: '#d36233',
                                pointerEvents: 'none'
                            }}
                            name="help-circle-outline"
                        />
                    </span>
                </th>
                <th style={{whiteSpace: 'nowrap', borderTop: '1px solid #fff'}}>
                    STRIKE
                    <span
                        style={{cursor: 'pointer'}}
                        data-tip="Preço de exercício do contrato de opções."
                    >
                        <ion-icon
                            style={{
                                marginBottom: 2,
                                fontSize: 12,
                                color: '#d36233',
                                pointerEvents: 'none'
                            }}
                            name="help-circle-outline"
                        />
                    </span>
                </th>
                <th style={{whiteSpace: 'nowrap', borderTop: '1px solid #fff'}}>
                    TICKER
                    <span
                        style={{cursor: 'pointer'}}
                        data-tip="Nomenclatura utilizada para identificar exclusivamente contratos de opções negociadas publicamente."
                    >
                        <ion-icon
                            style={{
                                marginBottom: 2,
                                fontSize: 12,
                                color: '#d36233',
                                pointerEvents: 'none'
                            }}
                            name="help-circle-outline"
                        />
                    </span>
                </th>
                <th style={{whiteSpace: 'nowrap', borderTop: '1px solid #fff'}}>
                    <div style={{textAlign: 'center'}}>
                        PRE
                        <span
                            style={{
                                fontFamily: 'Rubik',
                                fontWeight: '300',
                                fontSize: 11.5
                            }}
                        >
                            Ç
                        </span>
                        O
                        <span
                            style={{cursor: 'pointer'}}
                            data-tip="Preço negociado pela opção ou ativo."
                        >
                            <ion-icon
                                style={{
                                    marginBottom: 2,
                                    fontSize: 12,
                                    color: '#d36233',
                                    pointerEvents: 'none'
                                }}
                                name="help-circle-outline"
                            />
                        </span>
                    </div>
                </th>
                <th style={{whiteSpace: 'nowrap', borderTop: '1px solid #fff'}}>
                    <div style={{textAlign: 'center'}}>
                        P.TEÓRICO
                        <span
                            style={{cursor: 'pointer'}}
                            data-tip="Preço teórico da opção, seguindo modelo Black&Scholes."
                        >
                            <ion-icon
                                style={{
                                    marginBottom: 2,
                                    fontSize: 12,
                                    color: '#d36233',
                                    pointerEvents: 'none'
                                }}
                                name="help-circle-outline"
                            />
                        </span>
                    </div>
                </th>
                <th style={{borderTop: '1px solid #fff'}} align="end">
                    {/* TOTAL */}
                    <div style={{textAlign: 'end'}}>
                        TOTAL
                        <span
                            style={{cursor: 'pointer'}}
                            data-tip="Somatória das opções e ativos registrados."
                        >
                            <ion-icon
                                style={{
                                    marginBottom: 2,
                                    fontSize: 12,
                                    color: '#d36233',
                                    pointerEvents: 'none'
                                }}
                                name="help-circle-outline"
                            />
                        </span>
                    </div>
                </th>
            </tr>
        </thead>
    );
}
