const citiesNordeste = [
    {state: 'BA', value: 'Abaíra'},
    {state: 'BA', value: 'Abaré'},
    {state: 'BA', value: 'Acajutiba'},
    {state: 'BA', value: 'Adustina'},
    {state: 'BA', value: 'Água Fria'},
    {state: 'BA', value: 'Aiquara'},
    {state: 'BA', value: 'Alagoinhas'},
    {state: 'BA', value: 'Alcobaça'},
    {state: 'BA', value: 'Almadina'},
    {state: 'BA', value: 'Amargosa'},
    {state: 'BA', value: 'Amélia Rodrigues'},
    {state: 'BA', value: 'América Dourada'},
    {state: 'BA', value: 'Anagé'},
    {state: 'BA', value: 'Andaraí'},
    {state: 'BA', value: 'Andorinha'},
    {state: 'BA', value: 'Angical'},
    {state: 'BA', value: 'Anguera'},
    {state: 'BA', value: 'Antas'},
    {state: 'BA', value: 'Antônio Cardoso'},
    {state: 'BA', value: 'Antônio Gonçalves'},
    {state: 'BA', value: 'Aporá'},
    {state: 'BA', value: 'Apuarema'},
    {state: 'BA', value: 'Araçás'},
    {state: 'BA', value: 'Aracatu'},
    {state: 'BA', value: 'Araci'},
    {state: 'BA', value: 'Aramari'},
    {state: 'BA', value: 'Arataca'},
    {state: 'BA', value: 'Aratuípe'},
    {state: 'BA', value: 'Aurelino Leal'},
    {state: 'BA', value: 'Baianópolis'},
    {state: 'BA', value: 'Baixa Grande'},
    {state: 'BA', value: 'Banzaê'},
    {state: 'BA', value: 'Barra'},
    {state: 'BA', value: 'Barra da Estiva'},
    {state: 'BA', value: 'Barra do Choça'},
    {state: 'BA', value: 'Barra do Mendes'},
    {state: 'BA', value: 'Barra do Rocha'},
    {state: 'BA', value: 'Barreiras'},
    {state: 'BA', value: 'Barro Alto'},
    {state: 'BA', value: 'Barro Preto'},
    {state: 'BA', value: 'Barrocas'},
    {state: 'BA', value: 'Belmonte'},
    {state: 'BA', value: 'Belo Campo'},
    {state: 'BA', value: 'Biritinga'},
    {state: 'BA', value: 'Boa Nova'},
    {state: 'BA', value: 'Boa Vista do Tupim'},
    {state: 'BA', value: 'Bom Jesus da Lapa'},
    {state: 'BA', value: 'Bom Jesus da Serra'},
    {state: 'BA', value: 'Boninal'},
    {state: 'BA', value: 'Bonito'},
    {state: 'BA', value: 'Boquira'},
    {state: 'BA', value: 'Botuporã'},
    {state: 'BA', value: 'Brejões'},
    {state: 'BA', value: 'Brejolândia'},
    {state: 'BA', value: 'Brotas de Macaúbas'},
    {state: 'BA', value: 'Brumado'},
    {state: 'BA', value: 'Buerarema'},
    {state: 'BA', value: 'Buritirama'},
    {state: 'BA', value: 'Caatiba'},
    {state: 'BA', value: 'Cabaceiras do Paraguaçu'},
    {state: 'BA', value: 'Cachoeira'},
    {state: 'BA', value: 'Caculé'},
    {state: 'BA', value: 'Caém'},
    {state: 'BA', value: 'Caetanos'},
    {state: 'BA', value: 'Caetité'},
    {state: 'BA', value: 'Cafarnaum'},
    {state: 'BA', value: 'Cairu'},
    {state: 'BA', value: 'Caldeirão Grande'},
    {state: 'BA', value: 'Camacan'},
    {state: 'BA', value: 'Camaçari'},
    {state: 'BA', value: 'Camamu'},
    {state: 'BA', value: 'Campo Alegre de Lourdes'},
    {state: 'BA', value: 'Campo Formoso'},
    {state: 'BA', value: 'Canápolis'},
    {state: 'BA', value: 'Canarana'},
    {state: 'BA', value: 'Canavieiras'},
    {state: 'BA', value: 'Candeal'},
    {state: 'BA', value: 'Candeias'},
    {state: 'BA', value: 'Candiba'},
    {state: 'BA', value: 'Cândido Sales'},
    {state: 'BA', value: 'Cansanção'},
    {state: 'BA', value: 'Canudos'},
    {state: 'BA', value: 'Capela do Alto Alegre'},
    {state: 'BA', value: 'Capim Grosso'},
    {state: 'BA', value: 'Caraíbas'},
    {state: 'BA', value: 'Caravelas'},
    {state: 'BA', value: 'Cardeal da Silva'},
    {state: 'BA', value: 'Carinhanha'},
    {state: 'BA', value: 'Casa Nova'},
    {state: 'BA', value: 'Castro Alves'},
    {state: 'BA', value: 'Catolândia'},
    {state: 'BA', value: 'Catu'},
    {state: 'BA', value: 'Caturama'},
    {state: 'BA', value: 'Central'},
    {state: 'BA', value: 'Chorrochó'},
    {state: 'BA', value: 'Cícero Dantas'},
    {state: 'BA', value: 'Cipó'},
    {state: 'BA', value: 'Coaraci'},
    {state: 'BA', value: 'Cocos'},
    {state: 'BA', value: 'Conceição da Feira'},
    {state: 'BA', value: 'Conceição do Almeida'},
    {state: 'BA', value: 'Conceição do Coité'},
    {state: 'BA', value: 'Conceição do Jacuípe'},
    {state: 'BA', value: 'Conde'},
    {state: 'BA', value: 'Condeúba'},
    {state: 'BA', value: 'Contendas do Sincorá'},
    {state: 'BA', value: 'Coração de Maria'},
    {state: 'BA', value: 'Cordeiros'},
    {state: 'BA', value: 'Coribe'},
    {state: 'BA', value: 'Coronel João Sá'},
    {state: 'BA', value: 'Correntina'},
    {state: 'BA', value: 'Cotegipe'},
    {state: 'BA', value: 'Cravolândia'},
    {state: 'BA', value: 'Crisópolis'},
    {state: 'BA', value: 'Cristópolis'},
    {state: 'BA', value: 'Cruz das Almas'},
    {state: 'BA', value: 'Curaçá'},
    {state: 'BA', value: 'Dário Meira'},
    {state: 'BA', value: "Dias d'Ávila"},
    {state: 'BA', value: 'Dom Basílio'},
    {state: 'BA', value: 'Dom Macedo Costa'},
    {state: 'BA', value: 'Elísio Medrado'},
    {state: 'BA', value: 'Encruzilhada'},
    {state: 'BA', value: 'Entre Rios'},
    {state: 'BA', value: 'Érico Cardoso'},
    {state: 'BA', value: 'Esplanada'},
    {state: 'BA', value: 'Euclides da Cunha'},
    {state: 'BA', value: 'Eunápolis'},
    {state: 'BA', value: 'Fátima'},
    {state: 'BA', value: 'Feira da Mata'},
    {state: 'BA', value: 'Feira de Santana'},
    {state: 'BA', value: 'Filadélfia'},
    {state: 'BA', value: 'Firmino Alves'},
    {state: 'BA', value: 'Floresta Azul'},
    {state: 'BA', value: 'Formosa do Rio Preto'},
    {state: 'BA', value: 'Gandu'},
    {state: 'BA', value: 'Gavião'},
    {state: 'BA', value: 'Gentio do Ouro'},
    {state: 'BA', value: 'Glória'},
    {state: 'BA', value: 'Gongogi'},
    {state: 'BA', value: 'Governador Mangabeira'},
    {state: 'BA', value: 'Guajeru'},
    {state: 'BA', value: 'Guanambi'},
    {state: 'BA', value: 'Guaratinga'},
    {state: 'BA', value: 'Heliópolis'},
    {state: 'BA', value: 'Iaçu'},
    {state: 'BA', value: 'Ibiassucê'},
    {state: 'BA', value: 'Ibicaraí'},
    {state: 'BA', value: 'Ibicoara'},
    {state: 'BA', value: 'Ibicuí'},
    {state: 'BA', value: 'Ibipeba'},
    {state: 'BA', value: 'Ibipitanga'},
    {state: 'BA', value: 'Ibiquera'},
    {state: 'BA', value: 'Ibirapitanga'},
    {state: 'BA', value: 'Ibirapuã'},
    {state: 'BA', value: 'Ibirataia'},
    {state: 'BA', value: 'Ibitiara'},
    {state: 'BA', value: 'Ibititá'},
    {state: 'BA', value: 'Ibotirama'},
    {state: 'BA', value: 'Ichu'},
    {state: 'BA', value: 'Igaporã'},
    {state: 'BA', value: 'Igrapiúna'},
    {state: 'BA', value: 'Iguaí'},
    {state: 'BA', value: 'Ilhéus'},
    {state: 'BA', value: 'Inhambupe'},
    {state: 'BA', value: 'Ipecaetá'},
    {state: 'BA', value: 'Ipiaú'},
    {state: 'BA', value: 'Ipirá'},
    {state: 'BA', value: 'Ipupiara'},
    {state: 'BA', value: 'Irajuba'},
    {state: 'BA', value: 'Iramaia'},
    {state: 'BA', value: 'Iraquara'},
    {state: 'BA', value: 'Irará'},
    {state: 'BA', value: 'Irecê'},
    {state: 'BA', value: 'Itabela'},
    {state: 'BA', value: 'Itaberaba'},
    {state: 'BA', value: 'Itabuna'},
    {state: 'BA', value: 'Itacaré'},
    {state: 'BA', value: 'Itaetê'},
    {state: 'BA', value: 'Itagi'},
    {state: 'BA', value: 'Itagibá'},
    {state: 'BA', value: 'Itagimirim'},
    {state: 'BA', value: 'Itaguaçu da Bahia'},
    {state: 'BA', value: 'Itaju do Colônia'},
    {state: 'BA', value: 'Itajuípe'},
    {state: 'BA', value: 'Itamaraju'},
    {state: 'BA', value: 'Itamari'},
    {state: 'BA', value: 'Itambé'},
    {state: 'BA', value: 'Itanagra'},
    {state: 'BA', value: 'Itanhém'},
    {state: 'BA', value: 'Itaparica'},
    {state: 'BA', value: 'Itapé'},
    {state: 'BA', value: 'Itapebi'},
    {state: 'BA', value: 'Itapetinga'},
    {state: 'BA', value: 'Itapicuru'},
    {state: 'BA', value: 'Itapitanga'},
    {state: 'BA', value: 'Itaquara'},
    {state: 'BA', value: 'Itarantim'},
    {state: 'BA', value: 'Itatim'},
    {state: 'BA', value: 'Itiruçu'},
    {state: 'BA', value: 'Itiúba'},
    {state: 'BA', value: 'Itororó'},
    {state: 'BA', value: 'Ituaçu'},
    {state: 'BA', value: 'Ituberá'},
    {state: 'BA', value: 'Iuiú'},
    {state: 'BA', value: 'Jaborandi'},
    {state: 'BA', value: 'Jacaraci'},
    {state: 'BA', value: 'Jacobina'},
    {state: 'BA', value: 'Jaguaquara'},
    {state: 'BA', value: 'Jaguarari'},
    {state: 'BA', value: 'Jaguaripe'},
    {state: 'BA', value: 'Jandaíra'},
    {state: 'BA', value: 'Jequié'},
    {state: 'BA', value: 'Jeremoabo'},
    {state: 'BA', value: 'Jiquiriçá'},
    {state: 'BA', value: 'Jitaúna'},
    {state: 'BA', value: 'João Dourado'},
    {state: 'BA', value: 'Juazeiro'},
    {state: 'BA', value: 'Jucuruçu'},
    {state: 'BA', value: 'Jussara'},
    {state: 'BA', value: 'Jussari'},
    {state: 'BA', value: 'Jussiape'},
    {state: 'BA', value: 'Lafaiete Coutinho'},
    {state: 'BA', value: 'Lagoa Real'},
    {state: 'BA', value: 'Laje'},
    {state: 'BA', value: 'Lajedão'},
    {state: 'BA', value: 'Lajedinho'},
    {state: 'BA', value: 'Lajedo do Tabocal'},
    {state: 'BA', value: 'Lamarão'},
    {state: 'BA', value: 'Lapão'},
    {state: 'BA', value: 'Lauro de Freitas'},
    {state: 'BA', value: 'Lençóis'},
    {state: 'BA', value: 'Licínio de Almeida'},
    {state: 'BA', value: 'Livramento de Nossa Senhora'},
    {state: 'BA', value: 'Luís Eduardo Magalhães'},
    {state: 'BA', value: 'Macajuba'},
    {state: 'BA', value: 'Macarani'},
    {state: 'BA', value: 'Macaúbas'},
    {state: 'BA', value: 'Macururé'},
    {state: 'BA', value: 'Madre de Deus'},
    {state: 'BA', value: 'Maetinga'},
    {state: 'BA', value: 'Maiquinique'},
    {state: 'BA', value: 'Mairi'},
    {state: 'BA', value: 'Malhada de Pedras'},
    {state: 'BA', value: 'Malhada'},
    {state: 'BA', value: 'Manoel Vitorino'},
    {state: 'BA', value: 'Mansidão'},
    {state: 'BA', value: 'Maracás'},
    {state: 'BA', value: 'Maragogipe'},
    {state: 'BA', value: 'Maraú'},
    {state: 'BA', value: 'Marcionílio Souza'},
    {state: 'BA', value: 'Mascote'},
    {state: 'BA', value: 'Mata de São João'},
    {state: 'BA', value: 'Matina'},
    {state: 'BA', value: 'Medeiros Neto'},
    {state: 'BA', value: 'Miguel Calmon'},
    {state: 'BA', value: 'Milagres'},
    {state: 'BA', value: 'Mirangaba'},
    {state: 'BA', value: 'Mirante'},
    {state: 'BA', value: 'Monte Santo'},
    {state: 'BA', value: 'Morpará'},
    {state: 'BA', value: 'Morro do Chapéu'},
    {state: 'BA', value: 'Mortugaba'},
    {state: 'BA', value: 'Mucugê'},
    {state: 'BA', value: 'Mucuri'},
    {state: 'BA', value: 'Mulungu do Morro'},
    {state: 'BA', value: 'Mundo Novo'},
    {state: 'BA', value: 'Muniz Ferreira'},
    {state: 'BA', value: 'Muquém do São Francisco'},
    {state: 'BA', value: 'Muritiba'},
    {state: 'BA', value: 'Mutuípe'},
    {state: 'BA', value: 'Nazaré'},
    {state: 'BA', value: 'Nilo Peçanha'},
    {state: 'BA', value: 'Nordestina'},
    {state: 'BA', value: 'Nova Canaã'},
    {state: 'BA', value: 'Nova Fátima'},
    {state: 'BA', value: 'Nova Ibiá'},
    {state: 'BA', value: 'Nova Itarana'},
    {state: 'BA', value: 'Nova Redenção'},
    {state: 'BA', value: 'Nova Soure'},
    {state: 'BA', value: 'Nova Viçosa'},
    {state: 'BA', value: 'Novo Horizonte'},
    {state: 'BA', value: 'Novo Triunfo'},
    {state: 'BA', value: 'Olindina'},
    {state: 'BA', value: 'Oliveira dos Brejinhos'},
    {state: 'BA', value: 'Ouriçangas'},
    {state: 'BA', value: 'Ourolândia'},
    {state: 'BA', value: 'Palmas de Monte Alto'},
    {state: 'BA', value: 'Palmeiras'},
    {state: 'BA', value: 'Paramirim'},
    {state: 'BA', value: 'Paratinga'},
    {state: 'BA', value: 'Paripiranga'},
    {state: 'BA', value: 'Pau Brasil'},
    {state: 'BA', value: 'Paulo Afonso'},
    {state: 'BA', value: 'Pé de Serra'},
    {state: 'BA', value: 'Pedrão'},
    {state: 'BA', value: 'Pedro Alexandre'},
    {state: 'BA', value: 'Piatã'},
    {state: 'BA', value: 'Pilão Arcado'},
    {state: 'BA', value: 'Pindaí'},
    {state: 'BA', value: 'Pindobaçu'},
    {state: 'BA', value: 'Pintadas'},
    {state: 'BA', value: 'Piraí do Norte'},
    {state: 'BA', value: 'Piripá'},
    {state: 'BA', value: 'Piritiba'},
    {state: 'BA', value: 'Planaltino'},
    {state: 'BA', value: 'Planalto'},
    {state: 'BA', value: 'Poções'},
    {state: 'BA', value: 'Pojuca'},
    {state: 'BA', value: 'Ponto Novo'},
    {state: 'BA', value: 'Porto Seguro'},
    {state: 'BA', value: 'Potiraguá'},
    {state: 'BA', value: 'Prado'},
    {state: 'BA', value: 'Presidente Dutra'},
    {state: 'BA', value: 'Presidente Jânio Quadros'},
    {state: 'BA', value: 'Presidente Tancredo Neves'},
    {state: 'BA', value: 'Queimadas'},
    {state: 'BA', value: 'Quijingue'},
    {state: 'BA', value: 'Quixabeira'},
    {state: 'BA', value: 'Rafael Jambeiro'},
    {state: 'BA', value: 'Remanso'},
    {state: 'BA', value: 'Retirolândia'},
    {state: 'BA', value: 'Riachão das Neves'},
    {state: 'BA', value: 'Riachão do Jacuípe'},
    {state: 'BA', value: 'Riacho de Santana'},
    {state: 'BA', value: 'Ribeira do Amparo'},
    {state: 'BA', value: 'Ribeira do Pombal'},
    {state: 'BA', value: 'Ribeirão do Largo'},
    {state: 'BA', value: 'Rio de Contas'},
    {state: 'BA', value: 'Rio do Antônio'},
    {state: 'BA', value: 'Rio do Pires'},
    {state: 'BA', value: 'Rio Real'},
    {state: 'BA', value: 'Rodelas'},
    {state: 'BA', value: 'Ruy Barbosa'},
    {state: 'BA', value: 'Salinas da Margarida'},
    {state: 'BA', value: 'Salvador'},
    {state: 'BA', value: 'Santa Bárbara'},
    {state: 'BA', value: 'Santa Brígida'},
    {state: 'BA', value: 'Santa Cruz Cabrália'},
    {state: 'BA', value: 'Santa Cruz da Vitória'},
    {state: 'BA', value: 'Santa Inês'},
    {state: 'BA', value: 'Santa Luzia'},
    {state: 'BA', value: 'Santa Maria da Vitória'},
    {state: 'BA', value: 'Santa Rita de Cássia'},
    {state: 'BA', value: 'Santa Teresinha'},
    {state: 'BA', value: 'Santaluz'},
    {state: 'BA', value: 'Santana'},
    {state: 'BA', value: 'Santanópolis'},
    {state: 'BA', value: 'Santo Amaro'},
    {state: 'BA', value: 'Santo Antônio de Jesus'},
    {state: 'BA', value: 'Santo Estêvão'},
    {state: 'BA', value: 'São Desidério'},
    {state: 'BA', value: 'São Domingos'},
    {state: 'BA', value: 'São Felipe'},
    {state: 'BA', value: 'São Félix'},
    {state: 'BA', value: 'São Félix do Coribe'},
    {state: 'BA', value: 'São Francisco do Conde'},
    {state: 'BA', value: 'São Gabriel'},
    {state: 'BA', value: 'São Gonçalo dos Campos'},
    {state: 'BA', value: 'São José da Vitória'},
    {state: 'BA', value: 'São José do Jacuípe'},
    {state: 'BA', value: 'São Miguel das Matas'},
    {state: 'BA', value: 'São Sebastião do Passé'},
    {state: 'BA', value: 'Sapeaçu'},
    {state: 'BA', value: 'Sátiro Dias'},
    {state: 'BA', value: 'Saubara'},
    {state: 'BA', value: 'Saúde'},
    {state: 'BA', value: 'Seabra'},
    {state: 'BA', value: 'Sebastião Laranjeiras'},
    {state: 'BA', value: 'Senhor do Bonfim'},
    {state: 'BA', value: 'Sento Sé'},
    {state: 'BA', value: 'Serra do Ramalho'},
    {state: 'BA', value: 'Serra Dourada'},
    {state: 'BA', value: 'Serra Preta'},
    {state: 'BA', value: 'Serrinha'},
    {state: 'BA', value: 'Serrolândia'},
    {state: 'BA', value: 'Simões Filho'},
    {state: 'BA', value: 'Sítio do Mato'},
    {state: 'BA', value: 'Sítio do Quinto'},
    {state: 'BA', value: 'Sobradinho'},
    {state: 'BA', value: 'Souto Soares'},
    {state: 'BA', value: 'Tabocas do Brejo Velho'},
    {state: 'BA', value: 'Tanhaçu'},
    {state: 'BA', value: 'Tanque Novo'},
    {state: 'BA', value: 'Tanquinho'},
    {state: 'BA', value: 'Taperoá'},
    {state: 'BA', value: 'Tapiramutá'},
    {state: 'BA', value: 'Teixeira de Freitas'},
    {state: 'BA', value: 'Teodoro Sampaio'},
    {state: 'BA', value: 'Teofilândia'},
    {state: 'BA', value: 'Teolândia'},
    {state: 'BA', value: 'Terra Nova'},
    {state: 'BA', value: 'Tremedal'},
    {state: 'BA', value: 'Tucano'},
    {state: 'BA', value: 'Uauá'},
    {state: 'BA', value: 'Ubaíra'},
    {state: 'BA', value: 'Ubaitaba'},
    {state: 'BA', value: 'Ubatã'},
    {state: 'BA', value: 'Uibaí'},
    {state: 'BA', value: 'Umburanas'},
    {state: 'BA', value: 'Una'},
    {state: 'BA', value: 'Urandi'},
    {state: 'BA', value: 'Uruçuca'},
    {state: 'BA', value: 'Utinga'},
    {state: 'BA', value: 'Valença'},
    {state: 'BA', value: 'Valente'},
    {state: 'BA', value: 'Várzea da Roça'},
    {state: 'BA', value: 'Várzea do Poço'},
    {state: 'BA', value: 'Várzea Nova'},
    {state: 'BA', value: 'Varzedo'},
    {state: 'BA', value: 'Vera Cruz'},
    {state: 'BA', value: 'Vereda'},
    {state: 'BA', value: 'Vitória da Conquista'},
    {state: 'BA', value: 'Wagner'},
    {state: 'BA', value: 'Wanderley'},
    {state: 'BA', value: 'Wenceslau Guimarães'},
    {state: 'BA', value: 'Xique-Xique'},
    {state: 'SE', value: 'Amparo de São Francisco'},
    {state: 'SE', value: 'Aquidabã'},
    {state: 'SE', value: 'Aracaju'},
    {state: 'SE', value: 'Arauá'},
    {state: 'SE', value: 'Areia Branca'},
    {state: 'SE', value: 'Barra dos Coqueiros'},
    {state: 'SE', value: 'Boquim'},
    {state: 'SE', value: 'Brejo Grande'},
    {state: 'SE', value: 'Campo do Brito'},
    {state: 'SE', value: 'Canhoba'},
    {state: 'SE', value: 'Canindé de São Francisco'},
    {state: 'SE', value: 'Capela'},
    {state: 'SE', value: 'Carira'},
    {state: 'SE', value: 'Carmópolis'},
    {state: 'SE', value: 'Cedro de São João'},
    {state: 'SE', value: 'Cristinápolis'},
    {state: 'SE', value: 'Cumbe'},
    {state: 'SE', value: 'Divina Pastora'},
    {state: 'SE', value: 'Estância'},
    {state: 'SE', value: 'Feira Nova'},
    {state: 'SE', value: 'Frei Paulo'},
    {state: 'SE', value: 'Gararu'},
    {state: 'SE', value: 'General Maynard'},
    {state: 'SE', value: 'Graccho Cardoso'},
    {state: 'SE', value: 'Ilha das Flores'},
    {state: 'SE', value: 'Indiaroba'},
    {state: 'SE', value: 'Itabaiana'},
    {state: 'SE', value: 'Itabaianinha'},
    {state: 'SE', value: 'Itabi'},
    {state: 'SE', value: "Itaporanga d'Ajuda"},
    {state: 'SE', value: 'Japaratuba'},
    {state: 'SE', value: 'Japoatã'},
    {state: 'SE', value: 'Lagarto'},
    {state: 'SE', value: 'Laranjeiras'},
    {state: 'SE', value: 'Macambira'},
    {state: 'SE', value: 'Malhada dos Bois'},
    {state: 'SE', value: 'Malhador'},
    {state: 'SE', value: 'Maruim'},
    {state: 'SE', value: 'Moita Bonita'},
    {state: 'SE', value: 'Monte Alegre de Sergipe'},
    {state: 'SE', value: 'Muribeca'},
    {state: 'SE', value: 'Neópolis'},
    {state: 'SE', value: 'Nossa Senhora Aparecida'},
    {state: 'SE', value: 'Nossa Senhora da Glória'},
    {state: 'SE', value: 'Nossa Senhora das Dores'},
    {state: 'SE', value: 'Nossa Senhora de Lourdes'},
    {state: 'SE', value: 'Nossa Senhora do Socorro'},
    {state: 'SE', value: 'Pacatuba'},
    {state: 'SE', value: 'Pedra Mole'},
    {state: 'SE', value: 'Pedrinhas'},
    {state: 'SE', value: 'Pinhão'},
    {state: 'SE', value: 'Pirambu'},
    {state: 'SE', value: 'Poço Redondo'},
    {state: 'SE', value: 'Poço Verde'},
    {state: 'SE', value: 'Porto da Folha'},
    {state: 'SE', value: 'Propriá'},
    {state: 'SE', value: 'Riachão do Dantas'},
    {state: 'SE', value: 'Riachuelo'},
    {state: 'SE', value: 'Ribeirópolis'},
    {state: 'SE', value: 'Rosário do Catete'},
    {state: 'SE', value: 'Salgado'},
    {state: 'SE', value: 'Santa Luzia do Itanhy'},
    {state: 'SE', value: 'Santa Rosa de Lima'},
    {state: 'SE', value: 'Santana do São Francisco'},
    {state: 'SE', value: 'Santo Amaro das Brotas'},
    {state: 'SE', value: 'São Cristóvão'},
    {state: 'SE', value: 'São Domingos'},
    {state: 'SE', value: 'São Francisco'},
    {state: 'SE', value: 'São Miguel do Aleixo'},
    {state: 'SE', value: 'Simão Dias'},
    {state: 'SE', value: 'Siriri'},
    {state: 'SE', value: 'Telha'},
    {state: 'SE', value: 'Tobias Barreto'},
    {state: 'SE', value: 'Tomar do Geru'},
    {state: 'SE', value: 'Umbaúba'},
    {state: 'AL', value: 'Água Branca'},
    {state: 'AL', value: 'Anadia'},
    {state: 'AL', value: 'Arapiraca'},
    {state: 'AL', value: 'Atalaia'},
    {state: 'AL', value: 'Barra de Santo Antônio'},
    {state: 'AL', value: 'Barra de São Miguel'},
    {state: 'AL', value: 'Batalha'},
    {state: 'AL', value: 'Belém'},
    {state: 'AL', value: 'Belo Monte'},
    {state: 'AL', value: 'Boca da Mata'},
    {state: 'AL', value: 'Branquinha'},
    {state: 'AL', value: 'Cacimbinhas'},
    {state: 'AL', value: 'Cajueiro'},
    {state: 'AL', value: 'Campestre'},
    {state: 'AL', value: 'Campo Alegre'},
    {state: 'AL', value: 'Campo Grande'},
    {state: 'AL', value: 'Canapi'},
    {state: 'AL', value: 'Capela'},
    {state: 'AL', value: 'Carneiros'},
    {state: 'AL', value: 'Chã Preta'},
    {state: 'AL', value: 'Coité do Noia'},
    {state: 'AL', value: 'Colônia Leopoldina'},
    {state: 'AL', value: 'Coqueiro Seco'},
    {state: 'AL', value: 'Coruripe'},
    {state: 'AL', value: 'Craíbas'},
    {state: 'AL', value: 'Delmiro Gouveia'},
    {state: 'AL', value: 'Dois Riachos'},
    {state: 'AL', value: 'Estrela de Alagoas'},
    {state: 'AL', value: 'Feira Grande'},
    {state: 'AL', value: 'Feliz Deserto'},
    {state: 'AL', value: 'Flexeiras'},
    {state: 'AL', value: 'Girau do Ponciano'},
    {state: 'AL', value: 'Ibateguara'},
    {state: 'AL', value: 'Igaci'},
    {state: 'AL', value: 'Igreja Nova'},
    {state: 'AL', value: 'Inhapi'},
    {state: 'AL', value: 'Jacaré dos Homens'},
    {state: 'AL', value: 'Jacuípe'},
    {state: 'AL', value: 'Japaratinga'},
    {state: 'AL', value: 'Jaramataia'},
    {state: 'AL', value: 'Jequiá da Praia'},
    {state: 'AL', value: 'Joaquim Gomes'},
    {state: 'AL', value: 'Jundiá'},
    {state: 'AL', value: 'Junqueiro'},
    {state: 'AL', value: 'Lagoa da Canoa'},
    {state: 'AL', value: 'Limoeiro de Anadia'},
    {state: 'AL', value: 'Maceió'},
    {state: 'AL', value: 'Major Izidoro'},
    {state: 'AL', value: 'Mar Vermelho'},
    {state: 'AL', value: 'Maragogi'},
    {state: 'AL', value: 'Maravilha'},
    {state: 'AL', value: 'Marechal Deodoro'},
    {state: 'AL', value: 'Maribondo'},
    {state: 'AL', value: 'Mata Grande'},
    {state: 'AL', value: 'Matriz de Camaragibe'},
    {state: 'AL', value: 'Messias'},
    {state: 'AL', value: 'Minador do Negrão'},
    {state: 'AL', value: 'Monteirópolis'},
    {state: 'AL', value: 'Murici'},
    {state: 'AL', value: 'Novo Lino'},
    {state: 'AL', value: "Olho d'Água das Flores"},
    {state: 'AL', value: "Olho d'Água do Casado"},
    {state: 'AL', value: "Olho d'Água Grande"},
    {state: 'AL', value: 'Olivença'},
    {state: 'AL', value: 'Ouro Branco'},
    {state: 'AL', value: 'Palestina'},
    {state: 'AL', value: 'Palmeira dos Índios'},
    {state: 'AL', value: 'Pão de Açúcar'},
    {state: 'AL', value: 'Pariconha'},
    {state: 'AL', value: 'Paripueira'},
    {state: 'AL', value: 'Passo de Camaragibe'},
    {state: 'AL', value: 'Paulo Jacinto'},
    {state: 'AL', value: 'Penedo'},
    {state: 'AL', value: 'Piaçabuçu'},
    {state: 'AL', value: 'Pilar'},
    {state: 'AL', value: 'Pindoba'},
    {state: 'AL', value: 'Piranhas'},
    {state: 'AL', value: 'Poço das Trincheiras'},
    {state: 'AL', value: 'Porto Calvo'},
    {state: 'AL', value: 'Porto de Pedras'},
    {state: 'AL', value: 'Porto Real do Colégio'},
    {state: 'AL', value: 'Quebrangulo'},
    {state: 'AL', value: 'Rio Largo'},
    {state: 'AL', value: 'Roteiro'},
    {state: 'AL', value: 'Santa Luzia do Norte'},
    {state: 'AL', value: 'Santana do Ipanema'},
    {state: 'AL', value: 'Santana do Mundaú'},
    {state: 'AL', value: 'São Brás'},
    {state: 'AL', value: 'São José da Laje'},
    {state: 'AL', value: 'São José da Tapera'},
    {state: 'AL', value: 'São Luís do Quitunde'},
    {state: 'AL', value: 'São Miguel dos Campos'},
    {state: 'AL', value: 'São Miguel dos Milagres'},
    {state: 'AL', value: 'São Sebastião'},
    {state: 'AL', value: 'Satuba'},
    {state: 'AL', value: 'Senador Rui Palmeira'},
    {state: 'AL', value: "Tanque d'Arca"},
    {state: 'AL', value: 'Taquarana'},
    {state: 'AL', value: 'Teotônio Vilela'},
    {state: 'AL', value: 'Traipu'},
    {state: 'AL', value: 'União dos Palmares'},
    {state: 'AL', value: 'Viçosa'},
    {state: 'PE', value: 'Abreu e Lima'},
    {state: 'PE', value: 'Afogados da Ingazeira'},
    {state: 'PE', value: 'Afrânio'},
    {state: 'PE', value: 'Agrestina'},
    {state: 'PE', value: 'Água Preta'},
    {state: 'PE', value: 'Águas Belas'},
    {state: 'PE', value: 'Alagoinha'},
    {state: 'PE', value: 'Aliança'},
    {state: 'PE', value: 'Altinho'},
    {state: 'PE', value: 'Amaraji'},
    {state: 'PE', value: 'Angelim'},
    {state: 'PE', value: 'Araçoiaba'},
    {state: 'PE', value: 'Araripina'},
    {state: 'PE', value: 'Arcoverde'},
    {state: 'PE', value: 'Barra de Guabiraba'},
    {state: 'PE', value: 'Barreiros'},
    {state: 'PE', value: 'Belém de Maria'},
    {state: 'PE', value: 'Belém do São Francisco'},
    {state: 'PE', value: 'Belo Jardim'},
    {state: 'PE', value: 'Betânia'},
    {state: 'PE', value: 'Bezerros'},
    {state: 'PE', value: 'Bodocó'},
    {state: 'PE', value: 'Bom Conselho'},
    {state: 'PE', value: 'Bom Jardim'},
    {state: 'PE', value: 'Bonito'},
    {state: 'PE', value: 'Brejão'},
    {state: 'PE', value: 'Brejinho'},
    {state: 'PE', value: 'Brejo da Madre de Deus'},
    {state: 'PE', value: 'Buenos Aires'},
    {state: 'PE', value: 'Buíque'},
    {state: 'PE', value: 'Cabo de Santo Agostinho'},
    {state: 'PE', value: 'Cabrobó'},
    {state: 'PE', value: 'Cachoeirinha'},
    {state: 'PE', value: 'Caetés'},
    {state: 'PE', value: 'Calçado'},
    {state: 'PE', value: 'Calumbi'},
    {state: 'PE', value: 'Camaragibe'},
    {state: 'PE', value: 'Camocim de São Félix'},
    {state: 'PE', value: 'Camutanga'},
    {state: 'PE', value: 'Canhotinho'},
    {state: 'PE', value: 'Capoeiras'},
    {state: 'PE', value: 'Carnaíba'},
    {state: 'PE', value: 'Carnaubeira da Penha'},
    {state: 'PE', value: 'Carpina'},
    {state: 'PE', value: 'Caruaru'},
    {state: 'PE', value: 'Casinhas'},
    {state: 'PE', value: 'Catende'},
    {state: 'PE', value: 'Cedro'},
    {state: 'PE', value: 'Chã de Alegria'},
    {state: 'PE', value: 'Chã Grande'},
    {state: 'PE', value: 'Condado'},
    {state: 'PE', value: 'Correntes'},
    {state: 'PE', value: 'Cortês'},
    {state: 'PE', value: 'Cumaru'},
    {state: 'PE', value: 'Cupira'},
    {state: 'PE', value: 'Custódia'},
    {state: 'PE', value: 'Dormentes'},
    {state: 'PE', value: 'Escada'},
    {state: 'PE', value: 'Exu'},
    {state: 'PE', value: 'Feira Nova'},
    {state: 'PE', value: 'Fernando de Noronha'},
    {state: 'PE', value: 'Ferreiros'},
    {state: 'PE', value: 'Flores'},
    {state: 'PE', value: 'Floresta'},
    {state: 'PE', value: 'Frei Miguelinho'},
    {state: 'PE', value: 'Gameleira'},
    {state: 'PE', value: 'Garanhuns'},
    {state: 'PE', value: 'Glória do Goitá'},
    {state: 'PE', value: 'Goiana'},
    {state: 'PE', value: 'Granito'},
    {state: 'PE', value: 'Gravatá'},
    {state: 'PE', value: 'Iati'},
    {state: 'PE', value: 'Ibimirim'},
    {state: 'PE', value: 'Ibirajuba'},
    {state: 'PE', value: 'Igarassu'},
    {state: 'PE', value: 'Iguaraci'},
    {state: 'PE', value: 'Ilha de Itamaracá'},
    {state: 'PE', value: 'Inajá'},
    {state: 'PE', value: 'Ingazeira'},
    {state: 'PE', value: 'Ipojuca'},
    {state: 'PE', value: 'Ipubi'},
    {state: 'PE', value: 'Itacuruba'},
    {state: 'PE', value: 'Itaíba'},
    {state: 'PE', value: 'Itambé'},
    {state: 'PE', value: 'Itapetim'},
    {state: 'PE', value: 'Itapissuma'},
    {state: 'PE', value: 'Itaquitinga'},
    {state: 'PE', value: 'Jaboatão dos Guararapes'},
    {state: 'PE', value: 'Jaqueira'},
    {state: 'PE', value: 'Jataúba'},
    {state: 'PE', value: 'Jatobá'},
    {state: 'PE', value: 'João Alfredo'},
    {state: 'PE', value: 'Joaquim Nabuco'},
    {state: 'PE', value: 'Jucati'},
    {state: 'PE', value: 'Jupi'},
    {state: 'PE', value: 'Jurema'},
    {state: 'PE', value: 'Lagoa de Itaenga'},
    {state: 'PE', value: 'Lagoa do Carro'},
    {state: 'PE', value: 'Lagoa do Ouro'},
    {state: 'PE', value: 'Lagoa dos Gatos'},
    {state: 'PE', value: 'Lagoa Grande'},
    {state: 'PE', value: 'Lajedo'},
    {state: 'PE', value: 'Limoeiro'},
    {state: 'PE', value: 'Macaparana'},
    {state: 'PE', value: 'Machados'},
    {state: 'PE', value: 'Manari'},
    {state: 'PE', value: 'Maraial'},
    {state: 'PE', value: 'Mirandiba'},
    {state: 'PE', value: 'Moreilândia'},
    {state: 'PE', value: 'Moreno'},
    {state: 'PE', value: 'Nazaré da Mata'},
    {state: 'PE', value: 'Olinda'},
    {state: 'PE', value: 'Orobó'},
    {state: 'PE', value: 'Orocó'},
    {state: 'PE', value: 'Ouricuri'},
    {state: 'PE', value: 'Palmares'},
    {state: 'PE', value: 'Palmeirina'},
    {state: 'PE', value: 'Panelas'},
    {state: 'PE', value: 'Paranatama'},
    {state: 'PE', value: 'Parnamirim'},
    {state: 'PE', value: 'Passira'},
    {state: 'PE', value: 'Paudalho'},
    {state: 'PE', value: 'Paulista'},
    {state: 'PE', value: 'Pedra'},
    {state: 'PE', value: 'Pesqueira'},
    {state: 'PE', value: 'Petrolândia'},
    {state: 'PE', value: 'Petrolina'},
    {state: 'PE', value: 'Poção'},
    {state: 'PE', value: 'Pombos'},
    {state: 'PE', value: 'Primavera'},
    {state: 'PE', value: 'Quipapá'},
    {state: 'PE', value: 'Quixaba'},
    {state: 'PE', value: 'Recife'},
    {state: 'PE', value: 'Riacho das Almas'},
    {state: 'PE', value: 'Ribeirão'},
    {state: 'PE', value: 'Rio Formoso'},
    {state: 'PE', value: 'Sairé'},
    {state: 'PE', value: 'Salgadinho'},
    {state: 'PE', value: 'Salgueiro'},
    {state: 'PE', value: 'Saloá'},
    {state: 'PE', value: 'Sanharó'},
    {state: 'PE', value: 'Santa Cruz'},
    {state: 'PE', value: 'Santa Cruz da Baixa Verde'},
    {state: 'PE', value: 'Santa Cruz do Capibaribe'},
    {state: 'PE', value: 'Santa Filomena'},
    {state: 'PE', value: 'Santa Maria da Boa Vista'},
    {state: 'PE', value: 'Santa Maria do Cambucá'},
    {state: 'PE', value: 'Santa Terezinha'},
    {state: 'PE', value: 'São Benedito do Sul'},
    {state: 'PE', value: 'São Bento do Una'},
    {state: 'PE', value: 'São Caetano'},
    {state: 'PE', value: 'São João'},
    {state: 'PE', value: 'São Joaquim do Monte'},
    {state: 'PE', value: 'São José da Coroa Grande'},
    {state: 'PE', value: 'São José do Belmonte'},
    {state: 'PE', value: 'São José do Egito'},
    {state: 'PE', value: 'São Lourenço da Mata'},
    {state: 'PE', value: 'São Vicente Férrer'},
    {state: 'PE', value: 'Serra Talhada'},
    {state: 'PE', value: 'Serrita'},
    {state: 'PE', value: 'Sertânia'},
    {state: 'PE', value: 'Sirinhaém'},
    {state: 'PE', value: 'Solidão'},
    {state: 'PE', value: 'Surubim'},
    {state: 'PE', value: 'Tabira'},
    {state: 'PE', value: 'Tacaimbó'},
    {state: 'PE', value: 'Tacaratu'},
    {state: 'PE', value: 'Tamandaré'},
    {state: 'PE', value: 'Taquaritinga do Norte'},
    {state: 'PE', value: 'Terezinha'},
    {state: 'PE', value: 'Terra Nova'},
    {state: 'PE', value: 'Timbaúba'},
    {state: 'PE', value: 'Toritama'},
    {state: 'PE', value: 'Tracunhaém'},
    {state: 'PE', value: 'Trindade'},
    {state: 'PE', value: 'Triunfo'},
    {state: 'PE', value: 'Tupanatinga'},
    {state: 'PE', value: 'Tuparetama'},
    {state: 'PE', value: 'Venturosa'},
    {state: 'PE', value: 'Verdejante'},
    {state: 'PE', value: 'Vertente do Lério'},
    {state: 'PE', value: 'Vertentes'},
    {state: 'PE', value: 'Vicência'},
    {state: 'PE', value: 'Vitória de Santo Antão'},
    {state: 'PE', value: 'Xexéu'},
    {state: 'PB', value: 'Água Branca'},
    {state: 'PB', value: 'Aguiar'},
    {state: 'PB', value: 'Alagoa Grande'},
    {state: 'PB', value: 'Alagoa Nova'},
    {state: 'PB', value: 'Alagoinha'},
    {state: 'PB', value: 'Alcantil'},
    {state: 'PB', value: 'Algodão de Jandaíra'},
    {state: 'PB', value: 'Alhandra'},
    {state: 'PB', value: 'Amparo'},
    {state: 'PB', value: 'Aparecida'},
    {state: 'PB', value: 'Araçagi'},
    {state: 'PB', value: 'Arara'},
    {state: 'PB', value: 'Araruna'},
    {state: 'PB', value: 'Areia'},
    {state: 'PB', value: 'Areia de Baraúnas'},
    {state: 'PB', value: 'Areial'},
    {state: 'PB', value: 'Aroeiras'},
    {state: 'PB', value: 'Assunção'},
    {state: 'PB', value: 'Baía da Traição'},
    {state: 'PB', value: 'Bananeiras'},
    {state: 'PB', value: 'Baraúna'},
    {state: 'PB', value: 'Barra de Santa Rosa'},
    {state: 'PB', value: 'Barra de Santana'},
    {state: 'PB', value: 'Barra de São Miguel'},
    {state: 'PB', value: 'Bayeux'},
    {state: 'PB', value: 'Belém'},
    {state: 'PB', value: 'Belém do Brejo do Cruz'},
    {state: 'PB', value: 'Bernardino Batista'},
    {state: 'PB', value: 'Boa Ventura'},
    {state: 'PB', value: 'Boa Vista'},
    {state: 'PB', value: 'Bom Jesus'},
    {state: 'PB', value: 'Bom Sucesso'},
    {state: 'PB', value: 'Bonito de Santa Fé'},
    {state: 'PB', value: 'Boqueirão'},
    {state: 'PB', value: 'Borborema'},
    {state: 'PB', value: 'Brejo do Cruz'},
    {state: 'PB', value: 'Brejo dos Santos'},
    {state: 'PB', value: 'Caaporã'},
    {state: 'PB', value: 'Cabaceiras'},
    {state: 'PB', value: 'Cabedelo'},
    {state: 'PB', value: 'Cachoeira dos Índios'},
    {state: 'PB', value: 'Cacimba de Areia'},
    {state: 'PB', value: 'Cacimba de Dentro'},
    {state: 'PB', value: 'Cacimbas'},
    {state: 'PB', value: 'Caiçara'},
    {state: 'PB', value: 'Cajazeiras'},
    {state: 'PB', value: 'Cajazeirinhas'},
    {state: 'PB', value: 'Caldas Brandão'},
    {state: 'PB', value: 'Camalaú'},
    {state: 'PB', value: 'Campina Grande'},
    {state: 'PB', value: 'Capim'},
    {state: 'PB', value: 'Caraúbas'},
    {state: 'PB', value: 'Carrapateira'},
    {state: 'PB', value: 'Casserengue'},
    {state: 'PB', value: 'Catingueira'},
    {state: 'PB', value: 'Catolé do Rocha'},
    {state: 'PB', value: 'Caturité'},
    {state: 'PB', value: 'Conceição'},
    {state: 'PB', value: 'Condado'},
    {state: 'PB', value: 'Conde'},
    {state: 'PB', value: 'Congo'},
    {state: 'PB', value: 'Coremas'},
    {state: 'PB', value: 'Coxixola'},
    {state: 'PB', value: 'Cruz do Espírito Santo'},
    {state: 'PB', value: 'Cubati'},
    {state: 'PB', value: 'Cuité'},
    {state: 'PB', value: 'Cuité de Mamanguape'},
    {state: 'PB', value: 'Cuitegi'},
    {state: 'PB', value: 'Curral de Cima'},
    {state: 'PB', value: 'Curral Velho'},
    {state: 'PB', value: 'Damião'},
    {state: 'PB', value: 'Desterro'},
    {state: 'PB', value: 'Diamante'},
    {state: 'PB', value: 'Dona Inês'},
    {state: 'PB', value: 'Duas Estradas'},
    {state: 'PB', value: 'Emas'},
    {state: 'PB', value: 'Esperança'},
    {state: 'PB', value: 'Fagundes'},
    {state: 'PB', value: 'Frei Martinho'},
    {state: 'PB', value: 'Gado Bravo'},
    {state: 'PB', value: 'Guarabira'},
    {state: 'PB', value: 'Gurinhém'},
    {state: 'PB', value: 'Gurjão'},
    {state: 'PB', value: 'Ibiara'},
    {state: 'PB', value: 'Igaracy'},
    {state: 'PB', value: 'Imaculada'},
    {state: 'PB', value: 'Ingá'},
    {state: 'PB', value: 'Itabaiana'},
    {state: 'PB', value: 'Itaporanga'},
    {state: 'PB', value: 'Itapororoca'},
    {state: 'PB', value: 'Itatuba'},
    {state: 'PB', value: 'Jacaraú'},
    {state: 'PB', value: 'Jericó'},
    {state: 'PB', value: 'João Pessoa'},
    {state: 'PB', value: 'Joca Claudino (ex-Santarém)'},
    {state: 'PB', value: 'Juarez Távora'},
    {state: 'PB', value: 'Juazeirinho'},
    {state: 'PB', value: 'Junco do Seridó'},
    {state: 'PB', value: 'Juripiranga'},
    {state: 'PB', value: 'Juru'},
    {state: 'PB', value: 'Lagoa'},
    {state: 'PB', value: 'Lagoa de Dentro'},
    {state: 'PB', value: 'Lagoa Seca'},
    {state: 'PB', value: 'Lastro'},
    {state: 'PB', value: 'Livramento'},
    {state: 'PB', value: 'Logradouro'},
    {state: 'PB', value: 'Lucena'},
    {state: 'PB', value: "Mãe d'Água"},
    {state: 'PB', value: 'Malta'},
    {state: 'PB', value: 'Mamanguape'},
    {state: 'PB', value: 'Manaíra'},
    {state: 'PB', value: 'Marcação'},
    {state: 'PB', value: 'Mari'},
    {state: 'PB', value: 'Marizópolis'},
    {state: 'PB', value: 'Massaranduba'},
    {state: 'PB', value: 'Mataraca'},
    {state: 'PB', value: 'Matinhas'},
    {state: 'PB', value: 'Mato Grosso'},
    {state: 'PB', value: 'Matureia'},
    {state: 'PB', value: 'Mogeiro'},
    {state: 'PB', value: 'Montadas'},
    {state: 'PB', value: 'Monte Horebe'},
    {state: 'PB', value: 'Monteiro'},
    {state: 'PB', value: 'Mulungu'},
    {state: 'PB', value: 'Natuba'},
    {state: 'PB', value: 'Nazarezinho'},
    {state: 'PB', value: 'Nova Floresta'},
    {state: 'PB', value: 'Nova Olinda'},
    {state: 'PB', value: 'Nova Palmeira'},
    {state: 'PB', value: "Olho d'Água"},
    {state: 'PB', value: 'Olivedos'},
    {state: 'PB', value: 'Ouro Velho'},
    {state: 'PB', value: 'Parari'},
    {state: 'PB', value: 'Passagem'},
    {state: 'PB', value: 'Patos'},
    {state: 'PB', value: 'Paulista'},
    {state: 'PB', value: 'Pedra Branca'},
    {state: 'PB', value: 'Pedra Lavrada'},
    {state: 'PB', value: 'Pedras de Fogo'},
    {state: 'PB', value: 'Pedro Régis'},
    {state: 'PB', value: 'Piancó'},
    {state: 'PB', value: 'Picuí'},
    {state: 'PB', value: 'Pilar'},
    {state: 'PB', value: 'Pilões'},
    {state: 'PB', value: 'Pilõezinhos'},
    {state: 'PB', value: 'Pirpirituba'},
    {state: 'PB', value: 'Pitimbu'},
    {state: 'PB', value: 'Pocinhos'},
    {state: 'PB', value: 'Poço Dantas'},
    {state: 'PB', value: 'Poço de José de Moura'},
    {state: 'PB', value: 'Pombal'},
    {state: 'PB', value: 'Prata'},
    {state: 'PB', value: 'Princesa Isabel'},
    {state: 'PB', value: 'Puxinanã'},
    {state: 'PB', value: 'Queimadas'},
    {state: 'PB', value: 'Quixaba'},
    {state: 'PB', value: 'Remígio'},
    {state: 'PB', value: 'Riachão'},
    {state: 'PB', value: 'Riachão do Bacamarte'},
    {state: 'PB', value: 'Riachão do Poço'},
    {state: 'PB', value: 'Riacho de Santo Antônio'},
    {state: 'PB', value: 'Riacho dos Cavalos'},
    {state: 'PB', value: 'Rio Tinto'},
    {state: 'PB', value: 'Salgadinho'},
    {state: 'PB', value: 'Salgado de São Félix'},
    {state: 'PB', value: 'Santa Cecília'},
    {state: 'PB', value: 'Santa Cruz'},
    {state: 'PB', value: 'Santa Helena'},
    {state: 'PB', value: 'Santa Inês'},
    {state: 'PB', value: 'Santa Luzia'},
    {state: 'PB', value: 'Santa Rita'},
    {state: 'PB', value: 'Santa Terezinha'},
    {state: 'PB', value: 'Santana de Mangueira'},
    {state: 'PB', value: 'Santana dos Garrotes'},
    {state: 'PB', value: 'Santo André'},
    {state: 'PB', value: 'São Bentinho'},
    {state: 'PB', value: 'São Bento'},
    {state: 'PB', value: 'São Domingos'},
    {state: 'PB', value: 'São Domingos do Cariri'},
    {state: 'PB', value: 'São Francisco'},
    {state: 'PB', value: 'São João do Cariri'},
    {state: 'PB', value: 'São João do Rio do Peixe'},
    {state: 'PB', value: 'São João do Tigre'},
    {state: 'PB', value: 'São José da Lagoa Tapada'},
    {state: 'PB', value: 'São José de Caiana'},
    {state: 'PB', value: 'São José de Espinharas'},
    {state: 'PB', value: 'São José de Piranhas'},
    {state: 'PB', value: 'São José de Princesa'},
    {state: 'PB', value: 'São José do Bonfim'},
    {state: 'PB', value: 'São José do Brejo do Cruz'},
    {state: 'PB', value: 'São José do Sabugi'},
    {state: 'PB', value: 'São José dos Cordeiros'},
    {state: 'PB', value: 'São José dos Ramos'},
    {state: 'PB', value: 'São Mamede'},
    {state: 'PB', value: 'São Miguel de Taipu'},
    {state: 'PB', value: 'São Sebastião de Lagoa de Roça'},
    {state: 'PB', value: 'São Sebastião do Umbuzeiro'},
    {state: 'PB', value: 'São Vicente do Seridó'},
    {state: 'PB', value: 'Sapé'},
    {state: 'PB', value: 'Serra Branca'},
    {state: 'PB', value: 'Serra da Raiz'},
    {state: 'PB', value: 'Serra Grande'},
    {state: 'PB', value: 'Serra Redonda'},
    {state: 'PB', value: 'Serraria'},
    {state: 'PB', value: 'Sertãozinho'},
    {state: 'PB', value: 'Sobrado'},
    {state: 'PB', value: 'Solânea'},
    {state: 'PB', value: 'Soledade'},
    {state: 'PB', value: 'Sossêgo'},
    {state: 'PB', value: 'Sousa'},
    {state: 'PB', value: 'Sumé'},
    {state: 'PB', value: 'Tacima (ex-Campo de Santana)'},
    {state: 'PB', value: 'Taperoá'},
    {state: 'PB', value: 'Tavares'},
    {state: 'PB', value: 'Teixeira'},
    {state: 'PB', value: 'Tenório'},
    {state: 'PB', value: 'Triunfo'},
    {state: 'PB', value: 'Uiraúna'},
    {state: 'PB', value: 'Umbuzeiro'},
    {state: 'PB', value: 'Várzea'},
    {state: 'PB', value: 'Vieirópolis'},
    {state: 'PB', value: 'Vista Serrana'},
    {state: 'PB', value: 'Zabelê'},
    {state: 'RN', value: 'Acari'},
    {state: 'RN', value: 'Afonso Bezerra'},
    {state: 'RN', value: 'Água Nova'},
    {state: 'RN', value: 'Alexandria'},
    {state: 'RN', value: 'Almino Afonso'},
    {state: 'RN', value: 'Alto do Rodrigues'},
    {state: 'RN', value: 'Angicos'},
    {state: 'RN', value: 'Antônio Martins'},
    {state: 'RN', value: 'Apodi'},
    {state: 'RN', value: 'Areia Branca'},
    {state: 'RN', value: 'Arez'},
    {state: 'RN', value: 'Assu'},
    {state: 'RN', value: 'Baía Formosa'},
    {state: 'RN', value: 'Baraúna'},
    {state: 'RN', value: 'Barcelona'},
    {state: 'RN', value: 'Bento Fernandes'},
    {state: 'RN', value: 'Boa Saúde'},
    {state: 'RN', value: 'Bodó'},
    {state: 'RN', value: 'Bom Jesus'},
    {state: 'RN', value: 'Brejinho'},
    {state: 'RN', value: 'Caiçara do Norte'},
    {state: 'RN', value: 'Caiçara do Rio do Vento'},
    {state: 'RN', value: 'Caicó'},
    {state: 'RN', value: 'Campo Grande'},
    {state: 'RN', value: 'Campo Redondo'},
    {state: 'RN', value: 'Canguaretama'},
    {state: 'RN', value: 'Caraúbas'},
    {state: 'RN', value: 'Carnaúba dos Dantas'},
    {state: 'RN', value: 'Carnaubais'},
    {state: 'RN', value: 'Ceará-Mirim'},
    {state: 'RN', value: 'Cerro Corá'},
    {state: 'RN', value: 'Coronel Ezequiel'},
    {state: 'RN', value: 'Coronel João Pessoa'},
    {state: 'RN', value: 'Cruzeta'},
    {state: 'RN', value: 'Currais Novos'},
    {state: 'RN', value: 'Doutor Severiano'},
    {state: 'RN', value: 'Encanto'},
    {state: 'RN', value: 'Equador'},
    {state: 'RN', value: 'Espírito Santo'},
    {state: 'RN', value: 'Extremoz'},
    {state: 'RN', value: 'Felipe Guerra'},
    {state: 'RN', value: 'Fernando Pedroza'},
    {state: 'RN', value: 'Florânia'},
    {state: 'RN', value: 'Francisco Dantas'},
    {state: 'RN', value: 'Frutuoso Gomes'},
    {state: 'RN', value: 'Galinhos'},
    {state: 'RN', value: 'Goianinha'},
    {state: 'RN', value: 'Governador Dix-Sept Rosado'},
    {state: 'RN', value: 'Grossos'},
    {state: 'RN', value: 'Guamaré'},
    {state: 'RN', value: 'Ielmo Marinho'},
    {state: 'RN', value: 'Ipanguaçu'},
    {state: 'RN', value: 'Ipueira'},
    {state: 'RN', value: 'Itajá'},
    {state: 'RN', value: 'Itaú'},
    {state: 'RN', value: 'Jaçanã'},
    {state: 'RN', value: 'Jandaíra'},
    {state: 'RN', value: 'Janduís'},
    {state: 'RN', value: 'Japi'},
    {state: 'RN', value: 'Jardim de Angicos'},
    {state: 'RN', value: 'Jardim de Piranhas'},
    {state: 'RN', value: 'Jardim do Seridó'},
    {state: 'RN', value: 'João Câmara'},
    {state: 'RN', value: 'João Dias'},
    {state: 'RN', value: 'José da Penha'},
    {state: 'RN', value: 'Jucurutu'},
    {state: 'RN', value: 'Jundiá'},
    {state: 'RN', value: "Lagoa d'Anta"},
    {state: 'RN', value: 'Lagoa de Pedras'},
    {state: 'RN', value: 'Lagoa de Velhos'},
    {state: 'RN', value: 'Lagoa Nova'},
    {state: 'RN', value: 'Lagoa Salgada'},
    {state: 'RN', value: 'Lajes'},
    {state: 'RN', value: 'Lajes Pintadas'},
    {state: 'RN', value: 'Lucrécia'},
    {state: 'RN', value: 'Luís Gomes'},
    {state: 'RN', value: 'Macaíba'},
    {state: 'RN', value: 'Macau'},
    {state: 'RN', value: 'Major Sales'},
    {state: 'RN', value: 'Marcelino Vieira'},
    {state: 'RN', value: 'Martins'},
    {state: 'RN', value: 'Maxaranguape'},
    {state: 'RN', value: 'Messias Targino'},
    {state: 'RN', value: 'Montanhas'},
    {state: 'RN', value: 'Monte Alegre'},
    {state: 'RN', value: 'Monte das Gameleiras'},
    {state: 'RN', value: 'Mossoró'},
    {state: 'RN', value: 'Natal'},
    {state: 'RN', value: 'Nísia Floresta'},
    {state: 'RN', value: 'Nova Cruz'},
    {state: 'RN', value: "Olho-d'Água do Borges"},
    {state: 'RN', value: 'Ouro Branco'},
    {state: 'RN', value: 'Paraná'},
    {state: 'RN', value: 'Paraú'},
    {state: 'RN', value: 'Parazinho'},
    {state: 'RN', value: 'Parelhas'},
    {state: 'RN', value: 'Parnamirim'},
    {state: 'RN', value: 'Passa-e-Fica'},
    {state: 'RN', value: 'Passagem'},
    {state: 'RN', value: 'Patu'},
    {state: 'RN', value: 'Pau dos Ferros'},
    {state: 'RN', value: 'Pedra Grande'},
    {state: 'RN', value: 'Pedra Preta'},
    {state: 'RN', value: 'Pedro Avelino'},
    {state: 'RN', value: 'Pedro Velho'},
    {state: 'RN', value: 'Pendências'},
    {state: 'RN', value: 'Pilões'},
    {state: 'RN', value: 'Poço Branco'},
    {state: 'RN', value: 'Portalegre'},
    {state: 'RN', value: 'Porto do Mangue'},
    {state: 'RN', value: 'Pureza'},
    {state: 'RN', value: 'Rafael Fernandes'},
    {state: 'RN', value: 'Rafael Godeiro'},
    {state: 'RN', value: 'Riacho da Cruz'},
    {state: 'RN', value: 'Riacho de Santana'},
    {state: 'RN', value: 'Riachuelo'},
    {state: 'RN', value: 'Rio do Fogo'},
    {state: 'RN', value: 'Rodolfo Fernandes'},
    {state: 'RN', value: 'Ruy Barbosa'},
    {state: 'RN', value: 'Santa Cruz'},
    {state: 'RN', value: 'Santa Maria'},
    {state: 'RN', value: 'Santana do Matos'},
    {state: 'RN', value: 'Santana do Seridó'},
    {state: 'RN', value: 'Santo Antônio'},
    {state: 'RN', value: 'São Bento do Norte'},
    {state: 'RN', value: 'São Bento do Trairi'},
    {state: 'RN', value: 'São Fernando'},
    {state: 'RN', value: 'São Francisco do Oeste'},
    {state: 'RN', value: 'São Gonçalo do Amarante'},
    {state: 'RN', value: 'São João do Sabugi'},
    {state: 'RN', value: 'São José de Mipibu'},
    {state: 'RN', value: 'São José do Campestre'},
    {state: 'RN', value: 'São José do Seridó'},
    {state: 'RN', value: 'São Miguel'},
    {state: 'RN', value: 'São Miguel do Gostoso'},
    {state: 'RN', value: 'São Paulo do Potengi'},
    {state: 'RN', value: 'São Pedro'},
    {state: 'RN', value: 'São Rafael'},
    {state: 'RN', value: 'São Tomé'},
    {state: 'RN', value: 'São Vicente'},
    {state: 'RN', value: 'Senador Elói de Souza'},
    {state: 'RN', value: 'Senador Georgino Avelino'},
    {state: 'RN', value: 'Serra Caiada'},
    {state: 'RN', value: 'Serra de São Bento'},
    {state: 'RN', value: 'Serra do Mel'},
    {state: 'RN', value: 'Serra Negra do Norte'},
    {state: 'RN', value: 'Serrinha'},
    {state: 'RN', value: 'Serrinha dos Pintos'},
    {state: 'RN', value: 'Severiano Melo'},
    {state: 'RN', value: 'Sítio Novo'},
    {state: 'RN', value: 'Taboleiro Grande'},
    {state: 'RN', value: 'Taipu'},
    {state: 'RN', value: 'Tangará'},
    {state: 'RN', value: 'Tenente Ananias'},
    {state: 'RN', value: 'Tenente Laurentino Cruz'},
    {state: 'RN', value: 'Tibau'},
    {state: 'RN', value: 'Tibau do Sul'},
    {state: 'RN', value: 'Timbaúba dos Batistas'},
    {state: 'RN', value: 'Touros'},
    {state: 'RN', value: 'Triunfo Potiguar'},
    {state: 'RN', value: 'Umarizal'},
    {state: 'RN', value: 'Upanema'},
    {state: 'RN', value: 'Várzea'},
    {state: 'RN', value: 'Venha-Ver'},
    {state: 'RN', value: 'Vera Cruz'},
    {state: 'RN', value: 'Viçosa'},
    {state: 'RN', value: 'Vila Flor'},
    {state: 'CE', value: 'Abaiara'},
    {state: 'CE', value: 'Acarape'},
    {state: 'CE', value: 'Acaraú'},
    {state: 'CE', value: 'Acopiara'},
    {state: 'CE', value: 'Aiuaba'},
    {state: 'CE', value: 'Alcântaras'},
    {state: 'CE', value: 'Altaneira'},
    {state: 'CE', value: 'Alto Santo'},
    {state: 'CE', value: 'Amontada'},
    {state: 'CE', value: 'Antonina do Norte'},
    {state: 'CE', value: 'Apuiarés'},
    {state: 'CE', value: 'Aquiraz'},
    {state: 'CE', value: 'Aracati'},
    {state: 'CE', value: 'Aracoiaba'},
    {state: 'CE', value: 'Ararendá'},
    {state: 'CE', value: 'Araripe'},
    {state: 'CE', value: 'Aratuba'},
    {state: 'CE', value: 'Arneiroz'},
    {state: 'CE', value: 'Assaré'},
    {state: 'CE', value: 'Aurora'},
    {state: 'CE', value: 'Baixio'},
    {state: 'CE', value: 'Banabuiú'},
    {state: 'CE', value: 'Barbalha'},
    {state: 'CE', value: 'Barreira'},
    {state: 'CE', value: 'Barro'},
    {state: 'CE', value: 'Barroquinha'},
    {state: 'CE', value: 'Baturité'},
    {state: 'CE', value: 'Beberibe'},
    {state: 'CE', value: 'Bela Cruz'},
    {state: 'CE', value: 'Boa Viagem'},
    {state: 'CE', value: 'Brejo Santo'},
    {state: 'CE', value: 'Camocim'},
    {state: 'CE', value: 'Campos Sales'},
    {state: 'CE', value: 'Canindé'},
    {state: 'CE', value: 'Capistrano'},
    {state: 'CE', value: 'Caridade'},
    {state: 'CE', value: 'Cariré'},
    {state: 'CE', value: 'Caririaçu'},
    {state: 'CE', value: 'Cariús'},
    {state: 'CE', value: 'Carnaubal'},
    {state: 'CE', value: 'Cascavel'},
    {state: 'CE', value: 'Catarina'},
    {state: 'CE', value: 'Catunda'},
    {state: 'CE', value: 'Caucaia'},
    {state: 'CE', value: 'Cedro'},
    {state: 'CE', value: 'Chaval'},
    {state: 'CE', value: 'Choró'},
    {state: 'CE', value: 'Chorozinho'},
    {state: 'CE', value: 'Coreaú'},
    {state: 'CE', value: 'Crateús'},
    {state: 'CE', value: 'Crato'},
    {state: 'CE', value: 'Croatá'},
    {state: 'CE', value: 'Cruz'},
    {state: 'CE', value: 'Deputado Irapuan Pinheiro'},
    {state: 'CE', value: 'Ereré'},
    {state: 'CE', value: 'Eusébio'},
    {state: 'CE', value: 'Farias Brito'},
    {state: 'CE', value: 'Forquilha'},
    {state: 'CE', value: 'Fortaleza'},
    {state: 'CE', value: 'Fortim'},
    {state: 'CE', value: 'Frecheirinha'},
    {state: 'CE', value: 'General Sampaio'},
    {state: 'CE', value: 'Graça'},
    {state: 'CE', value: 'Granja'},
    {state: 'CE', value: 'Granjeiro'},
    {state: 'CE', value: 'Groaíras'},
    {state: 'CE', value: 'Guaiúba'},
    {state: 'CE', value: 'Guaraciaba do Norte'},
    {state: 'CE', value: 'Guaramiranga'},
    {state: 'CE', value: 'Hidrolândia'},
    {state: 'CE', value: 'Horizonte'},
    {state: 'CE', value: 'Ibaretama'},
    {state: 'CE', value: 'Ibiapina'},
    {state: 'CE', value: 'Ibicuitinga'},
    {state: 'CE', value: 'Icapuí'},
    {state: 'CE', value: 'Icó'},
    {state: 'CE', value: 'Iguatu'},
    {state: 'CE', value: 'Independência'},
    {state: 'CE', value: 'Ipaporanga'},
    {state: 'CE', value: 'Ipaumirim'},
    {state: 'CE', value: 'Ipu'},
    {state: 'CE', value: 'Ipueiras'},
    {state: 'CE', value: 'Iracema'},
    {state: 'CE', value: 'Irauçuba'},
    {state: 'CE', value: 'Itaiçaba'},
    {state: 'CE', value: 'Itaitinga'},
    {state: 'CE', value: 'Itapajé'},
    {state: 'CE', value: 'Itapipoca'},
    {state: 'CE', value: 'Itapiúna'},
    {state: 'CE', value: 'Itarema'},
    {state: 'CE', value: 'Itatira'},
    {state: 'CE', value: 'Jaguaretama'},
    {state: 'CE', value: 'Jaguaribara'},
    {state: 'CE', value: 'Jaguaribe'},
    {state: 'CE', value: 'Jaguaruana'},
    {state: 'CE', value: 'Jardim'},
    {state: 'CE', value: 'Jati'},
    {state: 'CE', value: 'Jijoca de Jericoacoara'},
    {state: 'CE', value: 'Juazeiro do Norte'},
    {state: 'CE', value: 'Jucás'},
    {state: 'CE', value: 'Lavras da Mangabeira'},
    {state: 'CE', value: 'Limoeiro do Norte'},
    {state: 'CE', value: 'Madalena'},
    {state: 'CE', value: 'Maracanaú'},
    {state: 'CE', value: 'Maranguape'},
    {state: 'CE', value: 'Marco'},
    {state: 'CE', value: 'Martinópole'},
    {state: 'CE', value: 'Massapê'},
    {state: 'CE', value: 'Mauriti'},
    {state: 'CE', value: 'Meruoca'},
    {state: 'CE', value: 'Milagres'},
    {state: 'CE', value: 'Milhã'},
    {state: 'CE', value: 'Miraíma'},
    {state: 'CE', value: 'Missão Velha'},
    {state: 'CE', value: 'Mombaça'},
    {state: 'CE', value: 'Monsenhor Tabosa'},
    {state: 'CE', value: 'Morada Nova'},
    {state: 'CE', value: 'Moraújo'},
    {state: 'CE', value: 'Morrinhos'},
    {state: 'CE', value: 'Mucambo'},
    {state: 'CE', value: 'Mulungu'},
    {state: 'CE', value: 'Nova Olinda'},
    {state: 'CE', value: 'Nova Russas'},
    {state: 'CE', value: 'Novo Oriente'},
    {state: 'CE', value: 'Ocara'},
    {state: 'CE', value: 'Orós'},
    {state: 'CE', value: 'Pacajus'},
    {state: 'CE', value: 'Pacatuba'},
    {state: 'CE', value: 'Pacoti'},
    {state: 'CE', value: 'Pacujá'},
    {state: 'CE', value: 'Palhano'},
    {state: 'CE', value: 'Palmácia'},
    {state: 'CE', value: 'Paracuru'},
    {state: 'CE', value: 'Paraipaba'},
    {state: 'CE', value: 'Parambu'},
    {state: 'CE', value: 'Paramoti'},
    {state: 'CE', value: 'Pedra Branca'},
    {state: 'CE', value: 'Penaforte'},
    {state: 'CE', value: 'Pentecoste'},
    {state: 'CE', value: 'Pereiro'},
    {state: 'CE', value: 'Pindoretama'},
    {state: 'CE', value: 'Piquet Carneiro'},
    {state: 'CE', value: 'Pires Ferreira'},
    {state: 'CE', value: 'Poranga'},
    {state: 'CE', value: 'Porteiras'},
    {state: 'CE', value: 'Potengi'},
    {state: 'CE', value: 'Potiretama'},
    {state: 'CE', value: 'Quiterianópolis'},
    {state: 'CE', value: 'Quixadá'},
    {state: 'CE', value: 'Quixelô'},
    {state: 'CE', value: 'Quixeramobim'},
    {state: 'CE', value: 'Quixeré'},
    {state: 'CE', value: 'Redenção'},
    {state: 'CE', value: 'Reriutaba'},
    {state: 'CE', value: 'Russas'},
    {state: 'CE', value: 'Saboeiro'},
    {state: 'CE', value: 'Salitre'},
    {state: 'CE', value: 'Santa Quitéria'},
    {state: 'CE', value: 'Santana do Acaraú'},
    {state: 'CE', value: 'Santana do Cariri'},
    {state: 'CE', value: 'São Benedito'},
    {state: 'CE', value: 'São Gonçalo do Amarante'},
    {state: 'CE', value: 'São João do Jaguaribe'},
    {state: 'CE', value: 'São Luís do Curu'},
    {state: 'CE', value: 'Senador Pompeu'},
    {state: 'CE', value: 'Senador Sá'},
    {state: 'CE', value: 'Sobral'},
    {state: 'CE', value: 'Solonópole'},
    {state: 'CE', value: 'Tabuleiro do Norte'},
    {state: 'CE', value: 'Tamboril'},
    {state: 'CE', value: 'Tarrafas'},
    {state: 'CE', value: 'Tauá'},
    {state: 'CE', value: 'Tejuçuoca'},
    {state: 'CE', value: 'Tianguá'},
    {state: 'CE', value: 'Trairi'},
    {state: 'CE', value: 'Tururu'},
    {state: 'CE', value: 'Ubajara'},
    {state: 'CE', value: 'Umari'},
    {state: 'CE', value: 'Umirim'},
    {state: 'CE', value: 'Uruburetama'},
    {state: 'CE', value: 'Uruoca'},
    {state: 'CE', value: 'Varjota'},
    {state: 'CE', value: 'Várzea Alegre'},
    {state: 'CE', value: 'Viçosa do Ceará'},
    {state: 'PI', value: 'Acauã'},
    {state: 'PI', value: 'Agricolândia'},
    {state: 'PI', value: 'Água Branca'},
    {state: 'PI', value: 'Alagoinha do Piauí'},
    {state: 'PI', value: 'Alegrete do Piauí'},
    {state: 'PI', value: 'Alto Longá'},
    {state: 'PI', value: 'Altos'},
    {state: 'PI', value: 'Alvorada do Gurgueia'},
    {state: 'PI', value: 'Amarante'},
    {state: 'PI', value: 'Angical do Piauí'},
    {state: 'PI', value: 'Anísio de Abreu'},
    {state: 'PI', value: 'Antônio Almeida'},
    {state: 'PI', value: 'Aroazes'},
    {state: 'PI', value: 'Aroeiras do Itaim'},
    {state: 'PI', value: 'Arraial'},
    {state: 'PI', value: 'Assunção do Piauí'},
    {state: 'PI', value: 'Avelino Lopes'},
    {state: 'PI', value: 'Baixa Grande do Ribeiro'},
    {state: 'PI', value: "Barra d'Alcântara"},
    {state: 'PI', value: 'Barras'},
    {state: 'PI', value: 'Barreiras do Piauí'},
    {state: 'PI', value: 'Barro Duro'},
    {state: 'PI', value: 'Batalha'},
    {state: 'PI', value: 'Bela Vista do Piauí'},
    {state: 'PI', value: 'Belém do Piauí'},
    {state: 'PI', value: 'Beneditinos'},
    {state: 'PI', value: 'Bertolínia'},
    {state: 'PI', value: 'Betânia do Piauí'},
    {state: 'PI', value: 'Boa Hora'},
    {state: 'PI', value: 'Bocaina'},
    {state: 'PI', value: 'Bom Jesus'},
    {state: 'PI', value: 'Bom Princípio do Piauí'},
    {state: 'PI', value: 'Bonfim do Piauí'},
    {state: 'PI', value: 'Boqueirão do Piauí'},
    {state: 'PI', value: 'Brasileira'},
    {state: 'PI', value: 'Brejo do Piauí'},
    {state: 'PI', value: 'Buriti dos Lopes'},
    {state: 'PI', value: 'Buriti dos Montes'},
    {state: 'PI', value: 'Cabeceiras do Piauí'},
    {state: 'PI', value: 'Cajazeiras do Piauí'},
    {state: 'PI', value: 'Cajueiro da Praia'},
    {state: 'PI', value: 'Caldeirão Grande do Piauí'},
    {state: 'PI', value: 'Campinas do Piauí'},
    {state: 'PI', value: 'Campo Alegre do Fidalgo'},
    {state: 'PI', value: 'Campo Grande do Piauí'},
    {state: 'PI', value: 'Campo Largo do Piauí'},
    {state: 'PI', value: 'Campo Maior'},
    {state: 'PI', value: 'Canavieira'},
    {state: 'PI', value: 'Canto do Buriti'},
    {state: 'PI', value: 'Capitão de Campos'},
    {state: 'PI', value: 'Capitão Gervásio Oliveira'},
    {state: 'PI', value: 'Caracol'},
    {state: 'PI', value: 'Caraúbas do Piauí'},
    {state: 'PI', value: 'Caridade do Piauí'},
    {state: 'PI', value: 'Castelo do Piauí'},
    {state: 'PI', value: 'Caxingó'},
    {state: 'PI', value: 'Cocal'},
    {state: 'PI', value: 'Cocal de Telha'},
    {state: 'PI', value: 'Cocal dos Alves'},
    {state: 'PI', value: 'Coivaras'},
    {state: 'PI', value: 'Colônia do Gurgueia'},
    {state: 'PI', value: 'Colônia do Piauí'},
    {state: 'PI', value: 'Conceição do Canindé'},
    {state: 'PI', value: 'Coronel José Dias'},
    {state: 'PI', value: 'Corrente'},
    {state: 'PI', value: 'Cristalândia do Piauí'},
    {state: 'PI', value: 'Cristino Castro'},
    {state: 'PI', value: 'Curimatá'},
    {state: 'PI', value: 'Currais'},
    {state: 'PI', value: 'Curral Novo do Piauí'},
    {state: 'PI', value: 'Curralinhos'},
    {state: 'PI', value: 'Demerval Lobão'},
    {state: 'PI', value: 'Dirceu Arcoverde'},
    {state: 'PI', value: 'Dom Expedito Lopes'},
    {state: 'PI', value: 'Dom Inocêncio'},
    {state: 'PI', value: 'Domingos Mourão'},
    {state: 'PI', value: 'Elesbão Veloso'},
    {state: 'PI', value: 'Eliseu Martins'},
    {state: 'PI', value: 'Esperantina'},
    {state: 'PI', value: 'Fartura do Piauí'},
    {state: 'PI', value: 'Flores do Piauí'},
    {state: 'PI', value: 'Floresta do Piauí'},
    {state: 'PI', value: 'Floriano'},
    {state: 'PI', value: 'Francinópolis'},
    {state: 'PI', value: 'Francisco Ayres'},
    {state: 'PI', value: 'Francisco Macedo'},
    {state: 'PI', value: 'Francisco Santos'},
    {state: 'PI', value: 'Fronteiras'},
    {state: 'PI', value: 'Geminiano'},
    {state: 'PI', value: 'Gilbués'},
    {state: 'PI', value: 'Guadalupe'},
    {state: 'PI', value: 'Guaribas'},
    {state: 'PI', value: 'Hugo Napoleão'},
    {state: 'PI', value: 'Ilha Grande'},
    {state: 'PI', value: 'Inhuma'},
    {state: 'PI', value: 'Ipiranga do Piauí'},
    {state: 'PI', value: 'Isaías Coelho'},
    {state: 'PI', value: 'Itainópolis'},
    {state: 'PI', value: 'Itaueira'},
    {state: 'PI', value: 'Jacobina do Piauí'},
    {state: 'PI', value: 'Jaicós'},
    {state: 'PI', value: 'Jardim do Mulato'},
    {state: 'PI', value: 'Jatobá do Piauí'},
    {state: 'PI', value: 'Jerumenha'},
    {state: 'PI', value: 'João Costa'},
    {state: 'PI', value: 'Joaquim Pires'},
    {state: 'PI', value: 'Joca Marques'},
    {state: 'PI', value: 'José de Freitas'},
    {state: 'PI', value: 'Juazeiro do Piauí'},
    {state: 'PI', value: 'Júlio Borges'},
    {state: 'PI', value: 'Jurema'},
    {state: 'PI', value: 'Lagoa Alegre'},
    {state: 'PI', value: 'Lagoa de São Francisco'},
    {state: 'PI', value: 'Lagoa do Barro do Piauí'},
    {state: 'PI', value: 'Lagoa do Piauí'},
    {state: 'PI', value: 'Lagoa do Sítio'},
    {state: 'PI', value: 'Lagoinha do Piauí'},
    {state: 'PI', value: 'Landri Sales'},
    {state: 'PI', value: 'Luís Correia'},
    {state: 'PI', value: 'Luzilândia'},
    {state: 'PI', value: 'Madeiro'},
    {state: 'PI', value: 'Manoel Emídio'},
    {state: 'PI', value: 'Marcolândia'},
    {state: 'PI', value: 'Marcos Parente'},
    {state: 'PI', value: 'Massapê do Piauí'},
    {state: 'PI', value: 'Matias Olímpio'},
    {state: 'PI', value: 'Miguel Alves'},
    {state: 'PI', value: 'Miguel Leão'},
    {state: 'PI', value: 'Milton Brandão'},
    {state: 'PI', value: 'Monsenhor Gil'},
    {state: 'PI', value: 'Monsenhor Hipólito'},
    {state: 'PI', value: 'Monte Alegre do Piauí'},
    {state: 'PI', value: 'Morro Cabeça no Tempo'},
    {state: 'PI', value: 'Morro do Chapéu do Piauí'},
    {state: 'PI', value: 'Murici dos Portelas'},
    {state: 'PI', value: 'Nazaré do Piauí'},
    {state: 'PI', value: 'Nazária'},
    {state: 'PI', value: 'Nossa Senhora de Nazaré'},
    {state: 'PI', value: 'Nossa Senhora dos Remédios'},
    {state: 'PI', value: 'Nova Santa Rita'},
    {state: 'PI', value: 'Novo Oriente do Piauí'},
    {state: 'PI', value: 'Novo Santo Antônio'},
    {state: 'PI', value: 'Oeiras'},
    {state: 'PI', value: "Olho d'Água do Piauí"},
    {state: 'PI', value: 'Padre Marcos'},
    {state: 'PI', value: 'Paes Landim'},
    {state: 'PI', value: 'Pajeú do Piauí'},
    {state: 'PI', value: 'Palmeira do Piauí'},
    {state: 'PI', value: 'Palmeirais'},
    {state: 'PI', value: 'Paquetá'},
    {state: 'PI', value: 'Parnaguá'},
    {state: 'PI', value: 'Parnaíba'},
    {state: 'PI', value: 'Passagem Franca do Piauí'},
    {state: 'PI', value: 'Patos do Piauí'},
    {state: 'PI', value: "Pau d'Arco do Piauí"},
    {state: 'PI', value: 'Paulistana'},
    {state: 'PI', value: 'Pavussu'},
    {state: 'PI', value: 'Pedro II'},
    {state: 'PI', value: 'Pedro Laurentino'},
    {state: 'PI', value: 'Picos'},
    {state: 'PI', value: 'Pimenteiras'},
    {state: 'PI', value: 'Pio IX'},
    {state: 'PI', value: 'Piracuruca'},
    {state: 'PI', value: 'Piripiri'},
    {state: 'PI', value: 'Porto'},
    {state: 'PI', value: 'Porto Alegre do Piauí'},
    {state: 'PI', value: 'Prata do Piauí'},
    {state: 'PI', value: 'Queimada Nova'},
    {state: 'PI', value: 'Redenção do Gurgueia'},
    {state: 'PI', value: 'Regeneração'},
    {state: 'PI', value: 'Riacho Frio'},
    {state: 'PI', value: 'Ribeira do Piauí'},
    {state: 'PI', value: 'Ribeiro Gonçalves'},
    {state: 'PI', value: 'Rio Grande do Piauí'},
    {state: 'PI', value: 'Santa Cruz do Piauí'},
    {state: 'PI', value: 'Santa Cruz dos Milagres'},
    {state: 'PI', value: 'Santa Filomena'},
    {state: 'PI', value: 'Santa Luz'},
    {state: 'PI', value: 'Santa Rosa do Piauí'},
    {state: 'PI', value: 'Santana do Piauí'},
    {state: 'PI', value: 'Santo Antônio de Lisboa'},
    {state: 'PI', value: 'Santo Antônio dos Milagres'},
    {state: 'PI', value: 'Santo Inácio do Piauí'},
    {state: 'PI', value: 'São Braz do Piauí'},
    {state: 'PI', value: 'São Félix do Piauí'},
    {state: 'PI', value: 'São Francisco de Assis do Piauí'},
    {state: 'PI', value: 'São Francisco do Piauí'},
    {state: 'PI', value: 'São Gonçalo do Gurgueia'},
    {state: 'PI', value: 'São Gonçalo do Piauí'},
    {state: 'PI', value: 'São João da Canabrava'},
    {state: 'PI', value: 'São João da Fronteira'},
    {state: 'PI', value: 'São João da Serra'},
    {state: 'PI', value: 'São João da Varjota'},
    {state: 'PI', value: 'São João do Arraial'},
    {state: 'PI', value: 'São João do Piauí'},
    {state: 'PI', value: 'São José do Divino'},
    {state: 'PI', value: 'São José do Peixe'},
    {state: 'PI', value: 'São José do Piauí'},
    {state: 'PI', value: 'São Julião'},
    {state: 'PI', value: 'São Lourenço do Piauí'},
    {state: 'PI', value: 'São Luís do Piauí'},
    {state: 'PI', value: 'São Miguel da Baixa Grande'},
    {state: 'PI', value: 'São Miguel do Fidalgo'},
    {state: 'PI', value: 'São Miguel do Tapuio'},
    {state: 'PI', value: 'São Pedro do Piauí'},
    {state: 'PI', value: 'São Raimundo Nonato'},
    {state: 'PI', value: 'Sebastião Barros'},
    {state: 'PI', value: 'Sebastião Leal'},
    {state: 'PI', value: 'Sigefredo Pacheco'},
    {state: 'PI', value: 'Simões'},
    {state: 'PI', value: 'Simplício Mendes'},
    {state: 'PI', value: 'Socorro do Piauí'},
    {state: 'PI', value: 'Sussuapara'},
    {state: 'PI', value: 'Tamboril do Piauí'},
    {state: 'PI', value: 'Tanque do Piauí'},
    {state: 'PI', value: 'Teresina'},
    {state: 'PI', value: 'União'},
    {state: 'PI', value: 'Uruçuí'},
    {state: 'PI', value: 'Valença do Piauí'},
    {state: 'PI', value: 'Várzea Branca'},
    {state: 'PI', value: 'Várzea Grande'},
    {state: 'PI', value: 'Vera Mendes'},
    {state: 'PI', value: 'Vila Nova do Piauí'},
    {state: 'PI', value: 'Wall Ferraz'},
    {state: 'MA', value: 'Agricolândia'},
    {state: 'MA', value: 'Água Branca'},
    {state: 'MA', value: 'Alagoinha do Piauí'},
    {state: 'MA', value: 'Alegrete do Piauí'},
    {state: 'MA', value: 'Alto Longá'},
    {state: 'MA', value: 'Altos'},
    {state: 'MA', value: 'Alvorada do Gurgueia'},
    {state: 'MA', value: 'Amarante'},
    {state: 'MA', value: 'Angical do Piauí'},
    {state: 'MA', value: 'Anísio de Abreu'},
    {state: 'MA', value: 'Antônio Almeida'},
    {state: 'MA', value: 'Aroazes'},
    {state: 'MA', value: 'Aroeiras do Itaim'},
    {state: 'MA', value: 'Arraial'},
    {state: 'MA', value: 'Assunção do Piauí'},
    {state: 'MA', value: 'Avelino Lopes'},
    {state: 'MA', value: 'Baixa Grande do Ribeiro'},
    {state: 'MA', value: "Barra d'Alcântara"},
    {state: 'MA', value: 'Barras'},
    {state: 'MA', value: 'Barreiras do Piauí'},
    {state: 'MA', value: 'Barro Duro'},
    {state: 'MA', value: 'Batalha'},
    {state: 'MA', value: 'Bela Vista do Piauí'},
    {state: 'MA', value: 'Belém do Piauí'},
    {state: 'MA', value: 'Beneditinos'},
    {state: 'MA', value: 'Bertolínia'},
    {state: 'MA', value: 'Betânia do Piauí'},
    {state: 'MA', value: 'Boa Hora'},
    {state: 'MA', value: 'Bocaina'},
    {state: 'MA', value: 'Bom Jesus'},
    {state: 'MA', value: 'Bom Princípio do Piauí'},
    {state: 'MA', value: 'Bonfim do Piauí'},
    {state: 'MA', value: 'Boqueirão do Piauí'},
    {state: 'MA', value: 'Brasileira'},
    {state: 'MA', value: 'Brejo do Piauí'},
    {state: 'MA', value: 'Buriti dos Lopes'},
    {state: 'MA', value: 'Buriti dos Montes'},
    {state: 'MA', value: 'Cabeceiras do Piauí'},
    {state: 'MA', value: 'Cajazeiras do Piauí'},
    {state: 'MA', value: 'Cajueiro da Praia'},
    {state: 'MA', value: 'Caldeirão Grande do Piauí'},
    {state: 'MA', value: 'Campinas do Piauí'},
    {state: 'MA', value: 'Campo Alegre do Fidalgo'},
    {state: 'MA', value: 'Campo Grande do Piauí'},
    {state: 'MA', value: 'Campo Largo do Piauí'},
    {state: 'MA', value: 'Campo Maior'},
    {state: 'MA', value: 'Canavieira'},
    {state: 'MA', value: 'Canto do Buriti'},
    {state: 'MA', value: 'Capitão de Campos'},
    {state: 'MA', value: 'Capitão Gervásio Oliveira'},
    {state: 'MA', value: 'Caracol'},
    {state: 'MA', value: 'Caraúbas do Piauí'},
    {state: 'MA', value: 'Caridade do Piauí'},
    {state: 'MA', value: 'Castelo do Piauí'},
    {state: 'MA', value: 'Caxingó'},
    {state: 'MA', value: 'Cocal'},
    {state: 'MA', value: 'Cocal de Telha'},
    {state: 'MA', value: 'Cocal dos Alves'},
    {state: 'MA', value: 'Coivaras'},
    {state: 'MA', value: 'Colônia do Gurgueia'},
    {state: 'MA', value: 'Colônia do Piauí'},
    {state: 'MA', value: 'Conceição do Canindé'},
    {state: 'MA', value: 'Coronel José Dias'},
    {state: 'MA', value: 'Corrente'},
    {state: 'MA', value: 'Cristalândia do Piauí'},
    {state: 'MA', value: 'Cristino Castro'},
    {state: 'MA', value: 'Curimatá'},
    {state: 'MA', value: 'Currais'},
    {state: 'MA', value: 'Curral Novo do Piauí'},
    {state: 'MA', value: 'Curralinhos'},
    {state: 'MA', value: 'Demerval Lobão'},
    {state: 'MA', value: 'Dirceu Arcoverde'},
    {state: 'MA', value: 'Dom Expedito Lopes'},
    {state: 'MA', value: 'Dom Inocêncio'},
    {state: 'MA', value: 'Domingos Mourão'},
    {state: 'MA', value: 'Elesbão Veloso'},
    {state: 'MA', value: 'Eliseu Martins'},
    {state: 'MA', value: 'Esperantina'},
    {state: 'MA', value: 'Fartura do Piauí'},
    {state: 'MA', value: 'Flores do Piauí'},
    {state: 'MA', value: 'Floresta do Piauí'},
    {state: 'MA', value: 'Floriano'},
    {state: 'MA', value: 'Francinópolis'},
    {state: 'MA', value: 'Francisco Ayres'},
    {state: 'MA', value: 'Francisco Macedo'},
    {state: 'MA', value: 'Francisco Santos'},
    {state: 'MA', value: 'Fronteiras'},
    {state: 'MA', value: 'Geminiano'},
    {state: 'MA', value: 'Gilbués'},
    {state: 'MA', value: 'Guadalupe'},
    {state: 'MA', value: 'Guaribas'},
    {state: 'MA', value: 'Hugo Napoleão'},
    {state: 'MA', value: 'Ilha Grande'},
    {state: 'MA', value: 'Inhuma'},
    {state: 'MA', value: 'Ipiranga do Piauí'},
    {state: 'MA', value: 'Isaías Coelho'},
    {state: 'MA', value: 'Itainópolis'},
    {state: 'MA', value: 'Itaueira'},
    {state: 'MA', value: 'Jacobina do Piauí'},
    {state: 'MA', value: 'Jaicós'},
    {state: 'MA', value: 'Jardim do Mulato'},
    {state: 'MA', value: 'Jatobá do Piauí'},
    {state: 'MA', value: 'Jerumenha'},
    {state: 'MA', value: 'João Costa'},
    {state: 'MA', value: 'Joaquim Pires'},
    {state: 'MA', value: 'Joca Marques'},
    {state: 'MA', value: 'José de Freitas'},
    {state: 'MA', value: 'Juazeiro do Piauí'},
    {state: 'MA', value: 'Júlio Borges'},
    {state: 'MA', value: 'Jurema'},
    {state: 'MA', value: 'Lagoa Alegre'},
    {state: 'MA', value: 'Lagoa de São Francisco'},
    {state: 'MA', value: 'Lagoa do Barro do Piauí'},
    {state: 'MA', value: 'Lagoa do Piauí'},
    {state: 'MA', value: 'Lagoa do Sítio'},
    {state: 'MA', value: 'Lagoinha do Piauí'},
    {state: 'MA', value: 'Landri Sales'},
    {state: 'MA', value: 'Luís Correia'},
    {state: 'MA', value: 'Luzilândia'},
    {state: 'MA', value: 'Madeiro'},
    {state: 'MA', value: 'Manoel Emídio'},
    {state: 'MA', value: 'Marcolândia'},
    {state: 'MA', value: 'Marcos Parente'},
    {state: 'MA', value: 'Massapê do Piauí'},
    {state: 'MA', value: 'Matias Olímpio'},
    {state: 'MA', value: 'Miguel Alves'},
    {state: 'MA', value: 'Miguel Leão'},
    {state: 'MA', value: 'Milton Brandão'},
    {state: 'MA', value: 'Monsenhor Gil'},
    {state: 'MA', value: 'Monsenhor Hipólito'},
    {state: 'MA', value: 'Monte Alegre do Piauí'},
    {state: 'MA', value: 'Morro Cabeça no Tempo'},
    {state: 'MA', value: 'Morro do Chapéu do Piauí'},
    {state: 'MA', value: 'Murici dos Portelas'},
    {state: 'MA', value: 'Nazaré do Piauí'},
    {state: 'MA', value: 'Nazária'},
    {state: 'MA', value: 'Nossa Senhora de Nazaré'},
    {state: 'MA', value: 'Nossa Senhora dos Remédios'},
    {state: 'MA', value: 'Nova Santa Rita'},
    {state: 'MA', value: 'Novo Oriente do Piauí'},
    {state: 'MA', value: 'Novo Santo Antônio'},
    {state: 'MA', value: 'Oeiras'},
    {state: 'MA', value: "Olho d'Água do Piauí"},
    {state: 'MA', value: 'Padre Marcos'},
    {state: 'MA', value: 'Paes Landim'},
    {state: 'MA', value: 'Pajeú do Piauí'},
    {state: 'MA', value: 'Palmeira do Piauí'},
    {state: 'MA', value: 'Palmeirais'},
    {state: 'MA', value: 'Paquetá'},
    {state: 'MA', value: 'Parnaguá'},
    {state: 'MA', value: 'Parnaíba'},
    {state: 'MA', value: 'Passagem Franca do Piauí'},
    {state: 'MA', value: 'Patos do Piauí'},
    {state: 'MA', value: "Pau d'Arco do Piauí"},
    {state: 'MA', value: 'Paulistana'},
    {state: 'MA', value: 'Pavussu'},
    {state: 'MA', value: 'Pedro II'},
    {state: 'MA', value: 'Pedro Laurentino'},
    {state: 'MA', value: 'Picos'},
    {state: 'MA', value: 'Pimenteiras'},
    {state: 'MA', value: 'Pio IX'},
    {state: 'MA', value: 'Piracuruca'},
    {state: 'MA', value: 'Piripiri'},
    {state: 'MA', value: 'Porto'},
    {state: 'MA', value: 'Porto Alegre do Piauí'},
    {state: 'MA', value: 'Prata do Piauí'},
    {state: 'MA', value: 'Queimada Nova'},
    {state: 'MA', value: 'Redenção do Gurgueia'},
    {state: 'MA', value: 'Regeneração'},
    {state: 'MA', value: 'Riacho Frio'},
    {state: 'MA', value: 'Ribeira do Piauí'},
    {state: 'MA', value: 'Ribeiro Gonçalves'},
    {state: 'MA', value: 'Rio Grande do Piauí'},
    {state: 'MA', value: 'Santa Cruz do Piauí'},
    {state: 'MA', value: 'Santa Cruz dos Milagres'},
    {state: 'MA', value: 'Santa Filomena'},
    {state: 'MA', value: 'Santa Luz'},
    {state: 'MA', value: 'Santa Rosa do Piauí'},
    {state: 'MA', value: 'Santana do Piauí'},
    {state: 'MA', value: 'Santo Antônio de Lisboa'},
    {state: 'MA', value: 'Santo Antônio dos Milagres'},
    {state: 'MA', value: 'Santo Inácio do Piauí'},
    {state: 'MA', value: 'São Braz do Piauí'},
    {state: 'MA', value: 'São Félix do Piauí'},
    {state: 'MA', value: 'São Francisco de Assis do Piauí'},
    {state: 'MA', value: 'São Francisco do Piauí'},
    {state: 'MA', value: 'São Gonçalo do Gurgueia'},
    {state: 'MA', value: 'São Gonçalo do Piauí'},
    {state: 'MA', value: 'São João da Canabrava'},
    {state: 'MA', value: 'São João da Fronteira'},
    {state: 'MA', value: 'São João da Serra'},
    {state: 'MA', value: 'São João da Varjota'},
    {state: 'MA', value: 'São João do Arraial'},
    {state: 'MA', value: 'São João do Piauí'},
    {state: 'MA', value: 'São José do Divino'},
    {state: 'MA', value: 'São José do Peixe'},
    {state: 'MA', value: 'São José do Piauí'},
    {state: 'MA', value: 'São Julião'},
    {state: 'MA', value: 'São Lourenço do Piauí'},
    {state: 'MA', value: 'São Luís do Piauí'},
    {state: 'MA', value: 'São Miguel da Baixa Grande'},
    {state: 'MA', value: 'São Miguel do Fidalgo'},
    {state: 'MA', value: 'São Miguel do Tapuio'},
    {state: 'MA', value: 'São Pedro do Piauí'},
    {state: 'MA', value: 'São Raimundo Nonato'},
    {state: 'MA', value: 'Sebastião Barros'},
    {state: 'MA', value: 'Sebastião Leal'},
    {state: 'MA', value: 'Sigefredo Pacheco'},
    {state: 'MA', value: 'Simões'},
    {state: 'MA', value: 'Simplício Mendes'},
    {state: 'MA', value: 'Socorro do Piauí'},
    {state: 'MA', value: 'Sussuapara'},
    {state: 'MA', value: 'Tamboril do Piauí'},
    {state: 'MA', value: 'Tanque do Piauí'},
    {state: 'MA', value: 'Teresina'},
    {state: 'MA', value: 'União'}
];

export default citiesNordeste;
