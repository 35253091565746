/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import React, {useState, useLayoutEffect, useEffect} from 'react';
import {Accordion, Card, Modal} from 'react-bootstrap';
import {useAccordionToggle} from 'react-bootstrap/AccordionToggle';
import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import moment from 'moment';
import WebFont from 'webfontloader';
import {cleanNumber} from '@app/utils/myaux';
import MobileDetecter from '@app/components/MobileDetecter';
import WalletChart from '@app/components/charts/WalletChart';
import CardWallet from '@app/components/CardWallet';
import CardWalletBlank from '@app/components/CardWalletBlank';
import api from '@app/utils/api';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Loading from '../../components/loading/Loading';
import FiltersWallet from '../../components/FiltersWallet';
import SelectWalletAtiv from '../../components/SelectWalletAtiv';
import WalletHeader from '../../components/WalletHeader';
import NewStrategy from './strateggi/NewStrategy';

const useStyles = makeStyles({
    dialog: {
        position: 'absolute',
        left: 10,
        top: 20
    }
});

const defaultFilters = {
    btnType: {call: true, put: true},
    btnModel: {europe: true, america: true},
    btnMoneyness: {ITM: false, ATM: false, OTM: false},
    strike: {min: 0, start: 0, end: 5000, max: 5000},
    strikeList: [],
    tikers: [],
    venc: []
};

export default function Wallet({setHeaderValues, refValue, walletValue}) {
    const [expand, setExpand] = useState(false);
    const [expandChart, setExpandChart] = useState(true);
    const [openModalAtv, setOpenModalAtv] = useState(false);
    const [openNewWallet, setOpenNewWallet] = useState(false);
    const [loading, setLoading] = useState(true);
    const [ativ, setAtiv] = useState('');
    const [data, setData] = useState({});
    const [filteredCards, setFilteredcards] = useState([]);
    const [isOpen, setIsOpen] = useState(true);
    const [cards, setCards] = useState([]);
    const [size, setSize] = useState(window.innerWidth);
    const [selic, setSelic] = useState();

    useEffect(() => {
        // api.options;
        /* .getSelic()
            .then((res) => {
                setSelic(0.1225);
                // console.log(Number(res) / 100);
            }) */
        setSelic(0.1225);
        // .catch((err) => console.log(err));
        WebFont.load({
            google: {
                families: ['Roboto', 'Chilanka']
            }
        });
        function handleResize() {
            console.log(window.innerWidth);
            setSize(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (ativ !== '') {
            setLoading(true);
            api.options
                .getCotacoesPorAtivo(ativ)
                .then((res) => {
                    const listVenc = [];
                    const listStrikes = [];
                    const listTikers = [];
                    const tmp = {
                        filters: defaultFilters,
                        values: []
                    };
                    res.data.retorno.dados.forEach((resp, i) => {
                        // strikeFilters.push(parseFloat(resp.strike));
                        if (i === 0) {
                            listTikers.push({
                                label: ativ,
                                value: ativ
                            });
                        }

                        let vi;
                        let ve;
                        if (resp.tipo === 'PUT') {
                            if (Number(resp.strike) < Number(resp.cotacao)) {
                                vi = 0;
                                ve = Number(resp.pteorico);
                            } else {
                                vi = Number(resp.strike) - Number(resp.cotacao);
                                ve = Number(resp.pteorico) - Number(vi);
                            }
                        } else if (Number(resp.strike) > Number(resp.cotacao)) {
                            vi = 0;
                            ve = Number(resp.pteorico);
                        } else {
                            vi = Number(resp.cotacao) - Number(resp.strike);
                            ve =
                                Number(resp.pteorico) +
                                Number(resp.strike) -
                                Number(resp.cotacao);
                        }

                        listVenc.push(resp.vencimentos);
                        listStrikes.push(Number(resp.strike));
                        listTikers.push({
                            label: resp.ticker,
                            value: resp.ticker
                        });
                        // if (
                        //     Number(resp.strike) >= tmp.filters.strike.start &&
                        //     Number(resp.strike) <= tmp.filters.strike.end
                        // )
                        // listMoneyness.push(resp.otm);
                        tmp.values.push({
                            ticker: resp.ticker.split('_')[0],
                            fm: resp.fm,
                            money: resp.otm,
                            mod: resp.mod === 'A' ? 'AMERICANA' : 'EUROPÉIA',
                            strike: resp.strike,
                            tipo: resp.tipo,
                            ultimo: resp.ultimo,
                            negocios: resp.num_neg,
                            vol: resp.vol_neg,
                            data: resp.data,
                            implicita: (Number(resp.vol_impl) * 100).toFixed(2),
                            pteorico: resp.pteorico,
                            pteoricoDesmont: resp.pteorico,
                            delta: resp.new_delta,
                            gamma: resp.new_gamma,
                            theta: resp.new_theta,
                            vega: resp.new_vega,
                            venc: resp.vencimentos,
                            cotacao: resp.cotacao,
                            name: ativ,
                            vi: vi.toFixed(2),
                            ve: ve.toFixed(2)
                        });
                    });
                    // strikeFilters.sort();
                    // console.log(strikeFilters);
                    // const uniqueMoneyness = [...new Set(listMoneyness)];
                    const uniqueVenc = [...new Set(listVenc)];
                    const uniqueStrike = [...new Set(listStrikes)];

                    // uniqueMoneyness.forEach((m) => {
                    //     tmp.filters.btnMoneyness[m] = true;
                    // });
                    // uniqueStrike.sort(function (a, b) {
                    //     // if (a === Infinity) return 1;
                    //     // if (Number.isNaN(a)) return -1;
                    //     return a - b;
                    // });
                    const strikeFinal = uniqueStrike
                        .slice()
                        .sort((a, b) => a - b);
                    uniqueVenc.sort();
                    uniqueVenc.forEach((u, i) => {
                        tmp.filters.venc.push({
                            label: moment(u).format('DD/MM/YYYY'),
                            value: u
                        });
                        // if (i === 0) {
                        //     tmp.filters.venc.push({label: u, status: true});
                        // } else {
                        //     tmp.filters.venc.push({label: u, status: false});
                        // }
                    });
                    strikeFinal.forEach((u, i) => {
                        tmp.filters.strikeList.push({
                            label: u,
                            value: u
                        });
                        // if (i === 0) {
                        //     tmp.filters.venc.push({label: u, status: true});
                        // } else {
                        //     tmp.filters.venc.push({label: u, status: false});
                        // }
                    });
                    tmp.filters.tikers = listTikers;
                    setData({[ativ]: tmp});
                    setOpenModalAtv(false);
                    setLoading(false);
                    setOpenNewWallet(true);
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        }
    }, [ativ]);

    const calcAloc = (strategy) => {
        let totMont = 0;
        strategy.forEach((s) => {
            if (s.type === 'V') {
                totMont += cleanNumber(s.preco_mont) * cleanNumber(s.qtd) * -1;
            } else {
                totMont += cleanNumber(s.preco_mont) * cleanNumber(s.qtd);
            }
        });
        return totMont;
    };

    const calcDesmont = (strategy) => {
        let totDesMont = 0;
        strategy.forEach((s) => {
            if (s.type === 'V') {
                totDesMont += cleanNumber(s.preco_desmont) * cleanNumber(s.qtd);
            } else {
                totDesMont +=
                    cleanNumber(s.preco_desmont) * cleanNumber(s.qtd) * -1;
            }
        });
        return totDesMont;
    };

    const calcLucro = (totMont, totDesm) => {
        let lucro = 0;
        if (totMont < 0 && totDesm < 0) {
            //  -> lucro
            totMont *= -1;
            lucro = totMont - totDesm;
        } else if (totMont < 0 && totDesm > 0) {
            totMont *= -1;
            if (totMont > totDesm) {
                // -> Lucro
                lucro = totMont - totDesm;
            } else {
                // -> Prejuizo
                lucro = totMont - totDesm;
            }
        } else if (totMont > 0 && totDesm > 0) {
            // -> Prejuizo
            lucro = (totMont + totDesm) * -1;
        } else if (totMont > 0 && totDesm < 0) {
            // -> lucro
            totDesm *= -1;
            if (totMont < totDesm) {
                // -> lucro
                lucro = totDesm - totMont;
            } else {
                lucro = totDesm - totMont;
            }
        }
        return lucro;
    };

    const calcDataMont = (strategy) => {
        let myDate = new Date(strategy[0]?.date_mont);
        strategy.forEach((s) => {
            myDate = moment(s.date_mont).isBefore(myDate)
                ? s.date_mont
                : myDate;
        });
        return myDate;
    };

    const calcRoi = (strategy) => {
        let capital = calcAloc(strategy);
        if (capital < 0) {
            capital *= -1;
        }
        const lucro = calcLucro(calcAloc(strategy), calcDesmont(strategy));
        let tmp = 0;
        tmp = (lucro * 0.85) / capital;
        if (Number.isNaN(tmp)) {
            return 0;
        }
        return tmp;
    };

    useEffect(() => {
        setLoading(true);
        api.wallet
            .getMyWallets()
            .then((wallet) => {
                // console.log(wallet);
                wallet.data.retorno.forEach((ret) => {
                    ret.lucro = calcLucro(
                        calcAloc(ret.Strategys),
                        calcDesmont(ret.Strategys)
                    );
                    ret.filterDataMont = calcDataMont(ret.Strategys);
                });

                setCards(wallet.data.retorno);
                setLoading(false);
            })
            .catch(() => setLoading(false));
    }, []);

    // setHeaderValues();

    const collapse = () => {
        setExpand(!expand);
    };

    const collapseChart = () => {
        setExpandChart(!expandChart);
    };

    function ContextAwareToggle({children, eventKey, callback}) {
        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey)
        );
        return (
            <button
                type="button"
                style={{backgroundColor: 'white', border: 'none'}}
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    }

    const handleClose = () => {
        setOpenNewWallet(false);
        setData();
        setAtiv('');
    };
    return (
        <>
            <div
                style={{
                    justifyContent: 'center',
                    display: 'flex',
                    marginTop: '-60px',
                    marginBottom: '40px',
                    // marginLeft: '660px',
                    zIndex: 1050,
                    position: 'relative',
                    width: '1200px',
                    marginLeft: window.innerWidth > 1650 ? '300px' : '50px'
                }}
            >
                <WalletHeader cards={filteredCards} />
            </div>
            <Loading open={loading} msg="Carregando carteira..." />
            <MobileDetecter />
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <Accordion defaultActiveKey="1">
                                <Card>
                                    <Card.Header>
                                        <div className="container-fluid">
                                            <div
                                                className="row"
                                                style={{
                                                    alignItems: 'space-between',
                                                    justifyContent:
                                                        'space-between'
                                                }}
                                            >
                                                <ContextAwareToggle eventKey="0">
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            direction: 'row',
                                                            alignItems:
                                                                'center',
                                                            color: '#d36233'
                                                        }}
                                                        onClick={collapse}
                                                    >
                                                        <ion-icon
                                                            size="large"
                                                            name="options-outline"
                                                            style={{
                                                                color: '#d36233',
                                                                marginRight: 15
                                                            }}
                                                        />{' '}
                                                        FILTROS
                                                    </div>
                                                </ContextAwareToggle>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        direction: 'row',
                                                        justifyContent: 'end'
                                                    }}
                                                >
                                                    <ContextAwareToggle eventKey="0">
                                                        {expand ? (
                                                            <span
                                                                title=""
                                                                onClick={
                                                                    collapse
                                                                }
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    height: '100%'
                                                                }}
                                                            >
                                                                <ion-icon
                                                                    id="collapse"
                                                                    size="large"
                                                                    name="chevron-down-outline"
                                                                    style={{
                                                                        color: '#d36233',
                                                                        pointerEvents:
                                                                            'none'
                                                                    }}
                                                                />
                                                            </span>
                                                        ) : (
                                                            <span
                                                                title=""
                                                                onClick={
                                                                    collapse
                                                                }
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    height: '100%'
                                                                }}
                                                            >
                                                                <ion-icon
                                                                    id="collapse"
                                                                    size="large"
                                                                    name="chevron-back-outline"
                                                                    style={{
                                                                        color: '#d36233',
                                                                        pointerEvents:
                                                                            'none'
                                                                    }}
                                                                />
                                                            </span>
                                                        )}
                                                    </ContextAwareToggle>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <FiltersWallet
                                                setLoading="setLoading"
                                                data={cards}
                                                setData={setFilteredcards}
                                                active={ativ}
                                                size={size}
                                            />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                        {/* <div className="col-lg-12">
                            <Accordion defaultActiveKey="0">
                                <Card>
                                    <Card.Header>
                                        <div className="container-fluid">
                                            <div
                                                className="row"
                                                style={{
                                                    alignItems: 'space-between',
                                                    justifyContent:
                                                        'space-between'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        direction: 'row',
                                                        alignItems: 'center',
                                                        color: '#d36233'
                                                    }}
                                                >
                                                    <ion-icon
                                                        name="bar-chart-outline"
                                                        style={{
                                                            marginRight: 15
                                                        }}
                                                    />
                                                    RENTABILIDADE
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        direction: 'row',
                                                        justifyContent: 'end'
                                                    }}
                                                >
                                                    <ContextAwareToggle eventKey="0">
                                                        {expandChart && (
                                                            <ion-icon
                                                                id="collapsed"
                                                                size="large"
                                                                name="chevron-down-outline"
                                                                style={{
                                                                    color: '#d36233',
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={
                                                                    collapseChart
                                                                }
                                                            />
                                                        )}
                                                        {!expandChart && (
                                                            <ion-icon
                                                                id="collapse"
                                                                size="large"
                                                                name="chevron-back-outline"
                                                                style={{
                                                                    color: '#d36233',
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={
                                                                    collapseChart
                                                                }
                                                            />
                                                        )}
                                                    </ContextAwareToggle>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <WalletChart
                                                cards={filteredCards}
                                            />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div> */}
                        <div className="col-lg-12">
                            <Grid container spacing={1}>
                                {filteredCards.map((c) => (
                                    <Grid key={c.id} item>
                                        <CardWallet
                                            key={c.id}
                                            card={c}
                                            setCards={setCards}
                                            cards={cards}
                                            status={c.status}
                                            selic={selic}
                                        />
                                    </Grid>
                                ))}
                                <Grid item>
                                    <CardWalletBlank
                                        open={setOpenModalAtv}
                                        cards={cards}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={openModalAtv} size="lg">
                <SelectWalletAtiv close={setOpenModalAtv} setAtiv={setAtiv} />
            </Modal>
            <Modal show={openNewWallet} onClose={handleClose} size="xl">
                <NewStrategy
                    handleClose={handleClose}
                    ativ={ativ}
                    data={data}
                    status={isOpen}
                    setStatus={setIsOpen}
                    cards={cards}
                    setCards={setCards}
                    selic={selic}
                />
            </Modal>
            {/* <Dialog
                fullWidth
                maxWidth="xl"
                open={openNewWallet}
                onClose={handleClose}
                classes={{
                    paper: classes.dialog
                }}
            >
                <NewStrategy
                    handleClose={handleClose}
                    ativ={ativ}
                    data={data}
                />
            </Dialog> */}
        </>
    );
}
