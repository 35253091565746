import React from 'react';

import Backdrop from '@material-ui/core/Backdrop';
import {makeStyles} from '@material-ui/core/styles';
import {Typography, Grid} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import logo from 'src/img/OPTI_logo.avif';

import './Loading.css';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: 'rgba(0, 0, 0, .75)'
    },
    circularColor: {
        color: '#d36233',
        zIndex: theme.zIndex.drawer + 2
    },
    text: {
        marginTop: 30
    },
    logo1: {
        color: '#3763B6',
        fontWeight: 'bold',
        fontSize: 25,
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1
    },
    logo2: {
        color: theme.palette.common.white,
        fontWeight: 'bold',
        fontSize: 25,
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1
    },
    tipografy: {
        color: '#d36233',
        fontWeight: 'bold',
        fontSize: 16
    }
}));

export default function Loading({open, msg}) {
    const classes = useStyles();
    return (
        <Backdrop className={classes.backdrop} open={open}>
            <Grid container spacing={0}>
                <Grid
                    item
                    xs={12}
                    alignItems="center"
                    justifyContent="center"
                    container
                    spacing={3}
                >
                    <CircularProgress
                        className={classes.circularColor}
                        size={80}
                    />
                    <div className={classes.logo1}>
                        {/* <Typography
                            style={{
                                fontWeight: 'bold',
                                fontSize: 60
                                // marginBottom: -1
                            }}
                        >
                            C
                        </Typography> */}
                        {/* <Typography style={{fontWeight: 'bold', fontSize: 20}}>
                            Store
                        </Typography> */}
                    </div>
                    <img className="image" alt="Caju" src={logo} />
                </Grid>
                <Grid
                    className={classes.text}
                    item
                    xs={12}
                    container
                    justifyContent="center"
                    spacing={3}
                >
                    {msg && (
                        <Typography className={classes.tipografy}>
                            {msg}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Backdrop>
    );
}
