import React from 'react';
import {Grid} from '@material-ui/core';
import {makeStyles, createStyles} from '@material-ui/core/styles';

export default function EmptyData({isRight}) {
    const useStyles = makeStyles(() =>
        createStyles({
            myGridVC: {
                minWidth: 15,
                maxWidth: 15,
                maxHeight: 15,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                textAlign: 'center',
                // cursor: 'pointer',
                border: '2px solid #c5c5c5'
            },
            myGridVCSelected: {
                minWidth: 15,
                maxWidth: 15,
                maxHeight: 15,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                textAlign: 'center',
                // cursor: 'pointer',
                backgroundColor: '#e37039',
                color: '#fff'
            }
        })
    );
    const classes = useStyles();
    return (
        <tr
            style={{
                height: '20px',
                borderBottom: ' 0.5px solid lightgrey',
                borderTop: '0.5px solid lightgrey',
                borderRight: !isRight
                    ? '0.5px solid lightgrey'
                    : '0px solid white'
                // marginRight: -10
            }}
        >
            <td
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-evenly'
                }}
            >
                <Grid
                    className={classes.myGridVC}
                    style={{
                        fontSize: 12,
                        fontWeight: 500
                    }}
                >
                    C
                </Grid>
                <span
                    style={{
                        // marginLeft: '15px ',
                        width: '85px',
                        fontSize: 14,
                        fontWeight: 'bold'
                    }}
                >
                    Empty
                    <ion-icon
                        // onClick={() => notify()}
                        style={{
                            fontSize: 12,
                            color: '#d36233',
                            cursor: 'pointer',
                            marginLeft: 5
                        }}
                        name="copy-outline"
                    />
                </span>
                <span
                    style={{
                        width: '35px',
                        fontSize: 14,
                        fontWeight: 'bold'
                    }}
                >
                    00,00
                </span>
            </td>
        </tr>
    );
}
