export default {
    // login: '/login',
    // group: '/group',
    // gateways: '/gateways',
    // alldevices: '/alldevices',
    // profile: '/profile',
    home: '/',
    money: '/carteira-de-estruturas',
    antes: '/teste',
    charts: '/mapa-de-opcoes',
    dashboard: '/grade-de-opcoes',
    test: '/testes',
    login: '/login',
    roex: '/roex',
    mercado: '/mercado',
    perfil: '/perfil',
    termos: '/termos-de-uso-opti',
    privacidade: '/politica-de-privacidade',
    faq: '/perguntas-frequentes',
    sobrenos: '/sobre-nos',
    gradeepm: '/grade-de-opcoes-epm',
    admin: '/admin/dashboard',
    vendas: '/lp-30dias'

    // GatewaysDetails: (gtD) => `/gatewaysDetails/${gtD}`,
    // devices: (d) => `/devices/${d}`
};
