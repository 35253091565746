/* eslint-disable no-unused-vars */

import React, {useContext, useEffect} from 'react';
import {Route, useHistory, Redirect} from 'react-router-dom';
import routing from '@app/routes/routing';
import {AuthContext} from './Auth';
import app from './base';
import api from '../utils/api';

// Redirect,

const PrivateRoute = ({render: RouteComponent, role, ...rest}) => {
    const history = useHistory();
    const {currentUser} = useContext(AuthContext);
    useEffect(() => {
        if (!api.currentSession) {
            app.auth()
                .signOut()
                .then(() => {
                    // Sign-out successful.
                })
                .catch(() => {
                    // An error happened.
                });
        }
    }, []);
    // const [permissions, setPermissions] = useState([]);s
    // app.auth()
    //     .signOut()
    //     .then(() => {
    //         // Sign-out successful.
    //     })
    //     .catch(() => {
    //         // An error happened.
    //     });

    // async function loadRoles() {
    //     const response = [];
    //     if (api.currentUser) {
    //         if (api.currentUser.role) {
    //             response.push(api.currentUser.role.value);
    //             const findRole = response.some((r) =>
    //                 role?.split(',').includes(r)
    //             );
    //             setPermissions(findRole);
    //         }
    //     }
    // }

    // useEffect(() => {
    //     loadRoles();
    // }, [permissions, currentUser]);

    // if (!role) {
    return (
        <Route
            {...rest}
            render={(routeProps) =>
                currentUser ? (
                    <RouteComponent {...routeProps} />
                ) : (
                    // <Redirect to="/login" />
                    history.push(routing.login)
                )
            }
        />
    );
    // }

    // if (permissions) {
    //     return (
    //         <Route
    //             {...rest}
    //             render={(routeProps) =>
    //                 currentUser ? (
    //                     <RouteComponent {...routeProps} />
    //                 ) : (
    //                     <Redirect to="/login" />
    //                 )
    //             }
    //         />
    //     );
    // }
    // return <Redirect to="/login" />;

    // return permissions ? (
    //     <Route
    //         {...rest}
    //         render={(routeProps) =>
    //             currentUser ? (
    //                 <RouteComponent {...routeProps} />
    //             ) : (
    //                 <Redirect to="/login" />
    //             )
    //         }
    //     />
    // ) : (
    //     <Redirect to="/login" />
    // );
};

export default PrivateRoute;

// import React, {useContext} from 'react';
// import {Route, Redirect} from 'react-router-dom';
// import {AuthContext} from './Auth';

// const PrivateRoute = ({component: RouteComponent, ...rest}) => {
//     const {currentUser} = useContext(AuthContext);
//     console.log('To aqui, ', currentUser);
//     return (
//         <Route
//             {...rest}
//             render={(routeProps) =>
//                 currentUser ? (
//                     <RouteComponent {...routeProps} />
//                 ) : (
//                     <Redirect to="/login" />
//                 )
//             }
//         />
//     );
// };

// export default PrivateRoute;
