/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */

import React, {useEffect, useState} from 'react';
import {Card, Modal, Row, Col, Button} from 'react-bootstrap';
import api from '@app/utils/api';
import {cleanNumber} from '@app/utils/myaux';
import {Grid, Box} from '@material-ui/core';
import moment from 'moment';
import './RoexCards.css';

export default function RoexCards({
    card,
    setCards,
    cards,
    status,
    returnSelected,
    setHighlight,
    nowSelected,
    filtered
}) {
    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(false);

    const calcAloc = (strategy) => {
        let totMont = 0;
        strategy.forEach((s) => {
            if (s.type === 'V') {
                totMont += cleanNumber(s.preco_mont) * cleanNumber(s.qtd) * -1;
            } else {
                totMont += cleanNumber(s.preco_mont) * cleanNumber(s.qtd);
            }
        });

        return totMont;
    };

    const calcCredito = (strategy) => {
        let totMont = 0;
        let desMont = 0;
        strategy.forEach((s) => {
            if (s.type === 'V') {
                totMont += cleanNumber(s.preco_mont) * cleanNumber(s.qtd) * -1;
                desMont +=
                    cleanNumber(s.preco_desmont) * cleanNumber(s.qtd) * -1;
            } else {
                totMont += cleanNumber(s.preco_mont) * cleanNumber(s.qtd);
                desMont += cleanNumber(s.preco_desmont) * cleanNumber(s.qtd);
            }
        });
        if (desMont < 0) totMont = 0;
        return totMont;
    };

    const calcDays = (strategy) => {
        let myDate = new Date(strategy[0]?.date_desmont);
        let initialDate = new Date(strategy[0]?.date_mont);
        strategy.forEach((s) => {
            myDate = moment(s.date_desmont).isAfter(myDate)
                ? s.date_desmont
                : myDate;

            initialDate = moment(s?.date_mont).isBefore(initialDate)
                ? s.date_mont
                : initialDate;
        });

        myDate = new Date();
        const tmp = moment(initialDate);
        const sumDays = moment(myDate);
        const dif = Math.ceil(sumDays.diff(tmp, 'days', true));
        return dif;
    };

    const calcDesmont = (strategy) => {
        let totDesMont = 0;
        strategy.forEach((s) => {
            if (s.type === 'V') {
                totDesMont += cleanNumber(s.preco_desmont) * cleanNumber(s.qtd);
            } else {
                totDesMont +=
                    cleanNumber(s.preco_desmont) * cleanNumber(s.qtd) * -1;
            }
        });

        return totDesMont;
    };

    const calcLucro = (totMont, totDesm) => {
        let lucro = 0;
        if (totMont < 0 && totDesm < 0) {
            totMont *= -1;
            lucro = totMont - totDesm;
        } else if (totMont < 0 && totDesm > 0) {
            totMont *= -1;
            if (totMont > totDesm) {
                lucro = totMont - totDesm;
            } else {
                lucro = totMont - totDesm;
            }
        } else if (totMont > 0 && totDesm > 0) {
            lucro = (totMont + totDesm) * -1;
        } else if (totMont > 0 && totDesm < 0) {
            totDesm *= -1;
            if (totMont < totDesm) {
                lucro = totDesm - totMont;
            } else {
                lucro = totDesm - totMont;
            }
        }
        return lucro;
    };

    const calcRoi = (strategy) => {
        let capital = calcAloc(strategy);
        if (capital < 0) {
            capital *= -1;
        }
        const lucro = calcLucro(
            calcAloc(card.Strategys),
            calcDesmont(card.Strategys)
        );
        let tmp = 0;
        tmp = (lucro * 0.85) / capital;

        if (Number.isNaN(tmp)) {
            return 0;
        }
        return tmp;
    };

    const handleOpen = () => {
        if (card.id !== nowSelected) {
            setSelected(true);
            setHighlight(card.id);
        }
    };

    // codigo para não deixar ter dois selecionados ->
    useEffect(() => {
        if (nowSelected !== card.id) setSelected(false);
        else setSelected(true);
    }, [nowSelected, filtered]);

    return (
        <>
            {/* <Loading open={loading} msg="Carregando carteira..." />  */}
            <Card
                // className={`section-divs ${selected ? ' selected' : undefined}`}
                onClick={() => {
                    handleOpen();
                    returnSelected(card);
                }}
                style={{
                    width: '18rem',
                    cursor: 'pointer',
                    padding: 0,
                    height: '12rem',
                    marginBottom: '10px',
                    border: selected ? '4px solid #e37039' : '0px  solid white',
                    borderRadius: '10px'
                }}
            >
                <Card.Header
                    variant="top"
                    style={{
                        background: '#313031',
                        color: '#fff',
                        height: '52%',
                        paddingRight: 0,
                        paddingLeft: 0
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            marginLeft: '90%'
                            // paddingBottom: '2px'
                        }}
                    >
                        <ion-icon
                            style={{
                                fontSize: 18,
                                color: '#e37039',
                                cursor: 'pointer'
                            }}
                            name={
                                card.status
                                    ? 'lock-open-outline'
                                    : 'lock-closed-outline'
                            }
                        />
                    </div>
                    <div style={{justifyContent: 'center', display: 'flex'}}>
                        <h1
                            style={{
                                fontSize:
                                    card.name.length < 27
                                        ? '20px'
                                        : card.name.length < 30
                                        ? '18px'
                                        : '16px',
                                textAlign: 'center',
                                marginTop:
                                    card.name.length < 30
                                        ? '2px'
                                        : card.name.length < 33
                                        ? '2px'
                                        : '-12px',
                                width:
                                    card.name.length < 33
                                        ? ''
                                        : card.name.length < 39
                                        ? '180px'
                                        : '220px'
                            }}
                        >
                            {card.name}
                        </h1>
                    </div>
                    <div
                        style={{
                            background: '#cac6c7',
                            width: '110px',
                            height: '55px',
                            borderRadius: '15%',
                            color: '#000',
                            margin: '8px auto',
                            textAlign: 'center'
                        }}
                    >
                        <div
                            style={{
                                fontSize: 20,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingTop: '0px',
                                marginBottom: '-10px',
                                fontWeight: 700
                            }}
                        >
                            {calcRoi(card.Strategys) > 0 ? (
                                <span
                                    style={{
                                        color: '#e37039',
                                        paddingRight: 3,
                                        paddingLeft: 2,
                                        fontSize: 26,
                                        fontWeight: 300
                                    }}
                                >
                                    +
                                </span>
                            ) : (
                                <span
                                    style={{
                                        color: '#e37039',
                                        paddingRight: 3,
                                        paddingLeft: 2,
                                        fontSize: 26,
                                        fontWeight: 300
                                    }}
                                >
                                    -
                                </span>
                            )}
                            {new Intl.NumberFormat('pt-BR', {
                                style: 'percent',
                                minimumFractionDigits: 2,
                                currency: 'BRL',
                                signDisplay: 'never'
                            })
                                .format(calcRoi(card.Strategys))
                                .replace(',', '.')}
                        </div>
                        <div style={{fontWeight: 700, fontSize: 14}}>ROI</div>
                    </div>
                </Card.Header>
                <Card.Body
                    style={{
                        maxHeight: '40px',
                        paddingRight: 0,
                        paddingLeft: 0
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            marginTop: '30px',
                            fontSize: 11.5,
                            justifyContent: 'space-between',
                            padding: 0
                        }}
                    >
                        <div
                            style={{
                                borderRight: '2px solid #c5c5c5',
                                textAlign: 'center'
                            }}
                        >
                            <span
                                style={{
                                    color: '#e37039',
                                    fontWeight: 'bold',
                                    display: 'inline-block',
                                    margin: '0px 10px 0px 5px'
                                }}
                            >
                                {new Intl.NumberFormat('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL'
                                })
                                    .format(calcCredito(card.Strategys))
                                    .replace(',', '.')
                                    .replace('-', '')}
                            </span>
                            <div
                                style={{
                                    margin: '0px 10px 0px 5px',
                                    fontFamily: 'Roboto'
                                }}
                            >
                                CAP. ALOCADO
                            </div>
                        </div>
                        <div
                            style={{
                                borderRight: '2px solid #c5c5c5',
                                textAlign: 'center'
                            }}
                        >
                            <span
                                style={{
                                    color: '#e37039',
                                    fontWeight: 'bold',
                                    display: 'inline-block',
                                    margin: '0px 10px 0px 5px'
                                }}
                            >
                                {/* {calcDesmont(card.Strategys) -
                                    calcAloc(card.Strategys)} */}
                                {new Intl.NumberFormat('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL'
                                })
                                    .format(
                                        Number.isNaN(
                                            calcLucro(
                                                calcAloc(card.Strategys),
                                                calcDesmont(card.Strategys)
                                            )
                                        )
                                            ? 0
                                            : calcLucro(
                                                  calcAloc(card.Strategys),
                                                  calcDesmont(card.Strategys)
                                              )
                                    )
                                    .replace(',', '.')
                                    .replace('-', '')}
                            </span>
                            <div
                                style={{
                                    margin: '0px 10px 0px 5px',
                                    fontFamily: 'Roboto'
                                }}
                            >
                                {calcLucro(
                                    calcAloc(card.Strategys),
                                    calcDesmont(card.Strategys)
                                ) >= 0
                                    ? 'LUCRO'
                                    : 'PREJUÍZO'}
                            </div>
                        </div>
                        <div
                            style={{
                                borderRight: '2px solid #c5c5c5',
                                textAlign: 'center'
                            }}
                        >
                            <span
                                style={{
                                    color: '#e37039',
                                    fontWeight: 'bold',
                                    display: 'inline-block',
                                    margin: '0px 10px 0px 5px'
                                }}
                            >
                                {calcDays(card.Strategys)}
                            </span>
                            <div
                                style={{
                                    margin: '0px 10px 0px 5px',
                                    fontFamily: 'Roboto'
                                }}
                            >
                                TEMPO
                            </div>
                        </div>
                        <div style={{textAlign: 'center', marginRight: '5px'}}>
                            <span
                                style={{
                                    color: '#e37039',
                                    fontWeight: 'bold',
                                    display: 'inline-block',
                                    margin: '0px 0px 0px 5px'
                                }}
                            >
                                {card.ativo}
                            </span>
                            <div
                                style={{
                                    margin: '0px 1px 0px 5px',
                                    fontFamily: 'Roboto'
                                }}
                            >
                                ATIVO
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </>
    );
}
