/* eslint-disable indent */

import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import toast from 'react-hot-toast';
import api from '@app/utils/api';
import _ from 'lodash';
import {cleanNumber} from '@app/utils/myaux';
import Loading from '../../../components/loading/Loading';

const useStyles = makeStyles(() => ({
    headerModal: {
        color: 'white',
        background: '#313031',
        fontSize: 25,
        border: '1px solid white',
        '&::placeholder': {
            color: '#fff',
            opacity: 1 /* Firefox */
        },
        '&:focus': {
            outline: 'none'
        }
    }
}));

export default function HeaderStrategy({
    handleClose,
    ativ,
    status,
    setStatus,
    strategy,
    setStrategy,
    cards,
    setCards,
    id,
    name,
    setName,
    cotIni,
    cotFim,

    tickers
}) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [msg, setMsg] = useState('');
    const [volTicker, setVolTicker] = useState();

    // console.log(status);

    const notifyError = () => {
        toast.error('Nome da carteira muito comprido');
    };

    const changeStatus = () => {
        if (!status) {
            toast.success('Carteira destravada!', {
                iconTheme: {
                    primary: '#d36233',
                    secondary: '#fff'
                }
            });
        } else {
            const orig = _.cloneDeep(strategy);
            orig.forEach((s) => {
                s.mustUpdated = false;
            });
            setStrategy(orig);
            toast.success('Carteira travada!', {
                iconTheme: {
                    primary: '#d36233',
                    secondary: '#fff'
                }
            });
        }
        setStatus(!status);
    };

    useEffect(() => {
        if (tickers) {
            setVolTicker(tickers);
        }
    }, [tickers]);

    const save = () => {
        const t = strategy.length - 1;

        let has = 0;
        strategy.forEach((s) => {
            if (!s.ticker || s.type === '') {
                has += 1;
            }
        });

        if (has > 0) {
            if (t === 0) {
                toast.error(
                    'Você precisa ter pelo menos uma estratégia completa para salvar a carteira.'
                );
            } else {
                toast.error(
                    'Sua última estratégia está incompleta. Complete-a ou à exclua.'
                );
            }
        } else if (name === '') {
            toast.error('Informe um nome a sua carteira');
        } else {
            const saveData = [];
            const newData = [];
            strategy.forEach((s, i) => {
                if (!id) {
                    newData.push({
                        type: s.type,
                        qtd: s.qtd,
                        type_ativo: s.activeType,
                        venc: s.venc?.value,
                        strike: s.strike?.value,
                        ticker: s.ticker.value,
                        preco_mont: cleanNumber(s.cotacao),
                        preco_desmont: cleanNumber(s.cotacaoDesmont.value),
                        preco_teorico: cleanNumber(s.pteorico),
                        preco_teorico_desmont: cleanNumber(s.pteoricoDesmont),
                        vol: new Intl.NumberFormat('pt-BR', {
                            maximumSignificantDigits: 3
                        }).format(Number(volTicker[i])),
                        date_mont: s.dataMont,
                        date_desmont: s.dataDesmont,
                        must_updated: s.mustUpdated
                    });
                    // console.log('antes aqui', newData);
                } else {
                    saveData.push({
                        type: s.type,
                        qtd: s.qtd,
                        type_ativo: s.activeType,
                        venc: s.venc?.value,
                        strike: s.strike?.value,
                        ticker: s.ticker.value,
                        preco_mont: cleanNumber(s.cotacao),
                        preco_desmont: cleanNumber(s.cotacaoDesmont.value),
                        preco_teorico: cleanNumber(s.pteorico),
                        preco_teorico_desmont: cleanNumber(s.pteoricoDesmont),
                        vol: !s.vol
                            ? new Intl.NumberFormat('pt-BR', {
                                  maximumSignificantDigits: 3
                              }).format(Number(volTicker[i]))
                            : s.vol,
                        date_mont: s.dataMont,
                        date_desmont: s.dataDesmont,
                        must_updated: s.mustUpdated
                    });
                    // console.log('aqui', saveData);
                }
            });

            if (id) {
                setMsg('Editando carteira...');
                setLoading(true);
                console.log(name.length);
                if (name.length <= 45) {
                    api.wallet
                        .edit({
                            id,
                            name,
                            ativo: ativ,
                            status,
                            cotacaoStart: cotIni,
                            cotacaoEnd: cotFim,
                            closedAt: status ? null : new Date(),
                            strategy: saveData
                        })
                        .then((res) => {
                            const orig = _.cloneDeep(cards);
                            const idx = orig.indexOf(
                                orig.find((card) => card.id === id)
                            );
                            orig.splice(idx, 1, res.data.retorno);
                            setCards(orig);
                            toast.success('Carteira editada com sucesso.', {
                                iconTheme: {
                                    primary: '#d36233',
                                    secondary: '#fff'
                                }
                            });
                            setLoading(false);
                            handleClose();
                        })
                        .catch((err) => {
                            console.error(err);
                        });
                } else {
                    notifyError();
                    setLoading(false);
                }
            } else {
                setMsg('Criando carteira...');
                setLoading(true);
                api.wallet
                    .new({
                        name,
                        ativo: ativ,
                        status,
                        cotacaoStart: cotIni,
                        cotacaoEnd: cotFim,
                        closedAt: status ? null : new Date(),
                        strategy: newData
                        // strategy: saveData
                    })
                    .then((res) => {
                        setCards([...cards, res.data.retorno]);
                        setLoading(false);
                        handleClose();
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }
        }
    };

    const deleteWallet = () => {
        if (id) {
            api.wallet
                .delete({id})
                .then(() => {
                    const orig = _.cloneDeep(cards);
                    const idx = orig.indexOf(
                        orig.find((card) => card.id === id)
                    );
                    orig.splice(idx, 1);
                    setCards(orig);
                    handleClose();
                })
                .catch((err) => console.error(err));
        } else {
            handleClose();
        }
    };

    return (
        <>
            <Loading open={loading} msg={msg} />
            <div className="container-fluid">
                <div className="row">
                    <div
                        className="col-sm-6"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            borderRight: '1px solid white',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div>
                            <input
                                type="NOVA ESTRATÉGIA"
                                placeholder="NOVA ESTRATÉGIA"
                                className={classes.headerModal}
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <ion-icon
                                style={{
                                    fontSize: 25,
                                    color: '#e37039',
                                    cursor: 'pointer',
                                    marginLeft: 4
                                }}
                                name="create-outline"
                            />
                        </div>
                        <div
                            style={{
                                textAlign: 'center'
                            }}
                        >
                            <p
                                style={{
                                    margin: -4,
                                    color: '#e37039',
                                    fontSize: 16
                                }}
                            >
                                {ativ}
                            </p>
                            <p
                                style={{
                                    margin: -4,
                                    color: 'white',
                                    fontSize: 12
                                }}
                            >
                                ATIVO
                            </p>
                        </div>
                    </div>
                    <div
                        className="col-sm-6"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div
                            style={{
                                textAlign: 'center',
                                marginLeft: 2
                            }}
                        >
                            <p
                                style={{
                                    margin: -4,
                                    color: '#e37039',
                                    fontSize: 16
                                }}
                            >
                                {status ? 'ABERTA' : 'FECHADA'}
                            </p>
                            <p
                                style={{
                                    margin: -4,
                                    color: 'white',
                                    fontSize: 12
                                }}
                            >
                                STATUS
                            </p>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <ion-icon
                                style={{
                                    fontSize: 25,
                                    color: '#e37039',
                                    cursor: 'pointer',
                                    marginLeft: 4
                                }}
                                name="trash-outline"
                                onClick={deleteWallet}
                            />
                            <ion-icon
                                style={{
                                    fontSize: 25,
                                    color: '#e37039',
                                    cursor: 'pointer',
                                    marginLeft: 4
                                }}
                                name="save-outline"
                                onClick={save}
                            />

                            <ion-icon
                                style={{
                                    fontSize: 25,
                                    color: '#e37039',
                                    cursor: 'pointer',
                                    marginLeft: 4
                                }}
                                name={
                                    status
                                        ? 'lock-open-outline'
                                        : 'lock-closed-outline'
                                }
                                onClick={changeStatus}
                            />
                            {/* <ion-icon
                            style={{
                                fontSize: 25,
                                color: '#e37039',
                                cursor: 'pointer',
                                marginLeft: 4
                            }}
                            name="settings-outline"
                        /> */}
                            <ion-icon
                                onClick={handleClose}
                                style={{
                                    fontSize: 33,
                                    color: '#e37039',
                                    cursor: 'pointer',
                                    marginLeft: -4
                                    // marginBottom: -4
                                }}
                                name="close-outline"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
