/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus   */
/* eslint-disable indent */

import React, {useState, useEffect, useRef} from 'react';
import {Accordion, Card} from 'react-bootstrap';
import {useAccordionToggle} from 'react-bootstrap/AccordionToggle';
import toast, {Toaster} from 'react-hot-toast';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import api from '@app/utils/api';
import Slider from 'react-slick';
import moment from 'moment';
import {Grid} from '@material-ui/core';
import _ from 'lodash';
import WebFont from 'webfontloader';

import Calculadora from '@app/pages/Calculadora';
import Loading from '../../components/loading/Loading';
import RoexRolagem from './Table/RoexRolagem';
import RoexExercicio from './Table/RoexExercicio';
import RoexRemont from './Table/RoexRemont';
import RoexFilter from './RoexFilter';
import RoexCards from './RoexCards';
import MobileDetecter from '../../components/MobileDetecter';
import './RoexCards.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import EmptyCards from './Table/components/EmptyCards';

function Roex() {
    const [counter, setCounter] = useState(0);
    const [selected, setSelected] = useState();
    const [loading, setLoading] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [cards, setCards] = useState([]);
    const [filteredCards, setFilteredcards] = useState([]);
    const [expand, setExpand] = useState(false);
    const [expandStructure, setExpandStructure] = useState(false);
    const [highlight, setHighlight] = useState();
    const [nowSelected, setNowSelected] = useState();
    const [currentCard, setCurrentCard] = useState();
    const [roexData, setRoexData] = useState();
    const [ativo, setAtivo] = useState();
    const [venc, setVenc] = useState();
    const [diffVenc, setDiffVenc] = useState();

    const [newStrategy, setNewStrategy] = useState();
    const [rolagem, setRolagem] = useState();
    const [remontagem, setRemontagem] = useState();
    const [size, setSize] = useState(window.innerWidth);
    const [selic, setSelic] = useState();
    const [ilimitado, setIlimitado] = useState(false);

    useEffect(() => {
        if (
            api.currentUser &&
            moment() <= moment(api.currentUser?.assinatura?.vencimento)
        ) {
            setIlimitado(true);
        }
        api.options
            .getSelic()
            .then((res) => setSelic(Number(res) / 100))
            .catch((err) => console.log(err));
        function handleResize() {
            console.log(window.innerWidth);
            setSize(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const active = '';
    const emptyCards = [1, 2, 3, 4, 5, 6, 7];

    const useStyles = makeStyles((theme) =>
        createStyles({
            ionIcon: {
                '& ion-icon:before': {
                    content: '',
                    position: 'absolute',
                    width: '100%',
                    height: '100%'
                }
            },
            slick: {
                '& .slick-track': {
                    marginLeft: '1%'
                }
            }
        })
    );

    const classes = useStyles();

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };
    const collapse = () => {
        setExpand(!expand);
    };
    const collapseStructure = () => {
        setExpandStructure(!expandStructure);
    };

    const notify = (t) =>
        toast.success('Ticker copiado!', {
            iconTheme: {
                primary: '#d36233',
                secondary: '#fff'
            }
        }) && navigator.clipboard.writeText(t);

    const returnSelected = (s) => {
        setSelected(s);
    };

    const calcAloc = (strategy) => {
        let totMont = 0;
        strategy.forEach((s) => {
            if (s.type === 'V') {
                totMont += Number(s.preco_mont) * Number(s.qtd) * -1;
            } else {
                totMont += Number(s.preco_mont) * Number(s.qtd);
            }
        });
        return totMont;
    };

    const calcDesmont = (strategy) => {
        let totDesMont = 0;
        strategy.forEach((s) => {
            if (s.type === 'V') {
                totDesMont += Number(s.preco_desmont) * Number(s.qtd);
            } else {
                totDesMont += Number(s.preco_desmont) * Number(s.qtd) * -1;
            }
        });
        // return totMont;
        return totDesMont;
    };

    const calcLucro = (totMont, totDesm) => {
        let lucro = 0;
        if (totMont < 0 && totDesm < 0) {
            totMont *= -1;
            lucro = totMont - totDesm;
        } else if (totMont < 0 && totDesm > 0) {
            totMont *= -1;
            if (totMont > totDesm) {
                lucro = totMont - totDesm;
            } else {
                lucro = totMont - totDesm;
            }
        } else if (totMont > 0 && totDesm > 0) {
            lucro = (totMont + totDesm) * -1;
        } else if (totMont > 0 && totDesm < 0) {
            totDesm *= -1;
            if (totMont < totDesm) {
                lucro = totDesm - totMont;
            } else {
                lucro = totDesm - totMont;
            }
        }
        return lucro;
    };

    const calcDataMont = (strategy) => {
        let myDate = new Date(strategy[0]?.date_mont);
        strategy.forEach((s) => {
            myDate = moment(s.date_mont).isBefore(myDate)
                ? s.date_mont
                : myDate;
        });
        return myDate;
    };

    function ContextAwareToggle({children, eventKey, callback}) {
        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey)
        );
        return (
            <button
                type="button"
                style={{backgroundColor: 'white', border: 'none'}}
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    }

    function SampleNextArrow(props) {
        const {className, style, onClick} = props;
        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <ion-icon
                name="chevron-forward-outline"
                className={className}
                style={{
                    minWidth: '40px',
                    width: '40px',
                    height: '60px',
                    marginLeft: '40px',
                    color: '#e37039',
                    cursor: 'pointer'
                }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const {className, style, onClick} = props;
        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <ion-icon
                name="chevron-back-outline"
                className={className}
                style={{
                    minWidth: '40px',
                    width: '40px',
                    height: '60px',
                    marginLeft: '-30px',
                    color: '#e37039',
                    cursor: 'pointer'
                }}
                onClick={onClick}
            />
        );
    }

    function checkDiff(a) {
        return new Set(a).size !== 1;
    }

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto', 'Chilanka']
            }
        });
        function handleResize() {
            setSize(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        // console.log(counter);
        if (filteredCards.length > 0 && counter === 0) {
            setSelected(filteredCards[0]);
            setCounter(1);
        }
    }, [filteredCards]);

    useEffect(() => {
        setLoading(true);
        api.wallet
            .getMyWallets()
            .then((wallet) => {
                wallet.data.retorno.forEach((ret) => {
                    ret.lucro = calcLucro(
                        calcAloc(ret.Strategys),
                        calcDesmont(ret.Strategys)
                    );
                    ret.filterDataMont = calcDataMont(ret.Strategys);
                });
                setCards(wallet.data.retorno);
                setLoading(false);
            })
            .catch(() => {
                // console.log(false);
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        setNowSelected(currentCard);
    }, [currentCard]);

    useEffect(() => {
        if (selected) {
            const listVenc = [];
            const listData = [];
            const listStr = [];
            // console.log(selected);

            const tmpList = selected.Strategys.filter(
                (a) => a.ticker !== selected.ativo
            );
            // console.log(tmpList);

            tmpList.forEach((s) => {
                if (s.must_updated) {
                    listStr.push(s);
                    listVenc.push(s.venc);
                    listData.push({
                        id: s.id,
                        ticker: s.ticker,
                        strike: s.strike,
                        newStrike:
                            selected.cotacao_end -
                            (selected.cotacao_start - s.strike),
                        type: s.type_ativo === 'C' ? 'CALL' : 'PUT',
                        venc: s.venc,
                        tipo: s.type,
                        qtd: s.qtd,
                        createdAt: s.createdAt,
                        dateMont: s.date_mont,
                        date_desmont: s.date_desmont,
                        wallet_id: s.wallet_id
                    });
                }
            });

            const tmpVenc = listVenc.sort((a, b) => a.localeCompare(b));
            const tmpDiffVenc = checkDiff(listVenc);
            // console.log(diffVenc);
            // console.log(tmpVenc);
            setDiffVenc(tmpDiffVenc);
            setVenc(tmpVenc[0]);
            setAtivo(selected.ativo);
            setRoexData(listData);
            setNewStrategy(listStr);
        }
    }, [selected]);

    useEffect(() => {
        if (ativo && venc && roexData) {
            setLoadingTable(true);
            api.roex
                .getData({
                    ativo,
                    vencimento: venc,
                    dataRoex: roexData
                })
                .then((res) => {
                    console.log('roex', res);
                    setRemontagem(res.data.retorno[0]);
                    setRolagem(res.data.retorno[1]);
                    setLoadingTable(false);
                })
                .catch((err) => {
                    console.log(err);
                    setLoadingTable(false);
                });
        }
    }, [roexData]);

    return (
        <div>
            <Loading open={loading} msg="Buscando estruturas..." />
            <Loading open={loadingTable} msg="Carregando Estratégias..." />
            <MobileDetecter />
            <div className="col-lg-12">
                <Accordion defaultActiveKey="1">
                    <Card>
                        <Card.Header>
                            <div className="container-fluid">
                                <div
                                    className="row"
                                    style={{
                                        alignItems: 'space-between',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <ContextAwareToggle eventKey="0">
                                        <div
                                            style={{
                                                display: 'flex',
                                                direction: 'row',
                                                alignItems: 'center',
                                                color: '#d36233'
                                            }}
                                            onClick={collapse}
                                        >
                                            <ion-icon
                                                size="large"
                                                name="options-outline"
                                                style={{
                                                    color: '#d36233',
                                                    marginRight: 15
                                                }}
                                            />{' '}
                                            FILTROS
                                        </div>
                                    </ContextAwareToggle>
                                    <div
                                        style={{
                                            display: 'flex',
                                            direction: 'row',
                                            justifyContent: 'end'
                                        }}
                                    >
                                        <ContextAwareToggle eventKey="0">
                                            {expand ? (
                                                <span
                                                    title=""
                                                    onClick={collapse}
                                                    style={{
                                                        display: 'flex',
                                                        height: '100%'
                                                    }}
                                                >
                                                    <ion-icon
                                                        className={
                                                            classes.ionIcon
                                                        }
                                                        id="collapse"
                                                        size="large"
                                                        name="chevron-down-outline"
                                                        style={{
                                                            color: '#d36233',
                                                            pointerEvents:
                                                                'none'
                                                        }}
                                                    />
                                                </span>
                                            ) : (
                                                <span
                                                    title=""
                                                    onClick={collapse}
                                                    style={{
                                                        display: 'flex',
                                                        height: '100%'
                                                    }}
                                                >
                                                    <ion-icon
                                                        id="collapse"
                                                        size="large"
                                                        name="chevron-back-outline"
                                                        style={{
                                                            color: '#d36233',
                                                            pointerEvents:
                                                                'none'
                                                        }}
                                                    />
                                                </span>
                                            )}
                                        </ContextAwareToggle>
                                    </div>
                                </div>
                            </div>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <RoexFilter
                                    data={cards}
                                    setData={setFilteredcards}
                                    active={active}
                                    ativFilter={cards.ativo}
                                />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>

            <div className="col-lg-12">
                <Accordion defaultActiveKey="0" style={{width: '100%'}}>
                    <Card>
                        <Card.Header>
                            <div className="container-fluid">
                                <div
                                    className="row"
                                    style={{
                                        alignItems: 'space-between',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <ContextAwareToggle eventKey="0">
                                        <div
                                            style={{
                                                display: 'flex',
                                                direction: 'row',
                                                alignItems: 'center',
                                                color: '#d36233'
                                            }}
                                            onClick={collapseStructure}
                                        >
                                            <ion-icon
                                                size="large"
                                                name="bar-chart-outline"
                                                style={{
                                                    color: '#d36233',
                                                    marginRight: 15
                                                }}
                                            />{' '}
                                            ESTRUTURA
                                        </div>
                                    </ContextAwareToggle>
                                    <div
                                        style={{
                                            display: 'flex',
                                            direction: 'row',
                                            justifyContent: 'end'
                                        }}
                                    >
                                        <ContextAwareToggle eventKey="0">
                                            {!expandStructure ? (
                                                <span
                                                    title=""
                                                    onClick={collapseStructure}
                                                    style={{
                                                        display: 'flex',
                                                        height: '100%'
                                                    }}
                                                >
                                                    <ion-icon
                                                        id="collapse"
                                                        size="large"
                                                        name="chevron-down-outline"
                                                        style={{
                                                            color: '#d36233',
                                                            pointerEvents:
                                                                'none'
                                                        }}
                                                    />
                                                </span>
                                            ) : (
                                                <span
                                                    title=""
                                                    onClick={collapseStructure}
                                                    style={{
                                                        display: 'flex',
                                                        height: '100%'
                                                    }}
                                                >
                                                    <ion-icon
                                                        id="collapse"
                                                        size="large"
                                                        name="chevron-back-outline"
                                                        style={{
                                                            color: '#d36233',
                                                            pointerEvents:
                                                                'none'
                                                        }}
                                                    />
                                                </span>
                                            )}
                                        </ContextAwareToggle>
                                    </div>
                                </div>
                            </div>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body
                            /*  style={{
                                    width: cards.length < 3 ? '30%' : 'auto',
                                    marginLeft:
                                        cards.length < 3 ? '-20px' : '0px'
                                }} */
                            >
                                <Slider
                                    {...settings}
                                    className={classes.slick}
                                    variableWidth
                                    style={{
                                        // width: '20%',
                                        height: '40%',
                                        display: 'flex',
                                        marginBottom: 20,
                                        marginLeft: 20,
                                        alignItems: 'center'
                                        // justifyContent: 'flex-start'
                                    }}
                                >
                                    {api.currentUser &&
                                    api.currentSession &&
                                    cards.length !== 0
                                        ? filteredCards.map((c) => (
                                              <div
                                                  key={c.id}
                                                  style={{
                                                      display: 'flex',
                                                      justifyContent:
                                                          'flex-start'
                                                  }}
                                              >
                                                  <Grid
                                                      style={{
                                                          margin: '10px 50px 0px 10px'
                                                      }}
                                                  >
                                                      <RoexCards
                                                          card={c}
                                                          setCards={setCards}
                                                          cards={cards}
                                                          status={c.status}
                                                          returnSelected={
                                                              returnSelected
                                                          }
                                                          nowSelected={
                                                              nowSelected
                                                          }
                                                          setHighlight={
                                                              setHighlight
                                                          }
                                                          filtered={
                                                              filteredCards
                                                          }
                                                      />
                                                  </Grid>
                                              </div>
                                          ))
                                        : emptyCards.map((a) => {
                                              return (
                                                  <div
                                                      key={a}
                                                      style={{
                                                          justifyContent:
                                                              'space-between'
                                                      }}
                                                  >
                                                      <Grid
                                                          key={a}
                                                          style={{
                                                              margin: '10px 50px 0px 10px'
                                                          }}
                                                      >
                                                          <EmptyCards />
                                                      </Grid>
                                                  </div>
                                              );
                                          })}
                                </Slider>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '10px 10px'
                }}
            >
                <span>
                    <RoexRolagem
                        titulo="ROLAGEM"
                        icone="sync-circle-outline"
                        notify={notify}
                        card={selected}
                        cards={cards}
                        setCards={setCards}
                        setCurrentCard={setCurrentCard}
                        rolagem={rolagem}
                        diffVenc={diffVenc}
                        ativo={ativo}
                        size={size}
                        selic={selic}
                        ilimitado={ilimitado}
                    />
                </span>
                <span>
                    <RoexExercicio
                        titulo="EXERCÍCIO"
                        icone="golf-outline"
                        notify={notify}
                        card={selected}
                        cards={cards}
                        setCards={setCards}
                        size={size}
                    />
                </span>
                <span>
                    <RoexRemont
                        titulo="REMONTAGEM"
                        icone="bar-chart-outline"
                        notify={notify}
                        card={selected}
                        cards={cards}
                        setCards={setCards}
                        remontagem={remontagem}
                        diffVenc={diffVenc}
                        ativo={ativo}
                        size={size}
                        selic={selic}
                        ilimitado={ilimitado}
                    />
                </span>
            </div>
            <Calculadora />

            <Toaster />
        </div>
    );
}
export default Roex;
