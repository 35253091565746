import React, {useState, useEffect} from 'react';
import moment from 'moment';

import {makeStyles, createStyles} from '@material-ui/core/styles';

import {cleanNumber} from '../../../utils/myaux';

const useStyles = makeStyles(() =>
    createStyles({
        myInput: {
            marginLeft: -15,
            color: '#e37039',
            fontSize: 12,
            padding: 0,
            margin: 0,
            border: 'none',
            maxWidth: 50,
            '&:focus': {
                outline: 'none'
            },
            textAlign: 'center'

            // fontWeight: 'bold'
        }
    })
);

export default function TotalMont({strategy, montCot, setMontCot}) {
    const classes = useStyles();
    const [total, setTotal] = useState(0);
    const [date, setDate] = useState(new Date());
    const [templateCot, setTemplateCot] = useState();
    // const [cot, setCot] = useState(0);

    useEffect(() => {
        if (strategy) {
            let tot = 0;
            let myDate = new Date(strategy[0]?.dataMont);
            strategy.forEach((s) => {
                tot += cleanNumber(s.total);
                myDate = moment(s.dataMont).isBefore(myDate)
                    ? s.dataMont
                    : myDate;
            });
            setDate(myDate);
            setTotal(tot);
        }
    }, [strategy]);

    useEffect(() => {
        if (montCot) {
            setTemplateCot(montCot);
        }
    }, [montCot]);

    /* const mCot = (v) => {
        const t = v.replace('R$', '');
        const t1 = t.replace('R', '');
        setMontCot(t1.trim());
    }; */

    const changeCotacao = (event) => {
        const t = event.target.value?.replace('R$', '');
        const t1 = t.replace('R', '');
        setMontCot(t1.trim());
    };

    function handleKeyUp(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            event.target.blur();
        }
    }

    return (
        <div
            style={{
                display: 'flex',
                // marginTop: '30px',
                fontSize: '12px',
                justifyContent: 'start',
                borderTop: '1px solid #c5c5c5',
                padding: 0,
                fontFamily: 'Roboto'
            }}
        >
            <div
                style={{
                    borderRight: '2px solid #c5c5c5',
                    textAlign: 'center',
                    minWidth: '85px'
                }}
            >
                <span
                    style={{
                        color: '#e37039',
                        fontWeight: 'bold',
                        display: 'inline-block',
                        margin: '0px 10px 0px 5px'
                    }}
                >
                    {moment(date).format('DD/MM/YYYY')}
                </span>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <p style={{margin: '0px 0px 0px 0px'}}>DATA</p>
                    <span
                        style={{cursor: 'pointer'}}
                        data-tip="Data em que a carteira iniciou, por padrão consideramos o registro mais antigo da carteira."
                    >
                        <ion-icon
                            style={{
                                marginBottom: 2,
                                fontSize: 12,
                                color: '#d36233',
                                pointerEvents: 'none'
                            }}
                            name="help-circle-outline"
                        />
                    </span>
                </div>
            </div>
            <div
                style={{
                    borderRight: '2px solid #c5c5c5',
                    textAlign: 'center',
                    minWidth: '85px'
                }}
            >
                <span
                    style={{
                        color: '#e37039',
                        fontWeight: 'bold',
                        display: 'inline-block',
                        margin: '0px 10px 0px 5px'
                    }}
                >
                    {new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                    })
                        .format(total)
                        .replace(',', '.')
                        .replace('-', '')}
                </span>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <p style={{margin: '0px 0px 0px 0px'}}>
                        {Number(total) >= 0 ? 'DÉBITO' : 'CRÉDITO'}
                    </p>
                    <span
                        style={{cursor: 'pointer'}}
                        data-tip="Crédito - a estrutura registrada resultou em recebimento financeiro. Débito - para montar a estrutura foi necessário desembolso financeiro."
                    >
                        <ion-icon
                            style={{
                                marginBottom: 2,
                                fontSize: 12,
                                color: '#d36233',
                                pointerEvents: 'none'
                            }}
                            name="help-circle-outline"
                        />
                    </span>
                </div>
            </div>
            <div
                style={{
                    borderRight: '2px solid #c5c5c5',
                    textAlign: 'center',
                    minWidth: '85px'
                }}
            >
                {/* <span
                    style={{
                        color: '#e37039',
                        fontWeight: 'bold',
                        display: 'inline-block',
                        margin: '0px 10px 0px 5px'
                    }}
                >
                    R$15,29
                </span> */}
                <input
                    className={classes.myInput}
                    // value={`R$ ${montCot}`}
                    value={`R$${templateCot
                        ?.replace(',', '.')
                        .replace('R$', '')}`}
                    onChange={(e) =>
                        setTemplateCot(e.target.value)
                            ?.replace('R$', '')
                            .replace(',', '.')
                    }
                    onBlur={changeCotacao}
                    onKeyUp={handleKeyUp}
                />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <p style={{margin: '0px 0px 0px 0px'}}>COTAÇÃO</p>
                    <span
                        style={{cursor: 'pointer'}}
                        data-tip="Cotação do ativo objeto no momento da montagem da carteira."
                    >
                        <ion-icon
                            style={{
                                marginBottom: 2,
                                fontSize: 12,
                                color: '#d36233',
                                pointerEvents: 'none'
                            }}
                            name="help-circle-outline"
                        />
                    </span>
                </div>
            </div>
            {/* <div
                style={{
                    borderRight: '2px solid #c5c5c5',
                    textAlign: 'center',
                    minWidth: '85px'
                }}
            >
                <span
                    style={{
                        color: '#e37039',
                        fontWeight: 'bold',
                        display: 'inline-block',
                        margin: '0px 10px 0px 5px',
                        textAlign: 'center'
                    }}
                >
                    R$25,00
                </span>
                <p style={{margin: '0px 1px 0px 5px'}}>LUCRO MAX</p>
            </div>
            <div
                style={{
                    textAlign: 'center',
                    minWidth: '85px'
                }}
            >
                <span
                    style={{
                        color: '#e37039',
                        fontWeight: 'bold',
                        display: 'inline-block',
                        margin: '0px 10px 0px 5px',
                        textAlign: 'center'
                    }}
                >
                    R$25,00
                </span>
                <p style={{margin: '0px 1px 0px 5px'}}>PREJUIZO MAX</p>
            </div> */}
        </div>
    );
}
