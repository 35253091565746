/* eslint-disable prefer-const */
/* eslint-disable guard-for-in */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-destructuring */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, {useState, useEffect} from 'react';
import {Grid} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import moment from 'moment';

import Slider from 'react-slick';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import _, {forEach, reverse} from 'lodash';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const useStyles = makeStyles((theme) =>
    createStyles({
        myButton: {
            color: '#2a3176',
            backgroundColor: '#f4f4f4',
            fontWeight: 'bold'
        },
        myBox: {
            marginLeft: theme.spacing(1.2),
            marginBottom: -18
        },
        myGrid: {
            minWidth: 90,
            maxHeight: 30,
            maxWidth: 90,
            margin: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            textAlign: 'center',
            cursor: 'pointer',
            fontSize: 14,
            border: '2px solid #c5c5c5'
        },
        myGridSelected: {
            minWidth: 90,
            maxWidth: 90,
            maxHeight: 30,
            backgroundColor: '#e37039',
            margin: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 14,
            display: 'flex',
            textAlign: 'center',
            cursor: 'pointer',
            border: '2px solid #e37039',
            color: '#fff'
        },
        myLabel: {
            color: '#d36233',
            fontSize: 14
        },
        myLabelSelected: {
            padding: theme.spacing(6),
            color: '#fff',
            fontWeight: 'bold'
        },
        ionIcon: {
            '& .ion-icon:before': {
                content: '',
                position: 'absolute',
                width: '100%',
                height: '100%'
            }
        },

        mySlider: {
            '& .slick-dots li.slick-active button:before': {
                color: '#e37039'
            }
        }
    })
);

const settings = {
    dots: true,
    infinite: true,
    speed: 400,
    slidesToShow: 5,
    slidesToScroll: 3
};

function ValueLabelComponent(props) {
    const {children, open, value} = props;
    if (children.props['data-index'] === 1) {
        return (
            <Tooltip
                open={open}
                enterTouchDelay={0}
                title={value}
                arrow
                // style={{backgroundColor: '#e37039', color: '#e37039'}}
                // placement="center"
                placement="top"
            >
                {children}
            </Tooltip>
        );
    }
    return (
        <Tooltip
            open={open}
            enterTouchDelay={0}
            title={value}
            arrow
            // style={{backgroundColor: '#e37039', color: '#e37039'}}
            // placement="center"
            placement="bottom"
        >
            {children}
        </Tooltip>
    );
}

ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired
};

// eslint-disable-next-line no-unused-vars
export default function Filters({data, setData, active, ativFilters}) {
    const classes = useStyles();
    const atual = moment().month();
    // eslint-disable-next-line no-unused-vars
    const [months, setMonths] = useState([
        {
            month: 'JANEIRO',
            status: !!(atual === 0 || atual - 1 === 0)
        },
        {
            month: 'FEVEREIRO',
            status: !!(atual === 1 || atual - 1 === 1)
        },
        {
            month: 'MARÇO',
            status: !!(atual === 2 || atual - 1 === 2)
        },
        {
            month: 'ABRIL',
            status: !!(atual === 3 || atual - 1 === 3)
        },
        {
            month: 'MAIO',
            status: !!(atual === 4 || atual - 1 === 4)
        },
        {
            month: 'JUNHO',
            status: !!(atual === 5 || atual - 1 === 5)
        },
        {
            month: 'JULHO',
            status: !!(atual === 6 || atual - 1 === 6)
        },
        {
            month: 'AGOSTO',
            status: !!(atual === 7 || atual - 1 === 7)
        },
        {
            month: 'SETEMBRO',
            status: !!(atual === 8 || atual - 1 === 8)
        },
        {
            month: 'OUTUBRO',
            status: !!(atual === 9 || atual - 1 === 9)
        },
        {
            month: 'NOVEMBRO',
            status: !!(atual === 10 || atual - 1 === 10)
        },
        {
            month: 'DEZEMBRO',
            status: !!(atual === 11 || atual - 1 === 11)
        }
    ]);
    const [ativo, setAtivo] = useState([
        {
            label: 'PETR4',
            status: true
        },
        {label: 'VALE3', status: false},
        {label: 'BOVA11', status: false}
    ]);
    const [status, setStatus] = useState([
        {
            label: 'ABERTA',
            status: true
        },
        {label: 'FECHADA', status: false}
    ]);
    const [lucro, setLucro] = useState([
        {
            label: 'CRESCENTE',
            status: true
        },
        {label: 'DECRESCENTE', status: false}
    ]);
    const [size, setSize] = useState(window.innerWidth);
    const myVenc = data[active]?.filters?.history?.find((e) => e.status).label;

    const inTheMonth = (mydata, list) => {
        const month = moment(mydata.filterDataMont).month();
        months.forEach((fm, i) => {
            if (fm.status) {
                if (i === month) {
                    list.push(mydata);
                }
            }
        });
    };

    useEffect(() => {
        if (data) {
            const listAtivo = [];
            const onlyOneAtivo = [];
            let listUnicoAtivo;
            data.forEach((d) => {
                onlyOneAtivo.push(d.ativo);
            });
            const reverseListAtivo = onlyOneAtivo.reverse();
            listUnicoAtivo = [...new Set(reverseListAtivo)];
            if (listUnicoAtivo.length >= 1) {
                listUnicoAtivo.forEach((a, indice) => {
                    if (indice === 0) listAtivo.push({label: a, status: true});
                    else listAtivo.push({label: a, status: false});
                });
            }

            setAtivo(listAtivo);
        }
    }, [data]);

    useEffect(() => {
        const listFiltered = [];
        data.forEach((d) => {
            ativo.forEach((a) => {
                if (a.status) {
                    if (a.label === d.ativo) inTheMonth(d, listFiltered);
                }
            });
        });

        let resultFiltered;
        if (status[0].status && status[1].status) {
            resultFiltered = listFiltered;
        } else if (status[0].status && !status[1].status) {
            resultFiltered = listFiltered.filter((l) => l.status === true);
        } else {
            resultFiltered = listFiltered.filter((l) => l.status === false);
        }

        const order = lucro.find((l) => l.status === true);

        resultFiltered.sort(function (a, b) {
            return new Date(b.created_at) - new Date(a.created_at);
        });

        if (order.label === 'CRESCENTE') {
            resultFiltered.sort((a, b) => a.lucro - b.lucro);
            // listFiltered.sort((a, b) => a.lucro - b.lucro);
        } else {
            resultFiltered.sort((a, b) => b.lucro - a.lucro);
            // listFiltered.sort((a, b) => b.lucro - a.lucro);
        }

        setData(resultFiltered);
    }, [lucro, ativo, months, data, status]);

    const changeAtivo = (e) => {
        const orig = _.cloneDeep(ativo);
        const find = orig.find((el) => el.label === e.label);
        if (!e.status) {
            find.status = !e.status;
        } else {
            find.status = !find.status;
        }
        setAtivo(orig);
    };

    const changeMonth = (e) => {
        const orig = _.cloneDeep(months);
        orig.forEach((el) => {
            if (el.month !== e.month && el.status) {
                const find = orig.find((o) => o.month === e.month);
                find.status = !e.status;
            }
        });
        setMonths(orig);
    };

    const changeLucro = (e) => {
        if (!e.status) {
            const orig = _.cloneDeep(lucro);
            const other = orig.find((el) => el.label !== e.label);
            const my = orig.find((el) => el.label === e.label);
            my.status = !e.status;
            other.status = e.status;
            setLucro(orig);
        }
    };

    const changeStatus = (e) => {
        const orig = _.cloneDeep(status);
        const find = orig.find((el) => el.label === e.label);
        if (!e.status) {
            find.status = !e.status;
        } else if (orig[0].status && orig[1].status) {
            find.status = !find.status;
        }
        setStatus(orig);
    };

    window.addEventListener('resize', () => {
        setSize(window.innerWidth);
    });

    return (
        <>
            <div
                style={{
                    marginTop: -10,
                    height: 60
                    /* display: 'flex',
                    width: '60%' */
                }}
            >
                <div
                    style={{
                        display: 'flex'
                    }}
                >
                    <div>
                        <ReactTooltip />
                        <ReactTooltip id="montagem" place="right" />
                        <div className={classes.myBox} style={{marginLeft: 5}}>
                            <label className={classes.myLabel}>ATIVOS</label>
                            <span
                                style={{cursor: 'pointer'}}
                                data-tip="ATIVOS - Ações utilizadas nas carteiras registradas"
                            >
                                <ion-icon
                                    style={{
                                        marginBottom: 2,
                                        fontSize: 12,
                                        color: '#d36233',
                                        pointerEvents: 'none'
                                    }}
                                    name="help-circle-outline"
                                />
                            </span>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'center',
                                marginTop: '8px',
                                width: window.innerWidth >= 1920 ? 520 : 595
                            }}
                        >
                            {ativo.length > 5 ? (
                                <Slider
                                    className={classes.mySlider}
                                    {...settings}
                                    variableWidth
                                    arrows
                                    style={{
                                        width: 540,
                                        height: '40%',
                                        // display: 'flex',
                                        marginBottom: 20,
                                        marginTop: 2
                                        // marginLeft: 35
                                        // alignItems: 'center'
                                    }}
                                >
                                    {ativo.map((s) => (
                                        <Grid
                                            key={s.label}
                                            className={
                                                s.status
                                                    ? classes.myGridSelected
                                                    : classes.myGrid
                                            }
                                            onClick={() => changeAtivo(s)}
                                        >
                                            {s.label}
                                        </Grid>
                                    ))}
                                </Slider>
                            ) : (
                                ativo.map((s) => (
                                    <Grid
                                        key={s.label}
                                        className={
                                            s.status
                                                ? classes.myGridSelected
                                                : classes.myGrid
                                        }
                                        onClick={() => changeAtivo(s)}
                                    >
                                        {s.label}
                                    </Grid>
                                ))
                            )}
                        </div>
                    </div>
                    <div
                        style={{
                            marginLeft: window.innerWidth >= 1920 ? '6.6%' : 41
                        }}
                    >
                        <div className={classes.myBox}>
                            <label className={classes.myLabel}>STATUS</label>
                            <span
                                style={{cursor: 'pointer'}}
                                data-tip="Status: Aberto - carteiras registradas ainda em andamento. Fechado -
                                carteiras registradas encerradas."
                            >
                                <ion-icon
                                    style={{
                                        marginBottom: 2,
                                        fontSize: 12,
                                        color: '#d36233',
                                        pointerEvents: 'none'
                                    }}
                                    name="help-circle-outline"
                                />
                            </span>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'center'
                            }}
                        >
                            {status.map((l) => (
                                <Grid
                                    key={l.label}
                                    className={
                                        l.status
                                            ? classes.myGridSelected
                                            : classes.myGrid
                                    }
                                    onClick={() => changeStatus(l)}
                                >
                                    {l.label}
                                </Grid>
                            ))}
                        </div>
                    </div>
                    <div
                        style={{
                            marginLeft:
                                window.innerWidth >= 1920
                                    ? '11.8%'
                                    : window.innerWidth >= 1600
                                    ? '14.5%'
                                    : '8.7%'
                        }}
                    >
                        <div className={classes.myBox}>
                            <label className={classes.myLabel}>LUCRO</label>
                            <span
                                style={{cursor: 'pointer'}}
                                data-tip="LUCRO - Filtro para carteiras utilizando Lucro/Prejuízo
                                como referência."
                            >
                                <ion-icon
                                    style={{
                                        marginBottom: 2,
                                        fontSize: 12,
                                        color: '#d36233',
                                        pointerEvents: 'none'
                                    }}
                                    name="help-circle-outline"
                                />
                            </span>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'center'
                            }}
                        >
                            {lucro.map((l) => (
                                <Grid
                                    key={l.label}
                                    className={
                                        l.status
                                            ? classes.myGridSelected
                                            : classes.myGrid
                                    }
                                    onClick={() => changeLucro(l)}
                                >
                                    {l.label}
                                </Grid>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Grid
                container
                spacing={1}
                style={{
                    marginTop: 10,
                    height: 60,
                    marginBottom:
                        data.length > 0 &&
                        data[active]?.values[myVenc]?.venc?.length > 0 &&
                        -20,
                    justifyContent: 'space-between'
                }}
            >
                <Grid item xs={12}>
                    <>
                        <Grid
                            item
                            sm={12}
                            style={{marginBottom: -10, marginLeft: 5}}
                        >
                            <label className={classes.myLabel}>MONTAGEM</label>
                            <span
                                data-for="montagem"
                                style={{cursor: 'pointer'}}
                                data-tip="MONTAGEM - Mês de montagem da carteira registrada"
                            >
                                <ion-icon
                                    style={{
                                        marginBottom: 2,
                                        fontSize: 12,
                                        color: '#d36233',
                                        pointerEvents: 'none'
                                    }}
                                    name="help-circle-outline"
                                />
                            </span>
                        </Grid>
                        <Grid
                            container
                            style={{
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'center'
                            }}
                        >
                            {months.map((m) => (
                                <Grid
                                    key={m.month}
                                    item
                                    className={
                                        m.status
                                            ? classes.myGridSelected
                                            : classes.myGrid
                                    }
                                    onClick={() => changeMonth(m)}
                                >
                                    {m.month}
                                </Grid>
                            ))}
                        </Grid>
                    </>
                </Grid>
            </Grid>
        </>
    );
}
