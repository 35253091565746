/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import React from 'react';

export default function WalletTooltip({
    active,
    payload,
    label,
    cot,
    ativo,
    chartTooltip,
    mouseX
}) {
    if (active && payload && payload.length && cot && ativo) {
        /* console.log(active);
        console.log(payload);
        console.log(chartTooltip); 
         payload.forEach((array) => {
            if (array.name === chartTooltip) { */

        // console.log(mouseX);
        /* const closestPoint = payload.reduce((prev, curr) => {
            if (!prev) return curr;
            return Math.abs(curr.coordinate.x - mouseX) <
                Math.abs(prev.coordinate.x - mouseX)
                ? curr
                : prev;
        }); */

        // console.log(closestPoint);

        return (
            <div
                style={{
                    backgroundColor: '#fff',
                    marginLeft: '5px'
                }}
            >
                <div>{ativo}</div>
                <div>Cotação referência: {cot.replace('.', ',')}</div>
                <div
                    style={{
                        display: 'flex'
                        // justifyContent: 'space-between'
                    }}
                >
                    Cotação:
                    {(payload[0].payload.cotacao * 100) / cot - 100 >= 0 ? (
                        <div style={{display: 'flex', color: '#028A0F'}}>
                            <div style={{marginLeft: '4px'}}>R$</div>
                            <div style={{marginLeft: '2px'}}>
                                {new Intl.NumberFormat('pt-BR', {
                                    minimumFractionDigits: 2,
                                    currency: 'BRL'
                                }).format(payload[0].payload.cotacao)}
                            </div>
                            <div
                                style={{
                                    marginLeft: '5px'
                                }}
                            >
                                {' '}
                                +
                                {(
                                    (payload[0].payload.cotacao * 100) / cot -
                                    100
                                )
                                    .toFixed(2)
                                    .replace('.', ',')}
                                %
                            </div>
                        </div>
                    ) : (
                        <div style={{display: 'flex', color: '#FF0000'}}>
                            <div style={{marginLeft: '4px'}}>R$</div>
                            <div style={{marginLeft: '2px'}}>
                                {new Intl.NumberFormat('pt-BR', {
                                    minimumFractionDigits: 2,
                                    currency: 'BRL'
                                }).format(payload[0].payload.cotacao)}
                            </div>
                            <div
                                style={{
                                    marginLeft: '5px'
                                }}
                            >
                                {(
                                    (payload[0].payload.cotacao * 100) / cot -
                                    100
                                )
                                    .toFixed(2)
                                    .replace('.', ',')}
                                %
                            </div>
                        </div>
                    )}
                </div>
                <div>
                    {payload.map((pld) => (
                        <div key={pld.name}>
                            <div
                                style={{
                                    display: 'flex'
                                }}
                            >
                                <div style={{color: pld.stroke}}>
                                    {pld.name}:
                                </div>
                                <div>
                                    {pld.value > 0 ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                marginLeft: '5px',
                                                color: '#028A0F'
                                            }}
                                        >
                                            R$
                                            <p
                                                style={{
                                                    marginLeft: '2px'
                                                }}
                                            >
                                                {new Intl.NumberFormat(
                                                    'pt-BR',
                                                    {
                                                        minimumFractionDigits: 2,
                                                        currency: 'BRL'
                                                    }
                                                ).format(pld.value)}
                                            </p>
                                        </div>
                                    ) : (
                                        <div
                                            style={{
                                                display: 'flex',
                                                marginLeft: '5px',
                                                color: '#FF0000'
                                            }}
                                        >
                                            R$
                                            {new Intl.NumberFormat('pt-BR', {
                                                minimumFractionDigits: 2,
                                                currency: 'BRL'
                                            }).format(pld.value)}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
        /* }
            return null; 
          }); */
    }
    return null;
}
