/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable dot-notation */
import React, {useState, useEffect} from 'react';
import {
    Modal,
    Avatar,
    Button,
    TextField,
    Checkbox,
    Box,
    Slider
} from '@material-ui/core';
import {Card} from 'react-bootstrap';
import Select from 'react-select';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';
/* 
import arte from '../img/arte_email_opti.png'; */
import botaoAssinar from '../img/botaoAssinar.avif';
import botaoAssinarHover from '../img/botaoAssinarHover.avif';
import logo from '../img/OPTI_logo.avif';
import fundo from '../img/fundo.avif';
import titulo from '../img/planosAssinaturasOpti.avif';

import '../components/ProfilePicture.css';

import routing from '../routes/routing';
import PlanosButton from './PlanosButton';
import ProfilePicture from '../components/ProfilePicture';
import RecoverPassword from '../components/RecoverPassword';

import api from '../utils/api';
import states from '../utils/states';
import countries from '../utils/countries';
import CancelSub from '../components/CancelSub';
import Norte from '../utils/citiesNorte';
import Nordeste from '../utils/citiesNordeste';
import Centro from '../utils/citiesCentro';
import Sudeste from '../utils/citiesSudeste';
import Sul from '../utils/citiesSul';

import './Perfil.css';

const styles = {
    control: (css) => ({
        ...css,
        height: 40,
        width: 340,
        color: '#e37039',
        border: 'none',
        background: '#cac6c2',
        boxShadow: 'none',
        borderRadius: '20px'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#e37039'
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#e37039'
    }),
    menu: ({width, ...css}) => ({
        ...css,
        marginTop: 16,
        width: 300,
        fontSize: 12,
        minWidth: '10%'
    }),
    container: (base) => ({
        ...base,
        minWidth: 340,
        maxWidth: 340,
        alignItems: 'center',
        justifyContent: 'center'
    }),
    valueContainer: (base) => ({
        ...base,
        width: 340,
        boxShadow: 'none',
        color: '#e37039'
    }),
    option: (css, state) => ({
        ...css,
        color: '#e37039',
        width: 280,
        backgroundColor: state.isSelected
            ? ''
            : '' || state.isHovered
            ? '#cac6c2'
            : 'transparent',
        '&:hover': {
            backgroundColor: '#cac6c2'
        }
    }),
    input: (base) => ({
        ...base,
        color: '#e37039',
        display: 'flex',
        fontWeight: 700,
        height: 25,
        alignItems: 'center'
    }),
    dropdownIndicator: (base) => ({
        ...base,
        color: '#e37039',
        width: 40
    })
};

export default function Perfil() {
    const useStyles = makeStyles(() =>
        createStyles({
            profilePicture: {
                width: '160px',
                height: '160px',
                fontSize: 50,
                backgroundColor: '#e37039'
            },
            myButton: {
                width: '220px',
                height: '40px',
                backgroundColor: '#f4f5f7',
                color: '#e37039',
                fontSize: 18,
                // fontWeight: 700,
                margin: '20px 0px 20px 0px'
            },
            cancel: {
                width: '230px',
                backgroundColor: '#cac6c2',
                color: '#000',
                fontSize: 14,
                // fontWeight: 700,
                margin: '20px 0px 20px 0px',
                borderRadius: '10px'
            },
            close: {
                borderRadius: '50%'
            },
            myTextField: {
                backgroundColor: '#cac6c2',
                color: '#e37039',
                width: '340px',
                height: '40px',
                borderRadius: '20px'
            },
            disabled: {
                backgroundColor: '#edeceb',
                width: '340px',
                height: '40px',
                borderRadius: '20px',
                cursor: 'not-allowed'
            },
            myInput: {
                disableUnderline: true,
                marginLeft: '15px',
                width: '320px',
                height: '40px',
                color: '#e37039',
                fontSize: 18
            },
            myInputDisabled: {
                disableUnderline: true,
                marginLeft: '15px',
                marginTop: '-10px',
                width: '340px',
                height: '0px',
                cursor: 'not-allowed'
            },
            myPlano: {
                backgroundColor: '#e37039',
                color: '#cac6c2',
                width: '375px',
                height: '40px',
                borderRadius: '20px',
                textAlign: 'center'
            },
            myInputPlano: {
                disableUnderline: true,
                height: '40px',
                color: '#cac6c2',
                fontSize: 18
            },
            inputSelect: {
                // disableUnderline: true,
                // height: '40px',
                maxWidth: '290px',
                marginLeft: '10px',
                marginTop: '5px',
                color: '#e37039',
                fontSize: 18
            },
            mySelect: {
                backgroundColor: '#cac6c2',
                color: '#e37039',
                width: '340px',
                height: '40px',
                borderRadius: '20px'
            },
            root: {
                borderRadius: '5px',
                '& .MuiSlider-rail': {
                    height: 15,
                    borderRadius: '5px'
                    // paddingLeft: '10px'
                },
                '& .MuiSlider-track': {
                    height: 15,
                    borderRadius: '5px'
                    // paddingLeft: '10px'
                },
                '& .MuiSlider-thumb': {
                    height: 25,
                    width: 6,
                    color: '#e37039',
                    // marginLeft: '0.5px',
                    borderRadius: '1px',
                    // boxShadow: '0 0 0 8px #e37039',
                    '&:hover': {
                        boxShadow: '0 0 0 6px rgb(227, 112, 57, 0.16)'
                    },
                    '&.Mui-focusVisible': {
                        boxShadow: '0 0 0 6px rgb(227, 112, 57, 0.16)'
                    }
                }
            }
        })
    );
    const history = useHistory();
    const classes = useStyles();
    const [cpf, setCpf] = useState();
    const [phone, setPhone] = useState();
    const [state, setState] = useState();
    const [city, setCity] = useState();
    const [country, setCountry] = useState();
    const [gender, setGender] = useState();
    const [photo, setPhoto] = useState(null);
    const [list, setList] = useState();
    const [listStates, setListStates] = useState();
    const [listCity, setListCity] = useState();
    const [open, setOpen] = useState(false);
    const [confirm, setConfirm] = useState(false);
    const [changePic, setChangePic] = useState(false);
    const [origProfile, setOrigProfile] = useState();
    const [profile, setProfile] = useState();
    const [forgotPassword, setForgotPassword] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    // const [selectedFile, setSelectedFile] = useState(null);

    let names;
    let initials;
    const getInitials = function (string) {
        names = string.split(' ');
        initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    };

    const handleKeyUp = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            event.target.blur();
        }
    };

    const closeModal = () => setOpen(false);

    const handleClose = (event, reason) => {
        if (reason && reason === 'backdropClick') setChangePic(false);
    };

    const handleChange = (event, key) => {
        const tmpProfile = _.cloneDeep(profile);
        tmpProfile[key] = event.target ? event.target.value : event.value;
        setProfile(tmpProfile);
    };

    const handleSlider = (e, value) => {
        const tmpProfile = _.cloneDeep(profile);
        tmpProfile['nivel'] = value;
        setProfile(tmpProfile);
    };

    const marks = [
        {
            value: 0,
            label: 'BÁSICO'
        },
        {
            value: 50,
            label: 'INTERMEDIÁRIO'
        },
        {
            value: 100,
            label: 'AVANÇADO'
        }
    ];

    const genderOptions = [
        {
            value: 'Masculino',
            label: 'Masculino'
        },
        {
            value: 'Fêminino',
            label: 'Fêminino'
        },
        {
            value: 'Não binário',
            label: 'Não binário'
        },
        {
            value: 'Outro',
            label: 'Outro'
        },
        {
            value: 'Prefiro não informar',
            label: 'Prefiro não informar'
        }
    ];

    const valuetext = (value) => {
        return value;
    };

    const formataCPF = (value) => {
        if (value)
            return value
                .replace(/\D/g, '')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d{1,2})/, '$1-$2')
                .replace(/(-\d{2})\d+?$/, '$1');
        return '';
    };

    const phoneMask = (value) => {
        if (!value) return '';
        value = value.replace(/\D/g, '');
        value = value.replace(/(\d{2})(\d)/, '($1) $2');
        value = value.replace(/(\d)(\d{4})$/, '$1-$2');
        return value;
    };

    const recurrency = () => {
        /* api.assinatura
            .recurrencyOff(api.currentUser?.plano?.recurrency)
            .then((res) => {
                console.log(res);
            })
            .catch((e) => console.log(e)); */
        api.assinatura
            .pause(api.currentUser?.user_id)
            .then((res) => {
                console.log(res);
            })
            .catch((e) => console.log(e));
    };

    const handleSelectImage = (event) => {
        const file = new FormData();
        file.append('receipt_file', event.target.files[0]);
        file.append('user_id', api.currentUser.user_id);
        api.user
            .changeImage(file, api.currentUser.user_id)
            .then((res) => setPhoto(res))
            .catch((e) => console.log(e));
    };

    useEffect(() => {
        const user = api.currentUser;
        const template = {
            name: user.name,
            email: user.email,
            birth: user.birth,
            cpf: user.cpf,
            country: user.country,
            state: user.state,
            city: user.city,
            phone: user.phone,
            gender: user.gender,
            nivel: user.nivel
        };
        setCpf(user.cpf);
        setPhone(user.phone);
        setPhoto(user.photo);
        setOrigProfile(template);
        setCountry({label: user.country, value: user.country});
        setState({label: user.state, value: user.state});
        setCity({label: user.city, value: user.city});
        setGender({label: user.gender, value: user.gender});
        // if (user.nivel) setNivel(user.nivel);
        setProfile(template);
    }, []);

    useEffect(() => {
        const finalList = [];
        const finalStates = [];
        countries.forEach((l) => {
            finalList.push({value: l, label: l});
        });
        states.forEach((s) => {
            finalStates.push({value: s, label: s});
        });
        setList(finalList);
        setListStates(finalStates);
    }, [countries]);

    useEffect(() => {
        if (state) {
            if (state.value !== profile.state) setCity(null);
            const tmpCity = [];
            if (
                state.value === 'Rio Grande do Sul' ||
                state.value === 'Santa Catarina' ||
                state.value === 'Paraná'
            ) {
                Sul.forEach((s) => {
                    if (
                        state.value === 'Rio Grande do Sul' &&
                        s.state === 'RS'
                    ) {
                        tmpCity.push({value: s.value, label: s.value});
                    }
                    if (state.value === 'Santa Catarina' && s.state === 'SC') {
                        tmpCity.push({value: s.value, label: s.value});
                    }
                    if (state.value === 'Paraná' && s.state === 'PR') {
                        tmpCity.push({value: s.value, label: s.value});
                    }
                });
            } else if (
                state.value === 'São Paulo' ||
                state.value === 'Rio de Janeiro' ||
                state.value === 'Minas Gerais' ||
                state.value === 'Espírito Santo'
            ) {
                Sudeste.forEach((s) => {
                    if (state.value === 'São Paulo' && s.state === 'SP') {
                        tmpCity.push({value: s.value, label: s.value});
                    }
                    if (state.value === 'Rio de Janeiro' && s.state === 'RJ') {
                        tmpCity.push({value: s.value, label: s.value});
                    }
                    if (state.value === 'Minas Gerais' && s.state === 'MG') {
                        tmpCity.push({value: s.value, label: s.value});
                    }
                    if (state.value === 'Espírito Santo' && s.state === 'ES') {
                        tmpCity.push({value: s.value, label: s.value});
                    }
                });
            } else if (
                state.value === 'Mato Grosso do Sul' ||
                state.value === 'Mato Grosso' ||
                state.value === 'Goiás' ||
                state.value === 'Distrito Federal'
            ) {
                Centro.forEach((c) => {
                    if (
                        state.value === 'Mato Grosso do Sul' &&
                        c.state === 'MT'
                    ) {
                        tmpCity.push({value: c.value, label: c.value});
                    }
                    if (state.value === 'Mato Grosso' && c.state === 'MG') {
                        tmpCity.push({value: c.value, label: c.value});
                    }
                    if (state.value === 'Goiás' && c.state === 'GO') {
                        tmpCity.push({value: c.value, label: c.value});
                    }
                    if (
                        state.value === 'Distrito Federal' &&
                        c.state === 'DF'
                    ) {
                        tmpCity.push({value: c.value, label: c.value});
                    }
                });
            } else if (
                state.value === 'Pará' ||
                state.value === 'Roraima' ||
                state.value === 'Rondônia' ||
                state.value === 'Amazonas' ||
                state.value === 'Amapá' ||
                state.value === 'Acre' ||
                state.value === 'Tocantins'
            ) {
                Norte.forEach((n) => {
                    if (state.value === 'Pará' && n.state === 'PA') {
                        tmpCity.push({value: n.value, label: n.value});
                    }
                    if (state.value === 'Roraima' && n.state === 'RR') {
                        tmpCity.push({value: n.value, label: n.value});
                    }
                    if (state.value === 'Rondônia' && n.state === 'RO') {
                        tmpCity.push({value: n.value, label: n.value});
                    }
                    if (state.value === 'Amazonas' && n.state === 'AM') {
                        tmpCity.push({value: n.value, label: n.value});
                    }
                    if (state.value === 'Amapá' && n.state === 'AP') {
                        tmpCity.push({value: n.value, label: n.value});
                    }
                    if (state.value === 'Acre' && n.state === 'AC') {
                        tmpCity.push({value: n.value, label: n.value});
                    }
                    if (state.value === 'Tocantins' && n.state === 'TO') {
                        tmpCity.push({value: n.value, label: n.value});
                    }
                });
            } else {
                Nordeste.forEach((n) => {
                    if (state.value === 'Bahia' && n.state === 'BA') {
                        tmpCity.push({value: n.value, label: n.value});
                    }
                    if (state.value === 'Sergipe' && n.state === 'SE') {
                        tmpCity.push({value: n.value, label: n.value});
                    }
                    if (state.value === 'Alagoas' && n.state === 'AL') {
                        tmpCity.push({value: n.value, label: n.value});
                    }
                    if (state.value === 'Pernambuco' && n.state === 'PE') {
                        tmpCity.push({value: n.value, label: n.value});
                    }
                    if (state.value === 'Paraíba' && n.state === 'PB') {
                        tmpCity.push({value: n.value, label: n.value});
                    }
                    if (
                        state.value === 'Rio Grande do Norte' &&
                        n.state === 'RN'
                    ) {
                        tmpCity.push({value: n.value, label: n.value});
                    }
                    if (state.value === 'Ceará' && n.state === 'CE') {
                        tmpCity.push({value: n.value, label: n.value});
                    }
                    if (state.value === 'Piauí' && n.state === 'PI') {
                        tmpCity.push({value: n.value, label: n.value});
                    }
                    if (state.value === 'Maranhão' && n.state === 'MA') {
                        tmpCity.push({value: n.value, label: n.value});
                    }
                });
            }
            setListCity(tmpCity);
        }
    }, [state]);

    useEffect(() => {
        if (JSON.stringify(profile) !== JSON.stringify(origProfile)) {
            const uid = api.currentUser.user_id;
            api.user
                .profileUpdate({
                    uid,
                    birth: profile.birth,
                    cpf: profile.cpf,
                    country: profile.country,
                    state: profile.state,
                    city: profile.city,
                    phone: profile.phone,
                    name: profile.name,
                    email: profile.email,
                    gender: profile.gender,
                    nivel: profile.nivel,
                    photo
                })
                .then((res) => {
                    console.log(res);
                    setOrigProfile(profile);
                })
                .catch((err) => console.log(err));
        }
    }, [profile]);

    return (
        <>
            <div
                style={{
                    backgroundImage: `url(${fundo})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    height: '64rem',
                    minWidth: '1360px',
                    maxWidth: '4096px',
                    margin: '-20px',
                    overflow: 'visible'
                }}
            >
                <div
                    style={{
                        marginLeft: window.innerWidth > 1650 ? '92%' : '88%'
                    }}
                >
                    <span style={{display: 'flex', paddingTop: '20px'}}>
                        <Button
                            className={classes.close}
                            onClick={() => history.goBack()}
                        >
                            <ion-icon
                                style={{fontSize: 54, color: '#e37039'}}
                                name="close-circle-outline"
                            />
                        </Button>
                    </span>
                </div>
                <div
                    style={{
                        display: 'flex',
                        // justifyContent: 'space-evenly',
                        // alignContent: 'center'
                        // width: '1360px',
                        marginLeft: window.innerWidth > 1650 ? '300px' : '50px'
                    }}
                >
                    <Card
                        style={{
                            width: '20rem',
                            height: '51.5rem',
                            borderRadius: '20px'
                        }}
                    >
                        <div
                            style={{
                                marginTop: '40px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center'
                            }}
                        >
                            <div>
                                <div
                                    style={{
                                        width: '180px',
                                        height: '180px',
                                        border: '10px solid #cac6c2',
                                        borderRadius: '50%'
                                    }}
                                >
                                    {!photo ? (
                                        <Avatar
                                            className={classes.profilePicture}
                                        >
                                            {getInitials(api.currentUser.name)}
                                        </Avatar>
                                    ) : (
                                        <Avatar
                                            className={classes.profilePicture}
                                            alt="Foto de Perfil"
                                            src={photo}
                                        />
                                    )}
                                </div>
                                <div
                                    style={{
                                        position: 'absolute',
                                        right: 100,
                                        top: 190
                                    }}
                                >
                                    <label htmlFor="arquivo">
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                backgroundColor: '#e37039',
                                                color: '#fff',
                                                maxWidth: 40,
                                                minWidth: 40,
                                                // height: 30,
                                                maxHeight: 40,
                                                minHeight: 40,
                                                borderRadius: '50%',
                                                cursor: 'pointer'
                                            }}
                                        >
                                            <ion-icon
                                                name="camera-outline"
                                                style={{
                                                    fontSize: 30,
                                                    width: 40
                                                }}
                                            />
                                        </div>
                                    </label>
                                    <input
                                        type="file"
                                        onChange={handleSelectImage}
                                        id="arquivo"
                                    />
                                </div>
                            </div>

                            <div style={{marginTop: '20px'}}>
                                <div style={{fontSize: 30}}>BEM-VINDO</div>
                                <div
                                    style={{
                                        marginTop: 15,
                                        fontSize: 26,
                                        fontWeight: 700,
                                        fontFamily: 'Roboto'
                                    }}
                                >
                                    {api.currentUser.name}
                                </div>
                            </div>
                            <Card
                                style={{
                                    width: '16rem',
                                    height: '16rem',
                                    backgroundColor: '#cac6c2',
                                    color: '#fff',
                                    marginTop: '60px',
                                    textAlign: 'center',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '20px'
                                }}
                            >
                                <div>
                                    <Button
                                        variant="contained"
                                        className={classes.myButton}
                                        onClick={() => setForgotPassword(true)}
                                    >
                                        ALTERAR SENHA
                                    </Button>

                                    <Button
                                        variant="contained"
                                        className={classes.myButton}
                                        onClick={() => {
                                            window.location.href =
                                                'mailto:contato@useopti.com.br';
                                        }}
                                    >
                                        FALE CONOSCO
                                    </Button>

                                    <Button
                                        variant="contained"
                                        className={classes.myButton}
                                        onClick={() => {
                                            history.push(routing.termos);
                                        }}
                                    >
                                        TERMOS DE USO
                                    </Button>
                                </div>
                            </Card>
                            <Button
                                variant="contained"
                                className={classes.cancel}
                                style={{marginTop: '60px'}}
                                onClick={() => setOpen(true) /* test() */}
                            >
                                CANCELAR ASSINATURA
                            </Button>
                        </div>
                    </Card>
                    {profile ? (
                        <>
                            <Card
                                style={{
                                    width: '24rem',
                                    height: '51.5rem',
                                    borderRadius: '20px',
                                    marginLeft: '50px'
                                }}
                            >
                                <div
                                    style={{
                                        marginTop: '30px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        marginLeft: '20px'
                                    }}
                                >
                                    <div style={{fontSize: 28}}>
                                        INFO PESSOAIS
                                    </div>
                                    <div
                                        style={{
                                            fontSize: 18,
                                            // fontWeight: 700,
                                            marginTop: '10px',
                                            fontFamily: 'Roboto'
                                        }}
                                    >
                                        <div style={{marginTop: '10px'}}>
                                            NOME
                                            <span
                                                style={{
                                                    color: '#e37039',
                                                    fontWeight: 400,
                                                    marginLeft: '4px'
                                                }}
                                            >
                                                *
                                            </span>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    margin: '0px 0px 0px -22px'
                                                }}
                                            >
                                                <TextField
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        className:
                                                            classes.myInput
                                                    }}
                                                    defaultValue={profile?.name}
                                                    variant="standard"
                                                    className={
                                                        classes.myTextField
                                                    }
                                                    onBlur={(event) =>
                                                        handleChange(
                                                            event,
                                                            'name'
                                                        )
                                                    }
                                                    onKeyUp={handleKeyUp}
                                                />
                                            </div>
                                        </div>
                                        <div style={{marginTop: '10px'}}>
                                            E-MAIL
                                            <span
                                                style={{
                                                    color: '#e37039',
                                                    fontWeight: 400,
                                                    marginLeft: '4px'
                                                }}
                                            >
                                                *
                                            </span>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    margin: '0px 0px 0px -22px'
                                                }}
                                            >
                                                <TextField
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        className:
                                                            classes.myInput
                                                    }}
                                                    defaultValue={
                                                        profile?.email
                                                    }
                                                    variant="standard"
                                                    className={
                                                        classes.myTextField
                                                    }
                                                    onBlur={(event) =>
                                                        handleChange(
                                                            event,
                                                            'email'
                                                        )
                                                    }
                                                    onKeyUp={handleKeyUp}
                                                />
                                            </div>
                                        </div>
                                        <div style={{marginTop: '10px'}}>
                                            DATA DE NASCIMENTO
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    margin: '0px 0px 0px -22px'
                                                }}
                                            >
                                                <TextField
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        className:
                                                            classes.myInput
                                                    }}
                                                    defaultValue={
                                                        profile?.birth
                                                    }
                                                    variant="standard"
                                                    className={
                                                        classes.myTextField
                                                    }
                                                    type="date"
                                                    onBlur={(event) =>
                                                        handleChange(
                                                            event,
                                                            'birth'
                                                        )
                                                    }
                                                    onKeyUp={handleKeyUp}
                                                />
                                            </div>
                                        </div>
                                        <div style={{marginTop: '10px'}}>
                                            CPF
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    margin: '0px 0px 0px -22px'
                                                }}
                                            >
                                                <TextField
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        className:
                                                            classes.myInput
                                                    }}
                                                    value={formataCPF(cpf)}
                                                    variant="standard"
                                                    className={
                                                        classes.myTextField
                                                    }
                                                    onChange={(event) =>
                                                        setCpf(
                                                            event.target.value
                                                        )
                                                    }
                                                    onBlur={(event) =>
                                                        handleChange(
                                                            event,
                                                            'cpf'
                                                        )
                                                    }
                                                    onKeyUp={handleKeyUp}
                                                />
                                            </div>
                                        </div>
                                        <div style={{marginTop: '10px'}}>
                                            PAÍS
                                            {list ? (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent:
                                                            'center',
                                                        margin: '0px 0px 0px -22px'
                                                    }}
                                                >
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        onChange={(e) => {
                                                            handleChange(
                                                                e,
                                                                'country'
                                                            );
                                                            if (
                                                                country?.value !==
                                                                e?.value
                                                            ) {
                                                                setState(null);
                                                                setCity(null);
                                                            }
                                                            setCountry(e);
                                                        }}
                                                        placeholder=""
                                                        // value={country}
                                                        defaultValue={
                                                            country || ''
                                                        }
                                                        isSearchable
                                                        styles={styles}
                                                        options={list}
                                                        components={{
                                                            IndicatorSeparator:
                                                                () => null
                                                        }}
                                                    />
                                                </div>
                                            ) : null}
                                        </div>
                                        <div style={{marginTop: '10px'}}>
                                            ESTADO
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    margin: '0px 0px 0px -22px'
                                                }}
                                            >
                                                {country?.value === 'Brasil' ? (
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        onChange={(e) => {
                                                            handleChange(
                                                                e,
                                                                'state'
                                                            );
                                                            if (
                                                                state?.value !==
                                                                e?.value
                                                            )
                                                                setCity(null);

                                                            setState(e);
                                                        }}
                                                        placeholder=""
                                                        defaultValue={
                                                            state || ''
                                                        }
                                                        // value={state}
                                                        isSearchable
                                                        styles={styles}
                                                        options={listStates}
                                                        components={{
                                                            IndicatorSeparator:
                                                                () => null
                                                        }}
                                                    />
                                                ) : (
                                                    <TextField
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            className:
                                                                classes.myInputDisabled
                                                        }}
                                                        variant="standard"
                                                        className={
                                                            classes.disabled
                                                        }
                                                        disabled
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div style={{marginTop: '10px'}}>
                                            CIDADE
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    margin: '0px 0px 0px -22px'
                                                }}
                                            >
                                                {listCity?.length > 0 ? (
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        onChange={(e) => {
                                                            handleChange(
                                                                e,
                                                                'city'
                                                            );
                                                            setCity(e);
                                                        }}
                                                        value={city}
                                                        placeholder=""
                                                        isSearchable
                                                        styles={styles}
                                                        options={listCity}
                                                        components={{
                                                            IndicatorSeparator:
                                                                () => null
                                                        }}
                                                    />
                                                ) : (
                                                    <TextField
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            className:
                                                                classes.myInputDisabled
                                                        }}
                                                        variant="standard"
                                                        className={
                                                            classes.disabled
                                                        }
                                                        disabled
                                                    />
                                                )}
                                            </div>
                                        </div>
                                        <div style={{marginTop: '10px'}}>
                                            CELULAR
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    margin: '0px 0px 0px -22px'
                                                }}
                                            >
                                                <TextField
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        className:
                                                            classes.myInput
                                                    }}
                                                    value={phoneMask(phone)}
                                                    variant="standard"
                                                    className={
                                                        classes.myTextField
                                                    }
                                                    onBlur={(event) =>
                                                        handleChange(
                                                            event,
                                                            'phone'
                                                        )
                                                    }
                                                    onChange={(event) =>
                                                        setPhone(
                                                            event.target.value
                                                        )
                                                    }
                                                    onKeyUp={handleKeyUp}
                                                />
                                            </div>
                                        </div>
                                        <div style={{marginTop: '10px'}}>
                                            GÊNERO
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    margin: '0px 0px 0px -22px'
                                                }}
                                            >
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    onChange={(e) => {
                                                        handleChange(
                                                            e,
                                                            'gender'
                                                        );
                                                        setGender(e);
                                                    }}
                                                    placeholder=""
                                                    defaultValue={gender || ''}
                                                    isSearchable
                                                    styles={styles}
                                                    options={genderOptions}
                                                    components={{
                                                        IndicatorSeparator:
                                                            () => null
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            marginTop: '5px',
                                            fontSize: 18,
                                            fontWeight: 700,
                                            marginLeft: '45%',
                                            color: '#e37039'
                                        }}
                                    >
                                        * Campos Obrigatórios
                                    </div>
                                </div>
                            </Card>

                            <div
                                style={{
                                    marginLeft: '50px'
                                }}
                            >
                                <Card
                                    style={{
                                        width: '26rem',
                                        height: '32rem',
                                        borderRadius: '20px'
                                    }}
                                >
                                    <div
                                        style={{
                                            marginTop: '30px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            marginLeft: '20px',
                                            fontSize: 18
                                        }}
                                    >
                                        <div style={{fontSize: 28}}>CONTA</div>
                                        {moment() <=
                                        moment(
                                            api.currentUser?.plano?.vencimento
                                        ) ? (
                                            <div>
                                                <div
                                                    style={{
                                                        fontSize: 18,
                                                        // fontWeight: 700
                                                        fontFamily: 'Roboto'
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            marginTop: '15px'
                                                        }}
                                                    >
                                                        PLANO
                                                        <div>
                                                            <div
                                                                style={{
                                                                    backgroundColor:
                                                                        '#d36233',
                                                                    color: '#cac6c2',
                                                                    width: '375px',
                                                                    height: '40px',
                                                                    borderRadius:
                                                                        '20px',
                                                                    textAlign:
                                                                        'center',
                                                                    display:
                                                                        'flex',
                                                                    justifyContent:
                                                                        'center',
                                                                    alignItems:
                                                                        'center',
                                                                    fontWeight:
                                                                        'bold'
                                                                }}
                                                            >
                                                                {api.currentUser
                                                                    ?.plano
                                                                    ?.plan ===
                                                                'MENSAL'
                                                                    ? 'Sardinha Mensal'
                                                                    : 'Sardinha Anual'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            marginTop: '15px'
                                                        }}
                                                    >
                                                        VENCIMENTO
                                                        <div>
                                                            <div
                                                                style={{
                                                                    backgroundColor:
                                                                        '#d36233',
                                                                    color: '#cac6c2',
                                                                    width: '375px',
                                                                    height: '40px',
                                                                    borderRadius:
                                                                        '20px',
                                                                    textAlign:
                                                                        'center',
                                                                    display:
                                                                        'flex',
                                                                    justifyContent:
                                                                        'center',
                                                                    alignItems:
                                                                        'center',
                                                                    fontWeight:
                                                                        'bold'
                                                                }}
                                                            >
                                                                {moment(
                                                                    api
                                                                        .currentUser
                                                                        ?.plano
                                                                        ?.vencimento
                                                                ).format(
                                                                    'DD/MM/YYYY'
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        marginLeft: '145px',
                                                        marginTop: '10px',
                                                        color: '#e37039',
                                                        fontFamily: 'Roboto',
                                                        fontWeight: 'bold'
                                                    }}
                                                >
                                                    <Checkbox
                                                        defaultChecked={
                                                            api.currentUser
                                                                ?.plano
                                                                ?.recurrency
                                                        }
                                                        style={{
                                                            color: '#e37039'
                                                        }}
                                                        onChange={recurrency}
                                                    />
                                                    <span>
                                                        renovação automática
                                                    </span>
                                                </div>
                                            </div>
                                        ) : (
                                            <div
                                                style={{
                                                    // margin: '40px',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    height: 200,
                                                    fontFamily: 'Roboto'
                                                }}
                                            >
                                                <button
                                                    type="button"
                                                    style={{
                                                        backgroundColor:
                                                            'transparent',
                                                        border: 0
                                                    }}
                                                    onClick={() =>
                                                        setOpenDialog(true)
                                                    }
                                                >
                                                    <img
                                                        src={botaoAssinar}
                                                        alt=""
                                                        width="200px"
                                                        onFocus=""
                                                        onBlur=""
                                                        onMouseOver={(e) => {
                                                            e.currentTarget.src =
                                                                botaoAssinarHover;
                                                        }}
                                                        onMouseOut={(e) => {
                                                            e.currentTarget.src =
                                                                botaoAssinar;
                                                        }}
                                                    />
                                                </button>
                                            </div>
                                        )}
                                    </div>

                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            borderTop: '5px solid darkgrey',
                                            margin: '10px 0px 0px 25px',
                                            width: '88%'
                                        }}
                                    />
                                    <div
                                        style={{
                                            marginTop: '20px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            marginLeft: '20px',
                                            fontSize: 24,
                                            width: '70%',
                                            color: '#e37039',
                                            fontFamily: 'Roboto'
                                        }}
                                    >
                                        NÍVEL DE CONHECIMENTO EM OPÇÕES
                                    </div>
                                    <div
                                        style={{
                                            marginTop: '10px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            marginLeft: '35px',
                                            color: '#e37039',
                                            fontFamily: 'Roboto'
                                        }}
                                    >
                                        <div
                                            style={{
                                                marginLeft: '-25px',
                                                width: 400,
                                                fontSize: 14,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                color: '#000'
                                            }}
                                        >
                                            <span>BÁSICO</span>
                                            <span
                                                style={{marginRight: '-15px'}}
                                            >
                                                INTERMEDIÁRIO
                                            </span>
                                            <span>AVANÇADO</span>
                                        </div>
                                        <Box sx={{width: 340}}>
                                            <Slider
                                                className={classes.root}
                                                style={{color: '#e37039'}}
                                                // aria-label="Always visible"
                                                defaultValue={
                                                    !profile?.nivel
                                                        ? 0
                                                        : profile?.nivel
                                                }
                                                getAriaValueText={valuetext}
                                                step={50}
                                                // marks={marks}
                                                onChange={(e, value) =>
                                                    handleSlider(e, value)
                                                }
                                                // valueLabelDisplay="on"
                                            />
                                        </Box>
                                    </div>
                                </Card>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        marginTop: '60px'
                                    }}
                                >
                                    <img
                                        style={{width: 240}}
                                        src={logo}
                                        alt="UseOpti"
                                    />
                                </div>
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
            <div style={{width: '100%', height: '100%'}}>
                <Modal
                    open={open}
                    onClose={closeModal}
                    disableEnforceFocus
                    style={{
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        backdropFilter: 'blur(3px)'
                    }}
                >
                    <CancelSub
                        setConfirm={setConfirm}
                        setOpen={setOpen}
                        confirm={confirm}
                    />
                </Modal>
            </div>
            <RecoverPassword
                forgotPassword={forgotPassword}
                setForgotPassword={setForgotPassword}
            />
            <Modal
                disableEnforceFocus
                open={openDialog}
                PaperProps={{
                    style: {
                        backgroundColor: 'rgba(211, 211, 211, 0.4)',
                        boxShadow: 'none'
                    }
                }}
                className={classes.modal}
                style={{
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    outline: 0,
                    backdropFilter: 'blur(5px)'
                }}
            >
                <Card
                    style={{
                        backgroundColor: 'rgba(211, 211, 211, 0.4)',
                        height: '38rem',
                        borderRadius: '20px'
                    }}
                    className={classes.modal}
                >
                    <div
                        style={{
                            textAlign: 'right',
                            fontSize: 32,
                            marginRight: '5px',
                            marginTop: '-15px'
                        }}
                    >
                        <ion-icon
                            style={{
                                backgroundColor: '#e37039',
                                color: '#fff',
                                borderRadius: '50%',
                                cursor: 'pointer'
                            }}
                            onClick={() => setOpenDialog(false)}
                            name="close-circle-outline"
                        />
                    </div>
                    <Card.Body style={{fontFamily: 'Roboto'}}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <img alt="" style={{width: '900px'}} src={titulo} />
                        </div>
                        <PlanosButton />
                    </Card.Body>
                </Card>
            </Modal>
        </>
    );
}
