/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, {useState} from 'react';
import {Card} from 'react-bootstrap';
import api from '@app/utils/api';
import {useHistory} from 'react-router-dom';
import {Dialog} from '@material-ui/core';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import Login from '@app/pages/Login/Login';
import routing from '@app/routes/routing';
import botaoComecar from 'src/img/botaoComecar.avif';
import botaoComecarHover from 'src/img/botaoComecarHover.avif';
import botaoAssinar from 'src/img/botaoAssinar.avif';
import botaoAssinarHover from 'src/img/botaoAssinarHover.avif';
import PlanosPayment from '@app/components/PlanosPayment';
import './Planos.css';

function PlanosButton() {
    const history = useHistory();

    const useStyles = makeStyles(() => createStyles({}));
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [openCheck, setOpenCheck] = useState(false);
    const [loginBtn, setLoginBtn] = useState(false);
    const [planoSelected, setPlanoSelected] = useState();

    const handleOpen = () => {
        setOpenCheck(true);
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: '15px 15px '
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Card
                            style={{
                                height: '21rem',
                                width: '22rem',
                                marginBottom: 10,
                                justifyContent: 'center',
                                background: '#2c2c2c'
                            }}
                        >
                            <div
                                style={{display: 'flex', alignItems: 'center'}}
                            >
                                <div>
                                    <div
                                        style={{
                                            transform: 'rotate(270deg)',
                                            textAlign: 'center',
                                            border: '0.2px solid white',
                                            borderRadius: '30px',
                                            width: '21.5rem',
                                            height: '2.3rem',
                                            marginRight: '-200px',
                                            marginLeft: '-160px',
                                            fontSize: 26,
                                            fontWeight: 700,
                                            background: '#fff',
                                            color: '#e37039',
                                            letterSpacing: '5px'
                                        }}
                                    >
                                        GRATUITO
                                    </div>
                                </div>
                                <div>
                                    <div
                                        style={{
                                            alignItems: 'center',
                                            height: '138px',
                                            margin: '100px 0px 100px 50px'
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontSize: 20,
                                                color: '#fff'
                                            }}
                                        >
                                            <p>• TELAS PERSONALIZAVEIS</p>
                                            <p>• ATÉ 3 ATIVOS FAVORITOS</p>
                                            <p>
                                                • CARTEIRA COM ATÉ 3 ESTRUTURAS
                                            </p>
                                        </div>
                                        <div
                                            style={{
                                                color: '#e37039',
                                                textAlign: 'right',
                                                fontSize: 28,
                                                fontWeight: 600,
                                                marginRight: '20px'
                                            }}
                                        >
                                            É GRATIS MESMO{' '}
                                        </div>
                                    </div>
                                    <div style={{marginLeft: '100px'}}>
                                        {api.currentSession ? (
                                            <button
                                                type="button"
                                                style={{
                                                    backgroundColor:
                                                        'transparent',
                                                    border: 0
                                                }}
                                                onClick={() =>
                                                    history.push(
                                                        routing.dashboard
                                                    )
                                                }
                                            >
                                                <img
                                                    src={botaoComecar}
                                                    alt=""
                                                    width="150px"
                                                    onFocus=""
                                                    onBlur=""
                                                    onMouseOver={(e) => {
                                                        e.currentTarget.src =
                                                            botaoComecarHover;
                                                    }}
                                                    onMouseOut={(e) => {
                                                        e.currentTarget.src =
                                                            botaoComecar;
                                                    }}
                                                />
                                            </button>
                                        ) : (
                                            <button
                                                type="button"
                                                style={{
                                                    backgroundColor:
                                                        'transparent',
                                                    border: 0
                                                }}
                                                onClick={() => {
                                                    setOpen(true);
                                                    setLoginBtn(true);
                                                }}
                                            >
                                                <img
                                                    src={botaoComecar}
                                                    alt=""
                                                    width="150px"
                                                    onFocus=""
                                                    onBlur=""
                                                    onMouseOver={(e) => {
                                                        e.currentTarget.src =
                                                            botaoComecarHover;
                                                    }}
                                                    onMouseOut={(e) => {
                                                        e.currentTarget.src =
                                                            botaoComecar;
                                                    }}
                                                />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '60px'
                    }}
                >
                    <Card
                        style={{
                            height: '21rem',
                            width: '22rem',
                            marginBottom: 10,
                            justifyContent: 'center',
                            background: '#2c2c2c'
                        }}
                    >
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div>
                                <div
                                    style={{
                                        transform: 'rotate(270deg)',
                                        textAlign: 'center',
                                        border: '0.2px solid white',
                                        borderRadius: '30px',
                                        width: '21.5rem',
                                        height: '2.3rem',
                                        marginRight: '-200px',
                                        marginLeft: '-160px',
                                        fontSize: 26,
                                        fontWeight: 700,
                                        background: '#fff',
                                        color: '#e37039',
                                        letterSpacing: '5px'
                                    }}
                                >
                                    SARDINHA MENSAL
                                </div>
                            </div>
                            <div>
                                <div
                                    style={{
                                        alignItems: 'center',
                                        height: '140px',
                                        margin: '100px 0px 100px 50px'
                                    }}
                                >
                                    <div style={{fontSize: 20, color: '#fff'}}>
                                        <p>• TELAS PERSONALIZAVEIS</p>
                                        <p>• ATIVOS FAVORITOS ILIMITADOS</p>
                                        <p>
                                            • CARTEIRA E ESTRUTURAS ILIMITADAS
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            color: '#e37039',
                                            textAlign: 'right',
                                            fontSize: 28,
                                            fontWeight: 600,
                                            marginRight: '20px'
                                        }}
                                    >
                                        R$ 9,90 / mês*
                                    </div>
                                </div>
                                <div style={{marginLeft: '105px'}}>
                                    {/* <button
                                    type="button"
                                    onClick={() => checkoutAssinatura.open()}
                                >
                                    teste
                                </button> */}

                                    {api.currentSession ? (
                                        <button
                                            type="button"
                                            style={{
                                                backgroundColor: 'transparent',
                                                border: 0
                                            }}
                                            // onClick={() => setOpenCheckMensal(true)}
                                            // onClick={() => checkoutMensal.open()}
                                            onClick={() => {
                                                setPlanoSelected('mensal');
                                                handleOpen();
                                            }}
                                        >
                                            <img
                                                src={botaoAssinar}
                                                alt=""
                                                width="150px"
                                                onFocus=""
                                                onBlur=""
                                                onMouseOver={(e) => {
                                                    e.currentTarget.src =
                                                        botaoAssinarHover;
                                                }}
                                                onMouseOut={(e) => {
                                                    e.currentTarget.src =
                                                        botaoAssinar;
                                                }}
                                            />
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            style={{
                                                backgroundColor: 'transparent',
                                                border: 0
                                            }}
                                            onClick={() => setOpen(true)}
                                        >
                                            <img
                                                src={botaoAssinar}
                                                alt=""
                                                width="150px"
                                                onFocus=""
                                                onBlur=""
                                                onMouseOver={(e) => {
                                                    e.currentTarget.src =
                                                        botaoAssinarHover;
                                                }}
                                                onMouseOut={(e) => {
                                                    e.currentTarget.src =
                                                        botaoAssinar;
                                                }}
                                            />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '60px'
                    }}
                >
                    <Card
                        style={{
                            height: '21rem',
                            width: '22rem',
                            marginBottom: 10,
                            justifyContent: 'center',
                            background: '#2c2c2c'
                        }}
                    >
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div>
                                <div
                                    style={{
                                        transform: 'rotate(270deg)',
                                        textAlign: 'center',
                                        border: '0.2px solid white',
                                        borderRadius: '30px',
                                        width: '21.5rem',
                                        height: '2.3rem',
                                        marginRight: '-200px',
                                        marginLeft: '-160px',
                                        fontSize: 26,
                                        fontWeight: 700,
                                        background: '#fff',
                                        color: '#e37039',
                                        letterSpacing: '5px'
                                    }}
                                >
                                    SARDINHA ANUAL
                                </div>
                            </div>
                            <div>
                                <div
                                    style={{
                                        alignItems: 'center',
                                        height: '140px',
                                        margin: '100px 0px 100px 50px'
                                    }}
                                >
                                    <div style={{fontSize: 20, color: '#fff'}}>
                                        <p>• TELAS PERSONALIZAVEIS</p>
                                        <p>• ATIVOS FAVORITOS ILIMITADOS</p>
                                        <p>
                                            • CARTEIRA E ESTRUTURAS ILIMITADAS
                                        </p>
                                    </div>
                                    <div
                                        style={{
                                            color: '#e37039',
                                            textAlign: 'right',
                                            fontSize: 30,
                                            fontWeight: 600,
                                            marginRight: '20px'
                                        }}
                                    >
                                        R$ 69,90 / Ano*
                                    </div>
                                    <div style={{color: '#fff', fontSize: 18}}>
                                        Economize R$48,90
                                    </div>
                                </div>
                                <div style={{marginLeft: '105px'}}>
                                    {api.currentSession ? (
                                        <button
                                            type="button"
                                            style={{
                                                backgroundColor: 'transparent',
                                                border: 0
                                            }}
                                            onClick={() => {
                                                // setOpenCheckAnual(true);
                                                setPlanoSelected('anual');
                                                handleOpen();
                                            }}
                                        >
                                            <img
                                                src={botaoAssinar}
                                                alt=""
                                                width="150px"
                                                onFocus=""
                                                onBlur=""
                                                onMouseOver={(e) => {
                                                    e.currentTarget.src =
                                                        botaoAssinarHover;
                                                }}
                                                onMouseOut={(e) => {
                                                    e.currentTarget.src =
                                                        botaoAssinar;
                                                }}
                                            />
                                        </button>
                                    ) : (
                                        <button
                                            type="button"
                                            style={{
                                                backgroundColor: 'transparent',
                                                border: 0
                                            }}
                                            onClick={() => setOpen(true)}
                                        >
                                            <img
                                                src={botaoAssinar}
                                                alt=""
                                                width="150px"
                                                onFocus=""
                                                onBlur=""
                                                onMouseOver={(e) => {
                                                    e.currentTarget.src =
                                                        botaoAssinarHover;
                                                }}
                                                onMouseOut={(e) => {
                                                    e.currentTarget.src =
                                                        botaoAssinar;
                                                }}
                                            />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
                <Dialog
                    // fullWidth
                    maxWidth="lg"
                    open={open}
                    onClose={() => {
                        setOpen(false);
                        setOpen();
                    }}
                    classes={{
                        paper: classes.dialog
                    }}
                >
                    {loginBtn ? (
                        <Login setOpen={setOpen} redirect={loginBtn} />
                    ) : (
                        <Login setOpen={setOpen} />
                    )}
                </Dialog>

                <PlanosPayment
                    open={openCheck}
                    setOpenCheck={setOpenCheck}
                    planoSelected={planoSelected}
                    reRender={open}
                />
            </div>
        </>
    );
}
export default PlanosButton;
