const ativos = [
    {
        label: 'AERI3',
        value: 'AERI3'
    },
    {
        label: 'ABEV3',
        value: 'ABEV3'
    },
    {
        label: 'AESB3',
        value: 'AESB3'
    },
    {
        label: 'ABCB4',
        value: 'ABCB4'
    },
    {
        label: 'ALPA4',
        value: 'ALPA4'
    },
    {
        label: 'ALSO3',
        value: 'ALSO3'
    },
    {
        label: 'ALUP11',
        value: 'ALUP11'
    },
    {
        label: 'AMAR3',
        value: 'AMAR3'
    },
    {
        label: 'AMBP3',
        value: 'AMBP3'
    },
    {
        label: 'AMER3',
        value: 'AMER3'
    },
    {
        label: 'ANIM3',
        value: 'ANIM3'
    },
    {
        label: 'ARZZ3',
        value: 'ARZZ3'
    },
    {
        label: 'ASAI3',
        value: 'ASAI3'
    },
    {
        label: 'AZUL4',
        value: 'AZUL4'
    },
    {
        label: 'B3SA3',
        value: 'B3SA3'
    },
    {
        label: 'BBAS3',
        value: 'BBAS3'
    },
    {
        label: 'BBDC3',
        value: 'BBDC3'
    },
    {
        label: 'BBDC4',
        value: 'BBDC4'
    },
    {
        label: 'BBSE3',
        value: 'BBSE3'
    },
    {
        label: 'BEEF3',
        value: 'BEEF3'
    },
    {
        label: 'BIDI11',
        value: 'BIDI11'
    },
    {
        label: 'BKBR3',
        value: 'BKBR3'
    },
    {
        label: 'BMGB4',
        value: 'BMGB4'
    },
    {
        label: 'BMOB3',
        value: 'BMOB3'
    },
    {
        label: 'BOVA11',
        value: 'BOVA11'
    },
    {
        label: 'BOVB11',
        value: 'BOVB11'
    },
    {
        label: 'BOVV11',
        value: 'BOVV11'
    },
    {
        label: 'BPAC11',
        value: 'BPAC11'
    },
    {
        label: 'BPAN4',
        value: 'BPAN4'
    },
    {
        label: 'BRAP4',
        value: 'BRAP4'
    },
    {
        label: 'BRAP99',
        value: 'BRAP99'
    },
    {
        label: 'BRAX11',
        value: 'BRAX11'
    },
    {
        label: 'BRFS3',
        value: 'BRFS3'
    },
    {
        label: 'BRKM5',
        value: 'BRKM5'
    },
    {
        label: 'BRML3',
        value: 'BRML3'
    },
    {
        label: 'BRPR3',
        value: 'BRPR3'
    },
    {
        label: 'BRSR6',
        value: 'BRSR6'
    },
    {
        label: 'BTOW3',
        value: 'BTOW3'
    },
    {
        label: 'CAML3',
        value: 'CAML3'
    },
    {
        label: 'CASH3',
        value: 'CASH3'
    },
    {
        label: 'CBAV3',
        value: 'CBAV3'
    },
    {
        label: 'CCRO3',
        value: 'CCRO3'
    },
    {
        label: 'CEAB3',
        value: 'CEAB3'
    },
    {
        label: 'CESP6',
        value: 'CESP6'
    },
    {
        label: 'CIEL3',
        value: 'CIEL3'
    },
    {
        label: 'CMIG3',
        value: 'CMIG3'
    },
    {
        label: 'CMIG4',
        value: 'CMIG4'
    },
    {
        label: 'CMIN3',
        value: 'CMIN3'
    },
    {
        label: 'COGN3',
        value: 'COGN3'
    },
    {
        label: 'CPFE3',
        value: 'CPFE3'
    },
    {
        label: 'CPLE6',
        value: 'CPLE6'
    },
    {
        label: 'CRFB3',
        value: 'CRFB3'
    },
    {
        label: 'CSAN3',
        value: 'CSAN3'
    },
    {
        label: 'CSMG3',
        value: 'CSMG3'
    },
    {
        label: 'CSNA3',
        value: 'CSNA3'
    },
    {
        label: 'CURY3',
        value: 'CURY3'
    },
    {
        label: 'CVCB3',
        value: 'CVCB3'
    },
    {
        label: 'CXSE3',
        value: 'CXSE3'
    },
    {
        label: 'CYRE3',
        value: 'CYRE3'
    },
    {
        label: 'DIRR3',
        value: 'DIRR3'
    },
    {
        label: 'DXCO3',
        value: 'DXCO3'
    },
    {
        label: 'ECOR3',
        value: 'ECOR3'
    },
    {
        label: 'EGIE3',
        value: 'EGIE3'
    },
    {
        label: 'ELET3',
        value: 'ELET3'
    },
    {
        label: 'ELET6',
        value: 'ELET6'
    },
    {
        label: 'EMBR3',
        value: 'EMBR3'
    },
    {
        label: 'ENAT3',
        value: 'ENAT3'
    },
    {
        label: 'ENBR3',
        value: 'ENBR3'
    },
    {
        label: 'ENEV3',
        value: 'ENEV3'
    },
    {
        label: 'ENGI11',
        value: 'ENGI11'
    },
    {
        label: 'ENJU3',
        value: 'ENJU3'
    },
    {
        label: 'EQTL3',
        value: 'EQTL3'
    },
    {
        label: 'EVEN3',
        value: 'EVEN3'
    },
    {
        label: 'EZTC3',
        value: 'EZTC3'
    },
    {
        label: 'FESA4',
        value: 'FESA4'
    },
    {
        label: 'FLRY3',
        value: 'FLRY3'
    },
    {
        label: 'GFSA3',
        value: 'GFSA3'
    },
    {
        label: 'GGBR4',
        value: 'GGBR4'
    },
    {
        label: 'GMAT3',
        value: 'GMAT3'
    },
    {
        label: 'GNDI3',
        value: 'GNDI3'
    },
    {
        label: 'GOAU4',
        value: 'GOAU4'
    },
    {
        label: 'GOLL4',
        value: 'GOLL4'
    },
    {
        label: 'GRND3',
        value: 'GRND3'
    },
    {
        label: 'GUAR3',
        value: 'GUAR3'
    },
    {
        label: 'HAPV3',
        value: 'HAPV3'
    },
    {
        label: 'HBOR3',
        value: 'HBOR3'
    },
    {
        label: 'HBSA3',
        value: 'HBSA3'
    },
    {
        label: 'HYPE3',
        value: 'HYPE3'
    },
    {
        label: 'IBOV11',
        value: 'IBOV11'
    },
    {
        label: 'IFCM3',
        value: 'IFCM3'
    },
    {
        label: 'IGTA3',
        value: 'IGTA3'
    },
    {
        label: 'IGTI11',
        value: 'IGTI11'
    },
    {
        label: 'INTB3',
        value: 'INTB3'
    },
    {
        label: 'IRBR3',
        value: 'IRBR3'
    },
    {
        label: 'ITSA4',
        value: 'ITSA4'
    },
    {
        label: 'ITUB3',
        value: 'ITUB3'
    },
    {
        label: 'ITUB4',
        value: 'ITUB4'
    },
    {
        label: 'ITUB99',
        value: 'ITUB99'
    },
    {
        label: 'IVVB11',
        value: 'IVVB11'
    },
    {
        label: 'JBSS3',
        value: 'JBSS3'
    },
    {
        label: 'JHSF3',
        value: 'JHSF3'
    },
    {
        label: 'KLBN11',
        value: 'KLBN11'
    },
    {
        label: 'LAME3',
        value: 'LAME3'
    },
    {
        label: 'LAME4',
        value: 'LAME4'
    },
    {
        label: 'LAME98',
        value: 'LAME98'
    },
    {
        label: 'LAME99',
        value: 'LAME99'
    },
    {
        label: 'LAVV3',
        value: 'LAVV3'
    },
    {
        label: 'LCAM3',
        value: 'LCAM3'
    },
    {
        label: 'LEVE3',
        value: 'LEVE3'
    },
    {
        label: 'LIGT3',
        value: 'LIGT3'
    },
    {
        label: 'LJQQ3',
        value: 'LJQQ3'
    },
    {
        label: 'LOGG3',
        value: 'LOGG3'
    },
    {
        label: 'LOGN3',
        value: 'LOGN3'
    },
    {
        label: 'LREN3',
        value: 'LREN3'
    },
    {
        label: 'LWSA3',
        value: 'LWSA3'
    },
    {
        label: 'MDIA3',
        value: 'MDIA3'
    },
    {
        label: 'MEAL3',
        value: 'MEAL3'
    },
    {
        label: 'MEGA3',
        value: 'MEGA3'
    },
    {
        label: 'MGLU3',
        value: 'MGLU3'
    },
    {
        label: 'MILS3',
        value: 'MILS3'
    },
    {
        label: 'MLAS3',
        value: 'MLAS3'
    },
    {
        label: 'MODL11',
        value: 'MODL11'
    },
    {
        label: 'MOSI3',
        value: 'MOSI3'
    },
    {
        label: 'MOVI3',
        value: 'MOVI3'
    },
    {
        label: 'MRFG3',
        value: 'MRFG3'
    },
    {
        label: 'MRVE3',
        value: 'MRVE3'
    },
    {
        label: 'MULT3',
        value: 'MULT3'
    },
    {
        label: 'MYPK3',
        value: 'MYPK3'
    },
    {
        label: 'NEOE3',
        value: 'NEOE3'
    },
    {
        label: 'NTCO3',
        value: 'NTCO3'
    },
    {
        label: 'ODPV3',
        value: 'ODPV3'
    },
    {
        label: 'OMGE3',
        value: 'OMGE3'
    },
    {
        label: 'PARD3',
        value: 'PARD3'
    },
    {
        label: 'PCAR3',
        value: 'PCAR3'
    },
    {
        label: 'PCAR99',
        value: 'PCAR99'
    },
    {
        label: 'PETR3',
        value: 'PETR3'
    },
    {
        label: 'PETR4',
        value: 'PETR4'
    },
    {
        label: 'PETZ3',
        value: 'PETZ3'
    },
    {
        label: 'PIBB11',
        value: 'PIBB11'
    },
    {
        label: 'PNVL3',
        value: 'PNVL3'
    },
    {
        label: 'POMO4',
        value: 'POMO4'
    },
    {
        label: 'POSI3',
        value: 'POSI3'
    },
    {
        label: 'PRIO3',
        value: 'PRIO3'
    },
    {
        label: 'PSSA3',
        value: 'PSSA3'
    },
    {
        label: 'PTBL3',
        value: 'PTBL3'
    },
    {
        label: 'QUAL3',
        value: 'QUAL3'
    },
    {
        label: 'RADL3',
        value: 'RADL3'
    },
    {
        label: 'RAIL3',
        value: 'RAIL3'
    },
    {
        label: 'RAIZ4',
        value: 'RAIZ4'
    },
    {
        label: 'RANI3',
        value: 'RANI3'
    },
    {
        label: 'RAPT4',
        value: 'RAPT4'
    },
    {
        label: 'RDOR3',
        value: 'RDOR3'
    },
    {
        label: 'RENT3',
        value: 'RENT3'
    },
    {
        label: 'ROMI3',
        value: 'ROMI3'
    },
    {
        label: 'RRRP3',
        value: 'RRRP3'
    },
    {
        label: 'SANB11',
        value: 'SANB11'
    },
    {
        label: 'SAPR11',
        value: 'SAPR11'
    },
    {
        label: 'SBFG3',
        value: 'SBFG3'
    },
    {
        label: 'SBSP3',
        value: 'SBSP3'
    },
    {
        label: 'SEER3',
        value: 'SEER3'
    },
    {
        label: 'SEQL3',
        value: 'SEQL3'
    },
    {
        label: 'SIMH3',
        value: 'SIMH3'
    },
    {
        label: 'SLCE3',
        value: 'SLCE3'
    },
    {
        label: 'SMAL11',
        value: 'SMAL11'
    },
    {
        label: 'SMFT3',
        value: 'SMFT3'
    },
    {
        label: 'SMTO3',
        value: 'SMTO3'
    },
    {
        label: 'SOMA3',
        value: 'SOMA3'
    },
    {
        label: 'SPXI11',
        value: 'SPXI11'
    },
    {
        label: 'SQIA3',
        value: 'SQIA3'
    },
    {
        label: 'STBP3',
        value: 'STBP3'
    },
    {
        label: 'SULA11',
        value: 'SULA11'
    },
    {
        label: 'SUZB3',
        value: 'SUZB3'
    },
    {
        label: 'TAEE11',
        value: 'TAEE11'
    },
    {
        label: 'TASA4',
        value: 'TASA4'
    },
    {
        label: 'TCSA3',
        value: 'TCSA3'
    },
    {
        label: 'TEND3',
        value: 'TEND3'
    },
    {
        label: 'TIMS3',
        value: 'TIMS3'
    },
    {
        label: 'TOTS3',
        value: 'TOTS3'
    },
    {
        label: 'TRAD3',
        value: 'TRAD3'
    },
    {
        label: 'TRIS3',
        value: 'TRIS3'
    },
    {
        label: 'TRPL4',
        value: 'TRPL4'
    },
    {
        label: 'TUPY3',
        value: 'TUPY3'
    },
    {
        label: 'UGPA3',
        value: 'UGPA3'
    },
    {
        label: 'UNIP6',
        value: 'UNIP6'
    },
    {
        label: 'USIM5',
        value: 'USIM5'
    },
    {
        label: 'VALE3',
        value: 'VALE3'
    },
    {
        label: 'VAMO3',
        value: 'VAMO3'
    },
    {
        label: 'VBBR3',
        value: 'VBBR3'
    },
    {
        label: 'VIIA3',
        value: 'VIIA3'
    },
    {
        label: 'VIVA3',
        value: 'VIVA3'
    },
    {
        label: 'VIVT3',
        value: 'VIVT3'
    },
    {
        label: 'VLID3',
        value: 'VLID3'
    },
    {
        label: 'VULC3',
        value: 'VULC3'
    },
    {
        label: 'WEGE3',
        value: 'WEGE3'
    },
    {
        label: 'WIZS3',
        value: 'WIZS3'
    },
    {
        label: 'XBOV11',
        value: 'XBOV11'
    },
    {
        label: 'YDUQ3',
        value: 'YDUQ3'
    }
];

export default ativos;
