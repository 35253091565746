import React, {useState} from 'react';
import LazyLoad from 'react-lazyload';
import TagManager from 'react-gtm-module';
import WebFont from 'webfontloader';

import {
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    Section6,
    Section7
} from '../components/VendasSections';
import PlanosPayment from '../components/PlanosPayment';

export default function Vendas() {
    const [size, setSize] = useState(false);
    const [openCheck, setOpenCheck] = useState(false);

    React.useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto', 'Chilanka']
            }
        });
        setSize(window.screen.width);
    }, []);

    const handlePlano = () => {
        setOpenCheck(true);
    };

    const tagManagerArgs = {
        gtmId: 'GTM-5758LXQ'
    };

    TagManager.initialize(tagManagerArgs);

    return (
        <>
            <LazyLoad once unmountIfInvisible>
                <Section1 setOpen={handlePlano} size={size} />
            </LazyLoad>
            <LazyLoad once unmountIfInvisible>
                <Section2 size={size} />
            </LazyLoad>
            <LazyLoad once unmountIfInvisible>
                <Section3 size={size} />
            </LazyLoad>
            <LazyLoad once unmountIfInvisible>
                <Section4 setOpen={handlePlano} size={size} />
            </LazyLoad>
            <LazyLoad once unmountIfInvisible>
                <Section5 size={size} />
            </LazyLoad>
            <LazyLoad once unmountIfInvisible>
                <Section6 setOpen={handlePlano} size={size} />
            </LazyLoad>
            <LazyLoad once unmountIfInvisible>
                <Section7 setOpen={handlePlano} size={size} />
            </LazyLoad>
            <PlanosPayment
                open={openCheck}
                setOpenCheck={setOpenCheck}
                planoSelected="FREE"
                reRender={open}
            />
        </>
    );
}
