const citiesNorte = [
    {state: 'TO', value: 'Abreulândia'},
    {state: 'TO', value: 'Aguiarnópolis'},
    {state: 'TO', value: 'Aliança do Tocantins'},
    {state: 'TO', value: 'Almas'},
    {state: 'TO', value: 'Alvorada'},
    {state: 'TO', value: 'Ananás'},
    {state: 'TO', value: 'Angico'},
    {state: 'TO', value: 'Aparecida do Rio Negro'},
    {state: 'TO', value: 'Aragominas'},
    {state: 'TO', value: 'Araguacema'},
    {state: 'TO', value: 'Araguaçu'},
    {state: 'TO', value: 'Araguaína'},
    {state: 'TO', value: 'Araguanã'},
    {state: 'TO', value: 'Araguatins'},
    {state: 'TO', value: 'Arapoema'},
    {state: 'TO', value: 'Arraias'},
    {state: 'TO', value: 'Augustinópolis'},
    {state: 'TO', value: 'Aurora do Tocantins'},
    {state: 'TO', value: 'Axixá do Tocantins'},
    {state: 'TO', value: 'Babaçulândia'},
    {state: 'TO', value: 'Bandeirantes do Tocantins'},
    {state: 'TO', value: 'Barra do Ouro'},
    {state: 'TO', value: 'Barrolândia'},
    {state: 'TO', value: 'Bernardo Sayão'},
    {state: 'TO', value: 'Bom Jesus do Tocantins'},
    {state: 'TO', value: 'Brasilândia do Tocantins'},
    {state: 'TO', value: 'Brejinho de Nazaré'},
    {state: 'TO', value: 'Buriti do Tocantins'},
    {state: 'TO', value: 'Cachoeirinha'},
    {state: 'TO', value: 'Campos Lindos'},
    {state: 'TO', value: 'Cariri do Tocantins'},
    {state: 'TO', value: 'Carmolândia'},
    {state: 'TO', value: 'Carrasco Bonito'},
    {state: 'TO', value: 'Caseara'},
    {state: 'TO', value: 'Centenário'},
    {state: 'TO', value: 'Chapada da Natividade'},
    {state: 'TO', value: 'Chapada de Areia'},
    {state: 'TO', value: 'Colinas do Tocantins'},
    {state: 'TO', value: 'Colméia'},
    {state: 'TO', value: 'Combinado'},
    {state: 'TO', value: 'Conceição do Tocantins'},
    {state: 'TO', value: 'Couto Magalhães'},
    {state: 'TO', value: 'Cristalândia'},
    {state: 'TO', value: 'Crixás do Tocantins'},
    {state: 'TO', value: 'Darcinópolis'},
    {state: 'TO', value: 'Dianópolis'},
    {state: 'TO', value: 'Divinópolis do Tocantins'},
    {state: 'TO', value: 'Dois Irmãos do Tocantins'},
    {state: 'TO', value: 'Dueré'},
    {state: 'TO', value: 'Esperantina'},
    {state: 'TO', value: 'Fátima'},
    {state: 'TO', value: 'Figueirópolis'},
    {state: 'TO', value: 'Filadélfia'},
    {state: 'TO', value: 'Formoso do Araguaia'},
    {state: 'TO', value: 'Fortaleza do Tabocão'},
    {state: 'TO', value: 'Goianorte'},
    {state: 'TO', value: 'Goiatins'},
    {state: 'TO', value: 'Guaraí'},
    {state: 'TO', value: 'Gurupi'},
    {state: 'TO', value: 'Ipueiras'},
    {state: 'TO', value: 'Itacajá'},
    {state: 'TO', value: 'Itaguatins'},
    {state: 'TO', value: 'Itapiratins'},
    {state: 'TO', value: 'Itaporã do Tocantins'},
    {state: 'TO', value: 'Jaú do Tocantins'},
    {state: 'TO', value: 'Juarina'},
    {state: 'TO', value: 'Lagoa da Confusão'},
    {state: 'TO', value: 'Lagoa do Tocantins'},
    {state: 'TO', value: 'Lajeado'},
    {state: 'TO', value: 'Lavandeira'},
    {state: 'TO', value: 'Lizarda'},
    {state: 'TO', value: 'Luzinópolis'},
    {state: 'TO', value: 'Marianópolis do Tocantins'},
    {state: 'TO', value: 'Mateiros'},
    {state: 'TO', value: 'Maurilândia do Tocantins'},
    {state: 'TO', value: 'Miracema do Tocantins'},
    {state: 'TO', value: 'Miranorte'},
    {state: 'TO', value: 'Monte do Carmo'},
    {state: 'TO', value: 'Monte Santo do Tocantins'},
    {state: 'TO', value: 'Muricilândia'},
    {state: 'TO', value: 'Natividade'},
    {state: 'TO', value: 'Nazaré'},
    {state: 'TO', value: 'Nova Olinda'},
    {state: 'TO', value: 'Nova Rosalândia'},
    {state: 'TO', value: 'Novo Acordo'},
    {state: 'TO', value: 'Novo Alegre'},
    {state: 'TO', value: 'Novo Jardim'},
    {state: 'TO', value: 'Oliveira de Fátima'},
    {state: 'TO', value: 'Palmas'},
    {state: 'TO', value: 'Palmeirante'},
    {state: 'TO', value: 'Palmeiras do Tocantins'},
    {state: 'TO', value: 'Palmeirópolis'},
    {state: 'TO', value: 'Paraíso do Tocantins'},
    {state: 'TO', value: 'Paranã'},
    {state: 'TO', value: "Pau d'Arco"},
    {state: 'TO', value: 'Pedro Afonso'},
    {state: 'TO', value: 'Peixe'},
    {state: 'TO', value: 'Pequizeiro'},
    {state: 'TO', value: 'Pindorama do Tocantins'},
    {state: 'TO', value: 'Piraquê'},
    {state: 'TO', value: 'Pium'},
    {state: 'TO', value: 'Ponte Alta do Bom Jesus'},
    {state: 'TO', value: 'Ponte Alta do Tocantins'},
    {state: 'TO', value: 'Porto Alegre do Tocantins'},
    {state: 'TO', value: 'Porto Nacional'},
    {state: 'TO', value: 'Praia Norte'},
    {state: 'TO', value: 'Presidente Kennedy'},
    {state: 'TO', value: 'Pugmil'},
    {state: 'TO', value: 'Recursolândia'},
    {state: 'TO', value: 'Riachinho'},
    {state: 'TO', value: 'Rio da Conceição'},
    {state: 'TO', value: 'Rio dos Bois'},
    {state: 'TO', value: 'Rio Sono'},
    {state: 'TO', value: 'Sampaio'},
    {state: 'TO', value: 'Sandolândia'},
    {state: 'TO', value: 'Santa Fé do Araguaia'},
    {state: 'TO', value: 'Santa Maria do Tocantins'},
    {state: 'TO', value: 'Santa Rita do Tocantins'},
    {state: 'TO', value: 'Santa Rosa do Tocantins'},
    {state: 'TO', value: 'Santa Tereza do Tocantins'},
    {state: 'TO', value: 'Santa Terezinha do Tocantins'},
    {state: 'TO', value: 'São Bento do Tocantins'},
    {state: 'TO', value: 'São Félix do Tocantins'},
    {state: 'TO', value: 'São Miguel do Tocantins'},
    {state: 'TO', value: 'São Salvador do Tocantins'},
    {state: 'TO', value: 'São Sebastião do Tocantins'},
    {state: 'TO', value: 'São Valério da Natividade'},
    {state: 'TO', value: 'Silvanópolis'},
    {state: 'TO', value: 'Sítio Novo do Tocantins'},
    {state: 'TO', value: 'Sucupira'},
    {state: 'TO', value: 'Taguatinga'},
    {state: 'TO', value: 'Taipas do Tocantins'},
    {state: 'TO', value: 'Talismã'},
    {state: 'TO', value: 'Tocantínia'},
    {state: 'TO', value: 'Tocantinópolis'},
    {state: 'TO', value: 'Tupirama'},
    {state: 'TO', value: 'Tupiratins'},
    {state: 'TO', value: 'Wanderlândia'},
    {state: 'TO', value: 'Xambioá'},
    {state: 'PA', value: 'Abaetetuba'},
    {state: 'PA', value: 'Abel Figueiredo'},
    {state: 'PA', value: 'Acará'},
    {state: 'PA', value: 'Afuá'},
    {state: 'PA', value: 'Água Azul do Norte'},
    {state: 'PA', value: 'Alenquer'},
    {state: 'PA', value: 'Almeirim'},
    {state: 'PA', value: 'Altamira'},
    {state: 'PA', value: 'Anajás'},
    {state: 'PA', value: 'Ananindeua'},
    {state: 'PA', value: 'Anapu'},
    {state: 'PA', value: 'Augusto Corrêa'},
    {state: 'PA', value: 'Aurora do Pará'},
    {state: 'PA', value: 'Aveiro'},
    {state: 'PA', value: 'Bagre'},
    {state: 'PA', value: 'Baião'},
    {state: 'PA', value: 'Bannach'},
    {state: 'PA', value: 'Barcarena'},
    {state: 'PA', value: 'Belém'},
    {state: 'PA', value: 'Belterra'},
    {state: 'PA', value: 'Benevides'},
    {state: 'PA', value: 'Bom Jesus do Tocantins'},
    {state: 'PA', value: 'Bonito'},
    {state: 'PA', value: 'Bragança'},
    {state: 'PA', value: 'Brasil Novo'},
    {state: 'PA', value: 'Brejo Grande do Araguaia'},
    {state: 'PA', value: 'Breu Branco'},
    {state: 'PA', value: 'Breves'},
    {state: 'PA', value: 'Bujaru'},
    {state: 'PA', value: 'Cachoeira do Arari'},
    {state: 'PA', value: 'Cachoeira do Piriá'},
    {state: 'PA', value: 'Cametá'},
    {state: 'PA', value: 'Canaã dos Carajás'},
    {state: 'PA', value: 'Capanema'},
    {state: 'PA', value: 'Capitão Poço'},
    {state: 'PA', value: 'Castanhal'},
    {state: 'PA', value: 'Chaves'},
    {state: 'PA', value: 'Colares'},
    {state: 'PA', value: 'Conceição do Araguaia'},
    {state: 'PA', value: 'Concórdia do Pará'},
    {state: 'PA', value: 'Cumaru do Norte'},
    {state: 'PA', value: 'Curionópolis'},
    {state: 'PA', value: 'Curralinho'},
    {state: 'PA', value: 'Curuá'},
    {state: 'PA', value: 'Curuçá'},
    {state: 'PA', value: 'Dom Eliseu'},
    {state: 'PA', value: 'Eldorado do Carajás'},
    {state: 'PA', value: 'Faro'},
    {state: 'PA', value: 'Floresta do Araguaia'},
    {state: 'PA', value: 'Garrafão do Norte'},
    {state: 'PA', value: 'Goianésia do Pará'},
    {state: 'PA', value: 'Gurupá'},
    {state: 'PA', value: 'Igarapé-Açu'},
    {state: 'PA', value: 'Igarapé-Miri'},
    {state: 'PA', value: 'Inhangapi'},
    {state: 'PA', value: 'Ipixuna do Pará'},
    {state: 'PA', value: 'Irituia'},
    {state: 'PA', value: 'Itaituba'},
    {state: 'PA', value: 'Itupiranga'},
    {state: 'PA', value: 'Jacareacanga'},
    {state: 'PA', value: 'Jacundá'},
    {state: 'PA', value: 'Juruti'},
    {state: 'PA', value: 'Limoeiro do Ajuru'},
    {state: 'PA', value: 'Mãe do Rio'},
    {state: 'PA', value: 'Magalhães Barata'},
    {state: 'PA', value: 'Marabá'},
    {state: 'PA', value: 'Maracanã'},
    {state: 'PA', value: 'Marapanim'},
    {state: 'PA', value: 'Marituba'},
    {state: 'PA', value: 'Medicilândia'},
    {state: 'PA', value: 'Melgaço'},
    {state: 'PA', value: 'Mocajuba'},
    {state: 'PA', value: 'Moju'},
    {state: 'PA', value: 'Mojuí dos Campos'},
    {state: 'PA', value: 'Monte Alegre'},
    {state: 'PA', value: 'Muaná'},
    {state: 'PA', value: 'Nova Esperança do Piriá'},
    {state: 'PA', value: 'Nova Ipixuna'},
    {state: 'PA', value: 'Nova Timboteua'},
    {state: 'PA', value: 'Novo Progresso'},
    {state: 'PA', value: 'Novo Repartimento'},
    {state: 'PA', value: 'Óbidos'},
    {state: 'PA', value: 'Oeiras do Pará'},
    {state: 'PA', value: 'Oriximiná'},
    {state: 'PA', value: 'Ourém'},
    {state: 'PA', value: 'Ourilândia do Norte'},
    {state: 'PA', value: 'Pacajá'},
    {state: 'PA', value: 'Palestina do Pará'},
    {state: 'PA', value: 'Paragominas'},
    {state: 'PA', value: 'Parauapebas'},
    {state: 'PA', value: "Pau-d'Arco"},
    {state: 'PA', value: 'Peixe-Boi'},
    {state: 'PA', value: 'Piçarra'},
    {state: 'PA', value: 'Placas'},
    {state: 'PA', value: 'Ponta de Pedras'},
    {state: 'PA', value: 'Portel'},
    {state: 'PA', value: 'Porto de Moz'},
    {state: 'PA', value: 'Prainha'},
    {state: 'PA', value: 'Primavera'},
    {state: 'PA', value: 'Quatipuru'},
    {state: 'PA', value: 'Redenção'},
    {state: 'PA', value: 'Rio Maria'},
    {state: 'PA', value: 'Rondon do Pará'},
    {state: 'PA', value: 'Rurópolis'},
    {state: 'PA', value: 'Salinópolis'},
    {state: 'PA', value: 'Salvaterra'},
    {state: 'PA', value: 'Santa Bárbara do Pará'},
    {state: 'PA', value: 'Santa Cruz do Arari'},
    {state: 'PA', value: 'Santa Izabel do Pará'},
    {state: 'PA', value: 'Santa Luzia do Pará'},
    {state: 'PA', value: 'Santa Maria das Barreiras'},
    {state: 'PA', value: 'Santa Maria do Pará'},
    {state: 'PA', value: 'Santana do Araguaia'},
    {state: 'PA', value: 'Santarém'},
    {state: 'PA', value: 'Santarém Novo'},
    {state: 'PA', value: 'Santo Antônio do Tauá'},
    {state: 'PA', value: 'São Caetano de Odivelas'},
    {state: 'PA', value: 'São Domingos do Araguaia'},
    {state: 'PA', value: 'São Domingos do Capim'},
    {state: 'PA', value: 'São Félix do Xingu'},
    {state: 'PA', value: 'São Francisco do Pará'},
    {state: 'PA', value: 'São Geraldo do Araguaia'},
    {state: 'PA', value: 'São João da Ponta'},
    {state: 'PA', value: 'São João de Pirabas'},
    {state: 'PA', value: 'São João do Araguaia'},
    {state: 'PA', value: 'São Miguel do Guamá'},
    {state: 'PA', value: 'São Sebastião da Boa Vista'},
    {state: 'PA', value: 'Sapucaia'},
    {state: 'PA', value: 'Senador José Porfírio'},
    {state: 'PA', value: 'Soure'},
    {state: 'PA', value: 'Tailândia'},
    {state: 'PA', value: 'Terra Alta'},
    {state: 'PA', value: 'Terra Santa'},
    {state: 'PA', value: 'Tomé-Açu'},
    {state: 'PA', value: 'Tracuateua'},
    {state: 'PA', value: 'Trairão'},
    {state: 'PA', value: 'Tucumã'},
    {state: 'PA', value: 'Tucuruí'},
    {state: 'PA', value: 'Ulianópolis'},
    {state: 'PA', value: 'Uruará'},
    {state: 'PA', value: 'Vigia'},
    {state: 'PA', value: 'Viseu'},
    {state: 'PA', value: 'Vitória do Xingu'},
    {state: 'PA', value: 'Xinguara'},
    {state: 'AP', value: 'Amapá'},
    {state: 'AP', value: 'Calçoene'},
    {state: 'AP', value: 'Cutias'},
    {state: 'AP', value: 'Ferreira Gomes'},
    {state: 'AP', value: 'Itaubal'},
    {state: 'AP', value: 'Laranjal do Jari'},
    {state: 'AP', value: 'Macapá'},
    {state: 'AP', value: 'Mazagão'},
    {state: 'AP', value: 'Oiapoque'},
    {state: 'AP', value: 'Pedra Branca do Amapari'},
    {state: 'AP', value: 'Porto Grande'},
    {state: 'AP', value: 'Pracuúba'},
    {state: 'AP', value: 'Santana'},
    {state: 'AP', value: 'Serra do Navio'},
    {state: 'AP', value: 'Tartarugalzinho'},
    {state: 'AP', value: 'Vitória do Jari'},
    {state: 'AM', value: 'Alvarães'},
    {state: 'AM', value: 'Amaturá'},
    {state: 'AM', value: 'Anamã'},
    {state: 'AM', value: 'Anori'},
    {state: 'AM', value: 'Apuí'},
    {state: 'AM', value: 'Atalaia do Norte'},
    {state: 'AM', value: 'Autazes'},
    {state: 'AM', value: 'Barcelos'},
    {state: 'AM', value: 'Barreirinha'},
    {state: 'AM', value: 'Benjamin Constant'},
    {state: 'AM', value: 'Beruri'},
    {state: 'AM', value: 'Boa Vista do Ramos'},
    {state: 'AM', value: 'Boca do Acre'},
    {state: 'AM', value: 'Borba'},
    {state: 'AM', value: 'Caapiranga'},
    {state: 'AM', value: 'Canutama'},
    {state: 'AM', value: 'Carauari'},
    {state: 'AM', value: 'Careiro'},
    {state: 'AM', value: 'Careiro da Várzea'},
    {state: 'AM', value: 'Coari'},
    {state: 'AM', value: 'Codajás'},
    {state: 'AM', value: 'Eirunepé'},
    {state: 'AM', value: 'Envira'},
    {state: 'AM', value: 'Fonte Boa'},
    {state: 'AM', value: 'Guajará'},
    {state: 'AM', value: 'Humaitá'},
    {state: 'AM', value: 'Ipixuna'},
    {state: 'AM', value: 'Iranduba'},
    {state: 'AM', value: 'Itacoatiara'},
    {state: 'AM', value: 'Itamarati'},
    {state: 'AM', value: 'Itapiranga'},
    {state: 'AM', value: 'Japurá'},
    {state: 'AM', value: 'Juruá'},
    {state: 'AM', value: 'Jutaí'},
    {state: 'AM', value: 'Lábrea'},
    {state: 'AM', value: 'Manacapuru'},
    {state: 'AM', value: 'Manaquiri'},
    {state: 'AM', value: 'Manaus'},
    {state: 'AM', value: 'Manicoré'},
    {state: 'AM', value: 'Maraã'},
    {state: 'AM', value: 'Maués'},
    {state: 'AM', value: 'Nhamundá'},
    {state: 'AM', value: 'Nova Olinda do Norte'},
    {state: 'AM', value: 'Novo Airão'},
    {state: 'AM', value: 'Novo Aripuanã'},
    {state: 'AM', value: 'Parintins'},
    {state: 'AM', value: 'Pauini'},
    {state: 'AM', value: 'Presidente Figueiredo'},
    {state: 'AM', value: 'Rio Preto da Eva'},
    {state: 'AM', value: 'Santa Isabel do Rio Negro'},
    {state: 'AM', value: 'Santo Antônio do Içá'},
    {state: 'AM', value: 'São Gabriel da Cachoeira'},
    {state: 'AM', value: 'São Paulo de Olivença'},
    {state: 'AM', value: 'São Sebastião do Uatumã'},
    {state: 'AM', value: 'Silves'},
    {state: 'AM', value: 'Tabatinga'},
    {state: 'AM', value: 'Tapauá'},
    {state: 'AM', value: 'Tefé'},
    {state: 'AM', value: 'Tonantins'},
    {state: 'AM', value: 'Uarini'},
    {state: 'AM', value: 'Urucará'},
    {state: 'AM', value: 'Urucurituba'},
    {state: 'RO', value: "Alta Floresta d'Oeste"},
    {state: 'RO', value: 'Alto Alegre dos Parecis'},
    {state: 'RO', value: 'Alto Paraíso'},
    {state: 'RO', value: "Alvorada d'Oeste"},
    {state: 'RO', value: 'Ariquemes'},
    {state: 'RO', value: 'Buritis'},
    {state: 'RO', value: 'Cabixi'},
    {state: 'RO', value: 'Cacaulândia'},
    {state: 'RO', value: 'Cacoal'},
    {state: 'RO', value: 'Campo Novo de Rondônia'},
    {state: 'RO', value: 'Candeias do Jamari'},
    {state: 'RO', value: 'Castanheiras'},
    {state: 'RO', value: 'Cerejeiras'},
    {state: 'RO', value: 'Chupinguaia'},
    {state: 'RO', value: 'Colorado do Oeste'},
    {state: 'RO', value: 'Corumbiara'},
    {state: 'RO', value: 'Costa Marques'},
    {state: 'RO', value: 'Cujubim'},
    {state: 'RO', value: "Espigão d'Oeste"},
    {state: 'RO', value: 'Governador Jorge Teixeira'},
    {state: 'RO', value: 'Guajará-Mirim'},
    {state: 'RO', value: 'Itapuã do Oeste'},
    {state: 'RO', value: 'Jaru'},
    {state: 'RO', value: 'Ji-Paraná'},
    {state: 'RO', value: "Machadinho d'Oeste"},
    {state: 'RO', value: 'Ministro Andreazza'},
    {state: 'RO', value: 'Mirante da Serra'},
    {state: 'RO', value: 'Monte Negro'},
    {state: 'RO', value: "Nova Brasilândia d'Oeste"},
    {state: 'RO', value: 'Nova Mamoré'},
    {state: 'RO', value: 'Nova União'},
    {state: 'RO', value: 'Novo Horizonte do Oeste'},
    {state: 'RO', value: 'Ouro Preto do Oeste'},
    {state: 'RO', value: 'Parecis'},
    {state: 'RO', value: 'Pimenta Bueno'},
    {state: 'RO', value: 'Pimenteiras do Oeste'},
    {state: 'RO', value: 'Porto Velho'},
    {state: 'RO', value: 'Presidente Médici'},
    {state: 'RO', value: 'Primavera de Rondônia'},
    {state: 'RO', value: 'Rio Crespo'},
    {state: 'RO', value: 'Rolim de Moura'},
    {state: 'RO', value: "Santa Luzia d'Oeste"},
    {state: 'RO', value: "São Felipe d'Oeste"},
    {state: 'RO', value: 'São Francisco do Guaporé'},
    {state: 'RO', value: 'São Miguel do Guaporé'},
    {state: 'RO', value: 'Seringueiras'},
    {state: 'RO', value: 'Teixeirópolis'},
    {state: 'RO', value: 'Theobroma'},
    {state: 'RO', value: 'Urupá'},
    {state: 'RO', value: 'Vale do Anari'},
    {state: 'RO', value: 'Vale do Paraíso'},
    {state: 'RO', value: 'Vilhena'},
    {state: 'RR', value: 'Amajari'},
    {state: 'RR', value: 'Alto Alegre'},
    {state: 'RR', value: 'Boa Vista'},
    {state: 'RR', value: 'Bonfim'},
    {state: 'RR', value: 'Cantá'},
    {state: 'RR', value: 'Caracaraí'},
    {state: 'RR', value: 'Caroebe'},
    {state: 'RR', value: 'Iracema'},
    {state: 'RR', value: 'Mucajaí'},
    {state: 'RR', value: 'Normandia'},
    {state: 'RR', value: 'Pacaraima'},
    {state: 'RR', value: 'Rorainópolis'},
    {state: 'RR', value: 'São João da Baliza'},
    {state: 'RR', value: 'São Luiz'},
    {state: 'RR', value: 'Uiramutã'},
    {state: 'AC', value: 'Acrelândia'},
    {state: 'AC', value: 'Assis Brasil'},
    {state: 'AC', value: 'Brasiléia'},
    {state: 'AC', value: 'Bujari'},
    {state: 'AC', value: 'Capixaba'},
    {state: 'AC', value: 'Cruzeiro do Sul'},
    {state: 'AC', value: 'Epitaciolândia'},
    {state: 'AC', value: 'Feijó'},
    {state: 'AC', value: 'Jordão'},
    {state: 'AC', value: 'Manoel Urbano'},
    {state: 'AC', value: 'Marechal Thaumaturgo'},
    {state: 'AC', value: 'Mâncio Lima'},
    {state: 'AC', value: 'Plácido de Castro'},
    {state: 'AC', value: 'Porto Acre'},
    {state: 'AC', value: 'Porto Walter'},
    {state: 'AC', value: 'Rio Branco'},
    {state: 'AC', value: 'Rodrigues Alves'},
    {state: 'AC', value: 'Santa Rosa do Purus'},
    {state: 'AC', value: 'Sena Madureira'},
    {state: 'AC', value: 'Senador Guiomard'},
    {state: 'AC', value: 'Tarauacá'},
    {state: 'AC', value: 'Xapuri'}
];
export default citiesNorte;
