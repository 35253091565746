/* eslint-disable no-unused-vars */
/* eslint-disable indent */
import Usuario from '@app/pages/Usuario';
import {Button} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import api from '@app/utils/api';
import routing from '@app/routes/routing';
import News from '../../../components/News';
import WalletHeader from '../../../components/WalletHeader';
// import Login from '@app/pages/Login/Login';~
// import {makeStyles, createStyles} from '@material-ui/core/styles';
// import Dialog from '@material-ui/core/Dialog';
// import Ticker from 'react-ticker';
// import {Link} from 'react-router-dom';
// import {useTranslation} from 'react-i18next';
// import Messages from './messages-dropdown/MessagesDropdown';
// import Notifications from './notifications-dropdown/NotificationsDropdown';
// import Languages from './languages-dropdown/LanguagesDropdown';
// import User from './user-dropdown/UserDropdown';

// eslint-disable-next-line no-unused-vars
const Header = ({toggleMenuSidebar, headerValues, refValue}) => {
    const [size, setSize] = useState(window.innerWidth);
    // const [open, setOpen] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const history = useHistory();
    const location = useLocation();

    // const [t] = useTranslation();

    /* const useStyles = makeStyles(() =>
        createStyles({
            menuPaper: {
                backgroundColor: 'black',
                position: 'bottom'
            }
        })
    ); */
    // const classes = useStyles();
    window.addEventListener('resize', () => {
        setSize(window.innerWidth);
    });

    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            {/* Left navbar links */}
            <ul className="navbar-nav">
                <li className="nav-item">
                    {size < 992 && (
                        <button
                            onClick={() => toggleMenuSidebar()}
                            type="button"
                            className="nav-link"
                            data-widget="pushmenu"
                            href="#"
                        >
                            <i className="fas fa-bars" />
                        </button>
                    )}
                </li>
            </ul>
            {location.pathname === '/grade-de-opcoes' ||
            location.pathname === '/mapa-de-opcoes' ||
            location.pathname === '/grade-de-opcoes-epm' ? (
                <div
                    className="col-sm-11"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingLeft: -50
                        // marginLeft: -50,
                    }}
                >
                    {headerValues && (
                        <p
                            style={{
                                marginTop: 9,
                                fontWeight: 'bold',
                                color: '#d36233',
                                paddingRigth: -50
                            }}
                        >
                            {headerValues.ativo} :
                        </p>
                    )}
                    <ul
                        style={{
                            width: '90%',
                            paddingTop: 15,
                            paddingBottom: -15
                        }}
                    >
                        <News headerValues={headerValues} />
                    </ul>
                </div>
            ) : null}

            {!api.currentSession ? (
                <div
                    style={{
                        marginLeft:
                            location.pathname === '/grade-de-opcoes' ||
                            location.pathname === '/mapa-de-opcoes' ||
                            location.pathname === '/grade-de-opcoes-epm'
                                ? '0px'
                                : '92%'
                    }}
                >
                    <Button
                        color="inherit"
                        style={{
                            backgroundColor: ' #e37039',
                            width: 80,
                            color: '#fff'
                        }}
                        size="small"
                        onClick={() => history.push(routing.login)}
                    >
                        Login
                    </Button>
                </div>
            ) : (
                <div style={{padding: 22}}>
                    <Usuario />
                </div>
            )}

            {/* <Dialog
                // fullWidth
                maxWidth="lg"
                open={open}
                onClose={() => setOpen(false)}
                classes={{
                    paper: classes.dialog
                }}
            >
                <Login />
            </Dialog> */}
            {/* <form className="form-inline ml-3">
                <div className="input-group input-group-sm">
                    <input
                        className="form-control form-control-navbar"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                    />
                    <div className="input-group-append">
                        <button className="btn btn-navbar" type="submit">
                            <i className="fas fa-search" />
                        </button>
                    </div>
                </div>
            </form> */}
            {/* <ul className="navbar-nav ml-auto"> */}
            {/* <Messages />
                <Notifications />
                <Languages /> */}
            {/* <User /> */}
            {/* <li className="nav-item">
          <button
            className="nav-link"
            data-widget="control-sidebar"
            data-slide="true"
            type="button"
          >
            <i className="fas fa-th-large" />
          </button>
        </li> */}
            {/* </ul> */}
        </nav>
    );
};

export default Header;
