/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable no-lonely-if */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import React, {useEffect, useState} from 'react';
import {Grid, createTheme} from '@material-ui/core';
import _, {template} from 'lodash';
import moment from 'moment';
import toast, {Toaster} from 'react-hot-toast';
import lightBlue from '@material-ui/core/colors/lightBlue';
import ReactTooltip from 'react-tooltip';
import {formatMoney, cleanNumber} from '@app/utils/myaux';

import {
    makeStyles,
    createStyles,
    ThemeProvider
} from '@material-ui/core/styles';

import Select from 'react-select';

import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/pt-BR';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import HeaderTable from './HeaderTable';
// import api from '@app/utils/api';

const isCalls = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L'];
const isPuts = ['M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X'];

const useStyles = makeStyles((theme) =>
    createStyles({
        myButton: {
            color: '#2a3176',
            backgroundColor: '#f4f4f4',
            fontWeight: 'bold'
        },
        myBox: {
            marginLeft: theme.spacing(1.2),
            marginBottom: -18
        },
        myGridVC: {
            minWidth: 15,
            maxHeight: 15,
            // margin: theme.spacing(0),
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            textAlign: 'center',
            cursor: 'pointer',
            border: '2px solid #c5c5c5'
        },
        myGridVCSelected: {
            minWidth: 15,
            maxHeight: 15,
            // margin: theme.spacing(0),
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            textAlign: 'center',
            cursor: 'pointer',
            backgroundColor: '#e37039',
            color: '#fff'
        },
        myGrid: {
            minWidth: 40,
            maxHeight: 20,
            maxWidth: 40,
            // backgroundColor: '#f4f4f4',
            // margin: theme.spacing(0),
            // marginTop: -2,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            textAlign: 'center',
            cursor: 'pointer',
            fontSize: 14,
            border: '2px solid #c5c5c5'
        },
        myGridSelected: {
            minWidth: 40,
            maxWidth: 40,
            maxHeight: 20,
            backgroundColor: '#e37039',
            // margin: theme.spacing(0),
            // marginTop: -2,
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 14,
            display: 'flex',
            textAlign: 'center',
            cursor: 'pointer',
            border: '2px solid #e37039',
            color: '#fff'
        },
        myLabel: {
            marginLeft: -4,
            color: '#e37039',
            fontSize: 14,
            padding: 0,
            margin: 0
            // fontWeight: 'bold'
        },
        myInput: {
            marginLeft: -15,
            color: '#e37039',
            fontSize: 14,
            padding: 0,
            margin: 0,
            border: 'none',
            maxWidth: 50,
            '&:focus': {
                outline: 'none'
            }

            // fontWeight: 'bold'
        },
        myLabel1: {
            marginLeft: -4,
            color: '#000',
            fontSize: 14,
            padding: 0,
            margin: 0
            // fontWeight: 'bold'
        },
        myLabelSelected: {
            padding: theme.spacing(6),
            color: '#fff',
            fontWeight: 'bold'
        },
        mySlider: {
            color: '#e37039',
            valueLabel: {
                zIndex: 10,
                left: 'calc(-50% + 12px)',
                top: -22,
                '& *': {
                    background: 'transparent',
                    color: '#000'
                }
            }
        },
        myTable: {}
    })
);

const materialTheme = createTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: '#e37039'
            },
            color: '#000'
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                // backgroundColor: lightBlue.A200,
                color: '#000'
            }
        },
        MuiPickersDay: {
            day: {
                color: '#000'
            },
            daySelected: {
                backgroundColor: '#e37039'
            },
            dayDisabled: {
                color: lightBlue['100']
            },
            current: {
                color: lightBlue['900']
            }
        },
        MuiPickersModal: {
            // dialogAction: {
            //     color: lightBlue['400']
            // },
            MuiDialogActions: {
                root: {
                    display: 'none'
                }
            }
        },
        MuiDialogActions: {
            root: {
                display: 'none'
            }
        }
    }
});

const styles = {
    control: (css) => ({
        ...css,
        padding: 0,
        margin: -6,
        border: 'none',
        boxShadow: 'none'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#e37039',
        fontSize: 12.5,
        margin: 0,
        padding: 0
    }),
    menu: ({width, ...css}) => ({
        ...css,
        width: 'max-content',
        minWidth: '10%'
        // color: '#cac6c2'
    }),
    container: (base) => ({
        ...base,
        minWidth: 65
        // backgroundColor: state.isSelected ? '#cac6c2' : '#fff'
        // flex: 1,
        // margin: 0,
        // padding: 0
    }),
    // Add padding to account for width of Indicators Container plus padding
    option: (css, state) => ({
        ...css,
        color: '#e37039',
        width: 70,
        backgroundColor: state.isSelected
            ? ''
            : '' || state.isHovered
            ? '#cac6c2'
            : 'transparent',
        '&:hover': {
            backgroundColor: '#cac6c2'
        }
    })
};

export default function TableStrategyMont({
    strategy,
    setStrategy,
    ativ,
    data,
    status
}) {
    const [cotTot, setCotTot] = useState(0);
    const [pTeoTot, setPTeoTot] = useState(0);
    const [total, setTotal] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [myIdx, setMyIdx] = useState();
    const [templateCot, setTemplateCot] = useState(['']);
    const [tickers, setTickers] = useState(false);
    const [origStrategy, setOrigStrategy] = useState();
    const [cont, setCont] = useState(0);
    const classes = useStyles();

    useEffect(() => {
        if (!_.isEqual(strategy, origStrategy)) {
            let tot = 0.0;
            let pteorico = 0;
            let cot = 0;
            if (!tickers) {
                const list = [];
                const listTicker = [];
                strategy.forEach((s) => {
                    s.vencOptFiltered = data && data[ativ]?.filters?.venc;
                    // console.log(s.pteorico);
                    // if (typeof s.total === 'number') {
                    tot += cleanNumber(s.total);
                    if (s.type === 'C') {
                        cot += cleanNumber(s.cotacao);
                        pteorico += cleanNumber(s.pteorico);
                    } else {
                        cot -= cleanNumber(s.cotacao);
                        pteorico -= cleanNumber(s.pteorico);
                    }
                    list.push(s.cotacao);
                    if (s.ticker?.value) listTicker.push(s.ticker?.value);
                });
                if (listTicker.length > 0) {
                    setTickers(listTicker);
                }
                setTemplateCot(list);
                setCotTot(cot);
                setPTeoTot(pteorico);
                setTotal(tot);
                setOrigStrategy(strategy);
            } else {
                const orig = [...tickers];
                const copy = [...templateCot];
                strategy.forEach((s, i) => {
                    if (orig.includes(s.ticker?.value)) {
                        s.vencOptFiltered = data && data[ativ]?.filters?.venc;
                        if (s.type === 'C') {
                            cot += cleanNumber(copy[i]);
                            pteorico += cleanNumber(s.pteorico);
                            tot += cleanNumber(
                                cleanNumber(copy[i]) * Number(s.qtd)
                            );
                        } else {
                            cot -= cleanNumber(copy[i]);
                            pteorico -= cleanNumber(s.pteorico);
                            tot -= cleanNumber(
                                cleanNumber(copy[i]) * Number(s.qtd)
                            );
                        }
                    } else {
                        s.vencOptFiltered = data && data[ativ]?.filters?.venc;
                        tot += cleanNumber(s.total);
                        if (s.type === 'C') {
                            cot += cleanNumber(s.cotacao);
                            pteorico += cleanNumber(s.pteorico);
                        } else {
                            cot -= cleanNumber(s.cotacao);
                            pteorico -= cleanNumber(s.pteorico);
                        }
                        copy[i] = s.cotacao;
                        orig[i] = s.ticker?.value;
                    }
                });
                if (!_.isEqual(templateCot, copy)) {
                    setTemplateCot(copy);
                }
                if (!_.isEqual(tickers, orig)) {
                    setTickers(orig);
                }
                setCotTot(cot);
                setPTeoTot(pteorico);
                setTotal(tot);
            }
        }
    }, [strategy]);

    const handleTemplateCot = (event, index) => {
        const copy = _.cloneDeep(templateCot);
        copy[index] = event.target.value;
        setTemplateCot(copy);
    };

    const handleTotal = (value, qtd, type) => {
        const result = cleanNumber(value) * cleanNumber(qtd);
        if (type === 'C') return result;
        return result * -1;
    };

    const handleTicker = (e, i) => {
        const orig = _.cloneDeep(strategy);
        orig[i].ticker = e;

        if (e.value !== ativ) {
            const o = orig[i].data[ativ].values.find(
                (f) => f.ticker === e.value
            );
            orig[i].venc = {
                label: moment(o.venc).format('DD/MM/YYYY'),
                value: o.venc
            };
            orig[i].vencOptFiltered = [
                {label: moment(o.venc).format('DD/MM/YYYY'), value: o.venc}
            ];

            if (!orig[i].cotacaoDesmont.changed) {
                orig[i].cotacaoDesmont.value = cleanNumber(o.ultimo);
                if (orig[i].type === 'C' || '') {
                    if (cleanNumber(orig[i].totalDesmont) >= 0) {
                        const result = cleanNumber(o.ultimo) * orig[i].qtd * -1;
                        orig[i].totalDesmont = formatMoney(result);
                    } else {
                        const result = cleanNumber(o.ultimo) * orig[i].qtd;
                        orig[i].totalDesmont = formatMoney(result);
                    }
                } else {
                    if (cleanNumber(orig[i].totalDesmont) >= 0) {
                        const result = cleanNumber(o.ultimo) * orig[i].qtd;
                        orig[i].totalDesmont = formatMoney(result);
                    } else {
                        const result = cleanNumber(o.ultimo) * orig[i].qtd * -1;
                        orig[i].totalDesmont = formatMoney(result);
                    }
                }
            }

            orig[i].cotacao = formatMoney(cleanNumber(o.ultimo));
            orig[i].total =
                orig[i].type === 'C' || ''
                    ? formatMoney(cleanNumber(o.ultimo) * orig[i].qtd)
                    : formatMoney(cleanNumber(o.ultimo) * orig[i].qtd * -1);

            orig[i].pteorico = formatMoney(cleanNumber(o.pteorico));
            orig[i].pteoricoDesmont = formatMoney(
                cleanNumber(o.pteoricoDesmont)
            );

            orig[i].strike = {label: o.strike, value: o.strike};
            orig[i].strikeOptFiltered = [{label: o.strike, value: o.strike}];

            orig[i].activeType = o.tipo === 'PUT' ? 'P' : 'C';
        } else {
            const o = orig[i].data[ativ].values.find((f, ii) => ii === 0);
            orig[i].cotacao = formatMoney(cleanNumber(o.cotacao));
            if (!orig[i].cotacaoDesmont.changed) {
                orig[i].cotacaoDesmont.value = formatMoney(
                    cleanNumber(o.cotacao)
                );

                if (orig[i].type === 'C' || '') {
                    if (cleanNumber(orig[i].totalDesmont) >= 0) {
                        const result =
                            cleanNumber(o.cotacao) * orig[i].qtd * -1;
                        orig[i].totalDesmont = formatMoney(result);
                    } else {
                        const result = cleanNumber(o.cotacao) * orig[i].qtd;
                        orig[i].totalDesmont = formatMoney(result);
                    }
                } else {
                    if (cleanNumber(orig[i].totalDesmont) >= 0) {
                        const result = cleanNumber(o.cotacao) * orig[i].qtd;
                        orig[i].totalDesmont = formatMoney(result);
                    } else {
                        const result =
                            cleanNumber(o.cotacao) * orig[i].qtd * -1;
                        orig[i].totalDesmont = formatMoney(result);
                    }
                }
            }
            orig[i].total =
                orig[i].type === 'C' || ''
                    ? formatMoney(cleanNumber(o.cotacao) * orig[i].qtd)
                    : formatMoney(cleanNumber(o.cotacao) * orig[i].qtd * -1);

            orig[i].activeType = '';
            orig[i].venc = null;
            orig[i].strike = null;
        }
        setStrategy(orig);
    };

    const handleChangeDate = (e) => {
        const orig = _.cloneDeep(strategy);
        orig[myIdx].dataMont = e;
        setStrategy(orig);
    };

    const handleStrike = (e, i) => {
        const orig = _.cloneDeep(strategy);
        let myType = null;
        if (orig[i].activeType) {
            myType = orig[i].activeType === 'C' ? 'CALL' : 'PUT';
        }
        // console.log(orig[i].data[ativ].values);
        orig[i].data[ativ].values.forEach((o) => {
            if (o.venc === orig[i].venc.value) {
                if (e.value === o.strike) {
                    if (myType === o.tipo) {
                        orig[i].ticker = {label: o.ticker, value: o.ticker};
                        orig[i].cotacao =
                            o.ticker === ativ
                                ? formatMoney(o.cotacao)
                                : formatMoney(o.ultimo);

                        if (!orig[i].cotacaoDesmont.changed) {
                            orig[i].cotacaoDesmont.value =
                                o.ticker === ativ
                                    ? formatMoney(o.cotacao)
                                    : formatMoney(o.ultimo);
                            if (o.ticker === ativ) {
                                if (orig[i].type === 'V' || '') {
                                    if (
                                        cleanNumber(orig[i].totalDesmont) <= 0
                                    ) {
                                        orig[i].totalDesmont = formatMoney(
                                            cleanNumber(o.cotacao) * orig[i].qtd
                                        );
                                    }
                                } else if (orig[i].type === 'C') {
                                    if (
                                        cleanNumber(orig[i].totalDesmont) >= 0
                                    ) {
                                        orig[i].totalDesmont = formatMoney(
                                            cleanNumber(o.cotacao) *
                                                orig[i].qtd *
                                                -1
                                        );
                                    }
                                }
                            } else {
                                if (orig[i].type === 'V' || '') {
                                    if (
                                        cleanNumber(orig[i].totalDesmont) <= 0
                                    ) {
                                        orig[i].totalDesmont = formatMoney(
                                            cleanNumber(o.ultimo) * orig[i].qtd
                                        );
                                    }
                                } else if (orig[i].type === 'C') {
                                    if (
                                        cleanNumber(orig[i].totalDesmont) >= 0
                                    ) {
                                        orig[i].totalDesmont = formatMoney(
                                            cleanNumber(o.ultimo) *
                                                orig[i].qtd *
                                                -1
                                        );
                                    }
                                }
                            }
                        }

                        orig[i].pteorico = formatMoney(o.pteorico);
                        orig[i].pteoricoDesmont = formatMoney(
                            o.pteoricoDesmont
                        );
                        if (o.ticker === ativ) {
                            orig[i].total =
                                orig[i].type === 'C' || ''
                                    ? formatMoney(
                                          cleanNumber(o.cotacao) * orig[i].qtd
                                      )
                                    : formatMoney(
                                          cleanNumber(o.cotacao) *
                                              orig[i].qtd *
                                              -1
                                      );
                        } else {
                            orig[i].total =
                                orig[i].type === 'C' || ''
                                    ? formatMoney(
                                          cleanNumber(o.ultimo) * orig[i].qtd
                                      )
                                    : formatMoney(
                                          cleanNumber(o.ultimo) *
                                              orig[i].qtd *
                                              -1
                                      );
                        }
                    }
                }
            }
        });
        orig[i].strike = e;
        setStrategy(orig);
    };

    const handleVenc = (e, i) => {
        const orig = _.cloneDeep(strategy);
        const list = [];
        orig[i].data[ativ].values.forEach((o) => {
            if (o.venc === e.value) {
                list.push(o.strike);
            }
        });
        const uniqueStrike = [...new Set(list)];

        const strikeFinal = uniqueStrike.slice().sort((a, b) => a - b);
        const tmp = [];
        strikeFinal.forEach((u) => {
            tmp.push({
                label: u,
                value: u
            });
        });
        orig[i].strikeOptFiltered = tmp;
        orig[i].venc = e;

        const ticker = [];
        const listStrike = [];

        orig[i].data[ativ].values.forEach((o) => {
            if (o.venc === orig[i]?.venc?.value) {
                listStrike.push(o.strike);
            }
            if (orig[i].strike && orig[i].venc) {
                if (
                    o.strike === orig[i]?.strike.value &&
                    o.venc === orig[i]?.venc.value &&
                    o.tipo.at(0) === orig[i]?.activeType
                ) {
                    // console.log(o.ticker);
                    ticker.push({
                        label: o.ticker,
                        value: o.ticker
                    });
                }
            }
        });
        const myType = orig[i].activeType === 'C' ? 'CALL' : 'PUT';

        orig[i].data[ativ].values.forEach((o) => {
            if (orig[i].venc && o.venc === orig[i].venc.value) {
                if (orig[i].strike && orig[i].strike.value === o.strike) {
                    if (myType === o.tipo) {
                        // orig[i].ticker = {label: o.ticker, value: o.ticker};
                        orig[i].cotacao =
                            o.ticker === ativ
                                ? formatMoney(o.cotacao)
                                : formatMoney(o.ultimo);

                        if (!orig[i].cotacaoDesmont.changed) {
                            orig[i].cotacaoDesmont.value =
                                o.ticker === ativ
                                    ? formatMoney(o.cotacao)
                                    : formatMoney(o.ultimo);
                            if (o.ticker === ativ) {
                                if (orig[i].type === 'V' || '') {
                                    if (
                                        cleanNumber(orig[i].totalDesmont) <= 0
                                    ) {
                                        orig[i].totalDesmont = formatMoney(
                                            cleanNumber(o.cotacao) * orig[i].qtd
                                        );
                                    }
                                } else if (orig[i].type === 'C') {
                                    if (
                                        cleanNumber(orig[i].totalDesmont) >= 0
                                    ) {
                                        orig[i].totalDesmont = formatMoney(
                                            cleanNumber(o.cotacao) *
                                                orig[i].qtd *
                                                -1
                                        );
                                    }
                                }
                            } else {
                                if (orig[i].type === 'V' || '') {
                                    if (
                                        cleanNumber(orig[i].totalDesmont) <= 0
                                    ) {
                                        orig[i].totalDesmont = formatMoney(
                                            cleanNumber(o.ultimo) * orig[i].qtd
                                        );
                                    }
                                } else if (orig[i].type === 'C') {
                                    if (
                                        cleanNumber(orig[i].totalDesmont) >= 0
                                    ) {
                                        orig[i].totalDesmont = formatMoney(
                                            cleanNumber(o.ultimo) *
                                                orig[i].qtd *
                                                -1
                                        );
                                    }
                                }
                            }
                        }
                        orig[i].pteorico = formatMoney(o.pteorico);
                        orig[i].pteoricoDesmont = formatMoney(
                            o.pteoricoDesmont
                        );
                        if (o.ticker === ativ) {
                            orig[i].total =
                                orig[i].type === 'C' || ''
                                    ? formatMoney(
                                          cleanNumber(o.cotacao) * orig[i].qtd
                                      )
                                    : formatMoney(
                                          cleanNumber(o.cotacao) *
                                              orig[i].qtd *
                                              -1
                                      );
                        } else {
                            orig[i].total =
                                orig[i].type === 'C' || ''
                                    ? formatMoney(
                                          cleanNumber(o.ultimo) * orig[i].qtd
                                      )
                                    : formatMoney(
                                          cleanNumber(o.ultimo) *
                                              orig[i].qtd *
                                              -1
                                      );
                        }
                    }
                }
            }
        });
        // const uniqueStrike = [...new Set(listStrike)];

        // const strikeFinal = uniqueStrike.slice().sort((a, b) => a - b);
        const tmpStrike = [];
        strikeFinal.forEach((u) => {
            tmpStrike.push({
                label: u,
                value: u
            });
        });

        orig[i].strikeOptFiltered = tmpStrike;
        // orig[i].vencOptFiltered = tmpVenc;
        orig[i].strike = orig[i].strike && ticker[0] ? orig[i].strike : null;
        orig[i].ticker = ticker[0] ? ticker[0] : null;

        /*  orig[i].cotacao = 0;
        orig[i].cotacaoDesmont.value = 0;
        orig[i].cotacaoDesmont.changed = false;
        orig[i].pteorico = 0;
        orig[i].pteoricoDesmont = 0;
        orig[i].strike = null;
        orig[i].ticker = null;
        orig[i].total = 0;
        orig[i].totalDesmont = 0; */

        setStrategy(orig);
    };

    // DEFININDO SE É CALL OU PUT
    const handleAtivType = (e, i) => {
        const orig = _.cloneDeep(strategy);
        orig[i].activeType = e;
        const list = [];
        // APLICANDO FILTRO
        if (e === 'C') {
            orig[i].data[ativ].values.forEach((o) => {
                const find = isCalls.find(
                    (f) => f === o.ticker.substring(4, 5)
                );
                if (find) {
                    list.push(o.venc);
                }
            });
        } else if (e === 'P') {
            orig[i].data[ativ].values.forEach((o) => {
                const find = isPuts.find((f) => f === o.ticker.substring(4, 5));
                if (find) {
                    list.push(o.venc);
                }
            });
        }
        const uniqueVenc = [...new Set(list)];
        const tmpVenc = [];
        uniqueVenc.sort();
        uniqueVenc.forEach((u) => {
            tmpVenc.push({
                label: moment(u).format('DD/MM/YYYY'),
                value: u
            });
        });

        const ticker = [];
        const listStrike = [];

        orig[i].data[ativ].values.forEach((o) => {
            if (o.venc === orig[i]?.venc?.value) {
                listStrike.push(o.strike);
            }
            if (orig[i].strike && orig[i].venc) {
                if (
                    o.strike === orig[i]?.strike.value &&
                    o.venc === orig[i]?.venc.value /* &&
                    o.tipo.at(0) === orig[i]?.activeType */
                ) {
                    ticker.push({
                        label: o.ticker,
                        value: o.ticker
                    });
                }
            }
        });
        const myType = orig[i].activeType === 'C' ? 'CALL' : 'PUT';

        orig[i].data[ativ].values.forEach((o) => {
            if (orig[i].venc && o.venc === orig[i].venc.value) {
                if (orig[i].strike && orig[i].strike.value === o.strike) {
                    if (myType === o.tipo) {
                        // orig[i].ticker = {label: o.ticker, value: o.ticker};
                        orig[i].cotacao =
                            o.ticker === ativ
                                ? formatMoney(o.cotacao)
                                : formatMoney(o.ultimo);

                        if (!orig[i].cotacaoDesmont.changed) {
                            orig[i].cotacaoDesmont.value =
                                o.ticker === ativ
                                    ? formatMoney(o.cotacao)
                                    : formatMoney(o.ultimo);
                            if (o.ticker === ativ) {
                                if (orig[i].type === 'V' || '') {
                                    if (
                                        cleanNumber(orig[i].totalDesmont) <= 0
                                    ) {
                                        orig[i].totalDesmont = formatMoney(
                                            cleanNumber(o.cotacao) * orig[i].qtd
                                        );
                                    }
                                } else if (orig[i].type === 'C') {
                                    if (
                                        cleanNumber(orig[i].totalDesmont) >= 0
                                    ) {
                                        orig[i].totalDesmont = formatMoney(
                                            cleanNumber(o.cotacao) *
                                                orig[i].qtd *
                                                -1
                                        );
                                    }
                                }
                            } else {
                                if (orig[i].type === 'V' || '') {
                                    if (
                                        cleanNumber(orig[i].totalDesmont) <= 0
                                    ) {
                                        orig[i].totalDesmont = formatMoney(
                                            cleanNumber(o.ultimo) * orig[i].qtd
                                        );
                                    }
                                } else if (orig[i].type === 'C') {
                                    if (
                                        cleanNumber(orig[i].totalDesmont) >= 0
                                    ) {
                                        orig[i].totalDesmont = formatMoney(
                                            cleanNumber(o.ultimo) *
                                                orig[i].qtd *
                                                -1
                                        );
                                    }
                                }
                            }
                        }
                        orig[i].pteorico = formatMoney(o.pteorico);
                        orig[i].pteoricoDesmont = formatMoney(
                            o.pteoricoDesmont
                        );
                        if (o.ticker === ativ) {
                            orig[i].total =
                                orig[i].type === 'C' || ''
                                    ? formatMoney(
                                          cleanNumber(o.cotacao) * orig[i].qtd
                                      )
                                    : formatMoney(
                                          cleanNumber(o.cotacao) *
                                              orig[i].qtd *
                                              -1
                                      );
                        } else {
                            orig[i].total =
                                orig[i].type === 'C' || ''
                                    ? formatMoney(
                                          cleanNumber(o.ultimo) * orig[i].qtd
                                      )
                                    : formatMoney(
                                          cleanNumber(o.ultimo) *
                                              orig[i].qtd *
                                              -1
                                      );
                        }
                    }
                }
            }
        });
        const uniqueStrike = [...new Set(listStrike)];

        const strikeFinal = uniqueStrike.slice().sort((a, b) => a - b);
        const tmpStrike = [];
        strikeFinal.forEach((u) => {
            tmpStrike.push({
                label: u,
                value: u
            });
        });

        orig[i].strikeOptFiltered = tmpStrike;
        orig[i].vencOptFiltered = tmpVenc;
        orig[i].strike = orig[i].strike && ticker[0] ? orig[i].strike : null;
        orig[i].ticker = ticker[0] ? ticker[0] : null;

        setStrategy(orig);
    };

    const handleType = (e, i) => {
        const orig = _.cloneDeep(strategy);
        orig[i].type = e;
        if (e === 'V') {
            if (cleanNumber(orig[i].total) > 0) {
                const tmp = cleanNumber(orig[i].total) * -1;
                orig[i].total = formatMoney(tmp);
            } else {
                const ini = cleanNumber(orig[i].total);
                // const tmp = ini * -1;
                orig[i].total = formatMoney(ini);
            }

            if (cleanNumber(orig[i].totalDesmont) < 0) {
                // if (typeof orig[i].totalDesmont === 'number') {
                const tmp = cleanNumber(orig[i].totalDesmont) * -1;
                orig[i].totalDesmont = formatMoney(tmp);
            } else {
                const ini = cleanNumber(orig[i].totalDesmont);
                // console.log(ini);
                // const tmp = ini * -1;
                orig[i].totalDesmont = formatMoney(ini);
            }
            // }
        }
        if (e === 'C') {
            if (cleanNumber(orig[i].total) < 0) {
                // if (typeof orig[i].total === 'number') {
                const tmp = cleanNumber(orig[i].total) * -1;
                orig[i].total = formatMoney(tmp);
            } else {
                const ini = cleanNumber(orig[i].total);
                // const tmp = ini * -1;
                orig[i].total = formatMoney(ini);
            }
            // }
            if (cleanNumber(orig[i].totalDesmont) > 0) {
                // if (typeof orig[i].totalDesmont === 'number') {
                const tmp = cleanNumber(orig[i].totalDesmont) * -1;
                orig[i].totalDesmont = formatMoney(tmp);
            } else {
                const ini = cleanNumber(orig[i].totalDesmont);
                // const tmp = ini * -1;
                orig[i].totalDesmont = formatMoney(ini);
            }
            // }
        }
        setStrategy(orig);
    };

    // const handleType = (e, i) => {
    //     console.log(e);
    //     const orig = _.cloneDeep(strategy);
    //     console.log(orig[i].totalDesmont);
    //     orig[i].type = e;
    //     if (e === 'V') {
    //         if (cleanNumber(orig[i].total) > 0) {
    //             if (typeof orig[i].total === 'number') {
    //                 const tmp = cleanNumber(orig[i].total) * -1;
    //                 orig[i].total = formatMoney(tmp);
    //             } else {
    //                 const ini = cleanNumber(orig[i].total);
    //                 const tmp = ini * -1;
    //                 orig[i].total = formatMoney(tmp);
    //             }
    //         }
    //         if (cleanNumber(orig[i].totalDesmont) < 0) {
    //             if (typeof orig[i].totalDesmont === 'number') {
    //                 const tmp = cleanNumber(orig[i].totalDesmont) * -1;
    //                 orig[i].totalDesmont = formatMoney(tmp);
    //             } else {
    //                 const ini = cleanNumber(orig[i].totalDesmont);
    //                 const tmp = ini * -1;
    //                 orig[i].totalDesmont = formatMoney(tmp);
    //             }
    //         }
    //     }
    //     if (e === 'C') {
    //         if (cleanNumber(orig[i].total) < 0) {
    //             if (typeof orig[i].total === 'number') {
    //                 const tmp = cleanNumber(orig[i].total) * -1;
    //                 orig[i].total = formatMoney(tmp);
    //             } else {
    //                 const ini = cleanNumber(orig[i].total);
    //                 const tmp = ini * -1;
    //                 orig[i].total = formatMoney(tmp);
    //             }
    //         }
    //         if (cleanNumber(orig[i].totalDesmont) > 0) {
    //             if (typeof orig[i].totalDesmont === 'number') {
    //                 const tmp = cleanNumber(orig[i].totalDesmont) * -1;
    //                 orig[i].totalDesmont = formatMoney(tmp);
    //             } else {
    //                 const ini = cleanNumber(orig[i].totalDesmont);
    //                 const tmp = ini * -1;
    //                 orig[i].totalDesmont = formatMoney(tmp);
    //             }
    //         }
    //     }
    //     setStrategy(orig);
    // };

    // const handleCot = (e, i) => {
    //     const calc = e.replace(',', '.');
    //     const orig = _.cloneDeep(strategy);
    //     orig[i].cotacao = calc;
    //     orig[i].total =
    //         orig[i].type === 'C' || ''
    //             ? Number(calc) * orig[i].qtd
    //             : Number(calc) * orig[i].qtd * -1;
    //     if (!orig[i].cotacaoDesmont.changed) {
    //         orig[i].cotacaoDesmont.value = calc;
    //         // orig[i].totalDesmont = (Number(calc) * orig[i].qtd).toFixed(2);
    //         orig[i].totalDesmont =
    //             orig[i].type === 'C' || ''
    //                 ? Number(calc) * orig[i].qtd
    //                 : Number(calc) * orig[i].qtd * -1;
    //     }
    //     setStrategy(orig);
    // };

    const handleCot = (e, i) => {
        const calc = e.replace(',', '.');
        const orig = _.cloneDeep(strategy);
        orig[i].cotacao = calc;
        // orig[i].total =
        //     orig[i].type === 'C' || ''
        //         ? formatMoney(calc * orig[i].qtd)
        //         : formatMoney(calc * orig[i].qtd * -1);
        if (!orig[i].cotacaoDesmont.changed) {
            orig[i].cotacaoDesmont.value = formatMoney(calc);
            // orig[i].totalDesmont =
            //     orig[i].type === 'C' || ''
            //         ? formatMoney(calc * orig[i].qtd)
            //         : formatMoney(calc * orig[i].qtd * -1);
        }
        setStrategy(orig);
    };

    const handleKeyUp = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            event.target.blur();
        }
    };

    const formatCot = (e, i) => {
        const calc = cleanNumber(e);
        const orig = _.cloneDeep(strategy);
        orig[i].cotacao = formatMoney(calc);

        orig[i].total =
            orig[i].type === 'C' || ''
                ? formatMoney(calc * orig[i].qtd)
                : formatMoney(calc * orig[i].qtd * -1);

        if (!orig[i].cotacaoDesmont.changed) {
            orig[i].cotacaoDesmont.value = formatMoney(calc);
            orig[i].totalDesmont =
                orig[i].type === 'C' || ''
                    ? formatMoney(calc * orig[i].qtd)
                    : formatMoney(calc * orig[i].qtd * -1);
            // orig[i].totalDesmont = (Number(calc) * orig[i].qtd).toFixed(2);
        }
        setStrategy(orig);
    };

    const handleQtd = (e, i) => {
        const calc = e.replace(/[^0-9]/g, '');
        const orig = _.cloneDeep(strategy);
        orig[i].qtd = calc;
        // orig[i].total = Number(calc) * orig[i].cotacao;
        if (orig[i].type === 'C' || '') {
            const result = Number(calc) * cleanNumber(orig[i].cotacao);
            if (result % 1 !== 0 && !Number.isNaN(result % 1)) {
                orig[i].total = formatMoney(result);
                // new Intl.NumberFormat('pt-BR', {
                //     style: 'currency',
                //     currency: 'BRL'
                // })
                //     .format(result)
                //     .replace(',', '.');
            } else {
                orig[i].total = formatMoney(result);
                // new Intl.NumberFormat('pt-BR', {
                //     style: 'currency',
                //     currency: 'BRL'
                // })
                //     .format(result)
                //     .replace(',', '.');
            }
        } else {
            const result = Number(calc) * cleanNumber(orig[i].cotacao) * -1;
            if (result % 1 !== 0 && !Number.isNaN(result % 1)) {
                orig[i].total = formatMoney(result);
                // new Intl.NumberFormat('pt-BR', {
                //     style: 'currency',
                //     currency: 'BRL'
                // })
                //     .format(result)
                //     .replace(',', '.');
            } else {
                orig[i].total = formatMoney(result);
                // new Intl.NumberFormat('pt-BR', {
                //     style: 'currency',
                //     currency: 'BRL'
                // })
                //     .format(result)
                //     .replace(',', '.');
            }
        }
        // orig[i].total =
        //     orig[i].type === 'C' || ''
        //         ? Number(calc) * orig[i].cotacao
        //         : Number(calc) * orig[i].cotacao * -1;
        if (!orig[i].cotacaoDesmont.changed) {
            // orig[i].totalDesmont = Number(calc) * orig[i].cotacaoDesmont.value;
            if (orig[i].type === 'C' || '') {
                orig[i].totalDesmont = formatMoney(
                    Number(calc) * cleanNumber(orig[i].cotacaoDesmont.value)
                );
            } else {
                orig[i].totalDesmont = formatMoney(
                    Number(calc) *
                        cleanNumber(orig[i].cotacaoDesmont.value) *
                        -1
                );
            }
            // orig[i].totalDesmont =
            //     orig[i].type === 'C' || ''
            //         ? Number(calc) * orig[i].cotacaoDesmont
            //         : Number(calc) * orig[i].cotacaoDesmont * -1;
        }

        // if (typeof orig[i].total === 'number') {
        //     const tmp = orig[i].total * -1;
        //     orig[i].total = tmp.toString();
        // } else {
        //     const ini = orig[i].total.replace('.', '');
        //     const tmp = ini * -1;
        //     orig[i].total = tmp.toString();
        // }
        setStrategy(orig);
    };

    const handleDel = (i) => {
        if (strategy.length === 1) {
            setStrategy([
                {
                    type: '',
                    qtd: '1000',
                    activeType: '',
                    venc: null,
                    strike: null,
                    ticker: null,
                    cotacao: '0',
                    cotacaoDesmont: {changed: false, value: '0'},
                    pteorico: '0',
                    pteoricoDesmont: '0',
                    total: '0',
                    totalDesmont: '0',
                    data,
                    tickerOptFiltered: data[ativ]?.filters?.tikers,
                    allOptStrike: [],
                    strikeOptFiltered: [],
                    dataMont: new Date(),
                    dataDesmont: new Date(),
                    mustUpdated: true
                }
            ]);
            toast.success('Carteira limpa!', {
                iconTheme: {
                    primary: '#d36233',
                    secondary: '#fff'
                }
            });
        } else {
            const orig = _.cloneDeep(strategy);
            orig.splice(i, 1);
            setStrategy(orig);
        }
    };

    const handleClean = (i) => {
        const clean = _.cloneDeep(strategy);
        clean[i].type = '';
        clean[i].qtd = '1000';
        clean[i].activeType = '';
        clean[i].venc = null;
        clean[i].strike = null;
        clean[i].ticker = null;
        clean[i].cotacao = '0';
        clean[i].cotacaoDesmont = {changed: false, value: '0'};
        clean[i].pteorico = '0';
        clean[i].pteoricoDesmont = '0';
        clean[i].total = '0';
        clean[i].totalDesmont = '0';
        clean[i].dataDesmont = new Date();
        clean[i].dataMont = new Date();
        clean[i].mustUpdated = true;
        setStrategy(clean);
    };

    const openCalendar = (i) => {
        setIsOpen(true);
        setMyIdx(i);
    };

    const notify = (ticker) =>
        toast.success(`Ticker ${ticker} copiado!`, {
            iconTheme: {
                primary: '#d36233',
                secondary: '#fff'
            }
        }) && navigator.clipboard.writeText(ticker);

    /* React.useEffect(() => {
        console.log(strategy);
    }, [strategy]); */

    return (
        <>
            <Toaster />
            <table
                className="table"
                style={{
                    fontSize: 11,
                    width: '100%'
                }}
            >
                <HeaderTable />
                <tbody>
                    <MuiPickersUtilsProvider
                        locale={locale}
                        utils={DateFnsUtils}
                    >
                        {strategy.map((s, idx) => (
                            <tr
                                key={s.total}
                                style={{
                                    whiteSpace: 'nowrap',
                                    marginTop: '-5px',
                                    marginBottom: '-10px',
                                    height: '5px',
                                    pointerEvents:
                                        status && s.mustUpdated
                                            ? 'auto'
                                            : 'none'
                                }}
                            >
                                <td
                                    style={{display: 'flex', marginTop: '-1px'}}
                                >
                                    <ion-icon
                                        name="trash-outline"
                                        style={{
                                            color: '#e37039',
                                            cursor: 'pointer',
                                            fontSize: 15
                                        }}
                                        onClick={() => handleDel(idx)}
                                    />
                                    <ion-icon
                                        name="refresh-outline"
                                        style={{
                                            color: '#e37039',
                                            cursor: 'pointer',
                                            fontSize: 15
                                        }}
                                        onClick={() => handleClean(idx)}
                                    />
                                    <Grid
                                        className={
                                            s.type === 'C'
                                                ? classes.myGridVCSelected
                                                : classes.myGridVC
                                        }
                                        style={{marginRight: 3, marginLeft: 3}}
                                        // className={
                                        //     tradeType === 'Compra'
                                        //         ? classes.myGridVCSelected
                                        //         : classes.myGridVC
                                        // }
                                        // onClick={() => handleChangeSell('Compra')}
                                        onClick={() => handleType('C', idx)}
                                    >
                                        C
                                    </Grid>
                                    <Grid
                                        className={
                                            s.type === 'V'
                                                ? classes.myGridVCSelected
                                                : classes.myGridVC
                                        }
                                        // className={
                                        //     tradeType === 'Venda'
                                        //         ? classes.myGridVCSelected
                                        //         : classes.myGridVC
                                        // }
                                        // onClick={() => handleChangeSell('Venda')}
                                        onClick={() => handleType('V', idx)}
                                        style={{
                                            marginLeft: '3px'
                                        }}
                                    >
                                        V
                                    </Grid>
                                </td>
                                <td>
                                    <ion-icon
                                        data-tip={moment(s.dataMont).format(
                                            'DD/MM/YYYY'
                                        )}
                                        name="calendar-number-outline"
                                        style={{
                                            color: '#e37039',
                                            cursor: 'pointer',
                                            fontSize: 15
                                        }}
                                        onClick={() => openCalendar(idx)}
                                    />
                                    {/* <MuiPickersUtilsProvider
                                        locale={locale}
                                        utils={DateFnsUtils}
                                    > */}
                                    <ReactTooltip />
                                    <ThemeProvider theme={materialTheme}>
                                        <DatePicker
                                            open={isOpen}
                                            onOpen={() => setIsOpen(true)}
                                            onClose={() => setIsOpen(false)}
                                            value={s.dataMont}
                                            onChange={(e) =>
                                                handleChangeDate(e)
                                            }
                                            TextFieldComponent={() => null}
                                            maxDate={new Date()}
                                            autoOk
                                        />
                                    </ThemeProvider>
                                    {/* </MuiPickersUtilsProvider> */}
                                </td>
                                <td
                                    align="center"
                                    style={{fontFamily: 'Roboto'}}
                                >
                                    {/* <p className={classes.myLabel}>1000</p> */}
                                    <input
                                        className={classes.myInput}
                                        defaultValue={s.qtd}
                                        onBlur={(e) =>
                                            handleQtd(e.target.value, idx)
                                        }
                                        onKeyUp={handleKeyUp}

                                        /*  onChange={(e) =>
                                            handleQtd(e.target.value, idx)
                                        } */
                                    />
                                </td>
                                <td
                                    style={{
                                        display: 'flex',
                                        // justifyContent: 'center',
                                        marginTop: '-1px',
                                        borderTop:
                                            idx === 0 ? '1px solid #fff' : ''
                                    }}
                                >
                                    {s.ticker?.value !== ativ ? (
                                        <>
                                            <Grid
                                                className={
                                                    s.activeType === 'C' &&
                                                    s.activeType !== ''
                                                        ? classes.myGridSelected
                                                        : classes.myGrid
                                                }
                                                style={{
                                                    marginRight: 3,
                                                    marginLeft: 3
                                                }}
                                                onClick={() =>
                                                    handleAtivType('C', idx)
                                                }
                                            >
                                                CALL
                                            </Grid>
                                            <Grid
                                                className={
                                                    s.activeType === 'P' &&
                                                    s.activeType !== ''
                                                        ? classes.myGridSelected
                                                        : classes.myGrid
                                                }
                                                onClick={() =>
                                                    handleAtivType('P', idx)
                                                }
                                                style={{
                                                    marginLeft: '3px'
                                                }}
                                            >
                                                PUT
                                            </Grid>
                                        </>
                                    ) : (
                                        <Grid style={{height: 15}} />
                                    )}
                                </td>
                                <td
                                    align="left"
                                    style={{
                                        whiteSpace: 'nowrap',
                                        fontFamily: 'Roboto'
                                    }}
                                >
                                    {s.ticker?.value !== ativ ? (
                                        <Select
                                            onChange={(e) => handleVenc(e, idx)}
                                            isSearchable
                                            value={s.venc}
                                            placeholder="Selecione"
                                            styles={styles}
                                            options={s.vencOptFiltered}
                                            components={{
                                                DropdownIndicator: () => null,
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                    ) : (
                                        <Grid style={{height: 15}} />
                                    )}
                                </td>

                                <td
                                    align="center"
                                    style={{
                                        whiteSpace: 'nowrap',
                                        fontFamily: 'Roboto'
                                    }}
                                >
                                    {s.ticker?.value !== ativ ? (
                                        <Select
                                            onChange={(e) =>
                                                handleStrike(e, idx)
                                            }
                                            isSearchable
                                            value={s.strike}
                                            placeholder="Selecione"
                                            styles={styles}
                                            options={s.strikeOptFiltered}
                                            components={{
                                                DropdownIndicator: () => null,
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                    ) : (
                                        <Grid style={{height: 15}} />
                                    )}
                                </td>
                                <td
                                    align="center"
                                    style={{
                                        whiteSpace: 'nowrap',
                                        fontFamily: 'Roboto'
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex'
                                            // justifyContent: 'space-between'
                                        }}
                                    >
                                        <Select
                                            // onChange={(e) => searchFromTicker(e, dadosRow.id)}
                                            // isLoading
                                            onChange={(e) =>
                                                handleTicker(e, idx)
                                            }
                                            isSearchable
                                            value={s.ticker}
                                            placeholder="Selecione"
                                            styles={styles}
                                            options={s.tickerOptFiltered}
                                            components={{
                                                DropdownIndicator: () => null,
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                        {s.ticker && (
                                            <ion-icon
                                                onClick={() =>
                                                    notify(s.ticker.label)
                                                }
                                                style={{
                                                    fontSize: 12,
                                                    color: '#d36233',
                                                    cursor: 'pointer',
                                                    marginLeft: 5
                                                }}
                                                name="copy-outline"
                                            />
                                        )}
                                    </div>
                                </td>
                                <td
                                    align="right"
                                    style={{
                                        whiteSpace: 'nowrap',
                                        fontFamily: 'Roboto'
                                        // paddingLeft: '-5px'
                                    }}
                                >
                                    {/* <p className={classes.myLabel}>0.38</p> */}
                                    <input
                                        // type="number"
                                        className={classes.myInput}
                                        value={templateCot[idx]}
                                        onChange={(e) =>
                                            handleTemplateCot(e, idx)
                                        }
                                        onBlur={(e) =>
                                            formatCot(e.target.value, idx)
                                        }
                                        onKeyUp={handleKeyUp}
                                    />
                                </td>
                                <td
                                    align="center"
                                    style={{
                                        whiteSpace: 'nowrap',
                                        fontFamily: 'Roboto'
                                    }}
                                >
                                    {s.ticker?.value !== ativ ? (
                                        <p className={classes.myLabel1}>
                                            {s?.pteorico}
                                        </p>
                                    ) : null}
                                </td>
                                <td
                                    align="end"
                                    style={{
                                        whiteSpace: 'nowrap',
                                        fontFamily: 'Roboto'
                                    }}
                                >
                                    <p className={classes.myLabel1}>
                                        {formatMoney(
                                            handleTotal(
                                                templateCot[idx],
                                                s.qtd,
                                                s.type
                                            )
                                        )}
                                        {/* {new Intl.NumberFormat('pt-BR', {
                                            style: 'currency',
                                            currency: 'BRL'
                                        })
                                            .format(s.total)
                                            .replace(',', '.')} */}
                                    </p>
                                </td>
                            </tr>
                        ))}
                    </MuiPickersUtilsProvider>
                    <tr>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td>
                            <div
                                style={{
                                    textAlign: 'center',
                                    fontSize: 16,
                                    fontWeight: 'bold'
                                }}
                            >
                                TOTAL
                            </div>
                        </td>
                        <td
                            align="right"
                            className={classes.myLabel}
                            style={{fontFamily: 'Roboto'}}
                        >
                            {new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL'
                            })
                                .format(cotTot)
                                .replace(',', '.')}
                        </td>
                        <td
                            align="center"
                            className={classes.myLabel1}
                            style={{fontFamily: 'Roboto'}}
                        >
                            {new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL'
                            })
                                .format(pTeoTot)
                                .replace(',', '.')}
                        </td>
                        <td
                            align="end"
                            className={classes.myLabel1}
                            style={{fontFamily: 'Roboto'}}
                        >
                            {new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL'
                            })
                                .format(total)
                                .replace(',', '.')}
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}
