/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, {useState, useEffect} from 'react';
import ReactTooltip from 'react-tooltip';
import Pagination from '@material-ui/lab/Pagination';

import {makeStyles} from '@material-ui/core/styles';
// import moment from 'moment';

const useStyles = makeStyles(() => ({
    root: {
        '& .Mui-selected': {
            backgroundColor: '#e37039',
            '&:hover': {
                backgroundColor: '#e37039'
            }
        }
    },
    tableRow: {
        height: 10,
        padding: '0px 16px',
        '&:hover': {
            background: '#efefef'
        }
    }
}));

export default function Table({active, dt1, tableLabel, notify}) {
    const classes = useStyles();
    const [page, setPage] = useState(1);
    const rowsPerPage = 25;

    useEffect(() => {
        if (active && page !== 1) {
            // console.log(page);
            setPage(1);
        }
    }, [active]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    return (
        <>
            <table className="table">
                <thead>
                    <tr
                        style={{
                            whiteSpace: 'nowrap'
                        }}
                    >
                        {tableLabel.ticker.selected && (
                            <th
                                style={{
                                    color: '#d36233'
                                }}
                            >
                                <ReactTooltip id="opcoes" />
                                OP
                                <span
                                    style={{
                                        fontFamily: 'Rubik',
                                        fontWeight: '300',
                                        fontSize: 16.5
                                    }}
                                >
                                    Ç
                                </span>
                                ÃO
                                <span
                                    style={{cursor: 'pointer'}}
                                    data-for="opcoes"
                                    data-tip="Ticker - Abreviação utilizada para identificar exclusivamente contratos de opções negociadas publicamente."
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </th>
                        )}
                        {tableLabel.tipo.selected && (
                            <th
                                style={{
                                    color: '#d36233'
                                }}
                            >
                                <ReactTooltip id="tipo" />
                                {tableLabel.tipo.value}
                                <span
                                    style={{cursor: 'pointer'}}
                                    data-for="tipo"
                                    data-tip="CALL - contrato de compra ou PUT - contrato de venda"
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </th>
                        )}
                        {tableLabel.mod.selected && (
                            <th
                                style={{
                                    color: '#d36233'
                                }}
                            >
                                <ReactTooltip id="mod" />
                                {tableLabel.mod.value}
                                <span
                                    data-for="mod"
                                    style={{cursor: 'pointer'}}
                                    data-tip="Modalidade - Americana - permite o exercício a qualquer momento e Europeia - o exercício da opção acontece apenas no dia do vencimento."
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </th>
                        )}
                        {tableLabel.money.selected && (
                            <th
                                style={{
                                    color: '#d36233'
                                }}
                            >
                                <ReactTooltip
                                    id="money"
                                    place="right"
                                    data-html
                                    multiline
                                    insecure
                                />
                                {tableLabel.money.value}
                                <span
                                    style={{cursor: 'pointer'}}
                                    data-for="money"
                                    data-tip="Moneyness - Classificação do contrato de opção em relação ao preço do ativo. <br/> OTM - out of the money, ou fora do dinheiro, <br/> ATM - at the money, ou no dinheiro <br/> e ITM - in the money, ou dentro do dinheiro."
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </th>
                        )}
                        {tableLabel.strike.selected && (
                            <th
                                style={{
                                    color: '#d36233'
                                }}
                            >
                                <ReactTooltip id="strike" />
                                {tableLabel.strike.value}
                                <span
                                    data-for="strike"
                                    style={{cursor: 'pointer'}}
                                    data-tip="Strike - Preço de exercício do contrato de opções."
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </th>
                        )}
                        {tableLabel.intrinseco.selected && (
                            <th
                                style={{
                                    color: '#d36233'
                                }}
                            >
                                <ReactTooltip id="vi" />
                                {tableLabel.intrinseco.value}
                                <span
                                    data-for="vi"
                                    style={{cursor: 'pointer'}}
                                    data-tip="V.I. - Valor intrínseco é o valor imediato de uma opção em relação ao preço do ativo-objeto."
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </th>
                        )}
                        {tableLabel.extriseco.selected && (
                            <th
                                style={{
                                    color: '#d36233'
                                }}
                            >
                                <ReactTooltip id="ve" />
                                {tableLabel.extriseco.value}
                                <span
                                    data-for="ve"
                                    style={{cursor: 'pointer'}}
                                    data-tip="V.E. - Valor extrínseco da opção é o prêmio de uma opção atribuído ao risco ou ao custo de oportunidade e expectativas sobre a evolução do ativo-objeto."
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </th>
                        )}
                        {tableLabel.ultimo.selected && (
                            <th
                                style={{
                                    color: '#d36233'
                                }}
                            >
                                <ReactTooltip id="ultimo" />
                                {tableLabel.ultimo.value}
                                <span
                                    data-for="ultimo"
                                    style={{cursor: 'pointer'}}
                                    data-tip="ULTIMO - Último negócio realizado."
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </th>
                        )}
                        {tableLabel.negocio.selected && (
                            <th
                                style={{
                                    color: '#d36233'
                                }}
                            >
                                <ReactTooltip id="negocio" />
                                {tableLabel.negocio.value}
                                <span
                                    data-for="negocio"
                                    style={{cursor: 'pointer'}}
                                    data-tip="NEGÓCIOS - Quantidade de negócios realizados."
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </th>
                        )}
                        {tableLabel.vol.selected && (
                            <th
                                style={{
                                    color: '#d36233'
                                }}
                            >
                                <ReactTooltip id="vol" />
                                {tableLabel.vol.value}
                                <span
                                    data-for="vol"
                                    style={{cursor: 'pointer'}}
                                    data-tip="VOLUME - Volume financeiro dos negócios realizados."
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </th>
                        )}
                        {tableLabel.data.selected && (
                            <th
                                style={{
                                    color: '#d36233'
                                }}
                            >
                                <ReactTooltip id="data" />
                                {tableLabel.data.value}
                                <span
                                    data-for="data"
                                    style={{cursor: 'pointer'}}
                                    data-tip="DATA - Data do último negócio realizado."
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </th>
                        )}
                        {tableLabel.implicita.selected && (
                            <th
                                style={{
                                    color: '#d36233'
                                }}
                            >
                                <ReactTooltip id="imp" />
                                {tableLabel.implicita.value}
                                <span
                                    data-for="imp"
                                    style={{cursor: 'pointer'}}
                                    data-tip="VOL. IMPLÍCITA - Volatilidade implícita da opção, de acordo com modelo Black&Scholes e Newton Raphson."
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </th>
                        )}
                        {tableLabel.pteorico.selected && (
                            <th
                                style={{
                                    color: '#d36233'
                                }}
                            >
                                <ReactTooltip id="pteorico" />
                                {tableLabel.pteorico.value}
                                <span
                                    data-for="pteorico"
                                    style={{cursor: 'pointer'}}
                                    data-tip="P. TEÓRICO - Preço teórico da opção, seguindo modelo Black&Scholes"
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </th>
                        )}
                        {tableLabel.delta.selected && (
                            <th
                                style={{
                                    color: '#d36233'
                                }}
                            >
                                <ReactTooltip id="delta" />
                                {tableLabel.delta.value}
                                <span
                                    data-for="delta"
                                    style={{cursor: 'pointer'}}
                                    data-tip="DELTA - Representa a variação da opção em relação ao preço da ação-objeto."
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </th>
                        )}
                        {tableLabel.gamma.selected && (
                            <th
                                style={{
                                    color: '#d36233'
                                }}
                            >
                                <ReactTooltip id="gamma" />

                                {tableLabel.gamma.value}
                                <span
                                    data-for="gamma"
                                    style={{cursor: 'pointer'}}
                                    data-tip="GAMMA - Taxa da variação do Delta em relação ao deslocamento do preço da ação-objeto."
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </th>
                        )}
                        {tableLabel.theta.selected && (
                            <th
                                style={{
                                    color: '#d36233'
                                }}
                            >
                                <ReactTooltip id="theta" />
                                {tableLabel.theta.value}
                                <span
                                    data-for="theta"
                                    style={{cursor: 'pointer'}}
                                    data-tip="THETA - Taxa de desvalorização da opção ao longo do tempo."
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </th>
                        )}
                        {tableLabel.vega.selected && (
                            <th
                                style={{
                                    color: '#d36233'
                                }}
                            >
                                <ReactTooltip id="vega" />
                                {tableLabel.vega.value}
                                <span
                                    data-for="vega"
                                    style={{cursor: 'pointer'}}
                                    data-tip="VEGA - Taxa da mudança do valor da Opção em relação a uma variação na volatilidade do ativo-objeto."
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody
                    style={{
                        fontFamily: 'Roboto, sans-serif',
                        fontWeight: 400,
                        fontSize: 14
                    }}
                >
                    {dt1
                        .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                        .map((v, ii) => (
                            <tr key={ii} className={classes.tableRow}>
                                {tableLabel.ticker.selected && (
                                    <td
                                        style={{
                                            whiteSpace: 'nowrap'
                                        }}
                                    >
                                        {v.ticker}
                                        <span
                                            title="Copiar"
                                            style={{cursor: 'pointer'}}
                                            // onClick={() => notify(v.ticker)}
                                            onClick={() => notify(v.ticker)}
                                        >
                                            <ion-icon
                                                // onClick={() => notify(v.ticker)}
                                                style={{
                                                    fontSize: 12,
                                                    color: '#d36233',
                                                    pointerEvents: 'none',
                                                    marginLeft: 5
                                                }}
                                                name="copy-outline"
                                            />
                                        </span>
                                        <ReactTooltip id="fm" />
                                        {v.fm !== '0' ? (
                                            <span
                                                data-for="fm"
                                                style={{cursor: 'pointer'}}
                                                data-tip="FM - Formador de mercado"
                                            >
                                                <ion-icon
                                                    style={{
                                                        marginBottom: 2,
                                                        fontSize: 12,
                                                        color: '#d36233',
                                                        pointerEvents: 'none'
                                                    }}
                                                    name="person-circle-outline"
                                                />
                                            </span>
                                        ) : (
                                            ''
                                        )}
                                    </td>
                                )}
                                {tableLabel.tipo.selected && <td>{v.tipo}</td>}
                                {tableLabel.mod.selected && <td>{v.mod}</td>}
                                {tableLabel.money.selected && (
                                    <td>{v.money}</td>
                                )}
                                {tableLabel.strike.selected && (
                                    <td>R${v.strike}</td>
                                )}
                                {
                                    /* eslint-disable indent */
                                    tableLabel.intrinseco.selected && (
                                        <td>
                                            R$
                                            {v.vi}
                                        </td>
                                    )
                                }
                                {
                                    /* eslint-disable indent */
                                    tableLabel.extriseco.selected && (
                                        <td>
                                            R$
                                            {v.ve}
                                        </td>
                                    )
                                }
                                {tableLabel.ultimo.selected && (
                                    <td>
                                        {v.ultimo !== null
                                            ? `R$${v.ultimo}`
                                            : v.ultimo}
                                    </td>
                                )}
                                {tableLabel.negocio.selected && (
                                    <td>{v.negocios}</td>
                                )}
                                {tableLabel.vol.selected && (
                                    <td>
                                        {v.vol !== null ? `R$${v.vol}` : ''}
                                    </td>
                                )}
                                {tableLabel.data.selected && (
                                    <td>
                                        {v.data}
                                        {/* {moment(v.data).format('DD/MM/YYYY')} */}
                                    </td>
                                )}
                                {tableLabel.implicita.selected && (
                                    <td>{v.implicita}</td>
                                )}
                                {tableLabel.pteorico.selected && (
                                    <td>R${v.pteorico > 0 ? v.pteorico : 0}</td>
                                )}
                                {tableLabel.delta.selected && (
                                    <td>{Number(v.delta).toFixed(2)}</td>
                                )}
                                {tableLabel.gamma.selected && (
                                    <td>{Number(v.gamma).toFixed(2)}</td>
                                )}
                                {tableLabel.theta.selected && (
                                    <td>{Number(v.theta).toFixed(4)}</td>
                                )}
                                {tableLabel.vega.selected && (
                                    <td>{Number(v.vega).toFixed(2)}</td>
                                )}
                            </tr>
                        ))}
                </tbody>
            </table>
            <Pagination
                count={Math.ceil(Number(dt1.length / rowsPerPage))}
                page={page}
                onChange={handleChangePage}
                // onChangeRowsPerPage={handleChangeRowsPerPage}
                // rowsPerPage={rowsPerPage}
                className={classes.root}
            />
        </>
    );
}
