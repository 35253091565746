import React from 'react';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import Main from '@modules/main/Main';
import Home from '@app/pages/Home';
// import MobileDetecter from '@app/components/MobileDetecter';
import routing from './routes/routing';
// import Login from '@modules/login/Login';
// import Charts from '@pages/Charts';
// import Money from '@pages/Money';
// import Dashboard from '@pages/Dashboard';
// import Register from '@modules/register/Register';
// import ForgetPassword from '@modules/forgot-password/ForgotPassword';
// import RecoverPassword from '@modules/recover-password/RecoverPassword';
// import PrivacyPolicy from '@modules/privacy-policy/PrivacyPolicy';

import PublicRoute from './routes/PublicRoute';

// import PrivateRoute from './routes/PrivateRoute';
// import PrivateRoute from './firebase/PrivateRoute';
import {AuthProvider} from './firebase/Auth';
import history from './routes/history';

import './App.scss';

const App = () => {
    return (
        <Router history={history}>
            <AuthProvider>
                <Switch>
                    {/* <PublicRoute exact path="/login" component={Login} /> */}
                    {/* <PublicRoute exact path="/money" component={Money} />
                    <PublicRoute exact path="/charts" component={Charts} /> */}
                    {/* <PublicRoute exact path="/" component={Main} /> */}
                    <PublicRoute exact path={routing.home} component={Home} />
                    <PublicRoute
                        exact
                        path={routing.dashboard}
                        component={Main}
                    />
                    <PublicRoute exact path={routing.termos} component={Main} />
                    <PublicRoute exact path={routing.charts} component={Main} />
                    <PublicRoute exact path={routing.money} component={Main} />
                    <PublicRoute exact path={routing.test} component={Main} />
                    <PublicRoute exact path={routing.login} component={Main} />
                    <PublicRoute exact path={routing.roex} component={Main} />
                    <PublicRoute
                        exact
                        path={routing.mercado}
                        component={Main}
                    />
                    <PublicRoute
                        exact
                        path={routing.userpage}
                        component={Main}
                    />

                    {/* <PublicRoute exact path="/" component={Dashboard} /> */}
                    {/* <Login /> */}
                    {/* </PublicRoute> */}
                    {/* <PublicRoute exact path="/register">
                        <Register />
                    </PublicRoute>
                    <PublicRoute exact path="/forgot-password">
                        <ForgetPassword />
                    </PublicRoute>
                    <PublicRoute exact path="/recover-password">
                        <RecoverPassword />
                    </PublicRoute>
                    <PublicRoute exact path="/privacy-policy">
                        <PrivacyPolicy />
                    </PublicRoute>
                    <PublicRoute exact path="/callback">
                        <h1>Callback</h1>
                    </PublicRoute> */}
                    {/* <PrivateRoute path="/" component={Main} /> */}
                    {/* <Main />
                    </PrivateRoute> */}
                </Switch>
            </AuthProvider>
        </Router>
    );
};

export default App;
