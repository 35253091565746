/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */

import React, {useState, useEffect} from 'react';
import {Card, Modal, Row, Col, Button} from 'react-bootstrap';
import api from '@app/utils/api';
import {cleanNumber} from '@app/utils/myaux';
import {Grid, Box} from '@material-ui/core';
import moment from 'moment';
import WebFont from 'webfontloader';
import Loading from './loading/Loading';
import NewStrategy from '../pages/wallet/strateggi/NewStrategy';

export default function CardWallet({card, setCards, cards, status, selic}) {
    const stonks = ((1000 - 1) / 1) * 100;
    const [data, setData] = useState({});
    const [open, setOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [myAtiv, setMyAtiv] = useState('');
    const [strategys, setStrategys] = useState();
    const [name, setName] = useState();
    const [editId, setEditId] = useState();
    const [cotIni, setCotIni] = useState();
    const [cotFim, setCotFim] = useState();

    const handleClose = () => {
        setOpen(false);
        setData();
    };
    const defaultFilters = {
        btnType: {call: true, put: true},
        btnModel: {europe: true, america: true},
        btnMoneyness: {ITM: false, ATM: false, OTM: false},
        strike: {min: 0, start: 0, end: 5000, max: 5000},
        strikeList: [],
        tikers: [],
        venc: []
    };

    const handleOpen = (myCard) => {
        console.log(myCard.Strategys);
        setStrategys(myCard.Strategys);
        setEditId(myCard.id);
        setName(myCard.name);
        setCotIni(myCard.cotacao_start);
        setIsOpen(card.status);
        const ativ = myCard.ativo;
        setMyAtiv(ativ);
        if (ativ !== '') {
            setLoading(true);
            api.options
                .getCotacoesPorAtivo(ativ)
                .then((res) => {
                    const listVenc = [];
                    const listStrikes = [];
                    const listTikers = [];
                    const tmp = {
                        filters: defaultFilters,
                        values: []
                    };
                    res.data.retorno.dados.forEach((resp, i) => {
                        // strikeFilters.push(parseFloat(resp.strike));
                        if (i === 0) {
                            listTikers.push({
                                label: ativ,
                                value: ativ
                            });
                        }

                        let vi;
                        let ve;
                        if (resp.tipo === 'PUT') {
                            if (
                                cleanNumber(resp.strike) <
                                cleanNumber(resp.cotacao)
                            ) {
                                vi = 0;
                                ve = cleanNumber(resp.pteorico);
                            } else {
                                vi =
                                    cleanNumber(resp.strike) -
                                    cleanNumber(resp.cotacao);
                                ve =
                                    cleanNumber(resp.pteorico) -
                                    cleanNumber(vi);
                            }
                        } else if (
                            cleanNumber(resp.strike) > cleanNumber(resp.cotacao)
                        ) {
                            vi = 0;
                            ve = cleanNumber(resp.pteorico);
                        } else {
                            vi =
                                cleanNumber(resp.cotacao) -
                                cleanNumber(resp.strike);
                            ve =
                                cleanNumber(resp.pteorico) +
                                cleanNumber(resp.strike) -
                                cleanNumber(resp.cotacao);
                        }
                        listVenc.push(resp.vencimentos);

                        listStrikes.push(cleanNumber(resp.strike));
                        listTikers.push({
                            label: resp.ticker,
                            value: resp.ticker
                        });
                        tmp.values.push({
                            ticker: resp.ticker.split('_')[0],
                            fm: resp.fm,
                            money: resp.otm,
                            mod: resp.mod === 'A' ? 'AMERICANA' : 'EUROPÉIA',
                            strike: resp.strike,
                            tipo: resp.tipo,
                            ultimo: resp.ultimo,
                            negocios: resp.num_neg,
                            vol: resp.vol_neg,
                            data: resp.data,
                            implicita: (
                                cleanNumber(resp.vol_impl) * 100
                            ).toFixed(2),
                            pteorico: resp.pteorico,
                            pteoricoDesmont: resp.pteorico,
                            delta: resp.new_delta,
                            gamma: resp.new_gamma,
                            theta: resp.new_theta,
                            vega: resp.new_vega,
                            venc: resp.vencimentos,
                            cotacao: resp.cotacao,
                            name: ativ,
                            vi: vi.toFixed(2),
                            ve: ve.toFixed(2)
                        });
                    });
                    const uniqueVenc = [...new Set(listVenc)];
                    const uniqueStrike = [...new Set(listStrikes)];
                    const strikeFinal = uniqueStrike
                        .slice()
                        .sort((a, b) => a - b);
                    uniqueVenc.sort();
                    uniqueVenc.forEach((u, i) => {
                        tmp.filters.venc.push({
                            label: moment(u).format('DD/MM/YYYY'),
                            value: u
                        });
                    });
                    strikeFinal.forEach((u, i) => {
                        tmp.filters.strikeList.push({
                            label: u,
                            value: u
                        });
                        // if (i === 0) {
                        //     tmp.filters.venc.push({label: u, status: true});
                        // } else {
                        //     tmp.filters.venc.push({label: u, status: false});
                        // }
                    });
                    tmp.filters.tikers = listTikers;
                    setData({[ativ]: tmp});
                    setCotFim(
                        myCard.status
                            ? tmp.values[0].cotacao
                            : myCard.cotacao_end
                    );
                    // setOpenModalAtv(false);
                    setLoading(false);
                    setOpen(true);
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        }
    };

    const calcCredito = (strategy) => {
        let totMont = 0;
        let desMont = 0;
        strategy.forEach((s) => {
            if (s.type === 'V') {
                totMont += cleanNumber(s.preco_mont) * cleanNumber(s.qtd) * -1;
                desMont +=
                    cleanNumber(s.preco_desmont) * cleanNumber(s.qtd) * -1;
            } else {
                totMont += cleanNumber(s.preco_mont) * cleanNumber(s.qtd);
                desMont += cleanNumber(s.preco_desmont) * cleanNumber(s.qtd);
            }
        });
        if (desMont < 0) totMont = 0;
        return totMont;
    };

    const calcAloc = (strategy) => {
        let totMont = 0;
        strategy.forEach((s) => {
            if (s.type === 'V') {
                totMont += cleanNumber(s.preco_mont) * cleanNumber(s.qtd) * -1;
            } else {
                totMont += cleanNumber(s.preco_mont) * cleanNumber(s.qtd);
            }
        });
        // if(totMont < 0) {
        //     totMont *=-1;
        // }
        return totMont;
    };

    const calcDays = (strategy) => {
        let myDate = new Date(strategy[0]?.date_desmont);
        let initialDate = new Date(strategy[0]?.date_mont);

        strategy.forEach((s) => {
            myDate = moment(s.date_desmont).isAfter(myDate)
                ? s.date_desmont
                : myDate;

            initialDate = moment(s?.date_mont).isBefore(initialDate)
                ? s.date_mont
                : initialDate;
        });

        myDate = new Date();
        const tmp = moment(initialDate);
        const sumDays = moment(myDate);
        const dif = Math.ceil(sumDays.diff(tmp, 'days', true));
        return dif;
    };

    const calcDesmont = (strategy) => {
        let totDesMont = 0;
        strategy.forEach((s) => {
            if (s.type === 'V') {
                totDesMont += cleanNumber(s.preco_desmont) * cleanNumber(s.qtd);
            } else {
                totDesMont +=
                    cleanNumber(s.preco_desmont) * cleanNumber(s.qtd) * -1;
            }
        });
        // return totMont;
        return totDesMont;
    };

    /* const calcLucro = (totMont, totDesm) => {
        let lucro = 0;
        if (totMont < 0) {
            totMont *= -1;
            if (totDesm < 0) {
                totDesm *= -1;
            }
            lucro = totMont - totDesm;
        } else {
            if (totDesm < 0) {
                totDesm *= -1;
            }
            lucro = totDesm - totMont;
        }
        return lucro;
    }; */

    const calcLucro = (totMont, totDesm) => {
        let lucro = 0;
        // credito e credito
        if (totMont < 0 && totDesm < 0) {
            //  lucro
            totMont *= -1;
            lucro = totMont - totDesm;
        } else if (totMont < 0 && totDesm > 0) {
            totMont *= -1;
            if (totMont > totDesm) {
                lucro = totMont - totDesm;
            } else {
                // -> Prejuizo
                lucro = totMont - totDesm;
            }
        } else if (totMont > 0 && totDesm > 0) {
            // Prejuizo

            lucro = (totMont + totDesm) * -1;
        } else if (totMont > 0 && totDesm < 0) {
            // lucro

            totDesm *= -1;
            if (totMont < totDesm) {
                // -> lucro

                lucro = totDesm - totMont;
            } else {
                lucro = totDesm - totMont;
            }
        }
        return lucro;
    };

    const calcRoi = (strategy) => {
        let capital = calcAloc(strategy);
        if (capital < 0) {
            capital *= -1;
        }
        const lucro = calcLucro(
            calcAloc(card.Strategys),
            calcDesmont(card.Strategys)
        );
        let tmp = 0;
        // if (lucro >= 0) {
        tmp = (lucro * 0.85) / capital;
        // } else {
        //     tmp = 0;
        // }
        if (Number.isNaN(tmp)) {
            return 0;
        }
        return tmp;
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto']
            }
        });
    }, []);

    return (
        <>
            <Loading open={loading} msg="Carregando carteira..." />
            <Card
                onClick={() => handleOpen(card)}
                style={{
                    width: '18rem',
                    cursor: 'pointer',
                    // minWidth: '22rem',
                    // marginLeft: '11px',
                    padding: 0,
                    height: '12rem',
                    marginBottom: '10px'
                }}
                // key={card.id}
            >
                <Card.Header
                    variant="top"
                    style={{
                        background: '#313031',
                        color: '#fff',
                        height: '52%',
                        paddingRight: 0,
                        paddingLeft: 0
                    }}
                >
                    <div
                        style={{
                            display: 'inline',
                            marginLeft: '92%'
                        }}
                    >
                        <ion-icon
                            /* onClick={() =>
                                      navigator.clipboard.writeText(
                                          v.ticker
                                          )
                                      } */
                            style={{
                                fontSize: 19,
                                color: '#e37039',
                                cursor: 'pointer'
                                // marginLeft: 2
                            }}
                            name={
                                card.status
                                    ? 'lock-open-outline'
                                    : 'lock-closed-outline'
                            }
                            // onClick={() => handleOpenEditCard(card.card_id)}
                        />
                        {/* <ion-icon
                            name="close-outline"
                            style={{
                                fontSize: 27,
                                color: '#e37039',
                                cursor: 'pointer',
                                marginBottom: -4
                            }}
                        /> */}
                    </div>
                    <div style={{justifyContent: 'center', display: 'flex'}}>
                        <h1
                            style={{
                                fontSize:
                                    card.name.length < 28
                                        ? '20px'
                                        : card.name.length < 31
                                        ? '18px'
                                        : '16px',
                                textAlign: 'center',
                                marginTop:
                                    card.name.length < 31
                                        ? '0px'
                                        : card.name.length < 34
                                        ? '2px'
                                        : '-14px',
                                width:
                                    card.name.length < 34
                                        ? ''
                                        : card.name.length < 40
                                        ? '180px'
                                        : '220px'
                            }}
                        >
                            {card.name}
                        </h1>
                    </div>
                    <div
                        style={{
                            background: '#cac6c7',
                            width: '95px',
                            height: '55px',
                            borderRadius: '15%',
                            color: '#000',
                            margin: 'auto',
                            justifyContent: 'center',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <h1
                            style={{
                                // position: 'absolute',
                                // top: stonks.length < 5 ? '30%' : '35%',
                                // marginLeft: stonks.length < 5 ? '30%' : '0%',
                                // smarginBottom: '-0',
                                // marginTop: '1px',
                                paddingTop: '8px',
                                fontSize: stonks.length < 5 ? 28 : 23
                                // textAlign: 'center'
                            }}
                        >
                            {1 > 0 ? (
                                <span
                                    style={{
                                        color: '#e37039',
                                        paddingRight: 5,
                                        paddingLeft: 2
                                    }}
                                >
                                    {/* + */}
                                </span>
                            ) : (
                                <span
                                    style={{
                                        color: '#e37039',
                                        paddingRight: 5,
                                        paddingLeft: 2
                                    }}
                                >
                                    -
                                </span>
                            )}
                            {/* {`${calcRoi(card.Strategys)}%`} */}
                            {new Intl.NumberFormat('pt-BR', {
                                style: 'percent',
                                minimumFractionDigits: 2,
                                currency: 'BRL'
                            })
                                .format(calcRoi(card.Strategys))
                                .replace(',', '.')}
                        </h1>
                    </div>
                </Card.Header>
                <Card.Body
                    style={{
                        maxHeight: '40px',
                        paddingRight: 0,
                        paddingLeft: 0
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            marginTop: '30px',
                            fontSize: 11.5,
                            justifyContent: 'space-between',
                            padding: 0
                        }}
                    >
                        <div
                            style={{
                                borderRight: '2px solid #c5c5c5',
                                textAlign: 'center'
                            }}
                        >
                            <span
                                style={{
                                    color: '#e37039',
                                    fontWeight: 'bold',
                                    display: 'inline-block',
                                    margin: '0px 10px 0px 5px'
                                }}
                            >
                                {new Intl.NumberFormat('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL'
                                })
                                    .format(calcCredito(card.Strategys))
                                    .replace(',', '.')
                                    .replace('-', '')}
                            </span>
                            <div
                                style={{
                                    margin: '0px 10px 0px 5px',
                                    fontFamily: 'Roboto'
                                }}
                            >
                                CAP. ALOCADO
                            </div>
                        </div>
                        <div
                            style={{
                                borderRight: '2px solid #c5c5c5',
                                textAlign: 'center'
                            }}
                        >
                            <span
                                style={{
                                    color: '#e37039',
                                    fontWeight: 'bold',
                                    display: 'inline-block',
                                    margin: '0px 10px 0px 5px'
                                }}
                            >
                                {/* {calcDesmont(card.Strategys) -
                                    calcAloc(card.Strategys)} */}
                                {new Intl.NumberFormat('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL'
                                })
                                    .format(
                                        Number.isNaN(
                                            calcLucro(
                                                calcAloc(card.Strategys),
                                                calcDesmont(card.Strategys)
                                            )
                                        )
                                            ? 0
                                            : calcLucro(
                                                  calcAloc(card.Strategys),
                                                  calcDesmont(card.Strategys)
                                              )
                                    )
                                    .replace(',', '.')
                                    .replace('-', '')}
                            </span>
                            <div
                                style={{
                                    margin: '0px 10px 0px 5px',
                                    fontFamily: 'Roboto'
                                }}
                            >
                                {calcLucro(
                                    calcAloc(card.Strategys),
                                    calcDesmont(card.Strategys)
                                ) >= 0
                                    ? 'LUCRO'
                                    : 'PREJUÍZO'}
                            </div>
                        </div>
                        <div
                            style={{
                                borderRight: '2px solid #c5c5c5',
                                textAlign: 'center'
                            }}
                        >
                            <span
                                style={{
                                    color: '#e37039',
                                    fontWeight: 'bold',
                                    display: 'inline-block',
                                    margin: '0px 10px 0px 5px'
                                }}
                            >
                                {calcDays(card.Strategys)}
                            </span>
                            <div
                                style={{
                                    margin: '0px 10px 0px 5px',
                                    fontFamily: 'Roboto'
                                }}
                            >
                                TEMPO
                            </div>
                        </div>
                        <div style={{textAlign: 'center', marginRight: '5px'}}>
                            <span
                                style={{
                                    color: '#e37039',
                                    fontWeight: 'bold',
                                    display: 'inline-block',
                                    margin: '0px 0px 0px 5px'
                                }}
                            >
                                {card.ativo}
                            </span>
                            <div
                                style={{
                                    margin: '0px 1px 0px 5px',
                                    fontFamily: 'Roboto'
                                }}
                            >
                                ATIVO
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Modal show={open} onClose={handleClose} size="xl">
                <NewStrategy
                    handleClose={handleClose}
                    ativ={myAtiv}
                    data={data}
                    status={isOpen}
                    setStatus={setIsOpen}
                    cards={cards}
                    setCards={setCards}
                    myStrategy={strategys}
                    id={editId}
                    myName={name}
                    cotIni={cotIni}
                    cotFim={cotFim}
                    selic={selic}
                />
            </Modal>
        </>
    );
}
