/* eslint-disable no-plusplus */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable no-lonely-if */

import {cleanNumber} from '@app/utils/myaux';
import _ from 'lodash';

export const identifier = (array, ativo) => {
    /* identificador de Spread */
    if (array) {
        const size = array.length;
        const orig = _.cloneDeep(array);
        const sorted = orig.sort((a, b) => a?.strike?.value - b?.strike?.value);
        let callCounter = 0;
        let putCounter = 0;
        let shortCounter = 0;
        let longCounter = 0;
        let equalStrike = 0;
        let sameQtd = 0;
        let equalVenc = 0;
        orig.forEach((o) => {
            if (o?.activeType === 'P') {
                putCounter += 1;
            } else if (o?.activeType === 'C') {
                callCounter += 1;
            }
            if (o.type === 'V') {
                shortCounter += 1;
            } else if (o.type === 'C') {
                longCounter += 1;
            }
            if (o.qtd === orig[0].qtd) {
                sameQtd += 1;
            }
            if (o?.strike?.value === orig[0]?.strike?.value) {
                equalStrike += 1;
            }
            if (o?.venc?.value === orig[0]?.venc?.value) {
                equalVenc += 1;
            }
        });
        if (size === 2) {
            if (array[0].strike && array[1].strike) {
                let diff =
                    cleanNumber(sorted[0]?.strike?.value) -
                    cleanNumber(sorted[1]?.strike?.value);
                if (diff < 0) diff *= -1;
                if (
                    longCounter !== size &&
                    array[0].activeType === array[1].activeType &&
                    array[0]?.venc?.value !== array[1]?.venc?.value &&
                    diff < 0.5
                ) {
                    return 'Spread';
                }
                if (equalVenc === size && sameQtd === size) {
                    if (equalStrike === size) {
                        if (longCounter === size && callCounter !== size) {
                            return 'Long Straddle';
                        }
                        if (shortCounter === size && callCounter !== size) {
                            return 'Short straddle';
                        }
                    } else {
                        if (
                            longCounter === size &&
                            callCounter !== size &&
                            sorted[0].activeType === 'P' &&
                            sorted[0]?.strike?.value > sorted[1]?.strike?.value
                        ) {
                            return 'Long Strangle';
                        }
                        if (
                            longCounter === size &&
                            callCounter !== size &&
                            sorted[0].activeType === 'C' &&
                            sorted[0]?.strike?.value > sorted[1]?.strike?.value
                        ) {
                            return 'Long Gut Strangle';
                        }
                        if (
                            shortCounter === size &&
                            callCounter !== size &&
                            sorted[0].activeType === 'P' &&
                            sorted[0]?.strike?.value > sorted[1]?.strike?.value
                        ) {
                            return 'Short Strangle';
                        }
                        if (
                            shortCounter === size &&
                            sorted[0].activeType === 'C' &&
                            callCounter !== size &&
                            sorted[0]?.strike?.value > sorted[1]?.strike?.value
                        ) {
                            return 'Short Gut Strangle';
                        }
                    }
                } else if (equalVenc === size && equalStrike === size) {
                    if (
                        longCounter === size &&
                        sorted[0].activeType === 'P' &&
                        sorted[0].qtd > sorted[1].qtd
                    ) {
                        return 'Long Strip';
                    }
                    if (
                        shortCounter === size &&
                        sorted[0].activeType === 'P' &&
                        sorted[0].qtd > sorted[1].qtd
                    ) {
                        return 'Short Strip';
                    }
                    if (
                        longCounter === size &&
                        sorted[0].activeType === 'C' &&
                        sorted[0].qtd > sorted[1].qtd
                    ) {
                        return 'Long Strap';
                    }
                    if (
                        shortCounter === size &&
                        sorted[0].activeType === 'C' &&
                        sorted[0].qtd > sorted[1].qtd
                    ) {
                        return 'Short Strap';
                    }
                } else {
                    if (
                        sorted[0].type === 'V' &&
                        shortCounter !== size &&
                        callCounter === size &&
                        sorted[0]?.venc?.value < sorted[1]?.venc?.value &&
                        diff < 0.5
                    ) {
                        return 'Long Call Calendar';
                    }
                    if (
                        sorted[0].type === 'V' &&
                        shortCounter !== size &&
                        putCounter === size &&
                        sorted[0]?.venc?.value < sorted[1]?.venc?.value &&
                        diff < 0.5
                    ) {
                        return 'Long Put Calendar';
                    }
                    if (
                        sorted[0].type === 'V' &&
                        longCounter !== size &&
                        callCounter === size &&
                        sorted[0]?.venc?.value > sorted[1]?.venc?.value &&
                        sorted[0]?.strike?.value !== sorted[1]?.strike?.value
                    ) {
                        return 'Long Call Diagonal';
                    }
                    if (
                        sorted[0].type === 'V' &&
                        longCounter !== size &&
                        putCounter === size &&
                        sorted[0]?.venc?.value > sorted[1]?.venc?.value &&
                        sorted[0]?.strike?.value !== sorted[1]?.strike?.value
                    ) {
                        return 'Long Put Diagonal';
                    }
                    if (
                        sorted[0].type === 'C' &&
                        shortCounter !== size &&
                        callCounter === size &&
                        sorted[0]?.venc?.value < sorted[1]?.venc?.value &&
                        diff < 0.5
                    ) {
                        return 'Short Call Calendar';
                    }
                    if (
                        sorted[0].type === 'C' &&
                        putCounter === size &&
                        sorted[0]?.venc?.value < sorted[1]?.venc?.value &&
                        diff < 0.5
                    ) {
                        return 'Short Put Calendar';
                    }
                    if (
                        sorted[0].type === 'C' &&
                        shortCounter !== size &&
                        callCounter === size &&
                        sorted[0]?.venc?.value < sorted[1]?.venc?.value &&
                        sorted[0]?.strike?.value !== sorted[1]?.strike?.value
                    ) {
                        return 'Short Call Diagonal';
                    }
                    if (
                        sorted[0].type === 'C' &&
                        shortCounter !== size &&
                        putCounter === size &&
                        sorted[0]?.venc?.value < sorted[1]?.venc?.value &&
                        sorted[0]?.strike?.value !== sorted[1]?.strike?.value
                    ) {
                        return 'Short Put Diagonal';
                    }
                }
                return null;
            }
            // console.log(array[1]?.ticker?.value, ativo);
            if (
                longCounter === size &&
                sameQtd === size &&
                sorted[1].activeType === 'P' &&
                sorted[0]?.ticker?.value === ativo
            ) {
                return 'Long Call Synthetic';
            }
            if (
                sorted[1].type === 'V' &&
                shortCounter !== size &&
                sorted[1].activeType === 'C' &&
                sorted[0]?.ticker?.value === ativo &&
                sameQtd === size
            ) {
                return 'Covered Call';
            }
            if (
                sorted[1].type === 'C' &&
                shortCounter !== size &&
                sorted[1].activeType === 'C' &&
                sorted[0]?.ticker?.value === ativo &&
                sameQtd !== size
            ) {
                return 'Long Call Synthetic Straddle';
            }
            if (
                sorted[1].type === 'V' &&
                shortCounter !== size &&
                sorted[1].activeType === 'C' &&
                sorted[0]?.ticker?.value === ativo &&
                sameQtd !== size
            ) {
                return 'Short Call Synthetic Straddle';
            }
            if (
                longCounter === size &&
                sameQtd === size &&
                sorted[1].activeType === 'C' &&
                sorted[0]?.ticker?.value === ativo
            ) {
                return 'Long Put Synthetic';
            }
            if (
                shortCounter === size &&
                sorted[1].activeType === 'P' &&
                sorted[0]?.ticker?.value === ativo &&
                sameQtd === size
            ) {
                return 'Covered Put ';
            }
            if (
                sorted[1].type === 'C' &&
                longCounter === size &&
                sorted[1].activeType === 'P' &&
                sorted[0]?.ticker?.value === ativo &&
                sameQtd !== size
            ) {
                return 'Long Put Synthetic Straddle';
            }
            if (
                sorted[1].type === 'V' &&
                longCounter !== size &&
                sorted[1].activeType === 'P' &&
                sorted[0]?.ticker?.value === ativo &&
                sameQtd !== size
            ) {
                return 'Short Put Synthetic Straddle';
            }
            return null;
        }

        if (size === 3) {
            const call = array.filter((a) => a?.activeType === 'C').length;
            const put = array.filter((a) => a?.activeType === 'P').length;
            const strikes = [];
            const ListStrike = [];
            const equalQtd = [];

            const venc = array.filter(
                (a) => a?.venc?.value === array[0]?.venc?.value
            ).length;
            array.forEach((a) => {
                if (a.strike) {
                    ListStrike.push(a.strike.value);
                }
                if (a.qtd === array[0].qtd) {
                    equalQtd.push(a.qtd);
                }
            });

            for (let i = 0; i < size; i++) {
                if (
                    array[i].strike &&
                    sorted[0]?.strike?.value === sorted[i]?.strike?.value
                ) {
                    strikes.push(array?.strike?.value);
                }
            }
            if (callCounter === 1 && putCounter === 2 && equalStrike === 3) {
                return 'Strip';
            }
            if (callCounter === 2 && putCounter === 1 && equalStrike === 3) {
                return 'Strap';
            }
            if (sorted[0].type === 'C') {
                if (
                    sameQtd === 3 &&
                    sorted[0].activeType === 'P' &&
                    sorted[1].type === sorted[0].type &&
                    sorted[1].type !== sorted[2].type &&
                    sorted[1]?.strike?.value === sorted[0]?.strike?.value &&
                    sorted[0]?.strike?.value !== sorted[2]?.strike?.value &&
                    sorted[2].activeType === sorted[1].activeType &&
                    sorted[0].activeType !== sorted[2].activeType
                ) {
                    return 'Inverted Big Lizard';
                }
                if (
                    equalQtd.length === 3 &&
                    sorted[0].activeType === 'P' &&
                    sorted[2].type === sorted[1].type &&
                    sorted[0].type !== sorted[2].type &&
                    sorted[2]?.strike?.value === sorted[1]?.strike?.value &&
                    sorted[0]?.strike?.value !== sorted[2]?.strike?.value &&
                    sorted[0].activeType === sorted[1].activeType &&
                    sorted[1].activeType !== sorted[2].activeType
                ) {
                    return 'Reverse Big Lizard';
                }
                if (
                    equalQtd.length === 3 &&
                    sorted[0].type !== sorted[1].type &&
                    sorted[0].activeType === 'P' &&
                    sorted[0].activeType === sorted[1].activeType &&
                    sorted[0].activeType !== sorted[2].activeType
                ) {
                    return 'Short Put Seagull';
                }
                if (
                    equalQtd.length === 3 &&
                    sorted[0].type !== sorted[1].type &&
                    sorted[2].type !== sorted[1].type &&
                    sorted[0].activeType === 'C' &&
                    sorted[0].activeType === sorted[1].activeType &&
                    sorted[0].activeType === sorted[2].activeType
                ) {
                    return 'Seagull Call Backspread';
                }
                if (
                    equalQtd.length === 3 &&
                    sorted[0].type !== sorted[1].type &&
                    sorted[0].activeType === 'C' &&
                    sorted[1].activeType === sorted[2].activeType &&
                    sorted[0].activeType !== sorted[2].activeType
                ) {
                    return 'Short Call Seagull';
                }
                if (
                    equalQtd.length === 3 &&
                    sorted[0].type !== sorted[1].type &&
                    sorted[2].type !== sorted[1].type &&
                    sorted[0].activeType === 'P' &&
                    sorted[0].activeType === sorted[1].activeType &&
                    sorted[0].activeType === sorted[2].activeType
                ) {
                    return 'Seagull Put Backspread';
                }
                if (
                    equalQtd.length === 3 &&
                    sorted[0].type !== sorted[1].type &&
                    sorted[2].type === sorted[1].type &&
                    sorted[0].activeType === 'C' &&
                    sorted[0].activeType === sorted[1].activeType &&
                    sorted[0].activeType === sorted[2].activeType
                ) {
                    return 'Long Call Ladder';
                }
                if (
                    equalQtd.length === 3 &&
                    sorted[0].type !== sorted[1].type &&
                    sorted[2].type === sorted[1].type &&
                    sorted[0].activeType === 'P' &&
                    array[0].activeType === sorted[1].activeType &&
                    array[0].activeType === sorted[2].activeType
                ) {
                    return 'Long Put Ladder';
                }
                if (
                    sorted[0]?.ticker?.value === ativo &&
                    sorted[1].type !== sorted[2].type &&
                    sorted[1].activeType !== sorted[2].activeType
                ) {
                    return 'Long Collar';
                }
                if (
                    sorted[0]?.ticker?.value === ativo &&
                    sorted[1].activeType !== sorted[2].activeType &&
                    sorted[1].type === sorted[2].type
                ) {
                    return 'Covered Short Straddle';
                }
                if (
                    sorted[0]?.strike?.value < sorted[1]?.strike?.value &&
                    sorted[1]?.strike?.value < sorted[2]?.strike?.value &&
                    sorted[0].activeType === 'P' &&
                    sorted[1].activeType === 'C' &&
                    sorted[2].activeType === 'C' &&
                    sorted[0].type === sorted[1].type
                ) {
                    return 'Inverted Jade Lizard';
                }
                if (
                    sorted[0]?.strike?.value < sorted[1]?.strike?.value &&
                    sorted[1]?.strike?.value < sorted[2]?.strike?.value &&
                    sorted[0].activeType === 'P' &&
                    sorted[1].activeType === 'P' &&
                    sorted[2].activeType === 'C' &&
                    sorted[0].type !== sorted[1].type
                ) {
                    return 'Reverse Jade Lizard';
                }
            } else {
                if (
                    sorted[0].type === sorted[1].type &&
                    sorted[0].activeType !== sorted[1].activeType &&
                    sorted[0]?.strike?.value === sorted[1]?.strike?.value &&
                    sorted[0].qtd === sorted[1].qtd &&
                    sorted[0]?.venc?.value === sorted[1]?.venc?.value &&
                    sorted[0]?.venc?.value !== sorted[2]?.venc?.value &&
                    sorted[2]?.type !== sorted[0]?.type &&
                    sorted[2]?.activeType === 'P'
                ) {
                    return 'Short Straddle Long Put';
                }
                if (
                    equalQtd.length === 3 &&
                    sorted[0].activeType === 'P' &&
                    sorted[1].type === sorted[2].type &&
                    sorted[1].type !== sorted[0].type &&
                    sorted[1]?.strike?.value === sorted[2]?.strike?.value &&
                    sorted[0]?.strike?.value !== sorted[2]?.strike?.value &&
                    sorted[1].activeType === sorted[0].activeType &&
                    sorted[0].activeType !== sorted[2].activeType
                ) {
                    return 'Inverted Reverse Big Lizard';
                }
                if (
                    equalQtd.length === 3 &&
                    sorted[0].activeType === 'P' &&
                    sorted[0].type === sorted[1].type &&
                    sorted[0].type !== sorted[2].type &&
                    sorted[0]?.strike?.value === sorted[1]?.strike?.value &&
                    sorted[0]?.strike?.value !== sorted[2]?.strike?.value &&
                    sorted[1].activeType === sorted[2].activeType &&
                    sorted[0].activeType !== sorted[2].activeType
                ) {
                    return 'Big Lizard';
                }
                if (
                    equalQtd.length === 3 &&
                    sorted[0].type !== sorted[1].type &&
                    sorted[0].activeType === 'P' &&
                    sorted[0].activeType === sorted[1].activeType &&
                    sorted[0].activeType !== sorted[2].activeType
                ) {
                    return 'Long Put Seagull';
                }
                if (
                    equalQtd.length === 3 &&
                    sorted[0].type !== sorted[1].type &&
                    sorted[2].type !== sorted[1].type &&
                    sorted[0].activeType === 'C' &&
                    sorted[0].activeType === sorted[1].activeType &&
                    sorted[0].activeType === sorted[2].activeType
                ) {
                    return 'Seagull Call Ratio Spread';
                }
                if (
                    equalQtd.length === 3 &&
                    sorted[0].type !== sorted[1].type &&
                    sorted[0].activeType === 'C' &&
                    sorted[1].activeType === sorted[2].activeType &&
                    sorted[0].activeType !== sorted[2].activeType
                ) {
                    return 'Long Call Seagull';
                }
                if (
                    equalQtd.length === 3 &&
                    sorted[0].type !== sorted[1].type &&
                    sorted[2].type !== sorted[1].type &&
                    sorted[0].activeType === 'P' &&
                    sorted[0].activeType === sorted[1].activeType &&
                    sorted[0].activeType === sorted[2].activeType
                ) {
                    return 'Seagull Put Ratio Spread';
                }
                if (
                    equalQtd.length === 3 &&
                    sorted[0].type !== sorted[1].type &&
                    sorted[2].type === sorted[1].type &&
                    sorted[0].activeType === 'C' &&
                    sorted[0].activeType === sorted[1].activeType &&
                    sorted[0].activeType === sorted[2].activeType
                ) {
                    return 'Short Call Ladder';
                }
                if (
                    equalQtd.length === 3 &&
                    sorted[0].type !== sorted[1].type &&
                    sorted[2].type === sorted[1].type &&
                    sorted[0].activeType === 'P' &&
                    sorted[0].activeType === sorted[1].activeType &&
                    sorted[0].activeType === sorted[2].activeType
                ) {
                    return 'Short Put Ladder';
                }
                if (
                    sorted[0]?.ticker?.value === ativo &&
                    sorted[1].type !== sorted[2].type &&
                    sorted[1].activeType !== sorted[2].activeType
                ) {
                    return 'Short Collar';
                }
                if (
                    sorted[0]?.ticker?.value === ativo &&
                    sorted[1].activeType !== sorted[2].activeType &&
                    sorted[1].type === sorted[2].type
                ) {
                    return 'Covered Short Strangle';
                }
                if (
                    sorted[0]?.strike?.value < sorted[1]?.strike?.value &&
                    sorted[1]?.strike?.value < sorted[2]?.strike?.value &&
                    sorted[0].activeType === 'P' &&
                    sorted[1].activeType === 'C' &&
                    sorted[2].activeType === 'C' &&
                    sorted[0].type === sorted[1].type
                ) {
                    return 'Jade Lizard';
                }
                if (
                    sorted[0]?.strike?.value < sorted[1]?.strike?.value &&
                    sorted[1]?.strike?.value < sorted[2]?.strike?.value &&
                    sorted[0].activeType === 'P' &&
                    sorted[1].activeType === 'P' &&
                    sorted[2].activeType === 'C' &&
                    sorted[0].type !== sorted[1].type
                ) {
                    return 'Inverted Reverse Jade Lizard';
                }
            }

            if (venc === 3 && ListStrike.length === 3) {
                if (sorted[0].type === 'C') {
                    if (sorted[0].activeType === 'C') {
                        if (
                            sorted[0].qtd !== sorted[1].qtd &&
                            sorted[0].qtd === sorted[2].qtd &&
                            sorted[0]?.strike?.value <
                                sorted[1]?.strike?.value <
                                sorted[2]?.strike?.value &&
                            sorted[0]?.type === 'C' &&
                            sorted[1]?.type === 'V' &&
                            sorted[2]?.type === 'C'
                        ) {
                            if (
                                cleanNumber(sorted[0]?.strike?.value) -
                                    cleanNumber(sorted[1]?.strike?.value) ===
                                cleanNumber(sorted[1]?.strike?.value) -
                                    cleanNumber(sorted[2]?.strike?.value)
                            )
                                return 'Long call butterfly';
                            if (
                                cleanNumber(sorted[0]?.strike?.value) -
                                    cleanNumber(sorted[1]?.strike?.value) <
                                cleanNumber(sorted[1]?.strike?.value) -
                                    cleanNumber(sorted[2]?.strike?.value)
                            )
                                return 'Broken right-w long call butterfly';
                            return 'Broken left-w long call butterfly';
                        }
                        if (
                            sorted[0].qtd !== sorted[1].qtd &&
                            sorted[0].qtd < sorted[2].qtd &&
                            sorted[1].qtd !== sorted[2].qtd &&
                            sorted[0]?.strike?.value <
                                sorted[1]?.strike?.value <
                                sorted[2]?.strike?.value &&
                            sorted[0]?.type === 'C' &&
                            sorted[1]?.type === 'V' &&
                            sorted[2]?.type === 'C'
                        ) {
                            return 'Ratio right-w long call butterfly';
                        }
                        if (
                            sorted[0].qtd !== sorted[1].qtd &&
                            sorted[0].qtd < sorted[2].qtd &&
                            sorted[0]?.strike?.value <
                                sorted[1]?.strike?.value <
                                sorted[2]?.strike?.value &&
                            sorted[0]?.type === 'C' &&
                            sorted[1]?.type === 'V' &&
                            sorted[2]?.type === 'C'
                        ) {
                            return 'Asymmetric left-w long call butterfly';
                        }
                        if (
                            sorted[0].qtd !== sorted[1].qtd &&
                            sorted[0].qtd > sorted[2].qtd &&
                            sorted[0]?.strike?.value <
                                sorted[1]?.strike?.value <
                                sorted[2]?.strike?.value &&
                            sorted[0]?.type === 'C' &&
                            sorted[1]?.type === 'V' &&
                            sorted[2]?.type === 'C'
                        ) {
                            return 'Asymmetric right-w long call butterfly';
                        }
                    } else {
                        if (
                            sorted[0].qtd !== sorted[1].qtd &&
                            sorted[0].qtd === sorted[2].qtd &&
                            sorted[0]?.strike?.value <
                                sorted[1]?.strike?.value <
                                sorted[2]?.strike?.value &&
                            sorted[0]?.type === 'C' &&
                            sorted[1]?.type === 'V' &&
                            sorted[2]?.type === 'C'
                        ) {
                            if (
                                cleanNumber(sorted[0]?.strike?.value) -
                                    cleanNumber(sorted[1]?.strike?.value) ===
                                cleanNumber(sorted[1]?.strike?.value) -
                                    cleanNumber(sorted[2]?.strike?.value)
                            )
                                return 'Long put butterfly';
                            if (
                                cleanNumber(sorted[0]?.strike?.value) -
                                    cleanNumber(sorted[1]?.strike?.value) <
                                cleanNumber(sorted[1]?.strike?.value) -
                                    cleanNumber(sorted[2]?.strike?.value)
                            )
                                return 'Broken right-w long put butterfly';
                            return 'Broken left-w long put butterfly';
                        }
                        if (
                            sorted[0].qtd !== sorted[1].qtd &&
                            sorted[0].qtd < sorted[2].qtd &&
                            sorted[1].qtd !== sorted[2].qtd &&
                            sorted[0]?.strike?.value <
                                sorted[1]?.strike?.value <
                                sorted[2]?.strike?.value &&
                            sorted[0]?.type === 'C' &&
                            sorted[1]?.type === 'V' &&
                            sorted[2]?.type === 'C'
                        ) {
                            return 'Ratio right-w long put butterfly';
                        }
                        if (
                            sorted[0].qtd !== sorted[1].qtd &&
                            sorted[0].qtd < sorted[2].qtd &&
                            sorted[0]?.strike?.value <
                                sorted[1]?.strike?.value <
                                sorted[2]?.strike?.value &&
                            sorted[0]?.type === 'C' &&
                            sorted[1]?.type === 'V' &&
                            sorted[2]?.type === 'C'
                        ) {
                            return 'Asymmetric left-w long put butterfly';
                        }
                        if (
                            sorted[0].qtd !== sorted[1].qtd &&
                            sorted[0].qtd > sorted[2].qtd &&
                            sorted[0]?.strike?.value <
                                sorted[1]?.strike?.value <
                                sorted[2]?.strike?.value &&
                            sorted[0]?.type === 'C' &&
                            sorted[1]?.type === 'V' &&
                            sorted[2]?.type === 'C'
                        ) {
                            return 'Asymmetric right-w long put butterfly';
                        }
                    }
                } else {
                    if (sorted[0].activeType === 'C') {
                        if (
                            sorted[0].qtd !== sorted[1].qtd &&
                            sorted[0].qtd === sorted[2].qtd &&
                            sorted[0]?.strike?.value <
                                sorted[1]?.strike?.value <
                                sorted[2]?.strike?.value &&
                            sorted[0]?.type === 'V' &&
                            sorted[1]?.type === 'C' &&
                            sorted[2]?.type === 'V'
                        ) {
                            if (
                                cleanNumber(sorted[0]?.strike?.value) -
                                    cleanNumber(sorted[1]?.strike?.value) ===
                                cleanNumber(sorted[1]?.strike?.value) -
                                    cleanNumber(sorted[2]?.strike?.value)
                            )
                                return 'short call butterfly';
                            if (
                                cleanNumber(sorted[0]?.strike?.value) -
                                    cleanNumber(sorted[1]?.strike?.value) <
                                cleanNumber(sorted[1]?.strike?.value) -
                                    cleanNumber(sorted[2]?.strike?.value)
                            )
                                return 'Broken right-w short call butterfly';
                            return 'Broken left-w short call butterfly';
                        }
                    } else {
                        if (
                            sorted[0].qtd !== sorted[1].qtd &&
                            sorted[0].qtd === sorted[2].qtd &&
                            sorted[0]?.strike?.value <
                                sorted[1]?.strike?.value <
                                sorted[2]?.strike?.value &&
                            sorted[0]?.type === 'V' &&
                            sorted[1]?.type === 'C' &&
                            sorted[2]?.type === 'V'
                        ) {
                            if (
                                cleanNumber(sorted[0]?.strike?.value) -
                                    cleanNumber(sorted[1]?.strike?.value) ===
                                cleanNumber(sorted[1]?.strike?.value) -
                                    cleanNumber(sorted[2]?.strike?.value)
                            )
                                return 'short put butterfly';
                            if (
                                cleanNumber(sorted[0]?.strike?.value) -
                                    cleanNumber(sorted[1]?.strike?.value) <
                                cleanNumber(sorted[1]?.strike?.value) -
                                    cleanNumber(sorted[2]?.strike?.value)
                            )
                                return 'Broken right-w short put butterfly';
                            return 'Broken left-w short put butterfly';
                        }
                    }
                }
                return null;
            }
        }
        if (size === 4) {
            const notAdjusted = [];
            const listCall = [];
            const listPut = [];
            const order = [];
            // iron && gut
            const ironButterfly = [];
            const shortIron = [];
            const longIron = [];
            const longGut = [];
            const shortGut = [];
            // condor
            const longCallCondor = [];
            const longPutCondor = [];
            const shortCallCondor = [];
            const shortPutCondor = [];
            const shortGutIronCondor = [];
            const longGutIronCondor = [];
            // albatross
            const longCallAlbatross = [];
            const shortCallAlbatross = [];
            const longPutAlbatross = [];
            const shortPutAlbatross = [];
            const shortIronAlbatross = [];
            const longIronAlbatross = [];
            const longGutIronAlbatross = [];
            const shortGutIronAlbatross = [];
            // pterodactyl
            const longCallPtero = [];
            const longPutPtero = [];
            const shortCallPtero = [];
            const shortPutPtero = [];
            const shortIronPtero = [];
            const longIronPtero = [];
            const longGutIronPtero = [];
            const shortGutIronPtero = [];
            // wrangle
            const longWrangle = [];
            const shortWrangle = [];
            // Seagull
            const longPutSeagull = [];
            const longCallSeagull = [];
            // CartWhell
            const longCartWhell = [];
            const shortCartWhell = [];
            // conversion
            const conversion = [];
            // reversal
            const reversal = [];
            // Box
            const longBox = [];
            const shortBox = [];
            // bear Put
            const BearPutSpread = [];
            // doubleSpread
            const longDoubleSpread = [];
            //
            const longDiagonalStrangle = [];
            const jellyRoll = [];

            sorted.forEach((a, i) => {
                if (sorted[0].qtd === a.qtd) {
                    notAdjusted.push(a.qtd);
                }
                if (a.activeType === 'C') {
                    listCall.push('C');
                } else {
                    listPut.push('P');
                }
                if (a?.strike?.value <= sorted[i + 1]?.strike?.value) {
                    order.push(a?.strike?.value);
                }
                if (i + 1 === size) {
                    let check = true;
                    order.forEach((b) => {
                        if (a?.strike?.value < b?.strike?.value) {
                            check = false;
                        }
                    });
                    if (check === true) {
                        order.push(a?.strike?.value);
                    }
                }
            });

            const listStrikes = sorted[0].strikeOptFiltered;

            for (let i = 0; i < size / 2; i++) {
                const nextStrikes = [];
                let ptero = false;
                for (let j = 0; j < listStrikes.length; j++) {
                    if (nextStrikes.length === 6) {
                        break;
                    }
                    if (sorted[1]?.strike?.value <= listStrikes[j].value) {
                        nextStrikes.push(listStrikes[j].value);
                    }
                }
                for (let j = 2; j < nextStrikes.length; j++) {
                    if (sorted[2]?.strike?.value === nextStrikes[j]) {
                        ptero = true;
                        break;
                    }
                }

                if (sorted[0].type === 'C') {
                    if (sorted[1]?.strike?.value === sorted[2]?.strike?.value) {
                        if (
                            notAdjusted.length === size &&
                            sorted[i].qtd === sorted[0]?.qtd &&
                            sorted[i].type === sorted[size - i - 1].type &&
                            sorted[i].activeType === sorted[i + 1].activeType &&
                            sorted[size - 1 - i].activeType ===
                                sorted[size - i - 2].activeType
                        ) {
                            shortIron.push(i);
                        }
                        if (
                            notAdjusted.length === size &&
                            sorted[i].qtd === sorted[0]?.qtd &&
                            sorted[i].type === sorted[size - i - 1].type &&
                            sorted[i].activeType !== sorted[i + 1].activeType &&
                            sorted[size - 1 - i].activeType !==
                                sorted[size - i - 2].activeType
                        ) {
                            longGut.push(i);
                        }
                        if (
                            sorted[i].qtd !== sorted[size - 1 - i].qtd &&
                            sorted[i].type !== sorted[size - i - 1].type &&
                            sorted[i].activeType !==
                                sorted[size - i - 1].activeType &&
                            sorted[i]?.strike?.value ===
                                sorted[size - i - 1]?.strike?.value
                        ) {
                            shortWrangle.push(i);
                        }
                    }
                    if (
                        sorted[1]?.strike?.value - sorted[0]?.strike?.value ===
                            sorted[2]?.strike?.value -
                                sorted[1]?.strike?.value &&
                        sorted[2]?.strike?.value - sorted[1]?.strike?.value ===
                            sorted[3]?.strike?.value - sorted[2]?.strike?.value
                    ) {
                        if (
                            notAdjusted.length === size &&
                            sorted[i].activeType !==
                                sorted[size - i - 1].activeType &&
                            sorted[i].type === sorted[size - i - 1].type
                        ) {
                            longGutIronCondor.push(i);
                        }
                        if (
                            notAdjusted.length === size &&
                            listCall.length === size &&
                            sorted[i].type === sorted[size - i - 1].type &&
                            order.length === size
                        ) {
                            shortCallCondor.push(i);
                        }
                        if (
                            notAdjusted.length === size &&
                            listPut.length === size &&
                            sorted[i].type === sorted[size - i - 1].type &&
                            order.length === size
                        ) {
                            longPutCondor.push(i);
                        }
                    }
                    if (
                        sorted[1]?.strike?.value - sorted[0]?.strike?.value ===
                            sorted[3]?.strike?.value -
                                sorted[2]?.strike?.value &&
                        sorted[2]?.strike?.value - sorted[1]?.strike?.value !==
                            sorted[3]?.strike?.value - sorted[2]?.strike?.value
                    ) {
                        if (ptero) {
                            if (
                                notAdjusted.length === size &&
                                listCall.length === size &&
                                sorted[i].type === sorted[size - i - 1].type &&
                                order.length === size
                            ) {
                                longCallPtero.push(i);
                            }
                            if (
                                notAdjusted.length === size &&
                                listPut.length === size &&
                                sorted[i].type === sorted[size - i - 1].type &&
                                order.length === size
                            ) {
                                longPutPtero.push(i);
                            }
                            if (
                                notAdjusted.length === size &&
                                sorted[0].activeType === 'P' &&
                                sorted[0].activeType === sorted[1].activeType &&
                                sorted[2].activeType === sorted[3].activeType &&
                                sorted[1].activeType !== sorted[2].activeType &&
                                sorted[i].type === sorted[size - i - 1].type
                            ) {
                                shortIronPtero.push(i);
                            }
                            if (
                                notAdjusted.length === size &&
                                sorted[0].activeType === 'C' &&
                                sorted[0].activeType === sorted[1].activeType &&
                                sorted[2].activeType === sorted[3].activeType &&
                                sorted[1].activeType !== sorted[2].activeType &&
                                sorted[i].type === sorted[size - i - 1].type
                            ) {
                                longGutIronPtero.push(i);
                            }
                        } else {
                            if (
                                notAdjusted.length === size &&
                                listCall.length === size &&
                                sorted[i].type === sorted[size - i - 1].type &&
                                order.length === size
                            ) {
                                longCallAlbatross.push(i);
                            }
                            if (
                                notAdjusted.length === size &&
                                listPut.length === size &&
                                sorted[i].type === sorted[size - i - 1].type &&
                                order.length === size
                            ) {
                                longPutAlbatross.push(i);
                            }
                            if (
                                notAdjusted.length === size &&
                                sorted[0].activeType === 'P' &&
                                sorted[0].activeType === sorted[1].activeType &&
                                sorted[2].activeType === sorted[3].activeType &&
                                sorted[1].activeType !== sorted[2].activeType &&
                                sorted[i].type === sorted[size - i - 1].type
                            ) {
                                shortIronAlbatross.push(i);
                            }
                            if (
                                notAdjusted.length === size &&
                                sorted[0].activeType === 'C' &&
                                sorted[0].activeType === sorted[1].activeType &&
                                sorted[2].activeType === sorted[3].activeType &&
                                sorted[1].activeType !== sorted[2].activeType &&
                                sorted[i].type === sorted[size - i - 1].type
                            ) {
                                longGutIronAlbatross.push(i);
                            }
                        }
                    }
                    if (
                        sorted[i].qtd === sorted[size - 1 - i].qtd &&
                        sorted[i].type !== sorted[size - i - 1].type &&
                        sorted[i].activeType !==
                            sorted[size - i - 1].activeType &&
                        sorted[i]?.strike?.value <
                            sorted[size - i - 1]?.strike?.value
                    ) {
                        longCallSeagull.push(i);
                    }
                    if (
                        sorted[i].qtd === sorted[size - 1 - i].qtd &&
                        sorted[i].type === sorted[size - i - 1].type &&
                        sorted[i].activeType !==
                            sorted[size - i - 1].activeType &&
                        sorted[i]?.strike?.value <
                            sorted[size - i - 1]?.strike?.value &&
                        sorted[0]?.strike?.value === sorted[1]?.strike?.value &&
                        sorted[2]?.strike?.value === sorted[3]?.strike?.value
                    ) {
                        conversion.push(i);
                    }
                    if (
                        sorted[i].qtd === sorted[size - 1 - i].qtd &&
                        sorted[i].type !== sorted[size - i - 1].type &&
                        sorted[i].activeType !==
                            sorted[size - i - 1].activeType &&
                        sorted[0]?.strike?.value === sorted[2]?.strike?.value &&
                        sorted[1]?.strike?.value === sorted[3]?.strike?.value
                    ) {
                        longBox.push(i);
                    }
                    if (
                        sorted[0].activeType === 'C' &&
                        sorted[i].qtd === sorted[size - 1 - i].qtd &&
                        sorted[i].type !== sorted[size - i - 1].type &&
                        sorted[i].activeType !==
                            sorted[size - i - 1].activeType &&
                        sorted[0]?.strike?.value === sorted[1]?.strike?.value &&
                        sorted[2]?.strike?.value === sorted[3]?.strike?.value &&
                        sorted[i]?.venc?.value >
                            sorted[size - 1 - i]?.venc?.value
                    ) {
                        longDoubleSpread.push(i);
                    }
                    if (
                        sorted[0].activeType === 'P' &&
                        sorted[i].qtd === sorted[size - 1 - i].qtd &&
                        sorted[i].type !== sorted[size - i - 1].type &&
                        sorted[i].activeType !==
                            sorted[size - i - 1].activeType &&
                        sorted[0]?.strike?.value !== sorted[1]?.strike?.value &&
                        sorted[i]?.venc?.value >
                            sorted[size - 1 - i]?.venc?.value
                    ) {
                        longDiagonalStrangle.push(i);
                    }
                    if (
                        sorted[0].activeType === 'C' &&
                        sorted[i].qtd === sorted[size - 1 - i].qtd &&
                        sorted[i].type === sorted[size - i - 1].type &&
                        sorted[i].activeType !==
                            sorted[size - i - 1].activeType &&
                        sorted[i]?.strike?.value ===
                            sorted[size - i - 1]?.strike?.value &&
                        sorted[i]?.venc?.value !==
                            sorted[size - 1 - i]?.venc?.value
                    ) {
                        jellyRoll.push(i);
                    }
                } else {
                    if (
                        sorted[1]?.strike?.value - sorted[0]?.strike?.value ===
                            sorted[2]?.strike?.value -
                                sorted[1]?.strike?.value &&
                        sorted[2]?.strike?.value - sorted[1]?.strike?.value ===
                            sorted[3]?.strike?.value - sorted[2]?.strike?.value
                    ) {
                        if (
                            notAdjusted.length === size &&
                            listCall.length === size &&
                            sorted[i].type === sorted[size - i - 1].type &&
                            order.length === size
                        ) {
                            longCallCondor.push(i);
                        }
                        if (
                            notAdjusted.length === size &&
                            sorted[i].activeType !==
                                sorted[size - i - 1].activeType &&
                            sorted[i].type === sorted[size - i - 1].type
                        ) {
                            shortGutIronCondor.push(i);
                        }
                        if (
                            notAdjusted.length === size &&
                            listPut.length === size &&
                            sorted[i].type === sorted[size - i - 1].type &&
                            order.length === size
                        ) {
                            shortPutCondor.push(i);
                        }
                    }
                    if (
                        sorted[1]?.strike?.value - sorted[0]?.strike?.value ===
                            sorted[3]?.strike?.value -
                                sorted[2]?.strike?.value &&
                        sorted[2]?.strike?.value - sorted[1]?.strike?.value !==
                            sorted[3]?.strike?.value - sorted[2]?.strike?.value
                    ) {
                        if (ptero) {
                            if (
                                notAdjusted.length === size &&
                                listCall.length === size &&
                                sorted[i].type === sorted[size - i - 1].type &&
                                order.length === size
                            ) {
                                shortCallPtero.push(i);
                            }
                            if (
                                notAdjusted.length === size &&
                                listPut.length === size &&
                                sorted[i].type === sorted[size - i - 1].type &&
                                order.length === size
                            ) {
                                shortPutPtero.push(i);
                            }
                            if (
                                notAdjusted.length === size &&
                                sorted[0].activeType === 'P' &&
                                sorted[0].activeType === sorted[1].activeType &&
                                sorted[2].activeType === sorted[3].activeType &&
                                sorted[1].activeType !== sorted[2].activeType &&
                                sorted[i].type === sorted[size - i - 1].type
                            ) {
                                longIronPtero.push(i);
                            }
                            if (
                                notAdjusted.length === size &&
                                sorted[0].activeType === 'C' &&
                                sorted[0].activeType === sorted[1].activeType &&
                                sorted[2].activeType === sorted[3].activeType &&
                                sorted[1].activeType !== sorted[2].activeType &&
                                sorted[i].type === sorted[size - i - 1].type
                            ) {
                                shortGutIronPtero.push(i);
                            }
                        } else {
                            if (
                                notAdjusted.length === size &&
                                listPut.length === size &&
                                sorted[i].type === sorted[size - i - 1].type &&
                                order.length === size
                            ) {
                                shortPutAlbatross.push(i);
                            }

                            if (
                                notAdjusted.length === size &&
                                listCall.length === size &&
                                sorted[i].type === sorted[size - i - 1].type &&
                                order.length === size
                            ) {
                                shortCallAlbatross.push(i);
                            }
                            if (
                                notAdjusted.length === size &&
                                sorted[0].activeType === 'P' &&
                                sorted[0].activeType === sorted[1].activeType &&
                                sorted[2].activeType === sorted[3].activeType &&
                                sorted[1].activeType !== sorted[2].activeType &&
                                sorted[i].type === sorted[size - i - 1].type
                            ) {
                                longIronAlbatross.push(i);
                            }
                            if (
                                notAdjusted.length === size &&
                                sorted[0].activeType === 'C' &&
                                sorted[0].activeType === sorted[1].activeType &&
                                sorted[2].activeType === sorted[3].activeType &&
                                sorted[1].activeType !== sorted[2].activeType &&
                                sorted[i].type === sorted[size - i - 1].type
                            ) {
                                shortGutIronAlbatross.push(i);
                            }
                        }
                    }
                    if (sorted[1]?.strike?.value === sorted[2]?.strike?.value) {
                        if (
                            notAdjusted.length === size &&
                            sorted[i].qtd === sorted[0]?.qtd &&
                            sorted[i].type === sorted[size - i - 1].type &&
                            sorted[i].activeType === sorted[i + 1].activeType &&
                            sorted[size - 1 - i].activeType ===
                                sorted[size - i - 2].activeType
                        ) {
                            longIron.push(i);
                        }
                        if (
                            notAdjusted.length === size &&
                            sorted[i].qtd === sorted[0]?.qtd &&
                            sorted[i].type === sorted[size - i - 1].type &&
                            sorted[i].activeType !== sorted[i + 1].activeType &&
                            sorted[size - 1 - i].activeType !==
                                sorted[size - i - 2].activeType
                        ) {
                            shortGut.push(i);
                        }
                        if (
                            sorted[i].qtd !== sorted[size - 1 - i].qtd &&
                            sorted[i].type !== sorted[size - i - 1].type &&
                            sorted[i].activeType !==
                                sorted[size - i - 1].activeType &&
                            sorted[i]?.strike?.value ===
                                sorted[size - i - 1]?.strike?.value
                        ) {
                            longWrangle.push(i);
                        }
                    }
                    if (
                        sorted[i].qtd === sorted[size - 1 - i].qtd &&
                        sorted[i].type !== sorted[size - i - 1].type &&
                        sorted[i].activeType !==
                            sorted[size - i - 1].activeType &&
                        sorted[i]?.strike?.value <
                            sorted[size - i - 1]?.strike?.value
                    ) {
                        longPutSeagull.push(i);
                    }
                    if (
                        sorted[0].activeType === 'C' &&
                        sorted[i].qtd !== sorted[size - 1 - i].qtd &&
                        sorted[i].type === sorted[size - i - 1].type &&
                        sorted[i].activeType !==
                            sorted[size - i - 1].activeType &&
                        sorted[i]?.strike?.value ===
                            sorted[size - i - 1]?.strike?.value
                    ) {
                        longCartWhell.push(i);
                    }
                    if (
                        sorted[0].activeType === 'P' &&
                        sorted[i].qtd !== sorted[size - 1 - i].qtd &&
                        sorted[i].type === sorted[size - i - 1].type &&
                        sorted[i].activeType !==
                            sorted[size - i - 1].activeType &&
                        sorted[i]?.strike?.value ===
                            sorted[size - i - 1]?.strike?.value
                    ) {
                        shortCartWhell.push(i);
                    }
                    if (
                        sorted[i].qtd === sorted[size - 1 - i].qtd &&
                        sorted[i].type === sorted[size - i - 1].type &&
                        sorted[i].activeType !==
                            sorted[size - i - 1].activeType &&
                        sorted[i]?.strike?.value <
                            sorted[size - i - 1]?.strike?.value &&
                        sorted[0]?.strike?.value === sorted[1]?.strike?.value &&
                        sorted[2]?.strike?.value === sorted[3]?.strike?.value
                    ) {
                        reversal.push(i);
                    }
                    if (
                        sorted[i].qtd === sorted[size - 1 - i].qtd &&
                        sorted[i].type !== sorted[size - i - 1].type &&
                        sorted[i].activeType !==
                            sorted[size - i - 1].activeType &&
                        sorted[0]?.strike?.value === sorted[2]?.strike?.value &&
                        sorted[1]?.strike?.value === sorted[3]?.strike?.value
                    ) {
                        shortBox.push(i);
                    }
                    if (
                        sorted[0].type !== sorted[3].type &&
                        sorted[3]?.ticker?.value === ativo &&
                        sorted[1].type !== sorted[2].type &&
                        sorted[1].activeType !== sorted[2].activeType
                    ) {
                        BearPutSpread.push(i);
                    }
                }
                if (
                    notAdjusted.length !== size &&
                    sorted[i].qtd === sorted[size - i - 1].qtd &&
                    sorted[i].type === sorted[size - i - 1].type &&
                    sorted[i].activeType !== sorted[size - i - 1].activeType &&
                    sorted[1]?.strike?.value === sorted[2]?.strike?.value
                ) {
                    ironButterfly.push(i);
                }

                // Butterfly
                if (ironButterfly.length === size / 2)
                    return 'Adjusted Short Iron Butterfly';
                if (shortIron.length === size / 2)
                    return 'Short Iron Butterfly';
                if (longIron.length === size / 2) return 'Long Iron Butterfly';
                if (longGut.length === size / 2)
                    return 'Long Gut Iron Butterfly';
                if (shortGut.length === size / 2)
                    return 'Short Gut Iron Butterfly';
                // Condor
                if (longCallCondor.length === size / 2)
                    return 'Long Call Condor';
                if (longPutCondor.length === size / 2) return 'Long Put Condor';
                if (shortCallCondor.length === size / 2)
                    return 'Short Call Condor';
                if (shortPutCondor.length === size / 2)
                    return 'Short Put Condor';
                if (shortGutIronCondor.length === size / 2)
                    return 'Short Gut Iron Condor';
                if (longGutIronCondor.length === size / 2)
                    return 'Long Gut Iron Condor';
                // Albatross
                if (longCallAlbatross.length === size / 2)
                    return 'Long Call Albatross';
                if (shortCallAlbatross.length === size / 2)
                    return 'Short Call Albatross';
                if (longPutAlbatross.length === size / 2)
                    return 'Long Put Albatross';
                if (shortPutAlbatross.length === size / 2)
                    return 'Short Put Albatross';
                if (shortIronAlbatross.length === size / 2)
                    return 'Short Iron Albatross';
                if (longIronAlbatross.length === size / 2)
                    return 'Long Iron Albatross';
                if (longGutIronAlbatross.length === size / 2)
                    return 'Long Gut Iron Albatross';
                if (shortGutIronAlbatross.length === size / 2)
                    return 'Short Gut Iron Albatross';
                // Pterodactyl
                if (longCallPtero.length === size / 2)
                    return 'Long Call Pterodactyl';
                if (shortCallPtero.length === size / 2)
                    return 'Short Call Pterodactyl';
                if (longPutPtero.length === size / 2)
                    return 'Long Put Pterodactyl';
                if (shortPutPtero.length === size / 2)
                    return 'Short Put Pterodactyl';
                if (shortIronPtero.length === size / 2)
                    return 'Short Iron Pterodactyl';
                if (longIronPtero.length === size / 2)
                    return 'Long Iron Pterodactyl';
                if (longGutIronPtero.length === size / 2)
                    return 'Long Gut Iron Pterodactyl';
                if (shortGutIronPtero.length === size / 2)
                    return 'Short Gut Iron Pterodactyl';
                // Wrangle
                if (longWrangle.length === size / 2) return 'Long Wrangle';
                if (shortWrangle.length === size / 2) return 'Short Wrangle';
                // Seagull
                if (longPutSeagull.length === size / 2)
                    return 'Adjusted Long Put Seagull';
                if (longCallSeagull.length === size / 2)
                    return 'Adjusted Long Call Seagull';
                if (longCartWhell.length === size / 2) return 'Long Cartwhell';
                if (shortCartWhell.length === size / 2)
                    return 'Short Cartwhell';
                if (conversion.length === size / 2) return 'Conversion';
                if (reversal.length === size / 2) return 'Reversal';
                if (longBox.length === size / 2) return 'Long Box';
                if (shortBox.length === size / 2) return 'Short Box';
                if (BearPutSpread.length === size / 2)
                    return 'Bear Put Spread Collar';
                if (longDoubleSpread.length === size / 2)
                    return 'Long Double Calendar Spread';
                if (longDiagonalStrangle.length === size / 2)
                    return 'Long Diagonal Strangle Spread';
                if (jellyRoll.length === size / 2) return 'Jelly Roll';
            }
        }
        if (size === 5) {
            const shortCallMoth = [];
            const shortPutMoth = [];
            const longCallMoth = [];
            const longPutMoth = [];
            const callPeakButterfly = [];
            const putPeakButterfly = [];

            for (let i = 0; i < size; i++) {
                if (
                    sorted[0].qtd === sorted[4].qtd &&
                    sorted[0].qtd !== sorted[1].qtd &&
                    sorted[0].type === 'V' &&
                    sorted[i].type !== sorted[i + 1].type &&
                    sorted[1]?.strike?.value - sorted[0]?.strike?.value !==
                        sorted[2]?.strike?.value - sorted[1]?.strike?.value &&
                    sorted[4]?.strike?.value - sorted[3]?.strike?.value !==
                        sorted[3]?.strike?.value - sorted[2]?.strike?.value &&
                    sorted[2]?.strike?.value - sorted[1]?.strike?.value ===
                        sorted[3]?.strike?.value - sorted[2]?.strike?.value &&
                    sorted[0].activeType === 'C' &&
                    sorted[i].activeType === sorted[0].activeType
                ) {
                    shortCallMoth.push(i);
                }
                if (
                    sorted[0].qtd === sorted[4].qtd &&
                    sorted[0].qtd !== sorted[1].qtd &&
                    sorted[0].type === 'C' &&
                    sorted[i].type !== sorted[i + 1].type &&
                    sorted[1]?.strike?.value - sorted[0]?.strike?.value !==
                        sorted[2]?.strike?.value - sorted[1]?.strike?.value &&
                    sorted[4]?.strike?.value - sorted[3]?.strike?.value !==
                        sorted[3]?.strike?.value - sorted[2]?.strike?.value &&
                    sorted[2]?.strike?.value - sorted[1]?.strike?.value ===
                        sorted[3]?.strike?.value - sorted[2]?.strike?.value &&
                    sorted[0].activeType === 'C' &&
                    sorted[i].activeType === sorted[0].activeType
                ) {
                    longCallMoth.push(i);
                }
                if (
                    sorted[0].qtd === sorted[4].qtd &&
                    sorted[0].qtd !== sorted[1].qtd &&
                    sorted[0].type === 'V' &&
                    sorted[i].type !== sorted[i + 1].type &&
                    sorted[1]?.strike?.value - sorted[0]?.strike?.value !==
                        sorted[2]?.strike?.value - sorted[1]?.strike?.value &&
                    sorted[4]?.strike?.value - sorted[3]?.strike?.value !==
                        sorted[3]?.strike?.value - sorted[2]?.strike?.value &&
                    sorted[2]?.strike?.value - sorted[1]?.strike?.value ===
                        sorted[3]?.strike?.value - sorted[2]?.strike?.value &&
                    sorted[0].activeType === 'P' &&
                    sorted[i].activeType === sorted[0].activeType
                ) {
                    shortPutMoth.push(i);
                }
                if (
                    sorted[0].qtd === sorted[4].qtd &&
                    sorted[0].qtd !== sorted[1].qtd &&
                    sorted[0].type === 'C' &&
                    sorted[i].type !== sorted[i + 1].type &&
                    sorted[1]?.strike?.value - sorted[0]?.strike?.value !==
                        sorted[2]?.strike?.value - sorted[1]?.strike?.value &&
                    sorted[4]?.strike?.value - sorted[3]?.strike?.value !==
                        sorted[3]?.strike?.value - sorted[2]?.strike?.value &&
                    sorted[2]?.strike?.value - sorted[1]?.strike?.value ===
                        sorted[3]?.strike?.value - sorted[2]?.strike?.value &&
                    sorted[0].activeType === 'P' &&
                    sorted[i].activeType === sorted[0].activeType
                ) {
                    longPutMoth.push(i);
                }
                if (
                    sorted[0].qtd === sorted[4].qtd &&
                    sorted[0].qtd !== sorted[1].qtd &&
                    sorted[0].type === 'C' &&
                    sorted[i].type !== sorted[i + 1].type &&
                    sorted[1]?.strike?.value - sorted[0]?.strike?.value ===
                        sorted[2]?.strike?.value - sorted[1]?.strike?.value &&
                    sorted[4]?.strike?.value - sorted[3]?.strike?.value ===
                        sorted[3]?.strike?.value - sorted[2]?.strike?.value &&
                    sorted[2]?.strike?.value - sorted[1]?.strike?.value ===
                        sorted[3]?.strike?.value - sorted[2]?.strike?.value &&
                    sorted[0].activeType === 'C' &&
                    sorted[i].activeType === sorted[0].activeType
                ) {
                    callPeakButterfly.push(i);
                }
                if (
                    sorted[0].qtd === sorted[4].qtd &&
                    sorted[0].qtd !== sorted[1].qtd &&
                    sorted[0].type === 'C' &&
                    sorted[i].type !== sorted[i + 1].type &&
                    sorted[1]?.strike?.value - sorted[0]?.strike?.value ===
                        sorted[2]?.strike?.value - sorted[1]?.strike?.value &&
                    sorted[4]?.strike?.value - sorted[3]?.strike?.value ===
                        sorted[3]?.strike?.value - sorted[2]?.strike?.value &&
                    sorted[2]?.strike?.value - sorted[1]?.strike?.value ===
                        sorted[3]?.strike?.value - sorted[2]?.strike?.value &&
                    sorted[0].activeType === 'P' &&
                    sorted[i].activeType === sorted[0].activeType
                ) {
                    putPeakButterfly.push(i);
                }
            }
            if (shortCallMoth.length === size) return 'Short Call Moth Spread';
            if (longCallMoth.length === size) return 'Long Call Moth Spread';
            if (longPutMoth.length === size) return 'Long Put Moth Spread';
            if (shortPutMoth.length === size) return 'Short Put Moth Spread';
            if (callPeakButterfly.length === size)
                return 'Call Peak Double Butterfly';
            if (putPeakButterfly.length === size)
                return 'Put Peak Double Butterfly';
        }
        if (size === 6) {
            const batman = [];
            for (let i = 0; i < size / 2; i++) {
                if (
                    sorted[i].qtd === sorted[size - i - 1].qtd &&
                    sorted[i].type === sorted[size - i - 1].type &&
                    sorted[i].activeType !== sorted[size - i - 1].activeType
                ) {
                    batman.push(i);
                }
            }
            // console.log('Im batman', batman.length, 'size', size / 2);
            if (batman.length === size / 2) return 'Batman Spread';
            return null;
        }
        if (size === 8) {
            const counter = [];
            let order = true;
            let twoPuts = true;
            let equalQtd = true;
            sorted.forEach((a, i) => {
                if (i > 2) {
                    if (a.activeType !== 'P') {
                        twoPuts = false;
                    }
                }
                if (a?.strike?.value >= sorted[i + 1]?.strike?.value) {
                    order = false;
                }
                if (a.qtd !== sorted[i + 1].qtd) {
                    equalQtd = false;
                }
            });
            if (order && twoPuts && equalQtd) {
                for (let i = 0; i < size / 2; i++) {
                    if (sorted[i].type === sorted[size - i - 1].type) {
                        counter.push(i);
                    }
                }
            }
            if (counter === size / 2) return 'Pterodactyl Cake';
        }
    }
    return null;
};
