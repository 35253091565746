/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
// import {Link} from 'react-scroll';
// import {makeStyles, createStyles} from '@material-ui/core/styles';
import fadeIcon from '../img/fadeoutlogo-opti.avif';
// import seta from '../img/arrowHome.gif';

export default function Sobre() {
    /* const useStyles = makeStyles(() =>
        createStyles({
            setaDown: {
                margin: 'auto',
                display: 'flex',
                cursor: 'pointer',
                width: '60px',
                transform: 'rotate(180deg)'
            }
        })
    ); */
    // const classes = useStyles();
    return (
        <div style={{fontSize: 24}} name="header">
            <div style={{display: 'flex', alignItems: 'center'}}>
                <img alt="" src={fadeIcon} style={{width: 150}} />
                <span style={{fontSize: 34, margin: '2rem'}}>Sobre Nós</span>
            </div>
            <div style={{marginTop: '2rem'}}>
                <div name="first" style={{display: 'flex'}}>
                    <div>
                        <p>
                            A jornada da OPTI começou com uma ideia simples e um
                            desejo profundo de tornar o mundo dos investimentos
                            com opções mais acessível e descomplicado. Antes
                            conhecido como "Caju", nosso projeto nasceu do
                            desejo de criar um espaço onde os investidores
                            pudessem relaxar sob a sombra de decisões informadas
                            e estratégias sólidas, assim como a tranquilidade de
                            um cajueiro no nordeste brasileiro.
                        </p>
                        <p>
                            A transição para o nome "OPTI" não foi por acaso.
                            Ele deriva do latim "OPTIO," que significa opções, e
                            do verbo "OPTAR," que é escolher com preferência.
                            Aqui, nossos usuários optam por utilizar uma
                            ferramenta eficiente e segura. É uma escolha clara e
                            informada.
                        </p>
                        <p>
                            Com um compromisso inabalável com nossos valores e
                            uma visão de tornar o mercado de opções acessível a
                            todos, estamos empenhados em fornecer uma plataforma
                            confiável e clara para os operadores de opções e
                            investidores em potencial. Acreditamos que todos têm
                            o potencial de prosperar no mercado financeiro, e
                            nosso objetivo é ajudá-lo a alcançar seus objetivos
                            com confiança e clareza.
                        </p>
                        <p>
                            Na OPTI, estamos comprometidos com a missão de
                            simplificar a negociação de opções, oferecendo
                            ferramentas eficientes e recursos educacionais para
                            investidores de todos os níveis. Junte-se a nós
                            nesta jornada e experimente uma abordagem única e
                            direta para alcançar seus objetivos financeiros. Seu
                            sucesso é a nossa prioridade.
                        </p>
                        <p style={{fontSize: 28, fontWeight: 'bold'}}>Missão</p>
                        <p>
                            A missão é clara: Facilitar a vida dos operadores de
                            opções. Queremos que você tome decisões de
                            investimento com confiança, oferecendo ferramentas
                            que tornam a negociação de opções compreensível e
                            acessível.
                        </p>
                        <p style={{fontSize: 28, fontWeight: 'bold'}}>Visão</p>
                        <p>
                            Acreditamos que todos têm o potencial de prosperar
                            no mercado de opções. Independentemente de você
                            estar buscando crescimento financeiro ou segurança
                            futura, estamos aqui para simplificar essa jornada,
                            oferecendo recursos que ajudam a tomar decisões
                            informadas.
                        </p>
                        <p style={{fontSize: 28, fontWeight: 'bold'}}>
                            Valores
                        </p>
                        <p>
                            ● Democratização do Conhecimento Financeiro:
                            Compartilhamos informações valiosas e tornamos as
                            estruturas de opções acessíveis, para que todos
                            possam entender e participar.
                        </p>
                        <p>
                            ● Agilidade e Simplicidade: Simplificamos a
                            negociação de opções para que você possa
                            concentrar-se nas estratégias, sem a complicação.
                        </p>
                        <p>
                            ● Clareza e Confiança: Operamos com transparência e
                            confiabilidade, para que você possa investir com
                            tranquilidade.
                        </p>
                        <p>
                            ● Eficiência e Usabilidade: Fornecemos uma
                            plataforma eficiente e amigável, valorizando seu
                            tempo e experiência.
                        </p>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'end',
                            marginRight: '-50px',
                            marginLeft: '20px'
                            // marginBottom: '60px'
                        }}
                    >
                        {/* <Link activeClass="active" to="last" spy smooth>
                            <img
                                src={seta}
                                // style={{marginTop: '-350px', marginLeft: '60%'}}
                                alt=""
                                className={classes.setaDown}
                            />
                        </Link> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
