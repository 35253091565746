/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable no-plusplus */
import React, {useEffect, useState} from 'react';
import {Card} from 'react-bootstrap';
import {Fab, Popper} from '@material-ui/core';
import {
    makeStyles,
    createStyles,
    createTheme,
    ThemeProvider
} from '@material-ui/core/styles';
import WebFont from 'webfontloader';
import toast from 'react-hot-toast';
import api from '@app/utils/api';
import moment from 'moment';
import Loading from '../components/loading/Loading';
import './Calculadora.css';

export default function Calculadora() {
    const [diasUteis, setDiasUteis] = useState(0);
    const [pteorico, setPteorico] = useState(0.0);
    const [volImplicita, setVolImplcita] = useState(0.0);
    const [delta, setDelta] = useState(0.0);
    const [gamma, setGamma] = useState(0.0);
    const [vega, setVega] = useState(0.0);
    const [theta, setTheta] = useState(0.0);
    const [cotacao, setCotacao] = useState(0.0);
    const [strike, setStrike] = useState(0.0);
    const [juros, setJuros] = useState(0.0);
    const [ticker, setTicker] = useState('');
    const [type, setType] = useState('');
    const [vencimento, setVencimento] = useState('');
    const [ultimo, setUltimo] = useState();
    const [impliedVol, setImpliedVol] = useState();
    const [date, setDate] = useState();
    const [newPteorico, setNewPteorico] = useState();
    const [newCotacao, setNewCotacao] = useState();
    const [newVolImp, setNewVolImp] = useState();
    const [newJuros, setNewJuros] = useState();
    const [loading, setLoading] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const useStyles = makeStyles((theme) =>
        createStyles({
            menuPaper: {
                // backgroundColor: 'black'
                marginLeft: '-50px !important'
            }
        })
    );
    const styles = {
        marginRight: 30,
        top: 'auto',
        right: 18,
        bottom: 20,
        left: 'auto',
        position: 'fixed'
    };
    const classes = useStyles();
    const color = createTheme({
        palette: {
            primary: {
                main: '#313031'
            },
            secondary: {
                main: '#fff'
            }
        }
    });

    function handleKeyUp(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            event.target.blur();
        }
    }

    const changeTicker = (event) => {
        setTicker(event.target.value);
    };

    const changePteorico = (event) => {
        setNewPteorico(event.target.value);
    };

    const changeCotacao = (event) => {
        setNewCotacao(event.target.value);
    };

    const changeVolImp = (event) => {
        setNewVolImp(event.target.value);
    };

    const changeJuros = (event) => {
        setNewJuros(event.target.value);
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto', 'Chilanka']
            }
        });
    }, []);

    useEffect(() => {
        // const a = 'PETRA292';
        if (ticker) {
            setLoading(true);
            api.calculator
                .newData(ticker)
                .then((response) => {
                    console.log(response);
                    setJuros(response.data[1]);
                    setCotacao(response.data[0].cotacao);
                    setStrike(response.data[0].strike);
                    setType(response.data[0].tipo);
                    setDate(moment(response.data[0].data).format('YYYY-MM-DD'));
                    setPteorico(response.data[0].pteorico);
                    setVolImplcita(
                        (Number(response.data[0].new_volat) * 100)?.toFixed(2)
                    );

                    setImpliedVol(response.data[0].new_volat);
                    setUltimo(response.data[0].ultimo);
                    setDelta(
                        Number(response.data[0].new_delta)
                            ?.toFixed(2)
                            .replace('.', ',')
                    );
                    setGamma(
                        Number(response.data[0].new_gamma)
                            ?.toFixed(4)
                            .replace('.', ',')
                    );
                    setVega(
                        Number(response.data[0].new_vega)
                            ?.toFixed(4)
                            .replace('.', ',')
                    );
                    setTheta(
                        Number(response.data[0].new_theta)
                            ?.toFixed(2)
                            .replace('.', ',')
                    );
                    setVencimento(response.data[0].vencimentos);
                    setLoading(false);
                })
                .catch((e) => {
                    console.log(e);
                    setLoading(false);
                    toast.error('Ticker não Encontrado ou não existe');
                });
        }
    }, [ticker]);

    const calcBusinessDays = (startDate, endDate) => {
        const day = moment(startDate);
        let businessDays = 0;
        while (day.isSameOrBefore(endDate, 'day')) {
            if (day.day() !== 0 && day.day() !== 6) {
                businessDays++;
            }
            day.add(1, 'd');
        }
        return businessDays;
    };
    useEffect(() => {
        const today = moment().format('YYYY-MM-DD');
        const result = calcBusinessDays(today, vencimento);
        setDiasUteis(result);
    }, [vencimento]);

    useEffect(() => {
        if (strike !== 0.0)
            api.calculator
                .update(
                    Number(strike),
                    Number(cotacao),
                    date,
                    type,
                    Number(volImplicita),
                    Number(pteorico),
                    vencimento,
                    Number(ultimo),
                    Number(impliedVol),
                    juros
                )

                .then((res) => {
                    setPteorico(res.data[0]?.toFixed(2));
                    setDelta(res.data[1][0]?.toFixed(2));
                    setGamma(res.data[1][1]?.toFixed(4));
                    setVega(res.data[1][2]?.toFixed(4));
                    setTheta(res.data[1][3]?.toFixed(2));
                })
                .catch((error) => {
                    console.log(error);
                });
    }, [newVolImp, newCotacao, newJuros]);

    useEffect(() => {
        if (strike !== 0.0)
            api.calculator
                .updateCot(
                    Number(pteorico),
                    Number(strike),
                    date,
                    type,
                    Number(volImplicita),
                    vencimento,
                    Number(ultimo),
                    Number(impliedVol),
                    juros
                )
                .then((res) => {
                    setCotacao(res.data[0]?.toFixed(2));
                })
                .catch((error) => {
                    console.log(error);
                });
    }, [newPteorico]);

    return (
        <>
            <Loading open={loading} msg="Obtendo Dados..." />
            <div>
                <ThemeProvider theme={color}>
                    <Fab
                        size="large"
                        style={styles}
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        color="primary"
                    >
                        <ion-icon
                            name="calculator-outline"
                            style={{color: '#e37039', fontSize: 30}}
                        />
                    </Fab>
                    <Popper
                        id="basic-menu"
                        variant="menu"
                        anchorEl={anchorEl}
                        open={open}
                        className="custom-popper"
                        placement="top-end"
                        modifiers={{
                            offset: {enabled: true, offset: '-40px, 10px'}
                        }}
                    >
                        <Card
                            style={{
                                // marginLeft: '-80px',
                                width: '25rem',
                                height: '20rem',
                                background: '#313031',
                                fontFamily: 'Roboto'
                            }}
                        >
                            <Card.Header
                                style={{border: 'none', boxShadow: 'none'}}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        fontSize: 22
                                    }}
                                >
                                    <span style={{marginTop: -12}}>
                                        <ion-icon
                                            name="calculator-outline"
                                            style={{
                                                color: '#e37039'
                                            }}
                                        />
                                    </span>
                                    <h4 style={{color: '#fff', marginTop: -12}}>
                                        CALCULADORA B&S
                                    </h4>
                                    <span>
                                        <ion-icon
                                            name="close-outline"
                                            onClick={handleClose}
                                            style={{
                                                color: '#e37039',
                                                fontSize: 22,
                                                marginBottom: 20,
                                                marginRight: -15,
                                                cursor: 'pointer'
                                            }}
                                        />
                                    </span>
                                </div>
                                <div style={{textAlign: 'center'}}>
                                    <div>
                                        <input
                                            id="inputID"
                                            type="text"
                                            name="name"
                                            color="secondary"
                                            autoComplete="off"
                                            onBlur={changeTicker}
                                            onKeyUp={handleKeyUp}
                                            placeholder="Digite o Ticker da Opção"
                                            style={{
                                                textTransform: 'upperCase',
                                                borderRadius: '5px',
                                                textAlign: 'center',
                                                width: '75%',
                                                maxHeight: '30px',
                                                height: '100%',
                                                backgroundColor: '#e37049',
                                                color: '#fff',
                                                border: '0.5px solid #e37039'
                                            }}
                                            // onChange={updateValue}
                                        />
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        color: '#e37039'
                                    }}
                                >
                                    <span
                                        style={{
                                            borderRight: '0.2px solid grey',
                                            height: '50px',
                                            width: '140px',
                                            textAlign: 'center',
                                            marginTop: -15
                                        }}
                                    >
                                        <div>
                                            <div
                                                style={{
                                                    marginTop: 7,
                                                    fontSize: 12
                                                    // // fontWeight: 700
                                                }}
                                            >
                                                {!type ? 'INDEFINIDO' : type}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: 10,
                                                    color: '#fff'
                                                }}
                                            >
                                                TIPO
                                            </div>
                                        </div>
                                    </span>
                                    <span
                                        style={{
                                            borderRight: '0.2px solid grey',
                                            height: '50px',
                                            width: '140px',
                                            textAlign: 'center',
                                            marginTop: -15
                                        }}
                                    >
                                        <div>
                                            <div
                                                style={{
                                                    marginTop: 5,
                                                    fontSize: 14
                                                    // // fontWeight: 700
                                                }}
                                            >
                                                R${strike}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: 10,
                                                    color: '#fff'
                                                }}
                                            >
                                                STRIKE
                                            </div>
                                        </div>
                                    </span>
                                    <span
                                        style={{
                                            borderRight: '0.2px solid grey',
                                            height: '50px',
                                            width: '140px',
                                            textAlign: 'center',
                                            marginTop: -15
                                        }}
                                    >
                                        <div>
                                            <div
                                                style={{
                                                    marginTop: 5,
                                                    fontSize: 14
                                                    // // fontWeight: 700
                                                }}
                                            >
                                                {vencimento
                                                    ? moment(vencimento).format(
                                                          'DD/MM/YY'
                                                      )
                                                    : 0}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: 10,
                                                    color: '#fff'
                                                }}
                                            >
                                                VENC
                                            </div>
                                        </div>
                                    </span>
                                    <span
                                        style={{
                                            height: '60px',
                                            width: '140px',
                                            textAlign: 'center',
                                            marginTop: -15
                                        }}
                                    >
                                        <div>
                                            <div
                                                style={{
                                                    marginTop: 5,
                                                    fontSize: 14
                                                    // // fontWeight: 700
                                                }}
                                            >
                                                {!diasUteis ? 0 : diasUteis}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: 10,
                                                    color: '#fff'
                                                }}
                                            >
                                                DIAS VENC
                                            </div>
                                        </div>
                                    </span>
                                </div>{' '}
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        color: '#fff',
                                        marginTop: 20
                                    }}
                                >
                                    <span
                                        style={{
                                            borderRight: '0.2px solid grey',
                                            height: '50px',
                                            width: '140px',
                                            textAlign: 'center',
                                            marginTop: -15
                                            // justifyContent: 'center'
                                        }}
                                    >
                                        <div style={{marginTop: 5}}>
                                            <input
                                                id="inputCotacao"
                                                type="text"
                                                fontSize="12"
                                                autoComplete="off"
                                                value={`R$${cotacao}`}
                                                onChange={(e) =>
                                                    setCotacao(
                                                        e.target.value
                                                            .replace('R$', '')
                                                            .replace(',', '.')
                                                    )
                                                }
                                                onBlur={changeCotacao}
                                                onKeyUp={handleKeyUp}
                                                style={{
                                                    border: '0.2px solid #e37039',
                                                    borderRadius: '5px',
                                                    textAlign: 'center',
                                                    maxWidth: '80px',
                                                    width: '100%',
                                                    maxHeight: '30px',
                                                    height: '100%',
                                                    backgroundColor: '#e37039',
                                                    color: '#fff'
                                                }}
                                            />

                                            <div
                                                style={{
                                                    fontSize: 10,
                                                    color: '#fff'
                                                }}
                                            >
                                                COTAÇÃO
                                            </div>
                                        </div>
                                    </span>
                                    <span
                                        style={{
                                            borderRight: '0.2px solid grey',
                                            height: '50px',
                                            width: '140px',
                                            textAlign: 'center',
                                            marginTop: -15
                                        }}
                                    >
                                        <div style={{marginTop: 5}}>
                                            <input
                                                id="inputJuros"
                                                type="text"
                                                fontSize="12"
                                                autoComplete="off"
                                                value={`${juros}%`}
                                                onChange={(e) =>
                                                    setJuros(
                                                        e.target.value
                                                            .replace('%', '')
                                                            .replace(',', '.')
                                                    )
                                                }
                                                onBlur={changeJuros}
                                                onKeyUp={handleKeyUp}
                                                style={{
                                                    border: '0.2px solid #e37039',
                                                    borderRadius: '5px',
                                                    textAlign: 'center',
                                                    maxWidth: '80px',
                                                    width: '100%',
                                                    maxHeight: '30px',
                                                    height: '100%',
                                                    backgroundColor: '#e37039',
                                                    color: '#fff'
                                                }}
                                            />
                                            <div
                                                style={{
                                                    fontSize: 10,
                                                    color: '#fff'
                                                }}
                                            >
                                                TX.JUROS
                                            </div>
                                        </div>
                                    </span>
                                    <span
                                        style={{
                                            borderRight: '0.2px solid grey',
                                            height: '50px',
                                            width: '140px',
                                            textAlign: 'center',
                                            marginTop: -15
                                        }}
                                    >
                                        <div style={{marginTop: 5}}>
                                            <input
                                                id="inputImplicita"
                                                type="text"
                                                fontSize="12"
                                                autoComplete="off"
                                                value={`${volImplicita}%`}
                                                onBlur={changeVolImp}
                                                onKeyUp={handleKeyUp}
                                                onChange={(e) =>
                                                    setVolImplcita(
                                                        e.target.value
                                                            .replace('%', '')
                                                            .replace(',', '.')
                                                    )
                                                }
                                                style={{
                                                    border: '0.2px solid #e37039',
                                                    borderRadius: '5px',
                                                    textAlign: 'center',
                                                    maxWidth: '80px',
                                                    width: '100%',
                                                    maxHeight: '30px',
                                                    height: '100%',
                                                    backgroundColor: '#e37039',
                                                    color: '#fff'
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                fontSize: 10,
                                                color: '#fff'
                                            }}
                                        >
                                            VOL. IMP.
                                        </div>
                                    </span>
                                    <span
                                        style={{
                                            height: '60px',
                                            width: '140px',
                                            textAlign: 'center',
                                            marginTop: -15
                                        }}
                                    >
                                        <div>
                                            <div style={{marginTop: 5}}>
                                                <input
                                                    id="inputTeorico"
                                                    type="text"
                                                    fontSize="12"
                                                    autoComplete="off"
                                                    value={`R$${pteorico}`}
                                                    onBlur={changePteorico}
                                                    onKeyUp={handleKeyUp}
                                                    onChange={(e) =>
                                                        setPteorico(
                                                            e.target.value
                                                                .replace(
                                                                    'R$',
                                                                    ''
                                                                )
                                                                .replace(
                                                                    ',',
                                                                    '.'
                                                                )
                                                        )
                                                    }
                                                    style={{
                                                        border: '0.2px solid #e37039',
                                                        borderRadius: '5px',
                                                        textAlign: 'center',
                                                        maxWidth: '80px',
                                                        width: '100%',
                                                        maxHeight: '30px',
                                                        height: '100%',
                                                        backgroundColor:
                                                            '#e37039',
                                                        color: '#fff'
                                                    }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: 10,
                                                    color: '#fff'
                                                }}
                                            >
                                                P. TEORICO
                                            </div>
                                        </div>
                                    </span>
                                </div>{' '}
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        color: '#e37039',
                                        marginTop: 20
                                    }}
                                >
                                    <span
                                        style={{
                                            borderRight: '0.2px solid grey',
                                            height: '50px',
                                            width: '140px',
                                            textAlign: 'center',
                                            marginTop: -15
                                        }}
                                    >
                                        <div>
                                            <div
                                                style={{
                                                    marginTop: 5,
                                                    fontSize: 14
                                                    // fontWeight: 700
                                                }}
                                            >
                                                {delta}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: 10,
                                                    color: '#fff'
                                                }}
                                            >
                                                DELTA
                                            </div>
                                        </div>
                                    </span>
                                    <span
                                        style={{
                                            borderRight: '0.2px solid grey',
                                            height: '50px',
                                            width: '140px',
                                            textAlign: 'center',
                                            marginTop: -15
                                        }}
                                    >
                                        <div>
                                            <div
                                                style={{
                                                    marginTop: 5,
                                                    fontSize: 14
                                                    // fontWeight: 700
                                                }}
                                            >
                                                {gamma}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: 10,
                                                    color: '#fff'
                                                }}
                                            >
                                                GAMA
                                            </div>
                                        </div>
                                    </span>
                                    <span
                                        style={{
                                            borderRight: '0.2px solid grey',
                                            height: '50px',
                                            width: '140px',
                                            textAlign: 'center',
                                            marginTop: -15
                                        }}
                                    >
                                        <div>
                                            <div
                                                style={{
                                                    marginTop: 5,
                                                    fontSize: 14
                                                    // fontWeight: 700
                                                }}
                                            >
                                                {theta}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: 10,
                                                    color: '#fff'
                                                }}
                                            >
                                                THETA
                                            </div>
                                        </div>
                                    </span>
                                    <span
                                        style={{
                                            height: '60px',
                                            width: '140px',
                                            textAlign: 'center',
                                            marginTop: -15
                                        }}
                                    >
                                        <div>
                                            <div
                                                style={{
                                                    marginTop: 5,
                                                    fontSize: 14
                                                    // fontWeight: 700
                                                }}
                                            >
                                                {vega}
                                            </div>
                                            <div
                                                style={{
                                                    fontSize: 10,
                                                    color: '#fff'
                                                }}
                                            >
                                                VEGA
                                            </div>
                                        </div>
                                    </span>
                                </div>{' '}
                            </Card.Body>
                        </Card>
                    </Popper>
                </ThemeProvider>
            </div>
        </>
    );
}
