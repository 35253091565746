/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
// import {Link} from 'react-scroll';
// import {useHistory} from 'react-router-dom';
// import Button from '@material-ui/core/Button';
// import {makeStyles, createStyles} from '@material-ui/core/styles';
// import routing from '../routes/routing';
// import seta from '../img/arrowHome.gif';

export default function Politica() {
    // const history = useHistory();
    const handlePrivacidade = () => {
        window.location.reload();
    };
    /* const useStyles = makeStyles(() =>
        createStyles({
            setaDown: {
                margin: 'auto',
                display: 'flex',
                cursor: 'pointer',
                width: '60px',
                transform: 'rotate(180deg)'
            }
        })
    ); */
    // const classes = useStyles();
    return (
        <div>
            <div style={{display: 'flex'}}>
                <div>
                    <p style={{fontWeight: 'bold'}}>1. Dados pessoais</p>
                    <p>
                        Todos os dados pessoais fornecidos pelo usuário no
                        momento de seu acesso ou que venham a ser solicitados
                        posteriormente, a este título, e que possibilitem sua
                        identificação permanecerão sigilosos e não serão
                        repassados a quaisquer parceiros do site.
                    </p>
                    <p>
                        Os usuários poderão compartilhar comentários,
                        estratégias, imagens, links, vídeos e conteúdos diversos
                        a critério próprio, mas sempre em concordância com os
                        Termos de Uso do site, sendo que nesses casos seu nome
                        real será utilizado e poderá ser indexado por mecanismos
                        de pesquisa na internet.
                    </p>
                    <p>
                        O usuário poderá, a critério próprio, disponibilizar no
                        site{' '}
                        <span style={{fontWeight: 'bold'}}>useopti.com.br</span>{' '}
                        uma página com seu perfil onde divulgará outros dados
                        pessoais tais como: formação, especialidades, seu
                        endereço em outras redes sociais e todos conteúdos por
                        ele compartilhados publicamente no{' '}
                        <span style={{fontWeight: 'bold'}}>useopti.com.br</span>
                        . Também neste caso todas informações estarão
                        publicamente acessíveis e poderão ser indexadas por
                        mecanismos de pesquisa na internet.
                    </p>
                    <p>
                        Os administradores do site apenas poderão transmitir os
                        dados pessoais identificáveis a terceiros quando o
                        usuário permitir expressamente ou quando presentes as
                        hipóteses legais previstas no Direito brasileiro, tais
                        como a requisição judicial. Dados estatísticos agregados
                        que impossibilitem a identificação dos usuários poderão
                        eventualmente ser divulgados no site e/ou compartilhados
                        com parceiros.
                    </p>
                    <p>
                        É de responsabilidade da{' '}
                        <span style={{fontWeight: 'bold'}}>
                            Opti Investimentos
                        </span>{' '}
                        a garantia de confidencialidade dos dados pessoais
                        fornecidos pelos usuários, devendo proteger o site
                        contra tentativas de violações ou acessos clandestinos à
                        sua base de dados.
                    </p>
                    <p style={{fontWeight: 'bold'}}>2. Dados de navegação</p>
                    <p>
                        O site recorre eventualmente às técnicas de "cookies",
                        que lhe permitem analisar as estatísticas e as
                        informações sobre a navegação do usuário. Podem ser
                        fornecidos, por exemplo, dados sobre o dispositivo
                        utilizado pelo usuário e o seu local de acesso. Esta
                        coleta de informações busca melhorar a navegação, para o
                        conforto do usuário, ao permitir apresentar-lhe serviços
                        personalizados, de acordo com suas preferências.
                    </p>
                    <p>
                        Estes dados de navegação poderão, ainda, ser
                        compartilhados com eventuais parceiros do site, buscando
                        o aprimoramento dos produtos e serviços ofertados ao
                        usuário.
                    </p>
                    <p>
                        O usuário poderá se opor ao registro de "cookies" pelo
                        site, bastando desativar esta opção no seu próprio
                        navegador ou aparelho. Por outro lado, a desativação
                        deste registro poderá afetar a disponibilidade de
                        algumas ferramentas e alguns serviços do site.
                    </p>
                    <p style={{fontWeight: 'bold'}}>
                        3. Segurança da Informação
                    </p>
                    <p>
                        O{' '}
                        <span style={{fontWeight: 'bold'}}>useopti.com.br</span>{' '}
                        ressalta que por mais que as atualizações de tecnologias
                        e esforços envidados sejam frequentes no sentido de
                        reforçar a segurança das informações armazenadas e
                        transmitidas, hoje não existem sistemas de segurança
                        100% (cem por cento) imunes aos ataques cibernéticos
                        realizados por terceiros, como hackers. De tal forma,{' '}
                        <span style={{fontWeight: 'bold'}}>
                            NÃO PODEMOS GARANTIR SEGURANÇA ABSOLUTA DE NOSSOS
                            SERVIDORES E BANCOS DE DADOS, E A useopti.com.br
                            EXIME-SE DE RESPONDER POR EVENTUAIS PERDAS E DANOS
                            DECORRENTES DE AÇÃO DE TERCEIROS EM SEUS SISTEMAS E
                            QUE POSSA RESULTAR EM VAZAMENTO DE INFORMAÇÕES
                            PESSOAIS DOS USUÁRIOS DO SEU SITE.
                        </span>
                    </p>
                    <p style={{fontWeight: 'bold'}}>4. Alterações</p>
                    <p>
                        A presente versão da política de privacidade foi
                        atualizada pela última vez em 24/08/2023
                    </p>
                    <p>
                        A{' '}
                        <span style={{fontWeight: 'bold'}}>
                            Opti Investimentos
                        </span>{' '}
                        se reserva o direito de modificar a política de
                        privacidade conforme a necessidade, se comprometendo em
                        notificar os usuários utilizando para isso as próprias
                        páginas do site
                    </p>
                    <p>
                        Todos aqueles que desejarem ter acesso às informações e
                        política de privacidade através do site{' '}
                        <span style={{fontWeight: 'bold'}}>useopti.com.br</span>{' '}
                        deverão, primeiramente, se informar sobre as regras que
                        compõem o presente instrumento, as quais ficarão
                        disponíveis para pronta e ampla consulta, em{' '}
                        <span
                            style={{
                                color: '#d36233',
                                textDecoration: 'underline',
                                cursor: 'pointer'
                            }}
                            onClick={handlePrivacidade}
                        >
                            www.useopti.com.br/politica-de-privacidade.
                        </span>
                    </p>
                    <p>
                        O usuário que utilizar o serviço após realizadas
                        alterações na política de privacidade demonstra a sua
                        concordância com as novas normas. Caso discorde de
                        alguma das modificações, deverá interromper,
                        imediatamente, o acesso ao site e apresentar a sua
                        ressalva ao serviço de atendimento, se assim o desejar.
                    </p>
                    <p style={{fontWeight: 'bold'}}>5. Abrangência</p>
                    <p>
                        Essas regras sobre privacidade se aplicam apenas ao site{' '}
                        <span style={{fontWeight: 'bold'}}>useopti.com.br</span>
                        . Caso navegue para outro site referenciado no
                        useopti.com.br você deve se informar sobre a política
                        dele antes de fornecer qualquer tipo de informação.
                    </p>
                    <p>
                        O{' '}
                        <span style={{fontWeight: 'bold'}}>useopti.com.br</span>{' '}
                        salienta que não pode ser, em qualquer hipótese,
                        responsabilizado por eventuais perdas e danos sofridos
                        em razão da utilização de referidos links.
                    </p>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'end',
                        marginRight: '-30px',
                        marginLeft: '20px'
                        // marginBottom: '20px'
                    }}
                >
                    {/* <Link activeClass="active" to="last" spy smooth>
                        <img
                            src={seta}
                            // style={{marginTop: '-350px', marginLeft: '60%'}}
                            alt=""
                            clLinkassName={classes.setaDown}
                        />
                    </Link> */}
                </div>
            </div>
        </div>
    );
}
