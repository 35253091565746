/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-destructuring */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, {useState, useEffect, useRef} from 'react';
import LazyLoad from 'react-lazyload';
import _ from 'lodash';
import toast, {Toaster} from 'react-hot-toast';
import moment from 'moment';
import WebFont from 'webfontloader';
import api from '../utils/api';
import Loading from '../components/loading/Loading';
import MobileDetecter from '../components/MobileDetecter';
import MercadoFilters from '../components/MercadoFilter';
import MercadoTable from '../components/MercadoTable';

import './Mercado.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Mercado() {
    const [favoriteAtivo, setFavoriteAtivo] = useState();
    const [user, setUser] = useState();
    const [ativos, setAtivos] = useState([]);
    const [userAtivos, setUserAtivos] = useState();
    const [loading, setLoading] = useState(false);
    const [repetido, setRepetido] = useState();
    const [filterMercado, setFilterMercado] = useState([]);
    const [defaultActives, setDefaultActives] = useState();
    const [userActives, setUserActives] = useState();
    const [tableData, setTableData] = useState();
    const [assinatura, setAssinatura] = useState(false);
    const [notify, setNotify] = useState(false);
    const [screen, setScreen] = useState(window.innerWidth);
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [tmpTimeLabels, setTmpTimeLabels] = useState();

    useEffect(() => {
        setLoading(true);
        if (
            api.currentUser &&
            api.currentSession &&
            api.currentUser.preference.favorite_active
        ) {
            setUser(api.currentUser.preference.favorite_active.actives);
            if (moment() <= moment(api.currentUser?.assinatura?.vencimento)) {
                setAssinatura(true);
            }
        }
        WebFont.load({
            google: {
                families: ['Roboto', 'Rubik-light']
            }
        });

        if (api.currentUser) {
            api.mercado
                .getUserActives()
                .then((res) => {
                    if (api.currentUser.preference.favorite_active) {
                        setUserActives(res.data.retorno[1]);
                    }
                    setDefaultActives(res.data.retorno[0]);
                    // console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            api.mercado
                .getDefaultActives()
                .then((res) => {
                    // console.log(res);
                    setDefaultActives(res.data.retorno[0]);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        function handleResize() {
            setScreen(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (tableData) {
            if (user) {
                const listActives = [];
                tableData.forEach((ativo) => {
                    listActives.push(ativo.name);
                });
                const listUserAtivos = [];
                const listAtivos = [];
                user.forEach((a) => {
                    listAtivos.push(a);
                    listUserAtivos.push(a.name);
                });
                const filterRepeated = listActives.filter(
                    (ativo) => !listUserAtivos.includes(ativo)
                );
                const listAllAtivos = listUserAtivos.concat(filterRepeated);
                setAtivos(listAtivos);
                setUserAtivos(listAllAtivos);
            }
        }
    }, [tableData]);

    useEffect(() => {
        if (defaultActives) {
            if (user) {
                setTableData(userActives.concat(defaultActives));
            } else setTableData(defaultActives);
        }
    }, [defaultActives]);

    useEffect(() => {
        if (filterMercado) {
            setTableData(filterMercado);
        }
    }, [filterMercado]);

    // UseEffect que Remove um ativo como favorito ou adiciona como favorito
    useEffect(() => {
        const listRepetidos = [];
        if (favoriteAtivo && ativos) {
            ativos.forEach((ativo) => {
                listRepetidos.push(ativo.name);
            });
            console.log('repetidos', listRepetidos);
            console.log(listRepetidos.includes(favoriteAtivo.name));

            if (listRepetidos.length < 3 || assinatura) {
                if (!listRepetidos.includes(favoriteAtivo.name)) {
                    ativos.push(favoriteAtivo);
                    console.log(ativos);

                    const userPreference = {
                        page: 'mercado',
                        actives: ativos
                    };

                    const tmpativo = defaultActives.filter(
                        (a) => a.name === favoriteAtivo.name
                    );
                    const tmpfavs = userActives;
                    tmpfavs.push(tmpativo[0]);

                    console.log(tmpfavs);
                    console.log(tmpativo);

                    api.user
                        .createPreference({preference: userPreference})
                        .then((res) => console.log(res))
                        .catch((err) => {
                            console.log(err);
                        });
                    setUserActives(tmpfavs);
                    setFavoriteAtivo(null);
                }
            }
        } else if (repetido && ativos) {
            const desfavoritar = ativos.filter(
                (ativo) => ativo.name !== repetido.name
            );
            console.log(desfavoritar);
            setAtivos(desfavoritar);

            const tmpfavs = userActives.filter((a) => a.name !== repetido.name);

            const userPreference = {
                page: 'mercado',
                actives: desfavoritar
            };
            api.user
                .createPreference({preference: userPreference})
                .then((res) => console.log(res))
                .catch((err) => {
                    console.log(err);
                });
            console.log('desfavoritado');
            setUserActives(tmpfavs);
            setRepetido(null);
        }
    }, [favoriteAtivo, repetido]);

    useEffect(() => {
        if (notify) {
            if (!api.currentSession && !api.currentUser)
                toast.error('Você não está logado!');
            else toast.error('Seu plano está limitado a três favoritos');
            setNotify(false);
        }
    }, [notify]);

    return (
        <>
            <Loading open={loading} msg="Carregando Ativos..." />
            <MobileDetecter />
            <div style={{marginLeft: '20px'}}>
                <MercadoFilters
                    actives={defaultActives}
                    favs={userActives}
                    setFilterMercado={setFilterMercado}
                    setTmpTimeLabels={setTmpTimeLabels}
                />
            </div>

            <div
                style={{
                    marginLeft: '20px'
                }}
                className={
                    screen <= 1366
                        ? 'mercadoWrapper1366'
                        : screen <= 1680
                        ? 'mercadoWrapper1600'
                        : 'mercadoWrapper1920'
                }
            >
                {filterMercado.map((ativo, i) => {
                    return (
                        <LazyLoad
                            key={ativo.name}
                            height={400}
                            offset={400}
                            once
                        >
                            <MercadoTable
                                key={ativo.name}
                                user={user}
                                active={ativo.name}
                                setLoading={setLoading}
                                dataActives={tableData}
                                setRepetido={setRepetido}
                                setFavoriteAtivo={setFavoriteAtivo}
                                assinatura={assinatura}
                                setNotify={setNotify}
                                ativos={ativos}
                                windowSize={windowSize}
                                screen={screen}
                                tmpTimeLabels={tmpTimeLabels}
                            />
                        </LazyLoad>
                    );
                })}
            </div>
            <Toaster />
        </>
    );
}
export default Mercado;
