/* eslint-disable prefer-const */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable indent */
/* eslint-disable  no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable react/no-this-in-sfc */
/* eslint-enable no-underscore-dangle */
import React, {useState, useEffect} from 'react';
import {Box, Grid, Slider as SliderM} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import moment from 'moment';

import Slider from 'react-slick';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';
import 'moment/locale/pt-br';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const useStyles = makeStyles((theme) =>
    createStyles({
        myButton: {
            color: '#2a3176',
            backgroundColor: '#f4f4f4',
            fontWeight: 'bold'
        },
        myBox: {
            marginLeft: theme.spacing(1.2),
            marginBottom: 6
        },
        myGrid: {
            minWidth: 90,
            maxHeight: 30,
            maxWidth: 90,
            margin: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            textAlign: 'center',
            cursor: 'pointer',
            fontSize: 14,
            border: '2px solid #c5c5c5'
        },
        myGridSelected: {
            minWidth: 90,
            maxWidth: 90,
            maxHeight: 30,
            backgroundColor: '#e37039',
            margin: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 14,
            display: 'flex',
            textAlign: 'center',
            cursor: 'pointer',
            border: '2px solid #e37039',
            color: '#fff'
        },
        myLabel: {
            color: '#d36233',
            fontSize: 14
        },
        myLabelSelected: {
            padding: theme.spacing(6),
            color: '#fff',
            fontWeight: 'bold'
        },
        mySlider: {
            borderRadius: '5px',
            '& .MuiSlider-rail': {
                color: '#c7c7c7',
                height: 15,
                borderRadius: '5px',
                left: 0
            },
            '& .MuiSlider-track': {
                height: 15,
                color: '#e37039'
                // borderRadius: '5px'
                // paddingLeft: '10px'
            },
            '& .MuiSlider-thumb': {
                height: 25,
                width: 3,
                color: '#e37039',
                marginLeft: '0px',
                borderRadius: '1px',
                boxShadow: 'none'
            },
            '& .MuiSlider-markLabel': {
                top: '-10px',
                color: '#313031',
                fontSize: 10,
                backgroundColor: '#c7c7c7',
                borderRadius: '5px',
                width: '60px',
                height: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            },
            '& .MuiSlider-mark': {
                width: '0px',
                height: '0px',
                '&.MuiSlider-markActive': {
                    opacity: 1,
                    backgroundColor: '#e37039'
                }
            },
            '& .MuiSlider-valueLabel': {
                fontSize: 12,
                fontWeight: 'normal',
                top: -6,
                backgroundColor: '#e37039',
                color: '#fff',
                '&:before': {
                    display: 'none'
                },
                '& *': {
                    background: 'transparent',
                    color: '#313031'
                }
            }
        },
        tooltip: {
            backgroundColor: '#e37039',
            color: '#fff'
        }
    })
);
function ValueLabelComponent(props, classes) {
    const {children, open, value} = props;
    if (children.props['data-index'] === 1) {
        return (
            <Tooltip
                open={open}
                enterTouchDelay={0}
                title={`R$${value}`}
                classes={{tooltip: classes.tooltip}}
                placement="top"
            >
                {children}
            </Tooltip>
        );
    }
    return (
        <Tooltip
            open={open}
            enterTouchDelay={0}
            title={`R$${value}`}
            classes={{tooltip: classes.tooltip}}
            placement="bottom"
        >
            {children}
        </Tooltip>
    );
}

ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired
};

export default function Filters({data, setData, active, sliderLabel}) {
    const classes = useStyles();
    const [size, setSize] = useState(window.innerWidth);
    const myVenc = data[active]?.filters?.history?.find((e) => e.status).label;
    const [range, setRange] = useState([
        {value: 0, label: 'R$0'},
        {value: 100, label: 'R$100'}
    ]);

    moment().locale('pt-br');
    const [activeWeek, setActiveWeek] = useState(false);
    const atual = moment().month();
    const [months, setMonths] = useState([
        {
            label: 'JANEIRO',
            id: 1,
            status: !!(atual === 0 || atual - 1 === 0)
        },
        {
            label: 'FEVEREIRO',
            id: 2,
            status: !!(atual === 1 || atual - 1 === 1)
        },
        {
            label: 'MARÇO',
            id: 3,
            status: !!(atual === 2 || atual - 1 === 2)
        },
        {
            label: 'ABRIL',
            id: 4,
            status: !!(atual === 3 || atual - 1 === 3)
        },
        {
            label: 'MAIO',
            id: 5,
            status: !!(atual === 4 || atual - 1 === 4)
        },
        {
            label: 'JUNHO',
            id: 6,
            status: !!(atual === 5 || atual - 1 === 5)
        },
        {
            label: 'JULHO',
            id: 7,
            status: !!(atual === 6 || atual - 1 === 6)
        },
        {
            label: 'AGOSTO',
            id: 8,
            status: !!(atual === 7 || atual - 1 === 7)
        },
        {
            label: 'SETEMBRO',
            id: 9,
            status: !!(atual === 8 || atual - 1 === 8)
        },
        {
            label: 'OUTUBRO',
            id: 10,
            status: !!(atual === 9 || atual - 1 === 9)
        },
        {
            label: 'NOVEMBRO',
            id: 11,
            status: !!(atual === 10 || atual - 1 === 10)
        },
        {
            label: 'DEZEMBRO',
            id: 12,
            status: !!(atual === 11 || atual - 1 === 11)
        }
    ]);

    const [year, setYear] = useState([
        {
            label: moment().year(),
            status: true
        },
        {
            label: moment().add(1, 'years').year(),
            status: false
        },
        {
            label: moment().add(2, 'years').year(),
            status: false
        }
    ]);

    const [week, setWeek] = useState([
        {
            label: 'W1',
            status: 1
        },
        {
            label: 'W2',
            status: 0
        },
        {
            label: 'W3',
            status: 0
        },
        {
            label: 'W4',
            status: 0
        },
        {
            label: 'W5',
            status: 0
        }
    ]);

    useEffect(() => {
        if (data && active) {
            const list = [];
            const filterYear = year.find((y) => y.status);
            const tmpYear = data[active]?.values[myVenc]?.venc?.filter(
                (d) => moment(d.label).year() === filterYear.label
            );
            tmpYear?.forEach((y) => {
                list.push({
                    label: moment(y.label)
                        .locale('pt-br')
                        .format('MMMM')
                        .toUpperCase(),
                    id: Number(moment(y.label).format('M')),
                    status: y.status
                });
            });
            const base = data[active]?.values[myVenc]?.venc;

            const filterMonth = list.filter((l) => l.status);

            const activeVenc = [];
            filterMonth.forEach((mes) => {
                base.forEach((ativo) => {
                    if (
                        moment(ativo.label).month() + 1 === mes.id &&
                        moment(ativo.label).year() === moment().year()
                    ) {
                        activeVenc.push(ativo);
                    }
                });
            });

            const unique = list.reduce((uniqueArray, obj) => {
                const dupe = uniqueArray.find(
                    (item) => item.label === obj.label
                );

                if (dupe) {
                    if (obj.status) {
                        dupe.status = true;
                    }
                } else {
                    uniqueArray.push(obj);
                }
                return uniqueArray;
            }, []);
            setMonths(unique);

            const listWeeks = [];
            list.forEach((obj) => {
                if (obj.status) {
                    const cont = tmpYear.filter(
                        (t) => moment(t.label).month() + 1 === obj.id
                    );
                    if (cont.length > 1 && checkStatus(cont)) {
                        listWeeks.push(obj.id);
                    }
                }
            });
            setActiveWeek(listWeeks);

            if (listWeeks.length > 0 && week.length > 5) {
                const baseData = _.cloneDeep(data[active]?.values[myVenc].data);

                const filterMonth = unique.find((y) => y.status);
                const vencforMonth = baseData.filter(
                    (f) =>
                        moment(f.venc).year() === filterYear.label &&
                        Number(moment(f.venc).format('M')) === filterMonth.id
                );

                // ENCONTRA QUAIS SEMANAS TEM VENCIMENTO
                const existingweeks = [];
                week.forEach((w, i) => {
                    const tmp = vencforMonth.find(
                        (ativo) => ativo.ticker.slice(-2) === w.label
                    );
                    if (w.label === 'W3') existingweeks.push('W3');
                    if (tmp) existingweeks.push(tmp.ticker.slice(-2));
                });

                const tmp = [];
                const check = baseData.find((d) => d.venc === tmpYear[0].label);

                existingweeks.forEach((w) => {
                    if (w === check?.ticker.slice(-2)) {
                        tmp.push({
                            label: w,
                            status: true
                        });
                    } else {
                        tmp.push({
                            label: w,
                            status: false
                        });
                    }
                });

                setWeek(tmp);
            }

            const marks = [
                {
                    value: data[active]?.filters?.strike?.min,
                    label: `R$${data[active]?.filters?.strike?.min.toString()}`
                },
                {
                    value: data[active]?.filters?.strike?.max,
                    label: `R$${data[active]?.filters?.strike?.max.toString()}`
                }
            ];
            setRange(marks);
        }
    }, [data, active]);

    function SampleNextArrow(props) {
        const {className, style, onClick} = props;
        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
                className={className}
                style={{
                    ...style,
                    display: 'block',
                    background: '#e37039',
                    borderRadius: '100%',
                    height: 19,
                    width: 20
                }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const {className, style, onClick} = props;
        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
                className={className}
                style={{
                    ...style,
                    display: 'block',
                    background: '#e37039',
                    borderRadius: '100%',
                    height: 19,
                    width: 20,
                    marginLeft: 5
                }}
                onClick={onClick}
            />
        );
    }
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow:
            data[active]?.values[myVenc]?.venc?.length > 0 &&
            data[active]?.values[myVenc]?.venc?.length < 12
                ? data[active]?.values[myVenc]?.venc?.length
                : 12,
        slidesToScroll:
            data[active]?.values[myVenc]?.venc?.length > 0 &&
            data[active]?.values[myVenc]?.venc?.length < 12
                ? data[active]?.values[myVenc]?.venc?.length
                : 12,
        initialSlide: 0,
        autoplay: false,
        autoplaySpeed: 8000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1424,
                settings: {
                    slidesToShow:
                        data[active]?.values[myVenc]?.venc?.length > 0 &&
                        data[active]?.values[myVenc]?.venc?.length < 10
                            ? data[active]?.values[myVenc]?.venc?.length
                            : 10,
                    slidesToScroll:
                        data[active]?.values[myVenc]?.venc?.length > 0 &&
                        data[active]?.values[myVenc]?.venc?.length < 10
                            ? data[active]?.values[myVenc]?.venc?.length
                            : 10,
                    infinite: false,
                    dots: true
                }
            },
            {
                breakpoint: 1224,
                settings: {
                    slidesToShow:
                        data[active]?.values[myVenc]?.venc?.length > 0 &&
                        data[active]?.values[myVenc]?.venc?.length < 8
                            ? data[active]?.values[myVenc]?.venc?.length
                            : 8,
                    slidesToScroll:
                        data[active]?.values[myVenc]?.venc?.length > 0 &&
                        data[active]?.values[myVenc]?.venc?.length < 8
                            ? data[active]?.values[myVenc]?.venc?.length
                            : 8,
                    infinite: false,
                    dots: true
                }
            },
            {
                breakpoint: 950,
                settings: {
                    slidesToShow:
                        data[active]?.values[myVenc]?.venc?.length > 0 &&
                        data[active]?.values[myVenc]?.venc?.length < 6
                            ? data[active]?.values[myVenc]?.venc?.length
                            : 6,
                    slidesToScroll:
                        data[active]?.values[myVenc]?.venc?.length > 0 &&
                        data[active]?.values[myVenc]?.venc?.length < 6
                            ? data[active]?.values[myVenc]?.venc?.length
                            : 6,
                    infinite: false,
                    dots: true
                }
            },
            {
                breakpoint: 730,
                settings: {
                    slidesToShow:
                        data[active]?.values[myVenc]?.venc?.length > 0 &&
                        data[active]?.values[myVenc]?.venc?.length < 4
                            ? data[active]?.values[myVenc]?.venc?.length
                            : 4,
                    slidesToScroll:
                        data[active]?.values[myVenc]?.venc?.length > 0 &&
                        data[active]?.values[myVenc]?.venc?.length < 4
                            ? data[active]?.values[myVenc]?.venc?.length
                            : 4,
                    infinite: false,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow:
                        data[active]?.values[myVenc]?.venc?.length > 0 &&
                        data[active]?.values[myVenc]?.venc?.length < 3
                            ? data[active]?.values[myVenc]?.venc?.length
                            : 3,
                    slidesToScroll:
                        data[active]?.values[myVenc]?.venc?.length > 0 &&
                        data[active]?.values[myVenc]?.venc?.length < 3
                            ? data[active]?.values[myVenc]?.venc?.length
                            : 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow:
                        data[active]?.values[myVenc]?.venc?.length > 0 &&
                        data[active]?.values[myVenc]?.venc?.length < 2
                            ? data[active]?.values[myVenc]?.venc?.length
                            : 2,
                    slidesToScroll:
                        data[active]?.values[myVenc]?.venc?.length > 0 &&
                        data[active]?.values[myVenc]?.venc?.length < 2
                            ? data[active]?.values[myVenc]?.venc?.length
                            : 2
                }
            },
            {
                breakpoint: 309,
                settings: {
                    slidesToShow:
                        data[active]?.values[myVenc]?.venc?.length > 0 &&
                        data[active]?.values[myVenc]?.venc?.length < 1
                            ? data[active]?.values[myVenc]?.venc?.length
                            : 1,
                    slidesToScroll:
                        data[active]?.values[myVenc]?.venc?.length > 0 &&
                        data[active]?.values[myVenc]?.venc?.length < 1
                            ? data[active]?.values[myVenc]?.venc?.length
                            : 1
                }
            }
        ]
    };

    const getOldData = () => {
        const oldData = {};
        const dataKeys = Object.keys(data);
        dataKeys.forEach((key) => {
            const interKeys = Object.keys(data[key]?.values);
            oldData[key] = {filters: {}};
            oldData[key] = {values: {}};
            oldData[key].filters = _.cloneDeep(data[key].filters);
            interKeys.forEach((k) => {
                oldData[key].values = {
                    ...oldData[key].values,
                    [k]: {data: [], venc: []}
                };
                oldData[key].values[k].data = _.cloneDeep(
                    data[key].values[k].data
                );
                /* const venc = _.cloneDeep(data[key].values[k].venc);
                venc.forEach((v, i) => {
                    if (moment(v.label).format('M') === months[i].id) {
                        v.status = months[i].status;
                    }
                });
                oldData[key].values[k].venc = venc; */
                oldData[key].values[k].venc = _.cloneDeep(
                    data[key].values[k].venc
                );
            });
        });
        // COMENTAR ABAIXO
        // const keys = Object.keys(data[active]?.values);
        // keys.forEach((key) => {
        //     oldData[key] = {data: [], venc: []};
        //     oldData[key].data = _.cloneDeep(data[active].values[key].data);
        //     oldData[key].venc = _.cloneDeep(data[active].values[key].venc);
        // });

        return oldData;
        // DESCOMENTAR ACIMA
    };

    const handleChangeSlider = (event, newValue) => {
        const oldData = getOldData();
        // const orig = _.cloneDeep(data);
        const strike = oldData[active]?.filters?.strike;
        strike.start = newValue[0];
        strike.end = newValue[1];
        setData(oldData);
    };

    const changeType = (e) => {
        if (e === 'call' && !data[active]?.filters?.btnType.call) {
            const oldData = getOldData();
            // const orig = _.cloneDeep(data);
            const find = oldData[active]?.filters?.btnType;
            find.call = true;
            find.put = false;
            // orig[active].values = oldData;
            setData(oldData);
        } else if (e === 'put' && !data[active]?.filters?.btnType.put) {
            const oldData = getOldData();
            // const orig = _.cloneDeep(data);
            const find = oldData[active]?.filters?.btnType;
            find.call = false;
            find.put = true;
            // orig[active].values = oldData;
            setData(oldData);
        }
    };

    const changeHistory = (e) => {
        const oldData = getOldData();

        const findBase = oldData[active].filters.history;
        if (!e.status) {
            findBase.forEach((dt) => {
                if (dt.label === e.label) {
                    dt.status = true;
                } else {
                    dt.status = false;
                }
            });
            // orig[active].values = oldData;
            setData(oldData);
        }
    };

    /* const selectData = (e) => {
        const oldData = getOldData();
        const findBase = oldData[active].values[myVenc].venc;
        if (!e.status) {
            findBase.forEach((dt) => {
                if (dt.label === e.label) {
                    dt.status = true;
                } else {
                    dt.status = false;
                }
            });
            // orig[active].values = oldData;
            setData(oldData);
        }
    };
 */
    const changeMonth = (e) => {
        const orig = getOldData();
        const findBase = orig[active].values[myVenc].venc;
        const findData = orig[active].values[myVenc].data;

        const list = [];
        const vencs = [];
        findBase.forEach((f) => {
            if (moment(f.label).month() + 1 === e.id) {
                list.push(f);
                vencs.push(f.label);
            }
        });
        const template = ['W1', 'W2', 'W3', 'W4', 'W5'];
        const tmp = [];
        if (list.length > 1) {
            tmp.push({label: 'W3', status: false});
            template.forEach((t, index) => {
                for (let i = 0; i < findData.length; i++) {
                    if (
                        findData[i].ticker.slice(-2) === t &&
                        vencs.includes(findData[i].venc)
                    ) {
                        if (index === 0) {
                            tmp.push({
                                label: t,
                                status: true
                            });
                        } else {
                            tmp.push({
                                label: t,
                                status: false
                            });
                        }
                        break;
                    }
                }
            });
        } else {
            tmp.push({label: 'W3', status: false});
        }
        const listweek = tmp.sort((a, b) => a.label.localeCompare(b.label));
        setWeek(listweek);
        if (!e.status) {
            findBase.forEach((dt) => {
                if (dt.label === vencs[0]) {
                    dt.status = true;
                } else {
                    dt.status = false;
                }
            });
            const tmp = [];
            months.forEach((m) => {
                if (m.id === e.id) {
                    tmp.push({
                        label: m.label,
                        id: m.id,
                        status: true
                    });
                } else {
                    tmp.push({
                        label: m.label,
                        id: m.id,
                        status: false
                    });
                }
            });
            setData(orig);
            setMonths(tmp);
        }
    };

    const changeYear = (e) => {
        const list = [];
        const orig = _.cloneDeep(data);
        const findBase = orig[active].filters.venc; // VENCIMENTOS
        const filterYear = year.find((y) => y.label === e.label); // ANO
        const oldYear = year.find((y) => y.status); // ANO CASO TROQUE
        const tmpYear = findBase?.filter(
            (d) => moment(d.label).year() === filterYear.label
        );
        // VENCIMENTOS DO ANO

        tmpYear?.forEach((y, i) => {
            list.push({
                label: moment(y.label)
                    .locale('pt-br')
                    .format('MMMM')
                    .toUpperCase(),
                id: Number(moment(y.label).format('M')),
                status: i === 0 ? true : y.status
            });
        });
        // TODOS OS VENCIMENTOS ANUAIS EM UMA LISTA

        const unique = list.reduce((uniqueArray, obj) => {
            const dupe = uniqueArray.find((item) => item.label === obj.label);
            if (dupe) {
                if (obj.status) {
                    dupe.status = true;
                }
            } else {
                uniqueArray.push(obj);
            }
            return uniqueArray;
        }, []);
        setMonths(unique);

        filterYear.status = !filterYear.status;
        oldYear.status = !oldYear.status;
        const newMonth = list.find((l) => l.status);

        const listOld = findBase.filter(
            (d) => moment(d.label).year() === oldYear.label
        );
        listOld.forEach((l) => {
            if (l.status) {
                l.status = !l.status;
            }
        });

        const listMonth = findBase.filter(
            (d) => moment(d.label).year() === filterYear.label
        );
        const findData = listMonth?.find(
            (y) => Number(moment(y.label).format('M')) === newMonth.id
        );
        if (!findData.status) {
            findData.status = !findData.status;
            setData(orig);
        } else {
            const has = orig[active].filters.venc.find(
                (dt1) =>
                    moment(dt1.label).month() !== e.id && dt1.status === true
            );
            if (has) {
                findData.status = !findData.status;
                setData(orig);
            }
        }
    };

    const checkStatus = (cont) => {
        return cont.some((obj) => obj.status === true);
    };

    const checkWeek = (cont) => {
        if (cont) {
            const orig = _.cloneDeep(months);
            const checked = orig.filter((m) => m.status);
            return checked.some((c) => cont?.includes(c.id));
        }
    };

    const changeWeek = (e) => {
        const orig = getOldData();
        const filterYear = year.find((y) => y.status); // ANO FILTRADO
        const filterMonth = months.filter((y) => y.status); // MES FILTRADO
        const findweek = week.filter((w) => w.status);
        const template = [];
        week.forEach((w) => {
            template.push(w.label);
        });
        const tmpmonth = [];
        orig[active].filters.venc.forEach((m) => {
            filterMonth.forEach((f) => {
                if (
                    moment(m.label).month() + 1 === f.id &&
                    moment(m.label).year() === filterYear.label
                ) {
                    tmpmonth.push(m.label);
                }
            });
        });
        const base = orig[active].values[myVenc].data;
        const tmp = [];
        base.forEach((b) => {
            filterMonth.forEach((f) => {
                if (moment(b.venc).month() + 1 === f.id) {
                    tmp.push(b);
                }
            });
        });

        const vencWeeks = [];
        if (e.label !== 'W3') {
            week.forEach((w) => {
                if (w.label === 'W3' && w.status) {
                    if (!e.status) {
                        tmpmonth.forEach((f) => {
                            const noweek = tmp.find(
                                (t) =>
                                    !template.includes(t.ticker.slice(-2)) &&
                                    !e.status &&
                                    t.venc === f
                            );
                            if (noweek) vencWeeks.push(noweek);
                            if (e.status && findweek.length === 1) {
                                const last = tmp.find(
                                    (t) =>
                                        t.ticker.slice(-2) === e.label &&
                                        e.status &&
                                        t.venc === f
                                );
                                if (last) vencWeeks.push(last);
                            }
                        });
                    } else {
                        tmpmonth.forEach((f) => {
                            const noweek = tmp.find(
                                (t) =>
                                    !template.includes(t.ticker.slice(-2)) &&
                                    e.status &&
                                    t.venc === f
                            );
                            if (noweek) vencWeeks.push(noweek);
                        });
                    }
                }
                tmpmonth.forEach((f) => {
                    const found = tmp.find(
                        (t) =>
                            t.ticker.slice(-2) === w.label &&
                            w.status &&
                            t.ticker.slice(-2) !== e.label &&
                            t.venc === f
                    );
                    if (found) vencWeeks.push(found);
                    if (e.status && findweek.length === 1) {
                        const last = tmp.find(
                            (t) =>
                                t.ticker.slice(-2) === e.label &&
                                e.status &&
                                t.venc === f
                        );
                        if (last) vencWeeks.push(last);
                    }
                });
            });
            tmpmonth.forEach((f) => {
                const found = tmp.find(
                    (t) =>
                        t.ticker.slice(-2) === e.label &&
                        !e.status &&
                        t.venc === f
                );
                if (found) vencWeeks.push(found);
            });
        } else {
            week.forEach((w) => {
                tmpmonth.forEach((f) => {
                    const found = tmp.find(
                        (t) =>
                            t.ticker.slice(-2) === w.label &&
                            w.status &&
                            t.ticker.slice(-2) !== e.label &&
                            t.venc === f
                    );
                    if (found) vencWeeks.push(found);
                });
            });
            tmpmonth.forEach((f) => {
                const noweek = tmp.find(
                    (t) =>
                        !template.includes(t.ticker.slice(-2)) &&
                        !e.status &&
                        t.venc === f
                );
                if (noweek) vencWeeks.push(noweek);
                if (e.status && findweek.length === 1) {
                    const last = tmp.find(
                        (t) =>
                            !template.includes(t.ticker.slice(-2)) &&
                            e.status &&
                            t.venc === f
                    );
                    if (last) vencWeeks.push(last);
                }
            });
        }
        orig[active].values[myVenc].venc.forEach((f) => {
            let cont = 0;
            vencWeeks.forEach((w) => {
                if (f.label === w.venc) {
                    cont++;
                }
            });
            if (cont >= 1) {
                f.status = true;
            } else {
                f.status = false;
            }
        });
        const newweek = [];

        week.forEach((w, i) => {
            if (w.label === e.label) {
                if (findweek.length === 1 && e.status) {
                    newweek.push({
                        label: w.label,
                        status: w.status
                    });
                } else {
                    newweek.push({
                        label: w.label,
                        status: !e.status
                    });
                }
            } else {
                newweek.push({
                    label: w.label,
                    status: w.status
                });
            }
        });

        setWeek(newweek);
        setData(orig);
    };

    return (
        <>
            <Grid
                container
                spacing={1}
                style={{
                    justifyContent: 'space-between'
                }}
            >
                <Grid item xs={12} sm={6} md={6} lg={4} xl={6}>
                    <Grid
                        container
                        spacing={1}
                        style={{
                            justifyContent: 'space-between'
                        }}
                    >
                        <Grid item xs={12} sm={10} md={5} lg={2} xl={4}>
                            <ReactTooltip id="ticker" />
                            <ReactTooltip id="vencimento" place="right" />
                            <ReactTooltip id="historico" />
                            <ReactTooltip id="str" />

                            <Box
                                className={classes.myBox}
                                style={{marginLeft: 5}}
                            >
                                <label className={classes.myLabel}>TIPO</label>
                                <span
                                    data-for="vencimento"
                                    style={{cursor: 'pointer'}}
                                    data-tip="CALL - contrato de compra ou PUT - contrato de venda"
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    alignItems: 'center'
                                }}
                            >
                                <Grid
                                    className={
                                        data[active]?.filters?.btnType?.call
                                            ? classes.myGridSelected
                                            : classes.myGrid
                                    }
                                    onClick={() => changeType('call')}
                                >
                                    CALL
                                </Grid>
                                <Grid
                                    className={
                                        data[active]?.filters?.btnType?.put
                                            ? classes.myGridSelected
                                            : classes.myGrid
                                    }
                                    onClick={() => changeType('put')}
                                >
                                    PUT
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sm={10}
                            md={6}
                            lg={4}
                            xl={4}
                            // style={{marginTop: size < 960 && -15}}
                        >
                            <Box className={classes.myBox}>
                                <label className={classes.myLabel}>
                                    HISTÓRICO
                                </label>
                                <span
                                    style={{cursor: 'pointer'}}
                                    data-tip="HISTÓRICO - Dados históricos do mapa de opções."
                                    data-for="historico"
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    alignItems: 'center'
                                }}
                            >
                                {data[active]?.filters?.history?.map(
                                    (history) => (
                                        <Grid
                                            key={history.label}
                                            className={
                                                history.status
                                                    ? classes.myGridSelected
                                                    : classes.myGrid
                                            }
                                            onClick={() =>
                                                changeHistory(history)
                                            }
                                        >
                                            {moment(history.label).format(
                                                'DD-MM-YYYY'
                                            )}
                                        </Grid>
                                    )
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={10} md={7} lg={3} xl={4}>
                    <Box className={classes.myBox} style={{marginLeft: -4}}>
                        <label
                            className={classes.myLabel}
                            style={{worSpacing: '0.5px'}}
                        >
                            FAIXA DE STRIKE
                        </label>
                        <span
                            style={{cursor: 'pointer'}}
                            data-tip="Faixa de strike - Faixas dos preços de exercício dos contratos de opção."
                            data-for="str"
                        >
                            <ion-icon
                                style={{
                                    marginBottom: 2,
                                    fontSize: 12,
                                    color: '#d36233',
                                    pointerEvents: 'none'
                                }}
                                name="help-circle-outline"
                            />
                        </span>
                    </Box>
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center'
                        }}
                    >
                        <div
                            className={classes.mySlider}
                            style={{
                                width: '90%'
                            }}
                            // ref={ref}
                            // onScroll={handleShowScrollPosition}
                        >
                            <SliderM
                                className={classes.mySlider}
                                size="medium"
                                max={
                                    Number(
                                        data[active]?.filters?.strike?.max
                                    ) || 5000
                                }
                                min={
                                    Number(
                                        data[active]?.filters?.strike?.min
                                    ) || 0
                                }
                                value={[
                                    Number(
                                        data[active]?.filters?.strike?.start
                                    ),
                                    Number(
                                        Number(
                                            data[active]?.filters?.strike?.end
                                        ) -
                                            Number(
                                                data[active]?.filters?.strike
                                                    ?.start
                                            )
                                    ).toFixed(2)
                                ]}
                                valueLabelDisplay={sliderLabel}
                                aria-labelledby="range-slider"
                                ValueLabelComponent={(props) =>
                                    ValueLabelComponent(props, classes)
                                }
                                onChange={handleChangeSlider}
                                marks={range}
                            />
                        </div>
                    </Box>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={1}
                style={{
                    marginLeft:
                        size >= 1900 ? '-18px' : size >= 1600 ? '-10px' : '0px'
                }}
            >
                <Grid item xs={12}>
                    <>
                        <Box
                        /* className={classes.myBox} style={{
                                marginBottom: '-16px'
                            }} */
                        >
                            <label
                                className={classes.myLabel}
                                style={{
                                    marginLeft:
                                        size >= 1920
                                            ? '30px'
                                            : size >= 1600
                                            ? '10px'
                                            : '12px'
                                }}
                            >
                                VENCIMENTO
                            </label>
                            <span
                                data-for="vencimento"
                                style={{cursor: 'pointer'}}
                                data-tip="Vencimento - Data de vencimento dos contratos de opção."
                            >
                                <ion-icon
                                    style={{
                                        marginBottom: 2,
                                        fontSize: 12,
                                        color: '#d36233',
                                        pointerEvents: 'none'
                                    }}
                                    name="help-circle-outline"
                                />
                            </span>
                        </Box>
                        <div
                            style={{
                                display: 'flex',
                                marginBottom: '20px',
                                marginLeft: window.innerWidth > 1400 ? 20 : 0
                            }}
                        >
                            <Grid
                                container
                                style={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    alignItems: 'center'
                                }}
                            >
                                {year.map((y) => (
                                    <Grid
                                        key={y.label}
                                        item
                                        className={
                                            y.status
                                                ? classes.myGridSelected
                                                : classes.myGrid
                                        }
                                        onClick={() => changeYear(y)}
                                    >
                                        {y.label}
                                    </Grid>
                                ))}
                            </Grid>
                            {checkWeek(activeWeek) ? (
                                <Grid
                                    container
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'start',
                                        alignItems: 'center',
                                        marginLeft: '50px'
                                    }}
                                >
                                    {week.map((m) => (
                                        <Grid
                                            key={m.label}
                                            item
                                            className={
                                                m.status
                                                    ? classes.myGridSelected
                                                    : classes.myGrid
                                            }
                                            onClick={() => changeWeek(m)}
                                        >
                                            {m.label}
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : null}
                        </div>

                        <Grid
                            container
                            style={{
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'center',
                                marginLeft: window.innerWidth > 1400 ? 20 : 0
                            }}
                        >
                            {months.map((m) => (
                                <Grid
                                    key={m.label}
                                    item
                                    className={
                                        m.status
                                            ? classes.myGridSelected
                                            : classes.myGrid
                                    }
                                    onClick={() => changeMonth(m)}
                                >
                                    {m.label}
                                </Grid>
                            ))}
                        </Grid>
                    </>
                </Grid>
            </Grid>
        </>
    );
}
