import React, {useState} from 'react';
import {Card} from 'react-bootstrap';
import moment from 'moment';
import {Checkbox, /* TextField, */ Button} from '@material-ui/core';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import Loading from '@app/components/loading/Loading';
import api from '../utils/api';
import img from '../img/confirmation.avif';

export default function CancelSub({setOpen, confirm, setConfirm}) {
    const useStyles = makeStyles(() =>
        createStyles({
            myTextField: {
                backgroundColor: '#cac6c2',
                color: '#e37039',
                width: '500px',
                height: '40px',
                borderRadius: '20px',
                '&::placeholder': {
                    opacity: 1,
                    color: '#e37039'
                },
                border: '0px',
                paddingLeft: '15px'
            },
            myInput: {
                disableUnderline: true,
                width: '95%',
                marginLeft: '15px',
                height: '40px',
                color: '#e37039',
                fontSize: 18
            },
            myButton: {
                width: '150px',
                height: '45px',
                backgroundColor: '#e37039',
                color: '#000',
                fontSize: 26,
                borderRadius: '20px',
                margin: '15px 0px 15px 0px'
            }
        })
    );
    const classes = useStyles();
    const [value, setValue] = useState();
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);

    const handleChange = (e, name) => {
        setValue(name);
    };

    const handleText = (e) => {
        setText(e.target.value);
    };

    const sendReason = (v, t) => {
        setLoading(true);
        const reason = {
            reason: v,
            feedback: t
        };
        /* api.assinatura
            .cancelAssinatura(reason)
            .then(() => {
                setConfirm(true);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            }); */
        api.assinatura
            .cancelment(api.currentUser?.user_id, reason)
            .then(() => {
                setConfirm(true);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    return (
        <>
            <Loading open={loading} msg="Cancelando Assinatura" />
            {!confirm ? (
                <Card
                    style={{
                        backgroundColor: '#313031',
                        width: '40rem',
                        height: '32rem',
                        borderRadius: '30px',
                        fontFamily: 'Roboto'
                    }}
                >
                    <div
                        style={{
                            textAlign: 'center',
                            color: '#e37039',
                            fontSize: 28,
                            fontWeight: 700,
                            margin: '40px 0px 20px 0px'
                        }}
                    >
                        <div>Que pena que vai nos deixar!</div>
                        <div>Qual o motivo do cancelamento?</div>
                    </div>
                    <div
                        style={{
                            margin: '0px 5% 0px 10%',
                            fontSize: 24,
                            fontWeight: 400
                        }}
                    >
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                style={{color: '#e37039'}}
                                onChange={(e) => handleChange(e, 'preço')}
                                checked={value === 'preço'}
                            />
                            <span
                                style={{
                                    color: '#fff'
                                }}
                            >
                                Preço - Valor dos pacotes está caro.
                            </span>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                style={{color: '#e37039'}}
                                onChange={(e) =>
                                    handleChange(e, 'insatisfacao')
                                }
                                checked={value === 'insatisfacao'}
                            />
                            <span
                                style={{
                                    color: '#fff'
                                }}
                            >
                                Insatisfação com a plataforma - Não agradou!
                            </span>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Checkbox
                                style={{color: '#e37039'}}
                                onChange={(e) => handleChange(e, 'outros')}
                                checked={value === 'outros'}
                            />
                            <span
                                style={{
                                    color: '#fff'
                                }}
                            >
                                Outros...
                            </span>
                        </div>
                    </div>
                    <div
                        style={{
                            textAlign: 'center',
                            margin: '20px 0px 0px 0px'
                        }}
                    >
                        <input
                            className={classes.myTextField}
                            /* InputProps={{
                                disableUnderline: true,
                                className: classes.myInput
                            }} */
                            style={{fontWeight: 'bold', color: '#e37039'}}
                            placeholder="Digite aqui seu feedback para a equipe Opti."
                            value={text}
                            onChange={handleText}
                        />
                    </div>
                    <div
                        style={{
                            textAlign: 'center',
                            fontSize: 30,
                            margin: '20px 0px 0px 0px',
                            color: '#e37039',
                            fontWeight: 700
                        }}
                    >
                        Deseja mesmo cancelar sua assinatura?
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                            margin: '10px 0px 0px 0px'
                        }}
                    >
                        <div>
                            <Button
                                variant="contained"
                                className={classes.myButton}
                                onClick={() => setOpen(false)}
                            >
                                NÃO
                            </Button>
                        </div>
                        <div>
                            <Button
                                variant="contained"
                                className={classes.myButton}
                                onClick={() => sendReason(value, text)}
                            >
                                SIM
                            </Button>
                        </div>
                    </div>
                </Card>
            ) : (
                <Card
                    style={{
                        backgroundColor: '#313031',
                        width: '42rem',
                        height: '28rem',
                        borderRadius: '30px'
                    }}
                >
                    <div
                        style={{
                            color: '#e37039',
                            // position: 'fixed',
                            backgroundColor: '#313031',
                            borderRadius: '50%',
                            width: '32px',
                            height: '32px',
                            marginLeft: '97%',
                            marginTop: '-8px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <ion-icon
                            name="close-circle-outline"
                            style={{fontSize: 34, cursor: 'pointer'}}
                            onClick={() => setOpen(false)}
                        />
                    </div>
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                margin: '10px'
                            }}
                        >
                            <div style={{width: '400px', marginLeft: '-80px'}}>
                                <img
                                    src={img}
                                    alt="confirmação"
                                    style={{width: '130%'}}
                                />
                            </div>
                            <div style={{color: '#fff', fontSize: 24}}>
                                <span style={{width: '100%'}}>
                                    ENVIAMOS A CONFIRMAÇÃO DE CANCELAMENTO PARA
                                    O EMAIL:
                                </span>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        margin: '20px 30px 0px 0px',
                                        color: '#e37039',
                                        fontSize: 30
                                    }}
                                >
                                    {api.currentUser.email}
                                </div>
                                <div style={{marginTop: '20px'}}>
                                    SEU PLANO TEM VALIDADE ATÉ:
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        margin: '20px 30px 0px 0px',
                                        color: '#e37039',
                                        fontSize: 30
                                    }}
                                >
                                    {moment(
                                        api.currentUser?.assinatura?.vencimento
                                    ).format('DD/MM/YYYY')}
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                textAlign: 'center',
                                color: '#cac6c2',
                                fontSize: 22
                            }}
                        >
                            <div>Sua assinatura foi cancelada com sucesso.</div>
                            <div>
                                Agradecemos por ter sido parte da nossa
                                comunidade.
                            </div>
                            <div>Esperamos te ver novamente em breve!</div>
                        </div>
                    </div>
                </Card>
            )}
        </>
    );
}
