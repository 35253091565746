/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {Card, Modal, Row, Col, Button} from 'react-bootstrap';
import {Grid, Box} from '@material-ui/core';
import moment from 'moment';
// import {toast, {Toaster}} from 'react-toastify';
import toast, {Toaster} from 'react-hot-toast';
import api from '@app/utils/api';

export default function CardWalletBlank({open, cards}) {
    const stonks = ((1000 - 1) / 1) * 100;
    const user = api.currentUser;
    const [ilimitado, setIlimitado] = useState(false);

    useEffect(() => {
        if (api.currentSession && user) {
            if (
                user?.assinatura?.vencimento &&
                moment() <= moment(user?.assinatura?.vencimento)
            ) {
                setIlimitado(true);
            }
        }
    }, []);

    const handleNew = () => {
        if (!cards || cards.length < 3 || ilimitado) {
            open(true);
        } else {
            toast.error(
                'Plano limitado a 3 carteiras. Para mais informações consulte a equipe Opti!',
                {
                    duration: 10000
                }
            );
        }
    };
    return (
        <>
            <Toaster />
            <div
                style={{
                    height: '12rem',
                    width: '9rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <div
                    style={{
                        background: '#313031',
                        width: '54px',
                        height: '50px',
                        borderRadius: '50%',
                        color: '#000',
                        margin: 'auto',
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <h1
                        style={{
                            marginTop: '8px',
                            fontSize: '50px'
                        }}
                    >
                        <span
                            onClick={handleNew}
                            style={{
                                color: '#e37039',
                                cursor: 'pointer'
                            }}
                        >
                            +
                        </span>
                    </h1>
                </div>
            </div>
            {/* <Card
                style={{
                    width: '19rem',
                    // minWidth: '22rem',
                    // marginLeft: '11px',
                    height: '12rem',
                    marginBottom: '10px'
                }}
                // key={card.id}
            >
                <Card.Header
                    variant="top"
                    style={{
                        // background: '#313031',
                        color: '#fff',
                        height: '52%'
                    }}
                >
                    <div
                        style={{
                            display: 'inline',
                            marginLeft: '82%'
                        }}
                    />
                    <div
                        style={{
                            marginTop: '20px',
                            textAlign: 'center',
                            paddingTop: '10px'
                        }}
                    />
                    <div
                        style={{
                            background: '#cac6c7',
                            width: '75px',
                            height: '70px',
                            borderRadius: '50%',
                            color: '#000',
                            margin: 'auto',
                            justifyContent: 'center',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <h1
                            style={{
                                fontSize: '70px'
                            }}
                        >
                            <span
                                // onClick={() => handleModal()}
                                style={{
                                    color: '#e37039',
                                    cursor: 'pointer'
                                }}
                            >
                                +
                            </span>
                        </h1>
                    </div>
                </Card.Header>
                <Card.Body
                    style={{
                        maxHeight: '40px'
                    }}
                >
                    <Card.Text
                        style={{
                            display: 'flex',
                            marginTop: '30px',
                            fontSize: '12px',
                            justifyContent: 'space-between'
                        }}
                    />
                </Card.Body>
            </Card> */}
        </>
    );
}
