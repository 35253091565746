/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {Link} from 'react-scroll';
import {useHistory} from 'react-router-dom';
import WebFont from 'webfontloader';

import {makeStyles, createStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';

import routing from '../routes/routing';
import api from '../utils/api';
import Politica from '../utils/privacidade';
import Planos from './Planos';
import Usuario from './Usuario';
import Login from './Login/Login';

import icone from '../img/OPTI_logo.avif';
// import seta from '../img/arrowHome.gif';
import setaup from '../img/arrowHome1.gif';
import logofinal from '../img/OPTI_logo1_escuro.avif';
import insta from '../img/INSTA.avif';
import youtube from '../img/YOUTUBE.avif';
import twitter from '../img/TWITTER.avif';

const useStyles = makeStyles((theme) =>
    createStyles({
        icone: {
            width: 40
        },
        iconeGrande: {
            width: 150,
            height: 150
        },
        logo: {
            display: 'flex',
            marginLeft: '20%',
            width: '65%'
        },
        logofinal: {
            display: 'flex',
            marginBottom: '10px',
            width: '25%'
        },
        seta: {
            marginLeft: '64%',
            display: 'flex',
            cursor: 'pointer',
            width: '120px',
            transform: 'rotate(180deg)'
        },
        seta3: {
            margin: 'auto',
            marginTop: '70px',
            display: 'flex',
            justifyContent: 'flex-end',
            cursor: 'pointer',
            width: '120px'
        },
        seta3Mini: {
            margin: 'auto',
            marginTop: '70px',
            display: 'flex',
            justifyContent: 'flex-end',
            cursor: 'pointer',
            width: '100px'
        },
        midias: {
            margin: '5px',
            width: 65
        },
        menuButton: {
            marginRight: theme.spacing(2)
        },
        title: {
            cursor: 'pointer',
            margin: 30,
            color: '#0e0b0f',
            fontWeight: 'bold'
        }
    })
);

export default function Privacidade() {
    const classes = useStyles();
    const history = useHistory();
    const redirect = true;
    const [open, setOpen] = React.useState(false);
    const [size, setSize] = React.useState(window.innerWidth);

    const handleTermos = () => {
        history.push(routing.termos);
    };

    const handlePrivacidade = () => {
        history.push(routing.home);
    };

    const handleSobre = () => {
        history.push(routing.sobrenos);
    };

    const handleFaq = () => {
        history.push(routing.faq);
    };

    const handleLink = (name) => {
        if (name === 'youtube') {
            window.open(
                'https://www.youtube.com/channel/UCFq7ze2_01Xr4oxcoHT_bvA'
            );
        } else if (name === 'tiktok') {
            window.open('https://www.tiktok.com/@use.opti?lang=en');
        } else {
            window.open(
                'https://www.instagram.com/use.opti/?igshid=NGExMmI2YTkyZg%3D%3D'
            );
        }
    };

    React.useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto', 'Rubik']
            }
        });
        function handleResize() {
            setSize(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const LastSection = () => {
        return (
            <>
                <div name="last" className="container7">
                    <div className="topo7">
                        <Link activeClass="active" to="first" spy smooth>
                            <img
                                src={setaup}
                                alt=""
                                className={
                                    size >= 1600
                                        ? classes.seta3
                                        : classes.seta3Mini
                                }
                            />
                        </Link>
                        <div
                            style={{
                                backgroundColor: '#787374',
                                height: '10px',
                                width: '85%',
                                margin: '0 auto'
                                // marginLeft: '5%'
                            }}
                        />
                    </div>
                    <div
                        className="Centro7"
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            textAlign: 'center',
                            color: '#d36233',
                            width: '100%'
                        }}
                    >
                        {size >= 1600 ? (
                            <h2 style={{color: '#000'}}>
                                <span
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                        window.location.href =
                                            'mailto:contato@useopti.com.br';
                                    }}
                                >
                                    CENTRAL DE AJUDA <br />
                                </span>
                                <Link
                                    style={{cursor: 'pointer', color: '#000'}}
                                    activeClass="active"
                                    to="first"
                                    spy
                                    smooth
                                    onClick={handleSobre}
                                >
                                    SOBRE NÓS <br />
                                </Link>
                                <Link
                                    style={{cursor: 'pointer', color: '#000'}}
                                    activeClass="active"
                                    to="first"
                                    spy
                                    smooth
                                    onClick={handleFaq}
                                >
                                    PERGUNTAS FREQUENTES <br />
                                </Link>
                                <Link
                                    style={{cursor: 'pointer', color: '#000'}}
                                    activeClass="active"
                                    to="first"
                                    spy
                                    smooth
                                    onClick={handlePrivacidade}
                                >
                                    POLÍTICAS DE PRIVACIDADE <br />
                                </Link>
                                <Link
                                    style={{cursor: 'pointer', color: '#000'}}
                                    activeClass="active"
                                    to="first"
                                    spy
                                    smooth
                                    onClick={handleTermos}
                                >
                                    TERMOS DE USO
                                </Link>
                            </h2>
                        ) : (
                            <h4 style={{color: '#000'}}>
                                <span
                                    style={{cursor: 'pointer'}}
                                    onClick={() => {
                                        window.location.href =
                                            'mailto:contato@useopti.com.br';
                                    }}
                                >
                                    CENTRAL DE AJUDA <br />
                                </span>
                                <Link
                                    style={{cursor: 'pointer', color: '#000'}}
                                    activeClass="active"
                                    to="first"
                                    spy
                                    smooth
                                    onClick={handleSobre}
                                >
                                    SOBRE NÓS <br />
                                </Link>
                                <Link
                                    style={{cursor: 'pointer', color: '#000'}}
                                    activeClass="active"
                                    to="first"
                                    spy
                                    smooth
                                    onClick={handleFaq}
                                >
                                    PERGUNTAS FREQUENTES <br />
                                </Link>
                                <Link
                                    style={{cursor: 'pointer', color: '#000'}}
                                    activeClass="active"
                                    to="first"
                                    spy
                                    smooth
                                    onClick={handlePrivacidade}
                                >
                                    POLÍTICAS DE PRIVACIDADE <br />
                                </Link>
                                <Link
                                    style={{cursor: 'pointer', color: '#000'}}
                                    activeClass="active"
                                    to="first"
                                    spy
                                    smooth
                                    onClick={handleTermos}
                                >
                                    TERMOS DE USO
                                </Link>
                            </h4>
                        )}
                    </div>
                    <div className="base7">
                        <div
                            style={{
                                backgroundColor: '#787374',
                                height: '10px',
                                width: '85%',
                                margin: '0 auto'
                                // marginLeft: '5%'
                            }}
                        />
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                color: '#313031',
                                margin:
                                    window.innerWidth >= 1600
                                        ? 0
                                        : '-15px 0px -75px 0px'
                            }}
                        >
                            <div style={{margin: '8%'}}>
                                <img
                                    src={logofinal}
                                    alt=""
                                    className={classes.logofinal}
                                />
                                <h4>
                                    AV SHISHIMA HIFUMI, 2911 <br /> PARQUE
                                    TECNOLÓGICO UNIVAP, URBANOVA
                                    <br /> SÃO JOSÉ DOS CAMPOS - SP <br />
                                    CEP 12244-000
                                </h4>
                            </div>
                            <div style={{maxWidth: '300px', margin: '8%'}}>
                                <h2
                                    style={{
                                        color: '#d36233',
                                        marginLeft: '-20px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <a
                                        onClick={() => {
                                            window.location.href =
                                                'mailto:contato@useopti.com.br';
                                        }}
                                    >
                                        contato@useopti.com.br
                                    </a>
                                </h2>
                                <h2 style={{color: '#313031'}}>
                                    {/* +55 12 99999-9999 */}
                                </h2>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <button
                                        type="button"
                                        style={{
                                            backgroundColor: 'transparent',
                                            border: 0
                                        }}
                                        onClick={() => handleLink('instagram')}
                                    >
                                        <img
                                            src={insta}
                                            alt=""
                                            className={classes.midias}
                                            style={{cursor: 'pointer'}}
                                        />
                                    </button>

                                    <button
                                        type="button"
                                        style={{
                                            backgroundColor: 'transparent',
                                            border: 0
                                        }}
                                        onClick={() => handleLink('youtube')}
                                    >
                                        <img
                                            src={youtube}
                                            alt=""
                                            className={classes.midias}
                                            style={{cursor: 'pointer'}}
                                        />
                                    </button>

                                    <button
                                        type="button"
                                        style={{
                                            backgroundColor: 'transparent',
                                            border: 0
                                        }}
                                        onClick={() => handleLink('tiktok')}
                                    >
                                        <img
                                            src={twitter}
                                            alt=""
                                            className={classes.midias}
                                            style={{cursor: 'pointer'}}
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <AppBar position="fixed" style={{backgroundColor: '#cac6c2'}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                        onClick={() => history.push(routing.home)}
                    >
                        <img src={icone} alt="" className={classes.icone} />
                    </IconButton>
                    <div style={{flexGrow: 1, marginLeft: '10%'}}>
                        <Link
                            activeClass="active"
                            to="second"
                            spy
                            smooth
                            onClick={handlePrivacidade}
                        >
                            <Typography
                                variant="inherit"
                                className={classes.title}
                            >
                                VANTAGENS
                            </Typography>
                        </Link>
                        <Typography
                            variant="inherit"
                            className={classes.title}
                            onClick={() => history.push('/grade-de-opcoes')}
                        >
                            GRADE DE OP
                            <span
                                style={{
                                    fontFamily: 'Rubik',
                                    fontWeight: '300',
                                    fontSize: 16.5
                                }}
                            >
                                Ç
                            </span>
                            ÕES
                        </Typography>
                        <Typography
                            variant="inherit"
                            className={classes.title}
                            onClick={() => history.push('/mapa-de-opcoes')}
                        >
                            MAPA DE OP
                            <span
                                style={{
                                    fontFamily: 'Rubik',
                                    fontWeight: '300',
                                    fontSize: 16.5
                                }}
                            >
                                Ç
                            </span>
                            ÕES
                        </Typography>
                        <Planos />
                    </div>
                    {!api.currentSession ? (
                        <Button
                            color="inherit"
                            style={{backgroundColor: ' #e37039', width: 100}}
                            size="small"
                            onClick={() => setOpen(true)}
                        >
                            Login
                        </Button>
                    ) : (
                        <Usuario />
                    )}
                </Toolbar>
            </AppBar>
            <div style={{fontFamily: 'Roboto'}}>
                <div
                    name="first"
                    style={{
                        background: '#313031',
                        height: '250px',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: '5rem'
                    }}
                >
                    <img className={classes.iconeGrande} src={icone} alt="" />
                </div>
                <div
                    className="Baixo"
                    style={{
                        background: '#cac6c2',
                        height: '70px',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 26,
                        paddingLeft: '5.5rem'
                    }}
                >
                    Política de Privacidade
                </div>
                <div
                    style={{
                        // width: '90%',
                        margin: '2rem 4rem 2rem 5.4rem',
                        fontSize: 14,
                        display: 'flex',
                        justifyContent: 'space-between',
                        textAlign: 'justify'
                    }}
                >
                    <Politica />
                </div>
                <div>
                    <LastSection />
                </div>
            </div>
            <Dialog
                // fullWidth
                maxWidth="lg"
                open={open}
                onClose={() => {
                    setOpen(false);
                    setOpen();
                }}
                classes={{
                    paper: classes.dialog
                }}
            >
                <Login redirect={redirect} />
            </Dialog>
        </>
    );
}
