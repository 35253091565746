/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable no-unused-vars */
import React, {useState, useEffect} from 'react';
import ReactTooltip from 'react-tooltip';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';

import {makeStyles} from '@material-ui/core/styles';
// import moment from 'moment';

const useStyles = makeStyles(() => ({
    root: {
        '& .Mui-selected': {
            backgroundColor: '#e37039',
            '&:hover': {
                backgroundColor: '#e37039'
            }
        }
    },
    tableRow: {
        height: 10,
        padding: '0px 16px',
        '&:hover': {
            background: '#efefef'
        }
    },
    tooltip: {
        width: '500px',
        wordBreak: 'break-all',
        overflowWrap: 'break-word',
        display: 'block'
    }
}));

export default function Table({
    active,
    dt1,
    tableLabel,
    strikes,
    data,
    notify
}) {
    const classes = useStyles();
    const [page, setPage] = useState(1);
    const rowsPerPage = 25;
    const [venc, setVenc] = useState();
    const [isHovering, setIsHovering] = useState(false);
    const [call, setCall] = useState(false);
    const [put, setPut] = useState(false);
    const [tableDt, setTableDt] = useState();
    const [itm, setItm] = useState(false);
    const [otm, setOtm] = useState(false);
    const [atm, setAtm] = useState(false);
    const [amer, setAmer] = useState(true);
    const [euro, setEuro] = useState(true);

    const handleMouseOver = (id) => {
        setIsHovering(id);
    };
    const handleMouseOut = () => {
        setIsHovering(false);
    };

    useEffect(() => {
        if (active && page !== 1) {
            setPage(1);
        }
    }, [active]);

    useEffect(() => {
        if (data[active]?.filters?.venc) {
            const list = [];
            const filter = data[active]?.filters;
            filter?.venc.forEach((v) => {
                if (v.status) list.push(v);
            });
            setItm(filter?.btnMoneyness.ITM);
            setOtm(filter?.btnMoneyness.OTM);
            setAtm(filter?.btnMoneyness.ATM);
            setCall(filter?.btnType.call);
            setPut(filter?.btnType.put);
            setAmer(filter?.btnModel.america);
            setEuro(filter?.btnModel.europe);
            setVenc(list);
        }
    }, [data]);

    useEffect(() => {
        if (dt1 && strikes) {
            const st = [];
            strikes.forEach((s) => {
                st.push(s[0].strike);
            });
            const list = [];
            dt1.forEach((d) => {
                if (st.includes(d[0].strike)) {
                    const get = d.sort(
                        (a, b) =>
                            moment(a.vencimento).diff(moment(b.vencimento)) ||
                            a.tipo.localeCompare(b.tipo)
                    );
                    list.push(get);
                }
            });
            setTableDt(list);
        }
    }, [strikes]);

    const show = (op) => {
        let mod = false;
        let money = false;
        if (
            (op.mod === 'AMERICANA' && amer) ||
            (op.mod === 'EUROPÉIA' && euro)
        ) {
            mod = true;
        }
        if (
            (op.money === 'ITM' && itm) ||
            (op.money === 'ATM' && atm) ||
            (op.money === 'OTM' && otm)
        ) {
            money = true;
        }
        if (mod && money) {
            return true;
        }
        return false;
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    return (
        <>
            <table className="table">
                <thead>
                    <tr
                        style={{
                            whiteSpace: 'nowrap'
                        }}
                    >
                        {tableLabel.strike.selected && (
                            <th
                                style={{
                                    color: '#d36233'
                                }}
                            >
                                <ReactTooltip
                                    id="strike"
                                    place="right"
                                    // offset={{left: -80}}
                                />
                                {tableLabel.strike.value}
                                <span
                                    style={{cursor: 'pointer'}}
                                    data-tip="Strike - Preço de exercício do contrato de opções."
                                    data-for="strike"
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </th>
                        )}
                        {tableLabel.money.selected && (
                            <th
                                style={{
                                    color: '#d36233'
                                }}
                            >
                                <ReactTooltip
                                    multiline
                                    id="money"
                                    className={classes.tooltip}
                                    data-html
                                    insecure
                                    place="right"
                                />
                                {tableLabel.money.value}
                                <span
                                    style={{cursor: 'pointer'}}
                                    data-for="money"
                                    data-tip="Moneyness - Classificação do contrato de opção em relação ao preço do ativo. <br /> OTM - out of the money, ou fora do dinheiro, <br /> ATM - at the money, ou no dinheiro <br /> e ITM - in the money, ou dentro do dinheiro."
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </th>
                        )}
                        {tableLabel.tipo.selected && (
                            <th
                                style={{
                                    color: '#d36233'
                                }}
                            >
                                <ReactTooltip id="tipo" />
                                {tableLabel.tipo.value}
                                <span
                                    style={{cursor: 'pointer'}}
                                    data-for="tipo"
                                    data-tip="CALL - contrato de compra ou PUT - contrato de venda"
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </th>
                        )}
                        {venc?.map((vencimento, index) =>
                            vencimento.status ? (
                                <th
                                    key={index}
                                    style={{
                                        color: '#d36233',
                                        position: 'relative',
                                        left: '20px'
                                    }}
                                >
                                    <ReactTooltip id={vencimento.label} />
                                    {moment(vencimento.label).format(
                                        'DD/MM/YYYY'
                                    )}
                                    <span
                                        style={{cursor: 'pointer'}}
                                        data-for={vencimento.label}
                                        data-tip="Vencimento - Data de vencimento dos contratos de opção."
                                    >
                                        <ion-icon
                                            style={{
                                                marginBottom: 2,
                                                fontSize: 12,
                                                color: '#d36233',
                                                pointerEvents: 'none'
                                            }}
                                            name="help-circle-outline"
                                        />
                                    </span>
                                </th>
                            ) : null
                        )}
                    </tr>
                </thead>
                <tbody
                    style={{
                        fontFamily: 'Roboto, sans-serif',
                        fontWeight: 400,
                        fontSize: 14
                    }}
                >
                    {tableDt
                        ? tableDt
                              .slice(
                                  (page - 1) * rowsPerPage,
                                  page * rowsPerPage
                              )
                              .map((v, ii) => (
                                  <tr key={ii} className={classes.tableRow}>
                                      {tableLabel.strike.selected && (
                                          <td
                                              style={{
                                                  whiteSpace: 'nowrap',
                                                  verticalAlign: 'middle',
                                                  paddingLeft: '20px',
                                                  width: '170px'
                                              }}
                                          >
                                              R$
                                              {Number(v[0]?.strike).toFixed(2)}
                                          </td>
                                      )}
                                      {tableLabel.money.selected && (
                                          <td>
                                              {call ? (
                                                  <div
                                                      style={{
                                                          height: put
                                                              ? '30px'
                                                              : '',
                                                          marginTop: '5px'
                                                      }}
                                                  >
                                                      {v[0]?.money}
                                                  </div>
                                              ) : (
                                                  <div> </div>
                                              )}
                                              {put ? (
                                                  call ? (
                                                      <div>{v[1]?.money}</div>
                                                  ) : (
                                                      <div
                                                          style={{
                                                              marginTop: '5px'
                                                          }}
                                                      >
                                                          {v[0]?.money}
                                                      </div>
                                                  )
                                              ) : (
                                                  <div> </div>
                                              )}
                                          </td>
                                      )}
                                      {tableLabel.tipo.selected && (
                                          <td
                                              style={{
                                                  whiteSpace: 'nowrap',
                                                  verticalAlign: 'middle',
                                                  width: '120px'
                                              }}
                                          >
                                              {call ? (
                                                  <div
                                                      style={{
                                                          height: put
                                                              ? '30px'
                                                              : ''
                                                      }}
                                                  >
                                                      CALL
                                                  </div>
                                              ) : (
                                                  <div> </div>
                                              )}
                                              {put ? (
                                                  <div>PUT</div>
                                              ) : (
                                                  <div> </div>
                                              )}
                                          </td>
                                      )}
                                      {venc?.map((vencimento, index) => (
                                          <td key={index}>
                                              <div
                                                  key={index}
                                                  style={{
                                                      width: '170px'
                                                  }}
                                              >
                                                  {v.map((op, i) =>
                                                      op.vencimento ===
                                                      vencimento.label ? (
                                                          op.ultimo !== null &&
                                                          show(op) ? (
                                                              <div
                                                                  key={i}
                                                                  style={{
                                                                      whiteSpace:
                                                                          'nowrap',
                                                                      width: '120px',
                                                                      height: '30px',
                                                                      display:
                                                                          'flex',
                                                                      cursor: 'pointer',
                                                                      justifyContent:
                                                                          'center',
                                                                      alignItems:
                                                                          'center',
                                                                      backgroundColor:
                                                                          isHovering ===
                                                                          op.ticker
                                                                              ? '#e37039'
                                                                              : '',
                                                                      borderRadius:
                                                                          isHovering ===
                                                                          op.ticker
                                                                              ? '10px'
                                                                              : '0px'
                                                                  }}
                                                                  onMouseOver={() =>
                                                                      handleMouseOver(
                                                                          op.ticker
                                                                      )
                                                                  }
                                                                  onMouseOut={() =>
                                                                      handleMouseOut()
                                                                  }
                                                              >
                                                                  {isHovering ===
                                                                  op.ticker ? (
                                                                      <div
                                                                          key={
                                                                              i
                                                                          }
                                                                          onClick={() =>
                                                                              notify(
                                                                                  op?.ticker
                                                                              )
                                                                          }
                                                                          style={{
                                                                              color: '#fff',
                                                                              display:
                                                                                  'flex',
                                                                              justifyContent:
                                                                                  'center',
                                                                              alignItems:
                                                                                  'center'
                                                                          }}
                                                                      >
                                                                          {
                                                                              op.ticker
                                                                          }

                                                                          <ion-icon
                                                                              style={{
                                                                                  // fontSize: 16,
                                                                                  height: '21px',
                                                                                  width: '17px',
                                                                                  color: '#fff'
                                                                              }}
                                                                              name="copy-outline"
                                                                          />
                                                                      </div>
                                                                  ) : (
                                                                      <div
                                                                          style={{
                                                                              width: '70px',
                                                                              textAlign:
                                                                                  'center'
                                                                          }}
                                                                      >
                                                                          R$
                                                                          {Number(
                                                                              op?.ultimo
                                                                          )?.toFixed(
                                                                              2
                                                                          )}
                                                                      </div>
                                                                  )}
                                                              </div>
                                                          ) : (
                                                              <div
                                                                  key={i}
                                                                  style={{
                                                                      whiteSpace:
                                                                          'nowrap',
                                                                      width: '90px',
                                                                      height: '30px'
                                                                  }}
                                                              >
                                                                  {' '}
                                                              </div>
                                                          )
                                                      ) : null
                                                  )}
                                              </div>
                                          </td>
                                      ))}
                                  </tr>
                              ))
                        : null}
                </tbody>
            </table>
            <Pagination
                count={Math.ceil(Number(dt1.length / rowsPerPage))}
                page={page}
                onChange={handleChangePage}
                className={classes.root}
            />
        </>
    );
}
