/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable no-lonely-if */
/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable  no-underscore-dangle */

import React, {useEffect, useState, useRef} from 'react';
import {Card, Modal} from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import {Grid} from '@material-ui/core';
import Select from 'react-select';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import api from '@app/utils/api';
import moment from 'moment';
import Loading from '@app/components/loading/Loading';
import {cleanNumber} from '@app/utils/myaux';
import NewStrategy from '@app/pages/wallet/strateggi/NewStrategy';
import _ from 'lodash';
import toast, {Toaster} from 'react-hot-toast';

import Roi from './components/Roi';
import Total from './components/Total';
import Strategies from './components/Strategies';
import NextStrategies from './components/NextStrategies';
import EmptyData from './components/EmptyData';

export default function RoexRemont({
    titulo,
    icone,
    notify,
    card,
    cards,
    setCards,
    remontagem,
    diffVenc,
    ativo,
    size,
    selic,
    ilimitado
}) {
    const [tipo, setTipo] = useState([]);
    const [vencimento, setVencimento] = useState(['']);
    const [open, setOpen] = useState(false);
    const [data, setData] = useState();
    const [isOpen, setIsOpen] = useState(true);
    const [loading, setLoading] = useState(false);
    const [myAtiv, setMyAtiv] = useState('');
    const [strategys, setStrategys] = useState();
    const [name, setName] = useState('');
    const [editId, setEditId] = useState();
    const [cotIni, setCotIni] = useState();
    const [cotFim, setCotFim] = useState();
    const [nextVenc, setNextVenc] = useState();
    const [endCard, setEndCard] = useState();
    const [initialCard, setInitialCard] = useState();
    const [listVencimentos, setListVencimentos] = useState();
    const [indexCont, setIndexCont] = useState();
    const [longa, setLonga] = useState();

    const styles = {
        control: (css) => ({
            ...css,
            padding: 0,
            margin: -10,
            height: 30,
            /* minHeight: 25,
            maxHeight: 25, */
            color: '#fff',
            // minHeight: 10,
            border: 'none',
            background: '#e37039',
            boxShadow: 'none'
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#fff',
            // height: 25,

            // fontSize: 10,
            margin: 0,
            padding: 0
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#fff',
            margin: 0,
            padding: 0
        }),
        menu: ({width, ...css}) => ({
            ...css,
            marginLeft: -7,
            marginTop: 16,
            width: 85,
            fontSize: 12,
            minWidth: '10%'
        }),
        container: (base) => ({
            ...base,
            minWidth: 85,
            maxWidth: 85,
            alignItems: 'center',
            justifyContent: 'center'
        }),
        valueContainer: (base) => ({
            ...base,
            width: 90,

            boxShadow: 'none',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#fff'
            // backgroundColor: '#e37039'
        }),
        // Add padding to account for width of Indicators Container plus padding
        option: (css, state) => ({
            ...css,
            color: '#e37039',
            width: 85,
            backgroundColor: state.isSelected
                ? ''
                : '' || state.isHovered
                ? '#cac6c2'
                : 'transparent',
            '&:hover': {
                backgroundColor: '#cac6c2'
            }
        }),
        input: (base) => ({
            ...base,
            color: '#fff',
            height: 25,
            alignItems: 'center'
        }),
        dropdownIndicator: (base) => ({
            ...base,
            color: '#fff',
            width: 30,
            marginLeft: '-15px'
            // backgroundColor: '#e37039'
        })
    };

    const handleChangeVenc = (event) => {
        const date = moment(event.value).format('YYYY-MM-DD');
        endCard.forEach((e, indice) => {
            if (e.ticker !== ativo) {
                if (remontagem[indice].length > 0) {
                    for (let j = 0; j < remontagem[indice].length; j++) {
                        if (diffVenc) {
                            indexCont.forEach((i) => {
                                if (
                                    moment(remontagem[indice][j].venc).format(
                                        'DD/MM/YYYY'
                                    ) === event.value
                                ) {
                                    // console.log(indice);
                                    if (indexCont.includes(indice)) {
                                        // console.log(remontagem[indice][j + 1], i);
                                        e.date_mont =
                                            remontagem[indice][j + 1].dataMont;
                                        e.preco_desmont =
                                            remontagem[indice][
                                                j + 1
                                            ].pteoricoDesmont;
                                        e.preco_mont =
                                            remontagem[indice][
                                                j + 1
                                            ].pteoricoDesmont;
                                        e.preco_teorico =
                                            remontagem[indice][
                                                j + 1
                                            ].pteoricoDesmont;
                                        e.preco_teorico_desmont =
                                            remontagem[indice][
                                                j + 1
                                            ].pteoricoDesmont;
                                        e.qtd = remontagem[indice][j + 1].qtd;
                                        e.type = remontagem[indice][j + 1].tipo;
                                        e.strike =
                                            remontagem[indice][j + 1].strike;
                                        e.ticker =
                                            remontagem[indice][j + 1].ticker;
                                        e.type_ativo =
                                            remontagem[indice][
                                                j + 1
                                            ].type_ativo;
                                        e.venc = remontagem[indice][j + 1].venc;
                                        // break;
                                    } else if (!indexCont.includes(indice)) {
                                        e.date_mont =
                                            remontagem[indice][j].dataMont;
                                        e.preco_desmont =
                                            remontagem[indice][
                                                j
                                            ].pteoricoDesmont;
                                        e.preco_mont =
                                            remontagem[indice][
                                                j
                                            ].pteoricoDesmont;
                                        e.preco_teorico =
                                            remontagem[indice][
                                                j
                                            ].pteoricoDesmont;
                                        e.preco_teorico_desmont =
                                            remontagem[indice][
                                                j
                                            ].pteoricoDesmont;
                                        e.qtd = remontagem[indice][j].qtd;
                                        e.type = remontagem[indice][j].tipo;
                                        e.strike = remontagem[indice][j].strike;
                                        e.ticker = remontagem[indice][j].ticker;
                                        e.type_ativo =
                                            remontagem[indice][j].type_ativo;
                                        e.venc = remontagem[indice][j].venc;
                                        // break;
                                    }
                                } else if (j === 0 && indice === i) {
                                    // console.log('teste');
                                    e.date_mont =
                                        remontagem[indice][0].dataMont;
                                    e.preco_desmont =
                                        remontagem[indice][0].pteoricoDesmont;
                                    e.preco_mont =
                                        remontagem[indice][0].pteoricoDesmont;
                                    e.preco_teorico =
                                        remontagem[indice][0].pteoricoDesmont;
                                    e.preco_teorico_desmont =
                                        remontagem[indice][0].pteoricoDesmont;
                                    e.qtd = remontagem[indice][0].qtd;
                                    e.type = remontagem[indice][0].tipo;
                                    e.strike = remontagem[indice][0].strike;
                                    e.ticker = remontagem[indice][0].ticker;
                                    e.type_ativo =
                                        remontagem[indice][0].type_ativo;
                                    e.venc = remontagem[indice][0].venc;
                                }
                            });
                        } else {
                            if (
                                moment(remontagem[indice][j].venc).format(
                                    'DD/MM/YYYY'
                                ) === event.value
                            ) {
                                // console.log(indice);
                                e.date_mont = remontagem[indice][j].dataMont;
                                e.preco_desmont =
                                    remontagem[indice][j].pteoricoDesmont;
                                e.preco_mont =
                                    remontagem[indice][j].pteoricoDesmont;
                                e.preco_teorico =
                                    remontagem[indice][j].pteoricoDesmont;
                                e.preco_teorico_desmont =
                                    remontagem[indice][j].pteoricoDesmont;
                                e.qtd = remontagem[indice][j].qtd;
                                e.type = remontagem[indice][j].tipo;
                                e.strike = remontagem[indice][j].strike;
                                e.ticker = remontagem[indice][j].ticker;
                                e.type_ativo = remontagem[indice][j].type_ativo;
                                e.venc = remontagem[indice][j].venc;
                            }
                        }
                    }
                }
            }
        });
        setNextVenc(event);
    };

    const useStyles = makeStyles(() =>
        createStyles({
            myGridVC: {
                minWidth: 15,
                maxWidth: 15,
                maxHeight: 15,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                textAlign: 'center',
                // cursor: 'pointer',
                border: '2px solid #c5c5c5'
            },
            myGridVCSelected: {
                minWidth: 15,
                maxWidth: 15,
                maxHeight: 15,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                textAlign: 'center',
                // cursor: 'pointer',
                backgroundColor: '#e37039',
                color: '#fff'
            },
            myGrid: {
                height: 38,
                width: 110,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                textAlign: 'center',
                border: '1px solid #000',
                borderRadius: '5px'
            },
            myGridSelected: {
                width: 85,
                height: 20,
                backgroundColor: '#e37039',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                textAlign: 'center',
                border: '1px solid #e37039',
                borderRadius: '2px',
                color: '#fff'
            },
            myTable: {}
        })
    );
    const classes = useStyles();

    const defaultFilters = {
        btnType: {call: true, put: true},
        btnModel: {europe: true, america: true},
        btnMoneyness: {ITM: false, ATM: false, OTM: false},
        strike: {min: 0, start: 0, end: 5000, max: 5000},
        strikeList: [],
        tikers: [],
        venc: []
    };

    const calcAloc = (strategy) => {
        let totMont = 0;
        let desMont = 0;
        console.log(strategy);
        strategy.forEach((s) => {
            if (s.type === 'V') {
                totMont += cleanNumber(s.preco_mont) * cleanNumber(s.qtd) * -1;
                desMont +=
                    cleanNumber(s.preco_desmont) * cleanNumber(s.qtd) * -1;
            } else {
                totMont += cleanNumber(s.preco_mont) * cleanNumber(s.qtd);
                desMont += cleanNumber(s.preco_desmont) * cleanNumber(s.qtd);
            }
        });
        if (desMont < 0) totMont = 0;
        return totMont;
    };

    const calcCredito = (strategy) => {
        let totMont = 0;
        strategy.forEach((s) => {
            if (s.type === 'V') {
                totMont +=
                    cleanNumber(s.preco_desmont) * cleanNumber(s.qtd) * -1;
            } else {
                totMont += cleanNumber(s.preco_desmont) * cleanNumber(s.qtd);
            }
        });
        return totMont;
    };

    const handleOpen = (myCard, newEndCard) => {
        const listAtivo = [];
        const todayMont = moment().format('YYYY-MM-DD [23:59:59.000000]');
        // const todayDesmont = moment().format('YYYY-MM-DD');
        const newCard = _.cloneDeep(newEndCard);
        newCard.forEach((n, i) => {
            if (n.ticker === ativo) listAtivo.push(i);
        });
        console.log(calcAloc(newCard));
        if (!newCard.some((e) => e.ticker === ativo)) {
            newCard.forEach((s, indice) => {
                if (remontagem[indice].length > 0) {
                    for (let i = 0; i < remontagem[indice].length; i++) {
                        if (diffVenc) {
                            indexCont.forEach((j) => {
                                if (
                                    nextVenc.value ===
                                    moment(remontagem[indice][i].venc).format(
                                        'DD/MM/YYYY'
                                    )
                                ) {
                                    if (indexCont.includes(indice)) {
                                        s.date = todayMont;
                                        s.preco_desmont =
                                            remontagem[indice][
                                                i + 1
                                            ].pteoricoDesmont;
                                        s.preco_mont =
                                            remontagem[indice][
                                                i + 1
                                            ].pteoricoDesmont;
                                        s.preco_teorico =
                                            remontagem[indice][
                                                i + 1
                                            ].pteoricoDesmont;
                                        s.preco_teorico_desmont =
                                            remontagem[indice][
                                                i + 1
                                            ].pteoricoDesmont;
                                        s.qtd = remontagem[indice][i + 1].qtd;
                                        s.type = remontagem[indice][i + 1].tipo;
                                        s.strike =
                                            remontagem[indice][i + 1].strike;
                                        s.ticker =
                                            remontagem[indice][i + 1].ticker;
                                        s.type_ativo =
                                            remontagem[indice][
                                                i + 1
                                            ].type_ativo;
                                        s.venc = remontagem[indice][i + 1].venc;
                                    } else if (!indexCont.includes(indice)) {
                                        s.date_mont = todayMont;
                                        s.preco_desmont =
                                            remontagem[indice][
                                                i
                                            ].pteoricoDesmont;
                                        s.preco_mont =
                                            remontagem[indice][
                                                i
                                            ].pteoricoDesmont;
                                        s.preco_teorico =
                                            remontagem[indice][
                                                i
                                            ].pteoricoDesmont;
                                        s.preco_teorico_desmont =
                                            remontagem[indice][
                                                i
                                            ].pteoricoDesmont;
                                        s.qtd = remontagem[indice][i].qtd;
                                        s.type = remontagem[indice][i].tipo;
                                        s.strike = remontagem[indice][i].strike;
                                        s.ticker = remontagem[indice][i].ticker;
                                        s.type_ativo =
                                            remontagem[indice][i].type_ativo;
                                        s.venc = remontagem[indice][i].venc;
                                    }
                                } else if (i === 0 && indice === j) {
                                    s.date_mont = todayMont;
                                    s.preco_desmont =
                                        remontagem[indice][0].pteoricoDesmont;
                                    s.preco_mont =
                                        remontagem[indice][0].pteoricoDesmont;
                                    s.preco_teorico =
                                        remontagem[indice][0].pteoricoDesmont;
                                    s.preco_teorico_desmont =
                                        remontagem[indice][0].pteoricoDesmont;
                                    s.qtd = remontagem[indice][0].qtd;
                                    s.type = remontagem[indice][0].tipo;
                                    s.strike = remontagem[indice][0].strike;
                                    s.ticker = remontagem[indice][0].ticker;
                                    s.type_ativo =
                                        remontagem[indice][0].type_ativo;
                                    s.venc = remontagem[indice][0].venc;
                                }
                            });
                        } else {
                            if (
                                nextVenc.value ===
                                moment(remontagem[indice][i].venc).format(
                                    'DD/MM/YYYY'
                                )
                            ) {
                                s.date_mont = todayMont;
                                s.preco_desmont =
                                    remontagem[indice][i].pteoricoDesmont;
                                s.preco_mont =
                                    remontagem[indice][i].pteoricoDesmont;
                                s.preco_teorico =
                                    remontagem[indice][i].pteoricoDesmont;
                                s.preco_teorico_desmont =
                                    remontagem[indice][i].pteoricoDesmont;
                                s.qtd = remontagem[indice][i].qtd;
                                s.type = remontagem[indice][i].tipo;
                                s.strike = remontagem[indice][i].strike;
                                s.ticker = remontagem[indice][i].ticker;
                                s.type_ativo = remontagem[indice][i].type_ativo;
                                s.venc = remontagem[indice][i].venc;
                            }
                        }
                    }
                } else {
                    s.date_mont = todayMont;
                }
            });
            console.log(newCard);
            setStrategys(newCard);
        } else {
            const tmpNewCard = newCard.filter((a) => a.ticker !== ativo);
            console.log(tmpNewCard);
            tmpNewCard.forEach((s, indice) => {
                if (remontagem[indice].length > 0) {
                    for (let i = 0; i < remontagem[indice].length; i++) {
                        if (diffVenc) {
                            indexCont.forEach((j) => {
                                if (
                                    nextVenc.value ===
                                    moment(remontagem[indice][i].venc).format(
                                        'DD/MM/YYYY'
                                    )
                                ) {
                                    if (indexCont.includes(indice)) {
                                        s.date = todayMont;
                                        s.preco_desmont =
                                            remontagem[indice][
                                                i + 1
                                            ].pteoricoDesmont;
                                        s.preco_mont =
                                            remontagem[indice][
                                                i + 1
                                            ].pteoricoDesmont;
                                        s.preco_teorico =
                                            remontagem[indice][
                                                i + 1
                                            ].pteoricoDesmont;
                                        s.preco_teorico_desmont =
                                            remontagem[indice][
                                                i + 1
                                            ].pteoricoDesmont;
                                        s.qtd = remontagem[indice][i + 1].qtd;
                                        s.type = remontagem[indice][i + 1].tipo;
                                        s.strike =
                                            remontagem[indice][i + 1].strike;
                                        s.ticker =
                                            remontagem[indice][i + 1].ticker;
                                        s.type_ativo =
                                            remontagem[indice][
                                                i + 1
                                            ].type_ativo;
                                        s.venc = remontagem[indice][i + 1].venc;
                                    } else if (!indexCont.includes(indice)) {
                                        s.date_mont = todayMont;
                                        s.preco_desmont =
                                            remontagem[indice][
                                                i
                                            ].pteoricoDesmont;
                                        s.preco_mont =
                                            remontagem[indice][
                                                i
                                            ].pteoricoDesmont;
                                        s.preco_teorico =
                                            remontagem[indice][
                                                i
                                            ].pteoricoDesmont;
                                        s.preco_teorico_desmont =
                                            remontagem[indice][
                                                i
                                            ].pteoricoDesmont;
                                        s.qtd = remontagem[indice][i].qtd;
                                        s.type = remontagem[indice][i].tipo;
                                        s.strike = remontagem[indice][i].strike;
                                        s.ticker = remontagem[indice][i].ticker;
                                        s.type_ativo =
                                            remontagem[indice][i].type_ativo;
                                        s.venc = remontagem[indice][i].venc;
                                    }
                                } else if (i === 0 && indice === j) {
                                    s.date_mont = todayMont;
                                    s.preco_desmont =
                                        remontagem[indice][0].pteoricoDesmont;
                                    s.preco_mont =
                                        remontagem[indice][0].pteoricoDesmont;
                                    s.preco_teorico =
                                        remontagem[indice][0].pteoricoDesmont;
                                    s.preco_teorico_desmont =
                                        remontagem[indice][0].pteoricoDesmont;
                                    s.qtd = remontagem[indice][0].qtd;
                                    s.type = remontagem[indice][0].tipo;
                                    s.strike = remontagem[indice][0].strike;
                                    s.ticker = remontagem[indice][0].ticker;
                                    s.type_ativo =
                                        remontagem[indice][0].type_ativo;
                                    s.venc = remontagem[indice][0].venc;
                                }
                            });
                        } else {
                            if (
                                nextVenc.value ===
                                moment(remontagem[indice][i].venc).format(
                                    'DD/MM/YYYY'
                                )
                            ) {
                                s.date_mont = todayMont;
                                s.preco_desmont =
                                    remontagem[indice][i].pteoricoDesmont;
                                s.preco_mont =
                                    remontagem[indice][i].pteoricoDesmont;
                                s.preco_teorico =
                                    remontagem[indice][i].pteoricoDesmont;
                                s.preco_teorico_desmont =
                                    remontagem[indice][i].pteoricoDesmont;
                                s.qtd = remontagem[indice][i].qtd;
                                s.type = remontagem[indice][i].tipo;
                                s.strike = remontagem[indice][i].strike;
                                s.ticker = remontagem[indice][i].ticker;
                                s.type_ativo = remontagem[indice][i].type_ativo;
                                s.venc = remontagem[indice][i].venc;
                            }
                        }
                    }
                } else {
                    s.date_mont = todayMont;
                }
            });
            const listResult = [];
            newCard.forEach((n, i) => {
                listAtivo.forEach((a) => {
                    if (i === a) {
                        listResult.push(n);
                    } else {
                        listResult.push(tmpNewCard[i]);
                    }
                });
            });
            setStrategys(listResult);
        }
        setEditId(myCard.id);
        setName(myCard.name);
        if (longa.length > 0) {
            for (let j = 0; j < remontagem.length; j++) {
                if (!longa.includes(j)) {
                    setCotIni(remontagem[j][0].cotacao);
                    break;
                }
            }
        } else setCotIni(remontagem[0][0]?.cotacao);
        setIsOpen(true);
        const ativ = myCard.ativo;
        setMyAtiv(ativ);
        if (ativ !== '') {
            setLoading(true);
            api.options
                .getCotacoesPorAtivo(ativ)
                .then((res) => {
                    const listVenc = [];
                    const listStrikes = [];
                    const listTikers = [];
                    console.log('res', res);
                    const tmp = {
                        filters: defaultFilters,
                        values: []
                    };
                    res.data.retorno.dados.forEach((resp, i) => {
                        // strikeFilters.push(parseFloat(resp.strike));
                        if (i === 0) {
                            listTikers.push({
                                label: ativ,
                                value: ativ
                            });
                        }

                        let vi;
                        let ve;
                        if (resp.tipo === 'PUT') {
                            if (
                                cleanNumber(resp.strike) <
                                cleanNumber(resp.cotacao)
                            ) {
                                vi = 0;
                                ve = cleanNumber(resp.pteorico);
                            } else {
                                vi =
                                    cleanNumber(resp.strike) -
                                    cleanNumber(resp.cotacao);
                                ve =
                                    cleanNumber(resp.pteorico) -
                                    cleanNumber(vi);
                            }
                        } else if (
                            cleanNumber(resp.strike) > cleanNumber(resp.cotacao)
                        ) {
                            vi = 0;
                            ve = cleanNumber(resp.pteorico);
                        } else {
                            vi =
                                cleanNumber(resp.cotacao) -
                                cleanNumber(resp.strike);
                            ve =
                                cleanNumber(resp.pteorico) +
                                cleanNumber(resp.strike) -
                                cleanNumber(resp.cotacao);
                        }
                        listVenc.push(resp.vencimentos);
                        listStrikes.push(cleanNumber(resp.strike));
                        listTikers.push({
                            label: resp.ticker,
                            value: resp.ticker
                        });
                        tmp.values.push({
                            ticker: resp.ticker.split('_')[0],
                            fm: resp.fm,
                            money: resp.otm,
                            mod: resp.mod === 'A' ? 'AMERICANA' : 'EUROPÉIA',
                            strike: resp.strike,
                            tipo: resp.tipo,
                            ultimo: resp.ultimo,
                            negocios: resp.num_neg,
                            vol: resp.vol_neg,
                            data: resp.data,
                            implicita: (
                                cleanNumber(resp.vol_impl) * 100
                            ).toFixed(2),
                            pteorico: resp.pteorico,
                            pteoricoDesmont: resp.pteorico,
                            delta: resp.new_delta,
                            gamma: resp.new_gamma,
                            theta: resp.new_theta,
                            vega: resp.new_vega,
                            venc: resp.vencimentos,
                            cotacao: resp.cotacao,
                            name: ativ,
                            vi: vi.toFixed(2),
                            ve: ve.toFixed(2)
                        });
                    });
                    const uniqueVenc = [...new Set(listVenc)];
                    const uniqueStrike = [...new Set(listStrikes)];
                    const strikeFinal = uniqueStrike
                        .slice()
                        .sort((a, b) => a - b);
                    uniqueVenc.sort();
                    uniqueVenc.forEach((u, i) => {
                        tmp.filters.venc.push({
                            label: moment(u).format('DD/MM/YYYY'),
                            value: u
                        });
                    });
                    strikeFinal.forEach((u, i) => {
                        tmp.filters.strikeList.push({
                            label: u,
                            value: u
                        });
                    });
                    tmp.filters.tikers = listTikers;
                    setData({[ativ]: tmp});
                    setCotFim(
                        myCard.status
                            ? tmp.values[0].cotacao
                            : myCard.cotacao_end
                    );

                    setLoading(false);
                    setOpen(true);
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        }
    };

    const handleClose = () => {
        setOpen(false);
        setData();
    };

    const calcDesmont = (strategy) => {
        let totDesMont = 0;
        strategy.forEach((s) => {
            if (s.type === 'V') {
                totDesMont +=
                    cleanNumber(s.preco_desmont) * cleanNumber(s.qtd) * -1;
            } else {
                totDesMont += cleanNumber(s.preco_desmont) * cleanNumber(s.qtd);
            }
        });
        if (totDesMont < 0) totDesMont *= -1;
        return totDesMont;
    };

    const calcLucro = (totMont, totDesm) => {
        let lucro = 0;
        if (totMont < 0 && totDesm < 0) {
            totMont *= -1;
            lucro = totMont - totDesm;
        } else if (totMont < 0 && totDesm > 0) {
            totMont *= -1;
            if (totMont > totDesm) {
                lucro = totMont - totDesm;
            } else {
                lucro = totMont - totDesm;
            }
        } else if (totMont > 0 && totDesm > 0) {
            lucro = (totMont + totDesm) * -1;
        } else if (totMont > 0 && totDesm < 0) {
            totDesm *= -1;
            if (totMont < totDesm) {
                lucro = totDesm - totMont;
            } else {
                lucro = totDesm - totMont;
            }
        }
        return lucro;
    };

    useEffect(() => {
        if (card) {
            setMyAtiv(card.ativo);
            const copy = _.cloneDeep(card.Strategys);
            const initial = [];
            const list = [];
            copy.forEach((s) => {
                if (s.must_updated) {
                    list.push(s);
                }
            });
            console.log(calcAloc(list));
            setEndCard(list);
            card.Strategys.forEach((c) => {
                if (c.must_updated) {
                    initial.push(c);
                }
            });
            setInitialCard(initial);

            const listType = [];
            const listVenc = [];
            const listids = [];

            list.forEach((s) => {
                listType.push(s.type);
                if (s.ticker !== card.ativo) {
                    // console.log(ativo, s.ticker);
                    listVenc.push(s.venc);
                }
                listids.push(s.id);
            });
            const seconds = listVenc.map((r) => moment(r));
            // const lowestDate = Math.min(...momentsec);
            const lowestDate = moment.min(seconds);
            setTipo(listType);
            setVencimento(moment(lowestDate?._i).format('DD/MM/YYYY'));
        }
    }, [card]);

    useEffect(() => {
        if (remontagem) {
            endCard.forEach((e, indice) => {
                if (e.ticker !== ativo) {
                    if (remontagem[indice].length > 0) {
                        e.date_mont = remontagem[indice][0]?.dataMont;
                        e.preco_desmont =
                            remontagem[indice][0]?.pteoricoDesmont;
                        e.preco_mont = remontagem[indice][0]?.pteoricoDesmont;
                        e.preco_teorico =
                            remontagem[indice][0]?.pteoricoDesmont;
                        e.preco_teorico_desmont =
                            remontagem[indice][0]?.pteoricoDesmont;
                        e.qtd = remontagem[indice][0]?.qtd;
                        e.type = remontagem[indice][0]?.tipo;
                        e.strike = remontagem[indice][0]?.strike;
                        e.ticker = remontagem[indice][0]?.ticker;
                        e.type_ativo = remontagem[indice][0]?.type_ativo;
                        e.venc = remontagem[indice][0]?.venc;
                    }
                }
            });

            const cont = [];
            const contMax = [];
            const listTmpVenc = [];

            const listLonga = [];
            remontagem.forEach((r, i) => {
                if (r.length < 1) {
                    listLonga.push(i);
                }
            });
            setLonga(listLonga);

            if (diffVenc) {
                let max = remontagem[0].length;

                for (let i = 1; i < remontagem.length; i++) {
                    if (max <= remontagem[i].length) {
                        max = remontagem[i].length;
                        contMax.push(i);
                    }
                }
                if (contMax.length === 0) {
                    contMax.push(0);
                }
            }

            let min = remontagem[0].length;
            for (let i = 1; i < remontagem.length; i++) {
                if (min >= remontagem[i].length) {
                    min = remontagem[i].length;
                    cont.push(i);
                }
            }
            if (cont.length === 0) {
                cont.push(0);
            }

            if (diffVenc) setIndexCont(cont);

            for (
                let j = 0;
                diffVenc
                    ? j < remontagem[contMax.at()].length - 1
                    : j < remontagem[cont.at()].length;
                j++
            ) {
                if (diffVenc) {
                    listTmpVenc.push({
                        label: moment(remontagem[contMax.at()][j].venc).format(
                            'DD/MM/YYYY'
                        ),
                        value: moment(remontagem[contMax.at()][j].venc).format(
                            'DD/MM/YYYY'
                        )
                    });
                } else {
                    listTmpVenc.push({
                        label: moment(remontagem[cont.at()][j].venc).format(
                            'DD/MM/YYYY'
                        ),
                        value: moment(remontagem[cont.at()][j].venc).format(
                            'DD/MM/YYYY'
                        )
                    });
                }
            }
            setListVencimentos(listTmpVenc);
            if (diffVenc) {
                setNextVenc({
                    label: moment(remontagem[contMax.at()][0].venc).format(
                        'DD/MM/YYYY'
                    ),
                    value: moment(remontagem[contMax.at()][0].venc).format(
                        'DD/MM/YYYY'
                    )
                });
            } else {
                setNextVenc({
                    label: moment(remontagem[cont.at()][0].venc).format(
                        'DD/MM/YYYY'
                    ),
                    value: moment(remontagem[cont.at()][0].venc).format(
                        'DD/MM/YYYY'
                    )
                });
            }
        }
    }, [remontagem]);

    return (
        <>
            <Toaster />
            <Loading open={loading} msg="Carregando carteira..." />
            <Card
                style={{
                    width: size <= 1366 ? '26rem' : '30rem',
                    padding: 0,
                    maxHeight: '40rem',
                    height: '100%',
                    marginBottom: '10px',
                    // textAlign: 'center',
                    justifyContent: 'center'
                    // marginLeft: 30
                }}
            >
                <Card.Body
                    style={{
                        color: '#000',
                        paddingRight: 0,
                        paddingLeft: 0,
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            color: '#d36233',
                            marginLeft: 'auto'
                        }}
                    >
                        <ion-icon
                            name={icone}
                            style={{
                                display: 'flex',
                                paddingRight: '15px',
                                paddingLeft: '25px',
                                color: '#d36233',
                                fontSize: '20px'
                            }}
                        />

                        <span style={{fontSize: 19}}>{titulo}</span>
                        {!card && !ilimitado ? (
                            <ion-icon
                                name="save-outline"
                                style={{
                                    display: 'flex',
                                    marginLeft: 'auto',
                                    paddingRight: '25px',
                                    fontSize: '18px',
                                    cursor: 'pointer',
                                    color: '#e37039'
                                }}
                                onClick={() =>
                                    card
                                        ? toast.error(
                                              'Plano limitado a 3 carteiras. Para mais informações consulte a equipe Opti!',
                                              {
                                                  duration: 10000
                                              }
                                          )
                                        : toast.error(
                                              'Selecione uma carteira!',
                                              {
                                                  duration: 10000
                                              }
                                          )
                                }
                            />
                        ) : (
                            <ion-icon
                                onClick={() => handleOpen(card, endCard)}
                                name="save-outline"
                                style={{
                                    display: 'flex',
                                    marginLeft: 'auto',
                                    paddingRight: '25px',
                                    fontSize: '18px',
                                    cursor: 'pointer',
                                    color: '#e37039'
                                }}
                            />
                        )}
                    </div>
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                alignItems: 'center',
                                marginTop: 30,
                                marginBottom: 10
                            }}
                        >
                            <div
                                style={{
                                    justifyContent: 'center'
                                }}
                            >
                                {!card ? (
                                    <Grid
                                        className={classes.myGrid}
                                        style={{fontSize: 16, fontWeight: 600}}
                                    >
                                        --/--/----
                                    </Grid>
                                ) : (
                                    <Grid
                                        className={classes.myGrid}
                                        style={{fontSize: 16, fontWeight: 600}}
                                    >
                                        {vencimento}
                                    </Grid>
                                )}
                            </div>
                            <div
                                style={{
                                    justifyContent: 'center'
                                    // marginTop: '-10px'
                                }}
                            >
                                <div
                                    // className="col-sm-12"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                        // height: 'px'
                                        // width: '100px'
                                        // marginLeft: '45%'
                                    }}
                                >
                                    {nextVenc ? (
                                        <Select
                                            // onChange={(e) => handleVenc(e, idx)}
                                            onChange={(e) =>
                                                handleChangeVenc(e)
                                            }
                                            isSearchable
                                            value={nextVenc}
                                            defaultValue={nextVenc}
                                            styles={styles}
                                            options={listVencimentos}
                                            components={{
                                                // DropdownIndicator: () => null,
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                    ) : (
                                        <Select
                                            isSearchable
                                            // value={nextVenc}
                                            placeholder="--/--/----"
                                            styles={styles}
                                            // options={s.vencOptFiltered}
                                            components={{
                                                // DropdownIndicator: () => null,
                                                IndicatorSeparator: () => null
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <ReactTooltip />
                        <table
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '200px'
                            }}
                        >
                            <thead
                                style={{borderRight: '0.5px solid lightgrey'}}
                            >
                                <tr
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: 12,
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <td />
                                    <td style={{marginLeft: '55px'}}>
                                        TICKER
                                        <ReactTooltip />
                                        <ion-icon
                                            data-tip="Ticker - Abreviação utilizada para identificar exclusivamente contratos de opções negociadas publicamente."
                                            style={{
                                                marginBottom: 5,
                                                fontSize: 12,
                                                color: '#d36233',
                                                cursor: 'pointer'
                                            }}
                                            name="help-circle-outline"
                                        />
                                    </td>
                                    <td style={{marginLeft: '43px'}}>
                                        STRIKE
                                        <ReactTooltip />
                                        <ion-icon
                                            data-tip="Strike - Preço de exercício do contrato de opções."
                                            style={{
                                                marginBottom: 5,
                                                fontSize: 12,
                                                color: '#d36233',
                                                cursor: 'pointer'
                                            }}
                                            name="help-circle-outline"
                                        />
                                    </td>
                                </tr>
                            </thead>
                            {!initialCard ? (
                                <tbody>
                                    <EmptyData />
                                </tbody>
                            ) : (
                                <tbody>
                                    <Strategies
                                        card={initialCard}
                                        notify={notify}
                                        tipo={tipo}
                                        titulo={titulo}
                                        longa={longa}
                                    />
                                </tbody>
                            )}
                        </table>
                        <table
                            style={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '200px'
                                // marginLeft: '10px'
                            }}
                        >
                            <thead>
                                <tr
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: 12,
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <td />
                                    <td style={{marginLeft: '55px'}}>
                                        TICKER
                                        <ion-icon
                                            data-tip="Ticker - Abreviação utilizada para identificar exclusivamente contratos de opções negociadas publicamente."
                                            style={{
                                                marginBottom: 5,
                                                fontSize: 12,
                                                color: '#d36233',
                                                cursor: 'pointer'
                                            }}
                                            name="help-circle-outline"
                                        />
                                    </td>
                                    <td style={{marginLeft: '43px'}}>
                                        STRIKE
                                        <ion-icon
                                            data-tip="Strike - Preço de exercício do contrato de opções."
                                            style={{
                                                marginBottom: 5,
                                                fontSize: 12,
                                                color: '#d36233',
                                                cursor: 'pointer'
                                            }}
                                            name="help-circle-outline"
                                        />
                                    </td>
                                </tr>
                            </thead>
                            {!endCard ? (
                                <tbody>
                                    <EmptyData isRight />
                                </tbody>
                            ) : (
                                <tbody>
                                    <NextStrategies
                                        card={endCard}
                                        notify={notify}
                                        tipo={tipo}
                                    />
                                </tbody>
                            )}
                        </table>
                    </div>

                    <table
                        style={{
                            marginTop: 20,
                            fontWeight: 'bold',
                            // width: '600px',
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <thead>
                            <tr>
                                <td
                                    style={{
                                        fontSize: 12,
                                        // marginRight: 190
                                        display: 'flex',
                                        width: '330px'
                                        // borderBottom: '1px dotted #000'
                                        // minWidth: 120,
                                        // maxWidth: 260
                                    }}
                                >
                                    CAPITAL ALOCADO
                                    <ion-icon
                                        data-tip="Cap alocado - Abreviação utilizada para identificar exclusivamente contratos de opções negociadas publicamente."
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            cursor: 'pointer'
                                        }}
                                        name="help-circle-outline"
                                    />
                                    <span
                                        style={{
                                            borderBottom: '1px dotted #000',
                                            width: 220,
                                            display: 'flex'
                                        }}
                                    />
                                </td>
                                <td
                                    style={{
                                        fontSize: 12,
                                        color: '#d36233',
                                        fontFamily: 'Roboto'
                                    }}
                                >
                                    {!endCard
                                        ? 'R$ 000,00'
                                        : new Intl.NumberFormat('pt-BR', {
                                              style: 'currency',
                                              currency: 'BRL'
                                          })
                                              .format(calcAloc(endCard))
                                              .replace('.', '')
                                              .replace('-', '')}
                                </td>
                            </tr>
                            <tr>
                                {!endCard ? (
                                    <td
                                        style={{
                                            fontSize: 12,
                                            paddingTop: '5px',
                                            display: 'flex'
                                        }}
                                    >
                                        CRÉDITO
                                        <ion-icon
                                            data-tip="Crédito"
                                            style={{
                                                marginBottom: 2,
                                                fontSize: 12,
                                                color: '#d36233',
                                                cursor: 'pointer'
                                            }}
                                            name="help-circle-outline"
                                        />
                                        <span
                                            style={{
                                                borderBottom: '1px dotted #000',
                                                width: '100%',
                                                display: 'flex'
                                            }}
                                        />
                                    </td>
                                ) : (
                                    <Total card={endCard} />
                                )}
                                <td
                                    style={{
                                        fontSize: 12,
                                        color: '#d36233',
                                        fontFamily: 'Roboto'
                                    }}
                                >
                                    {!endCard
                                        ? 'R$ 000,00'
                                        : new Intl.NumberFormat('pt-BR', {
                                              style: 'currency',
                                              currency: 'BRL'
                                          })
                                              .format(calcCredito(endCard))
                                              .replace('.', '')
                                              .replace('-', '')}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        fontSize: 12,
                                        paddingTop: '5px',
                                        display: 'flex'
                                    }}
                                >
                                    {titulo}
                                    <ion-icon
                                        data-tip="REMONTAGEM - Remontagem da estrutura inicial
                                        considerando as cotações atuais do mercado.
                                        "
                                        style={{
                                            marginBottom: 5,
                                            fontSize: 14,
                                            color: '#d36233',
                                            cursor: 'pointer'
                                        }}
                                        name="help-circle-outline"
                                    />
                                    <span
                                        style={{
                                            borderBottom: '1px dotted #000',
                                            width: '100%',
                                            display: 'flex'
                                        }}
                                    />
                                </td>
                                <td
                                    style={{
                                        fontSize: 12,
                                        color: '#d36233',
                                        fontFamily: 'Roboto'
                                    }}
                                >
                                    R${' '}
                                    {!endCard
                                        ? '000,00'
                                        : calcDesmont(endCard)
                                              .toFixed(2)
                                              .replace('.', ',')}
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style={{
                                        fontSize: 12,
                                        paddingTop: '5px',
                                        display: 'flex'
                                    }}
                                >
                                    ROI
                                    <ion-icon
                                        data-tip='"Return over investment" - Retorno sobre o investimento, ou quanto de lucro você obteve com o capital alocado.'
                                        style={{
                                            marginBottom: 5,
                                            fontSize: 12,
                                            color: '#d36233',
                                            cursor: 'pointer'
                                        }}
                                        name="help-circle-outline"
                                    />
                                    <span
                                        style={{
                                            borderBottom: '1px dotted #000',
                                            width: '100%',
                                            display: 'flex'
                                        }}
                                    />
                                </td>
                                <td
                                    style={{
                                        fontSize: 12,
                                        color: '#d36233',
                                        fontFamily: 'Roboto'
                                    }}
                                >
                                    {!endCard ? (
                                        '0,00%'
                                    ) : (
                                        <Roi
                                            card={endCard}
                                            lucro={calcDesmont(endCard)}
                                        />
                                    )}
                                </td>
                            </tr>
                        </thead>
                    </table>
                </Card.Body>
            </Card>
            <Modal show={open} onClose={handleClose} size="xl">
                <NewStrategy
                    handleClose={handleClose}
                    ativ={myAtiv}
                    data={data}
                    status={isOpen}
                    setStatus={setIsOpen}
                    cards={cards}
                    setCards={setCards}
                    myStrategy={strategys}
                    // id={editId}
                    selic={selic}
                    myName=""
                    cotIni={cotFim}
                    cotFim={cotFim}
                />
            </Modal>
        </>
    );
}
