import React, {useEffect, useState} from 'react';
import {Card} from 'react-bootstrap';
import moment from 'moment';
import {TextField, InputAdornment, Button, Checkbox} from '@material-ui/core';
import api from '@app/utils/api';

import icone from 'src/img/OPTI_logo.avif';
import cancel from 'src/img/icone-cancelLaranja.avif';

export default function AlterarCad({classes, setOpen, setCancel, data}) {
    const handleOpen = () => {
        setCancel(true);
    };

    const handleKeyUp = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            event.target.blur();
        }
    };

    const handleChange = (event, id) => {
        formdata[id] = event.target.value;
        setFormdata(formdata);
    };

    const handleCheck = () => {
        if (formdata.plano.toUpperCase() !== 'GRATUITO') {
            setCheck(!check);
            formdata.recurrency = !check;
        }
    };

    const [formdata, setFormdata] = useState();
    const [orig, setOrig] = useState({
        nome: data.nome,
        email: data.email,
        birth: data.data_nascimento,
        cpf: data.cpf,
        pais: data.pais,
        cidade: data.cidade_estado,
        estado: data.cidade_estado,
        celular: data.celular,
        genero: data.genero,
        cadastro: data.data_cad,
        plano: data.plano,
        conhecimento: data.conhecimento,
        vencimento: data.venc,
        recurrency: data.recurrency,
        obs: data.observacao
    });
    const [check, setCheck] = useState(data.recurrency);

    useEffect(() => {
        setFormdata({
            nome: data.nome,
            email: data.email,
            birth: data.data_nascimento,
            cpf: data.cpf,
            pais: data.pais,
            cidade: data.cidade_estado,
            estado: data.cidade_estado,
            celular: data.celular,
            genero: data.genero,
            cadastro: data.data_cad,
            plano: data.plano,
            conhecimento: data.conhecimento,
            vencimento: data.venc,
            recurrency: data.recurrency,
            obs: data.observacao
        });
    }, []);

    useEffect(() => {
        if (formdata && JSON.stringify(formdata) !== JSON.stringify(orig)) {
            console.log('aqui', data.id);
            api.user
                .profileUpdate({
                    uid: data.id,
                    birth: formdata.birth,
                    cpf: formdata.cpf,
                    country: formdata.pais,
                    state: formdata.estado,
                    city: formdata.cidade,
                    phone: formdata.celular,
                    name: formdata.nome,
                    email: formdata.email,
                    gender: formdata.genero,
                    nivel: formdata.conhecimento,
                    observacao: formdata.obs
                    // photo
                })
                .then((res) => {
                    console.log(res);
                    setOrig(formdata);
                })
                .catch((err) => console.log(err));
            if (JSON.stringify(formdata.plano) !== JSON.stringify(orig.plano)) {
                api.assinatura
                    .changePlano()
                    .then((res) => {
                        console.log(res);
                    })
                    .catch((err) => console.log(err));
            }
        }
    }, [formdata]);

    return formdata ? (
        <>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontFamily: 'Roboto'
                }}
            >
                <Card
                    style={{
                        backgroundColor: '#cac6c2',
                        width: '45%',
                        height: '90%',
                        display: 'flex',
                        flexDirection: 'row',
                        borderRadius: '15px',
                        fontSize: 26
                    }}
                >
                    <div style={{padding: '2.5rem', width: '100%'}}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '54%'
                            }}
                        >
                            <img src={icone} alt="" style={{width: '50px'}} />
                            <div
                                style={{
                                    fontWeight: 700,
                                    fontSize: 26
                                }}
                            >
                                ALTERAÇÃO DE CADASTRO
                            </div>
                        </div>
                        <div
                            style={{
                                marginTop: '1rem',
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div>
                                ID:
                                <span style={{color: '#d36233'}}>
                                    * {data.id}
                                </span>
                            </div>
                            <div>
                                TEMPO INATIVO:
                                <span style={{color: '#d36233'}}>
                                    * {data.tempo_inativo}
                                </span>
                            </div>
                            <div style={{color: '#d36233'}}>
                                |{'   '}*
                                <span style={{fontSize: 15}}>
                                    CAMPOS OBRIGATÓRIOS
                                </span>
                                {'   '}|
                            </div>
                        </div>
                        <div>
                            <TextField
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.myInput,
                                    startAdornment: (
                                        <InputAdornment>NOME:*</InputAdornment>
                                    )
                                }}
                                defaultValue={formdata?.nome}
                                variant="standard"
                                className={classes.myTextField}
                                onBlur={(event) => handleChange(event, 'nome')}
                                onKeyUp={handleKeyUp}
                            />
                        </div>
                        <div>
                            <TextField
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.myInput,
                                    startAdornment: (
                                        <InputAdornment>
                                            E-MAIL:*
                                        </InputAdornment>
                                    )
                                }}
                                defaultValue={formdata?.email}
                                variant="standard"
                                className={classes.myTextField}
                                onBlur={(event) => handleChange(event, 'email')}
                                onKeyUp={handleKeyUp}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <TextField
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.myInput,
                                    startAdornment: (
                                        <InputAdornment>
                                            DATA NASCIMENTO:
                                        </InputAdornment>
                                    )
                                }}
                                defaultValue={formdata?.birth}
                                variant="standard"
                                className={classes.dtNascimento}
                                onBlur={(event) => handleChange(event, 'birth')}
                                onKeyUp={handleKeyUp}
                            />

                            <TextField
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.myInput,
                                    startAdornment: (
                                        <InputAdornment>CPF:</InputAdornment>
                                    )
                                }}
                                defaultValue={formdata?.cpf}
                                variant="standard"
                                className={classes.cpf}
                                onBlur={(event) => handleChange(event, 'cpf')}
                                onKeyUp={handleKeyUp}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <TextField
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.myInput,
                                    startAdornment: (
                                        <InputAdornment>PAÍS:</InputAdornment>
                                    )
                                }}
                                defaultValue={formdata?.pais}
                                variant="standard"
                                className={classes.pais}
                                onBlur={(event) => handleChange(event, 'pais')}
                                onKeyUp={handleKeyUp}
                            />

                            <TextField
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.myInput,
                                    startAdornment: (
                                        <InputAdornment>
                                            CIDADE/ESTADO:
                                        </InputAdornment>
                                    )
                                }}
                                defaultValue={formdata?.cidade_estado}
                                variant="standard"
                                className={classes.citystate}
                                onBlur={(event) =>
                                    handleChange(event, 'cidade')
                                }
                                onKeyUp={handleKeyUp}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <TextField
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.myInput,
                                    startAdornment: (
                                        <InputAdornment>
                                            CELULAR:
                                        </InputAdornment>
                                    )
                                }}
                                defaultValue={formdata?.celular}
                                variant="standard"
                                className={classes.textfieldMedium}
                                onBlur={(event) =>
                                    handleChange(event, 'celular')
                                }
                                onKeyUp={handleKeyUp}
                            />

                            <TextField
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.myInput,
                                    startAdornment: (
                                        <InputAdornment>GÊNERO:</InputAdornment>
                                    )
                                }}
                                defaultValue={formdata?.genero}
                                variant="standard"
                                className={classes.textfieldSmall}
                                onBlur={(event) =>
                                    handleChange(event, 'genero')
                                }
                                onKeyUp={handleKeyUp}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <TextField
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.myInput,
                                    startAdornment: (
                                        <InputAdornment>
                                            DATA CADASTRO:*
                                        </InputAdornment>
                                    )
                                }}
                                defaultValue={moment(formdata?.cadastro).format(
                                    'DD/MM/YYYY'
                                )}
                                variant="standard"
                                className={classes.textfieldMedium}
                                onBlur={(event) =>
                                    handleChange(event, 'cadastro')
                                }
                                onKeyUp={handleKeyUp}
                            />

                            <TextField
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.myInput,
                                    startAdornment: (
                                        <InputAdornment>PLANO:*</InputAdornment>
                                    )
                                }}
                                defaultValue={formdata?.plano}
                                variant="standard"
                                className={classes.textfieldSmall}
                                onBlur={(event) => handleChange(event, 'plano')}
                                onKeyUp={handleKeyUp}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <TextField
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.myInput,
                                    startAdornment: (
                                        <InputAdornment>
                                            CONHECIMENTO:
                                        </InputAdornment>
                                    )
                                }}
                                defaultValue={formdata?.conhecimento}
                                variant="standard"
                                className={classes.textfieldMedium}
                                onBlur={(event) =>
                                    handleChange(event, 'conhecimento')
                                }
                                onKeyUp={handleKeyUp}
                            />

                            <TextField
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.myInput,
                                    startAdornment: (
                                        <InputAdornment>VENC.:</InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment>
                                            <Checkbox
                                                defaultChecked={check}
                                                style={{
                                                    color: '#e37039'
                                                }}
                                                onChange={handleCheck}
                                            />
                                        </InputAdornment>
                                    )
                                }}
                                defaultValue={formdata?.vencimento}
                                variant="standard"
                                className={classes.textfieldSmall}
                                onBlur={(event) =>
                                    handleChange(event, 'vencimento')
                                }
                                onKeyUp={handleKeyUp}
                            />
                        </div>
                        <div
                            style={{
                                fontSize: 12,
                                display: 'flex',
                                justifyContent: 'flex-end',
                                color: '#d36233',
                                marginRight: '25px'
                            }}
                        >
                            RENOVAÇÃO AUTOMATICA
                        </div>
                        <div>
                            <TextField
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.myInput,
                                    startAdornment: (
                                        <InputAdornment>OBS:</InputAdornment>
                                    )
                                }}
                                variant="standard"
                                className={classes.observation}
                                onBlur={(event) => handleChange(event, 'obs')}
                                onKeyUp={handleKeyUp}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                margin: '2rem 1rem 1rem 1rem'
                            }}
                        >
                            <Button className={classes.buttonModal}>
                                EXPORTAR CSV
                            </Button>

                            <Button
                                className={classes.buttonModal}
                                onClick={handleOpen}
                            >
                                CANCELAR CADASTRO
                                <img
                                    src={cancel}
                                    alt=""
                                    style={{width: '30px'}}
                                />
                            </Button>
                        </div>
                    </div>
                    <div style={{margin: '-5px -5px 0px 0px'}}>
                        <ion-icon
                            style={{
                                fontSize: 32,
                                color: '#d36233',
                                backgroundColor: '#cac6c2',
                                borderRadius: '50%',
                                cursor: 'pointer'
                            }}
                            onClick={() => setOpen(false)}
                            name="close-circle-outline"
                        />
                    </div>
                </Card>
            </div>
        </>
    ) : null;
}
