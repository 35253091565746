/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
/* eslint-disable indent */

import React from 'react';
import {Card} from 'react-bootstrap';
import {Button} from '@material-ui/core';
import thumbs from 'src/img/icone-ok.avif';
import api from '@app/utils/api';

export default function ConfirmModal({
    classes,
    setReativ,
    setCancel,
    cancel,
    reativar,
    data
}) {
    const [confirm, setConfirm] = React.useState(false);
    const handleClose = () => {
        if (reativar) setReativ(false);
        if (cancel) setCancel(false);
    };

    const handleConfirm = () => {
        if (cancel) {
            api.admin
                .cancelAccount(data.id)
                .then((res) => console.log(res))
                .catch((e) => console.log(e));
        } else if (reativar) {
            api.admin
                .reativarAccount(data.id)
                .then((res) => console.log(res))
                .catch((e) => console.log(e));
        }
        setConfirm(true);
    };

    return (
        <>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontFamily: 'Roboto'
                }}
            >
                <Card
                    style={{
                        backgroundColor: '#313031',
                        width: '35%',
                        height: '40%',
                        display: 'flex',
                        flexDirection: 'row',
                        borderRadius: '25px',
                        fontSize: 22
                    }}
                >
                    {!confirm ? (
                        <div style={{margin: '2rem'}}>
                            <div
                                style={{
                                    textAlign: 'center',
                                    fontSize: 30,
                                    color: '#e37039',
                                    fontWeight: 700
                                }}
                            >
                                {cancel
                                    ? 'TEM CERTEZA QUE DESEJA CANCELAR ESTE CADASTRO?'
                                    : reativar
                                    ? 'TEM CERTEZA QUE DESEJA REATIVAR ESTE CADASTRO?'
                                    : null}
                            </div>
                            <div
                                style={{
                                    color: '#cac6c2',
                                    textAlign: 'center',
                                    marginTop: '2rem'
                                }}
                            >
                                {cancel
                                    ? `Ao clicar em "sim" o cadastro não estará mais
                            disponível em "USUÁRIOS CADASTRADOS", para
                            reativar o mesmo vá em "CANCELAMENTOS" no menu
                            principal`
                                    : reativar
                                    ? `Ao clicar em "sim" o cadastro SERÁ REATIVADO e
                            será encontrado novamente em "USUÁRIOS
                            CADASTRADOS", no menu principal.`
                                    : null}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                    margin: '2rem 1rem 1rem 1rem'
                                }}
                            >
                                <Button
                                    className={classes.buttonCancel}
                                    onClick={handleClose}
                                >
                                    NÃO
                                </Button>
                                <Button
                                    className={classes.buttonCancel}
                                    onClick={handleConfirm}
                                >
                                    SIM
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    margin: '5px'
                                }}
                            >
                                <ion-icon
                                    onClick={() => handleClose()}
                                    name="close-circle-outline"
                                    style={{
                                        fontSize: '28px',
                                        color: '#d36233',
                                        cursor: 'pointer'
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <div
                                    style={{
                                        fontSize: 36,
                                        color: '#d36233',
                                        width: '70%'
                                    }}
                                >
                                    {cancel
                                        ? `CADASTRO CANCELADO COM SUCESSO!`
                                        : reativar
                                        ? `CADASTRO REATIVADO COM SUCESSO!`
                                        : null}
                                </div>
                                <div>
                                    <img
                                        style={{width: '30%', margin: '5px'}}
                                        src={thumbs}
                                        alt="OK"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </Card>
            </div>
        </>
    );
}
