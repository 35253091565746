/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react';
import {Grid, createTheme} from '@material-ui/core';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
// import Checkbox from '@material-ui/core/Checkbox';

import {
    makeStyles,
    createStyles,
    ThemeProvider
    // withStyles
} from '@material-ui/core/styles';
import Select from 'react-select';

import lightBlue from '@material-ui/core/colors/lightBlue';
import moment from 'moment';

import DateFnsUtils from '@date-io/date-fns';
import locale from 'date-fns/locale/pt-BR';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';

import {cleanNumber, formatMoney} from '../../../../utils/myaux';
import HeaderTable from './HeaderTable';
// import api from '@app/utils/api';

const useStyles = makeStyles((theme) =>
    createStyles({
        myButton: {
            color: '#2a3176',
            backgroundColor: '#f4f4f4',
            fontWeight: 'bold'
        },
        myBox: {
            marginLeft: theme.spacing(1.2),
            marginBottom: -18
        },
        myGridVC: {
            minWidth: 15,
            maxHeight: 15,
            marginTop: 5,
            // margin: theme.spacing(0),
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            textAlign: 'center',
            border: '2px solid #c5c5c5'
        },
        myGridVCSelected: {
            minWidth: 15,
            maxHeight: 15,
            marginTop: 5,
            // margin: theme.spacing(0),
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            textAlign: 'center',
            backgroundColor: '#e37039',
            color: '#fff'
        },
        myGrid: {
            minWidth: 40,
            maxWidth: 40,
            maxHeight: 20,
            // backgroundColor: '#f4f4f4',
            // margin: theme.spacing(0),
            // marginTop: -2,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            textAlign: 'center',
            fontSize: 14,
            border: '2px solid #c5c5c5'
        },
        myGridSelected: {
            minWidth: 40,
            maxWidth: 40,
            maxHeight: 20,
            backgroundColor: '#e37039',
            // margin: theme.spacing(0),
            // marginTop: -2,
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 14,
            display: 'flex',
            textAlign: 'center',
            border: '2px solid #e37039',
            color: '#fff'
        },
        myLabel: {
            marginLeft: -4,
            color: '#e37039',
            fontSize: 14,
            padding: 0,
            margin: 0
            // fontWeight: 'bold'
        },
        myInput: {
            marginLeft: -15,
            color: '#e37039',
            fontSize: 14,
            padding: 0,
            margin: 0,
            border: 'none',
            maxWidth: 50,
            '&:focus': {
                outline: 'none'
            }

            // fontWeight: 'bold'
        },
        myLabel1: {
            marginLeft: -4,
            color: '#000',
            fontSize: 14,
            padding: 0,
            margin: 0
            // fontWeight: 'bold'
        },
        myLabelSelected: {
            padding: theme.spacing(6),
            color: '#fff',
            fontWeight: 'bold'
        },
        mySlider: {
            color: '#e37039',
            valueLabel: {
                zIndex: 10,
                left: 'calc(-50% + 12px)',
                top: -22,
                '& *': {
                    background: 'transparent',
                    color: '#000'
                }
            }
        },
        myTable: {}
    })
);

const materialTheme = createTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: '#e37039'
            },
            color: '#000'
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                // backgroundColor: lightBlue.A200,
                color: '#000'
            }
        },
        MuiPickersDay: {
            day: {
                color: '#000'
            },
            daySelected: {
                backgroundColor: '#e37039'
            },
            dayDisabled: {
                color: lightBlue['100']
            },
            current: {
                color: lightBlue['900']
            }
        },
        MuiPickersModal: {
            // dialogAction: {
            //     color: lightBlue['400']
            // },
            MuiDialogActions: {
                root: {
                    display: 'none'
                }
            }
        },
        MuiDialogActions: {
            root: {
                display: 'none'
            }
        }
    }
});

const styles = {
    control: (css) => ({
        ...css,
        padding: 0,
        margin: -6,
        border: 'none',
        boxShadow: 'none'
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#e37039',
        fontSize: 12.5,
        margin: 0,
        padding: 0
    }),
    menu: ({width, ...css}) => ({
        ...css,
        width: 'max-content',
        minWidth: '10%'
    }),
    container: (base) => ({
        ...base,
        minWidth: 65
        // flex: 1,
        // margin: 0,
        // padding: 0
    }),
    // Add padding to account for width of Indicators Container plus padding
    option: (css, state) => ({
        ...css,
        color: '#e37039',
        width: 70,
        backgroundColor: state.isSelected
            ? ''
            : '' || state.isHovered
            ? '#cac6c2'
            : 'transparent',
        '&:hover': {
            backgroundColor: '#cac6c2'
        }
    })
};

// const OrangeCheckbox = withStyles({
//     root: {
//         color: '#e37039',
//         '&$checked': {
//             color: '#e37039'
//         }
//     },
//     checked: {}
// })((props) => <Checkbox color="default" {...props} />);

export default function TableStrategy({ativ, strategy, setStrategy, status}) {
    const [cotTot, setCotTot] = useState(0);
    const [pTeoTot, setPTeoTot] = useState(0);
    const [total, setTotal] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [myIdx, setMyIdx] = useState();

    const classes = useStyles();
    // const [selectedDate, handleChangeDate] = useState(new Date());

    useEffect(() => {
        let tot = 0.0;
        let pteoricoDesmont = 0;
        let cot = 0;
        strategy.forEach((s) => {
            // if (typeof s.totalDesmont === 'number') {
            //     tot += cleanNumber(s.totalDesmont);
            //     // cot += Number(s.cotacaoDesmont.value);
            //     if (s.type === 'V') {
            //         cot += cleanNumber(s.cotacaoDesmont.value);
            //         pteoricoDesmont += cleanNumber(s.pteoricoDesmont);
            //     } else {
            //         cot -= cleanNumber(s.cotacaoDesmont.value);
            //         pteoricoDesmont -= cleanNumber(s.pteoricoDesmont);
            //     }
            // } else {
            const tmp = s.totalDesmont ? cleanNumber(s.totalDesmont) : 0.0;
            tot += parseFloat(tmp);
            if (s.type === 'V') {
                cot += cleanNumber(s.cotacaoDesmont.value);
                pteoricoDesmont += cleanNumber(s.pteoricoDesmont);
            } else {
                cot -= cleanNumber(s.cotacaoDesmont.value);
                pteoricoDesmont -= cleanNumber(s.pteoricoDesmont);
            }
            // cot += Number(s.cotacaoDesmont.value);
            // }
        });
        setCotTot(cot);
        setPTeoTot(pteoricoDesmont);
        setTotal(tot);
    }, [strategy]);

    const handleStrike = (e, i) => {
        const orig = _.cloneDeep(strategy);
        orig[i].strike = e;
        setStrategy(orig);
    };

    const handleVenc = (e, i) => {
        const orig = _.cloneDeep(strategy);
        orig[i].venc = e;
        setStrategy(orig);
    };

    // const handleCot = (e, i) => {
    //     // const orig = _.cloneDeep(strategy);
    //     // orig[i].cotacaoDesmont.value = formatMoney(
    //     //     cleanNumber(e.replace(',', '.'))
    //     // );
    //     // orig[i].cotacaoDesmont.changed = true;
    //     // orig[i].totalDesmont = formatMoney(cleanNumber(e) * orig[i].qtd);
    //     // setStrategy(orig);

    //     const calc = e;
    //     const orig = _.cloneDeep(strategy);
    //     console.log(formatMoney(cleanNumber(calc)));
    //     orig[i].cotacaoDesmont.value = formatMoney(cleanNumber(calc));
    //     orig[i].totalDesmont =
    //         orig[i].type === 'C' || ''
    //             ? formatMoney(cleanNumber(calc) * orig[i].qtd * -1)
    //             : formatMoney(cleanNumber(calc) * orig[i].qtd);

    //     setStrategy(orig);
    // };
    const handleCot = (e, i) => {
        const calc = e.replace(',', '.');
        const orig = _.cloneDeep(strategy);
        orig[i].cotacaoDesmont.value = calc;
        // orig[i].totalDesmont =
        //     orig[i].type === 'C' || ''
        //         ? formatMoney(calc * orig[i].qtd * -1)
        //         : formatMoney(calc * orig[i].qtd);
        orig[i].cotacaoDesmont.changed = true;
        setStrategy(orig);
    };

    const formatCot = (e, i) => {
        const calc = cleanNumber(e);
        const orig = _.cloneDeep(strategy);
        orig[i].cotacaoDesmont.value = formatMoney(calc);
        orig[i].totalDesmont =
            orig[i].type === 'C' || ''
                ? formatMoney(calc * orig[i].qtd * -1)
                : formatMoney(calc * orig[i].qtd);

        setStrategy(orig);
    };

    const handleChangeDate = (e) => {
        const orig = _.cloneDeep(strategy);
        orig[myIdx].dataDesmont = e;
        setStrategy(orig);
    };

    const openCalendar = (i) => {
        setIsOpen(true);
        setMyIdx(i);
    };

    const changeUpdate = (i) => {
        const orig = _.cloneDeep(strategy);
        orig[i].mustUpdated = !orig[i].mustUpdated;
        setStrategy(orig);
    };

    return (
        <table
            className="table"
            style={{
                fontSize: 11,
                width: '100%'
            }}
        >
            <HeaderTable />
            <tbody>
                {strategy.map((s, i) => (
                    <tr
                        style={{
                            whiteSpace: 'nowrap',
                            marginTop: '-5px',
                            marginBottom: '-10px',
                            height: '5px',
                            pointerEvents: status ? 'auto' : 'none'
                        }}
                    >
                        <td style={{display: 'flex', marginTop: '-1px'}}>
                            <ion-icon
                                style={{
                                    fontSize: 15,
                                    color: '#e37039',
                                    cursor: 'pointer',
                                    marginTop: 4
                                }}
                                name={
                                    s.mustUpdated
                                        ? 'lock-open-outline'
                                        : 'lock-closed-outline'
                                }
                                onClick={() => changeUpdate(i)}
                            />
                            <Grid
                                className={
                                    s.type === 'V'
                                        ? classes.myGridVCSelected
                                        : classes.myGridVC
                                }
                                style={{marginRight: 3, marginLeft: 3}}
                            >
                                C
                            </Grid>
                            <Grid
                                className={
                                    s.type === 'C'
                                        ? classes.myGridVCSelected
                                        : classes.myGridVC
                                }
                                style={{
                                    marginLeft: '3px'
                                }}
                            >
                                V
                            </Grid>
                        </td>
                        <td
                            style={{
                                pointerEvents: s.mustUpdated ? 'auto' : 'none'
                            }}
                        >
                            <ion-icon
                                data-tip={moment(s.dataDesmont).format(
                                    'DD/MM/YYYY'
                                )}
                                name="calendar-number-outline"
                                style={{
                                    color: '#e37039',
                                    cursor: 'pointer',
                                    fontSize: 15
                                }}
                                onClick={() => openCalendar(i)}
                            />
                            <MuiPickersUtilsProvider
                                locale={locale}
                                utils={DateFnsUtils}
                            >
                                <ReactTooltip />
                                <ThemeProvider theme={materialTheme}>
                                    <DatePicker
                                        open={isOpen}
                                        onOpen={() => setIsOpen(true)}
                                        onClose={() => setIsOpen(false)}
                                        value={s.dataDesmont}
                                        onChange={(e) => handleChangeDate(e)}
                                        TextFieldComponent={() => null}
                                        autoOk
                                    />
                                </ThemeProvider>
                            </MuiPickersUtilsProvider>
                        </td>
                        <td align="left" style={{fontFamily: 'Roboto'}}>
                            <input className={classes.myInput} value={s.qtd} />
                        </td>
                        <td
                            style={{
                                display: 'flex',
                                // justifyContent: 'center',
                                marginTop: '-1px',
                                paddingTop: '16px',
                                // marginBottom: '2px',
                                borderTop: i === 0 ? '1px solid #fff' : ''
                            }}
                        >
                            {s.ticker?.value !== ativ ? (
                                <>
                                    <Grid
                                        className={
                                            s.activeType === 'C' &&
                                            s.activeType !== ''
                                                ? classes.myGridSelected
                                                : classes.myGrid
                                        }
                                        style={{
                                            marginRight: 3,
                                            marginLeft: 3
                                            // marginTop: '-2px'
                                        }}
                                    >
                                        CALL
                                    </Grid>
                                    <Grid
                                        className={
                                            s.activeType === 'P' &&
                                            s.activeType !== ''
                                                ? classes.myGridSelected
                                                : classes.myGrid
                                        }
                                        style={{
                                            marginLeft: '3px'
                                        }}
                                    >
                                        PUT
                                    </Grid>
                                </>
                            ) : (
                                <Grid style={{height: 15}} />
                            )}
                        </td>
                        <td
                            align="left"
                            style={{whiteSpace: 'nowrap', fontFamily: 'Roboto'}}
                        >
                            {s.ticker?.value !== ativ ? (
                                <Select
                                    onChange={(e) => handleVenc(e, i)}
                                    isSearchable
                                    value={s.venc}
                                    placeholder="Selecione"
                                    styles={styles}
                                    options={s.vencOptFiltered}
                                    components={{
                                        DropdownIndicator: () => null,
                                        IndicatorSeparator: () => null
                                    }}
                                />
                            ) : (
                                <Grid style={{height: 15}} />
                            )}
                        </td>

                        <td
                            align="left"
                            style={{whiteSpace: 'nowrap', fontFamily: 'Roboto'}}
                        >
                            {s.ticker?.value !== ativ ? (
                                <Select
                                    onChange={(e) => handleStrike(e, i)}
                                    isSearchable
                                    value={s.strike}
                                    placeholder="Selecione"
                                    styles={styles}
                                    options={s.strikeOptFiltered}
                                    components={{
                                        DropdownIndicator: () => null,
                                        IndicatorSeparator: () => null
                                    }}
                                />
                            ) : (
                                <Grid style={{height: 15}} />
                            )}
                        </td>
                        <td
                            align="left"
                            style={{whiteSpace: 'nowrap', fontFamily: 'Roboto'}}
                        >
                            {/* <p className={classes.myLabel}>ABEVD15</p> */}
                            <Select
                                // onChange={(e) => searchFromTicker(e, dadosRow.id)}
                                // onChange={(e) => handleTicker(e, i)}
                                isDisabled
                                isSearchable
                                value={s.ticker}
                                placeholder="Selecione"
                                styles={styles}
                                options={[]}
                                components={{
                                    DropdownIndicator: () => null,
                                    IndicatorSeparator: () => null
                                }}
                            />
                        </td>
                        <td
                            align="center"
                            style={{
                                whiteSpace: 'nowrap',
                                pointerEvents: s.mustUpdated ? 'auto' : 'none',
                                fontFamily: 'Roboto'
                            }}
                        >
                            {/* <p className={classes.myLabel}>0.38</p> */}
                            <input
                                // type="number"
                                className={classes.myInput}
                                value={s.cotacaoDesmont.value}
                                onChange={(e) => handleCot(e.target.value, i)}
                                onBlur={(e) => formatCot(e.target.value, i)}
                            />
                        </td>
                        <td
                            align="center"
                            style={{whiteSpace: 'nowrap', fontFamily: 'Roboto'}}
                        >
                            {s.ticker?.value !== ativ ? (
                                <p className={classes.myLabel1}>
                                    {s.pteoricoDesmont}
                                </p>
                            ) : null}
                        </td>
                        <td
                            align="end"
                            style={{whiteSpace: 'nowrap', fontFamily: 'Roboto'}}
                        >
                            <p className={classes.myLabel1}>{s.totalDesmont}</p>
                        </td>
                    </tr>
                ))}
                <tr>
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td />
                    <td>
                        <div
                            style={{
                                textAlign: 'center',
                                fontSize: 16,
                                fontWeight: 'bold'
                            }}
                        >
                            TOTAL
                        </div>
                    </td>
                    <td
                        align="center"
                        className={classes.myLabel}
                        style={{fontFamily: 'Roboto'}}
                    >
                        {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                        })
                            .format(cotTot)
                            .replace(',', '.')
                            .replace('-', '')}
                    </td>
                    <td
                        align="center"
                        className={classes.myLabel1}
                        style={{fontFamily: 'Roboto'}}
                    >
                        {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                        })
                            .format(pTeoTot)
                            .replace(',', '.')}
                    </td>
                    <td
                        align="end"
                        className={classes.myLabel1}
                        style={{fontFamily: 'Roboto'}}
                    >
                        {new Intl.NumberFormat('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                        })
                            .format(total)
                            .replace(',', '.')}
                    </td>
                </tr>
            </tbody>
        </table>
    );
}
