/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import React, {useEffect, useState} from 'react';
import {Box, Grid, Slider as SliderM} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import moment from 'moment';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';

import 'moment/locale/pt-br';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const useStyles = makeStyles((theme) =>
    createStyles({
        myButton: {
            color: '#2a3176',
            backgroundColor: '#f4f4f4',
            fontWeight: 'bold'
        },
        myBox: {
            marginLeft: theme.spacing(1.2),
            marginBottom: 6
        },
        myGrid: {
            minWidth: 90,
            maxHeight: 30,
            maxWidth: 90,
            margin: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            textAlign: 'center',
            cursor: 'pointer',
            fontSize: 14,
            border: '2px solid #c5c5c5'
        },
        myGridSelected: {
            minWidth: 90,
            maxWidth: 90,
            maxHeight: 30,
            backgroundColor: '#e37039',
            margin: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 14,
            display: 'flex',
            textAlign: 'center',
            cursor: 'pointer',
            border: '2px solid #e37039',
            color: '#fff'
        },
        myLabel: {
            color: '#d36233',
            fontSize: 14
        },
        myLabelSelected: {
            padding: theme.spacing(6),
            color: '#fff',
            fontWeight: 'bold'
        },
        mySlider: {
            borderRadius: '5px',
            '& .MuiSlider-rail': {
                color: '#c7c7c7',
                height: 15,
                borderRadius: '5px',
                left: 0
            },
            '& .MuiSlider-track': {
                height: 15,
                color: '#e37039'
                // borderRadius: '5px'
                // paddingLeft: '10px'
            },
            '& .MuiSlider-thumb': {
                height: 25,
                width: 3,
                color: '#e37039',
                marginLeft: '0px',
                borderRadius: '1px',
                boxShadow: 'none'
            },
            '& .MuiSlider-markLabel': {
                top: '-10px',
                color: '#313031',
                fontSize: 10,
                backgroundColor: '#c7c7c7',
                borderRadius: '5px',
                width: '60px',
                height: '20px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            },
            '& .MuiSlider-mark': {
                width: '0px',
                height: '0px',
                '&.MuiSlider-markActive': {
                    opacity: 1,
                    backgroundColor: '#e37039'
                }
            },
            '& .MuiSlider-valueLabel': {
                fontSize: 12,
                fontWeight: 'normal',
                top: -6,
                backgroundColor: '#e37039',
                color: '#fff',
                '&:before': {
                    display: 'none'
                },
                '& *': {
                    background: 'transparent',
                    color: '#313031'
                }
            }
        },
        tooltip: {
            backgroundColor: '#e37039',
            color: '#fff'
        }
    })
);

function ValueLabelComponent(props, classes) {
    const {children, open, value} = props;
    if (children.props['data-index'] === 1) {
        return (
            <Tooltip
                open={open}
                enterTouchDelay={0}
                title={`R$${value}`}
                classes={{tooltip: classes.tooltip}}
                placement="top"
            >
                {children}
            </Tooltip>
        );
    }
    return (
        <Tooltip
            open={open}
            enterTouchDelay={0}
            title={`R$${value}`}
            classes={{tooltip: classes.tooltip}}
            placement="bottom"
        >
            {children}
        </Tooltip>
    );
}

ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired
};

export default function Filters({data, setData, active, sliderLabel}) {
    const classes = useStyles();
    const ref = React.createRef();
    const [size, setSize] = useState(window.innerWidth);
    const [range, setRange] = useState([
        {value: 0, label: 'R$0'},
        {value: 100, label: 'R$100'}
    ]);
    moment().locale('pt-br');
    const [activeWeek, setActiveWeek] = useState();
    const atual = moment().month();
    const [months, setMonths] = useState([
        {
            label: 'JANEIRO',
            id: 1,
            status: !!(atual === 0 || atual - 1 === 0)
        },
        {
            label: 'FEVEREIRO',
            id: 2,
            status: !!(atual === 1 || atual - 1 === 1)
        },
        {
            label: 'MARÇO',
            id: 3,
            status: !!(atual === 2 || atual - 1 === 2)
        },
        {
            label: 'ABRIL',
            id: 4,
            status: !!(atual === 3 || atual - 1 === 3)
        },
        {
            label: 'MAIO',
            id: 5,
            status: !!(atual === 4 || atual - 1 === 4)
        },
        {
            label: 'JUNHO',
            id: 6,
            status: !!(atual === 5 || atual - 1 === 5)
        },
        {
            label: 'JULHO',
            id: 7,
            status: !!(atual === 6 || atual - 1 === 6)
        },
        {
            label: 'AGOSTO',
            id: 8,
            status: !!(atual === 7 || atual - 1 === 7)
        },
        {
            label: 'SETEMBRO',
            id: 9,
            status: !!(atual === 8 || atual - 1 === 8)
        },
        {
            label: 'OUTUBRO',
            id: 10,
            status: !!(atual === 9 || atual - 1 === 9)
        },
        {
            label: 'NOVEMBRO',
            id: 11,
            status: !!(atual === 10 || atual - 1 === 10)
        },
        {
            label: 'DEZEMBRO',
            id: 12,
            status: !!(atual === 11 || atual - 1 === 11)
        }
    ]);

    const [year, setYear] = useState([
        {
            label: moment().year(),
            status: true
        },
        {
            label: moment().add(1, 'years').year(),
            status: false
        },
        {
            label: moment().add(2, 'years').year(),
            status: false
        }
    ]);

    const [week, setWeek] = useState([
        {
            label: 'W1',
            status: true
        },
        {
            label: 'W2',
            status: false
        },
        {
            label: 'W3',
            status: false
        },
        {
            label: 'W4',
            status: false
        },
        {
            label: 'W5',
            status: false
        },
        {
            label: 'W0',
            status: false
        }
    ]);

    const handleChangeSlider = (event, newValue) => {
        const orig = _.cloneDeep(data);
        const strike = orig[active]?.filters?.strike;
        strike.start = newValue[0];
        strike.end = newValue[1];
        setData(orig);
    };

    const alterType = (e) => {
        const orig = _.cloneDeep(data);
        const find = orig[active]?.filters?.btnType;
        find[e] = !orig[active]?.filters?.btnType[e];
        setData(orig);
    };

    const alterModel = (e) => {
        const orig = _.cloneDeep(data);
        const find = orig[active]?.filters?.btnModel;
        find[e] = !orig[active]?.filters?.btnModel[e];
        setData(orig);
    };

    const alterMoneyness = (e) => {
        const orig = _.cloneDeep(data);
        const find = orig[active]?.filters?.btnMoneyness;
        find[e] = !orig[active]?.filters?.btnMoneyness[e];
        setData(orig);
    };

    const changeType = (e) => {
        if (
            data[active]?.filters?.btnType.call &&
            data[active]?.filters?.btnType.put
        ) {
            alterType(e);
        } else if (
            e === 'call' &&
            (!data[active]?.filters?.btnType.call ||
                data[active]?.filters?.btnType.put)
        ) {
            alterType(e);
        } else if (
            e === 'put' &&
            (!data[active]?.filters?.btnType.put ||
                data[active]?.filters?.btnType.call)
        ) {
            alterType(e);
        }
    };

    const changeModel = (e) => {
        if (
            data[active]?.filters?.btnModel.europe &&
            data[active]?.filters?.btnModel.america
        ) {
            alterModel(e);
        } else if (
            e === 'america' &&
            (!data[active]?.filters?.btnModel.america ||
                data[active]?.filters?.btnModel.europe)
        ) {
            alterModel(e);
        } else if (
            e === 'europe' &&
            (!data[active]?.filters?.btnModel.europe ||
                data[active]?.filters?.btnModel.america)
        ) {
            alterModel(e);
        }
    };

    const changeMoney = (e) => {
        if (
            data[active]?.filters?.btnMoneyness.ITM &&
            data[active]?.filters?.btnMoneyness.ATM &&
            data[active]?.filters?.btnMoneyness.OTM
        ) {
            alterMoneyness(e);
        } else if (
            e === 'ITM' &&
            (!data[active]?.filters?.btnMoneyness.ITM ||
                data[active]?.filters?.btnMoneyness.ATM ||
                data[active]?.filters?.btnMoneyness.OTM)
        ) {
            alterMoneyness(e);
        } else if (
            e === 'ATM' &&
            (!data[active]?.filters?.btnMoneyness.ATM ||
                data[active]?.filters?.btnMoneyness.ITM ||
                data[active]?.filters?.btnMoneyness.OTM)
        ) {
            alterMoneyness(e);
        } else if (
            e === 'OTM' &&
            (!data[active]?.filters?.btnMoneyness.OTM ||
                data[active]?.filters?.btnMoneyness.ITM ||
                data[active]?.filters?.btnMoneyness.ATM)
        ) {
            alterMoneyness(e);
        }
    };

    window.addEventListener('resize', () => {
        setSize(window.innerWidth);
    });

    const changeMonth = (e) => {
        const orig = _.cloneDeep(data);
        const findBase = orig[active].filters.venc;
        const filterYear = year.find((y) => y.status); // ANO
        const filterMonth = months.filter((m) => m.status); // MES
        const filterWeek = [];
        const newweek = [];
        const listMonth = findBase.filter(
            (d) => moment(d.label).year() === filterYear.label
        );
        const findData = listMonth?.filter(
            (y) => Number(moment(y.label).format('M')) === e.id
        );
        if (!e.status) {
            const list = [];
            listMonth.forEach((n) => {
                list.push({
                    label: moment(n.label)
                        .locale('pt-br')
                        .format('MMMM')
                        .toUpperCase(),
                    id: Number(moment(n.label).format('M')),
                    status: n.label === findData[0].label ? !n.status : n.status
                });
            });
            const unique = list.reduce((uniqueArray, obj) => {
                const dupe = uniqueArray.find(
                    (item) => item.label === obj.label
                );
                if (dupe) {
                    if (obj.status) {
                        dupe.status = true;
                    }
                } else {
                    uniqueArray.push(obj);
                }
                return uniqueArray;
            }, []);
            const activemonths = unique.filter((u) => u.status);
            setMonths(unique);

            orig[active].values.forEach((values) => {
                values.forEach((ativo) => {
                    for (let i = 1; i < 6; i++) {
                        if (ativo.ticker.slice(-2) === `W${i}`) {
                            filterWeek.push(ativo);
                        }
                    }
                });
            });

            const venc = [];
            filterMonth.forEach((m) => {
                if (m.id !== e.id) {
                    venc.push(
                        filterWeek.filter(
                            (w) =>
                                Number(moment(w.vencimento).format('M')) ===
                                m.id
                        )
                    );
                }
            });
            if (!e.status) {
                venc.push(
                    filterWeek.filter(
                        (w) => Number(moment(w.vencimento).format('M')) === e.id
                    )
                );
            }

            const tmpList = [];
            const checkNewMonth = [];
            const template = ['W1', 'W2', 'W3', 'W4', 'W5'];
            venc.forEach((v, index) => {
                for (let i = 1; i < 6; i++) {
                    const tmp = v.find((f) => f.ticker.slice(-2) === `W${i}`);
                    if (tmp) {
                        tmpList.push(tmp.ticker.slice(-2));
                        if (index === venc.length - 1) {
                            checkNewMonth.push(tmp.ticker.slice(-2));
                        }
                    }
                }
            });
            if (checkNewMonth.length === 0) checkNewMonth.push('W3');
            tmpList.push('W3');
            tmpList.sort();
            const uniq = [...new Set(tmpList)];

            template.forEach((t) => {
                uniq.forEach((u, i) => {
                    if (t === u) {
                        newweek.push({
                            label: u,
                            status: true
                        });
                    }
                });
            });
            newweek.forEach((n) => {
                week.forEach((a) => {
                    if (n.label === a.label) n.status = a.status;
                });
            });

            findData.forEach((ativo, i) => {
                if (!ativo.status) {
                    ativo.status = !ativo.status;
                }
                if (newweek.length === findData.length) {
                    ativo.status = newweek[i].status;
                }
            });

            setWeek(newweek);
            setData(orig);
        } else {
            const list = [];
            for (let i = 0; i < listMonth.length; i++) {
                if (Number(moment(listMonth[i].label).format('M')) === e.id) {
                    listMonth[i].status = false;
                }
            }
            listMonth.forEach((n) => {
                list.push({
                    label: moment(n.label)
                        .locale('pt-br')
                        .format('MMMM')
                        .toUpperCase(),
                    id: Number(moment(n.label).format('M')),
                    status: n.status
                });
            });
            const unique = list.reduce((uniqueArray, obj) => {
                const dupe = uniqueArray.find(
                    (item) => item.label === obj.label
                );
                if (dupe) {
                    if (obj.status) {
                        dupe.status = true;
                    }
                } else {
                    uniqueArray.push(obj);
                }
                return uniqueArray;
            }, []);
            const activemonths = unique.filter((u) => u.status);
            if (activemonths.length >= 1) {
                setMonths(unique);
                orig[active].values.forEach((values) => {
                    values.forEach((ativo) => {
                        for (let i = 1; i < 6; i++) {
                            if (ativo.ticker.slice(-2) === `W${i}`) {
                                filterWeek.push(ativo);
                            }
                        }
                    });
                });

                const venc = [];
                filterMonth.forEach((m) => {
                    if (m.id !== e.id)
                        venc.push(
                            filterWeek.filter(
                                (w) =>
                                    Number(moment(w.vencimento).format('M')) ===
                                    m.id
                            )
                        );
                });
                if (!e.status) {
                    venc.push(
                        filterWeek.filter(
                            (w) =>
                                Number(moment(w.vencimento).format('M')) ===
                                e.id
                        )
                    );
                }

                const tmpList = [];
                const template = ['W1', 'W2', 'W3', 'W4', 'W5'];
                venc.forEach((v) => {
                    for (let i = 1; i < 6; i++) {
                        const tmp = v.find(
                            (f) => f.ticker.slice(-2) === `W${i}`
                        );
                        if (tmp) tmpList.push(tmp.ticker.slice(-2));
                    }
                });
                tmpList.push('W3');
                const uniq = [...new Set(tmpList)];

                template.forEach((t) => {
                    uniq.forEach((u, i) => {
                        if (t === u) {
                            if (i === 0) {
                                newweek.push({
                                    label: u,
                                    status: true
                                });
                            } else {
                                newweek.push({
                                    label: u,
                                    status: false
                                });
                            }
                        }
                    });
                });
                week.forEach((w) => {
                    newweek.forEach((n) => {
                        if (w.label === n.label) {
                            n.status = w.status;
                        }
                    });
                });
                setWeek(newweek);
                if (!findData.status) {
                    setData(orig);
                } else {
                    findData.status = !findData.status;
                    const has = orig[active].filters.venc.find(
                        (dt1) =>
                            moment(dt1.label).month() !== e.id &&
                            dt1.status === true
                    );
                    if (has) {
                        findData.status = !findData.status;
                        setData(orig);
                    }
                }
            }
        }
    };

    const changeYear = (e) => {
        const list = [];
        const orig = _.cloneDeep(data);
        const findBase = orig[active].filters.venc; // VENCIMENTOS
        const filterYear = year.find((y) => y.label === e.label); // ANO
        const oldYear = year.find((y) => y.status); // ANO CASO TROQUE
        const tmpYear = findBase?.filter(
            (d) => moment(d.label).year() === filterYear.label
        );
        // VENCIMENTOS DO ANO

        tmpYear?.forEach((y, i) => {
            list.push({
                label: moment(y.label)
                    .locale('pt-br')
                    .format('MMMM')
                    .toUpperCase(),
                id: Number(moment(y.label).format('M')),
                status: i === 0 ? true : y.status
            });
        });
        // TODOS OS VENCIMENTOS ANUAIS EM UMA LISTA

        const unique = list.reduce((uniqueArray, obj) => {
            const dupe = uniqueArray.find((item) => item.label === obj.label);
            if (dupe) {
                if (obj.status) {
                    dupe.status = true;
                }
            } else {
                uniqueArray.push(obj);
            }
            return uniqueArray;
        }, []);
        setMonths(unique);

        filterYear.status = !filterYear.status;
        oldYear.status = !oldYear.status;
        const newMonth = list.find((l) => l.status);

        const listOld = findBase.filter(
            (d) => moment(d.label).year() === oldYear.label
        );
        listOld.forEach((l) => {
            if (l.status) {
                l.status = !l.status;
            }
        });

        const listMonth = findBase.filter(
            (d) => moment(d.label).year() === filterYear.label
        );
        const findData = listMonth?.find(
            (y) => Number(moment(y.label).format('M')) === newMonth.id
        );
        if (!findData.status) {
            findData.status = !findData.status;
            setData(orig);
        } else {
            const has = orig[active].filters.venc.find(
                (dt1) =>
                    moment(dt1.label).month() !== e.id && dt1.status === true
            );
            if (has) {
                findData.status = !findData.status;
                setData(orig);
            }
        }
    };

    const changeWeek = (e) => {
        const orig = _.cloneDeep(data);
        const filterYear = year.find((y) => y.status); // ANO FILTRADO
        const filterMonth = months.filter((y) => y.status); // MES FILTRADO
        const findweek = week.filter((w) => w.status);
        const template = [];
        week.forEach((w) => {
            template.push(w.label);
        });
        const tmpmonth = [];
        orig[active].filters.venc.forEach((m) => {
            filterMonth.forEach((f) => {
                if (
                    Number(moment(m.label).format('M')) === f.id &&
                    moment(m.label).year() === filterYear.label
                ) {
                    tmpmonth.push(m.label);
                }
            });
        });
        const base = orig[active].values;
        const tmp = [];
        base.forEach((ativo) => {
            filterMonth.forEach((f) => {
                const venc = ativo.filter(
                    (a) => Number(moment(a.vencimento).format('M')) === f.id
                );
                if (venc.length > 0) tmp.push(venc);
            });
        });

        const vencWeeks = [];
        /* if (!e.status) {
            week.forEach((w) => {
                tmpmonth.forEach((t) => {
                    tmp.forEach((ativo) => {
                        const defaultweek = ativo.filter(
                            (f) =>
                                f.ticker.slice(-2) === w.label &&
                                w.status &&
                                f.ticker.slice(-2) !== e.label &&
                                f.vencimento === t
                        );
                        if (defaultweek.length > 0) {
                            console.log(defaultweek);
                            vencWeeks.push(defaultweek);
                        }
                        const week3 = ativo.filter(
                            (f) =>
                                !template.includes(f.ticker.slice(-2)) &&
                                f.vencimento === t
                        );
                        if (week3.length > 0) {
                            console.log(week3);
                            vencWeeks.push(week3);
                        }
                        // CASO SEJA O ULTIMO
                    });
                });
            });
        } else {
            if (findweek.length === 1) {
                const lastweek = ativo.filter(
                    (f) => f.ticker.slice(-2) === e.label && f.vencimento === t
                );
                if (lastweek.length > 0) vencWeeks.push(lastweek);
            }
            week.forEach((w) => {
                tmpmonth.forEach((t) => {
                    tmp.forEach((ativo) => {
                        const defaultweek = ativo.filter(
                            (f) =>
                                f.ticker.slice(-2) === w.label &&
                                w.status &&
                                f.ticker.slice(-2) !== e.label &&
                                f.vencimento === t
                        );
                        if (defaultweek.length > 0) {
                            console.log(defaultweek);
                            vencWeeks.push(defaultweek);
                        }
                    });
                });
            });
            tmpmonth.forEach((f) => {
                tmp.forEach((ativo) => {
                    const noweek = ativo.filter(
                        (t) =>
                            !template.includes(t.ticker.slice(-2)) &&
                            t.vencimento === f
                    );
                    if (noweek) vencWeeks.push(noweek);
                });
            });
        }
        console.log(vencWeeks); */

        if (e.label !== 'W3') {
            week.forEach((w) => {
                if (w.label === 'W3' && w.status) {
                    if (!e.status) {
                        tmpmonth.forEach((f) => {
                            tmp.forEach((ativo) => {
                                const noweek = ativo.filter(
                                    (t) =>
                                        !template.includes(
                                            t.ticker.slice(-2)
                                        ) && t.vencimento === f
                                );
                                if (noweek.length > 0) vencWeeks.push(noweek);
                            });
                        });
                    } else {
                        tmpmonth.forEach((f) => {
                            tmp.forEach((ativo) => {
                                const noweek = ativo.filter(
                                    (t) =>
                                        !template.includes(
                                            t.ticker.slice(-2)
                                        ) &&
                                        e.status &&
                                        t.vencimento === f
                                );
                                if (noweek.length > 0) vencWeeks.push(noweek);
                                if (findweek.length === 1) {
                                    const last = ativo.filter(
                                        (t) =>
                                            t.ticker.slice(-2) === e.label &&
                                            t.vencimento === f
                                    );
                                    if (last.length > 0) vencWeeks.push(last);
                                }
                            });
                        });
                    }
                }
                tmpmonth.forEach((f) => {
                    tmp.forEach((ativo) => {
                        const found = ativo.filter(
                            (t) =>
                                t.ticker.slice(-2) === w.label &&
                                w.status &&
                                t.ticker.slice(-2) !== e.label &&
                                t.vencimento === f
                        );
                        if (found.length > 0) vencWeeks.push(found);
                        if (e.status && findweek.length === 1) {
                            const last = ativo.filter(
                                (t) =>
                                    t.ticker.slice(-2) === e.label &&
                                    e.status &&
                                    t.vencimento === f
                            );
                            if (last.length > 0) vencWeeks.push(last);
                        }
                    });
                });
            });
            tmpmonth.forEach((f) => {
                tmp.forEach((ativo) => {
                    const found = ativo.filter(
                        (t) =>
                            t.ticker.slice(-2) === e.label &&
                            !e.status &&
                            t.vencimento === f
                    );
                    if (found.length > 0) vencWeeks.push(found);
                });
            });
        } else {
            week.forEach((w) => {
                tmpmonth.forEach((f) => {
                    tmp.forEach((ativo) => {
                        const found = ativo.filter(
                            (t) =>
                                t.ticker.slice(-2) === w.label &&
                                w.status &&
                                t.ticker.slice(-2) !== e.label &&
                                t.vencimento === f
                        );
                        if (found.length > 0) vencWeeks.push(found);
                    });
                });
            });
            tmpmonth.forEach((f) => {
                tmp.forEach((ativo) => {
                    const noweek = ativo.filter(
                        (t) =>
                            !template.includes(t.ticker.slice(-2)) &&
                            !e.status &&
                            t.vencimento === f
                    );
                    if (noweek.length > 0) vencWeeks.push(noweek);
                    if (e.status && findweek.length === 1) {
                        const last = ativo.filter(
                            (t) =>
                                !template.includes(t.ticker.slice(-2)) &&
                                e.status &&
                                t.vencimento === f
                        );
                        if (last.length > 0) vencWeeks.push(last);
                    }
                });
            });
        }
        // console.log(vencWeeks);

        orig[active].filters.venc.forEach((f) => {
            let cont = 0;
            vencWeeks.forEach((ativo) => {
                ativo.forEach((a) => {
                    if (f.label === a.vencimento) {
                        cont++;
                    }
                });
            });
            if (cont >= 1) {
                f.status = true;
            } else {
                f.status = false;
            }
        });
        const newweek = [];

        week.forEach((w, i) => {
            if (w.label === e.label) {
                if (findweek.length === 1 && e.status) {
                    newweek.push({
                        label: w.label,
                        status: w.status
                    });
                } else {
                    newweek.push({
                        label: w.label,
                        status: !e.status
                    });
                }
            } else {
                newweek.push({
                    label: w.label,
                    status: w.status
                });
            }
        });

        setWeek(newweek);
        setData(orig);
    };

    const checkStatus = (cont) => {
        return cont.some((obj) => obj.status === true);
    };

    const checkWeek = (cont) => {
        const orig = _.cloneDeep(months);
        const checked = orig.filter((m) => m.status);
        return checked.some((c) => cont?.includes(c.id));
    };

    useEffect(() => {
        if (data && active) {
            const list = [];
            const orig = _.cloneDeep(data[active]?.values);
            const filterYear = year.find((y) => y.status);
            const tmpYear = data[active]?.filters?.venc?.filter(
                (d) => moment(d.label).year() === filterYear.label
            );
            tmpYear?.forEach((y) => {
                list.push({
                    label: moment(y.label)
                        .locale('pt-br')
                        .format('MMMM')
                        .toUpperCase(),
                    id: Number(moment(y.label).format('M')),
                    status: y.status
                });
            });

            const unique = list.reduce((uniqueArray, obj) => {
                const dupe = uniqueArray.find(
                    (item) => item.label === obj.label
                );

                if (dupe) {
                    if (obj.status) {
                        dupe.status = true;
                    }
                } else {
                    uniqueArray.push(obj);
                }
                return uniqueArray;
            }, []);
            setMonths(unique);

            const listWeeks = [];
            list.forEach((obj) => {
                if (obj.status) {
                    const cont = tmpYear.filter(
                        (t) => Number(moment(t.label).format('M')) === obj.id
                    );
                    if (cont.length > 1 && checkStatus(cont)) {
                        listWeeks.push(obj.id);
                    }
                }
            });
            setActiveWeek(listWeeks);
            // console.log(listWeeks);

            if (listWeeks.length > 0 && week.length > 5) {
                const filterMonth = unique.find((y) => y.status);
                const listvenc = [];
                orig.forEach((ativo) => {
                    const vencforMonth = ativo.filter(
                        (f) =>
                            moment(f.vencimento).year() === filterYear.label &&
                            Number(moment(f.vencimento).format('M')) ===
                                filterMonth.id
                    );
                    if (vencforMonth.length > 0) listvenc.push(vencforMonth);
                });
                // console.log(listvenc);

                // ENCONTRA QUAIS SEMANAS TEM VENCIMENTO
                const template = ['W1', 'W2', 'W3', 'W4', 'W5'];
                const existingweeks = [];
                week.forEach((w) => {
                    for (let i = 0; i < listvenc.length; i++) {
                        const tmp = listvenc[i].find(
                            (ativo) => ativo.ticker.slice(-2) === w.label
                        );
                        if (w.label === 'W3') {
                            existingweeks.push('W3');
                            break;
                        }
                        if (tmp) {
                            existingweeks.push(tmp.ticker.slice(-2));
                            break;
                        }
                    }
                });

                const tmp = [];
                let check;
                listvenc.forEach((ativo) => {
                    for (let i = 0; i < ativo.length; i++)
                        if (ativo[i].vencimento === tmpYear[0].label) {
                            check = ativo[i];
                            break;
                        }
                });
                // console.log(check);

                existingweeks.forEach((w) => {
                    tmp.push({
                        label: w,
                        status: true
                    });
                });

                setWeek(tmp);
            }

            const marks = [
                {
                    value: data[active]?.filters?.strike?.min,
                    label: `R$${data[active]?.filters?.strike?.min.toString()}`
                },
                {
                    value: data[active]?.filters?.strike?.max,
                    label: `R$${data[active]?.filters?.strike?.max.toString()}`
                }
            ];
            setRange(marks);
        }
    }, [data, active]);

    return (
        <>
            <Grid
                container
                spacing={1}
                style={{
                    marginTop: -10,
                    marginLeft: 5,
                    justifyContent: 'space-between'
                }}
            >
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <Grid
                        container
                        spacing={1}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            width: size >= 1900 ? '950px' : '850px'
                        }}
                    >
                        <ReactTooltip id="filtro-tipo" place="right" />
                        <ReactTooltip id="modalidade" place="top" />
                        <ReactTooltip id="fx-strike" place="top" />
                        <ReactTooltip
                            id="otm"
                            place="right"
                            data-html
                            multiline
                            insecure
                        />
                        <ReactTooltip id="vencimento" place="right" />
                        <Grid item xs={12} sm={10} md={5} lg={3} xl={4}>
                            <Box className={classes.myBox}>
                                <label className={classes.myLabel}>TIPO</label>
                                <span
                                    data-for="filtro-tipo"
                                    style={{cursor: 'pointer'}}
                                    data-tip="CALL - contrato de compra ou PUT - contrato de venda"
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    alignItems: 'center'
                                }}
                            >
                                <Grid
                                    className={
                                        data[active]?.filters?.btnType?.call
                                            ? classes.myGridSelected
                                            : classes.myGrid
                                    }
                                    onClick={() => changeType('call')}
                                >
                                    CALL
                                </Grid>
                                <Grid
                                    className={
                                        data[active]?.filters?.btnType?.put
                                            ? classes.myGridSelected
                                            : classes.myGrid
                                    }
                                    onClick={() => changeType('put')}
                                >
                                    PUT
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={10} md={5} lg={3} xl={4}>
                            <Box
                                className={classes.myBox}
                                style={{width: '100px'}}
                            >
                                <label className={classes.myLabel}>
                                    MODALIDADE
                                </label>
                                <span
                                    data-for="modalidade"
                                    style={{cursor: 'pointer'}}
                                    data-tip="Modalidade - Americana - permite o exercício a qualquer momento e Europeia - o exercício da opção acontece apenas no dia do vencimento."
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    alignItems: 'center'
                                }}
                            >
                                <Grid
                                    className={
                                        data[active]?.filters?.btnModel?.america
                                            ? classes.myGridSelected
                                            : classes.myGrid
                                    }
                                    onClick={() => changeModel('america')}
                                >
                                    AMERICANA
                                </Grid>
                                <Grid
                                    className={
                                        data[active]?.filters?.btnModel?.europe
                                            ? classes.myGridSelected
                                            : classes.myGrid
                                    }
                                    onClick={() => changeModel('europe')}
                                >
                                    EUROPÉIA
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={5} md={5} lg={3} xl={4}>
                            <Box
                                className={classes.myBox}
                                style={{width: '100px'}}
                            >
                                <label className={classes.myLabel}>
                                    MONEYNESS
                                </label>
                                <span
                                    data-for="otm"
                                    style={{cursor: 'pointer'}}
                                    data-tip="Moneyness - Classificação do contrato de opção em relação ao preço do ativo. <br/> OTM - out of the money, ou fora do dinheiro, <br/> ATM - at the money, ou no dinheiro <br/> e ITM - in the money, ou dentro do dinheiro."
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    alignItems: 'center'
                                }}
                            >
                                <Grid
                                    className={
                                        data[active]?.filters?.btnMoneyness?.ITM
                                            ? classes.myGridSelected
                                            : classes.myGrid
                                    }
                                    onClick={() => changeMoney('ITM')}
                                >
                                    ITM
                                </Grid>
                                <Grid
                                    className={
                                        data[active]?.filters?.btnMoneyness?.ATM
                                            ? classes.myGridSelected
                                            : classes.myGrid
                                    }
                                    onClick={() => changeMoney('ATM')}
                                >
                                    ATM
                                </Grid>

                                <Grid
                                    className={
                                        data[active]?.filters?.btnMoneyness?.OTM
                                            ? classes.myGridSelected
                                            : classes.myGrid
                                    }
                                    onClick={() => changeMoney('OTM')}
                                >
                                    OTM
                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                {/*  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    style={{maxWidth: '350px'}}
                >
                    
                </Grid> */}
                <Grid
                    item
                    xs={10}
                    sm={6}
                    md={4}
                    lg={4}
                    xl={6}
                    style={{maxWidth: 800}}
                >
                    <Box
                        /* className={classes.myBox} */
                        style={{marginLeft: -15, marginBottom: '-16px'}}
                    >
                        <label
                            className={classes.myLabel}
                            style={{wordSpacing: '0.5px'}}
                        >
                            FAIXA DE STRIKE
                        </label>
                        <span
                            data-for="fx-strike"
                            style={{cursor: 'pointer'}}
                            data-tip="Faixa de strike - Faixas dos preços de exercício dos contratos de opção."
                        >
                            <ion-icon
                                style={{
                                    marginBottom: 2,
                                    fontSize: 12,
                                    color: '#d36233',
                                    cursor: 'pointer',
                                    pointerEvents: 'none'
                                }}
                                name="help-circle-outline"
                            />
                        </span>
                    </Box>
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            paddingTop: 25
                        }}
                    >
                        <div
                            // className={classes.mySlider}
                            style={{
                                // margin: 'auto',
                                width: '90%'
                            }}
                            ref={ref}
                            // onScroll={handleShowScrollPosition}
                        >
                            <SliderM
                                className={classes.mySlider}
                                size="medium"
                                max={data[active]?.filters?.strike?.max || 5000}
                                min={data[active]?.filters?.strike?.min || 0}
                                value={[
                                    Number(
                                        data[active]?.filters?.strike?.start
                                    ),
                                    Number(data[active]?.filters?.strike?.end)
                                ]}
                                valueLabelDisplay={sliderLabel}
                                aria-labelledby="range-slider"
                                ValueLabelComponent={(props) =>
                                    ValueLabelComponent(props, classes)
                                }
                                onChange={handleChangeSlider}
                                marks={range}
                            />
                        </div>
                    </Box>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={1}
                style={{
                    marginLeft:
                        size >= 1900 ? '-18px' : size >= 1600 ? '-10px' : '0px'
                }}
            >
                <Grid item xs={12}>
                    <>
                        <Box
                        /* className={classes.myBox} style={{
                                marginBottom: '-16px'
                            }} */
                        >
                            <label
                                className={classes.myLabel}
                                style={{
                                    marginLeft:
                                        size >= 1920
                                            ? '30px'
                                            : size >= 1600
                                            ? '10px'
                                            : '12px'
                                }}
                            >
                                VENCIMENTO
                            </label>
                            <span
                                data-for="vencimento"
                                style={{cursor: 'pointer'}}
                                data-tip="Vencimento - Data de vencimento dos contratos de opção."
                            >
                                <ion-icon
                                    style={{
                                        marginBottom: 2,
                                        fontSize: 12,
                                        color: '#d36233',
                                        pointerEvents: 'none'
                                    }}
                                    name="help-circle-outline"
                                />
                            </span>
                        </Box>
                        <div
                            style={{
                                display: 'flex',
                                marginBottom: '20px',
                                marginLeft: window.innerWidth > 1400 ? 20 : 0
                            }}
                        >
                            <Grid
                                container
                                style={{
                                    display: 'flex',
                                    justifyContent: 'start',
                                    alignItems: 'center'
                                }}
                            >
                                {year.map((y) => (
                                    <Grid
                                        key={y.label}
                                        item
                                        className={
                                            y.status
                                                ? classes.myGridSelected
                                                : classes.myGrid
                                        }
                                        onClick={() => changeYear(y)}
                                    >
                                        {y.label}
                                    </Grid>
                                ))}
                            </Grid>
                            {checkWeek(activeWeek) ? (
                                <Grid
                                    container
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'start',
                                        alignItems: 'center',
                                        marginLeft: '50px'
                                    }}
                                >
                                    {week.map((m) => (
                                        <Grid
                                            key={m.label}
                                            item
                                            className={
                                                m.status
                                                    ? classes.myGridSelected
                                                    : classes.myGrid
                                            }
                                            onClick={() => changeWeek(m)}
                                        >
                                            {m.label}
                                        </Grid>
                                    ))}
                                </Grid>
                            ) : null}
                        </div>

                        <Grid
                            container
                            style={{
                                display: 'flex',
                                justifyContent: 'start',
                                alignItems: 'center',
                                marginLeft: window.innerWidth > 1400 ? 20 : 0
                            }}
                        >
                            {months.map((m) => (
                                <Grid
                                    key={m.label}
                                    item
                                    className={
                                        m.status
                                            ? classes.myGridSelected
                                            : classes.myGrid
                                    }
                                    onClick={() => changeMonth(m)}
                                >
                                    {m.label}
                                </Grid>
                            ))}
                        </Grid>
                    </>
                </Grid>
            </Grid>
        </>
    );
}
