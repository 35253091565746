/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useEffect, useState} from 'react';
import Ticker from 'react-ticker';

import {makeStyles, createStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
    createStyles({
        myLabel: {
            color: '#d36233',
            fontSize: 14,
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        myLabelTitle: {
            color: '#0e0b0f',
            marginLeft: 50
        },
        texto: {
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        }
    })
);

export default function News({headerValues}) {
    const classes = useStyles();
    const [myKey, setMyKey] = useState(Math.random());

    useEffect(() => {
        setMyKey(Math.random());
    }, [headerValues]);

    return headerValues ? (
        <Ticker key={myKey} className={classes.texto} style={{height: 20}}>
            {() => (
                <>
                    <div
                        className={classes.myLabel}
                        style={{marginTop: -5, marginBottom: -5}}
                    >
                        <label className={classes.myLabelTitle}>Data: </label>{' '}
                        {headerValues.data}
                        <label className={classes.myLabelTitle}>
                            Mínima:
                        </label>{' '}
                        {headerValues.min}
                        <label className={classes.myLabelTitle}>
                            Máxima:
                        </label>{' '}
                        {headerValues.max}
                        <label className={classes.myLabelTitle}>
                            Fechamento:
                        </label>{' '}
                        {headerValues.valor}
                        <label className={classes.myLabelTitle}>
                            Fechamento Diário:
                        </label>{' '}
                        {headerValues.fechD}
                        <label className={classes.myLabelTitle}>
                            Volatilidade Histórica:
                        </label>{' '}
                        {headerValues.volT}%
                        <label className={classes.myLabelTitle}>
                            Volatilidade CALL:
                        </label>{' '}
                        {headerValues.call}%
                        <label className={classes.myLabelTitle}>
                            Volatilidade PUT:
                        </label>{' '}
                        {headerValues.put}%
                    </div>
                </>
            )}
        </Ticker>
    ) : (
        <></>
    );
}
