/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable indent */
/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-const */
/* eslint-disable dot-notation */

import React, {useState, useEffect} from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    ReferenceLine,
    Label,
    linearGradient
} from 'recharts';
import Slider from 'react-slick';
import moment from 'moment';
import api from '@app/utils/api';
import Holidays from 'date-holidays';
import {cleanNumber} from '@app/utils/myaux';
import {Checkbox} from '@material-ui/core';
import {identifier} from '../utils/identifier';
import {getChart, getChartAtivo} from '../utils/chartsCalculation';
import WalletTooltip from './WalletTooltip';

const myIv = require('implied-volatility');

export default function WalletChart({
    myStrategy,
    strategy,
    cotation,
    ativo,
    tickers,
    setTickers,
    desmontCot,
    selic
}) {
    const [data, setData] = useState();
    const [originalData, setOriginalData] = useState();
    const [allData, setAllData] = useState();
    const [originalAllData, setOriginalAllData] = useState();
    const [dataChartPl, setDataChartPl] = useState();
    const [originalDataPl, setOriginalDataPl] = useState();
    const [tick, setTick] = useState();
    const [cot, setCot] = useState();
    const [vencLegenda, setVencLegenda] = useState();
    const [filter, setFilter] = useState();
    const [notFilter, setNotFilter] = useState();
    const [pattern, setPattern] = useState();
    const [strikes, setStrikes] = useState();
    const [days, setDays] = useState();
    const [allVenc, setAllVenc] = useState();
    const [chartTooltip, setChartTooltip] = useState();
    const [max, setMax] = useState();
    const [min, setMin] = useState();
    const [off, setOff] = useState();
    const [offTeorico, setOffTeorico] = useState();
    // const [selic, setSelic] = useState(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 1200,
        slidesToShow: 4,
        slidesToScroll: 3,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const hd = new Holidays('BR', '', '');

    const calcBusinessDays = (startDate, endDate) => {
        const day = moment(startDate);
        let businessDays = 0;
        while (day.isSameOrBefore(endDate, 'day')) {
            if (day.day() !== 0 && day.day() !== 6) {
                if (!hd.isHoliday(day)) {
                    businessDays++;
                }
            }
            day.add(1, 'd');
        }
        return businessDays;
    };

    const getTime = (str) => {
        if (str.venc) {
            const venc = moment(str.venc.value);
            const today = moment().format('YYYY-MM-DD');
            return calcBusinessDays(today, venc);
        }
        return null;
    };

    const colors = [
        /* '#0D214F', // janeiro
        '#F4511E', // fevereiro
        '#7CB342', // marco
        '#00897B', // abril
        '#00695C', // maio
        '#7B1FA2', // junho
        '#4FC3F7', // julho
        '#880E4F', // agosto
        '#AFB42B', // setembro
        '#D2691E', // outubro
        '#E6E6FA', // novembro
        '#4B0082' // dezembro */
        '#e37039',
        '#439da0',
        '#bba821',
        '#427411',
        '#9298ab',
        '#5d3967',
        '#d71814',
        '#fa66c2'
    ];

    const cotationRange = () => {
        const cotMin = cot * 0.45;
        const cotMax = cot * 1.45;
        // console.log(cotMin, cotMax);
        return [cotMin, cotMax];
    };

    const checkPosition = () => {
        const arr = [];
        strategy.forEach((s) => {
            if (s.ticker) arr.push(s.ticker.value);
        });

        const counts = arr.reduce(
            (acc, value) => ({
                ...acc,
                [value]: (acc[value] || 0) + 1
            }),
            {}
        );

        return counts;
    };

    const handleLineMouseOver = (e) => {
        // console.log(e);
        setChartTooltip(e);
    };

    const handleLineMouseOut = () => {
        // Quando o mouse sair da linha, você pode limpar os dados do tooltip definindo o estado como null.
        setChartTooltip(null);
    };

    const setAcumulada = (event) => {
        if (!event.target.checked) {
            setAllData(null);
        } else {
            setAllData(originalAllData);
        }
    };

    const changeCheck = (event, value, ref) => {
        const listOn = [];
        const listOff = [];

        if (event.target.checked) {
            if (filter) {
                filter.forEach((f) => {
                    listOn.push({
                        value: f.value,
                        color: f.color,
                        checked: true,
                        ref: f.ref
                    });
                });
            }
            notFilter.forEach((nf) => {
                if (nf.value === value && nf.ref === ref) {
                    listOn.push({
                        value: nf.value,
                        color: nf.color,
                        checked: true,
                        ref: nf.ref
                    });
                } else {
                    listOff.push({
                        value: nf.value,
                        color: nf.color,
                        checked: false,
                        ref: nf.ref
                    });
                }
            });
        } else {
            if (notFilter) {
                notFilter.forEach((nf) => {
                    listOff.push({
                        value: nf.value,
                        color: nf.color,
                        checked: false,
                        ref: nf.ref
                    });
                });
            }
            filter.forEach((f) => {
                if (f.value === value && f.ref === ref) {
                    listOff.push({
                        value: f.value,
                        color: f.color,
                        checked: false,
                        ref: f.ref
                    });
                } else {
                    listOn.push({
                        value: f.value,
                        color: f.color,
                        checked: true,
                        ref: f.ref
                    });
                }
            });
        }
        setNotFilter(listOff);
        setFilter(listOn);
    };

    const [nearestLine, setNearestLine] = useState('');

    const getStrike = (arr, mouse) => {
        return arr.reduce((prev, curr) => {
            return Math.abs(Number(curr.value) - Number(mouse)) >
                Math.abs(Number(prev.value) - Number(mouse))
                ? curr.name
                : prev.name;
        });
    };

    const handleMouseMove = (event) => {
        // console.log(event.chartY);
        // console.log(getStrike(event.activePayload, event.chartY));
    };

    const getTotal = (arr, indexOfAtivo) => {
        const dataTotal = [];
        const dataTeorico = [];
        const tmpTick = [];
        const dataMaxMin = [];
        const cotRange = cotationRange();
        const cotMin = cotRange[0];
        const cotMax = cotRange[1];
        const diff = cotMax - cotMin;
        let cont = 0;
        if (diff < 5) {
            cont = 0.05;
        } else if (diff < 10) {
            cont = 0.08;
        } else if (diff < 20) {
            cont = 0.1;
        } else {
            cont = 0.2;
        }
        let diffVenc = false;
        if (arr.length > 1) {
            const origVenc = arr[0]?.venc?.value;
            for (let i = 1; i < arr.length; i++) {
                if (arr[i]?.venc?.value !== origVenc) {
                    diffVenc = true;
                    break;
                }
            }
        }

        const position = checkPosition();
        let increase = cont;
        for (let i = 0; increase <= diff; i++) {
            let cotacao = 0;
            increase += cont;
            cotacao = cotMin + cleanNumber(increase.toFixed(2));
            for (let j = 0; j < strikes.length; j++) {
                if (
                    cotacao - cont < cleanNumber(strikes[j]) &&
                    cotacao + cont >= cleanNumber(strikes[j])
                ) {
                    cotacao = cleanNumber(strikes[j]);
                    break;
                }
            }
            let result = 0;
            let resultTheorical = 0;
            let tmpResult;
            if (!indexOfAtivo) {
                // console.log(tickers);
                arr.forEach((array, index) => {
                    if (array.strike && array.pteorico) {
                        tmpResult = getChart(
                            array,
                            index,
                            cotacao,
                            days,
                            position,
                            null,
                            tickers,
                            pattern,
                            diffVenc,
                            selic
                        );
                        result += tmpResult[0];
                        resultTheorical += tmpResult[1];
                    }
                });
            } else {
                // console.log(diffVenc, indexOfAtivo);
                tmpResult = getChartAtivo(
                    strategy,
                    cotacao,
                    days,
                    tickers,
                    diffVenc,
                    indexOfAtivo,
                    selic
                );
                result += tmpResult[0];
                resultTheorical += tmpResult[1];
            }
            // console.log(result);
            dataTotal.push({
                cotacao: cleanNumber(
                    cotacao <= cot && cotacao >= cot ? cot : cotacao.toFixed(2)
                ),
                soma: cleanNumber(result.toFixed(2))
            });
            dataTeorico.push({
                cotacao: cleanNumber(
                    cotacao <= cot && cotacao >= cot ? cot : cotacao.toFixed(2)
                ),
                soma: cleanNumber(resultTheorical.toFixed(2))
            });
            tmpTick.push(cotacao);
            dataMaxMin.push(result);
        }
        const ticks = [];
        tmpTick.forEach((c, i) => {
            const rounded = Math.ceil(c / 5) * 5;
            if (
                tmpTick[i - 1] &&
                Math.ceil(tmpTick[i - 1] / 5) * 5 !== rounded
            ) {
                ticks.push(rounded);
            } else if (!tmpTick[i - 1]) {
                ticks.push(rounded);
            }
        });
        ticks.pop();
        setTick(ticks);
        const maximo = dataMaxMin.reduce(function (a, b) {
            return Math.max(a, b);
        }, -Infinity);
        const minimo = dataMaxMin.reduce(function (a, b) {
            return Math.min(a, b);
        }, Infinity);

        if (maximo < 0) {
            setMax(Math.floor(maximo / 1000) * 1000 - 500);
        } else {
            setMax(Math.ceil(maximo / 1000) * 1000 + 500);
        }
        if (minimo < 0) {
            setMin(Math.floor(minimo / 1000) * 1000 - 500);
        } else {
            setMin(Math.ceil(minimo / 1000) * 1000 + 500);
        }

        setAllData([
            {name: 'TOTAL', data: dataTotal, stroke: colors[0]},
            {
                name: 'TEÓRICO',
                data: dataTeorico,
                stroke: colors[0]
            }
        ]);
        setOriginalAllData([
            {
                name: 'TOTAL',
                data: dataTotal,
                stroke: colors[0]
            },
            {
                name: 'TEÓRICO',
                data: dataTeorico,
                stroke: colors[0]
            }
        ]);
    };

    const getChartVenc = (arr) => {
        const dataTotal = [];
        const dataTeorico = [];
        const cotRange = cotationRange();
        const cotMin = cotRange[0];
        const cotMax = cotRange[1];
        const diff = cotMax - cotMin;
        let cont = 0;
        if (diff < 5) {
            cont = 0.05;
        } else if (diff < 10) {
            cont = 0.08;
        } else if (diff < 20) {
            cont = 0.1;
        } else {
            cont = 0.2;
        }

        const position = checkPosition();
        allVenc.forEach((v, indice) => {
            const dataVenc = [];
            const dataVencTeorico = [];
            let check;
            // if (v) check = moment(v).format('M');
            let increase = cont;
            for (let i = 0; increase <= diff; i++) {
                let cotacao = 0;
                increase += cont;
                cotacao = cotMin + cleanNumber(increase.toFixed(2));
                for (let j = 0; j < strikes.length; j++) {
                    if (
                        cotacao - cont < cleanNumber(strikes[j]) &&
                        cotacao + cont >= cleanNumber(strikes[j])
                    ) {
                        cotacao = cleanNumber(strikes[j]);
                        break;
                    }
                }
                let result = 0;
                let resultTheorical = 0;
                arr.forEach((array, index) => {
                    if (array.strike && array.pteorico) {
                        if (array.venc.value === v) {
                            const tmpResult = getChart(
                                array,
                                index,
                                cotacao,
                                days,
                                position,
                                v,
                                tickers,
                                pattern,
                                null,
                                selic
                            );
                            result += tmpResult[0];
                            resultTheorical += tmpResult[1];
                        }
                    }
                });
                dataVenc.push({
                    cotacao: cleanNumber(
                        cotacao <= cot && cotacao >= cot
                            ? cot
                            : cotacao.toFixed(2)
                    ),
                    soma: /* result >= max || result <= min
                            ? null
                            : */ cleanNumber(result.toFixed(2))
                });
                dataVencTeorico.push({
                    cotacao: cleanNumber(
                        cotacao <= cot && cotacao >= cot
                            ? cot
                            : cotacao.toFixed(2)
                    ),
                    soma: /*  resultTheorical >= max || resultTheorical <= min
                            ? null
                            : */ cleanNumber(resultTheorical.toFixed(2))
                });
            }
            if (v) {
                dataTotal.push({
                    name: moment(v).format('DD/MM/YYYY'),
                    data: dataVenc,
                    stroke: colors[indice]
                });
                dataTeorico.push({
                    name: `TEÓRICO ${moment(v).format('DD/MM/YYYY')}`,
                    data: dataVencTeorico,
                    stroke: colors[indice]
                });
            }
        });
        // console.log(dataTotal);
        setOriginalDataPl(dataTotal);
        setOriginalData(dataTeorico);
    };

    const gradientOffset = (array) => {
        // console.log(array);
        const tmpArray = array[0].data;
        const dataMax = Math.max(...tmpArray.map((i) => i.soma));
        const dataMin = Math.min(...tmpArray.map((i) => i.soma));

        // console.log(dataMax, dataMin);
        // console.log(dataMax / (dataMax - dataMin));

        if (dataMin === dataMax) {
            if (dataMax > 0) {
                return 1;
            }
        }
        if (dataMax <= 0) {
            return 0;
        }
        if (dataMin >= 0) {
            return 1;
        }
        return dataMax / (dataMax - dataMin);
    };

    const allEqual = (arr) => arr.every((v) => v.soma === arr[0].soma);
    const gradientOffTeorico = (array) => {
        // console.log(array);
        const tmpArray = array[1].data;
        const dataMax = Math.max(...tmpArray.map((i) => i.soma));
        const dataMin = Math.min(...tmpArray.map((i) => i.soma));

        // console.log(Math.round(dataMax), Math.round(dataMin));

        if (dataMax <= 0) {
            return 0;
        }
        if (dataMin >= 0) {
            return 1;
        }
        return dataMax / (dataMax - dataMin);
    };

    function SampleNextArrow(props) {
        const {className, style, onClick} = props;
        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
                className={className}
                style={{
                    ...style,
                    display: 'block',
                    background: '#e37039',
                    borderRadius: '100%',
                    height: 19,
                    width: 20
                }}
                onClick={onClick}
            />
        );
    }

    function SamplePrevArrow(props) {
        const {className, style, onClick} = props;
        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
                className={className}
                style={{
                    ...style,
                    display: 'block',
                    background: '#e37039',
                    borderRadius: '100%',
                    height: 19,
                    width: 20,
                    marginLeft: 5
                }}
                onClick={onClick}
            />
        );
    }

    function handleKeyUp(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            event.target.blur();
        }
    }

    useEffect(() => {
        if (filter && originalData) {
            const listFiltered = [];
            const listPL = [];

            console.log(originalData);
            console.log(originalDataPl);
            console.log(filter);

            originalData.forEach((d) => {
                const date = moment(d.name, 'DD/MM/YYYY').format('YYYY-MM-DD');
                filter.forEach((a) => {
                    const checkDate = moment(a.value, 'DD/MM/YYYY').format(
                        'YYYY-MM-DD'
                    );

                    // console.log(moment(d.name).i, moment(a.value).i);
                    if (date === checkDate && a.checked === true) {
                        listFiltered.push(d);
                    }
                });
            });

            originalDataPl.forEach((d) => {
                const date = moment(d.name, 'DD/MM/YYYY');
                filter.forEach((f) => {
                    if (moment(date).format('M/YY') === f.ref) {
                        listPL.push(d);
                    }
                });
            });

            if (filter.length === 0) {
                setData(null);
                setDataChartPl(null);
            } else {
                setData(listFiltered);
                setDataChartPl(listPL);
            }
        }
    }, [filter, originalData]);

    useEffect(() => {
        if (strategy.length > 0) {
            setCot(cotation);
            const listStrike = [];
            const listDays = [];
            const listVols = [];
            /* if (strategy.venc) {
                strategy.sort((a, b) =>
                    moment(a.venc.value).diff(moment(b.venc.value))
                );
            } */
            for (let i = 0; i < strategy.length; i++) {
                if (strategy[i].strike && strategy[i].ticker) {
                    if (strategy[i].strike) {
                        listStrike.push(strategy[i].strike.value);
                    }
                    const T = getTime(strategy[i]);
                    listDays.push(T / 252);
                    if (
                        strategy.length > 0 &&
                        strategy[i].strike &&
                        strategy[i].ticker &&
                        cot
                    ) {
                        const t = getTime(strategy[i]);
                        /* const findUltimo = strategy[i].data[ativo].values.find(
                            (o) => o.ticker === strategy[i].ticker.value
                        ); */
                        // console.log(findUltimo?.ultimo);
                        listVols.push(
                            cleanNumber(
                                myIv.getImpliedVolatility(
                                    cleanNumber(strategy[i]?.cotacao),
                                    cleanNumber(desmontCot),
                                    cleanNumber(strategy[i]?.strike?.value),
                                    t / 252,
                                    selic,
                                    strategy[i].activeType === 'C'
                                        ? 'call'
                                        : 'put',
                                    0.1
                                )
                            )
                        );
                    }
                }
                // console.log(strategy[i]);
            }
            // if (listVols) console.log('volatilidade', listVols);
            setStrikes(listStrike);
            setDays(listDays);
            if (listVols) {
                if (
                    listVols.length !== 0 ||
                    (tickers && listVols.length !== tickers.length)
                ) {
                    setTickers(listVols);
                }
            }
            setPattern(identifier(strategy, ativo));

            const listTmpVenc = [];
            const venc = strategy.filter(
                (v, i, a) =>
                    a.findIndex(
                        (v2) =>
                            v.venc &&
                            v2.venc &&
                            v.venc.value &&
                            v2.venc.value === v.venc.value
                    ) === i
            );
            venc.forEach((v) => {
                if (v.venc) listTmpVenc.push(v.venc.value);
            });
            setAllVenc(listTmpVenc);
            const listVenc = [];
            const listLegenda = [];

            listLegenda.push({
                value: 'TOTAL',
                color: '1',
                checked: true,
                ref: 0
            });
            venc.forEach((v, i) => {
                if (v.venc) {
                    listVenc.push({
                        value: moment(v.venc.value).format('DD/MM/YYYY'),
                        color: moment(v.venc.value).format('M'),
                        checked: false,
                        ref: moment(v.venc.value).format('M/YY')
                    });
                    listVenc.push({
                        value: moment().format('DD/MM/YYYY'),
                        color: moment(v.venc.value).format('M'),
                        checked: false,
                        ref: moment(v.venc.value).format('M/YY')
                    });
                    listLegenda.push({
                        value: moment(v.venc.value).format('DD/MM/YYYY'),
                        color: moment(v.venc.value).format('M'),
                        checked: false,
                        ref: moment(v.venc.value).format('M/YY')
                    });
                }
            });
            setVencLegenda(listLegenda);
            setNotFilter(listVenc);
        }
    }, [strategy]);

    useEffect(() => {
        if (strategy) {
            let indexOfAtivo = null;
            strategy.forEach((s, i) => {
                if (s?.ticker?.value === ativo) {
                    indexOfAtivo = i;
                }
            });
            if (indexOfAtivo) {
                getTotal(strategy, indexOfAtivo);
                getChartVenc(strategy);
            } else if (tickers && tickers.length === strategy.length) {
                /* getAllData(strategy);
            getChart(strategy); */
                getTotal(strategy);
                getChartVenc(strategy);
            }
        }
    }, [tickers]);

    useEffect(() => {
        if (allData) {
            const tmpTeorico = gradientOffTeorico(allData);
            const tmpOff = gradientOffset(allData);
            setOffTeorico(tmpTeorico);
            setOff(tmpOff);
            // console.log(allEqual(allData[0].data));
        }
    }, [allData]);

    useEffect(() => {
        if (pattern) {
            console.log('pattern -> ', pattern);
        }
    }, [pattern]);

    return (
        <>
            {(strategy.length === 1 && strategy[0].ticker) ||
            strategy.length > 1 ? (
                <div
                    style={{
                        marginTop:
                            vencLegenda && vencLegenda.length < 2
                                ? '30px'
                                : '-20px',
                        marginBottom: '20px'
                    }}
                >
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        {vencLegenda && vencLegenda.length > 2 ? (
                            vencLegenda.length > 6 ? (
                                <Slider
                                    {...settings}
                                    variableWidth
                                    style={{
                                        width: 540,
                                        height: '40%',
                                        // display: 'flex',
                                        marginBottom: 20,
                                        marginLeft: 35
                                        // alignItems: 'center'
                                    }}
                                >
                                    {vencLegenda.map((v) => {
                                        return (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    margin: '5px 5px 5px 5px',
                                                    alignItems: 'center',
                                                    color: '#313031'
                                                }}
                                            >
                                                <Checkbox
                                                    defaultChecked={v.checked}
                                                    onChange={(event) => {
                                                        if (
                                                            v.value === 'TOTAL'
                                                        ) {
                                                            setAcumulada(event);
                                                        } else {
                                                            changeCheck(
                                                                event,
                                                                v.value,
                                                                v.ref
                                                            );
                                                        }
                                                    }}
                                                    style={{
                                                        color: '#313031'
                                                    }}
                                                />
                                                {v.value}
                                            </div>
                                        );
                                    })}
                                </Slider>
                            ) : (
                                vencLegenda.map((v) => {
                                    return (
                                        <div
                                            style={{
                                                display: 'flex',
                                                margin: '5px 5px 5px 5px',
                                                alignItems: 'center',
                                                color: '#313031'
                                            }}
                                        >
                                            <Checkbox
                                                defaultChecked={v.checked}
                                                onChange={(event) => {
                                                    if (v.value === 'TOTAL') {
                                                        setAcumulada(event);
                                                    } else {
                                                        changeCheck(
                                                            event,
                                                            v.value,
                                                            v.ref
                                                        );
                                                    }
                                                }}
                                                style={{color: '#313031'}}
                                            />
                                            {v.value}
                                        </div>
                                    );
                                })
                            )
                        ) : null}
                    </div>

                    <div>
                        {originalData && tick ? (
                            <LineChart
                                width={1100}
                                height={350}
                                onMouseMove={handleMouseMove}
                            >
                                <defs>
                                    <linearGradient
                                        id="splitColor"
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                    >
                                        <stop offset={off} stopColor="green" />
                                        <stop offset={off} stopColor="red" />
                                    </linearGradient>
                                    <linearGradient
                                        id="Teorico"
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                    >
                                        <stop
                                            offset={offTeorico}
                                            stopColor="green"
                                        />
                                        <stop
                                            offset={offTeorico}
                                            stopColor="red"
                                        />
                                    </linearGradient>
                                </defs>

                                <XAxis
                                    type="number"
                                    ticks={tick}
                                    dataKey="cotacao"
                                    allowDuplicatedCategory={false}
                                    domain={[tick[0], tick.at(-1)]}
                                >
                                    {/* <Label value={pattern} position="insideTopRight" /> */}
                                </XAxis>
                                <YAxis dataKey="soma" />
                                <CartesianGrid
                                    stroke="#eee"
                                    strokeDasharray="5 5"
                                />
                                <ReferenceLine
                                    /* label={{
                                fill: '#e37039',
                                // value: Number(cot),
                                angle: 270,
                                position: 'insideRight'
                            }} */
                                    x={Number(cot)}
                                    stroke="#000"
                                    ifOverflow="visible"
                                />
                                <ReferenceLine
                                    y="0"
                                    strokeDasharray="3 3"
                                    stroke="#000"
                                    ifOverflow="visible"
                                />

                                <Tooltip
                                    /*  cursor={false}
                            wrapperStyle={{display: 'none'}} */
                                    content={
                                        <WalletTooltip
                                            chartTooltip={chartTooltip}
                                            ativo={ativo}
                                            cot={cot}
                                            mouseX={nearestLine}
                                        />
                                    }
                                />
                                {data
                                    ? data.map((d) => {
                                          return (
                                              <>
                                                  <Line
                                                      data={d.data}
                                                      name={d.name}
                                                      key={d.name}
                                                      type="monotone"
                                                      dataKey="soma"
                                                      stroke={d.stroke}
                                                      dot={false}
                                                      onMouseOver={() =>
                                                          setChartTooltip(
                                                              d.name
                                                          )
                                                      }
                                                  />
                                              </>
                                          );
                                      })
                                    : null}
                                {dataChartPl
                                    ? dataChartPl.map((d) => {
                                          return (
                                              <>
                                                  <Line
                                                      data={d.data}
                                                      name={d.name}
                                                      key={d.name}
                                                      type="monotone"
                                                      dataKey="soma"
                                                      stroke={d.stroke}
                                                      dot={false}
                                                      onMouseOver={
                                                          handleLineMouseOver
                                                      }
                                                      onMouseOut={
                                                          handleLineMouseOut
                                                      }
                                                  />
                                              </>
                                          );
                                      })
                                    : null}
                                {allData
                                    ? allData.map((a) => {
                                          return (
                                              /*   <>
                                                  {a.name === 'TOTAL' ? (
                                                      <Line
                                                          data={a.data}
                                                          name={a.name}
                                                          key={a.name}
                                                          type="monotone"
                                                          dataKey="soma"
                                                          stroke={
                                                              allEqual(a.data)
                                                                  ? a.data[0]
                                                                        .soma >=
                                                                    0
                                                                      ? 'green'
                                                                      : 'red'
                                                                  : 'url(#splitColor)'
                                                          }
                                                          dot={false}
                                                          onMouseOver={
                                                              handleLineMouseOver
                                                          }
                                                          onMouseOut={
                                                              handleLineMouseOut
                                                          }
                                                      />
                                                  ) : (
                                                      <Line
                                                          data={a.data}
                                                          name={a.name}
                                                          key={a.name}
                                                          type="monotone"
                                                          dataKey="soma"
                                                          stroke={
                                                              allEqual(a.data)
                                                                  ? a.data[0]
                                                                        .soma >=
                                                                    0
                                                                      ? 'green'
                                                                      : 'red'
                                                                  : 'url(#Teorico)'
                                                          }
                                                          dot={false}
                                                          onMouseOver={
                                                              handleLineMouseOver
                                                          }
                                                          onMouseOut={
                                                              handleLineMouseOut
                                                          }
                                                      />
                                                  )}
                                              </> */
                                              <Line
                                                  data={a.data}
                                                  name={a.name}
                                                  key={a.name}
                                                  type="monotone"
                                                  dataKey="soma"
                                                  stroke={
                                                      allEqual(a.data)
                                                          ? a.data[0].soma >= 0
                                                              ? 'green'
                                                              : 'red'
                                                          : a.name === 'TOTAL'
                                                          ? 'url(#splitColor)'
                                                          : 'url(#Teorico)'
                                                  }
                                                  dot={false}
                                                  onMouseOver={
                                                      handleLineMouseOver
                                                  }
                                                  onMouseOut={
                                                      handleLineMouseOut
                                                  }
                                              />
                                          );
                                      })
                                    : null}
                            </LineChart>
                        ) : null}
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginTop: '-70px',
                                marginBottom: '30px',
                                marginRight: '45px'
                            }}
                        >
                            {pattern}
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
}
