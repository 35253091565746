/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-return-await */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable indent */

import React, {useState} from 'react';
import api from '@app/utils/api';
import Loading from '@app/components/loading/Loading';
import {TextField, InputAdornment} from '@material-ui/core';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import moment from 'moment';
// import {Payment, initMercadoPago} from '@mercadopago/sdk-react';
import {Card} from 'react-bootstrap';

export default function CheckoutMP({
    setFinish,
    plano,
    setApproved,
    setProssiga,
    setErrorLog,
    formdata,
    client
}) {
    const [loading, setLoading] = useState(false);
    const useStyles = makeStyles(() =>
        createStyles({
            myTextField: {
                backgroundColor: '#cac6c2',
                width: '90%',
                height: '45px',
                borderRadius: '20px'
            },
            smallTextField: {
                backgroundColor: '#cac6c2',
                width: '50%',
                height: '45px',
                borderRadius: '20px'
            },
            myInput: {
                disableUnderline: true,
                height: '45px',
                color: '#313031',
                alignItems: 'center',
                textAlign: 'center',
                fontSize: 24,
                // marginLeft: 10,
                '& .MuiTypography-colorTextSecondary': {
                    color: '#d36233',
                    fontSize: 24,
                    margin: '10px'
                },
                '& .MuiInputBase-input': {
                    textAlign: 'center'
                }
            }
        })
    );

    const [number, setNumber] = useState('');
    const classes = useStyles();

    const handleKeyUp = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            event.target.blur();
        }
    };

    const [cardForm, setCardForm] = useState({
        value: plano === 'mensal' ? 9.9 : 69.9,
        customer: client,
        description:
            plano === 'mensal'
                ? 'Assinatura mensal Opti'
                : plano === 'FREE'
                ? 'Assinatura anual Opti com 30 dias grátis'
                : 'Assinatura anual Opti',
        billingType: 'CREDIT_CARD',
        nextDueDate:
            plano === 'FREE'
                ? moment().add(1, 'months').format('YYYY-MM-DD')
                : moment().format('YYYY-MM-DD'),
        cycle: plano === 'anual' ? 'YEARLY' : 'MONTHLY',
        creditCard: {
            holderName: '',
            number: '',
            expiryMonth: '',
            expiryYear: '',
            ccv: ''
        },
        creditCardHolderInfo: {
            name: formdata.nome,
            email: formdata.email,
            cpfCnpj: formdata.cpf,
            postalCode: formdata.cep,
            addressNumber: formdata.numeroend,
            // addressComplement: '',
            phone: formdata.fone,
            mobilePhone: formdata.celular
        },
        remoteIp: '',
        creditCardToken: ''
    });

    function mcc(v) {
        v = v.replace(/\D/g, '');
        return v.match(/\d{1,4}/g)?.join(' ');
    }

    const handleChange = (id, value) => {
        const [object, property] = id.split('.');

        if (object === 'creditCard') {
            cardForm.creditCard[property] = value;
        } else if (object === 'creditCardHolderInfo') {
            cardForm.creditCardHolderInfo[property] = value;
        }
    };

    const submit = (id) => {
        setLoading(true);
        api.assinatura
            .createAssinatura(cardForm, plano, id)
            .then((res) => {
                if (res.response?.status === 400) {
                    setErrorLog(res.response.data.message);
                    setLoading(false);
                    setApproved(false);
                    setFinish(true);
                } else if (res?.status === 201) {
                    setLoading(false);
                    setApproved(true);
                    setFinish(true);
                }
            })
            .catch((e) => console.log('error', e));
    };

    /*  const customization = {
        paymentMethods: {
            creditCard: 'all',
            atm: 'all',
            maxInstallments: 1
        },
        visual: {
            hideFormTitle: true,
            hidePaymentButton: true,
            style: {
                customVariables: {
                    errorColor: '#e37039',
                    // fontWeightNormal: '700',
                    inputBackgroundColor: '#cac6c0',
                    successColor: '#cac6c0',
                    textPrimaryColor: '#e37039',
                    textSecondaryColor: '#74737c',
                    baseColor: '#e37039',
                    formBackgroundColor: '#313031'
                }
            },
            texts: {
                cardholderName: {
                    label: 'NOME DO TITULAR COMO APARECE NO CARTÃO',
                    placeholder: 'Nome e Sobrenome'
                },
                cardholderIdentification: {
                    label: 'DOCUMENTO DO TITULAR',
                    placeholder: '000.000.000-00'
                },
                cardNumber: {
                    label: 'NÚMERO DO CARTÃO',
                    placeholder: '0000 0000 0000 0000'
                },
                expirationDate: {
                    label: 'DATA DE VENCIMENTO'
                },
                securityCode: {
                    label: 'CÓDIGO DE SEGURANÇA',
                    placeholder: '000'
                }
            }
        }
    }; */

    /* const initialization = {
        amount: plano === 'mensal' ? 9.9 : 69.9,
        preferenceId:
            plano === 'mensal'
                ? '1247724295-bbc2064b-3c08-4273-8c9d-59ce53c13a0a'
                : '1247724295-15607baa-4eb5-4dd8-a5e6-2c88282a8296',
        payer: {
            email
        }
    };
    const onSubmit = ({}) => {
        window.paymentBrickController.getFormData().then((formData) => {
            console.log(formData, plano);
            setLoading(true);
            api.assinatura
                .requestAssinaturaPlano(formData.formData, plano, name)
                .then((response) => {
                    console.log(response);
                    if (!response.data[1]) {
                        setLoading(false);
                        setApproved(false);
                        setFinish(true);
                    } else {
                        setLoading(false);
                        setApproved(true);
                        setFinish(true);
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    console.log(e);
                    setFinish(true);
                });
        });
    };

    const onError = async (error) => {
        console.log(error);
    };

    const onReady = async () => {}; */

    return (
        <>
            <Loading open={loading} msg="Efetuando Pagamento" />
            <Card
                style={{
                    backgroundColor: '#313031',
                    color: '#e37039',
                    borderRadius: '20px',
                    textAlign: 'center',
                    width: 500,
                    height: 450,
                    fontFamily: 'Roboto'
                }}
            >
                <div style={{height: 400}}>
                    <div style={{textAlign: 'start', margin: '10px'}}>
                        <span
                            title="Voltar"
                            style={{cursor: 'pointer'}}
                            onClick={() => setProssiga(false)}
                        >
                            <ion-icon
                                name="arrow-back-outline"
                                style={{
                                    color: '#d36233',
                                    fontSize: 34,
                                    cursor: 'pointer',
                                    pointerEvents: 'none'
                                }}
                            />
                        </span>
                    </div>
                    {/* <Payment
                        initialization={initialization}
                        customization={customization}
                        onReady={onReady}
                        onError={onError}
                    />
                    <div
                        style={{
                            display: 'flex',
                            textAlign: 'start',
                            // marginTop: '-10px',
                            marginLeft: '20px'
                        }}
                    ></div> */}
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div style={{width: '100%'}}>
                            <div style={{fontSize: 24}}>TITULAR DO CARTÃO</div>
                            <TextField
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.myInput
                                }}
                                variant="standard"
                                onBlur={(event) =>
                                    handleChange(
                                        'creditCard.holderName',
                                        event.target.value
                                    )
                                }
                                onKeyUp={handleKeyUp}
                                className={classes.myTextField}
                            />
                        </div>
                    </div>

                    <div>
                        <div style={{fontSize: 24}}>NÚMERO DO CARTÃO</div>
                        <TextField
                            InputProps={{
                                disableUnderline: true,
                                className: classes.myInput
                            }}
                            value={mcc(number)}
                            onChange={(event) => setNumber(event.target.value)}
                            inputProps={{maxLength: 20}}
                            placeholder="**** **** **** ****"
                            variant="standard"
                            onBlur={(event) =>
                                handleChange(
                                    'creditCard.number',
                                    event.target.value
                                )
                            }
                            onKeyUp={handleKeyUp}
                            className={classes.myTextField}
                        />
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                        <div>
                            <div style={{fontSize: 24}}>VENC. MÊS</div>

                            <TextField
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.myInput
                                }}
                                inputProps={{maxLength: 2}}
                                placeholder="00"
                                variant="standard"
                                onBlur={(event) =>
                                    handleChange(
                                        'creditCard.expiryMonth',
                                        event.target.value
                                    )
                                }
                                onKeyUp={handleKeyUp}
                                className={classes.smallTextField}
                            />
                        </div>
                        <div>
                            <div style={{fontSize: 24}}>VENC. ANO</div>
                            <TextField
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.myInput
                                }}
                                inputProps={{maxLength: 4}}
                                placeholder="0000"
                                variant="standard"
                                onBlur={(event) =>
                                    handleChange(
                                        'creditCard.expiryYear',
                                        event.target.value
                                    )
                                }
                                onKeyUp={handleKeyUp}
                                className={classes.smallTextField}
                            />
                        </div>
                        <div>
                            <div style={{fontSize: 24}}>CCV</div>
                            <TextField
                                InputProps={{
                                    disableUnderline: true,
                                    className: classes.myInput
                                }}
                                inputProps={{maxLength: 3}}
                                placeholder="***"
                                variant="standard"
                                onBlur={(event) =>
                                    handleChange(
                                        'creditCard.ccv',
                                        event.target.value
                                    )
                                }
                                onKeyUp={handleKeyUp}
                                className={classes.smallTextField}
                            />
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '-60px',
                        position: 'relative'
                    }}
                >
                    <div
                        style={{
                            width: '500px',
                            textAlign: 'start',
                            marginLeft: '20px'
                        }}
                    >
                        <span
                            style={{
                                color: '#e37039'
                            }}
                        >
                            *Para sua comodidade, enviaremos a Nota Fiscal
                            eletrônica para o email cadastrado acima.
                        </span>
                    </div>
                    <div
                        style={{
                            fontSize: 22,
                            // marginLeft: '170px',
                            marginTop: '-10px'
                        }}
                    >
                        <button
                            type="button"
                            style={{
                                backgroundColor: 'transparent',
                                border: 0
                            }}
                            onClick={() => submit(api?.currentUser?.user_id)}
                        >
                            <img
                                style={{
                                    width: '200px',
                                    cursor: 'pointer'
                                }}
                                src="./img/botaoF.png"
                                alt="finalizar"
                            />
                        </button>
                    </div>
                </div>
            </Card>
        </>
    );
}
