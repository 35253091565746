/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, {
    useContext,
    useState,
    useCallback,
    useEffect,
    useRef
} from 'react';

import firebase from 'firebase/compat/app';

import { toast, Toaster } from 'react-hot-toast';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Checkbox, FormControlLabel, Link as MuiLink } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import {Modal} from 'react-bootstrap';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import RecoverPassword from '@app/components/RecoverPassword';
import logo from 'src/img/OPTI_logo1_escuro.avif';
import Loading from '../../components/loading';
// import FlashContext from '../../contexts/FlashContext';

import routing from '../../routes/routing';
// import api from '../../services/api';
// import app from '../../services/firebase/base';
import api from '../../utils/api';
import app from '../../firebase/base';

import {
    useFormInput,
    useFullnameFormInput,
    useFormCheckbox
} from '../../components/formLogic/fields';
import { makeHelperText } from '../../components/formLogic/utils';
import validation from '../../components/formLogic/validation';

import './Login.css';

const useStyles = makeStyles((theme) => ({
    links: { marginTop: theme.spacing(2) },
    email: {
        borderStyle: 'none'
    },
    textField: {
        marginTop: -18,
        '& .MuiFormLabel-root': {
            top: '11px',
            left: '12px'
        },
        '& .MuiFormHelperText-root.Mui-error': {
            marginTop: '-10px'
        },
        '& .Mui-focused': {
            color: '#d36233'
        }
    },
    input: {
        '& label': {
            color: '#cac6c2'
        },
        '&:hover label': {
            fontWeight: 700
        },
        '& label.Mui-focused': {
            color: '#e37039'
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#e37039'
        }
    }
}));

export default function Login({ setHeaderValues, setOpen, setVendas, redirect }) {
    // const {search} = props.location;
    const emailLogin = useRef(null);
    const senhaLogin = useRef(null);
    const { condo } = useParams();
    const location = useLocation();

    const [forgotPassword, setForgotPassword] = useState(false);
    const [emailResPassword, setEmailResPassword] = useState();

    // const {notifyError, notifySuccess} = useContext(FlashContext);
    useEffect(() => {
        if (setHeaderValues) {
            setHeaderValues();
        }
        app.auth()
            .signOut()
            .then(() => {
                // Sign-out successful.
            })
            .catch((error) => {
                // An error happened.
            });
    }, []);

    const [visible, setVisible] = useState(false);

    const history = useHistory();
    const [errors] = useState({ username: [], password: [] });

    // const [idCondo, setIdCondo] = useState();
    // const remember = useFormCheckbox(api.rememberSession.checked);
    const remember = false;
    const email = useFormInput();
    const fullname = useFullnameFormInput();
    const password = useFormInput();
    const repeatPassword = useFormInput();
    const classes = useStyles();
    const [loadingMsg, setLoadingMsg] = useState('');
    // useTitle('ITCondo - Acesso');
    // const provider = new firebase.auth.GoogleAuthProvider();
    // provider.setCustomParameters({
    //     prompt: 'select_account'
    // });
    const labels = ['PETR4', 'VALE3', 'BOVA11'];

    /* const defaultGrid = () => {
        const list = [];
        labels.forEach((l) => {
            list.push({
                name: l,
                selected: {
                    tipo: {
                        call: true,
                        put: true
                    },
                    mod: {
                        america: true,
                        europe: true
                    },
                    moneyness: {
                        ATM: true,
                        ITM: true,
                        OTM: true
                    }
                }
            });
        });
        return {
            page: 'grid',
            actives: list,
            viewers: {
                tipo: true,
                mod: true,
                moneyness: true,
                strike: true,
                vi: false,
                ve: false,
                ultimo: true,
                negocios: true,
                volume: false,
                data: false,
                vol_imp: false,
                delta: false,
                gamma: false,
                theta: false,
                vega: false,
                p_teorico: true
            }
        };
    };

    const defaultMap = () => {
        const list = [];
        labels.forEach((l) => {
            list.push({
                name: l
            });
        });
        return {
            page: 'map',
            actives: list
        };
    }; */

    const [isPending, setIsPending] = useState(false);

    const handleChange = (e) => {
        setEmailResPassword(e.target.value);
        console.log(e.target.value);
    };

    const handleLogin = useCallback(
        async (event) => {
            setIsPending(true);
            setLoadingMsg('Verificando usuário e senha...');
            event.preventDefault();

            // eslint-disable-next-line no-shadow
            const { email, password } = event.target.elements;
            try {
                app.auth()
                    .signInWithEmailAndPassword(email.value, password.value)
                    .then((resultFire) => {
                        /* if (!resultFire.user.emailVerified) {
                            setLoadingMsg('');
                            setIsPending(false);
                            const config = {
                                handleCodeInApp: true,
                                url: `${process.env.FRONT_URL}/login/${condo}`
                            };
                            resultFire.user
                                .sendEmailVerification(config)
                                .then(() => {
                                    toast.error(
                                        'É necessário fazer a confirmação do e-mail cadastrado traves do link enviado novamente para o seu email.',
                                        { duration: 8000 }
                                    );
                                })
                                .catch(() => {
                                    toast.error(
                                        'É necessário fazer a confirmação do e-mail cadastrado traves do link enviado novamente para o seu email.',
                                        { duration: 8000 }
                                    );
                                });
                        } else { */
                        resultFire.user
                            .getIdToken(true)
                            .then((token) => {
                                setLoadingMsg('Obtendo dados do login...');
                                api.user
                                    .login({
                                        uid: resultFire.user.uid
                                    })
                                    .then(() => {
                                        toast.success(
                                            `Bem vindo, ${resultFire.user.displayName}`,
                                            {
                                                iconTheme: {
                                                    primary: '#d36233',
                                                    secondary: '#fff'
                                                }
                                            }
                                        );

                                        if (location.pathname === '/login')
                                            history.goBack();
                                        else if (redirect)
                                            history.push(routing.mercado);
                                        else if (
                                            location.pathname ===
                                            '/lp-30dias'
                                        ) {
                                            setOpen(false);
                                            setVendas(true);
                                        } else setOpen(false);
                                    })
                                    .catch((err) => {
                                        setLoadingMsg('');
                                        setIsPending(false);
                                        toast.error(err.message);
                                    });
                            })
                            .catch(() => {
                                setLoadingMsg('');
                                setIsPending(false);
                            });

                        resultFire.user
                            .sendEmailVerification(config)
                            .then(() => {
                                toast.error(
                                    'É necessário fazer a confirmação do e-mail cadastrado traves do link enviado novamente para o seu email.',
                                    { duration: 8000 }
                                );
                            })
                            .catch(() => {
                                toast.error(
                                    'É necessário fazer a confirmação do e-mail cadastrado traves do link enviado novamente para o seu email.',
                                    { duration: 8000 }
                                );
                            });
                        // }
                    })
                    .catch((error) => {
                        setIsPending(false);
                        if (error.code === 'auth/wrong-password') {
                            toast.error('Senha inválida.');
                        } else if (error.code === 'auth/user-not-found') {
                            toast.error('Usuário inválido.');
                        } else {
                            toast.error(error.code);
                        }
                    });
            } catch (err) {
                setIsPending(false);
                alert(err);
            }
        },
        // eslint-disable-next-line
        [history]
    );

    const handleGoogleLogin = useCallback(
        async () => {
            // setIsPending(true);
            // setLoadingMsg('Verificando usuário e senha...');
            // event.preventDefault();
            // eslint-disable-next-line no-shadow
            // const {email, password} = event.target.elements;
            try {
                app.auth()
                    .signInWithPopup(new firebase.auth.GoogleAuthProvider())
                    .then((result) => {
                        setLoadingMsg('Entrando no OPTI....');
                        setIsPending(true);
                        /** @type {firebase.auth.OAuthCredential} */
                        const { credential } = result;
                        console.log(result);
                        const token = credential.accessToken;
                        const { user } = result;
                        api.user
                            .login({
                                uid: user.uid
                            })
                            .then((res) => {
                                if (res?.response?.status === 404) {
                                    // setLoadingMsg(
                                    //     `É a sua primeira vez  ${user.displayName}? Estamos a sua conta do google ao OPTI...`
                                    // );
                                    api.user
                                        .createByGoogle({
                                            uid: user.uid,
                                            name: user.displayName,
                                            email: user.email
                                        })
                                        .then(() => {
                                            setIsPending(false);
                                            Animation2();
                                            toast.success(
                                                `Bem vindo, ${user.displayName}`,
                                                {
                                                    iconTheme: {
                                                        primary: '#d36233',
                                                        secondary: '#fff'
                                                    }
                                                }
                                            );

                                            if (location.pathname === '/login')
                                                history.goBack();
                                            else if (redirect)
                                                history.push(routing.mercado);
                                            else if (
                                                location.pathname ===
                                                '/lp-30dias'
                                            ) {
                                                setOpen(false);
                                                setVendas(true);
                                            } else setOpen(false);
                                        })
                                        .catch((err) => {
                                            setLoadingMsg('');
                                            setIsPending(false);
                                            toast.error(err.message);
                                        });
                                } else {
                                    setIsPending(false);
                                    toast.success(
                                        `Bem vindo, ${user.displayName}`,
                                        {
                                            iconTheme: {
                                                primary: '#d36233',
                                                secondary: '#fff'
                                            }
                                        }
                                    );

                                    if (location.pathname === '/login')
                                        history.goBack();
                                    else if (redirect)
                                        history.push(routing.mercado);
                                    else if (
                                        location.pathname === '/lp-30dias'
                                    ) {
                                        setOpen(false);
                                        setVendas(true);
                                    } else setOpen(false);
                                }

                                // history.push(routing.money); history.goBack()
                            })
                            .catch((err) => {
                                setLoadingMsg('');
                                setIsPending(false);
                                toast.error(err.message);
                            });
                    })
                    .catch((error) => {
                        // Handle Errors here.
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        // The email of the user's account used.
                        const { email } = error;
                        // The firebase.auth.AuthCredential type that was used.
                        const { credential } = error;
                        // ...
                    });
            } catch (err) {
                setIsPending(false);
                alert(err);
            }
        },
        // eslint-disable-next-line
        [history]
    );

    const newGoogleAccount = useCallback(
        async (event) => {
            // setIsPending(true);
            // setLoadingMsg('Verificando usuário e senha...');
            event.preventDefault();
            // eslint-disable-next-line no-shadow
            // const {email, password} = event.target.elements;
            try {
                app.auth()
                    .signInWithPopup(new firebase.auth.GoogleAuthProvider())
                    .then((result) => {
                        setIsPending(true);
                        /** @type {firebase.auth.OAuthCredential} */
                        const { credential } = result;

                        // This gives you a Google Access Token. You can use it to access the Google API.
                        const token = credential.accessToken;
                        // The signed-in user info.
                        const { user } = result;
                        setLoadingMsg(
                            `Estamos terminando seu cadastro ${user.displayName}...`
                        );
                        api.user
                            .createByGoogle({
                                uid: user.uid,
                                name: user.displayName,
                                email: user.email
                            })
                            .then(() => {
                                setIsPending(false);
                                Animation2();
                                toast.success(
                                    `Bem vindo, ${user.displayName}`,
                                    {
                                        iconTheme: {
                                            primary: '#d36233',
                                            secondary: '#fff'
                                        }
                                    }
                                );
                                const back = window.location.origin;

                                if (location.pathname === '/login')
                                    history.goBack();
                                else if (redirect)
                                    history.push(routing.mercado);
                                else if (location.pathname === '/lp-30dias') {
                                    setOpen(false);
                                    setVendas(true);
                                } else setOpen(false);
                            })
                            .catch((err) => {
                                setLoadingMsg('');
                                setIsPending(false);
                                toast.error(err.message);
                            });
                    })
                    .catch((error) => {
                        // Handle Errors here.
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        // The email of the user's account used.
                        const { email } = error;
                        // The firebase.auth.AuthCredential type that was used.
                        const { credential } = error;
                        // ...
                    });
            } catch (err) {
                setIsPending(false);
                alert(err);
            }
        },
        // eslint-disable-next-line
        [history]
    );

    const createAccount = () => {
        setIsPending(true);
        setLoadingMsg('Criando conta...');
        try {
            app.auth()
                .createUserWithEmailAndPassword(email.value, password.value)
                .then((resFire) => {
                    resFire.user
                        .updateProfile({ displayName: fullname.value })
                        .then(() => {
                            api.user
                                .create({
                                    uid: resFire.user.uid,
                                    name: fullname.value,
                                    email: email.value
                                })
                                .then(() => {
                                    setLoadingMsg(
                                        'Enviando email de verificação...'
                                    );
                                    // console.log(process.env.REACT_FRONT_URL);
                                    const config = {
                                        handleCodeInApp: true,
                                        url: 'https://www.useopti.com.br/login'
                                    };
                                    // url: `${process.env.REACT_FRONT_URL}/login}`
                                    resFire.user
                                        .sendEmailVerification(config)
                                        .then(() => {
                                            setLoadingMsg('');
                                            setIsPending(false);
                                            Animation2();
                                            toast.success(
                                                'Conta cadastrada com sucesso! A confirmação será enviada para o seu endereço de e-mail em breve.',
                                                {
                                                    position: 'top-center',
                                                    autoClose: false,
                                                    hideProgressBar: false,
                                                    closeOnClick: true,
                                                    iconTheme: {
                                                        primary: '#d36233',
                                                        secondary: '#fff'
                                                    }
                                                }
                                            );
                                        })
                                        .catch((err) => {
                                            setLoadingMsg('');
                                            setIsPending(false);
                                            toast.error(err.messag);
                                        });
                                })
                                .catch((err) => {
                                    setLoadingMsg('');
                                    setIsPending(false);
                                    toast.error(err.message);
                                });
                        })
                        .catch(() => {
                            setLoadingMsg('');
                            setIsPending(false);
                        });
                })
                .catch((err) => {
                    setLoadingMsg('');
                    setIsPending(false);
                    if (err.code === 'auth/email-already-in-use') {
                        toast.error('Este email já está em uso.', {
                            position: 'top-center',
                            autoClose: false,
                            hideProgressBar: false,
                            closeOnClick: true
                        });
                    } else {
                        toast.error(err.message, {
                            position: 'top-center',
                            autoClose: false,
                            hideProgressBar: false,
                            closeOnClick: true
                        });
                    }
                });
        } catch (err) {
            setLoadingMsg('');
            setIsPending(false);
            toast.error(err.message);
        }
    };

    const resetPassword = (email) => {
        console.log(email);
        setIsPending(false);
        setLoadingMsg('');
        const actionCodeSettings = {
            url: window.location.href,
            handleCodeInApp: true
        };
        try {
            app.auth()
                .sendPasswordResetEmail(email, actionCodeSettings)
                .then((resFire) => {
                    console.log(resFire);
                })
                .catch((error) => console.log(error));
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            return value === password.value;
        });

        return () => {
            ValidatorForm.removeValidationRule('isPasswordMatch');
        };
    }, [password.value]);

    const Animation1 = () => {
        if (window.innerWidth > 1300)
            document
                .getElementById('containerlogin')
                .classList.add('right-panel-active');
        else
            document
                .getElementById('containerloginMobile')
                .classList.add('right-panel-active');
    };

    const Animation2 = () => {
        if (window.innerWidth > 1300)
            document
                .getElementById('containerlogin')
                .classList.remove('right-panel-active');
        else
            document
                .getElementById('containerloginMobile')
                .classList.remove('right-panel-active');
    };

    const myVPort = () => {
        if (location.pathname === '/login') {
            return '10%';
        }
        return '0';
    };

    return (
        <>
            <Loading open={isPending} msg={loadingMsg} />
            <div>
                <div
                    className="base"
                    style={{ marginTop: myVPort(), fontFamily: 'Roboto' }}
                >
                    <div
                        className={
                            window.screen.width > 1300
                                ? 'containerlogin'
                                : 'containerLoginMobile'
                        }
                        id={
                            window.screen.width > 1300
                                ? 'containerlogin'
                                : 'containerLoginMobile'
                        }
                    >
                        <div className="form-container sign-up-container">
                            <ValidatorForm onSubmit={createAccount}>
                                {/* <input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    ref={emailLogin}
                                />
                                <input
                                    type="password"
                                    name="password"
                                    placeholder="Nome completo"
                                    ref={senhaLogin}
                                />
                                <input
                                    type="password"
                                    name="password"
                                    placeholder="Senha"
                                    ref={senhaLogin}
                                />
                                <input
                                    type="password"
                                    name="password"
                                    placeholder="Confirme a senha"
                                    ref={senhaLogin}
                                /> */}
                                <TextValidator
                                    className={classes.textField}
                                    InputProps={{
                                        disableUnderline: true
                                        // style: {color: '#e37039'}
                                    }}
                                    name="email"
                                    label="Endereço de e-mail *"
                                    autoFocus
                                    autoComplete="email"
                                    {...email}
                                    {...validation.requiredEmail}
                                    error={
                                        errors.email && errors.email.length > 0
                                    }
                                    helperText={makeHelperText(errors.email)}
                                    fullWidth
                                    margin="none"
                                />
                                <TextValidator
                                    className={classes.textField}
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    autoFocus
                                    name="fullname"
                                    label="Nome completo *"
                                    autoComplete="name"
                                    {...fullname}
                                    {...validation.requiredFullname}
                                    error={
                                        errors.fullname &&
                                        errors.fullname.length > 0
                                    }
                                    helperText={makeHelperText(errors.fullname)}
                                    fullWidth
                                    margin="none"
                                />
                                <TextValidator
                                    className={classes.textField}
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    name="password"
                                    type="password"
                                    label="Senha de acesso *"
                                    autoComplete="new-password"
                                    {...password}
                                    // {...validation.requiredPassword}
                                    error={
                                        errors.password &&
                                        errors.password.length > 0
                                    }
                                    helperText={makeHelperText(errors.password)}
                                    margin="none"
                                />
                                <TextValidator
                                    className={classes.textField}
                                    InputProps={{
                                        disableUnderline: true
                                    }}
                                    name="repeatPassword"
                                    type="password"
                                    label="Confirmar senha *"
                                    autoComplete="off"
                                    {...repeatPassword}
                                    validators={[
                                        'isPasswordMatch',
                                        ...validation.required.validators
                                    ]}
                                    errorMessages={[
                                        'Senha não coincide',
                                        ...validation.required.errorMessages
                                    ]}
                                    fullWidth
                                    margin="none"
                                />
                                <button type="submit">Criar</button>
                                <h2>ou</h2>
                                <img
                                    src="/img/Gmail-Logo.png"
                                    alt="Logo"
                                    onClick={newGoogleAccount}
                                    style={{
                                        height: 40,
                                        cursor: 'pointer'
                                        // marginBottom: -10,
                                        // marginTop: -10
                                        // marginLeft: 10
                                    }}
                                />
                            </ValidatorForm>
                        </div>
                        <div className="form-container sign-in-container">
                            <form onSubmit={handleLogin}>
                                <img
                                    src={logo}
                                    alt="Logo"
                                    style={{
                                        height: 70,
                                        marginBottom: 5,
                                        marginTop: 5

                                        // marginLeft: 10
                                    }}
                                />
                                {/* <h1>LOGIN</h1> */}
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    ref={emailLogin}
                                />
                                <input
                                    type="password"
                                    name="password"
                                    placeholder="Senha"
                                    ref={senhaLogin}
                                />
                                {/* <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="remember"
                                            value="remember"
                                            {...remember}
                                            color="primary"
                                        />
                                    }
                                    label="Lembrar estas credenciais"
                                /> */}
                                <button type="submit" style={{ marginTop: 20 }}>
                                    Entrar
                                </button>
                                <img
                                    src="/img/Gmail-Logo.png"
                                    alt="Logo"
                                    onClick={handleGoogleLogin}
                                    style={{
                                        height: 40,
                                        cursor: 'pointer'
                                        // marginBottom: -10,
                                        // marginTop: -10
                                        // marginLeft: 10
                                    }}
                                />
                                {/* <MuiLink
                                    title="Recuperação de senha"
                                    component={Link}
                                    to={routing.passwordredefinition_request}
                                    variant="body1"
                                    noWrap
                                >
                                    Esqueceu sua senha?
                                </MuiLink> */}
                                <div
                                    aria-hidden="true"
                                    onClick={() => setForgotPassword(true)}
                                    style={{
                                        cursor: 'pointer',
                                        color: '#e37039',
                                        fontWeight: 700
                                        // textDecorationLine: 'underline'
                                    }}
                                >
                                    Esqueceu sua senha?
                                </div>
                            </form>
                        </div>
                        <div className="overlay-container">
                            <div className="overlay">
                                <div className="overlay-panel overlay-left">
                                    <h1>Já possui uma conta?</h1>
                                    <p>Faça login com suas credenciais.</p>
                                    <button
                                        type="button"
                                        className="ghost"
                                        id="signIn"
                                        onClick={Animation2}
                                    >
                                        Entrar
                                    </button>
                                </div>
                                <div className="overlay-panel overlay-right">
                                    <h1>Ainda não possui uma conta?</h1>
                                    <p>Crie uma agora clicando abaixo.</p>
                                    <button
                                        type="button"
                                        className="ghost"
                                        id="signUp"
                                        onClick={Animation1}
                                    >
                                        Criar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RecoverPassword
                setIsPending={setIsPending}
                setLoadingMsg={setLoadingMsg}
                forgotPassword={forgotPassword}
                setForgotPassword={setForgotPassword}
            />
            <Toaster />
        </>
    );
}
