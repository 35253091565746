import React, {useState, useEffect} from 'react';
import moment from 'moment';
import {makeStyles, createStyles} from '@material-ui/core/styles';

import {cleanNumber} from '../../../utils/myaux';

const useStyles = makeStyles(() =>
    createStyles({
        myInput: {
            marginLeft: -15,
            color: '#e37039',
            fontSize: 12,
            padding: 0,
            margin: 0,
            border: 'none',
            maxWidth: 50,
            '&:focus': {
                outline: 'none'
            },
            textAlign: 'center'

            // fontWeight: 'bold'
        }
    })
);

export default function TotalMont({strategy, desmCot, setDesmCot, status}) {
    const classes = useStyles();
    const [totalDesmont, setTotalDesmont] = useState(0);
    const [date, setDate] = useState(new Date());
    const [days, setDays] = useState(0);

    useEffect(() => {
        if (strategy) {
            let totDes = 0;
            let myDate = new Date(strategy[0]?.dataDesmont);
            let initialDate = new Date(strategy[0]?.dataMont);
            strategy.forEach((s) => {
                totDes += s.totalDesmont ? cleanNumber(s.totalDesmont) : 0;
                myDate = moment(s.dataDesmont).isAfter(myDate)
                    ? s.dataDesmont
                    : myDate;

                initialDate = moment(s?.dataMont).isBefore(initialDate)
                    ? s.dataMont
                    : initialDate;
            });
            if (status) {
                myDate = new Date();
            }
            const tmp = moment(initialDate);
            const sumDays = moment(myDate);
            const dif = Math.ceil(sumDays.diff(tmp, 'days', true));
            setTotalDesmont(totDes);
            setDate(myDate);
            setDays(dif);
        }
    }, [strategy]);

    const mCot = (v) => {
        // const t = v.replace('R$ ', '');
        // setDesmCot(t);
        const t = v.replace('R$', '');
        const t1 = t.replace('R', '');
        setDesmCot(t1.trim());
    };

    return (
        <div
            style={{
                display: 'flex',
                // marginTop: '30px',
                fontSize: '12px',
                justifyContent: 'start',
                borderTop: '1px solid #c5c5c5',
                padding: 0,
                fontFamily: 'Roboto'
            }}
        >
            <div
                style={{
                    borderRight: '2px solid #c5c5c5',
                    textAlign: 'center',
                    minWidth: '85px'
                }}
            >
                <span
                    style={{
                        color: '#e37039',
                        fontWeight: 'bold',
                        display: 'inline-block',
                        margin: '0px 10px 0px 5px'
                    }}
                >
                    {moment(date).format('DD/MM/YYYY')}
                </span>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <p style={{margin: '0px 0px 0px 0px'}}>DATA</p>
                    <span
                        style={{cursor: 'pointer'}}
                        data-tip="Data de desmontagem da carteira, por padrão consideramos a data mais atual da desmontagem."
                    >
                        <ion-icon
                            style={{
                                marginBottom: 2,
                                fontSize: 12,
                                color: '#d36233',
                                pointerEvents: 'none'
                            }}
                            name="help-circle-outline"
                        />
                    </span>
                </div>
            </div>
            <div
                style={{
                    borderRight: '2px solid #c5c5c5',
                    textAlign: 'center',
                    minWidth: '85px'
                }}
            >
                <span
                    style={{
                        color: '#e37039',
                        fontWeight: 'bold',
                        display: 'inline-block',
                        margin: '0px 10px 0px 5px'
                    }}
                >
                    {days >= 0 ? days : 0} dias
                </span>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <p style={{margin: '0px 0px 0px 0px'}}>TEMPO</p>
                    <span
                        style={{cursor: 'pointer'}}
                        data-tip="Tempo de duração da estrutura registrada, apresentado em dias corridos."
                    >
                        <ion-icon
                            style={{
                                marginBottom: 2,
                                fontSize: 12,
                                color: '#d36233',
                                pointerEvents: 'none'
                            }}
                            name="help-circle-outline"
                        />
                    </span>
                </div>
            </div>
            <div
                style={{
                    borderRight: '2px solid #c5c5c5',
                    textAlign: 'center',
                    minWidth: '85px'
                }}
            >
                {/* <span
                    style={{
                        color: '#e37039',
                        fontWeight: 'bold',
                        display: 'inline-block',
                        margin: '0px 10px 0px 5px'
                    }}
                >
                    R$15,45
                </span> */}
                <input
                    className={classes.myInput}
                    value={`R$${desmCot.replace(',', '.')}`}
                    onChange={(e) => mCot(e.target.value)}
                />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <p style={{margin: '0px 0px 0px 0px'}}>COTAÇÃO</p>
                    <span
                        style={{cursor: 'pointer'}}
                        data-tip="Cotação do ativo objeto no momento da desmontagem da carteira."
                    >
                        <ion-icon
                            style={{
                                marginBottom: 2,
                                fontSize: 12,
                                color: '#d36233',
                                pointerEvents: 'none'
                            }}
                            name="help-circle-outline"
                        />
                    </span>
                </div>
            </div>
            <div
                style={{
                    borderRight: '2px solid #c5c5c5',
                    textAlign: 'center',
                    minWidth: '85px'
                }}
            >
                <span
                    style={{
                        color: '#e37039',
                        fontWeight: 'bold',
                        display: 'inline-block',
                        margin: '0px 10px 0px 5px',
                        textAlign: 'center'
                    }}
                >
                    {new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                    })
                        .format(totalDesmont)
                        .replace(',', '.')
                        .replace('-', '')}
                </span>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <p style={{margin: '0px 0px 0px 0px'}}>
                        {Number(totalDesmont) >= 0 ? 'DÉBITO' : 'CRÉDITO'}
                    </p>
                    <span
                        style={{cursor: 'pointer'}}
                        data-tip="Crédito - a estrutura registrada resultou em recebimento financeiro. Débito - para montar a estrutura foi necessário desembolso financeiro."
                    >
                        <ion-icon
                            style={{
                                marginBottom: 2,
                                fontSize: 12,
                                color: '#d36233',
                                pointerEvents: 'none'
                            }}
                            name="help-circle-outline"
                        />
                    </span>
                </div>
            </div>
            {/* <div
                style={{
                    borderRight: '2px solid #c5c5c5',
                    textAlign: 'center',
                    minWidth: '85px'
                }}
            >
                <span
                    style={{
                        color: '#e37039',
                        fontWeight: 'bold',
                        display: 'inline-block',
                        margin: '0px 10px 0px 5px',
                        textAlign: 'center'
                    }}
                >
                    120%
                </span>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <p style={{margin: '0px 0px 0px 0px'}}>ROI</p>
                    <ion-icon
                        data-tip="Que porra é essa Thiago?"
                        style={{
                            marginBottom: 5,
                            fontSize: 11,
                            color: '#d36233',
                            cursor: 'pointer'
                        }}
                        name="help-circle-outline"
                    />
                </div>
            </div>
            <div style={{textAlign: 'center', minWidth: '85px'}}>
                <span
                    style={{
                        color: '#e37039',
                        fontWeight: 'bold',
                        display: 'inline-block',
                        margin: '0px 10px 0px 5px',
                        textAlign: 'center'
                    }}
                >
                    R$4,50
                </span>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <p style={{margin: '0px 0px 0px 0px'}}>IMPOSTO</p>
                    <ion-icon
                        data-tip="Que porra é essa Thiago?"
                        style={{
                            marginBottom: 5,
                            fontSize: 11,
                            color: '#d36233',
                            cursor: 'pointer'
                        }}
                        name="help-circle-outline"
                    />
                </div>
            </div> */}
        </div>
    );
}
