const citiesCentro = [
    {state: 'MS', value: 'Água Clara'},
    {state: 'MS', value: 'Alcinópolis'},
    {state: 'MS', value: 'Amambai'},
    {state: 'MS', value: 'Anastácio'},
    {state: 'MS', value: 'Anaurilândia'},
    {state: 'MS', value: 'Angélica'},
    {state: 'MS', value: 'Antônio João'},
    {state: 'MS', value: 'Aparecida do Taboado'},
    {state: 'MS', value: 'Aquidauana'},
    {state: 'MS', value: 'Aral Moreira'},
    {state: 'MS', value: 'Bandeirantes'},
    {state: 'MS', value: 'Bataguassu'},
    {state: 'MS', value: 'Batayporã'},
    {state: 'MS', value: 'Bela Vista'},
    {state: 'MS', value: 'Bodoquena'},
    {state: 'MS', value: 'Bonito'},
    {state: 'MS', value: 'Brasilândia'},
    {state: 'MS', value: 'Caarapó'},
    {state: 'MS', value: 'Camapuã'},
    {state: 'MS', value: 'Campo Grande'},
    {state: 'MS', value: 'Caracol'},
    {state: 'MS', value: 'Cassilândia'},
    {state: 'MS', value: 'Chapadão do Sul'},
    {state: 'MS', value: 'Corguinho'},
    {state: 'MS', value: 'Coronel Sapucaia'},
    {state: 'MS', value: 'Corumbá'},
    {state: 'MS', value: 'Costa Rica'},
    {state: 'MS', value: 'Coxim'},
    {state: 'MS', value: 'Deodápolis'},
    {state: 'MS', value: 'Dois Irmãos do Buriti'},
    {state: 'MS', value: 'Douradina'},
    {state: 'MS', value: 'Dourados'},
    {state: 'MS', value: 'Eldorado'},
    {state: 'MS', value: 'Fátima do Sul'},
    {state: 'MS', value: 'Figueirão'},
    {state: 'MS', value: 'Glória de Dourados'},
    {state: 'MS', value: 'Guia Lopes da Laguna'},
    {state: 'MS', value: 'Iguatemi'},
    {state: 'MS', value: 'Inocência'},
    {state: 'MS', value: 'Itaporã'},
    {state: 'MS', value: 'Itaquiraí'},
    {state: 'MS', value: 'Ivinhema'},
    {state: 'MS', value: 'Japorã'},
    {state: 'MS', value: 'Jaraguari'},
    {state: 'MS', value: 'Jardim'},
    {state: 'MS', value: 'Jateí'},
    {state: 'MS', value: 'Juti'},
    {state: 'MS', value: 'Ladário'},
    {state: 'MS', value: 'Laguna Carapã'},
    {state: 'MS', value: 'Maracaju'},
    {state: 'MS', value: 'Miranda'},
    {state: 'MS', value: 'Mundo Novo'},
    {state: 'MS', value: 'Naviraí'},
    {state: 'MS', value: 'Nioaque'},
    {state: 'MS', value: 'Nova Alvorada do Sul'},
    {state: 'MS', value: 'Nova Andradina'},
    {state: 'MS', value: 'Novo Horizonte do Sul'},
    {state: 'MS', value: 'Paraíso das Águas'},
    {state: 'MS', value: 'Paranaíba'},
    {state: 'MS', value: 'Paranhos'},
    {state: 'MS', value: 'Pedro Gomes'},
    {state: 'MS', value: 'Ponta Porã'},
    {state: 'MS', value: 'Porto Murtinho'},
    {state: 'MS', value: 'Ribas do Rio Pardo'},
    {state: 'MS', value: 'Rio Brilhante'},
    {state: 'MS', value: 'Rio Negro'},
    {state: 'MS', value: 'Rio Verde'},
    {state: 'MS', value: 'Rochedo'},
    {state: 'MS', value: 'Santa Rita do Pardo'},
    {state: 'MS', value: 'São Gabriel do Oeste'},
    {state: 'MS', value: 'Selvíria'},
    {state: 'MS', value: 'Sete Quedas'},
    {state: 'MS', value: 'Sidrolândia'},
    {state: 'MS', value: 'Sonora'},
    {state: 'MS', value: 'Tacuru'},
    {state: 'MS', value: 'Taquarussu'},
    {state: 'MS', value: 'Terenos'},
    {state: 'MS', value: 'Três Lagoas'},
    {state: 'MS', value: 'Vicentina'},
    {state: 'MT', value: 'Acorizal'},
    {state: 'MT', value: 'Água Boa'},
    {state: 'MT', value: 'Alta Floresta'},
    {state: 'MT', value: 'Alto Araguaia'},
    {state: 'MT', value: 'Alto Boa Vista'},
    {state: 'MT', value: 'Alto Garças'},
    {state: 'MT', value: 'Alto Paraguai'},
    {state: 'MT', value: 'Alto Taquari'},
    {state: 'MT', value: 'Apiacás'},
    {state: 'MT', value: 'Araguaiana'},
    {state: 'MT', value: 'Araguainha'},
    {state: 'MT', value: 'Araputanga'},
    {state: 'MT', value: 'Arenápolis'},
    {state: 'MT', value: 'Aripuanã'},
    {state: 'MT', value: 'Barão de Melgaço'},
    {state: 'MT', value: 'Barra do Bugres'},
    {state: 'MT', value: 'Barra do Garças'},
    {state: 'MT', value: 'Bom Jesus do Araguaia'},
    {state: 'MT', value: 'Brasnorte'},
    {state: 'MT', value: 'Cáceres'},
    {state: 'MT', value: 'Campinápolis'},
    {state: 'MT', value: 'Campo Novo do Parecis'},
    {state: 'MT', value: 'Campo Verde'},
    {state: 'MT', value: 'Campos de Júlio'},
    {state: 'MT', value: 'Canabrava do Norte'},
    {state: 'MT', value: 'Canarana'},
    {state: 'MT', value: 'Carlinda'},
    {state: 'MT', value: 'Castanheira'},
    {state: 'MT', value: 'Chapada dos Guimarães'},
    {state: 'MT', value: 'Cláudia'},
    {state: 'MT', value: 'Cocalinho'},
    {state: 'MT', value: 'Colíder'},
    {state: 'MT', value: 'Colniza'},
    {state: 'MT', value: 'Comodoro'},
    {state: 'MT', value: 'Confresa'},
    {state: 'MT', value: "Conquista d'Oeste"},
    {state: 'MT', value: 'Cotriguaçu'},
    {state: 'MT', value: 'Cuiabá'},
    {state: 'MT', value: 'Curvelândia'},
    {state: 'MT', value: 'Denise'},
    {state: 'MT', value: 'Diamantino'},
    {state: 'MT', value: 'Dom Aquino'},
    {state: 'MT', value: 'Feliz Natal'},
    {state: 'MT', value: "Figueirópolis d'Oeste"},
    {state: 'MT', value: 'Gaúcha do Norte'},
    {state: 'MT', value: 'General Carneiro'},
    {state: 'MT', value: "Glória d'Oeste"},
    {state: 'MT', value: 'Guarantã do Norte'},
    {state: 'MT', value: 'Guiratinga'},
    {state: 'MT', value: 'Indiavaí'},
    {state: 'MT', value: 'Ipiranga do Norte'},
    {state: 'MT', value: 'Itanhangá'},
    {state: 'MT', value: 'Itaúba'},
    {state: 'MT', value: 'Itiquira'},
    {state: 'MT', value: 'Jaciara'},
    {state: 'MT', value: 'Jangada'},
    {state: 'MT', value: 'Jauru'},
    {state: 'MT', value: 'Juara'},
    {state: 'MT', value: 'Juína'},
    {state: 'MT', value: 'Juruena'},
    {state: 'MT', value: 'Juscimeira'},
    {state: 'MT', value: "Lambari d'Oeste"},
    {state: 'MT', value: 'Lucas do Rio Verde'},
    {state: 'MT', value: 'Luciara'},
    {state: 'MT', value: 'Marcelândia'},
    {state: 'MT', value: 'Matupá'},
    {state: 'MT', value: "Mirassol d'Oeste"},
    {state: 'MT', value: 'Nobres'},
    {state: 'MT', value: 'Nortelândia'},
    {state: 'MT', value: 'Nossa Senhora do Livramento'},
    {state: 'MT', value: 'Nova Bandeirantes'},
    {state: 'MT', value: 'Nova Brasilândia'},
    {state: 'MT', value: 'Nova Canaã do Norte'},
    {state: 'MT', value: 'Nova Guarita'},
    {state: 'MT', value: 'Nova Lacerda'},
    {state: 'MT', value: 'Nova Marilândia'},
    {state: 'MT', value: 'Nova Maringá'},
    {state: 'MT', value: 'Nova Monte Verde'},
    {state: 'MT', value: 'Nova Mutum'},
    {state: 'MT', value: 'Nova Nazaré'},
    {state: 'MT', value: 'Nova Olímpia'},
    {state: 'MT', value: 'Nova Santa Helena'},
    {state: 'MT', value: 'Nova Ubiratã'},
    {state: 'MT', value: 'Nova Xavantina'},
    {state: 'MT', value: 'Novo Horizonte do Norte'},
    {state: 'MT', value: 'Novo Mundo'},
    {state: 'MT', value: 'Novo Santo Antônio'},
    {state: 'MT', value: 'Novo São Joaquim'},
    {state: 'MT', value: 'Paranaíta'},
    {state: 'MT', value: 'Paranatinga'},
    {state: 'MT', value: 'Pedra Preta'},
    {state: 'MT', value: 'Peixoto de Azevedo'},
    {state: 'MT', value: 'Planalto da Serra'},
    {state: 'MT', value: 'Poconé'},
    {state: 'MT', value: 'Pontal do Araguaia'},
    {state: 'MT', value: 'Ponte Branca'},
    {state: 'MT', value: 'Pontes e Lacerda'},
    {state: 'MT', value: 'Porto Alegre do Norte'},
    {state: 'MT', value: 'Porto dos Gaúchos'},
    {state: 'MT', value: 'Porto Esperidião'},
    {state: 'MT', value: 'Porto Estrela'},
    {state: 'MT', value: 'Poxoréu'},
    {state: 'MT', value: 'Primavera do Leste'},
    {state: 'MT', value: 'Querência'},
    {state: 'MT', value: 'Reserva do Cabaçal'},
    {state: 'MT', value: 'Ribeirão Cascalheira'},
    {state: 'MT', value: 'Ribeirãozinho'},
    {state: 'MT', value: 'Rio Branco'},
    {state: 'MT', value: 'Rondolândia'},
    {state: 'MT', value: 'Rondonópolis'},
    {state: 'MT', value: 'Rosário Oeste'},
    {state: 'MT', value: 'Salto do Céu'},
    {state: 'MT', value: 'Santa Carmem'},
    {state: 'MT', value: 'Santa Cruz do Xingu'},
    {state: 'MT', value: 'Santa Rita do Trivelato'},
    {state: 'MT', value: 'Santa Terezinha'},
    {state: 'MT', value: 'Santo Afonso'},
    {state: 'MT', value: 'Santo Antônio do Leste'},
    {state: 'MT', value: 'Santo Antônio de Leverger'},
    {state: 'MT', value: 'São Félix do Araguaia'},
    {state: 'MT', value: 'São José do Povo'},
    {state: 'MT', value: 'São José do Rio Claro'},
    {state: 'MT', value: 'São José do Xingu'},
    {state: 'MT', value: 'São José dos Quatro Marcos'},
    {state: 'MT', value: 'São Pedro da Cipa'},
    {state: 'MT', value: 'Sapezal'},
    {state: 'MT', value: 'Serra Nova Dourada'},
    {state: 'MT', value: 'Sinop'},
    {state: 'MT', value: 'Sorriso'},
    {state: 'MT', value: 'Tabaporã'},
    {state: 'MT', value: 'Tangará da Serra'},
    {state: 'MT', value: 'Tapurah'},
    {state: 'MT', value: 'Terra Nova do Norte'},
    {state: 'MT', value: 'Tesouro'},
    {state: 'MT', value: 'Torixoréu'},
    {state: 'MT', value: 'União do Sul'},
    {state: 'MT', value: 'Vale de São Domingos'},
    {state: 'MT', value: 'Várzea Grande'},
    {state: 'MT', value: 'Vera'},
    {state: 'MT', value: 'Vila Bela da Santíssima Trindade'},
    {state: 'MT', value: 'Vila Rica'},
    {state: 'GO', value: 'Niquelândia'},
    {state: 'GO', value: 'Mineiros'},
    {state: 'GO', value: 'Caiapônia'},
    {state: 'GO', value: 'Rio Verde'},
    {state: 'GO', value: 'Nova Crixás'},
    {state: 'GO', value: 'Jataí'},
    {state: 'GO', value: 'Cavalcante'},
    {state: 'GO', value: 'Cristalina'},
    {state: 'GO', value: 'São Miguel do Araguaia'},
    {state: 'GO', value: 'Formosa'},
    {state: 'GO', value: 'Serranópolis'},
    {state: 'GO', value: 'Porangatu'},
    {state: 'GO', value: 'Crixás'},
    {state: 'GO', value: 'Ipameri'},
    {state: 'GO', value: 'Jussara'},
    {state: 'GO', value: 'Luziânia'},
    {state: 'GO', value: 'Catalão'},
    {state: 'GO', value: 'Quirinópolis'},
    {state: 'GO', value: 'Paraúna'},
    {state: 'GO', value: 'Flores de Goiás'},
    {state: 'GO', value: 'Itarumã'},
    {state: 'GO', value: 'São Domingos'},
    {state: 'GO', value: "São João d'Aliança"},
    {state: 'GO', value: 'Doverlândia'},
    {state: 'GO', value: 'Padre Bernardo'},
    {state: 'GO', value: 'Monte Alegre de Goiás'},
    {state: 'GO', value: 'Goiás'},
    {state: 'GO', value: 'Aruanã'},
    {state: 'GO', value: 'Montes Claros de Goiás'},
    {state: 'GO', value: 'Aporé'},
    {state: 'GO', value: 'Minaçu'},
    {state: 'GO', value: 'Morrinhos'},
    {state: 'GO', value: 'Alto Paraíso de Goiás'},
    {state: 'GO', value: 'Planaltina'},
    {state: 'GO', value: 'Goiatuba'},
    {state: 'GO', value: 'Campo Alegre de Goiás'},
    {state: 'GO', value: 'Itumbiara'},
    {state: 'GO', value: 'Piracanjuba'},
    {state: 'GO', value: 'Silvânia'},
    {state: 'GO', value: 'Caçu'},
    {state: 'GO', value: 'Pirenópolis'},
    {state: 'GO', value: 'Araguapaz'},
    {state: 'GO', value: 'Chapadão do Céu'},
    {state: 'GO', value: 'Vila Propício'},
    {state: 'GO', value: 'Mundo Novo'},
    {state: 'GO', value: 'Uruaçu'},
    {state: 'GO', value: 'Nova Roma'},
    {state: 'GO', value: 'Itajá'},
    {state: 'GO', value: 'Posse'},
    {state: 'GO', value: 'Piranhas'},
    {state: 'GO', value: 'Itapirapuã'},
    {state: 'GO', value: 'Água Fria de Goiás'},
    {state: 'GO', value: 'Campinaçu'},
    {state: 'GO', value: 'Orizona'},
    {state: 'GO', value: 'Faina'},
    {state: 'GO', value: 'Bom Jardim de Goiás'},
    {state: 'GO', value: 'Corumbaíba'},
    {state: 'GO', value: 'Montividiu'},
    {state: 'GO', value: 'Jaraguá'},
    {state: 'GO', value: 'Cocalzinho de Goiás'},
    {state: 'GO', value: 'Baliza'},
    {state: 'GO', value: 'Mozarlândia'},
    {state: 'GO', value: 'Colinas do Sul'},
    {state: 'GO', value: 'Mara Rosa'},
    {state: 'GO', value: 'Cachoeira Alta'},
    {state: 'GO', value: 'Bonópolis'},
    {state: 'GO', value: "Sítio d'Abadia"},
    {state: 'GO', value: 'Caldas Novas'},
    {state: 'GO', value: 'Acreúna'},
    {state: 'GO', value: 'Iaciara'},
    {state: 'GO', value: 'Goianésia'},
    {state: 'GO', value: 'Palmeiras de Goiás'},
    {state: 'GO', value: 'Britânia'},
    {state: 'GO', value: 'Edéia'},
    {state: 'GO', value: 'Itaberaí'},
    {state: 'GO', value: 'Pontalina'},
    {state: 'GO', value: 'Bom Jesus de Goiás'},
    {state: 'GO', value: 'Mimoso de Goiás'},
    {state: 'GO', value: 'Santa Rita do Araguaia'},
    {state: 'GO', value: 'Amaralina'},
    {state: 'GO', value: 'Montividiu do Norte'},
    {state: 'GO', value: 'Palestina de Goiás'},
    {state: 'GO', value: 'Itapuranga'},
    {state: 'GO', value: 'Bela Vista de Goiás'},
    {state: 'GO', value: 'Fazenda Nova'},
    {state: 'GO', value: 'Alvorada do Norte'},
    {state: 'GO', value: 'Ivolândia'},
    {state: 'GO', value: 'Novo Planalto'},
    {state: 'GO', value: 'Guarani de Goiás'},
    {state: 'GO', value: 'Santa Terezinha de Goiás'},
    {state: 'GO', value: 'Santa Fé de Goiás'},
    {state: 'GO', value: 'Paranaiguara'},
    {state: 'GO', value: 'Uirapuru'},
    {state: 'GO', value: 'Matrinchã'},
    {state: 'GO', value: 'Santa Helena de Goiás'},
    {state: 'GO', value: 'Cabeceiras'},
    {state: 'GO', value: 'Santa Cruz de Goiás'},
    {state: 'GO', value: 'Barro Alto'},
    {state: 'GO', value: 'Arenópolis'},
    {state: 'GO', value: 'Pires do Rio'},
    {state: 'GO', value: 'Campinorte'},
    {state: 'GO', value: 'Corumbá de Goiás'},
    {state: 'GO', value: 'Vila Boa'},
    {state: 'GO', value: 'Abadiânia'},
    {state: 'GO', value: 'Perolândia'},
    {state: 'GO', value: 'Iporá'},
    {state: 'GO', value: 'Anicuns'},
    {state: 'GO', value: 'Indiara'},
    {state: 'GO', value: 'Itapaci'},
    {state: 'GO', value: 'Santa Rita do Novo Destino'},
    {state: 'GO', value: 'Mutunópolis'},
    {state: 'GO', value: 'Vianópolis'},
    {state: 'GO', value: 'Hidrolândia'},
    {state: 'GO', value: 'Santo Antônio do Descoberto'},
    {state: 'GO', value: 'Turvelândia'},
    {state: 'GO', value: 'Anápolis'},
    {state: 'GO', value: 'Pilar de Goiás'},
    {state: 'GO', value: 'Buriti Alegre'},
    {state: 'GO', value: 'Jandaia'},
    {state: 'GO', value: 'Mambaí'},
    {state: 'GO', value: 'Alexânia'},
    {state: 'GO', value: 'Formoso'},
    {state: 'GO', value: 'Divinópolis de Goiás'},
    {state: 'GO', value: 'São Luís de Montes Belos'},
    {state: 'GO', value: 'Gouvelândia'},
    {state: 'GO', value: 'Santa Isabel'},
    {state: 'GO', value: 'Trombas'},
    {state: 'GO', value: 'Santa Tereza de Goiás'},
    {state: 'GO', value: 'Teresina de Goiás'},
    {state: 'GO', value: 'Rubiataba'},
    {state: 'GO', value: 'Vicentinópolis'},
    {state: 'GO', value: 'Campos Belos'},
    {state: 'GO', value: 'Goiânia'},
    {state: 'GO', value: 'Trindade'},
    {state: 'GO', value: 'Inaciolândia'},
    {state: 'GO', value: 'Diorama'},
    {state: 'GO', value: 'Mossâmedes'},
    {state: 'GO', value: 'Aragarças'},
    {state: 'GO', value: 'Novo Brasil'},
    {state: 'GO', value: 'Nova Iguaçu de Goiás'},
    {state: 'GO', value: 'Urutaí'},
    {state: 'GO', value: 'Inhumas'},
    {state: 'GO', value: 'Porteirão'},
    {state: 'GO', value: 'Edealina'},
    {state: 'GO', value: 'Aparecida do Rio Doce'},
    {state: 'GO', value: 'Guarinos'},
    {state: 'GO', value: 'Gameleira de Goiás'},
    {state: 'GO', value: 'São Luiz do Norte'},
    {state: 'GO', value: 'Hidrolina'},
    {state: 'GO', value: 'Israelândia'},
    {state: 'GO', value: 'Cumari'},
    {state: 'GO', value: 'Goiandira'},
    {state: 'GO', value: 'Aurilândia'},
    {state: 'GO', value: 'Portelândia'},
    {state: 'GO', value: 'São Miguel do Passa-Quatro'},
    {state: 'GO', value: 'Petrolina de Goiás'},
    {state: 'GO', value: 'Cachoeira Dourada'},
    {state: 'GO', value: 'Jaupaci'},
    {state: 'GO', value: 'Uruana'},
    {state: 'GO', value: 'Varjão'},
    {state: 'GO', value: 'Guapó'},
    {state: 'GO', value: 'Sanclerlândia'},
    {state: 'GO', value: 'Alto Horizonte'},
    {state: 'GO', value: 'Davinópolis'},
    {state: 'GO', value: 'Turvânia'},
    {state: 'GO', value: 'Leopoldo de Bulhões'},
    {state: 'GO', value: 'Mairipotaba'},
    {state: 'GO', value: 'Lagoa Santa'},
    {state: 'GO', value: 'Córrego do Ouro'},
    {state: 'GO', value: 'Moiporá'},
    {state: 'GO', value: 'Água Limpa'},
    {state: 'GO', value: 'Santo Antônio da Barra'},
    {state: 'GO', value: 'Joviânia'},
    {state: 'GO', value: 'Campos Verdes'},
    {state: 'GO', value: 'Panamá'},
    {state: 'GO', value: 'Firminópolis'},
    {state: 'GO', value: 'Cachoeira de Goiás'},
    {state: 'GO', value: 'Carmo do Rio Verde'},
    {state: 'GO', value: 'Cezarina'},
    {state: 'GO', value: 'São Francisco de Goiás'},
    {state: 'GO', value: 'Damianópolis'},
    {state: 'GO', value: 'São Simão'},
    {state: 'GO', value: 'Ouvidor'},
    {state: 'GO', value: 'Nova Glória'},
    {state: 'GO', value: 'Amorinópolis'},
    {state: 'GO', value: 'Cidade Ocidental'},
    {state: 'GO', value: 'Maurilândia'},
    {state: 'GO', value: 'Palminópolis'},
    {state: 'GO', value: 'Itauçu'},
    {state: 'GO', value: 'Cromínia'},
    {state: 'GO', value: 'Professor Jamil'},
    {state: 'GO', value: 'Simolândia'},
    {state: 'GO', value: 'Nova Aurora'},
    {state: 'GO', value: 'Estrela do Norte'},
    {state: 'GO', value: 'Castelândia'},
    {state: 'GO', value: 'São João da Paraúna'},
    {state: 'GO', value: 'Morro Agudo de Goiás'},
    {state: 'GO', value: 'Três Ranchos'},
    {state: 'GO', value: 'Nazário'},
    {state: 'GO', value: 'Aparecida de Goiânia'},
    {state: 'GO', value: 'Campestre de Goiás'},
    {state: 'GO', value: 'Rialma'},
    {state: 'GO', value: 'Caldazinha'},
    {state: 'GO', value: 'Senador Canedo'},
    {state: 'GO', value: 'Buritinópolis'},
    {state: 'GO', value: 'Rio Quente'},
    {state: 'GO', value: 'Ipiranga de Goiás'},
    {state: 'GO', value: 'Itaguaru'},
    {state: 'GO', value: 'Heitoraí'},
    {state: 'GO', value: 'Cristianópolis'},
    {state: 'GO', value: 'Marzagão'},
    {state: 'GO', value: 'Aragoiânia'},
    {state: 'GO', value: 'Ceres'},
    {state: 'GO', value: 'Goianira'},
    {state: 'GO', value: 'Nova América'},
    {state: 'GO', value: 'Ouro Verde de Goiás'},
    {state: 'GO', value: 'Guaraíta'},
    {state: 'GO', value: 'Caturaí'},
    {state: 'GO', value: 'Taquaral de Goiás'},
    {state: 'GO', value: 'Nerópolis'},
    {state: 'GO', value: 'Buriti de Goiás'},
    {state: 'GO', value: 'Novo Gama'},
    {state: 'GO', value: 'Águas Lindas de Goiás'},
    {state: 'GO', value: 'São Patrício'},
    {state: 'GO', value: 'Goianápolis'},
    {state: 'GO', value: 'Avelinópolis'},
    {state: 'GO', value: 'Santa Rosa de Goiás'},
    {state: 'GO', value: 'Campo Limpo de Goiás'},
    {state: 'GO', value: 'Rianápolis'},
    {state: 'GO', value: 'Araçu'},
    {state: 'GO', value: 'Abadia de Goiás'},
    {state: 'GO', value: 'Itaguari'},
    {state: 'GO', value: 'Santa Bárbara de Goiás'},
    {state: 'GO', value: 'Americano do Brasil'},
    {state: 'GO', value: 'Santo Antônio de Goiás'},
    {state: 'GO', value: 'Bonfinópolis'},
    {state: 'GO', value: 'Nova Veneza'},
    {state: 'GO', value: 'Brazabrantes'},
    {state: 'GO', value: 'Jesúpolis'},
    {state: 'GO', value: 'Adelândia'},
    {state: 'GO', value: 'Terezópolis de Goiás'},
    {state: 'GO', value: 'Aloândia'},
    {state: 'GO', value: 'Damolândia'},
    {state: 'GO', value: 'Valparaíso de Goiás'},
    {state: 'GO', value: 'Palmelo'},
    {state: 'GO', value: 'Anhanguera'},
    {state: 'DF', value: 'Plano Piloto'},
    {state: 'DF', value: 'Gama'},
    {state: 'DF', value: 'Taguatinga'},
    {state: 'DF', value: 'Brazlândia'},
    {state: 'DF', value: 'Sobradinho'},
    {state: 'DF', value: 'Planaltina'},
    {state: 'DF', value: 'Paranoá'},
    {state: 'DF', value: 'Núcleo Bandeirante'},
    {state: 'DF', value: 'Ceilândia'},
    {state: 'DF', value: 'Guará'},
    {state: 'DF', value: 'Cruzeiro'},
    {state: 'DF', value: 'Samambaia'},
    {state: 'DF', value: 'Santa Maria'},
    {state: 'DF', value: 'São Sebastião'},
    {state: 'DF', value: 'Recanto das Emas'},
    {state: 'DF', value: 'Lago Sul'},
    {state: 'DF', value: 'Riacho Fundo'},
    {state: 'DF', value: 'Lago Norte'},
    {state: 'DF', value: 'Candangolândia'},
    {state: 'DF', value: 'Águas Claras'},
    {state: 'DF', value: 'Riacho Fundo II'},
    {state: 'DF', value: 'Sudoeste/Octogonal'},
    {state: 'DF', value: 'Varjão'},
    {state: 'DF', value: 'Park Way'},
    {state: 'DF', value: 'SCIA'},
    {state: 'DF', value: 'Sobradinho II'},
    {state: 'DF', value: 'Jardim Botânico'},
    {state: 'DF', value: 'Itapoã'},
    {state: 'DF', value: 'SIA'},
    {state: 'DF', value: 'Vicente Pires'},
    {state: 'DF', value: 'Fercal'},
    {state: 'DF', value: 'Sol Nascente/Pôr do Sol'},
    {state: 'DF', value: 'Arniqueira'},
    {state: 'DF', value: 'Arapoanga'},
    {state: 'DF', value: 'Água Quente'}
];

export default citiesCentro;
