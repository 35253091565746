/* eslint-disable indent */

import React, {useState, useEffect} from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
// import {Gatekeeper} from 'gatekeeper-client-sdk';
import {logoutUser} from '@store/reducers/auth';

import Home from '@pages/Home';
import Dashboard from '@pages/Dashboard';
import Wallet from '@pages/wallet/Wallet';
import Charts from '@app/pages/Charts';
import Roex from '@app/pages/roex/Roex';
import Mercado from '@app/pages/Mercado';
import Perfil from '@app/pages/Perfil';
import TermosDeUso from '@app/pages/TermosDeUso';
import Privacidade from '@app/pages/PoliticaPrivacidade';
import Perguntas from '@app/pages/Perguntas';
import SobreNos from '@app/pages/SobreNos';
import Admin from '@app/pages/Admin';
// import paraTestes from '@pages/testeDeGregas';
import Login from '@app/pages/Login/Login';
import Grade from '@app/pages/NovaGrade';
import Vendas from '@app/pages/Vendas';

// import Profile from '@pages/profile/Profile';
// import Gateways from '@pages/Gateways';
// import GatewaysDetails from '@pages/GatewaysDetails';
// import Devices from '@pages/Devices';
// import AllDevices from '@pages/AllDevices';
// import Group from '@pages/Group';
import PrivateRoute from '../../firebase/PrivateRoute';
// import PrivateRoute from '../../routes/PrivateRoute';
import routing from '../../routes/routing';

import Header from './header/Header';
import Footer from './footer/Footer';
import MenuSidebar from './menu-sidebar/MenuSidebar';
import PageLoading from '../../components/page-loading/PageLoading';

const Main = () => {
    const history = useHistory();
    const [appLoadingState, updateAppLoading] = useState(false);
    const [menusidebarState, updateMenusidebarState] = useState({
        isMenuSidebarCollapsed: true
    });
    const [headerValues, setHeaderValues] = useState();
    const [sidebarOff, setSidebarOff] = useState(false);
    const [footerOff, setFooterOff] = useState(false);

    const toggleMenuSidebar = () => {
        updateMenusidebarState({
            isMenuSidebarCollapsed: !menusidebarState.isMenuSidebarCollapsed
        });
    };
    const dispatch = useDispatch();

    const allowedPaths = [
        '/',
        '/termos-de-uso-opti',
        '/politica-de-privacidade',
        '/sobre-nos',
        '/perguntas-frequentes',
        '/admin/dashboard',
        '/lp-30dias'
    ];

    useEffect(() => {
        if (allowedPaths.includes(window.location.pathname)) {
            setSidebarOff(true);
        }
        updateAppLoading(true);
        const fetchProfile = async () => {
            try {
                // const response = await Gatekeeper.getProfile();
                // dispatch(loadUser(response));
                updateAppLoading(false);
            } catch (error) {
                dispatch(logoutUser());
                updateAppLoading(false);
            }
        };
        fetchProfile();
        return () => {};
    }, []);

    useEffect(() => {
        if (allowedPaths.includes(window.location.pathname)) {
            setSidebarOff(true);
        } else {
            setSidebarOff(false);
        }
        if (window.location.pathname === '/admin/dashboard') {
            setFooterOff(true);
        }
    }, [JSON.stringify(history.location.pathname)]);

    document.getElementById('root').classList.remove('register-page');
    document.getElementById('root').classList.remove('login-page');
    document.getElementById('root').classList.remove('hold-transition');

    document.getElementById('root').className += ' sidebar-mini';

    if (menusidebarState.isMenuSidebarCollapsed) {
        document.getElementById('root').classList.add('sidebar-collapse');
        document.getElementById('root').classList.remove('sidebar-open');
    } else {
        document.getElementById('root').classList.add('sidebar-open');
        document.getElementById('root').classList.remove('sidebar-collapse');
    }

    let template;

    if (appLoadingState) {
        template = <PageLoading />;
    } else {
        template = (
            <>
                {!sidebarOff ? (
                    <Header
                        toggleMenuSidebar={toggleMenuSidebar}
                        headerValues={headerValues}
                    />
                ) : null}
                {!sidebarOff ? <MenuSidebar /> : null}

                <div className={!sidebarOff ? 'content-wrapper' : undefined}>
                    <div className={!sidebarOff ? 'pt-3' : 'pt-0'} />
                    <section className="content">
                        <Switch>
                            {/* <Route
                                exact
                                path={routing.profile}
                                component={Profile}
                            />
                            <Route
                                exact
                                path={routing.gateways}
                                component={Gateways}
                            /> */}
                            {/* <Route
                                exact
                                path={routing.home}
                                render={() => <Home />}
                            /> */}
                            <Route
                                exact
                                path={routing.dashboard}
                                render={(props) => (
                                    <Dashboard
                                        {...props}
                                        headerValues={headerValues}
                                        setHeaderValues={setHeaderValues}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={routing.gradeepm}
                                render={(props) => (
                                    <Grade
                                        {...props}
                                        headerValues={headerValues}
                                        setHeaderValues={setHeaderValues}
                                    />
                                )}
                            />
                            <Route exact path={routing.home} component={Home} />
                            <Route
                                exact
                                path={routing.termos}
                                component={TermosDeUso}
                            />
                            <Route
                                exact
                                path={routing.privacidade}
                                component={Privacidade}
                            />
                            <Route
                                exact
                                path={routing.sobrenos}
                                component={SobreNos}
                            />
                            <Route
                                exact
                                path={routing.faq}
                                component={Perguntas}
                            />

                            {/* <PrivateRoute
                                exact
                                path={routing.money}
                                render={(props) => (
                                    <Wallet
                                        {...props}
                                        headerValues={headerValues}
                                        setHeaderValues={setHeaderValues}
                                    />
                                )}
                                // component={Wallet}
                            /> */}
                            <PrivateRoute
                                exact
                                path={routing.money}
                                render={(props) => (
                                    <Wallet
                                        {...props}
                                        headerValues={headerValues}
                                        setHeaderValues={setHeaderValues}
                                    />
                                )}
                            />

                            <Route
                                exact
                                path={routing.roex}
                                render={(props) => (
                                    <Roex
                                        {...props}
                                        headerValues={headerValues}
                                        setHeaderValues={setHeaderValues}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={routing.mercado}
                                render={(props) => (
                                    <Mercado
                                        {...props}
                                        headerValues={headerValues}
                                        setHeaderValues={setHeaderValues}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={routing.perfil}
                                render={(props) => (
                                    <Perfil
                                        {...props}
                                        headerValues={headerValues}
                                        setHeaderValues={setHeaderValues}
                                    />
                                )}
                            />

                            <Route
                                exact
                                path={routing.charts}
                                render={(props) => (
                                    <Charts
                                        {...props}
                                        headerValues={headerValues}
                                        setHeaderValues={setHeaderValues}
                                    />
                                )}
                            />

                            <Route
                                exact
                                path={routing.login}
                                // component={Login}
                                render={(props) => (
                                    <Login
                                        {...props}
                                        headerValues={headerValues}
                                        setHeaderValues={setHeaderValues}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path={routing.admin}
                                render={() => <Admin />}
                            />
                            <Route
                                exact
                                path={routing.vendas}
                                render={() => <Vendas />}
                            />

                            {/* <Route
                                exact
                                path={routing.GatewaysDetails(':gateway_id')}
                                component={GatewaysDetails}
                            />
                            <Route
                                exact
                                path={routing.devices(':gateway_id')}
                                component={Devices}
                            />
                            <Route
                                exact
                                path={routing.alldevices}
                                component={AllDevices}
                            /> */}
                            {/* <Route
                                exact
                                path={routing.group}
                                component={Group}
                            /> */}
                        </Switch>
                    </section>
                </div>
                {footerOff ? null : <Footer />}
                <div
                    id="sidebar-overlay"
                    role="presentation"
                    onClick={toggleMenuSidebar}
                    onKeyDown={() => {}}
                />
            </>
        );
    }

    return (
        <div
            className="wrapper"
            style={{
                minWidth: '1360px',
                // maxWidth: '4096px',
                overflow: 'hidden'
            }}
        >
            {template}
        </div>
    );
};

export default Main;
