import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const app = firebase.initializeApp({
    apiKey: 'AIzaSyAKvYC2kO16igzJ9KBWpEZlgkeX4LfWpfI',
    authDomain: 'useopti-28a85.firebaseapp.com',
    projectId: 'useopti-28a85',
    storageBucket: 'useopti-28a85.appspot.com',
    messagingSenderId: '343804675577',
    appId: '1:343804675577:web:f0eacfb8adb3956a9e6928'
});

export default app;
// const app = firebase.initializeApp({
//     apiKey: 'AIzaSyANvhRh5e_Wd1heD4zI04CegkN_sAR85bY',
//     authDomain: 'itfast-itstore.firebaseapp.com',
//     projectId: 'itfast-itstore',
//     storageBucket: 'itfast-itstore.appspot.com',
//     messagingSenderId: '448511894621',
//     appId: '1:448511894621:web:adca1f62eba227071e99b4',
//     measurementId: 'G-PJKQJMKMYX'
// });

// apiKey: 'AIzaSyDs6GZ7aIIkGc3vjODRsOSdjNqBsGoeZaE',
// authDomain: 'cognitive-lignting.firebaseapp.com',
// projectId: 'cognitive-lignting',
// storageBucket: 'cognitive-lignting.appspot.com',
// messagingSenderId: '659197049995',
// appId: '1:659197049995:web:02ff571b897ce8c425e88e'
