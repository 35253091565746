/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-plusplus */
/* eslint-disable indent */

import React, {useState, useEffect} from 'react';
import {Modal} from 'react-bootstrap';
import {makeStyles} from '@material-ui/core/styles';
import WebFont from 'webfontloader';

import toast, {Toaster} from 'react-hot-toast';
import {Grid} from '@material-ui/core';
import api from '@app/utils/api';
import moment from 'moment';

import NewWalletChart from '@app/components/charts/NewWalletChart';
import Holidays from 'date-holidays';
import {cleanNumber} from '@app/utils/myaux';
import WalletChart from '@app/components/WalletChart';
import {formatMoney} from '../../../utils/myaux';
import HeaderStrategy from './HeaderStrategy';
import TableStrategyMont from './table/TableStrategyMont';
import TableStrategyDesmont from './table/TableStrategyDesmont';
import TotalMont from './TotalMont';
import TotalDesm from './TotalDesm';
import Extrato from './Extrato';

const myIv = require('implied-volatility');
const myBs = require('black-scholes');

const useStyles = makeStyles(() => ({
    modalHeader: {
        background: '#313031',
        display: 'flex'
    },
    customDialog: {
        width: '330%',
        marginLeft: '-110%',
        '@media (max-width: 1500px)': {
            width: '330%',
            marginLeft: '-110%',
            fontSize: '9px'
        }
    },
    colOne: {
        display: 'inline-block',
        textAlign: 'center',
        width: '50%',
        marginRight: -25,
        '@media (max-width: 1780px)': {
            display: 'flex',
            marginRight: -5
        }
    },
    colTwo: {
        borderLeft: '1px solid #c5c5c5',
        marginTop: -6,
        marginLeft: 5,
        paddingLeft: -1,
        '@media (max-width: 1780)': {
            marginTop: -7
        }
    },
    abvAtivo: {
        marginBot: '-9',
        borderLeft: '1px solid #c5c5c5',
        marginLeft: '47%',
        paddingTop: '8px',
        fontSize: 15.5,
        '@media (max-width: 1500px)': {
            marginBot: '-10',
            borderLeft: '1px solid #c5c5c5',
            marginLeft: '49%',
            paddingTop: '10px',
            fontSize: 10
        }
    },
    myGrid: {
        // minWidth: 40,
        maxHeight: 20,
        width: 110,
        // backgroundColor: '#f4f4f4',
        // margin: theme.spacing(0),
        // marginTop: -2,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        textAlign: 'center',
        cursor: 'pointer',
        fontSize: 14,
        border: '2px solid #c5c5c5'
    },
    myGridSelected: {
        // minWidth: 40,
        width: 110,
        maxHeight: 20,
        backgroundColor: '#e37039',
        // margin: theme.spacing(0),
        // marginTop: -2,
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 14,
        display: 'flex',
        textAlign: 'center',
        cursor: 'pointer',
        border: '2px solid #e37039',
        color: '#fff'
    }
}));

export default function NewStrategy({
    handleClose,
    ativ,
    data,
    status,
    setStatus,
    cards,
    setCards,
    myStrategy,
    id,
    myName,
    cotIni,
    cotFim,
    selic
}) {
    const classes = useStyles();
    const [mont, setMont] = useState(true);
    const [name, setName] = useState('');
    const [montCot, setMontCot] = useState(
        data && data[ativ]?.values[0]?.cotacao
    );
    const [desmCot, setDesmCot] = useState(
        data && data[ativ]?.values[0]?.cotacao
    );
    const [tickers, setTickers] = useState();
    const [ilimitado, setIlimitado] = useState(false);
    const [strategy, setStrategy] = useState([
        {
            type: '',
            qtd: '',
            activeType: '',
            venc: null,
            vencOptFiltered: data && data[ativ]?.filters?.venc,
            strike: null,
            ticker: null,
            cotacao: '0',
            cotacaoDesmont: {changed: false, value: '0'},
            pteorico: '0',
            pteoricoDesmont: '0',
            total: '0',
            totalDesmont: '0',
            data,
            vol: '0',
            tickerOptFiltered: data && data[ativ]?.filters?.tikers,
            allOptStrike: [],
            strikeOptFiltered: [],
            dataMont: new Date(),
            dataDesmont: new Date(),
            mustUpdated: true
        }
    ]);
    const user = api.currentUser;

    const calcTotal = (type, qtd, preco) => {
        if (type === 'C') {
            // return (Number(qtd) * Number(preco)).toFixed(2);
            return Number(qtd) * Number(Number(preco).toFixed(2));
        }
        // return (Number(qtd) * Number(preco)).toFixed(2) * -1;
        return Number(qtd) * Number(Number(preco).toFixed(2)) * -1;
    };

    const hd = new Holidays('BR', '', '');

    const calcBusinessDays = (startDate, endDate) => {
        const day = moment(startDate);
        let businessDays = 0;
        while (day.isSameOrBefore(endDate, 'day')) {
            if (day.day() !== 0 && day.day() !== 6) {
                if (!hd.isHoliday(day)) {
                    businessDays++;
                }
            }
            day.add(1, 'd');
        }
        return businessDays;
    };

    const calcTotalDesmont = (ticker, type, qtd, preco, update) => {
        let result;
        // if (update) {
        //     const find = data[ativ]?.values?.find((n) => n.ticker === ticker);
        //     if (find) {
        //         if (type === 'C') {
        //             result =
        //                 (Number(qtd) * Number(find.ultimo)).toFixed(2) * -1;
        //         } else {
        //             result = (Number(qtd) * Number(find.ultimo)).toFixed(2);
        //         }
        //     } else if (type === 'C') {
        //         result =
        //             (
        //                 Number(qtd) * Number(data[ativ]?.values[0]?.cotacao)
        //             ).toFixed(2) * -1;
        //     } else {
        //         result = (
        //             Number(qtd) * Number(data[ativ]?.values[0]?.cotacao)
        //         ).toFixed(2);
        //     }
        // } else
        if (type === 'V') {
            result = Number(qtd) * Number(preco);
            // result = (Number(qtd) * Number(preco)).toFixed(2);
        } else {
            result = Number(qtd) * Number(preco) * -1;
            // result = (Number(qtd) * Number(preco)).toFixed(2) * -1;
        }
        return result;
    };

    const calcCotacaoDesmont = (preco, updated, ticker) => {
        let result = 0;
        // if (updated) {
        //     const find = data[ativ]?.values?.find((n) => n.ticker === ticker);
        //     if (find) {
        //         result = find.ultimo;
        //     } else {
        //         result = data[ativ]?.values[0]?.cotacao;
        //     }
        // } else {
        result = preco;
        // }
        return result;
    };

    const calcStrikeOptions = (type, venc) => {
        const list = [];
        data[ativ]?.values?.forEach((o) => {
            if (o.venc === venc) {
                list.push(o.strike);
            }
        });
        const uniqueStrike = [...new Set(list)];

        const strikeFinal = uniqueStrike.slice().sort((a, b) => a - b);
        const tmp = [];
        strikeFinal.forEach((u) => {
            tmp.push({
                label: u,
                value: u
            });
        });
        return tmp;
    };

    const VencByStrikes = (type, strike) => {
        const list = [];
        data[ativ]?.values?.forEach((o) => {
            if (o.strike === strike) {
                list.push(o.strike);
            }
        });
        // console.log(list);
        const uniqueVenc = [...new Set(list)];

        const vencFinal = uniqueVenc.slice().sort((a, b) => a - b);
        const tmp = [];
        vencFinal.forEach((u) => {
            tmp.push({
                label: u,
                value: u
            });
        });
        return tmp;
    };

    // const formatMoney = (money) => {
    //     if (money) {
    //         return Intl.NumberFormat('pt-BR', {
    //             style: 'currency',
    //             currency: 'BRL'
    //         })
    //             .format(money)
    //             .replace(',', '.');
    //     }
    //     return money;
    // };

    useEffect(() => {
        // console.log(selic);
        WebFont.load({
            google: {
                families: ['Roboto', 'Rubik']
            }
        });
        if (user) {
            if (
                user?.assinatura?.vencimento &&
                moment() <= moment(user?.assinatura?.vencimento)
            ) {
                setIlimitado(true);
            }
        }
        if (myStrategy) {
            const list = [];
            myStrategy.forEach((s) => {
                // console.log(s);
                list.push({
                    type: s.type,
                    qtd: s.qtd,
                    activeType: s.type_ativo,
                    venc: {label: s.venc, value: s.venc},
                    vencOptFiltered: [{label: s.venc, value: s.venc}],
                    strike: {label: s.strike, value: s.strike},
                    ticker: {label: s.ticker, value: s.ticker},
                    cotacao: formatMoney(s.preco_mont),
                    cotacaoDesmont: {
                        changed: s.preco_mont !== s.preco_desmont,
                        value: formatMoney(
                            calcCotacaoDesmont(
                                s.preco_desmont,
                                s.must_updated,
                                s.ticker
                            )
                        )
                    },
                    pteorico:
                        s.ticker === ativ
                            ? formatMoney(0)
                            : formatMoney(s.preco_teorico),
                    pteoricoDesmont:
                        s.ticker === ativ
                            ? formatMoney(0)
                            : formatMoney(s.preco_teorico_desmont),
                    total: formatMoney(calcTotal(s.type, s.qtd, s.preco_mont)),
                    totalDesmont: formatMoney(
                        calcTotalDesmont(
                            s.ticker,
                            s.type,
                            s.qtd,
                            s.preco_desmont,
                            s.must_updated
                        )
                    ),
                    data,
                    vol: s.vol ? cleanNumber(s.vol.replace(',', '.')) : 0,
                    tickerOptFiltered: data && data[ativ]?.filters?.tikers,
                    allOptStrike: [],
                    strikeOptFiltered: calcStrikeOptions(s.type_ativo, s.venc),
                    dataMont: s.date_mont,
                    dataDesmont: s.date_desmont,
                    mustUpdated: s.must_updated
                });
            });
            setStrategy(list);
            setName(myName);
            setMontCot(cotIni);
            setDesmCot(cotFim);
        } else {
            setStrategy([
                {
                    type: '',
                    qtd: '1000',
                    activeType: '',
                    venc: null,
                    vencOptFiltered: data && data[ativ]?.filters?.venc,
                    strike: null,
                    ticker: null,
                    cotacao: '0',
                    cotacaoDesmont: {changed: false, value: '0'},
                    pteorico: '0',
                    pteoricoDesmont: '0',
                    total: '0',
                    totalDesmont: '0',
                    data,
                    vol: '0',
                    tickerOptFiltered: data && data[ativ]?.filters?.tikers,
                    allOptStrike: [],
                    strikeOptFiltered: [],
                    dataMont: new Date(),
                    dataDesmont: new Date(),
                    mustUpdated: true
                }
            ]);
        }
    }, []);

    useEffect(() => {
        if (montCot && cotIni) {
            const list = [];
            myStrategy.forEach((s) => {
                list.push({
                    type: s.type,
                    qtd: s.qtd,
                    activeType: s.type_ativo,
                    venc: {label: s.venc, value: s.venc},
                    vencOptFiltered: [{label: s.venc, value: s.venc}],
                    strike: {label: s.strike, value: s.strike},
                    ticker: {label: s.ticker, value: s.ticker},
                    cotacao: formatMoney(s.preco_mont),
                    cotacaoDesmont: {
                        changed: s.preco_mont !== s.preco_desmont,
                        value: formatMoney(
                            calcCotacaoDesmont(
                                s.preco_desmont,
                                s.must_updated,
                                s.ticker
                            )
                        )
                    },
                    pteorico:
                        s.ticker === ativ
                            ? formatMoney(0)
                            : formatMoney(
                                  myBs.blackScholes(
                                      cleanNumber(montCot),
                                      cleanNumber(s.strike),
                                      calcBusinessDays(
                                          moment(s.date_mont).format(
                                              'YYYY-MM-DD'
                                          ),
                                          s.venc
                                      ) / 252,
                                      cleanNumber(
                                          myIv.getImpliedVolatility(
                                              cleanNumber(s.preco_mont),
                                              cleanNumber(cotIni),
                                              cleanNumber(s.strike),
                                              calcBusinessDays(
                                                  moment(s.date_mont).format(
                                                      'YYYY-MM-DD'
                                                  ),
                                                  s.venc
                                              ) / 252,
                                              0.1325,
                                              s.type_ativo === 'C'
                                                  ? 'call'
                                                  : 'put',
                                              0.1
                                          )
                                      ),
                                      0.1325,
                                      s.type_ativo === 'C' ? 'call' : 'put'
                                  )
                              ),
                    pteoricoDesmont:
                        s.ticker === ativ
                            ? formatMoney(0)
                            : formatMoney(s.preco_teorico_desmont),
                    total: formatMoney(calcTotal(s.type, s.qtd, s.preco_mont)),
                    totalDesmont: formatMoney(
                        calcTotalDesmont(
                            s.ticker,
                            s.type,
                            s.qtd,
                            s.preco_desmont,
                            s.must_updated
                        )
                    ),
                    data,
                    vol: s.vol ? cleanNumber(s.vol.replace(',', '.')) : 0,
                    tickerOptFiltered: data && data[ativ]?.filters?.tikers,
                    allOptStrike: [],
                    strikeOptFiltered: calcStrikeOptions(s.type_ativo, s.venc),
                    dataMont: s.date_mont,
                    dataDesmont: s.date_desmont,
                    mustUpdated: s.must_updated
                });
                setStrategy(list);
                setName(myName);
            });
        }
    }, [montCot, cotIni]);

    const addNew = () => {
        // console.log(strategy.length);
        if (strategy.length < 5 || ilimitado) {
            if (!status) {
                toast.error(
                    'Não é possivel adicionar mais estratégia a uma carteira travada!'
                );
            } else {
                const qtd = strategy.length - 1;
                if (strategy[qtd].type !== '' && strategy[qtd].ticker) {
                    setStrategy([
                        ...strategy,
                        {
                            type: '',
                            qtd: '1000',
                            activeType: '',
                            venc: null,
                            vencOptFiltered: data[ativ]?.filters?.venc,
                            strike: null,
                            ticker: null,
                            cotacao: '0',
                            cotacaoDesmont: {changed: false, value: '0'},
                            pteorico: '0',
                            pteoricoDesmont: '0',
                            total: '0',
                            totalDesmont: '0',
                            data,
                            vol: '0',
                            tickerOptFiltered: data[ativ]?.filters?.tikers,
                            allOptStrike: [],
                            strikeOptFiltered: [],
                            dataMont: new Date(),
                            dataDesmont: new Date(),
                            mustUpdated: true
                        }
                    ]);
                } else {
                    toast.error(
                        'Complete a montagem em andamento para iniciar uma nova!'
                    );
                }
            }
        } else {
            toast.error(
                'Plano limitado a 5 montagens. Para mais informações consulte a equipe Opti!',
                {
                    duration: 10000
                }
            );
        }
    };
    return (
        <>
            {/* <Modal.Content> */}
            <Toaster />
            <Modal.Header className={classes.modalHeader}>
                <HeaderStrategy
                    handleClose={handleClose}
                    ativ={ativ}
                    status={status}
                    setStatus={setStatus}
                    strategy={strategy}
                    setStrategy={setStrategy}
                    cards={cards}
                    setCards={setCards}
                    id={id}
                    name={name}
                    setName={setName}
                    cotIni={montCot}
                    cotFim={desmCot}
                    setTickers={setTickers}
                    tickers={tickers}
                />
            </Modal.Header>
            <Modal.Body>
                <div className="container-fluid">
                    <div className="row">
                        <div
                            className="col-sm-6"
                            style={{
                                textAlign: 'end',
                                display: 'flex',
                                justifyContent: 'end',
                                marginTop: -10
                                // backgroundColor: '#e37039'
                            }}
                        >
                            {/* MONTAGEM */}
                            <Grid
                                className={
                                    mont
                                        ? classes.myGridSelected
                                        : classes.myGrid
                                }
                                style={{
                                    marginRight: 3,
                                    marginLeft: 3
                                }}
                                onClick={() => setMont(true)}
                            >
                                MONTAGEM
                            </Grid>
                        </div>
                        <div
                            className="col-sm-6"
                            style={{
                                textAlign: 'start',
                                marginTop: -10
                            }}
                        >
                            {/* DESMONTAGEM */}
                            <Grid
                                className={
                                    mont
                                        ? classes.myGrid
                                        : classes.myGridSelected
                                }
                                onClick={() => setMont(false)}
                                style={{
                                    marginLeft: '3px'
                                }}
                            >
                                DESMONTAGEM
                            </Grid>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            {mont ? (
                                <TableStrategyMont
                                    data={data}
                                    ativ={ativ}
                                    strategy={strategy}
                                    setStrategy={setStrategy}
                                    status={status}
                                />
                            ) : (
                                <TableStrategyDesmont
                                    ativ={ativ}
                                    strategy={strategy}
                                    setStrategy={setStrategy}
                                    status={status}
                                />
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    margin: '-40px 0px 40px 20px'
                                }}
                            >
                                <ion-icon
                                    name="add-circle-outline"
                                    style={{
                                        color: '#e37039',
                                        cursor: 'pointer',
                                        fontSize: 25
                                    }}
                                    onClick={addNew}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginLeft: '-30px'
                        }}
                    >
                        <WalletChart
                            myStrategy={myStrategy}
                            strategy={strategy}
                            cotation={!cotIni ? montCot : cotIni}
                            desmontCot={!cotFim ? desmCot : cotFim}
                            ativo={ativ}
                            tickers={tickers}
                            setTickers={setTickers}
                            selic={selic}
                        />
                    </div>
                    <div className="row" style={{marginTop: '20px'}}>
                        <div className="col-sm-6">
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <p style={{margin: '0px 0px 0px 0px'}}>
                                    RESUMO MONTAGEM
                                </p>
                                <span
                                    style={{cursor: 'pointer'}}
                                    data-tip="Resumo da carteira na data de montagem."
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </div>
                            <TotalMont
                                strategy={strategy}
                                montCot={montCot}
                                setMontCot={setMontCot}
                            />
                            <div style={{marginTop: '4px'}} />
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <p style={{margin: '0px 0px 0px 0px'}}>
                                    RESUMO DESMONTAGEM
                                </p>
                                <span
                                    style={{cursor: 'pointer'}}
                                    data-tip="Resumo da carteira na data de desmontagem."
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </div>
                            <TotalDesm
                                strategy={strategy}
                                desmCot={desmCot}
                                setDesmCot={setDesmCot}
                                status={status}
                            />
                        </div>
                        <div className="col-sm-6">
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                <p style={{margin: '0px 0px 0px 0px'}}>
                                    EXTRATO
                                </p>
                                <span
                                    style={{cursor: 'pointer'}}
                                    data-tip="Resumo do resultado financeiro obtido na carteira registrada."
                                >
                                    <ion-icon
                                        style={{
                                            marginBottom: 2,
                                            fontSize: 12,
                                            color: '#d36233',
                                            pointerEvents: 'none'
                                        }}
                                        name="help-circle-outline"
                                    />
                                </span>
                            </div>
                            <Extrato strategy={strategy} />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            {/* </Modal.Content> */}
        </>
    );
}
