const citiesSudeste = [
    {state: 'SP', value: 'Adamantina'},
    {state: 'SP', value: 'Adolfo'},
    {state: 'SP', value: 'Aguaí'},
    {state: 'SP', value: 'Águas da Prata'},
    {state: 'SP', value: 'Águas de Lindóia'},
    {state: 'SP', value: 'Águas de Santa Bárbara'},
    {state: 'SP', value: 'Águas de São Pedro'},
    {state: 'SP', value: 'Agudos'},
    {state: 'SP', value: 'Alambari'},
    {state: 'SP', value: 'Alfredo Marcondes'},
    {state: 'SP', value: 'Altair'},
    {state: 'SP', value: 'Altinópolis'},
    {state: 'SP', value: 'Alto Alegre'},
    {state: 'SP', value: 'Alumínio'},
    {state: 'SP', value: 'Álvares Florence'},
    {state: 'SP', value: 'Álvares Machado'},
    {state: 'SP', value: 'Álvaro de Carvalho'},
    {state: 'SP', value: 'Alvinlândia'},
    {state: 'SP', value: 'Americana'},
    {state: 'SP', value: 'Américo Brasiliense'},
    {state: 'SP', value: 'Américo de Campos'},
    {state: 'SP', value: 'Amparo'},
    {state: 'SP', value: 'Analândia'},
    {state: 'SP', value: 'Andradina'},
    {state: 'SP', value: 'Angatuba'},
    {state: 'SP', value: 'Anhembi'},
    {state: 'SP', value: 'Anhumas'},
    {state: 'SP', value: 'Aparecida'},
    {state: 'SP', value: "Aparecida d'Oeste"},
    {state: 'SP', value: 'Apiaí'},
    {state: 'SP', value: 'Araçariguama'},
    {state: 'SP', value: 'Araçatuba'},
    {state: 'SP', value: 'Araçoiaba da Serra'},
    {state: 'SP', value: 'Aramina'},
    {state: 'SP', value: 'Arandu'},
    {state: 'SP', value: 'Arapeí'},
    {state: 'SP', value: 'Araraquara'},
    {state: 'SP', value: 'Araras'},
    {state: 'SP', value: 'Arco-Íris'},
    {state: 'SP', value: 'Arealva'},
    {state: 'SP', value: 'Areias'},
    {state: 'SP', value: 'Areiópolis'},
    {state: 'SP', value: 'Ariranha'},
    {state: 'SP', value: 'Artur Nogueira'},
    {state: 'SP', value: 'Arujá'},
    {state: 'SP', value: 'Aspásia'},
    {state: 'SP', value: 'Assis'},
    {state: 'SP', value: 'Atibaia'},
    {state: 'SP', value: 'Auriflama'},
    {state: 'SP', value: 'Avaí'},
    {state: 'SP', value: 'Avanhandava'},
    {state: 'SP', value: 'Avaré'},
    {state: 'SP', value: 'Bady Bassitt'},
    {state: 'SP', value: 'Balbinos'},
    {state: 'SP', value: 'Bálsamo'},
    {state: 'SP', value: 'Bananal'},
    {state: 'SP', value: 'Barão de Antonina'},
    {state: 'SP', value: 'Barbosa'},
    {state: 'SP', value: 'Bariri'},
    {state: 'SP', value: 'Barra Bonita'},
    {state: 'SP', value: 'Barra do Chapéu'},
    {state: 'SP', value: 'Barra do Turvo'},
    {state: 'SP', value: 'Barretos'},
    {state: 'SP', value: 'Barrinha'},
    {state: 'SP', value: 'Barueri'},
    {state: 'SP', value: 'Bastos'},
    {state: 'SP', value: 'Batatais'},
    {state: 'SP', value: 'Bauru'},
    {state: 'SP', value: 'Bebedouro'},
    {state: 'SP', value: 'Bento de Abreu'},
    {state: 'SP', value: 'Bernardino de Campos'},
    {state: 'SP', value: 'Bertioga'},
    {state: 'SP', value: 'Bilac'},
    {state: 'SP', value: 'Birigui'},
    {state: 'SP', value: 'Biritibamirim'},
    {state: 'SP', value: 'Boa Esperança do Sul'},
    {state: 'SP', value: 'Bocaina'},
    {state: 'SP', value: 'Bofete'},
    {state: 'SP', value: 'Boituva'},
    {state: 'SP', value: 'Bom Jesus dos Perdões'},
    {state: 'SP', value: 'Bom Sucesso de Itararé'},
    {state: 'SP', value: 'Borá'},
    {state: 'SP', value: 'Boracéia'},
    {state: 'SP', value: 'Borborema'},
    {state: 'SP', value: 'Borebi'},
    {state: 'SP', value: 'Botucatu'},
    {state: 'SP', value: 'Bragança Paulista'},
    {state: 'SP', value: 'Braúna'},
    {state: 'SP', value: 'Brejo Alegre'},
    {state: 'SP', value: 'Brodowski'},
    {state: 'SP', value: 'Brotas'},
    {state: 'SP', value: 'Buri'},
    {state: 'SP', value: 'Buritama'},
    {state: 'SP', value: 'Buritizal'},
    {state: 'SP', value: 'Cabreúva'},
    {state: 'SP', value: 'Caçapava'},
    {state: 'SP', value: 'Cachoeira Paulista'},
    {state: 'SP', value: 'Caconde'},
    {state: 'SP', value: 'Cafelândia'},
    {state: 'SP', value: 'Caiabu'},
    {state: 'SP', value: 'Caieiras'},
    {state: 'SP', value: 'Caiuá'},
    {state: 'SP', value: ' Cajamar'},
    {state: 'SP', value: ' Cajati'},
    {state: 'SP', value: 'Cajobi'},
    {state: 'SP', value: ' Cajuru'},
    {state: 'SP', value: ' Campina do Monte Alegre'},
    {state: 'SP', value: ' Campinas'},
    {state: 'SP', value: 'Campo Limpo Paulista'},
    {state: 'SP', value: 'Campos do Jordão'},
    {state: 'SP', value: 'Campos Novos Paulista'},
    {state: 'SP', value: ' Cananéia'},
    {state: 'SP', value: ' Canas'},
    {state: 'SP', value: 'Cândido Mota'},
    {state: 'SP', value: ' Cândido Rodrigues'},
    {state: 'SP', value: 'Canitar'},
    {state: 'SP', value: ' Capão Bonito'},
    {state: 'SP', value: 'Capela do Alto'},
    {state: 'SP', value: ' Capivari'},
    {state: 'SP', value: ' Caraguatatuba'},
    {state: 'SP', value: 'Carapicuíba'},
    {state: 'SP', value: ' Cardoso'},
    {state: 'SP', value: ' Casa Branca'},
    {state: 'SP', value: ' Cássia dos Coqueiros'},
    {state: 'SP', value: ' Castilho'},
    {state: 'SP', value: 'Catanduva'},
    {state: 'SP', value: 'Catiguá'},
    {state: 'SP', value: ' Cedral'},
    {state: 'SP', value: ' Cerqueira César'},
    {state: 'SP', value: 'Cerquilho'},
    {state: 'SP', value: ' Cesário Lange'},
    {state: 'SP', value: ' Charqueada'},
    {state: 'SP', value: ' Chavantes'},
    {state: 'SP', value: 'Clementina'},
    {state: 'SP', value: ' Colina'},
    {state: 'SP', value: 'Colômbia'},
    {state: 'SP', value: ' Conchal'},
    {state: 'SP', value: 'Conchas'},
    {state: 'SP', value: 'Cordeirópolis'},
    {state: 'SP', value: ' Coroados'},
    {state: 'SP', value: ' Coronel Macedo'},
    {state: 'SP', value: ' Corumbataí'},
    {state: 'SP', value: ' Cosmópolis'},
    {state: 'SP', value: 'Cosmorama'},
    {state: 'SP', value: ' Cotia'},
    {state: 'SP', value: ' Cravinhos'},
    {state: 'SP', value: 'Cristais Paulista'},
    {state: 'SP', value: ' Cruzália'},
    {state: 'SP', value: ' Cruzeiro'},
    {state: 'SP', value: ' Cubatão'},
    {state: 'SP', value: ' Cunha'},
    {state: 'SP', value: 'Descalvado'},
    {state: 'SP', value: 'Diadema'},
    {state: 'SP', value: 'Dirce Reis'},
    {state: 'SP', value: 'Divinolândia'},
    {state: 'SP', value: 'Dobrada'},
    {state: 'SP', value: 'Dois Córregos'},
    {state: 'SP', value: 'Dolcinópolis'},
    {state: 'SP', value: 'Dourado'},
    {state: 'SP', value: 'Dracena'},
    {state: 'SP', value: 'Duartina'},
    {state: 'SP', value: 'Dumont'},
    {state: 'SP', value: 'Echaporã'},
    {state: 'SP', value: 'Eldorado'},
    {state: 'SP', value: 'Elias Fausto'},
    {state: 'SP', value: 'Elisiário'},
    {state: 'SP', value: 'Embaúba'},
    {state: 'SP', value: 'Embu'},
    {state: 'SP', value: 'Embu-Guaçu'},
    {state: 'SP', value: 'Emilianópolis'},
    {state: 'SP', value: 'Engenheiro Coelho'},
    {state: 'SP', value: 'Espírito Santo do Pinhal'},
    {state: 'SP', value: 'Espírito Santo do Turvo'},
    {state: 'SP', value: 'Estiva Gerbi'},
    {state: 'SP', value: 'Estrela do Norte'},
    {state: 'SP', value: "Estrela d'Oeste"},
    {state: 'SP', value: 'Euclides da Cunha Paulista'},
    {state: 'SP', value: 'Gabriel Monteiro'},
    {state: 'SP', value: 'Gália'},
    {state: 'SP', value: 'Garça'},
    {state: 'SP', value: 'Gastão Vidigal'},
    {state: 'SP', value: 'Gavião Peixoto'},
    {state: 'SP', value: 'General Salgado'},
    {state: 'SP', value: 'Getulina'},
    {state: 'SP', value: 'Glicério'},
    {state: 'SP', value: 'Guaiçara'},
    {state: 'SP', value: 'Guaimbê'},
    {state: 'SP', value: 'Guaíra'},
    {state: 'SP', value: 'Guapiaçu'},
    {state: 'SP', value: 'Guapiara'},
    {state: 'SP', value: 'Guará'},
    {state: 'SP', value: 'Guaraçaí'},
    {state: 'SP', value: 'Guaraci'},
    {state: 'SP', value: "Guarani d'Oeste"},
    {state: 'SP', value: 'Guarantã'},
    {state: 'SP', value: 'Guararapes'},
    {state: 'SP', value: 'Guararema'},
    {state: 'SP', value: 'Guaratinguetá'},
    {state: 'SP', value: 'Guareí'},
    {state: 'SP', value: 'Guariba'},
    {state: 'SP', value: 'Guarujá'},
    {state: 'SP', value: 'Guarulhos'},
    {state: 'SP', value: 'Guatapará'},
    {state: 'SP', value: 'Guzolândia'},
    {state: 'SP', value: 'Herculândia'},
    {state: 'SP', value: 'Holambra'},
    {state: 'SP', value: 'Hortolândia'},
    {state: 'SP', value: 'Iacanga'},
    {state: 'SP', value: 'Iacri'},
    {state: 'SP', value: 'Iaras'},
    {state: 'SP', value: 'Ibaté'},
    {state: 'SP', value: 'Ibirá'},
    {state: 'SP', value: 'Ibirarema'},
    {state: 'SP', value: 'Ibitinga'},
    {state: 'SP', value: 'Ibiúna'},
    {state: 'SP', value: 'Icém'},
    {state: 'SP', value: 'Iepê'},
    {state: 'SP', value: 'Igaraçu do Tietê'},
    {state: 'SP', value: 'Igarapava'},
    {state: 'SP', value: 'Igaratá'},
    {state: 'SP', value: 'Iguape'},
    {state: 'SP', value: 'Ilha Comprida'},
    {state: 'SP', value: 'Ilha Solteira'},
    {state: 'SP', value: 'Ilhabela'},
    {state: 'SP', value: 'Indaiatuba'},
    {state: 'SP', value: 'Indiana'},
    {state: 'SP', value: 'Indiaporã'},
    {state: 'SP', value: 'Inúbia Paulista'},
    {state: 'SP', value: 'Ipaussu'},
    {state: 'SP', value: 'Iperó'},
    {state: 'SP', value: 'Ipeúna'},
    {state: 'SP', value: 'Ipiguá'},
    {state: 'SP', value: 'Iporanga'},
    {state: 'SP', value: 'Ipuã'},
    {state: 'SP', value: 'Iracemápolis'},
    {state: 'SP', value: 'Irapuã'},
    {state: 'SP', value: 'Irapuru'},
    {state: 'SP', value: 'Itaberá'},
    {state: 'SP', value: 'Itaí'},
    {state: 'SP', value: 'Itajobi'},
    {state: 'SP', value: 'Itaju'},
    {state: 'SP', value: 'Itanhaém'},
    {state: 'SP', value: 'Itaóca'},
    {state: 'SP', value: 'Itapecerica da Serra'},
    {state: 'SP', value: 'Itapetininga'},
    {state: 'SP', value: 'Itapeva'},
    {state: 'SP', value: 'Itapevi'},
    {state: 'SP', value: 'Itapira'},
    {state: 'SP', value: 'Itapirapuã Paulista'},
    {state: 'SP', value: 'Itápolis'},
    {state: 'SP', value: 'Itaporanga'},
    {state: 'SP', value: 'Itapuí'},
    {state: 'SP', value: 'Itapura'},
    {state: 'SP', value: 'Itaquaquecetuba'},
    {state: 'SP', value: 'Itararé'},
    {state: 'SP', value: 'Itariri'},
    {state: 'SP', value: 'Itatiba'},
    {state: 'SP', value: 'Itatinga'},
    {state: 'SP', value: 'Itirapina'},
    {state: 'SP', value: 'Itirapuã'},
    {state: 'SP', value: 'Itobi'},
    {state: 'SP', value: 'Itu'},
    {state: 'SP', value: 'Itupeva'},
    {state: 'SP', value: 'Ituverava'},
    {state: 'SP', value: 'Jaborandi'},
    {state: 'SP', value: 'Jaboticabal'},
    {state: 'SP', value: 'Jacareí'},
    {state: 'SP', value: 'Jaci'},
    {state: 'SP', value: 'Jacupiranga'},
    {state: 'SP', value: 'Jaguariúna'},
    {state: 'SP', value: 'Jales'},
    {state: 'SP', value: 'Jambeiro'},
    {state: 'SP', value: 'Jandira'},
    {state: 'SP', value: 'Jardinópolis'},
    {state: 'SP', value: 'Jarinu'},
    {state: 'SP', value: 'Jaú'},
    {state: 'SP', value: 'Jeriquara'},
    {state: 'SP', value: 'Joanópolis'},
    {state: 'SP', value: 'João Ramalho'},
    {state: 'SP', value: 'José Bonifácio'},
    {state: 'SP', value: 'Júlio Mesquita'},
    {state: 'SP', value: 'Jumirim'},
    {state: 'SP', value: 'Jundiaí'},
    {state: 'SP', value: 'Junqueirópolis'},
    {state: 'SP', value: 'Juquiá'},
    {state: 'SP', value: 'Juquitiba'},
    {state: 'SP', value: 'Lagoinha'},
    {state: 'SP', value: 'Laranjal Paulista'},
    {state: 'SP', value: 'Lavínia'},
    {state: 'SP', value: 'Lavrinhas'},
    {state: 'SP', value: 'Leme'},
    {state: 'SP', value: 'Lençóis Paulista'},
    {state: 'SP', value: 'Limeira'},
    {state: 'SP', value: 'Lindóia'},
    {state: 'SP', value: 'Lins'},
    {state: 'SP', value: 'Lorena'},
    {state: 'SP', value: 'Lourdes'},
    {state: 'SP', value: 'Louveira'},
    {state: 'SP', value: 'Lucélia'},
    {state: 'SP', value: 'Lucianópolis'},
    {state: 'SP', value: 'Luís Antônio'},
    {state: 'SP', value: 'Luiziânia'},
    {state: 'SP', value: 'Lupércio'},
    {state: 'SP', value: 'Lutécia'},
    {state: 'SP', value: 'Macatuba'},
    {state: 'SP', value: 'Macaubal'},
    {state: 'SP', value: 'Macedônia'},
    {state: 'SP', value: 'Magda'},
    {state: 'SP', value: 'Mairinque'},
    {state: 'SP', value: 'Mairiporã'},
    {state: 'SP', value: 'Manduri'},
    {state: 'SP', value: 'Marabá Paulista'},
    {state: 'SP', value: 'Maracaí'},
    {state: 'SP', value: 'Marapoama'},
    {state: 'SP', value: 'Mariápolis'},
    {state: 'SP', value: 'Marília'},
    {state: 'SP', value: 'Marinópolis'},
    {state: 'SP', value: 'Martinópolis'},
    {state: 'SP', value: 'Matão'},
    {state: 'SP', value: 'Mauá'},
    {state: 'SP', value: 'Mendonça'},
    {state: 'SP', value: 'Meridiano'},
    {state: 'SP', value: 'Mesópolis'},
    {state: 'SP', value: 'Miguelópolis'},
    {state: 'SP', value: 'Mineiros do Tietê'},
    {state: 'SP', value: 'Mira Estrela'},
    {state: 'SP', value: 'Miracatu'},
    {state: 'SP', value: 'Mirandópolis'},
    {state: 'SP', value: 'Mirante do Paranapanema'},
    {state: 'SP', value: 'Mirassol'},
    {state: 'SP', value: 'Mirassolândia'},
    {state: 'SP', value: 'Mococa'},
    {state: 'SP', value: 'Mogi das Cruzes'},
    {state: 'SP', value: 'Mogi Guaçu'},
    {state: 'SP', value: 'Mogi Mirim'},
    {state: 'SP', value: 'Mombuca'},
    {state: 'SP', value: 'Monções'},
    {state: 'SP', value: 'Mongaguá'},
    {state: 'SP', value: 'Monte Alegre do Sul'},
    {state: 'SP', value: 'Monte Alto'},
    {state: 'SP', value: 'Monte Aprazível'},
    {state: 'SP', value: 'Monte Azul Paulista'},
    {state: 'SP', value: 'Monte Castelo'},
    {state: 'SP', value: 'Monte Mor'},
    {state: 'SP', value: 'Monteiro Lobato'},
    {state: 'SP', value: 'Morro Agudo'},
    {state: 'SP', value: 'Morungaba'},
    {state: 'SP', value: 'Motuca'},
    {state: 'SP', value: 'Murutinga do Sul'},
    {state: 'SP', value: 'Nantes'},
    {state: 'SP', value: 'Narandiba'},
    {state: 'SP', value: 'Natividade da Serra'},
    {state: 'SP', value: 'Nazaré Paulista'},
    {state: 'SP', value: 'Neves Paulista'},
    {state: 'SP', value: 'Nhandeara'},
    {state: 'SP', value: 'Nipoã'},
    {state: 'SP', value: 'Nova Aliança'},
    {state: 'SP', value: 'Nova Campina'},
    {state: 'SP', value: 'Nova Canaã Paulista'},
    {state: 'SP', value: 'Nova Castilho'},
    {state: 'SP', value: 'Nova Europa'},
    {state: 'SP', value: 'Nova Granada'},
    {state: 'SP', value: 'Nova Guataporanga'},
    {state: 'SP', value: 'Nova Independência'},
    {state: 'SP', value: 'Nova Luzitânia'},
    {state: 'SP', value: 'Nova Odessa'},
    {state: 'SP', value: 'Novais'},
    {state: 'SP', value: 'Novo Horizonte'},
    {state: 'SP', value: 'Nuporanga'},
    {state: 'SP', value: 'Ocauçu'},
    {state: 'SP', value: 'Óleo'},
    {state: 'SP', value: 'Olímpia'},
    {state: 'SP', value: 'Onda Verde'},
    {state: 'SP', value: 'Oriente'},
    {state: 'SP', value: 'Orindiúva'},
    {state: 'SP', value: 'Orlândia'},
    {state: 'SP', value: 'Osasco'},
    {state: 'SP', value: 'Oscar Bressane'},
    {state: 'SP', value: 'Osvaldo Cruz'},
    {state: 'SP', value: 'Ourinhos'},
    {state: 'SP', value: 'Ouro Verde'},
    {state: 'SP', value: 'Ouroeste'},
    {state: 'SP', value: 'Pacaembu'},
    {state: 'SP', value: 'Palestina'},
    {state: 'SP', value: 'Palmares Paulista'},
    {state: 'SP', value: "Palmeira d'Oeste"},
    {state: 'SP', value: 'Palmital'},
    {state: 'SP', value: 'Panorama'},
    {state: 'SP', value: 'Paraguaçu Paulista'},
    {state: 'SP', value: 'Paraibuna'},
    {state: 'SP', value: 'Paraíso'},
    {state: 'SP', value: 'Paranapanema'},
    {state: 'SP', value: 'Paranapuã'},
    {state: 'SP', value: 'Parapuã'},
    {state: 'SP', value: 'Pardinho'},
    {state: 'SP', value: 'Pariquera-Açu'},
    {state: 'SP', value: 'Parisi'},
    {state: 'SP', value: 'Patrocínio Paulista'},
    {state: 'SP', value: 'Paulicéia'},
    {state: 'SP', value: 'Paulínia'},
    {state: 'SP', value: 'Paulistânia'},
    {state: 'SP', value: 'Paulo de Faria'},
    {state: 'SP', value: 'Pederneiras'},
    {state: 'SP', value: 'Pedra Bela'},
    {state: 'SP', value: 'Pedranópolis'},
    {state: 'SP', value: 'Pedregulho'},
    {state: 'SP', value: 'Pedreira'},
    {state: 'SP', value: 'Pedrinhas Paulista'},
    {state: 'SP', value: 'Pedro de Toledo'},
    {state: 'SP', value: 'Penápolis'},
    {state: 'SP', value: 'Pereira Barreto'},
    {state: 'SP', value: 'Pereiras'},
    {state: 'SP', value: 'Peruíbe'},
    {state: 'SP', value: 'Piacatu'},
    {state: 'SP', value: 'Piedade'},
    {state: 'SP', value: 'Pilar do Sul'},
    {state: 'SP', value: 'Pindamonhangaba'},
    {state: 'SP', value: 'Pindorama'},
    {state: 'SP', value: 'Pinhalzinho'},
    {state: 'SP', value: 'Piquerobi'},
    {state: 'SP', value: 'Piquete'},
    {state: 'SP', value: 'Piracaia'},
    {state: 'SP', value: 'Piracicaba'},
    {state: 'SP', value: 'Piraju'},
    {state: 'SP', value: 'Pirajuí'},
    {state: 'SP', value: 'Pirangi'},
    {state: 'SP', value: 'Pirapora do Bom Jesus'},
    {state: 'SP', value: 'Pirapozinho'},
    {state: 'SP', value: 'Pirassununga'},
    {state: 'SP', value: 'Piratininga'},
    {state: 'SP', value: 'Pitangueiras'},
    {state: 'SP', value: 'Planalto'},
    {state: 'SP', value: 'Platina'},
    {state: 'SP', value: 'Poá'},
    {state: 'SP', value: 'Poloni'},
    {state: 'SP', value: 'Pompéia'},
    {state: 'SP', value: 'Pongaí'},
    {state: 'SP', value: 'Pontal'},
    {state: 'SP', value: 'Pontalinda'},
    {state: 'SP', value: 'Pontes Gestal'},
    {state: 'SP', value: 'Populina'},
    {state: 'SP', value: 'Porangaba'},
    {state: 'SP', value: 'Porto Feliz'},
    {state: 'SP', value: 'Porto Ferreira'},
    {state: 'SP', value: 'Potim'},
    {state: 'SP', value: 'Potirendaba'},
    {state: 'SP', value: 'Pracinha'},
    {state: 'SP', value: 'Pradópolis'},
    {state: 'SP', value: 'Praia Grande'},
    {state: 'SP', value: 'Pratânia'},
    {state: 'SP', value: 'Presidente Alves'},
    {state: 'SP', value: 'Presidente Bernardes'},
    {state: 'SP', value: 'Presidente Epitácio'},
    {state: 'SP', value: 'Presidente Prudente'},
    {state: 'SP', value: 'Presidente Venceslau'},
    {state: 'SP', value: 'Promissão'},
    {state: 'SP', value: 'Quadra'},
    {state: 'SP', value: 'Quatá'},
    {state: 'SP', value: 'Queiroz'},
    {state: 'SP', value: 'Queluz'},
    {state: 'SP', value: 'Quintana'},
    {state: 'SP', value: 'Rafard'},
    {state: 'SP', value: 'Rancharia'},
    {state: 'SP', value: 'Redenção da Serra'},
    {state: 'SP', value: 'Regente Feijó'},
    {state: 'SP', value: 'Reginópolis'},
    {state: 'SP', value: 'Registro'},
    {state: 'SP', value: 'Restinga'},
    {state: 'SP', value: 'Ribeira'},
    {state: 'SP', value: 'Ribeirão Bonito'},
    {state: 'SP', value: 'Ribeirão Branco'},
    {state: 'SP', value: 'Ribeirão Corrente'},
    {state: 'SP', value: 'Ribeirão do Sul'},
    {state: 'SP', value: 'Ribeirão dos Índios'},
    {state: 'SP', value: 'Ribeirão Grande'},
    {state: 'SP', value: 'Ribeirão Pires'},
    {state: 'SP', value: 'Ribeirão Preto'},
    {state: 'SP', value: 'Rifaina'},
    {state: 'SP', value: 'Rincão'},
    {state: 'SP', value: 'Rinópolis'},
    {state: 'SP', value: 'Rio Claro'},
    {state: 'SP', value: 'Rio das Pedras'},
    {state: 'SP', value: 'Rio Grande da Serra'},
    {state: 'SP', value: 'Riolândia'},
    {state: 'SP', value: 'Riversul'},
    {state: 'SP', value: 'Rosana'},
    {state: 'SP', value: 'Roseira'},
    {state: 'SP', value: 'Rubiácea'},
    {state: 'SP', value: 'Rubinéia'},
    {state: 'SP', value: 'Sabino'},
    {state: 'SP', value: 'Sagres'},
    {state: 'SP', value: 'Sales'},
    {state: 'SP', value: 'Sales Oliveira'},
    {state: 'SP', value: 'Salesópolis'},
    {state: 'SP', value: 'Salmourão'},
    {state: 'SP', value: 'Saltinho'},
    {state: 'SP', value: 'Salto'},
    {state: 'SP', value: 'Salto de Pirapora'},
    {state: 'SP', value: 'Salto Grande'},
    {state: 'SP', value: 'Sandovalina'},
    {state: 'SP', value: 'Santa Adélia'},
    {state: 'SP', value: 'Santa Albertina'},
    {state: 'SP', value: "Santa Bárbara d'Oeste"},
    {state: 'SP', value: 'Santa Branca'},
    {state: 'SP', value: "Santa Clara d'Oeste"},
    {state: 'SP', value: 'Santa Cruz da Conceição'},
    {state: 'SP', value: 'Santa Cruz da Esperança'},
    {state: 'SP', value: 'Santa Cruz das Palmeiras'},
    {state: 'SP', value: 'Santa Cruz do Rio Pardo'},
    {state: 'SP', value: 'Santa Ernestina'},
    {state: 'SP', value: 'Santa Fé do Sul'},
    {state: 'SP', value: 'Santa Gertrudes'},
    {state: 'SP', value: 'Santa Isabel'},
    {state: 'SP', value: 'Santa Lúcia'},
    {state: 'SP', value: 'Santa Maria da Serra'},
    {state: 'SP', value: 'Santa Mercedes'},
    {state: 'SP', value: 'Santa Rita do Passa-Quatro'},
    {state: 'SP', value: "Santa Rita d'Oeste"},
    {state: 'SP', value: 'Santa Rosa de Viterbo'},
    {state: 'SP', value: 'Santa Salete'},
    {state: 'SP', value: 'Santana da Ponte Pensa'},
    {state: 'SP', value: 'Santana de Parnaíba'},
    {state: 'SP', value: 'Santo Anastácio'},
    {state: 'SP', value: 'Santo André'},
    {state: 'SP', value: 'Santo Antônio da Alegria'},
    {state: 'SP', value: 'Santo Antônio de Posse'},
    {state: 'SP', value: 'Santo Antônio do Aracanguá'},
    {state: 'SP', value: 'Santo Antônio do Jardim'},
    {state: 'SP', value: 'Santo Antônio do Pinhal'},
    {state: 'SP', value: 'Santo Expedito'},
    {state: 'SP', value: 'Santópolis do Aguapeí'},
    {state: 'SP', value: 'Santos'},
    {state: 'SP', value: 'São Bento do Sapucaí'},
    {state: 'SP', value: 'São Bernardo do Campo'},
    {state: 'SP', value: 'São Caetano do Sul'},
    {state: 'SP', value: 'São Carlos'},
    {state: 'SP', value: 'São Francisco'},
    {state: 'SP', value: 'São João da Boa Vista'},
    {state: 'SP', value: 'São João das Duas Pontes'},
    {state: 'SP', value: 'São João de Iracema'},
    {state: 'SP', value: "São João do Pau d'Alho"},
    {state: 'SP', value: 'São Joaquim da Barra'},
    {state: 'SP', value: 'São José da Bela Vista'},
    {state: 'SP', value: 'São José do Barreiro'},
    {state: 'SP', value: 'São José do Rio Pardo'},
    {state: 'SP', value: 'São José do Rio Preto'},
    {state: 'SP', value: 'São José dos Campos'},
    {state: 'SP', value: 'São Lourenço da Serra'},
    {state: 'SP', value: 'São Luiz do Paraitinga'},
    {state: 'SP', value: 'São Manuel'},
    {state: 'SP', value: 'São Miguel Arcanjo'},
    {state: 'SP', value: 'São Paulo'},
    {state: 'SP', value: 'São Pedro'},
    {state: 'SP', value: 'São Pedro do Turvo'},
    {state: 'SP', value: 'São Roque'},
    {state: 'SP', value: 'São Sebastião'},
    {state: 'SP', value: 'São Sebastião da Grama'},
    {state: 'SP', value: 'São Simão'},
    {state: 'SP', value: 'São Vicente'},
    {state: 'SP', value: 'Sarapuí'},
    {state: 'SP', value: 'Sarutaiá'},
    {state: 'SP', value: 'Sebastianópolis do Sul'},
    {state: 'SP', value: 'Serra Azul'},
    {state: 'SP', value: 'Serra Negra'},
    {state: 'SP', value: 'Serrana'},
    {state: 'SP', value: 'Sertãozinho'},
    {state: 'SP', value: 'Sete Barras'},
    {state: 'SP', value: 'Severínia'},
    {state: 'SP', value: 'Silveiras'},
    {state: 'SP', value: 'Socorro'},
    {state: 'SP', value: 'Sorocaba'},
    {state: 'SP', value: 'Sud Mennucci'},
    {state: 'SP', value: 'Sumaré'},
    {state: 'SP', value: 'Suzanápolis'},
    {state: 'SP', value: 'Suzano'},
    {state: 'SP', value: 'Tabapuã'},
    {state: 'SP', value: 'Tabatinga'},
    {state: 'SP', value: 'Taboão da Serra'},
    {state: 'SP', value: 'Taciba'},
    {state: 'SP', value: 'Taguaí'},
    {state: 'SP', value: 'Taiaçu'},
    {state: 'SP', value: 'Taiúva'},
    {state: 'SP', value: 'Tambaú'},
    {state: 'SP', value: 'Tanabi'},
    {state: 'SP', value: 'Tapiraí'},
    {state: 'SP', value: 'Tapiratiba'},
    {state: 'SP', value: 'Taquaral'},
    {state: 'SP', value: 'Taquaritinga'},
    {state: 'SP', value: 'Taquarituba'},
    {state: 'SP', value: 'Taquarivaí'},
    {state: 'SP', value: 'Tarabai'},
    {state: 'SP', value: 'Tarumã'},
    {state: 'SP', value: 'Tatuí'},
    {state: 'SP', value: 'Taubaté'},
    {state: 'SP', value: 'Tejupá'},
    {state: 'SP', value: 'Teodoro Sampaio'},
    {state: 'SP', value: 'Terra Roxa'},
    {state: 'SP', value: 'Tietê'},
    {state: 'SP', value: 'Timburi'},
    {state: 'SP', value: 'Torre de Pedra'},
    {state: 'SP', value: 'Torrinha'},
    {state: 'SP', value: 'Trabiju'},
    {state: 'SP', value: 'Tremembé'},
    {state: 'SP', value: 'Três Fronteiras'},
    {state: 'SP', value: 'Tuiuti'},
    {state: 'SP', value: 'Tupã'},
    {state: 'SP', value: 'Tupi Paulista'},
    {state: 'SP', value: 'Turiúba'},
    {state: 'SP', value: 'Turmalina'},
    {state: 'SP', value: 'Ubarana'},
    {state: 'SP', value: 'Ubatuba'},
    {state: 'SP', value: 'Ubirajara'},
    {state: 'SP', value: 'Uchoa'},
    {state: 'SP', value: 'União Paulista'},
    {state: 'SP', value: 'Urânia'},
    {state: 'SP', value: 'Uru'},
    {state: 'SP', value: 'Urupês'},
    {state: 'SP', value: 'Valentim Gentil'},
    {state: 'SP', value: 'Valinhos'},
    {state: 'SP', value: 'Valparaíso'},
    {state: 'SP', value: 'Vargem'},
    {state: 'SP', value: 'Vargem Grande do Sul'},
    {state: 'SP', value: 'Vargem Grande Paulista'},
    {state: 'SP', value: 'Várzea Paulista'},
    {state: 'SP', value: 'Vera Cruz'},
    {state: 'SP', value: 'Vinhedo'},
    {state: 'SP', value: 'Viradouro'},
    {state: 'SP', value: 'Vista Alegre do Alto'},
    {state: 'SP', value: 'Vitória Brasil'},
    {state: 'SP', value: 'Votorantim'},
    {state: 'SP', value: 'Votuporanga'},
    {state: 'SP', value: 'Zacarias'},
    {state: 'RJ', value: 'Angra dos Reis'},
    {state: 'RJ', value: 'Aperibé'},
    {state: 'RJ', value: 'Araruama'},
    {state: 'RJ', value: 'Areal'},
    {state: 'RJ', value: 'Armação dos Búzios'},
    {state: 'RJ', value: 'Arraial do Cabo'},
    {state: 'RJ', value: 'Barra do Piraí'},
    {state: 'RJ', value: 'Barra Mansa'},
    {state: 'RJ', value: 'Belford Roxo'},
    {state: 'RJ', value: 'Bom Jardim'},
    {state: 'RJ', value: 'Bom Jesus do Itabapoana'},
    {state: 'RJ', value: 'Cabo Frio'},
    {state: 'RJ', value: 'Cachoeiras de Macacu'},
    {state: 'RJ', value: 'Cambuci'},
    {state: 'RJ', value: 'Campos dos Goytacazes'},
    {state: 'RJ', value: 'Cantagalo'},
    {state: 'RJ', value: 'Carapebus'},
    {state: 'RJ', value: 'Cardoso Moreira'},
    {state: 'RJ', value: 'Carmo'},
    {state: 'RJ', value: 'Casimiro de Abreu'},
    {state: 'RJ', value: 'Comendador Levy Gasparian'},
    {state: 'RJ', value: 'Conceição de Macabu'},
    {state: 'RJ', value: 'Cordeiro'},
    {state: 'RJ', value: 'Duas Barras'},
    {state: 'RJ', value: 'Duque de Caxias'},
    {state: 'RJ', value: 'Engenheiro Paulo de Frontin'},
    {state: 'RJ', value: 'Guapimirim'},
    {state: 'RJ', value: 'Iguaba Grande'},
    {state: 'RJ', value: 'Itaboraí'},
    {state: 'RJ', value: 'Itaguaí'},
    {state: 'RJ', value: 'Italva'},
    {state: 'RJ', value: 'Itaocara'},
    {state: 'RJ', value: 'Itaperuna'},
    {state: 'RJ', value: 'Itatiaia'},
    {state: 'RJ', value: 'Japeri'},
    {state: 'RJ', value: 'Laje do Muriaé'},
    {state: 'RJ', value: 'Macaé'},
    {state: 'RJ', value: 'Macuco'},
    {state: 'RJ', value: 'Magé'},
    {state: 'RJ', value: 'Mangaratiba'},
    {state: 'RJ', value: 'Maricá'},
    {state: 'RJ', value: 'Mendes'},
    {state: 'RJ', value: 'Mesquita'},
    {state: 'RJ', value: 'Miguel Pereira'},
    {state: 'RJ', value: 'Miracema'},
    {state: 'RJ', value: 'Natividade'},
    {state: 'RJ', value: 'Nilópolis'},
    {state: 'RJ', value: 'Niterói'},
    {state: 'RJ', value: 'Nova Friburgo'},
    {state: 'RJ', value: 'Nova Iguaçu'},
    {state: 'RJ', value: 'Paracambi'},
    {state: 'RJ', value: 'Paraíba do Sul'},
    {state: 'RJ', value: 'Paraty'},
    {state: 'RJ', value: 'Paty do Alferes'},
    {state: 'RJ', value: 'Petrópolis'},
    {state: 'RJ', value: 'Pinheiral'},
    {state: 'RJ', value: 'Piraí'},
    {state: 'RJ', value: 'Porciúncula'},
    {state: 'RJ', value: 'Porto Real'},
    {state: 'RJ', value: 'Quatis'},
    {state: 'RJ', value: 'Queimados'},
    {state: 'RJ', value: 'Quissamã'},
    {state: 'RJ', value: 'Resende'},
    {state: 'RJ', value: 'Rio Bonito'},
    {state: 'RJ', value: 'Rio Claro'},
    {state: 'RJ', value: 'Rio das Flores'},
    {state: 'RJ', value: 'Rio das Ostras'},
    {state: 'RJ', value: 'Rio de Janeiro'},
    {state: 'RJ', value: 'Santa Maria Madalena'},
    {state: 'RJ', value: 'Santo Antônio de Pádua'},
    {state: 'RJ', value: 'São Fidélis'},
    {state: 'RJ', value: 'São Francisco de Itabapoana'},
    {state: 'RJ', value: 'São Gonçalo'},
    {state: 'RJ', value: 'São João da Barra'},
    {state: 'RJ', value: 'São João de Meriti'},
    {state: 'RJ', value: 'São José de Ubá'},
    {state: 'RJ', value: 'São José do Vale do Rio Preto'},
    {state: 'RJ', value: 'São Pedro da Aldeia'},
    {state: 'RJ', value: 'São Sebastião do Alto'},
    {state: 'RJ', value: 'Sapucaia'},
    {state: 'RJ', value: 'Saquarema'},
    {state: 'RJ', value: 'Seropédica'},
    {state: 'RJ', value: 'Silva Jardim'},
    {state: 'RJ', value: 'Sumidouro'},
    {state: 'RJ', value: 'Tanguá'},
    {state: 'RJ', value: 'Teresópolis'},
    {state: 'RJ', value: 'Trajano de Moraes'},
    {state: 'RJ', value: 'Três Rios'},
    {state: 'RJ', value: 'Valença'},
    {state: 'RJ', value: 'Varre-Sai'},
    {state: 'RJ', value: 'Vassouras'},
    {state: 'RJ', value: 'Volta Redonda'},
    {state: 'MG', value: 'Belo Horizonte'},
    {state: 'MG', value: 'Uberlândia'},
    {state: 'MG', value: 'Contagem'},
    {state: 'MG', value: 'Juiz de Fora'},
    {state: 'MG', value: 'Betim'},
    {state: 'MG', value: 'Montes Claros'},
    {state: 'MG', value: 'Ribeirão das Neves'},
    {state: 'MG', value: 'Uberaba'},
    {state: 'MG', value: 'Governador Valadares'},
    {state: 'MG', value: 'Ipatinga'},
    {state: 'MG', value: 'Sete Lagoas'},
    {state: 'MG', value: 'Divinópolis'},
    {state: 'MG', value: 'Santa Luzia'},
    {state: 'MG', value: 'Ibirité'},
    {state: 'MG', value: 'Poços de Caldas'},
    {state: 'MG', value: 'Patos de Minas'},
    {state: 'MG', value: 'Pouso Alegre'},
    {state: 'MG', value: 'Teófilo Otoni'},
    {state: 'MG', value: 'Barbacena'},
    {state: 'MG', value: 'Sabará'},
    {state: 'MG', value: 'Varginha'},
    {state: 'MG', value: 'Conselheiro Lafaiete'},
    {state: 'MG', value: 'Vespasiano'},
    {state: 'MG', value: 'Itabira'},
    {state: 'MG', value: 'Araguari'},
    {state: 'MG', value: 'Ubá'},
    {state: 'MG', value: 'Passos'},
    {state: 'MG', value: 'Coronel Fabriciano'},
    {state: 'MG', value: 'Muriaé'},
    {state: 'MG', value: 'Araxá'},
    {state: 'MG', value: 'Ituiutaba'},
    {state: 'MG', value: 'Lavras'},
    {state: 'MG', value: 'Nova Serrana'},
    {state: 'MG', value: 'Itajubá'},
    {state: 'MG', value: 'Nova Lima'},
    {state: 'MG', value: 'Pará de Minas'},
    {state: 'MG', value: 'Itaúna'},
    {state: 'MG', value: 'Paracatu'},
    {state: 'MG', value: 'Caratinga'},
    {state: 'MG', value: 'Patrocínio'},
    {state: 'MG', value: 'Manhuaçu'},
    {state: 'MG', value: 'São João del Rei'},
    {state: 'MG', value: 'Timóteo'},
    {state: 'MG', value: 'Unaí'},
    {state: 'MG', value: 'Curvelo'},
    {state: 'MG', value: 'Alfenas'},
    {state: 'MG', value: 'João Monlevade'},
    {state: 'MG', value: 'Três Corações'},
    {state: 'MG', value: 'Viçosa'},
    {state: 'MG', value: 'Cataguases'},
    {state: 'MG', value: 'Ouro Preto'},
    {state: 'MG', value: 'Janaúba'},
    {state: 'MG', value: 'São Sebastião do Paraíso'},
    {state: 'MG', value: 'Esmeraldas'},
    {state: 'MG', value: 'Januária'},
    {state: 'MG', value: 'Formiga'},
    {state: 'MG', value: 'Lagoa Santa'},
    {state: 'MG', value: 'Pedro Leopoldo'},
    {state: 'MG', value: 'Mariana'},
    {state: 'MG', value: 'Ponte Nova'},
    {state: 'MG', value: 'Frutal'},
    {state: 'MG', value: 'Três Pontas'},
    {state: 'MG', value: 'Pirapora'},
    {state: 'MG', value: 'São Francisco'},
    {state: 'MG', value: 'Congonhas'},
    {state: 'MG', value: 'Campo Belo'},
    {state: 'MG', value: 'Leopoldina'},
    {state: 'MG', value: 'Lagoa da Prata'},
    {state: 'MG', value: 'Guaxupé'},
    {state: 'MG', value: 'Itabirito'},
    {state: 'MG', value: 'Bom Despacho'},
    {state: 'MG', value: 'Bocaiúva'},
    {state: 'MG', value: 'Monte Carmelo'},
    {state: 'MG', value: 'Diamantina'},
    {state: 'MG', value: 'João Pinheiro'},
    {state: 'MG', value: 'Santos Dumont'},
    {state: 'MG', value: 'São Lourenço'},
    {state: 'MG', value: 'Caeté'},
    {state: 'MG', value: 'Santa Rita do Sapucaí'},
    {state: 'MG', value: 'Igarapé'},
    {state: 'MG', value: 'Visconde do Rio Branco'},
    {state: 'MG', value: 'Machado'},
    {state: 'MG', value: 'Almenara'},
    {state: 'MG', value: 'Oliveira'},
    {state: 'MG', value: 'Salinas'},
    {state: 'MG', value: 'Andradas'},
    {state: 'MG', value: 'Nanuque'},
    {state: 'MG', value: 'Boa Esperança'},
    {state: 'MG', value: 'Brumadinho'},
    {state: 'MG', value: 'Arcos'},
    {state: 'MG', value: 'Ouro Branco'},
    {state: 'MG', value: 'Várzea da Palma'},
    {state: 'MG', value: 'Iturama'},
    {state: 'MG', value: 'Jaíba'},
    {state: 'MG', value: 'Porteirinha'},
    {state: 'MG', value: 'Matozinhos'},
    {state: 'MG', value: 'Capelinha'},
    {state: 'MG', value: 'Araçuaí'},
    {state: 'MG', value: 'Extrema'},
    {state: 'MG', value: 'São Gotardo'},
    {state: 'MG', value: 'Além Paraíba'},
    {state: 'MG', value: 'Itamarandiba'},
    {state: 'MG', value: 'Piumhi'},
    {state: 'MG', value: 'Santana do Paraíso'},
    {state: 'MG', value: 'Guanhães'},
    {state: 'MG', value: 'Taiobeiras'},
    {state: 'MG', value: 'Ouro Fino'},
    {state: 'MG', value: 'Carangola'},
    {state: 'MG', value: 'Sarzedo'},
    {state: 'MG', value: 'Barão de Cocais'},
    {state: 'MG', value: 'Três Marias'},
    {state: 'MG', value: 'Brasília de Minas'},
    {state: 'MG', value: 'Pompéu'},
    {state: 'MG', value: 'Espinosa'},
    {state: 'MG', value: 'São Joaquim de Bicas'},
    {state: 'MG', value: 'Minas Novas'},
    {state: 'MG', value: 'Novo Cruzeiro'},
    {state: 'MG', value: 'Santa Bárbara'},
    {state: 'MG', value: 'Mateus Leme'},
    {state: 'MG', value: 'Rio Pardo de Minas'},
    {state: 'MG', value: 'Carmo do Paranaíba'},
    {state: 'MG', value: 'Cambuí'},
    {state: 'MG', value: 'Campos Gerais'},
    {state: 'MG', value: 'Cláudio'},
    {state: 'MG', value: 'Santo Antônio do Monte'},
    {state: 'MG', value: 'Elói Mendes'},
    {state: 'MG', value: 'Buritizeiro'},
    {state: 'MG', value: 'Pitangui'},
    {state: 'MG', value: 'Coromandel'},
    {state: 'MG', value: 'Conceição das Alagoas'},
    {state: 'MG', value: 'Prata'},
    {state: 'MG', value: 'Mantena'},
    {state: 'MG', value: 'Mutum'},
    {state: 'MG', value: 'Juatuba'},
    {state: 'MG', value: 'Nepomuceno'},
    {state: 'MG', value: 'Belo Oriente'},
    {state: 'MG', value: 'Coração de Jesus'},
    {state: 'MG', value: 'São João Nepomuceno'},
    {state: 'MG', value: 'Francisco Sá'},
    {state: 'MG', value: 'Sacramento'},
    {state: 'MG', value: 'Jacutinga'},
    {state: 'MG', value: 'Carandaí'},
    {state: 'MG', value: 'São Gonçalo do Sapucaí'},
    {state: 'MG', value: 'Jequitinhonha'},
    {state: 'MG', value: 'Tupaciguara'},
    {state: 'MG', value: 'Ibiá'},
    {state: 'MG', value: 'Aimorés'},
    {state: 'MG', value: 'São João da Ponte'},
    {state: 'MG', value: 'Espera Feliz'},
    {state: 'MG', value: 'Buritis'},
    {state: 'MG', value: 'Paraopeba'},
    {state: 'MG', value: 'Pedra Azul'},
    {state: 'MG', value: 'Inhapim'},
    {state: 'MG', value: 'Bambuí'},
    {state: 'MG', value: 'Monte Sião'},
    {state: 'MG', value: 'São José da Lapa'},
    {state: 'MG', value: 'Raul Soares'},
    {state: 'MG', value: 'Corinto'},
    {state: 'MG', value: 'Caraí'},
    {state: 'MG', value: 'São João do Paraíso'},
    {state: 'MG', value: 'Abaeté'},
    {state: 'MG', value: 'Itambacuri'},
    {state: 'MG', value: 'Conselheiro Pena'},
    {state: 'MG', value: 'Manhumirim'},
    {state: 'MG', value: 'Carmo do Cajuru'},
    {state: 'MG', value: 'Camanducaia'},
    {state: 'MG', value: 'Itapecerica'},
    {state: 'MG', value: 'Caxambu'},
    {state: 'MG', value: 'Monte Santo de Minas'},
    {state: 'MG', value: 'Paraguaçu'},
    {state: 'MG', value: 'Perdões'},
    {state: 'MG', value: 'Carmo do Rio Claro'},
    {state: 'MG', value: 'Monte Alegre de Minas'},
    {state: 'MG', value: 'Paraisópolis'},
    {state: 'MG', value: 'Itaobim'},
    {state: 'MG', value: 'Campestre'},
    {state: 'MG', value: 'Serro'},
    {state: 'MG', value: 'Monte Azul'},
    {state: 'MG', value: 'Medina'},
    {state: 'MG', value: 'Lambari'},
    {state: 'MG', value: 'Barroso'},
    {state: 'MG', value: 'Vazante'},
    {state: 'MG', value: 'Muzambinho'},
    {state: 'MG', value: 'Padre Paraíso'},
    {state: 'MG', value: 'Jaboticatubas'},
    {state: 'MG', value: 'Turmalina'},
    {state: 'MG', value: 'Divino'},
    {state: 'MG', value: 'Lajinha'},
    {state: 'MG', value: 'Ipanema'},
    {state: 'MG', value: 'Alpinópolis'},
    {state: 'MG', value: 'Campina Verde'},
    {state: 'MG', value: 'Santa Vitória'},
    {state: 'MG', value: 'Simonésia'},
    {state: 'MG', value: 'Presidente Olegário'},
    {state: 'MG', value: 'Borda da Mata'},
    {state: 'MG', value: 'Carmópolis de Minas'},
    {state: 'MG', value: 'Varzelândia'},
    {state: 'MG', value: 'Águas Formosas'},
    {state: 'MG', value: 'Baependi'},
    {state: 'MG', value: 'Guaranésia'},
    {state: 'MG', value: 'Matipó'},
    {state: 'MG', value: 'Ervália'},
    {state: 'MG', value: 'Carlos Chagas'},
    {state: 'MG', value: 'Malacacheta'},
    {state: 'MG', value: 'Ipaba'},
    {state: 'MG', value: 'Santo Antônio do Amparo'},
    {state: 'MG', value: 'Manga'},
    {state: 'MG', value: 'Luz'},
    {state: 'MG', value: 'Itacarambi'},
    {state: 'MG', value: 'Ladainha'},
    {state: 'MG', value: 'Fronteira'},
    {state: 'MG', value: 'Lagoa Formosa'},
    {state: 'MG', value: 'Rio Pomba'},
    {state: 'MG', value: 'Arinos'},
    {state: 'MG', value: 'Conceição do Mato Dentro'},
    {state: 'MG', value: 'Cássia'},
    {state: 'MG', value: 'Piranga'},
    {state: 'MG', value: 'Bom Sucesso'},
    {state: 'MG', value: 'Nova Era'},
    {state: 'MG', value: 'Peçanha'},
    {state: 'MG', value: 'Resplendor'},
    {state: 'MG', value: 'São Domingos do Prata'},
    {state: 'MG', value: 'Urucuia'},
    {state: 'MG', value: 'Poço Fundo'},
    {state: 'MG', value: 'Nova Resende'},
    {state: 'MG', value: 'Lima Duarte'},
    {state: 'MG', value: 'Campanha'},
    {state: 'MG', value: 'Tocantins'},
    {state: 'MG', value: 'Poté'},
    {state: 'MG', value: 'Brasilândia de Minas'},
    {state: 'MG', value: 'Raposos'},
    {state: 'MG', value: 'Passa Quatro'},
    {state: 'MG', value: 'Santa Margarida'},
    {state: 'MG', value: 'Capinópolis'},
    {state: 'MG', value: 'Perdizes'},
    {state: 'MG', value: 'Itaú de Minas'},
    {state: 'MG', value: 'Grão Mogol'},
    {state: 'MG', value: 'São João Evangelista'},
    {state: 'MG', value: 'Papagaios'},
    {state: 'MG', value: 'Itamonte'},
    {state: 'MG', value: 'Nova Ponte'},
    {state: 'MG', value: 'Sabinópolis'},
    {state: 'MG', value: 'Campos Altos'},
    {state: 'MG', value: 'Joaíma'},
    {state: 'MG', value: 'Cruzília'},
    {state: 'MG', value: 'Mário Campos'},
    {state: 'MG', value: 'Chapada do Norte'},
    {state: 'MG', value: 'Felixlândia'},
    {state: 'MG', value: 'Itanhandu'},
    {state: 'MG', value: 'Entre Rios de Minas'},
    {state: 'MG', value: 'Itapagipe'},
    {state: 'MG', value: 'Capitão Enéas'},
    {state: 'MG', value: 'Alvinópolis'},
    {state: 'MG', value: 'Areado'},
    {state: 'MG', value: 'Miraí'},
    {state: 'MG', value: 'Itinga'},
    {state: 'MG', value: 'Botelhos'},
    {state: 'MG', value: 'Bom Jesus do Galho'},
    {state: 'MG', value: 'Candeias'},
    {state: 'MG', value: 'Montalvânia'},
    {state: 'MG', value: 'Carmo de Minas'},
    {state: 'MG', value: 'Santa Maria do Suaçuí'},
    {state: 'MG', value: 'Bicas'},
    {state: 'MG', value: 'Caldas'},
    {state: 'MG', value: 'Matias Barbosa'},
    {state: 'MG', value: 'Alterosa'},
    {state: 'MG', value: 'Brazópolis'},
    {state: 'MG', value: 'Rio Piracicaba'},
    {state: 'MG', value: 'Tarumirim'},
    {state: 'MG', value: 'Guapé'},
    {state: 'MG', value: 'Astolfo Dutra'},
    {state: 'MG', value: 'Maria da Fé'},
    {state: 'MG', value: 'Cabo Verde'},
    {state: 'MG', value: 'Santa Juliana'},
    {state: 'MG', value: 'Ibiraci'},
    {state: 'MG', value: 'Virgem da Lapa'},
    {state: 'MG', value: 'Água Boa'},
    {state: 'MG', value: 'Chapada Gaúcha'},
    {state: 'MG', value: 'Conceição do Rio Verde'},
    {state: 'MG', value: 'Mirabela'},
    {state: 'MG', value: 'Rio Casca'},
    {state: 'MG', value: 'Águas Vermelhas'},
    {state: 'MG', value: 'Dores do Indaiá'},
    {state: 'MG', value: 'Abre Campo'},
    {state: 'MG', value: 'Martinho Campos'},
    {state: 'MG', value: 'Itaguara'},
    {state: 'MG', value: 'Monte Belo'},
    {state: 'MG', value: 'São João das Missões'},
    {state: 'MG', value: 'Lagoa Dourada'},
    {state: 'MG', value: 'Ataléia'},
    {state: 'MG', value: 'Rio Vermelho'},
    {state: 'MG', value: 'Cambuquira'},
    {state: 'MG', value: 'Mar de Espanha'},
    {state: 'MG', value: 'Cristais'},
    {state: 'MG', value: 'Itaipé'},
    {state: 'MG', value: 'Ubaí'},
    {state: 'MG', value: 'Ubaporanga'},
    {state: 'MG', value: 'Mato Verde'},
    {state: 'MG', value: 'São Gonçalo do Pará'},
    {state: 'MG', value: 'Jequeri'},
    {state: 'MG', value: 'Ilicínea'},
    {state: 'MG', value: 'São Geraldo'},
    {state: 'MG', value: 'São Romão'},
    {state: 'MG', value: 'Jacinto'},
    {state: 'MG', value: 'Rio Paranaíba'},
    {state: 'MG', value: 'Setubinha'},
    {state: 'MG', value: 'Itanhomi'},
    {state: 'MG', value: 'Andrelândia'},
    {state: 'MG', value: 'Carmo da Cachoeira'},
    {state: 'MG', value: 'Canápolis'},
    {state: 'MG', value: 'Planura'},
    {state: 'MG', value: 'Ponto dos Volantes'},
    {state: 'MG', value: 'Pedras de Maria da Cruz'},
    {state: 'MG', value: 'Icaraí de Minas'},
    {state: 'MG', value: 'Congonhal'},
    {state: 'MG', value: 'Berilo'},
    {state: 'MG', value: 'Gouveia'},
    {state: 'MG', value: 'Teixeiras'},
    {state: 'MG', value: 'Campo do Meio'},
    {state: 'MG', value: 'Santo Antônio do Jacinto'},
    {state: 'MG', value: 'Conceição dos Ouros'},
    {state: 'MG', value: 'Caetanópolis'},
    {state: 'MG', value: 'Serra do Salitre'},
    {state: 'MG', value: 'São João do Manhuaçu'},
    {state: 'MG', value: 'Cachoeira de Minas'},
    {state: 'MG', value: 'Itabirinha'},
    {state: 'MG', value: 'Perdigão'},
    {state: 'MG', value: 'Resende Costa'},
    {state: 'MG', value: 'Carmo da Mata'},
    {state: 'MG', value: 'Antônio Carlos'},
    {state: 'MG', value: 'Estiva'},
    {state: 'MG', value: 'Porto Firme'},
    {state: 'MG', value: 'Eugenópolis'},
    {state: 'MG', value: 'Bonito de Minas'},
    {state: 'MG', value: 'Pedralva'},
    {state: 'MG', value: 'Matias Cardoso'},
    {state: 'MG', value: 'Itatiaiuçu'},
    {state: 'MG', value: 'Engenheiro Caldas'},
    {state: 'MG', value: 'Divisópolis'},
    {state: 'MG', value: 'Fervedouro'},
    {state: 'MG', value: 'Iapu'},
    {state: 'MG', value: 'Bueno Brandão'},
    {state: 'MG', value: 'Alto Rio Doce'},
    {state: 'MG', value: 'São Tiago'},
    {state: 'MG', value: 'São Gonçalo do Rio Abaixo'},
    {state: 'MG', value: 'Igaratinga'},
    {state: 'MG', value: 'Santa Maria de Itabira'},
    {state: 'MG', value: 'Jordânia'},
    {state: 'MG', value: 'Piraúba'},
    {state: 'MG', value: 'Arceburgo'},
    {state: 'MG', value: 'Novo Oriente de Minas'},
    {state: 'MG', value: 'Miradouro'},
    {state: 'MG', value: 'Pirapetinga'},
    {state: 'MG', value: 'Mercês'},
    {state: 'MG', value: 'Prudente de Morais'},
    {state: 'MG', value: 'Juruaia'},
    {state: 'MG', value: 'Bom Repouso'},
    {state: 'MG', value: 'Delta'},
    {state: 'MG', value: 'Recreio'},
    {state: 'MG', value: 'Virginópolis'},
    {state: 'MG', value: 'Senhora dos Remédios'},
    {state: 'MG', value: 'Buenópolis'},
    {state: 'MG', value: 'Urucânia'},
    {state: 'MG', value: 'Centralina'},
    {state: 'MG', value: 'Francisco Badaró'},
    {state: 'MG', value: 'Guaraciaba'},
    {state: 'MG', value: 'Rio Acima'},
    {state: 'MG', value: 'Ninheira'},
    {state: 'MG', value: 'Conceição da Aparecida'},
    {state: 'MG', value: 'Bela Vista de Minas'},
    {state: 'MG', value: 'Cristina'},
    {state: 'MG', value: 'Rubim'},
    {state: 'MG', value: 'Itamogi'},
    {state: 'MG', value: 'Dores de Campos'},
    {state: 'MG', value: 'Ipuiúna'},
    {state: 'MG', value: 'São Sebastião do Maranhão'},
    {state: 'MG', value: 'Carneirinho'},
    {state: 'MG', value: 'Coroaci'},
    {state: 'MG', value: 'Ferros'},
    {state: 'MG', value: 'Itapeva'},
    {state: 'MG', value: 'Capim Branco'},
    {state: 'MG', value: 'Lontra'},
    {state: 'MG', value: 'Frei Inocêncio'},
    {state: 'MG', value: 'Paula Cândido'},
    {state: 'MG', value: 'Formoso'},
    {state: 'MG', value: 'Lagoa Grande'},
    {state: 'MG', value: 'Riacho dos Machados'},
    {state: 'MG', value: 'Açucena'},
    {state: 'MG', value: 'Cachoeira de Pajeú'},
    {state: 'MG', value: 'Carbonita'},
    {state: 'MG', value: 'Verdelândia'},
    {state: 'MG', value: 'Antônio Dias'},
    {state: 'MG', value: 'Caputira'},
    {state: 'MG', value: 'Araújos'},
    {state: 'MG', value: 'Coronel Murta'},
    {state: 'MG', value: 'Coqueiral'},
    {state: 'MG', value: 'Prados'},
    {state: 'MG', value: 'Rio Novo'},
    {state: 'MG', value: 'Santa Rita de Caldas'},
    {state: 'MG', value: 'Guarani'},
    {state: 'MG', value: 'Cordisburgo'},
    {state: 'MG', value: 'Coluna'},
    {state: 'MG', value: 'Morada Nova de Minas'},
    {state: 'MG', value: 'Serra dos Aimorés'},
    {state: 'MG', value: 'Monsenhor Paulo'},
    {state: 'MG', value: 'Santana do Manhuaçu'},
    {state: 'MG', value: 'Virgínia'},
    {state: 'MG', value: 'Pimenta'},
    {state: 'MG', value: 'Capitólio'},
    {state: 'MG', value: 'Nazareno'},
    {state: 'MG', value: 'Santa Cruz de Minas'},
    {state: 'MG', value: 'Pratápolis'},
    {state: 'MG', value: 'Piranguinho'},
    {state: 'MG', value: 'Mata Verde'},
    {state: 'MG', value: 'Piedade de Caratinga'},
    {state: 'MG', value: 'Angelândia'},
    {state: 'MG', value: 'Pavão'},
    {state: 'MG', value: 'Araponga'},
    {state: 'MG', value: 'Pocrane'},
    {state: 'MG', value: 'Martins Soares'},
    {state: 'MG', value: 'Ibiaí'},
    {state: 'MG', value: 'Guiricema'},
    {state: 'MG', value: 'São Gonçalo do Abaeté'},
    {state: 'MG', value: 'Alto Jequitibá'},
    {state: 'MG', value: 'Pains'},
    {state: 'MG', value: 'Montezuma'},
    {state: 'MG', value: 'Campo Florido'},
    {state: 'MG', value: 'Santa Bárbara do Leste'},
    {state: 'MG', value: 'Riachinho'},
    {state: 'MG', value: 'Rodeiro'},
    {state: 'MG', value: 'Passa Tempo'},
    {state: 'MG', value: 'Guimarânia'},
    {state: 'MG', value: 'Delfim Moreira'},
    {state: 'MG', value: 'Tombos'},
    {state: 'MG', value: 'Orizânia'},
    {state: 'MG', value: 'Tiradentes'},
    {state: 'MG', value: 'Estrela do Sul'},
    {state: 'MG', value: 'Maravilhas'},
    {state: 'MG', value: 'Japonvar'},
    {state: 'MG', value: 'Iguatama'},
    {state: 'MG', value: 'Durandé'},
    {state: 'MG', value: 'Baldim'},
    {state: 'MG', value: 'Senador Firmino'},
    {state: 'MG', value: 'São Pedro dos Ferros'},
    {state: 'MG', value: 'São Vicente de Minas'},
    {state: 'MG', value: 'Curral de Dentro'},
    {state: 'MG', value: 'Dionísio'},
    {state: 'MG', value: 'Belo Vale'},
    {state: 'MG', value: 'Jenipapo de Minas'},
    {state: 'MG', value: 'Jacuí'},
    {state: 'MG', value: 'Serrania'},
    {state: 'MG', value: 'Cônego Marinho'},
    {state: 'MG', value: 'Santana de Pirapama'},
    {state: 'MG', value: 'Lagamar'},
    {state: 'MG', value: 'Divinolândia de Minas'},
    {state: 'MG', value: 'Coimbra'},
    {state: 'MG', value: 'Claro dos Poções'},
    {state: 'MG', value: 'Limeira do Oeste'},
    {state: 'MG', value: 'Jequitaí'},
    {state: 'MG', value: 'Moema'},
    {state: 'MG', value: 'Inimutaba'},
    {state: 'MG', value: 'Pintópolis'},
    {state: 'MG', value: 'Nova Porteirinha'},
    {state: 'MG', value: 'São João do Oriente'},
    {state: 'MG', value: 'Florestal'},
    {state: 'MG', value: 'Felisburgo'},
    {state: 'MG', value: 'São João Batista do Glória'},
    {state: 'MG', value: 'São José da Barra'},
    {state: 'MG', value: 'Alpercata'},
    {state: 'MG', value: 'Indaiabira'},
    {state: 'MG', value: 'Inconfidentes'},
    {state: 'MG', value: 'Sericita'},
    {state: 'MG', value: 'Santo Antônio do Retiro'},
    {state: 'MG', value: 'Desterro de Entre Rios'},
    {state: 'MG', value: 'Engenheiro Navarro'},
    {state: 'MG', value: 'Santa Rita de Minas'},
    {state: 'MG', value: 'Reduto'},
    {state: 'MG', value: 'Delfinópolis'},
    {state: 'MG', value: 'Machacalis'},
    {state: 'MG', value: 'Santana da Vargem'},
    {state: 'MG', value: 'Pedra Bonita'},
    {state: 'MG', value: 'São Thomé das Letras'},
    {state: 'MG', value: 'Guidoval'},
    {state: 'MG', value: 'São Roque de Minas'},
    {state: 'MG', value: 'Varjão de Minas'},
    {state: 'MG', value: 'Central de Minas'},
    {state: 'MG', value: 'São Tomás de Aquino'},
    {state: 'MG', value: 'Salto da Divisa'},
    {state: 'MG', value: 'Naque'},
    {state: 'MG', value: 'Abadia dos Dourados'},
    {state: 'MG', value: 'Iraí de Minas'},
    {state: 'MG', value: 'Cabeceira Grande'},
    {state: 'MG', value: 'Conquista'},
    {state: 'MG', value: 'São Miguel do Anta'},
    {state: 'MG', value: 'Sapucaí-Mirim'},
    {state: 'MG', value: 'Comercinho'},
    {state: 'MG', value: 'Capetinga'},
    {state: 'MG', value: 'Alfredo Vasconcelos'},
    {state: 'MG', value: 'Imbé de Minas'},
    {state: 'MG', value: 'Indianópolis'},
    {state: 'MG', value: 'Araporã'},
    {state: 'MG', value: 'Bonfim'},
    {state: 'MG', value: 'Galiléia'},
    {state: 'MG', value: 'Laranjal'},
    {state: 'MG', value: 'Periquito'},
    {state: 'MG', value: 'Cipotânea'},
    {state: 'MG', value: 'Divisa Alegre'},
    {state: 'MG', value: 'São Sebastião do Oeste'},
    {state: 'MG', value: 'Careaçu'},
    {state: 'MG', value: 'Tumiritinga'},
    {state: 'MG', value: 'Confins'},
    {state: 'MG', value: 'Crisólita'},
    {state: 'MG', value: 'Luislândia'},
    {state: 'MG', value: 'Palma'},
    {state: 'MG', value: 'Guarda-Mor'},
    {state: 'MG', value: 'Dona Eusébia'},
    {state: 'MG', value: 'Heliodora'},
    {state: 'MG', value: 'São Sebastião do Anta'},
    {state: 'MG', value: 'Ijaci'},
    {state: 'MG', value: 'Mamonas'},
    {state: 'MG', value: 'São Francisco de Paula'},
    {state: 'MG', value: 'Lassance'},
    {state: 'MG', value: 'Tiros'},
    {state: 'MG', value: 'Vargem Alegre'},
    {state: 'MG', value: 'Bom Jardim de Minas'},
    {state: 'MG', value: 'São José do Jacuri'},
    {state: 'MG', value: 'Mendes Pimentel'},
    {state: 'MG', value: 'Piracema'},
    {state: 'MG', value: 'Padre Carvalho'},
    {state: 'MG', value: 'Santa Helena de Minas'},
    {state: 'MG', value: 'Córrego Fundo'},
    {state: 'MG', value: 'Botumirim'},
    {state: 'MG', value: 'Catuji'},
    {state: 'MG', value: 'Sardoá'},
    {state: 'MG', value: 'Inhaúma'},
    {state: 'MG', value: 'Luisburgo'},
    {state: 'MG', value: 'Toledo'},
    {state: 'MG', value: 'São Francisco de Sales'},
    {state: 'MG', value: 'Silvianópolis'},
    {state: 'MG', value: 'Pirajuba'},
    {state: 'MG', value: 'Gonzaga'},
    {state: 'MG', value: 'Soledade de Minas'},
    {state: 'MG', value: "Olhos-d'Água"},
    {state: 'MG', value: 'Pai Pedro'},
    {state: 'MG', value: 'Bom Jesus do Amparo'},
    {state: 'MG', value: 'Itueta'},
    {state: 'MG', value: 'Munhoz'},
    {state: 'MG', value: 'Itumirim'},
    {state: 'MG', value: 'Divisa Nova'},
    {state: 'MG', value: 'Aiuruoca'},
    {state: 'MG', value: 'Rubelita'},
    {state: 'MG', value: 'Patis'},
    {state: 'MG', value: 'Cristália'},
    {state: 'MG', value: 'Pouso Alto'},
    {state: 'MG', value: 'Ouro Verde de Minas'},
    {state: 'MG', value: 'Frei Gaspar'},
    {state: 'MG', value: 'São João do Manteninha'},
    {state: 'MG', value: 'Alto Caparaó'},
    {state: 'MG', value: 'Rio Manso'},
    {state: 'MG', value: 'Senhora de Oliveira'},
    {state: 'MG', value: 'Bandeira do Sul'},
    {state: 'MG', value: 'Nova União'},
    {state: 'MG', value: 'Juvenília'},
    {state: 'MG', value: 'Veredinha'},
    {state: 'MG', value: 'Chalé'},
    {state: 'MG', value: 'Patrocínio do Muriaé'},
    {state: 'MG', value: 'São Domingos das Dores'},
    {state: 'MG', value: 'Gurinhatã'},
    {state: 'MG', value: 'Mesquita'},
    {state: 'MG', value: 'Cana Verde'},
    {state: 'MG', value: 'Sobrália'},
    {state: 'MG', value: 'Conceição do Pará'},
    {state: 'MG', value: 'Palmópolis'},
    {state: 'MG', value: 'São Sebastião da Bela Vista'},
    {state: 'MG', value: 'Caiana'},
    {state: 'MG', value: 'Bonfinópolis de Minas'},
    {state: 'MG', value: 'Santa Rita do Itueto'},
    {state: 'MG', value: 'Rio Preto'},
    {state: 'MG', value: 'Rio Espera'},
    {state: 'MG', value: 'Piranguçu'},
    {state: 'MG', value: 'Capitão Andrade'},
    {state: 'MG', value: 'Luminárias'},
    {state: 'MG', value: 'Caparaó'},
    {state: 'MG', value: 'São José do Goiabal'},
    {state: 'MG', value: 'Itaverava'},
    {state: 'MG', value: 'Datas'},
    {state: 'MG', value: 'Jampruca'},
    {state: 'MG', value: 'Ibiracatu'},
    {state: 'MG', value: 'Barão de Monte Alto'},
    {state: 'MG', value: 'Franciscópolis'},
    {state: 'MG', value: 'Itacambira'},
    {state: 'MG', value: 'Virgolândia'},
    {state: 'MG', value: 'Catas Altas'},
    {state: 'MG', value: 'Entre Folhas'},
    {state: 'MG', value: 'Fruta de Leite'},
    {state: 'MG', value: 'Presidente Bernardes'},
    {state: 'MG', value: 'Senador Amaral'},
    {state: 'MG', value: 'Novorizonte'},
    {state: 'MG', value: 'São Bento Abade'},
    {state: 'MG', value: 'Volta Grande'},
    {state: 'MG', value: 'São Pedro do Suaçuí'},
    {state: 'MG', value: 'Dom Silvério'},
    {state: 'MG', value: 'Santa Maria do Salto'},
    {state: 'MG', value: 'Aricanduva'},
    {state: 'MG', value: 'Francisco Dumont'},
    {state: 'MG', value: 'Jequitibá'},
    {state: 'MG', value: 'Dores de Guanhães'},
    {state: 'MG', value: 'Cristiano Otoni'},
    {state: 'MG', value: 'Rio do Prado'},
    {state: 'MG', value: 'Barra Longa'},
    {state: 'MG', value: 'Gameleiras'},
    {state: 'MG', value: 'Madre de Deus de Minas'},
    {state: 'MG', value: 'Bocaina de Minas'},
    {state: 'MG', value: 'Dom Cavati'},
    {state: 'MG', value: 'Liberdade'},
    {state: 'MG', value: 'Congonhas do Norte'},
    {state: 'MG', value: 'Turvolândia'},
    {state: 'MG', value: 'Crucilândia'},
    {state: 'MG', value: 'Ibertioga'},
    {state: 'MG', value: 'Descoberto'},
    {state: 'MG', value: 'Vargem Grande do Rio Pardo'},
    {state: 'MG', value: 'São José da Varginha'},
    {state: 'MG', value: 'José Raydan'},
    {state: 'MG', value: 'Catuti'},
    {state: 'MG', value: 'Cuparaque'},
    {state: 'MG', value: 'Piedade dos Gerais'},
    {state: 'MG', value: 'Divino das Laranjeiras'},
    {state: 'MG', value: 'Guaraciama'},
    {state: 'MG', value: "Pingo d'Água"},
    {state: 'MG', value: 'Moeda'},
    {state: 'MG', value: 'Leme do Prado'},
    {state: 'MG', value: 'São João da Lagoa'},
    {state: 'MG', value: 'Jeceaba'},
    {state: 'MG', value: 'Monte Formoso'},
    {state: 'MG', value: 'Miravânia'},
    {state: 'MG', value: 'Santa Rita de Jacutinga'},
    {state: 'MG', value: 'Augusto de Lima'},
    {state: 'MG', value: 'Josenópolis'},
    {state: 'MG', value: 'São Francisco do Glória'},
    {state: 'MG', value: 'Claraval'},
    {state: 'MG', value: 'Vermelho Novo'},
    {state: 'MG', value: 'Paulistas'},
    {state: 'MG', value: 'Santana do Jacaré'},
    {state: 'MG', value: 'Ressaquinha'},
    {state: 'MG', value: 'Braúnas'},
    {state: 'MG', value: 'Bandeira'},
    {state: 'MG', value: 'Jesuânia'},
    {state: 'MG', value: 'Serranópolis de Minas'},
    {state: 'MG', value: 'Tapira'},
    {state: 'MG', value: 'Santa Cruz do Escalvado'},
    {state: 'MG', value: 'Felício dos Santos'},
    {state: 'MG', value: 'Berizal'},
    {state: 'MG', value: 'Natércia'},
    {state: 'MG', value: 'Amparo do Serra'},
    {state: 'MG', value: 'Joaquim Felício'},
    {state: 'MG', value: 'Espírito Santo do Dourado'},
    {state: 'MG', value: 'São Pedro da União'},
    {state: 'MG', value: 'Oratórios'},
    {state: 'MG', value: 'Capela Nova'},
    {state: 'MG', value: 'Bertópolis'},
    {state: 'MG', value: 'Ritápolis'},
    {state: 'MG', value: 'Rosário da Limeira'},
    {state: 'MG', value: 'Fronteira dos Vales'},
    {state: 'MG', value: 'Conceição de Ipanema'},
    {state: 'MG', value: 'Joanésia'},
    {state: 'MG', value: 'Canaã'},
    {state: 'MG', value: 'Cantagalo'},
    {state: 'MG', value: 'José Gonçalves de Minas'},
    {state: 'MG', value: 'Piedade do Rio Grande'},
    {state: 'MG', value: 'Aguanil'},
    {state: 'MG', value: 'Paineiras'},
    {state: 'MG', value: 'Carvalhos'},
    {state: 'MG', value: 'Materlândia'},
    {state: 'MG', value: 'Santa Bárbara do Tugúrio'},
    {state: 'MG', value: 'São João do Pacuí'},
    {state: 'MG', value: 'Fortaleza de Minas'},
    {state: 'MG', value: 'Couto de Magalhães de Minas'},
    {state: 'MG', value: 'Santa Efigênia de Minas'},
    {state: 'MG', value: 'Pequi'},
    {state: 'MG', value: 'Itamarati de Minas'},
    {state: 'MG', value: 'Gonçalves'},
    {state: 'MG', value: 'Japaraíba'},
    {state: 'MG', value: 'Funilândia'},
    {state: 'MG', value: 'Brás Pires'},
    {state: 'MG', value: 'Juramento'},
    {state: 'MG', value: 'União de Minas'},
    {state: 'MG', value: 'Santana do Riacho'},
    {state: 'MG', value: 'Serra Azul de Minas'},
    {state: 'MG', value: 'São José da Safira'},
    {state: 'MG', value: 'Ponto Chique'},
    {state: 'MG', value: 'Dores do Turvo'},
    {state: 'MG', value: 'Pescador'},
    {state: 'MG', value: 'Ipiaçu'},
    {state: 'MG', value: 'Bom Jesus da Penha'},
    {state: 'MG', value: 'São José do Alegre'},
    {state: 'MG', value: 'Dom Joaquim'},
    {state: 'MG', value: 'Senador Modestino Gonçalves'},
    {state: 'MG', value: 'Santa Cruz de Salinas'},
    {state: 'MG', value: 'Piedade de Ponte Nova'},
    {state: 'MG', value: 'Marilac'},
    {state: 'MG', value: 'Lagoa dos Patos'},
    {state: 'MG', value: 'Tocos do Moji'},
    {state: 'MG', value: 'Taquaraçu de Minas'},
    {state: 'MG', value: 'Carrancas'},
    {state: 'MG', value: 'Marliéria'},
    {state: 'MG', value: 'Ribeirão Vermelho'},
    {state: 'MG', value: 'São Geraldo do Baixio'},
    {state: 'MG', value: 'Veríssimo'},
    {state: 'MG', value: 'Acaiaca'},
    {state: 'MG', value: 'Cajuri'},
    {state: 'MG', value: 'Bugre'},
    {state: 'MG', value: 'Santana do Deserto'},
    {state: 'MG', value: 'Pedra do Indaiá'},
    {state: 'MG', value: 'Goianá'},
    {state: 'MG', value: 'São Geraldo da Piedade'},
    {state: 'MG', value: 'Conceição da Barra de Minas'},
    {state: 'MG', value: 'Ewbank da Câmara'},
    {state: 'MG', value: 'Santo Antônio do Grama'},
    {state: 'MG', value: 'Alvarenga'},
    {state: 'MG', value: 'Minduri'},
    {state: 'MG', value: 'Santana de Cataguases'},
    {state: 'MG', value: 'São José do Divino'},
    {state: 'MG', value: 'Santa Fé de Minas'},
    {state: 'MG', value: 'Santo Antônio do Itambé'},
    {state: 'MG', value: 'Campo Azul'},
    {state: 'MG', value: 'Diogo de Vasconcelos'},
    {state: 'MG', value: 'Medeiros'},
    {state: 'MG', value: 'Guarará'},
    {state: 'MG', value: 'Itutinga'},
    {state: 'MG', value: 'Santana dos Montes'},
    {state: 'MG', value: 'Tabuleiro'},
    {state: 'MG', value: 'Matutina'},
    {state: 'MG', value: 'São Brás do Suaçuí'},
    {state: 'MG', value: 'Campanário'},
    {state: 'MG', value: 'Córrego do Bom Jesus'},
    {state: 'MG', value: 'Dom Bosco'},
    {state: 'MG', value: 'Catas Altas da Noruega'},
    {state: 'MG', value: 'Presidente Juscelino'},
    {state: 'MG', value: 'Pedrinópolis'},
    {state: 'MG', value: 'Cruzeiro da Fortaleza'},
    {state: 'MG', value: 'Vieiras'},
    {state: 'MG', value: 'Alvorada de Minas'},
    {state: 'MG', value: 'Cachoeira da Prata'},
    {state: 'MG', value: 'Pratinha'},
    {state: 'MG', value: 'Santo Antônio do Aventureiro'},
    {state: 'MG', value: 'Nova Módica'},
    {state: 'MG', value: 'Carvalhópolis'},
    {state: 'MG', value: 'Quartel Geral'},
    {state: 'MG', value: 'Cordislândia'},
    {state: 'MG', value: 'Romaria'},
    {state: 'MG', value: 'Senhora do Porto'},
    {state: 'MG', value: 'Estrela do Indaiá'},
    {state: 'MG', value: 'Ibitiúra de Minas'},
    {state: 'MG', value: 'Frei Lagonegro'},
    {state: 'MG', value: 'Coronel Xavier Chaves'},
    {state: 'MG', value: 'Fernandes Tourinho'},
    {state: 'MG', value: 'Belmiro Braga'},
    {state: 'MG', value: 'Santa Rita de Ibitipoca'},
    {state: 'MG', value: 'Divinésia'},
    {state: 'MG', value: 'Lamim'},
    {state: 'MG', value: 'Bias Fortes'},
    {state: 'MG', value: 'São Félix de Minas'},
    {state: 'MG', value: 'Goiabeira'},
    {state: 'MG', value: 'Santa Rosa da Serra'},
    {state: 'MG', value: 'Pequeri'},
    {state: 'MG', value: 'Natalândia'},
    {state: 'MG', value: 'Uruana de Minas'},
    {state: 'MG', value: 'Faria Lemos'},
    {state: 'MG', value: 'Leandro Ferreira'},
    {state: 'MG', value: 'Nacip Raydan'},
    {state: 'MG', value: 'Córrego Danta'},
    {state: 'MG', value: 'Mathias Lobato'},
    {state: 'MG', value: 'Nova Belém'},
    {state: 'MG', value: 'Caranaíba'},
    {state: 'MG', value: 'Morro do Pilar'},
    {state: 'MG', value: 'São Gonçalo do Rio Preto'},
    {state: 'MG', value: 'Chácara'},
    {state: 'MG', value: 'Glaucilândia'},
    {state: 'MG', value: 'Santa Bárbara do Monte Verde'},
    {state: 'MG', value: 'Onça de Pitangui'},
    {state: 'MG', value: 'Jaguaraçu'},
    {state: 'MG', value: 'Comendador Gomes'},
    {state: 'MG', value: 'Taparuba'},
    {state: 'MG', value: 'Santo Hipólito'},
    {state: 'MG', value: 'Coronel Pacheco'},
    {state: 'MG', value: 'Cascalho Rico'},
    {state: 'MG', value: 'Pedra do Anta'},
    {state: 'MG', value: 'Albertina'},
    {state: 'MG', value: 'São Sebastião da Vargem Alegre'},
    {state: 'MG', value: 'Presidente Kubitschek'},
    {state: 'MG', value: 'Dom Viçoso'},
    {state: 'MG', value: 'Ibituruna'},
    {state: 'MG', value: 'Maripá de Minas'},
    {state: 'MG', value: 'Fortuna de Minas'},
    {state: 'MG', value: 'Camacho'},
    {state: 'MG', value: 'Desterro do Melo'},
    {state: 'MG', value: 'Arapuá'},
    {state: 'MG', value: 'Conceição das Pedras'},
    {state: 'MG', value: 'Arantina'},
    {state: 'MG', value: 'São José do Mantimento'},
    {state: 'MG', value: 'Olímpio Noronha'},
    {state: 'MG', value: 'Córrego Novo'},
    {state: 'MG', value: 'Ingaí'},
    {state: 'MG', value: 'Marmelópolis'},
    {state: 'MG', value: 'São João da Mata'},
    {state: 'MG', value: 'Piau'},
    {state: 'MG', value: 'Argirita'},
    {state: 'MG', value: 'Cachoeira Dourada'},
    {state: 'MG', value: 'Chiador'},
    {state: 'MG', value: 'Alagoa'},
    {state: 'MG', value: 'Sem-Peixe'},
    {state: 'MG', value: 'Carmésia'},
    {state: 'MG', value: 'Simão Pereira'},
    {state: 'MG', value: 'Umburatiba'},
    {state: 'MG', value: 'Rio Doce'},
    {state: 'MG', value: 'Wenceslau Braz'},
    {state: 'MG', value: 'Biquinhas'},
    {state: 'MG', value: 'Pedra Dourada'},
    {state: 'MG', value: 'Morro da Garça'},
    {state: 'MG', value: 'Santana do Garambéu'},
    {state: 'MG', value: 'Fama'},
    {state: 'MG', value: 'Araçaí'},
    {state: 'MG', value: 'Estrela Dalva'},
    {state: 'MG', value: 'Rochedo de Minas'},
    {state: 'MG', value: 'Silveirânia'},
    {state: 'MG', value: 'Casa Grande'},
    {state: 'MG', value: 'São Sebastião do Rio Verde'},
    {state: 'MG', value: 'Monjolos'},
    {state: 'MG', value: 'Vargem Bonita'},
    {state: 'MG', value: 'Oliveira Fortes'},
    {state: 'MG', value: 'Itambé do Mato Dentro'},
    {state: 'MG', value: 'Aracitaba'},
    {state: 'MG', value: 'Passa Vinte'},
    {state: 'MG', value: 'Senador Cortes'},
    {state: 'MG', value: 'Água Comprida'},
    {state: 'MG', value: 'Serranos'},
    {state: 'MG', value: 'Queluzito'},
    {state: 'MG', value: 'Douradoquara'},
    {state: 'MG', value: 'Tapiraí'},
    {state: 'MG', value: 'Seritinga'},
    {state: 'MG', value: 'Pedro Teixeira'},
    {state: 'MG', value: 'Consolação'},
    {state: 'MG', value: 'Santo Antônio do Rio Abaixo'},
    {state: 'MG', value: 'Olaria'},
    {state: 'MG', value: 'Passabém'},
    {state: 'MG', value: 'Antônio Prado de Minas'},
    {state: 'MG', value: 'Paiva'},
    {state: 'MG', value: 'Doresópolis'},
    {state: 'MG', value: 'São Sebastião do Rio Preto'},
    {state: 'MG', value: 'Senador José Bento'},
    {state: 'MG', value: 'Grupiara'},
    {state: 'MG', value: 'Cedro do Abaeté'},
    {state: 'MG', value: 'Serra da Saudade'},
    {state: 'ES', value: 'Afonso Cláudio'},
    {state: 'ES', value: 'Água Doce do Norte'},
    {state: 'ES', value: 'Águia Branca'},
    {state: 'ES', value: 'Alegre'},
    {state: 'ES', value: 'Alfredo Chaves'},
    {state: 'ES', value: 'Alto Rio Novo'},
    {state: 'ES', value: 'Anchieta'},
    {state: 'ES', value: 'Apiacá'},
    {state: 'ES', value: 'Aracruz'},
    {state: 'ES', value: 'Atílio Vivácqua'},
    {state: 'ES', value: 'Baixo Guandu'},
    {state: 'ES', value: 'Barra de São Francisco'},
    {state: 'ES', value: 'Boa Esperança'},
    {state: 'ES', value: 'Bom Jesus do Norte'},
    {state: 'ES', value: 'Brejetuba'},
    {state: 'ES', value: 'Cachoeiro de Itapemirim'},
    {state: 'ES', value: 'Cariacica'},
    {state: 'ES', value: 'Castelo'},
    {state: 'ES', value: 'Colatina'},
    {state: 'ES', value: 'Conceição da Barra'},
    {state: 'ES', value: 'Conceição do Castelo'},
    {state: 'ES', value: 'Divino de São Lourenço'},
    {state: 'ES', value: 'Domingos Martins'},
    {state: 'ES', value: 'Dores do Rio Preto'},
    {state: 'ES', value: 'Ecoporanga'},
    {state: 'ES', value: 'Fundão'},
    {state: 'ES', value: 'Governador Lindenberg'},
    {state: 'ES', value: 'Guaçuí'},
    {state: 'ES', value: 'Guarapari'},
    {state: 'ES', value: 'Ibatiba'},
    {state: 'ES', value: 'Ibiraçu'},
    {state: 'ES', value: 'Ibitirama'},
    {state: 'ES', value: 'Iconha'},
    {state: 'ES', value: 'Irupi'},
    {state: 'ES', value: 'Itaguaçu'},
    {state: 'ES', value: 'Itapemirim'},
    {state: 'ES', value: 'Itarana'},
    {state: 'ES', value: 'Iúna'},
    {state: 'ES', value: 'Jaguaré'},
    {state: 'ES', value: 'Jerônimo Monteiro'},
    {state: 'ES', value: 'João Neiva'},
    {state: 'ES', value: 'Laranja da Terra'},
    {state: 'ES', value: 'Linhares'},
    {state: 'ES', value: 'Mantenópolis'},
    {state: 'ES', value: 'Marataízes'},
    {state: 'ES', value: 'Marechal Floriano'},
    {state: 'ES', value: 'Marilândia'},
    {state: 'ES', value: 'Mimoso do Sul'},
    {state: 'ES', value: 'Montanha'},
    {state: 'ES', value: 'Mucurici'},
    {state: 'ES', value: 'Muniz Freire'},
    {state: 'ES', value: 'Muqui'},
    {state: 'ES', value: 'Nova Venécia'},
    {state: 'ES', value: 'Pancas'},
    {state: 'ES', value: 'Pedro Canário'},
    {state: 'ES', value: 'Pinheiros'},
    {state: 'ES', value: 'Piúma'},
    {state: 'ES', value: 'Ponto Belo'},
    {state: 'ES', value: 'Presidente Kennedy'},
    {state: 'ES', value: 'Rio Bananal'},
    {state: 'ES', value: 'Rio Novo do Sul'},
    {state: 'ES', value: 'Santa Leopoldina'},
    {state: 'ES', value: 'Santa Maria de Jetibá'},
    {state: 'ES', value: 'Santa Teresa'},
    {state: 'ES', value: 'São Domingos do Norte'},
    {state: 'ES', value: 'São Gabriel da Palha'},
    {state: 'ES', value: 'São José do Calçado'},
    {state: 'ES', value: 'São Mateus'},
    {state: 'ES', value: 'São Roque do Canaã'},
    {state: 'ES', value: 'Serra'},
    {state: 'ES', value: 'Sooretama'},
    {state: 'ES', value: 'Vargem Alta'},
    {state: 'ES', value: 'Venda Nova do Imigrante'},
    {state: 'ES', value: 'Viana'},
    {state: 'ES', value: 'Vila Pavão'},
    {state: 'ES', value: 'Vila Valério'},
    {state: 'ES', value: 'Vila Velha'},
    {state: 'ES', value: 'Vitória'}
];

export default citiesSudeste;
