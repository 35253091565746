/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-lonely-if */
import React, {useState, useEffect} from 'react';
import {Box, Grid} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Accordion, Card, Modal} from 'react-bootstrap';
import {useAccordionToggle} from 'react-bootstrap/AccordionToggle';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import ReactTooltip from 'react-tooltip';
import _, {at, concat, filter, forEach, set} from 'lodash';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import api from '@app/utils/api';
import Loading from './loading/Loading';

const useStyles = makeStyles((theme) =>
    createStyles({
        myButton: {
            color: '#2a3176',
            backgroundColor: '#f4f4f4',
            fontWeight: 'bold'
        },
        myBox: {
            marginLeft: theme.spacing(1.2),
            marginBottom: -18
        },
        myGrid: {
            minWidth: 90,
            maxHeight: 30,
            maxWidth: 90,
            margin: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            textAlign: 'center',
            cursor: 'pointer',
            fontSize: 14,
            border: '2px solid #c5c5c5'
        },
        myGridSelected: {
            minWidth: 90,
            maxWidth: 90,
            maxHeight: 30,
            backgroundColor: '#e37039',
            margin: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 14,
            display: 'flex',
            textAlign: 'center',
            cursor: 'pointer',
            border: '2px solid #e37039',
            color: '#fff'
        },
        myLabel: {
            color: '#d36233',
            fontSize: 14
        },
        myLabelSelected: {
            padding: theme.spacing(6),
            color: '#fff',
            fontWeight: 'bold'
        },
        mySlider: {
            color: '#e37039',
            valueLabel: {
                zIndex: 10,
                left: 'calc(-50% + 12px)',
                top: -22,
                '& *': {
                    background: 'transparent',
                    color: '#000'
                }
            }
        }
    })
);

function ContextAwareToggle({children, eventKey, callback}) {
    const decoratedOnClick = useAccordionToggle(
        eventKey,
        () => callback && callback(eventKey)
    );
    return (
        <button
            type="button"
            style={{backgroundColor: 'white', border: 'none'}}
            onClick={decoratedOnClick}
        >
            {children}
        </button>
    );
}

function ValueLabelComponent(props) {
    const {children, open, value} = props;
    if (children.props['data-index'] === 1) {
        return (
            <Tooltip
                open={open}
                enterTouchDelay={0}
                title={value}
                arrow
                placement="top"
            >
                {children}
            </Tooltip>
        );
    }
    return (
        <Tooltip
            open={open}
            enterTouchDelay={0}
            title={value}
            arrow
            placement="bottom"
        >
            {children}
        </Tooltip>
    );
}

ValueLabelComponent.propTypes = {
    children: PropTypes.element.isRequired,
    open: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired
};

// eslint-disable-next-line no-unused-vars
export default function MercadoFilters({
    actives,
    favs,
    setFilterMercado,
    setTmpTimeLabels
}) {
    const [expand, setExpand] = useState(false);
    const collapse = () => {
        setExpand(!expand);
    };
    const classes = useStyles();
    const [proceed, setProceed] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState('ordem');
    const [highlight, setHighlight] = useState({
        label: 'A → Z',
        status: true
    });
    const [tmpTime, setTmpTime] = useState();

    const [movers, setMovers] = useState([
        {
            label: 'ALTAS',
            status: false
        },
        {label: 'BAIXAS', status: false}
    ]);
    const [volImplicita, setVolImplicita] = useState([
        {
            label: 'CRESCENTE',
            status: false
        },
        {label: 'DECRESCENTE', status: false}
    ]);
    const [ordem, setOrdem] = useState([
        {
            label: 'A → Z',
            status: true
        },
        {label: 'Z → A', status: false}
    ]);
    const [volHistorica, setVolHistorica] = useState([
        {
            label: 'CRESCENTE',
            status: false
        },
        {label: 'DECRESCENTE', status: false}
    ]);
    const [maximos, setMaximos] = useState([
        {
            label: 'CRESCENTE',
            status: false
        },
        {label: 'DECRESCENTE', status: false}
    ]);
    const [minimos, setMinimos] = useState([
        {
            label: 'CRESCENTE',
            status: false
        },
        {label: 'DECRESCENTE', status: false}
    ]);
    const [time, setTime] = useState([
        {
            label: '7 DIAS',
            status: true
        },
        {label: '30 DIAS', status: false},
        {label: '365 DIAS', status: false}
    ]);

    const changeMovers = (e, name) => {
        // console.log(e);
        if (name === 'movers') {
            if (!e.status) {
                const orig = _.cloneDeep(movers);
                const other = orig.find((el) => el.label !== e.label);
                const my = orig.find((el) => el.label === e.label);
                my.status = !e.status;
                other.status = e.status;
                setMovers(orig);
                // setSelected(null);
                setProceed(2);
            }
        } else {
            const orig = _.cloneDeep(movers);
            orig.forEach((o) => {
                o.status = false;
            });
            setMovers(orig);
        }
    };

    const changeVolImplicita = (e, name) => {
        if (name === 'volImp') {
            if (!e.status) {
                const orig = _.cloneDeep(volImplicita);
                const other = orig.find((el) => el.label !== e.label);
                const my = orig.find((el) => el.label === e.label);
                my.status = !e.status;
                other.status = e.status;
                setVolImplicita(orig);
                // setSelected(null);
                setProceed(5);
            }
        } else {
            const orig = _.cloneDeep(volImplicita);
            orig.forEach((o) => {
                o.status = false;
            });
            setVolImplicita(orig);
        }
    };

    const changeTime = (e) => {
        if (!e.status) {
            const orig = _.cloneDeep(time);
            const other = [];
            // const other = orig.find((el) => el.label !== e.label);
            orig.forEach((o) => {
                if (o.label !== e.label) {
                    other.push(o);
                }
            });
            const my = orig.find((el) => el.label === e.label);
            my.status = !e.status;
            other.forEach((o) => {
                o.status = false;
            });
            // console.log(my, other);
            setTime(orig);
            setTmpTimeLabels(orig);
            // setSelected(null);
            // setProceed(7);
        }
    };

    const changeOrdem = (e, name) => {
        if (name === 'ordem') {
            if (!e.status) {
                const orig = _.cloneDeep(ordem);
                const other = orig.find((el) => el.label !== e.label);
                const my = orig.find((el) => el.label === e.label);
                my.status = !e.status;
                other.status = e.status;
                setOrdem(orig);
                //  setSelected(null);
                setProceed(1);
            }
        } else {
            const orig = _.cloneDeep(ordem);
            orig.forEach((o) => {
                o.status = false;
            });
            setOrdem(orig);
        }
    };

    const changeVolHistorica = (e, name) => {
        if (name === 'volHis') {
            if (!e.status) {
                const orig = _.cloneDeep(volHistorica);
                const other = orig.find((el) => el.label !== e.label);
                const my = orig.find((el) => el.label === e.label);
                my.status = !e.status;
                other.status = e.status;
                setVolHistorica(orig);
                //   setSelected(null);
                setProceed(6);
            }
        } else {
            const orig = _.cloneDeep(volHistorica);
            orig.forEach((o) => {
                o.status = false;
            });
            setVolHistorica(orig);
        }
    };

    const changeMaximos = (e, name) => {
        if (name === 'maximos') {
            if (!e.status) {
                const orig = _.cloneDeep(maximos);
                const other = orig.find((el) => el.label !== e.label);
                const my = orig.find((el) => el.label === e.label);
                my.status = !e.status;
                other.status = e.status;
                setMaximos(orig);
                setProceed(3);
                // setSelected(null);
            }
        } else {
            const orig = _.cloneDeep(maximos);
            orig.forEach((o) => {
                o.status = false;
            });
            setMaximos(orig);
        }
    };

    const changeMinimos = (e, name) => {
        if (name === 'minimos') {
            if (!e.status) {
                const orig = _.cloneDeep(minimos);
                const other = orig.find((el) => el.label !== e.label);
                const my = orig.find((el) => el.label === e.label);
                my.status = !e.status;
                other.status = e.status;
                setMinimos(orig);
                setProceed(4);
                // setSelected(null);
            }
        } else {
            const orig = _.cloneDeep(minimos);
            orig.forEach((o) => {
                o.status = false;
            });
            setMinimos(orig);
        }
    };

    const getMovers = () => {
        setLoading(true);
        const tmpFilter = [];
        const result = [];

        movers.forEach((m) => {
            if (m.label !== 'ALTAS' && m.status) {
                if (favs) favs.sort((a, b) => a.fechamento - b.fechamento);
                actives.sort((a, b) => a.fechamento - b.fechamento);
            } else {
                if (favs) favs.sort((a, b) => b.fechamento - a.fechamento);
                actives.sort((a, b) => b.fechamento - a.fechamento);
            }
        });
        if (favs)
            favs.forEach((active) => {
                tmpFilter.push(active);
            });
        actives.forEach((active) => {
            tmpFilter.push(active);
        });
        setFilterMercado(tmpFilter);
        setProceed(0);
        setLoading(false);
    };

    const getOrdem = () => {
        setLoading(true);
        const tmpFilter = [];
        const result = [];

        ordem.forEach((o) => {
            if (o.label !== 'A ➜ Z' && o.status) {
                if (favs) favs.sort((a, b) => b.name.localeCompare(a.name));
                actives.sort((a, b) => b.name.localeCompare(a.name));
            } else {
                if (favs) favs.sort((a, b) => a.name.localeCompare(b.name));
                actives.sort((a, b) => a.name.localeCompare(b.name));
            }
        });
        if (favs)
            favs.forEach((active) => {
                tmpFilter.push(active);
            });
        actives.forEach((active) => {
            tmpFilter.push(active);
        });
        setFilterMercado(tmpFilter);
        setProceed(0);
        setLoading(false);
    };

    const getMax = () => {
        setLoading(true);
        const tmpFilter = [];
        const result = [];
        const period = time.find((o) => o.status === true);
        console.log(period);
        maximos.forEach((m) => {
            if (m.label !== 'CRESCENTE' && m.status) {
                if (period.label === '7 DIAS') {
                    if (favs)
                        favs.sort((a, b) => b.nearMax.localeCompare(a.nearMax));
                    actives.sort((a, b) => b.nearMax.localeCompare(a.nearMax));
                } else if (period.label === '30 DIAS') {
                    if (favs)
                        favs.sort((a, b) =>
                            b.nearMaxMensal.localeCompare(a.nearMaxMensal)
                        );
                    actives.sort((a, b) =>
                        b.nearMaxMensal.localeCompare(a.nearMaxMensal)
                    );
                } else {
                    if (favs)
                        favs.sort((a, b) =>
                            b.nearMaxAnual.localeCompare(a.nearMaxAnual)
                        );
                    actives.sort((a, b) =>
                        b.nearMaxAnual.localeCompare(a.nearMaxAnual)
                    );
                }
            } else {
                if (period.label === '7 DIAS') {
                    if (favs)
                        favs.sort((a, b) => a.nearMax.localeCompare(b.nearMax));
                    actives.sort((a, b) => a.nearMax.localeCompare(b.nearMax));
                } else if (period.label === '30 DIAS') {
                    if (favs)
                        favs.sort((a, b) =>
                            a.nearMaxMensal.localeCompare(b.nearMaxMensal)
                        );
                    actives.sort((a, b) =>
                        a.nearMaxMensal.localeCompare(b.nearMaxMensal)
                    );
                } else {
                    if (favs)
                        favs.sort((a, b) =>
                            a.nearMaxAnual.localeCompare(b.nearMaxAnual)
                        );
                    actives.sort((a, b) =>
                        a.nearMaxAnual.localeCompare(b.nearMaxAnual)
                    );
                }
            }
        });
        if (favs)
            favs.forEach((active) => {
                tmpFilter.push(active);
            });
        actives.forEach((active) => {
            tmpFilter.push(active);
        });
        setFilterMercado(tmpFilter);
        setProceed(0);
        setLoading(false);
    };

    const getMin = () => {
        setLoading(true);
        const tmpFilter = [];
        const result = [];
        const period = time.find((o) => o.status === true);
        console.log(period);
        minimos.forEach((m) => {
            if (m.label !== 'CRESCENTE' && m.status) {
                if (period.label === '7 DIAS') {
                    if (favs)
                        favs.sort((a, b) => b.nearMin.localeCompare(a.nearMin));

                    actives.sort((a, b) => b.nearMin.localeCompare(a.nearMin));
                } else if (period.label === '30 DIAS') {
                    if (favs)
                        favs.sort((a, b) =>
                            b.nearMinMensal.localeCompare(a.nearMinMensal)
                        );

                    actives.sort((a, b) =>
                        b.nearMinMensal.localeCompare(a.nearMinMensal)
                    );
                } else {
                    if (favs)
                        favs.sort((a, b) =>
                            b.nearMinAnual.localeCompare(a.nearMinAnual)
                        );

                    actives.sort((a, b) =>
                        b.nearMinAnual.localeCompare(a.nearMinAnual)
                    );
                }
            } else {
                if (period.label === '7 DIAS') {
                    if (favs)
                        favs.sort((a, b) => a.nearMin.localeCompare(b.nearMin));

                    actives.sort((a, b) => a.nearMin.localeCompare(b.nearMin));
                } else if (period.label === '30 DIAS') {
                    if (favs)
                        favs.sort((a, b) =>
                            a.nearMinMensal.localeCompare(b.nearMinMensal)
                        );

                    actives.sort((a, b) =>
                        a.nearMinMensal.localeCompare(b.nearMinMensal)
                    );
                } else {
                    if (favs)
                        favs.sort((a, b) =>
                            a.nearMinAnual.localeCompare(b.nearMinAnual)
                        );

                    actives.sort((a, b) =>
                        a.nearMinAnual.localeCompare(b.nearMinAnual)
                    );
                }
            }
        });
        if (favs)
            favs.forEach((active) => {
                tmpFilter.push(active);
            });
        actives.forEach((active) => {
            tmpFilter.push(active);
        });
        setFilterMercado(tmpFilter);
        setProceed(0);
        setLoading(false);
    };

    const getVolIp = () => {
        setLoading(true);
        const tmpFilter = [];
        const result = [];
        const period = time.find((o) => o.status === true);
        // console.log(period);
        volImplicita.forEach((vi) => {
            if (vi.label !== 'CRESCENTE' && vi.status) {
                if (period.label === '7 DIAS') {
                    if (favs) favs.sort((a, b) => b.volIpMax - a.volIpMax);
                    actives.sort((a, b) => b.volIpMax - a.volIpMax);
                } else if (period.label === '30 DIAS') {
                    if (favs)
                        favs.sort(
                            (a, b) => b.volIpMensalMax - a.volIpMensalMax
                        );
                    actives.sort((a, b) => b.volIpMensalMax - a.volIpMensalMax);
                } else {
                    if (favs)
                        favs.sort((a, b) => b.volIpAnualMax - a.volIpAnualMax);
                    actives.sort((a, b) => b.volIpAnualMax - a.volIpAnualMax);
                }
            } else {
                if (period.label === '7 DIAS') {
                    if (favs) favs.sort((a, b) => a.volIpMax - b.volIpMax);
                    actives.sort((a, b) => a.volIpMax - b.volIpMax);
                } else if (period.label === '30 DIAS') {
                    if (favs)
                        favs.sort(
                            (a, b) => a.volIpMensalMax - b.volIpMensalMax
                        );
                    actives.sort((a, b) => a.volIpMensalMax - b.volIpMensalMax);
                } else {
                    if (favs)
                        favs.sort((a, b) => a.volIpAnualMax - b.volIpAnualMax);
                    actives.sort((a, b) => a.volIpAnualMax - b.volIpAnualMax);
                }
            }
        });
        if (favs)
            favs.forEach((active) => {
                tmpFilter.push(active);
            });
        actives.forEach((active) => {
            tmpFilter.push(active);
        });
        setFilterMercado(tmpFilter);
        setProceed(0);
        setLoading(false);
    };

    const getVolHis = () => {
        setLoading(true);
        const tmpFilter = [];
        const result = [];

        const period = time.find((o) => o.status === true);
        // console.log(period);
        volHistorica.forEach((vh) => {
            if (vh.label !== 'CRESCENTE' && vh.status) {
                if (period.label === '7 DIAS') {
                    if (favs) favs.sort((a, b) => b.volHisMax - a.volHisMax);
                    actives.sort((a, b) => b.volHisMax - a.volHisMax);
                } else if (period.label === '30 DIAS') {
                    if (favs)
                        favs.sort(
                            (a, b) => b.volHisMensalMax - a.volHisMensalMax
                        );
                    actives.sort(
                        (a, b) => b.volHisMensalMax - a.volHisMensalMax
                    );
                } else {
                    if (favs)
                        favs.sort(
                            (a, b) => b.volHisAnualMax - a.volHisAnualMax
                        );
                    actives.sort((a, b) => b.volHisAnualMax - a.volHisAnualMax);
                }
            } else {
                if (period.label === '7 DIAS') {
                    if (favs) favs.sort((a, b) => a.volHisMax - b.volHisMax);
                    actives.sort((a, b) => a.volHisMax - b.volHisMax);
                } else if (period.label === '30 DIAS') {
                    if (favs)
                        favs.sort(
                            (a, b) => a.volHisMensalMax - b.volHisMensalMax
                        );
                    actives.sort(
                        (a, b) => a.volHisMensalMax - b.volHisMensalMax
                    );
                } else {
                    if (favs)
                        favs.sort(
                            (a, b) => a.volHisAnualMax - b.volHisAnualMax
                        );
                    actives.sort((a, b) => a.volHisAnualMax - b.volHisAnualMax);
                }
            }
        });
        if (favs)
            favs.forEach((active) => {
                tmpFilter.push(active);
            });
        actives.forEach((active) => {
            tmpFilter.push(active);
        });
        setFilterMercado(tmpFilter);
        setProceed(0);
        setLoading(false);
    };

    useEffect(() => {
        if (actives) setProceed(1);
    }, [actives]);

    useEffect(() => {
        if (highlight && time) {
            changeVolImplicita(highlight, selected);
            changeVolHistorica(highlight, selected);
            changeMaximos(highlight, selected);
            changeMinimos(highlight, selected);
            changeOrdem(highlight, selected);
            changeMovers(highlight, selected);
        }
    }, [highlight, time]);

    useEffect(() => {
        if (tmpTime) changeTime(tmpTime);
    }, [tmpTime]);

    useEffect(() => {
        if (actives) {
            if (proceed === 1) getOrdem();
            if (proceed === 2) getMovers();
            if (proceed === 3) getMax();
            if (proceed === 4) getMin();
            if (proceed === 5) getVolIp();
            if (proceed === 6) getVolHis();
            // if(proceed === 7) getTime();
        }
    }, [proceed]);

    return (
        <>
            <Loading open={loading} msg="Atualizando ativos..." />
            <Accordion defaultActiveKey="1" style={{marginRight: 16}}>
                <Card>
                    <Card.Header>
                        <div className="container-fluid">
                            <div
                                className="row"
                                style={{
                                    alignItems: 'space-between',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <ContextAwareToggle eventKey="0">
                                    <div
                                        style={{
                                            display: 'flex',
                                            direction: 'row',
                                            alignItems: 'center',
                                            color: '#d36233'
                                        }}
                                        onClick={collapse}
                                    >
                                        <ion-icon
                                            size="large"
                                            name="options-outline"
                                            style={{
                                                color: '#d36233',
                                                marginRight: 15
                                            }}
                                        />{' '}
                                        FILTROS
                                    </div>
                                </ContextAwareToggle>
                                <div
                                    style={{
                                        display: 'flex',
                                        direction: 'row',
                                        justifyContent: 'end'
                                    }}
                                >
                                    <ContextAwareToggle eventKey="0">
                                        {expand ? (
                                            <span
                                                title=""
                                                onClick={collapse}
                                                style={{
                                                    display: 'flex',
                                                    height: '100%'
                                                }}
                                            >
                                                <ion-icon
                                                    id="collapse"
                                                    size="large"
                                                    name="chevron-down-outline"
                                                    style={{
                                                        color: '#d36233',
                                                        pointerEvents: 'none'
                                                    }}
                                                />
                                            </span>
                                        ) : (
                                            <span
                                                title=""
                                                onClick={collapse}
                                                style={{
                                                    display: 'flex',
                                                    height: '100%'
                                                }}
                                            >
                                                <ion-icon
                                                    id="collapse"
                                                    size="large"
                                                    name="chevron-back-outline"
                                                    style={{
                                                        color: '#d36233',
                                                        pointerEvents: 'none'
                                                    }}
                                                />
                                            </span>
                                        )}
                                    </ContextAwareToggle>
                                </div>
                            </div>
                        </div>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    marginTop: '-10px',
                                    marginBottom: '-10px'
                                }}
                            >
                                <ReactTooltip />
                                <ReactTooltip id="minimos" place="left" />
                                <ReactTooltip id="tempo" place="right" />
                                <div>
                                    <div style={{height: '60px'}}>
                                        <Box
                                            className={classes.myBox}
                                            style={{marginLeft: 5}}
                                        >
                                            <label
                                                className={classes.myLabel}
                                                style={{marginLeft: 5}}
                                            >
                                                TEMPO
                                            </label>
                                            <span
                                                data-for="tempo"
                                                style={{
                                                    cursor: 'pointer'
                                                }}
                                                data-tip="Tempo - filtra os dados por um periodo de tempo."
                                            >
                                                <ion-icon
                                                    style={{
                                                        marginBottom: 2,
                                                        fontSize: 12,
                                                        color: '#d36233',
                                                        pointerEvents: 'none'
                                                    }}
                                                    name="help-circle-outline"
                                                />
                                            </span>
                                        </Box>
                                        <Box
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'start',
                                                alignItems: 'center'
                                            }}
                                        >
                                            {time.map((s) => (
                                                <Grid
                                                    key={s.label}
                                                    className={
                                                        s.status
                                                            ? classes.myGridSelected
                                                            : classes.myGrid
                                                    }
                                                    onClick={() => {
                                                        setTmpTime(s);
                                                    }}
                                                >
                                                    {s.label}
                                                </Grid>
                                            ))}
                                        </Box>
                                    </div>
                                    <div style={{height: '60px'}}>
                                        <Box
                                            className={classes.myBox}
                                            style={{marginLeft: 5}}
                                        >
                                            <label
                                                className={classes.myLabel}
                                                style={{marginLeft: 5}}
                                            >
                                                VOL. IMPLÍCITA
                                            </label>
                                            <span
                                                style={{
                                                    cursor: 'pointer'
                                                }}
                                                data-tip="Vol implicita - volatilidade implicita da opção de acordo com modelo Black&Scholes e
                                            Newton Raphson."
                                            >
                                                <ion-icon
                                                    style={{
                                                        marginBottom: 2,
                                                        fontSize: 12,
                                                        color: '#d36233',
                                                        pointerEvents: 'none'
                                                    }}
                                                    name="help-circle-outline"
                                                />
                                            </span>
                                        </Box>
                                        <Box
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'start',
                                                alignItems: 'center'
                                            }}
                                        >
                                            {volImplicita.map((s) => (
                                                <Grid
                                                    key={s.label}
                                                    className={
                                                        s.status
                                                            ? classes.myGridSelected
                                                            : classes.myGrid
                                                    }
                                                    onClick={() => {
                                                        setSelected('volImp');
                                                        setHighlight(s);
                                                    }}
                                                >
                                                    {s.label}
                                                </Grid>
                                            ))}
                                        </Box>
                                    </div>
                                </div>
                                <div>
                                    <div style={{height: '60px'}}>
                                        <Box className={classes.myBox}>
                                            <label className={classes.myLabel}>
                                                ORDEM
                                            </label>
                                            <span
                                                style={{
                                                    cursor: 'pointer'
                                                }}
                                                data-tip="Ordem - ordem alfabética"
                                            >
                                                <ion-icon
                                                    style={{
                                                        marginBottom: 2,
                                                        fontSize: 12,
                                                        color: '#d36233',
                                                        pointerEvents: 'none'
                                                    }}
                                                    name="help-circle-outline"
                                                />
                                            </span>
                                        </Box>
                                        <Box
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'start',
                                                alignItems: 'center'
                                            }}
                                        >
                                            {ordem.map((l) => (
                                                <Grid
                                                    key={l.label}
                                                    className={
                                                        l.status
                                                            ? classes.myGridSelected
                                                            : classes.myGrid
                                                    }
                                                    onClick={() => {
                                                        setSelected('ordem');
                                                        setHighlight(l);
                                                    }}
                                                >
                                                    {l.label}
                                                </Grid>
                                            ))}
                                        </Box>
                                    </div>
                                    <div style={{height: '60px'}}>
                                        <Box
                                            className={classes.myBox}
                                            style={{marginLeft: 5}}
                                        >
                                            <label
                                                className={classes.myLabel}
                                                style={{marginLeft: 5}}
                                            >
                                                VOL. HISTÓRICA
                                            </label>
                                            <span
                                                style={{
                                                    cursor: 'pointer'
                                                }}
                                                data-tip="Vol. histórica - volatilidade histórica do ativo objeto."
                                            >
                                                <ion-icon
                                                    style={{
                                                        marginBottom: 2,
                                                        fontSize: 12,
                                                        color: '#d36233',
                                                        pointerEvents: 'none'
                                                    }}
                                                    name="help-circle-outline"
                                                />
                                            </span>
                                        </Box>
                                        <Box
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'start',
                                                alignItems: 'center'
                                            }}
                                        >
                                            {volHistorica.map((s) => (
                                                <Grid
                                                    key={s.label}
                                                    className={
                                                        s.status
                                                            ? classes.myGridSelected
                                                            : classes.myGrid
                                                    }
                                                    onClick={() => {
                                                        setSelected('volHis');
                                                        setHighlight(s);
                                                    }}
                                                >
                                                    {s.label}
                                                </Grid>
                                            ))}
                                        </Box>
                                    </div>
                                </div>
                                <div>
                                    <div style={{height: '60px'}}>
                                        <Box
                                            className={classes.myBox}
                                            style={{marginLeft: 5}}
                                        >
                                            <label
                                                className={classes.myLabel}
                                                style={{marginLeft: 5}}
                                            >
                                                MOVERS
                                            </label>
                                            <span
                                                style={{
                                                    cursor: 'pointer'
                                                }}
                                                data-tip="Movers - ações que mais se movimentaram no dia, maiores altas e maiores baixas."
                                            >
                                                <ion-icon
                                                    style={{
                                                        marginBottom: 2,
                                                        fontSize: 12,
                                                        color: '#d36233',
                                                        pointerEvents: 'none'
                                                    }}
                                                    name="help-circle-outline"
                                                />
                                            </span>
                                        </Box>
                                        <Box
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'start',
                                                alignItems: 'center'
                                            }}
                                        >
                                            {movers.map((s) => (
                                                <Grid
                                                    key={s.label}
                                                    className={
                                                        s.status
                                                            ? classes.myGridSelected
                                                            : classes.myGrid
                                                    }
                                                    onClick={() => {
                                                        setSelected('movers');
                                                        setHighlight(s);
                                                    }}
                                                >
                                                    {s.label}
                                                </Grid>
                                            ))}
                                        </Box>
                                    </div>
                                    <div style={{height: '60px'}}>
                                        <Box className={classes.myBox}>
                                            <label className={classes.myLabel}>
                                                MÁXIMOS
                                            </label>
                                            <span
                                                style={{
                                                    cursor: 'pointer'
                                                }}
                                                data-tip="Máximos - mostra quais ações estão próxima da máxima."
                                            >
                                                <ion-icon
                                                    style={{
                                                        marginBottom: 2,
                                                        fontSize: 12,
                                                        color: '#d36233',
                                                        pointerEvents: 'none'
                                                    }}
                                                    name="help-circle-outline"
                                                />
                                            </span>
                                        </Box>
                                        <Box
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'start',
                                                alignItems: 'center'
                                            }}
                                        >
                                            {maximos.map((m) => (
                                                <Grid
                                                    key={m.label}
                                                    className={
                                                        m.status
                                                            ? classes.myGridSelected
                                                            : classes.myGrid
                                                    }
                                                    onClick={() => {
                                                        setSelected('maximos');
                                                        setHighlight(m);
                                                    }}
                                                >
                                                    {m.label}
                                                </Grid>
                                            ))}
                                        </Box>
                                    </div>
                                </div>

                                <div style={{marginTop: '60px'}}>
                                    <Box className={classes.myBox}>
                                        <label className={classes.myLabel}>
                                            MÍNIMOS
                                        </label>
                                        <span
                                            data-for="minimos"
                                            style={{
                                                cursor: 'pointer'
                                            }}
                                            data-tip="Mínimos - mostra quais ações estão próximas da mínima"
                                        >
                                            <ion-icon
                                                style={{
                                                    marginBottom: 2,
                                                    fontSize: 12,
                                                    color: '#d36233',
                                                    pointerEvents: 'none'
                                                }}
                                                name="help-circle-outline"
                                            />
                                        </span>
                                    </Box>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'start',
                                            alignItems: 'center'
                                        }}
                                    >
                                        {minimos.map((n) => (
                                            <Grid
                                                key={n.label}
                                                className={
                                                    n.status
                                                        ? classes.myGridSelected
                                                        : classes.myGrid
                                                }
                                                onClick={() => {
                                                    setSelected('minimos');
                                                    setHighlight(n);
                                                }}
                                            >
                                                {n.label}
                                            </Grid>
                                        ))}
                                    </Box>
                                </div>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </>
    );
}
