import React from 'react';
import {Grid} from '@material-ui/core';

import {makeStyles, createStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
    createStyles({
        myGrid: {
            display: 'flow-root',
            minWidth: 110,
            height: 60,
            maxWidth: 110,
            // color: '#d36233',
            // backgroundColor: '#f4f4f4',
            // margin: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            cursor: 'pointer',
            fontSize: 14,
            borderRight: '2px solid #c5c5c5'
        },
        myGridEnd: {
            minWidth: 110,
            height: 60,
            maxWidth: 110,
            // color: '#d36233',
            // backgroundColor: '#f4f4f4',
            // margin: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            cursor: 'pointer',
            fontSize: 14
        },
        myGridSelected: {
            display: 'flow-root',
            minWidth: 110,
            height: 60,
            maxWidth: 110,
            color: '#d36233',
            // backgroundColor: '#f4f4f4',
            // margin: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            cursor: 'pointer',
            fontSize: 14,
            borderRight: '2px solid #c5c5c5'
        },
        myGridEndSelected: {
            minWidth: 110,
            height: 60,
            maxWidth: 110,
            color: '#d36233',
            // backgroundColor: '#f4f4f4',
            // margin: theme.spacing(1),
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            cursor: 'pointer',
            fontSize: 14
        }
    })
);

// eslint-disable-next-line no-unused-vars
export default function SettingsTable({tableLabel, setTableLabel}) {
    const classes = useStyles();
    return (
        <>
            <Grid
                container
                spacing={1}
                style={{
                    justifyContent: 'center',
                    marginBottom: 10
                }}
            >
                <Grid
                    className={
                        tableLabel.strike.selected
                            ? classes.myGridSelected
                            : classes.myGrid
                    }
                    onClick={() =>
                        setTableLabel((prev) => ({
                            ...prev,
                            strike: {
                                value: 'STRIKE',
                                selected: !tableLabel.strike.selected
                            }
                        }))
                    }
                >
                    {tableLabel.strike.selected ? (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-outline"
                            />
                        </span>
                    ) : (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-off-outline"
                            />
                        </span>
                    )}
                    <div>STRIKE</div>
                </Grid>
                <Grid
                    className={
                        tableLabel.tipo.selected
                            ? classes.myGridSelected
                            : classes.myGrid
                    }
                    onClick={() =>
                        setTableLabel((prev) => ({
                            ...prev,
                            tipo: {
                                value: 'TIPO',
                                selected: !tableLabel.tipo.selected
                            }
                        }))
                    }
                >
                    {tableLabel.tipo.selected ? (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-outline"
                            />
                        </span>
                    ) : (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-off-outline"
                            />
                        </span>
                    )}
                    <div>TIPO</div>
                </Grid>
                <Grid
                    className={
                        tableLabel.money.selected
                            ? classes.myGridEndSelected
                            : classes.myGridEnd
                    }
                    onClick={() =>
                        setTableLabel((prev) => ({
                            ...prev,
                            money: {
                                value: 'A/I/OTM',
                                selected: !tableLabel.money.selected
                            }
                        }))
                    }
                >
                    {tableLabel.money.selected ? (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-outline"
                            />
                        </span>
                    ) : (
                        <span title="">
                            <ion-icon
                                style={{pointerEvents: 'none'}}
                                size="large"
                                name="eye-off-outline"
                            />
                        </span>
                    )}
                    <div>A/I/OTM</div>
                </Grid>
            </Grid>
        </>
    );
}
