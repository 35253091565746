/* eslint-disable no-unused-vars */
import React from 'react';
import {Card} from 'react-bootstrap';
import moment from 'moment';
import {Button, Modal} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';

import AlterarCad from './modals/AlterarModal';
import ConfirmModal from './modals/ConfirmModal';
import './admin.css';

export default function AdminUsuarios({
    size,
    classes,
    /* setOpen */
    usuarios
    // setData
}) {
    // rows -> 1360 = 0-10
    // rows -> 1920 = 0-16
    const rows = window.innerWidth > 1366 ? 16 : 10;
    const [page, setPage] = React.useState(1);
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState();
    const [cancel, setCancel] = React.useState(false);

    React.useEffect(() => {
        if (page !== 1) {
            setPage(1);
        }
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleModal = (info) => {
        setData(info);
        setOpen(true);
    };

    return (
        <>
            <Card
                style={{borderRadius: '1rem'}}
                className={size > 1600 ? 'carddashboard1920' : 'carddashboard'}
            >
                <Card.Header
                    style={{
                        border: 'none',
                        boxShadow: 'none',
                        color: '#000'
                    }}
                >
                    <div
                        style={{
                            margin: '10px 0px 10px 25px',
                            fontSize: 20,
                            fontWeight: '700'
                        }}
                    >
                        USUÁRIOS CADASTRADOS
                    </div>
                    <div
                        style={{
                            color: '#d36233',
                            display: 'flex',
                            // justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #e37039',
                            width: size > 1600 ? '96.5%' : '95%',
                            marginLeft: '25px',
                            fontSize: '18px',
                            fontWeight: 700
                        }}
                    >
                        <div className={size > 1600 ? 'idhead1920' : 'idhead'}>
                            <span
                                style={{
                                    display: 'block',
                                    textAlign: 'center',
                                    fontSize: '18px',
                                    backgroundColor: '#cac6c2',
                                    width: '60px',
                                    borderRadius: '15px'
                                }}
                            >
                                ID
                            </span>
                        </div>
                        <div
                            className={
                                size > 1600 ? 'nometext1920' : 'nometext'
                            }
                        >
                            NOME
                        </div>
                        <div
                            className={
                                size > 1600 ? 'emailtext1920' : 'emailtext'
                            }
                        >
                            EMAIL
                        </div>
                        <div
                            className={
                                size > 1600 ? 'othertext1920' : 'othertext'
                            }
                        >
                            DATA
                            <br /> CADASTRO
                        </div>
                        <div
                            className={
                                size > 1600 ? 'othertext1920' : 'othertext'
                            }
                        >
                            PLANO
                        </div>
                        <div
                            className={
                                size > 1600 ? 'othertext1920' : 'othertext'
                            }
                        >
                            VENC.
                        </div>
                        <div
                            className={
                                size > 1600 ? 'othertext1920' : 'othertext'
                            }
                        >
                            TEMPO
                            <br /> INATIVO
                        </div>
                    </div>
                </Card.Header>
                <Card.Body style={{marginTop: '-20px'}}>
                    <div>
                        {usuarios &&
                            usuarios
                                .slice((page - 1) * rows, page * rows)
                                .map((i) => (
                                    <div
                                        key={i}
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <ion-icon
                                            style={{
                                                color: '#d36233',
                                                cursor: 'pointer'
                                            }}
                                            name="settings-sharp"
                                            onClick={() => handleModal(i)}
                                        />
                                        <div
                                            style={{
                                                display: 'flex',
                                                borderBottom:
                                                    '1.5px solid #cac6c2',
                                                marginLeft: '10px'
                                            }}
                                        >
                                            <div
                                                className={
                                                    size > 1600
                                                        ? 'idtext1920'
                                                        : 'idtext'
                                                }
                                            >
                                                {i.id}
                                            </div>
                                            <div
                                                className={
                                                    size > 1600
                                                        ? 'nometext1920'
                                                        : 'nometext'
                                                }
                                            >
                                                {i.nome}
                                            </div>
                                            <div
                                                className={
                                                    size > 1600
                                                        ? 'emailtext1920'
                                                        : 'emailtext'
                                                }
                                            >
                                                {i.email}
                                            </div>
                                            <div
                                                className={
                                                    size > 1600
                                                        ? 'othertext1920'
                                                        : 'othertext'
                                                }
                                            >
                                                {moment(i.data_cad).format(
                                                    'DD/MM/YYYY'
                                                )}
                                            </div>
                                            <div
                                                className={
                                                    size > 1600
                                                        ? 'othertext1920'
                                                        : 'othertext'
                                                }
                                            >
                                                {i.plano}
                                            </div>
                                            <div
                                                className={
                                                    size > 1600
                                                        ? 'othertext1920'
                                                        : 'othertext'
                                                }
                                            >
                                                {i.venc}
                                            </div>
                                            <div
                                                className={
                                                    size > 1600
                                                        ? 'othertext1920'
                                                        : 'othertext'
                                                }
                                            >
                                                {i.tempo_inativo}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                margin: '20px 10px 10px 10px'
                            }}
                        >
                            <Button
                                className={
                                    size > 1600
                                        ? classes.myButton1920
                                        : classes.myButton
                                }
                            >
                                EXPORTAR CSV
                            </Button>
                            <Pagination
                                count={Math.ceil(
                                    Number(usuarios.length / rows)
                                )}
                                page={page}
                                onChange={handleChangePage}
                                pageRangeDisplayed={2}
                                // onChangeRowsPerPage={handleChangeRowsPerPage}
                                // rowsPerPage={rowsPerPage}
                                className={classes.root}
                            />
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Modal style={{zIndex: 999}} open={open}>
                <AlterarCad
                    size={window.innerWidth}
                    classes={classes}
                    data={data}
                    setOpen={setOpen}
                    setCancel={setCancel}
                />
            </Modal>
            <Modal style={{zIndex: 9999}} open={cancel}>
                <ConfirmModal
                    data={data}
                    classes={classes}
                    setCancel={setCancel}
                    cancel={cancel}
                />
            </Modal>
        </>
    );
}
