/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import {Card} from 'react-bootstrap';
import Select from 'react-select';
import {Button} from '@material-ui/core';
import moment from 'moment';

import './admin.css';

export default function Resumo({size, classes, data}) {
    const text = [
        'TOTAL USUÁRIOS CADASTRADOS',
        'TOTAL DE ASSINANTES - P.MENSAL',
        'TOTAL DE ASSINANTES - P.ANUAL',
        'TOTAL DE ASSINANTES - P.GRATUITO',
        'TOTAL DE CANCELAMENTOS'
    ];
    const [value, setValue] = useState();
    const [label, setLabel] = useState();
    const [placeholder, setPlaceHolder] = useState();
    const [allData, setAllData] = useState();
    const month = moment().format('M');
    const listMonths = [
        '',
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez'
    ];

    React.useEffect(() => {
        if (data) {
            const tmpData = [];
            const list = [];
            const all = [];

            console.log(data);
            data.allData.forEach((d, i) => {
                all.push(d);
                if (i + 1 === Number(month)) {
                    tmpData.push(
                        d.register,
                        d.mensal,
                        d.anual,
                        d.free,
                        d.cancel
                    );
                }
                list.push({
                    value: d.mes,
                    label: `${listMonths[Number(d.mes)]} ${d.ano}`
                });
            });
            const ph = list.find((l) => l.value === moment().format('MM'));
            setPlaceHolder(ph);
            setLabel(list);
            setAllData(all);
            setValue(tmpData);
        }
    }, [data]);

    const handleChange = (e) => {
        allData.forEach((a) => {
            if (e.value === a.mes) {
                setValue([a.register, a.mensal, a.anual, a.free, a.cancel]);
            }
        });
        label.forEach((l) => {
            if (l.value === e.value) {
                setPlaceHolder(l);
            }
        });
    };

    const styles = {
        control: (css) => ({
            ...css,
            height: 40,
            width: size > 1600 ? 340 : 200,
            color: '#e37039',
            border: 'none',
            background: '#cac6c2',
            boxShadow: 'none',
            borderRadius: '20px'
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#e37039'
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#e37039'
        }),
        menu: ({width, ...css}) => ({
            ...css,
            marginTop: 16,
            width: size > 1600 ? 340 : 200,
            fontSize: 16,
            justifyContent: 'center',
            minWidth: '10%'
        }),
        container: (base) => ({
            ...base,
            minWidth: size > 1600 ? 340 : 200,
            maxWidth: size > 1600 ? 340 : 200,
            alignItems: 'center',
            justifyContent: 'center'
        }),
        valueContainer: (base) => ({
            ...base,
            width: size > 1600 ? 340 : 200,
            fontSize: '20px',
            boxShadow: 'none',
            justifyContent: 'center',
            marginLeft: '40px',
            color: '#e37039'
        }),
        option: (css, state) => ({
            ...css,
            color: '#e37039',
            width: size > 1600 ? 340 : 200,
            justifyContent: 'center',
            backgroundColor: state.isSelected
                ? ''
                : '' || state.isHovered
                ? '#cac6c2'
                : 'transparent',
            '&:hover': {
                backgroundColor: '#cac6c2'
            }
        }),
        input: (base) => ({
            ...base,
            color: '#e37039',
            display: 'flex',
            fontWeight: 700,
            height: 25,
            alignItems: 'center'
        }),
        dropdownIndicator: (base) => ({
            ...base,
            color: '#e37039',
            width: 40
        })
    };

    return (
        <div
            style={{
                marginLeft: '5rem',
                marginRight: '8rem',
                width: size > 1600 ? '90%' : ''
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginTop: '2rem'
                }}
            >
                <Card className={size > 1600 ? 'resumo1920' : 'resumo'}>
                    <Card.Header
                        style={{
                            border: 'none',
                            boxShadow: 'none',
                            color: '#000'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '70%'
                            }}
                        >
                            <div
                                style={{
                                    fontWeight: 700,
                                    fontSize: size > 1600 ? 26 : 20
                                }}
                            >
                                RESUMO DE USUÁRIOS
                            </div>

                            <ion-icon
                                style={{fontSize: size > 1600 ? 26 : 20}}
                                name="chevron-forward-outline"
                            />
                        </div>
                    </Card.Header>
                    <Card.Body style={{marginTop: size > 1600 ? '40px' : ''}}>
                        <div>
                            {text.map((value, i) => (
                                <div
                                    className={
                                        size > 1600
                                            ? 'tabledata1920'
                                            : 'tabledata'
                                    }
                                >
                                    <div>ICON</div>
                                    <div
                                        className={
                                            size > 1600
                                                ? 'text-resumo1920'
                                                : 'text-resumo'
                                        }
                                    >
                                        {value}
                                    </div>
                                    <div className="text-num">
                                        {data?.users[i].total}
                                    </div>
                                    <div className="trending">
                                        <div>
                                            <ion-icon
                                                style={{
                                                    fontSize: 20,
                                                    color: '#000'
                                                }}
                                                name="trending-up-outline"
                                            />
                                        </div>
                                        <div
                                            style={{
                                                fontSize: 14
                                            }}
                                        >
                                            {data?.users[i].percent}%
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <Button
                                className={
                                    size > 1600
                                        ? classes.myButton1920
                                        : classes.myButton
                                }
                            >
                                EXPORTAR CSV
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
                <Card className={size > 1600 ? 'resumomes1920' : 'resumomes'}>
                    <Card.Header
                        style={{
                            border: 'none',
                            boxShadow: 'none',
                            color: '#000'
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <div
                                style={{
                                    fontWeight: 700,
                                    fontSize: size > 1600 ? 26 : 20
                                }}
                            >
                                RESUMO POR MÊS
                            </div>
                        </div>
                        <div
                            style={{display: 'flex', justifyContent: 'center'}}
                        >
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                onChange={(e) => {
                                    console.log(e);
                                    handleChange(e);
                                }}
                                placeholder=""
                                defaultValue={label || ''}
                                value={placeholder}
                                isSearchable
                                styles={styles}
                                options={label}
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                            />
                        </div>
                    </Card.Header>
                    <Card.Body style={{marginTop: size > 1600 ? '' : '-40px'}}>
                        {value?.map((v) => (
                            <div
                                className={
                                    size > 1600 ? 'tablemes1920' : 'tablemes'
                                }
                            >
                                {v}
                            </div>
                        ))}
                    </Card.Body>
                </Card>
            </div>
        </div>
    );
}
