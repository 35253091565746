import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import api from '@app/utils/api';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import {Link} from 'react-scroll';
import LazyLoad from 'react-lazyload';
import Planos from '@app/pages/Planos';
import Usuario from '@app/pages/Usuario';
import MobileDetecter from '@app/components/MobileDetecter';
import WebFont from 'webfontloader';
import {
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    Section6,
    Section7,
    Section8
} from '@app/components/HomeSections';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import alvo from '../img/diretoSprite.avif';
import ferramentas from '../img/customizavelSprite.avif';
import labirinto from '../img/descomplicadoSprite.avif';
import tempo from '../img/rapidoSprite.avif';
import icone from '../img/OPTI_logo.avif';
import Login from './Login/Login';
import './Home.css';

export default function Home() {
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const texts = [
        '',
        'Diga adeus ás complicações! Com a Opti, você terá acesso direto e simplificado ao mundo das operações com opções. Chega de enrolação, foco nas informações e ferramentas essenciais para suas estratégias.',
        'Tudo do seu jeito! Aqui, você pode personalizar suas configurações e preferências de acordo com suas necessidades. Adapte a plataforma às suas estratégias únicas e tenha controle total de suas operações.',
        'Simplifique sua experiência! Nossa interface intuitiva e recursos claros ajudam a desmistificar o processo, permitindo que você se concentre no que realmente importa: suas estratégias e resultados.',
        'Nossas ferramentas entregam informações digeridas, agilizando suas operações e permitindo que você aproveite cada momento no mercado, maximizando seus resultados.'
    ];
    const title = ['DIRETO', 'CUSTOMIZÁVEL', 'DESCOMPLICADO', 'RÁPIDO'];
    const imgs = [alvo, ferramentas, labirinto, tempo];
    const hover = ['alvo', 'ferramentas', 'labirinto', 'tempo'];
    const keys = [1, 2, 3, 4];

    const useStyles = makeStyles((theme) =>
        createStyles({
            icone: {
                width: 40,
                height: 40
            },
            menuButton: {
                marginRight: theme.spacing(2)
            },
            menuPaper: {
                backgroundColor: 'black',
                position: 'bottom'
            },
            title: {
                cursor: 'pointer',
                margin: 30,
                color: '#0e0b0f',
                fontWeight: 'bold'
            }
        })
    );

    const redirect = true;

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto', 'Rubik']
            }
        });
    }, []);

    const classes = useStyles();

    return (
        <>
            <AppBar position="fixed" style={{backgroundColor: '#cac6c2'}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                    >
                        <img src={icone} alt="" className={classes.icone} />
                    </IconButton>
                    <div style={{flexGrow: 1, marginLeft: '10%'}}>
                        <Link activeClass="active" to="service" spy smooth>
                            <Typography
                                variant="inherit"
                                className={classes.title}
                            >
                                VANTAGENS
                            </Typography>
                        </Link>
                        <Typography
                            variant="inherit"
                            className={classes.title}
                            onClick={() => history.push('/grade-de-opcoes')}
                        >
                            GRADE DE OP
                            <span
                                style={{
                                    fontFamily: 'Rubik',
                                    fontWeight: '300',
                                    fontSize: 16.5
                                }}
                            >
                                Ç
                            </span>
                            ÕES
                        </Typography>
                        <Typography
                            variant="inherit"
                            className={classes.title}
                            onClick={() => history.push('/mapa-de-opcoes')}
                        >
                            MAPA DE OP
                            <span
                                style={{
                                    fontFamily: 'Rubik',
                                    fontWeight: '300',
                                    fontSize: 16.5
                                }}
                            >
                                Ç
                            </span>
                            ÕES
                        </Typography>
                        <Planos />
                    </div>
                    {!api.currentSession ? (
                        <Button
                            color="inherit"
                            style={{backgroundColor: ' #e37039', width: 100}}
                            size="small"
                            onClick={() => setOpen(true)}
                        >
                            Login
                        </Button>
                    ) : (
                        <Usuario />
                    )}
                </Toolbar>
            </AppBar>
            <div
                style={{
                    overflow: 'hidden',
                    fontFamily: 'Roboto'
                }}
            >
                <LazyLoad height={900} once offset={500} unmountIfInvisible>
                    <Section1 id="first" />
                </LazyLoad>
                <LazyLoad height={900} once offset={500} unmountIfInvisible>
                    <Section2
                        id="service"
                        texts={texts}
                        imgs={imgs}
                        title={title}
                        hover={hover}
                        keys={keys}
                    />
                </LazyLoad>
                <LazyLoad height={900} once offset={500} unmountIfInvisible>
                    <Section3 id="three" />
                </LazyLoad>
                <LazyLoad height={900} once offset={500} unmountIfInvisible>
                    <Section4 id="four" />
                </LazyLoad>
                <LazyLoad height={900} once offset={500} unmountIfInvisible>
                    <Section5 id="five" />
                </LazyLoad>
                <LazyLoad height={900} once offset={500} unmountIfInvisible>
                    <Section6 id="six" />
                </LazyLoad>
                <LazyLoad height={900} once offset={500} unmountIfInvisible>
                    <Section8 id="eight" />
                </LazyLoad>
                <LazyLoad height={900} once offset={500} unmountIfInvisible>
                    <Section7 id="seven" />
                </LazyLoad>
            </div>
            <MobileDetecter />
            <Dialog
                maxWidth="lg"
                open={open}
                onClose={() => {
                    setOpen(false);
                    setOpen();
                }}
                classes={{
                    paper: classes.dialog
                }}
            >
                <Login redirect={redirect} />
            </Dialog>
        </>
    );
}
