import React from 'react';
import {Card} from 'react-bootstrap';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import {Button, Modal} from '@material-ui/core';

import ReativarModal from './modals/ReativarModal';
import ConfirmModal from './modals/ConfirmModal';

import './admin.css';

export default function AdminCancelamento({
    size,
    /* setOpen, */ cancelled,
    classes
}) {
    const rows = window.innerWidth > 1366 ? 16 : 10;
    const [page, setPage] = React.useState(1);
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState();
    const [reativar, setReativar] = React.useState(false);

    React.useEffect(() => {
        if (page !== 1) {
            setPage(1);
        }
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleModal = (info) => {
        setData(info);
        setOpen(true);
    };

    return (
        <>
            <Card
                style={{borderRadius: '1rem'}}
                className={size > 1600 ? 'carddashboard1920' : 'carddashboard'}
            >
                <Card.Header
                    style={{
                        border: 'none',
                        boxShadow: 'none',
                        color: '#000'
                    }}
                >
                    <div
                        style={{
                            margin: '10px 0px 10px 25px',
                            fontSize: 20,
                            fontWeight: '700'
                        }}
                    >
                        USUÁRIOS CANCELADOS
                    </div>
                    <div
                        style={{
                            color: '#d36233',
                            display: 'flex',
                            // justifyContent: 'space-between',
                            alignItems: 'center',
                            borderBottom: '1px solid #e37039',
                            width: size > 1600 ? '96.5%' : '95%',
                            marginLeft: '25px',
                            fontWeight: 700,
                            fontSize: '19px'
                        }}
                    >
                        <div
                            className={
                                size > 1600
                                    ? 'idcancelhead1920'
                                    : 'idcancelhead'
                            }
                        >
                            <span
                                style={{
                                    display: 'block',
                                    textAlign: 'center',
                                    fontSize: '19px',
                                    backgroundColor: '#cac6c2',
                                    width: '60px',
                                    borderRadius: '15px'
                                }}
                            >
                                ID
                            </span>
                        </div>
                        <div
                            className={
                                size > 1600
                                    ? 'nomecanceltext1920'
                                    : 'nomecanceltext'
                            }
                        >
                            NOME
                        </div>
                        <div
                            className={
                                size > 1600
                                    ? 'emailcanceltext1920'
                                    : 'emailcanceltext'
                            }
                        >
                            EMAIL
                        </div>

                        <div
                            className={
                                size > 1600 ? 'timetext1920' : 'timetext'
                            }
                        >
                            DATA
                            <br /> CANCELAMENTO
                        </div>
                        <div
                            className={
                                size > 1600 ? 'timetext1920' : 'timetext'
                            }
                        >
                            TEMPO
                            <br /> INATIVO
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div>
                        {cancelled &&
                            cancelled
                                .slice((page - 1) * rows, page * rows)
                                .map((info) => (
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <ion-icon
                                            style={{
                                                color: '#d36233',
                                                cursor: 'pointer'
                                            }}
                                            name="settings-sharp"
                                            onClick={() => handleModal(info)}
                                        />
                                        <div
                                            style={{
                                                display: 'flex',
                                                marginLeft: '10px',
                                                borderBottom:
                                                    '1.5px solid #cac6c2'
                                            }}
                                        >
                                            <div
                                                className={
                                                    size > 1600
                                                        ? 'idcanceltext1920'
                                                        : 'idcanceltext'
                                                }
                                            >
                                                {info?.id}
                                            </div>
                                            <div
                                                className={
                                                    size > 1600
                                                        ? 'nomecanceltext1920'
                                                        : 'nomecanceltext'
                                                }
                                            >
                                                {info?.nome}
                                            </div>
                                            <div
                                                className={
                                                    size > 1600
                                                        ? 'emailcanceltext1920'
                                                        : 'emailcanceltext'
                                                }
                                            >
                                                {info?.email}
                                            </div>
                                            <div
                                                className={
                                                    size > 1600
                                                        ? 'timetext1920'
                                                        : 'timetext'
                                                }
                                            >
                                                {moment(
                                                    info?.data_cancelled
                                                ).format('DD/MM/YYYY')}
                                            </div>
                                            <div
                                                className={
                                                    size > 1600
                                                        ? 'timetext1920'
                                                        : 'timetext'
                                                }
                                            >
                                                {info?.tempo_inativo}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            margin: '20px 10px 10px 10px'
                        }}
                    >
                        <Button
                            className={
                                size > 1600
                                    ? classes.myButton1920
                                    : classes.myButton
                            }
                        >
                            EXPORTAR CSV
                        </Button>
                        <Pagination
                            count={Math.ceil(Number(cancelled?.length / rows))}
                            page={page}
                            onChange={handleChangePage}
                            pageRangeDisplayed={2}
                            // onChangeRowsPerPage={handleChangeRowsPerPage}
                            // rowsPerPage={rowsPerPage}
                            className={classes.root}
                        />
                    </div>
                </Card.Body>
            </Card>
            <Modal style={{zIndex: 999}} open={open}>
                <ReativarModal
                    size={window.innerWidth}
                    classes={classes}
                    setOpen={setOpen}
                    setReativar={setReativar}
                    data={data}
                />
            </Modal>
            <Modal style={{zIndex: 9999}} open={reativar}>
                <ConfirmModal
                    classes={classes}
                    setReativ={setReativar}
                    reativar={reativar}
                    data={data}
                />
            </Modal>
        </>
    );
}
