/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, {useState} from 'react';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import {Link} from 'react-scroll';
import {Radio, Modal} from '@material-ui/core';
import {Card} from 'react-bootstrap';
import PlanosButton from '@app/pages/PlanosButton';
import {useHistory} from 'react-router-dom';
import routing from '../routes/routing';

// images
import background from '../img/1.avif';
import background2 from '../img/2.avif';
import logo from '../img/OPTI_logo1_claro.avif';
import logofinal from '../img/OPTI_logo1_escuro.avif';
import insta from '../img/INSTA.avif';
import youtube from '../img/YOUTUBE.avif';
import twitter from '../img/TWITTER.avif';
import vemopti from '../img/botaoAssinarHover.avif';
import video from '../img/video_vpg2.webm';
import videopg1 from '../img/videohome_1.webm';
import videopg3 from '../img/videohome_3.webm';
import videopg4 from '../img/videohome_4.webm';
import videopg5 from '../img/videohome_5.webm';
import videopg6 from '../img/videohome_6.webm';
import videopg7 from '../img/videohome_7.webm';
import seta from '../img/arrowHome.webm';
import titulo from '../img/planosAssinaturasOpti.avif';
import setaup from '../img/arrowHome1.webm';
import computador3 from '../img/nbnovo4.avif';
import computador1 from '../img/nbnovo3.avif';
import computador2 from '../img/nbnovo1.avif';
import computador4 from '../img/nbnovo2.avif';

import '@app/pages/Home.css';

const useStyles = makeStyles(() =>
    createStyles({
        container2: {
            backgroundImage: `url(${background2})`,
            backgroundPosition: 'left',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '30vw',
            height: '87vh',
            alingItens: 'center',
            padding: 20
        },
        logo: {
            display: 'flex',
            marginLeft: '20%',
            width: '65%'
        },
        logofinal: {
            display: 'flex',
            marginBottom: '10px',
            width: '25%'
        },
        computador: {
            width: '80%',
            marginBottom: 30
        },
        feacturesText: {
            textAlign: 'center',
            justifyContent: 'center',
            margin: '0px 20px 0px 20px',
            padding: 10,
            fontWeight: 'bold',
            color: '#cac6c2'
        },
        texto: {
            color: '#e37039',
            marginLeft: '13%'
        },
        seta: {
            marginLeft: '64%',
            display: 'flex',
            cursor: 'pointer',
            width: '120px',
            transform: 'rotate(180deg)'
        },
        seta2: {
            margin: '-80px auto auto auto',
            display: 'flex',
            cursor: 'pointer',
            width: '120px',
            transform: 'rotate(180deg)'
        },
        seta3: {
            margin: 'auto',
            marginTop: '70px',
            display: 'flex',
            justifyContent: 'flex-end',
            cursor: 'pointer',
            width: '120px'
        },
        seta3Mini: {
            margin: 'auto',
            marginTop: '70px',
            display: 'flex',
            justifyContent: 'flex-end',
            cursor: 'pointer',
            width: '100px'
        },
        setaDown: {
            margin: 'auto',
            display: 'flex',
            cursor: 'pointer',
            width: '120px',
            transform: 'rotate(180deg)'
        },
        setaMini: {
            margin: '-50px auto auto auto',
            display: 'flex',
            cursor: 'pointer',
            width: '100px',
            transform: 'rotate(180deg)'
        },
        midias: {
            margin: '5px',
            width: 65
        },
        modal: {
            outline: 0
        },
        radio: {
            '& .MuiSvgIcon-root': {
                fontSize: 16,
                color: '#e37039',
                // backgroundColor: '#e37039',
                '&.Mui-checked': {
                    color: '#e37039',
                    backgroundColor: '#e37039'
                },
                borderRadius: '50%'
            }
        }
    })
);

const emptyString = () => {
    return '';
};

const handleTermos = (history) => {
    history.push(routing.termos);
};

const handlePrivacidade = (history) => {
    history.push(routing.privacidade);
};

const handleSobre = (history) => {
    history.push(routing.sobrenos);
};

const handleFaq = (history) => {
    history.push(routing.faq);
};

const handleLink = (name) => {
    if (name === 'youtube') {
        window.open('https://www.youtube.com/channel/UCFq7ze2_01Xr4oxcoHT_bvA');
    } else if (name === 'tiktok') {
        window.open('https://www.tiktok.com/@use.opti?lang=en');
    } else {
        window.open(
            'https://www.instagram.com/use.opti/?igshid=NGExMmI2YTkyZg%3D%3D'
        );
    }
};

export const Section1 = () => {
    const classes = useStyles();
    return (
        <>
            <div className="containerw" name="first">
                <div className="Direita" />
                <video
                    className="EsquerdaVideo"
                    style={{width: '100%'}}
                    autoPlay
                    muted
                    loop
                >
                    <source src={videopg1} type="video/webm" />
                </video>

                <div className="Baixo">
                    <Link activeClass="active" to="service" spy smooth>
                        <video className={classes.seta} autoPlay muted loop>
                            <source src={seta} type="video/webm" />
                        </video>
                    </Link>
                </div>
                <div className="Centro">
                    <div className="Logo">
                        <img src={logo} alt="" className={classes.logo} />
                    </div>
                    <div className="Text">
                        <h1 className={classes.texto}>
                            a sua nova ferramenta de investimentos.
                        </h1>
                        <div
                            style={{
                                backgroundColor: '#fff',
                                height: '10px',
                                width: '85%',
                                marginLeft: '13%'
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export const Section2 = ({texts, title, imgs, hover, keys}) => {
    const classes = useStyles();
    const [index, setIndex] = useState(0);

    return (
        <>
            <div name="service" className="container2">
                <div className="topo2">
                    <div>
                        <video style={{width: '100%'}} autoPlay muted loop>
                            <source src={video} type="video/webm" />
                        </video>
                    </div>
                </div>

                <div
                    className={
                        window.innerWidth >= 1600
                            ? 'Centro2'
                            : 'Centro2Minimize'
                    }
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            color: '#d36233',
                            margin:
                                window.innerWidth >= 1600
                                    ? '80px 0px 0px 0px'
                                    : '20px 0px 0px 0px'
                            /* margin:
                                window.innerWidth >= 1600
                                    ? '-60px 0px 0px 0px'
                                    : '-40px 0px 0px 0px' */
                        }}
                    >
                        {title.map((t, i) => (
                            <div
                                key={keys[i]}
                                className={classes.feacturesText}
                            >
                                <div>
                                    <button
                                        type="button"
                                        style={{
                                            backgroundColor: 'transparent',
                                            border: 0
                                        }}
                                        onFocus={emptyString}
                                        onBlur={emptyString}
                                        onMouseOver={() => setIndex(keys[i])}
                                        onMouseOut={() => setIndex(0)}
                                    >
                                        {/* <img
                                                src={imgs[i]}
                                                alt=""
                                                className={`${hover[i]}`}
                                                style={{width: 150}}
                                            /> */}
                                        {i === 3 ? (
                                            <div
                                                className={`${hover[i]}`}
                                                style={{
                                                    width:
                                                        window.innerWidth >=
                                                        1600
                                                            ? 220
                                                            : 100,
                                                    height:
                                                        window.innerWidth >=
                                                        1600
                                                            ? 200
                                                            : 100
                                                }}
                                            />
                                        ) : (
                                            <div
                                                className={`${hover[i]}`}
                                                style={{
                                                    width:
                                                        window.innerWidth >=
                                                        1600
                                                            ? 200
                                                            : 100,
                                                    height:
                                                        window.innerWidth >=
                                                        1600
                                                            ? 200
                                                            : 100
                                                }}
                                            />
                                        )}
                                    </button>
                                </div>
                                <div>
                                    {window.innerWidth >= 1600 ? (
                                        <h2>{t}</h2>
                                    ) : (
                                        <h5>{t}</h5>
                                    )}
                                </div>
                                <Radio
                                    className={classes.radio}
                                    checked={index === keys[i]}
                                />
                            </div>
                        ))}
                    </div>
                    <div
                        style={{
                            fontSize: window.innerWidth >= 1600 ? 32 : 22,
                            textAlign: 'center',
                            /* marginTop:
                                window.innerWidth >= 1300 ? '60px' : '30px', */
                            marginLeft:
                                window.innerWidth >= 1600
                                    ? '200px'
                                    : window.innerWidth >= 1300
                                    ? '160px'
                                    : '120px',
                            color: '#fff',
                            width: '82%'
                        }}
                    >
                        <span>{texts[index]}</span>
                    </div>
                </div>

                <div className="base2">
                    <div
                        style={{
                            backgroundColor: '#d36233',
                            height: '10px',
                            width: '60%',
                            marginLeft: '5%'
                        }}
                    />
                    <Link to="three" spy smooth>
                        {/* <img src={seta} alt="" className={classes.setaDown} /> */}
                        <video className={classes.setaDown} autoPlay muted loop>
                            <source src={seta} type="video/webm" />
                        </video>
                    </Link>
                </div>
            </div>
        </>
    );
};

export const Section3 = () => {
    const classes = useStyles();
    return (
        <>
            <div name="three" className="container2">
                {/* <div className="topo3" /> */}
                <video
                    className="videopg3"
                    style={{width: '110%', height: '100%'}}
                    autoPlay
                    muted
                    loop
                >
                    <source src={videopg3} type="video/webm" />
                </video>

                <div
                    className={
                        window.innerWidth >= 1360
                            ? 'Centro3'
                            : 'Centro3Minimize'
                    }
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        color: '#d36233',
                        width: '100%'
                    }}
                >
                    <div
                        style={{
                            textAlign: 'end',
                            marginRight: 40,
                            marginTop: window.innerWidth >= 1600 ? 0 : 50,
                            width: '100%',
                            fontSize: window.innerWidth >= 1600 ? 20 : 12
                        }}
                    >
                        <p className="text">ATIVOS FAVORITOS</p>
                        <p className="text2" style={{color: '#cac6c2'}}>
                            Tenha grade e mapa de opções <br /> dos ativos que
                            você mais <br />
                            acompanha.
                        </p>
                    </div>
                    <div style={{height: '100px'}}>
                        <img
                            src={computador3}
                            alt=""
                            className={classes.computador}
                        />
                        <div
                            style={{
                                backgroundColor: '#d36233',
                                height: '10px',
                                width: '85%',
                                marginLeft: '5%'
                            }}
                        />
                    </div>
                </div>
                <div className="base2">
                    <Link activeClass="active" to="four" spy smooth>
                        <video
                            className={
                                window.innerWidth > 1360
                                    ? classes.seta2
                                    : classes.setaMini
                            }
                            autoPlay
                            muted
                            loop
                        >
                            <source src={seta} type="video/webm" />
                        </video>
                    </Link>
                </div>
            </div>
        </>
    );
};
export const Section4 = () => {
    const classes = useStyles();
    return (
        <>
            <div name="four" className="container2">
                <video
                    className="videopg4"
                    style={{width: '110%', height: '100%'}}
                    autoPlay
                    muted
                    loop
                >
                    <source src={videopg4} type="video/webm" />
                </video>
                <div
                    className={
                        window.innerWidth > 1360 ? 'Centro3' : 'Centro3Minimize'
                    }
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        color: '#d36233',
                        width: '100%'
                    }}
                >
                    <div style={{height: '100px'}}>
                        <img
                            src={computador1}
                            alt=""
                            className={classes.computador}
                        />
                    </div>
                    <div style={{width: '100%', alignItems: 'center'}}>
                        <div
                            style={{
                                textAlign: 'end',
                                marginRight: 40,
                                margin: 'auto',
                                marginTop: 20,
                                width: '80%',
                                fontSize: window.innerWidth >= 1600 ? 20 : 12
                            }}
                        >
                            <p className="text">LAYOUT CUSTOMIZÁVEL</p>
                            <p className="text2" style={{color: '#cac6c2'}}>
                                Visualize apenas os campos que <br /> mais
                                importam para a sua análise.
                            </p>
                        </div>
                        <div
                            style={{
                                marginTop: 150,
                                backgroundColor: '#d36233',
                                height: '10px',
                                width: '85%',
                                marginLeft: '5%'
                            }}
                        />
                    </div>
                </div>
                <div className="base2">
                    <Link activeClass="active" to="five" spy smooth>
                        <video
                            className={
                                window.innerWidth > 1360
                                    ? classes.seta2
                                    : classes.setaMini
                            }
                            autoPlay
                            muted
                            loop
                        >
                            <source src={seta} type="video/webm" />
                        </video>
                    </Link>
                </div>
            </div>
        </>
    );
};
export const Section5 = () => {
    const classes = useStyles();
    return (
        <>
            <div name="five" className="container2">
                <video
                    className="videopg5"
                    style={{width: '110%', height: '100%'}}
                    autoPlay
                    muted
                    loop
                >
                    <source src={videopg5} type="video/webm" />
                </video>
                <div
                    className={
                        window.innerWidth > 1360 ? 'Centro3' : 'Centro3Minimize'
                    }
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        color: '#d36233',
                        width: '100%'
                    }}
                >
                    <div
                        style={{
                            textAlign: 'end',
                            marginRight: 40,
                            marginTop: 20,
                            width: '100%'
                        }}
                    >
                        <p className="text">FILTROS POR ATIVOS</p>
                        <p className="text2" style={{color: '#cac6c2'}}>
                            Defina filtros específicos para <br /> cada ativo
                            favorito.
                        </p>
                    </div>
                    <div style={{height: '100px'}}>
                        <img
                            src={computador2}
                            alt=""
                            className={classes.computador}
                        />
                        <div
                            style={{
                                backgroundColor: '#d36233',
                                height: '10px',
                                width: '85%',
                                marginLeft: '5%'
                            }}
                        />
                    </div>
                </div>
                <div className="base2">
                    <Link activeClass="active" to="six" spy smooth>
                        <video
                            className={
                                window.innerWidth > 1360
                                    ? classes.seta2
                                    : classes.setaMini
                            }
                            autoPlay
                            muted
                            loop
                        >
                            <source src={seta} type="video/webm" />
                        </video>
                    </Link>
                </div>
            </div>
        </>
    );
};
export const Section6 = () => {
    const classes = useStyles();
    return (
        <>
            <div name="six" className="container2">
                <video
                    className="videopg6"
                    style={{width: '110%', height: '100%'}}
                    autoPlay
                    muted
                    loop
                >
                    <source src={videopg6} type="video/webm" />
                </video>
                <div
                    className={
                        window.innerWidth > 1360 ? 'Centro3' : 'Centro3Minimize'
                    }
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        color: '#d36233',
                        width: '100%'
                    }}
                >
                    <div style={{height: '100px'}}>
                        <img
                            src={computador4}
                            alt=""
                            className={classes.computador}
                        />
                    </div>
                    <div style={{width: '100%', alignItems: 'center'}}>
                        <div
                            style={{
                                textAlign: 'end',
                                marginRight: 40,
                                margin: 'auto',
                                marginTop: 20,
                                width: '80%'
                            }}
                        >
                            {window.innerWidth >= 1300 ? (
                                <>
                                    <p
                                        className="text"
                                        style={{fontFamily: 'Text me one'}}
                                    >
                                        CARTEIRA DE OPÇÕES
                                    </p>
                                    <h1 style={{color: '#cac6c2'}}>
                                        Registro de operações, cenário de <br />{' '}
                                        desmontagem, cáculo de lucro, <br />
                                        ROI, imposto estimado, dias úteis e{' '}
                                        <br /> risco.
                                    </h1>
                                </>
                            ) : (
                                <>
                                    <h2 style={{fontFamily: 'Text me one'}}>
                                        CARTEIRA DE OPÇÕES
                                    </h2>
                                    <h4 style={{color: '#cac6c2'}}>
                                        Registro de operações, cenário de <br />{' '}
                                        desmontagem, cáculo de lucro, <br />
                                        ROI, imposto estimado, dias úteis e{' '}
                                        <br /> risco.
                                    </h4>
                                </>
                            )}
                        </div>
                        <div
                            style={{
                                marginTop: 150,
                                backgroundColor: '#d36233',
                                height: '10px',
                                width: '85%',
                                marginLeft: '5%'
                            }}
                        />
                    </div>
                </div>
                <div className="base2">
                    <Link activeClass="active" to="eight" spy smooth>
                        <video
                            className={
                                window.innerWidth > 1360
                                    ? classes.seta2
                                    : classes.setaMini
                            }
                            autoPlay
                            muted
                            loop
                        >
                            <source src={seta} type="video/webm" />
                        </video>
                    </Link>
                </div>
            </div>
        </>
    );
};

export const Section8 = () => {
    const classes = useStyles();
    const [openDialog, setOpenDialog] = useState(false);

    return (
        <>
            <div className="containerR" name="eight">
                <div className="esquerdaR">
                    <div
                        style={{
                            width: '100%',
                            margin: '80px'
                        }}
                    >
                        <div
                            style={{
                                width: '70%',
                                color: '#e37039',
                                fontSize: window.innerWidth >= 1600 ? 75 : 40,
                                fontWeight: 700
                            }}
                        >
                            ASSINE AGORA!
                        </div>
                        <div
                            style={{
                                width: '80%',
                                color: '#e37039',
                                fontSize: window.innerWidth >= 1600 ? 50 : 20,
                                marginTop: '80px'
                            }}
                        >
                            EXPLORE TODO POTENCIAL DAS OPERAÇÕES COM OPÇÕES!
                        </div>
                        <div
                            style={{
                                marginTop: '90px'
                                // marginLeft: '-130px'
                            }}
                        >
                            <button
                                type="button"
                                style={{
                                    backgroundColor: 'transparent',
                                    border: 0
                                }}
                                onClick={() => setOpenDialog(true)}
                            >
                                <img
                                    style={{
                                        width:
                                            window.innerWidth >= 1600
                                                ? '400px'
                                                : '300px'
                                    }}
                                    src={vemopti}
                                    alt="button"
                                />
                            </button>
                        </div>
                        <div
                            style={{
                                borderTop: '10px solid #e37039',
                                width: '60%',
                                marginTop: '-180px'
                            }}
                        />

                        <div
                            style={{
                                width: '50%',
                                marginLeft:
                                    window.innerWidth >= 1300 ? '60%' : '53.5%',
                                marginTop:
                                    window.innerWidth >= 1360 ? '140px' : '90px'
                            }}
                        />
                    </div>
                </div>
                <div className="CentroR">
                    <video
                        className="Esquerda"
                        style={{
                            width: '100%',
                            minHeight: '100%',
                            objectFit: 'cover'
                        }}
                        autoPlay
                        muted
                        loop
                    >
                        <source src={videopg7} type="video/webm" />
                    </video>
                </div>
                <div className="direitaR" style={{marginLeft: -15}} />
            </div>
            <Modal
                disableEnforceFocus
                open={openDialog}
                PaperProps={{
                    style: {
                        backgroundColor: 'rgba(211, 211, 211, 0.4)',
                        boxShadow: 'none'
                    }
                }}
                className={classes.modal}
                style={{
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    outline: 0,
                    backdropFilter: 'blur(5px)'
                }}
            >
                <Card
                    style={{
                        backgroundColor: 'rgba(211, 211, 211, 0.4)',
                        height: '38rem',
                        borderRadius: '20px'
                    }}
                    className={classes.modal}
                >
                    <div
                        style={{
                            textAlign: 'right',
                            fontSize: 32,
                            marginRight: '5px',
                            marginTop: '-15px'
                        }}
                    >
                        <ion-icon
                            style={{
                                backgroundColor: '#e37039',
                                color: '#fff',
                                borderRadius: '50%',
                                cursor: 'pointer'
                            }}
                            onClick={() => setOpenDialog(false)}
                            name="close-circle-outline"
                        />
                    </div>
                    <Card.Body style={{fontFamily: 'Roboto'}}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}
                        >
                            <img alt="" style={{width: '900px'}} src={titulo} />
                        </div>
                        <PlanosButton />
                    </Card.Body>
                </Card>
            </Modal>
        </>
    );
};
export const Section7 = () => {
    const classes = useStyles();
    const history = useHistory();
    return (
        <>
            <div name="seven" className="container7">
                <div className="topo7">
                    <Link activeClass="active" to="first" spy smooth>
                        <video
                            className={
                                window.innerWidth >= 1600
                                    ? classes.seta3
                                    : classes.seta3Mini
                            }
                            autoPlay
                            muted
                            loop
                        >
                            <source src={setaup} type="video/webm" />
                        </video>
                    </Link>
                    <div
                        style={{
                            backgroundColor: '#787374',
                            height: '10px',
                            width: '85%',
                            margin: '0 auto'
                        }}
                    />
                </div>
                <div
                    className="Centro7"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center',
                        color: '#d36233',
                        width: '100%'
                    }}
                >
                    {window.innerWidth >= 1600 ? (
                        <h2 style={{color: '#000'}}>
                            <span
                                style={{cursor: 'pointer'}}
                                onClick={() => {
                                    window.location.href =
                                        'mailto:contato@useopti.com.br';
                                }}
                            >
                                CENTRAL DE AJUDA <br />
                            </span>
                            <Link
                                style={{cursor: 'pointer', color: '#000'}}
                                activeClass="active"
                                to="first"
                                spy
                                smooth
                                onClick={() => handleSobre(history)}
                            >
                                SOBRE NÓS <br />
                            </Link>
                            <Link
                                style={{cursor: 'pointer', color: '#000'}}
                                activeClass="active"
                                to="first"
                                spy
                                smooth
                                onClick={() => handleFaq(history)}
                            >
                                PERGUNTAS FREQUENTES <br />
                            </Link>
                            <Link
                                style={{cursor: 'pointer', color: '#000'}}
                                activeClass="active"
                                to="first"
                                spy
                                smooth
                                onClick={() => handlePrivacidade(history)}
                            >
                                POLÍTICAS DE PRIVACIDADE <br />
                            </Link>
                            <Link
                                style={{cursor: 'pointer', color: '#000'}}
                                activeClass="active"
                                to="first"
                                spy
                                smooth
                                onClick={() => handleTermos(history)}
                            >
                                TERMOS DE USO
                            </Link>
                        </h2>
                    ) : (
                        <h4 style={{color: '#000'}}>
                            <span
                                style={{cursor: 'pointer'}}
                                onClick={() => {
                                    window.location.href =
                                        'mailto:contato@useopti.com.br';
                                }}
                            >
                                CENTRAL DE AJUDA <br />
                            </span>
                            <Link
                                style={{cursor: 'pointer', color: '#000'}}
                                activeClass="active"
                                to="first"
                                spy
                                smooth
                                onClick={() => handleSobre(history)}
                            >
                                SOBRE NÓS <br />
                            </Link>
                            <Link
                                style={{cursor: 'pointer', color: '#000'}}
                                activeClass="active"
                                to="first"
                                spy
                                smooth
                                onClick={() => handleFaq(history)}
                            >
                                PERGUNTAS FREQUENTES <br />
                            </Link>
                            <Link
                                style={{cursor: 'pointer', color: '#000'}}
                                activeClass="active"
                                to="first"
                                spy
                                smooth
                                onClick={() => handlePrivacidade(history)}
                            >
                                POLÍTICAS DE PRIVACIDADE <br />
                            </Link>
                            <Link
                                style={{cursor: 'pointer', color: '#000'}}
                                activeClass="active"
                                to="first"
                                spy
                                smooth
                                onClick={() => handleTermos(history)}
                            >
                                TERMOS DE USO
                            </Link>
                        </h4>
                    )}
                </div>
                <div className="base7">
                    <div
                        style={{
                            backgroundColor: '#787374',
                            height: '10px',
                            width: '85%',
                            margin: '0 auto'
                            // marginLeft: '5%'
                        }}
                    />
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            color: '#313031',
                            margin:
                                window.innerWidth >= 1600
                                    ? 0
                                    : '-15px 0px -75px 0px'
                            // marginTop: window.innerWidth >= 1600 ? 0 : '-70px'
                        }}
                    >
                        <div style={{margin: '8%'}}>
                            <img
                                src={logofinal}
                                alt=""
                                className={classes.logofinal}
                            />
                            <h4>
                                AV SHISHIMA HIFUMI, 2911 <br /> PARQUE
                                TECNOLÓGICO UNIVAP, URBANOVA
                                <br /> SÃO JOSÉ DOS CAMPOS - SP <br />
                                CEP 12244-000
                            </h4>
                        </div>
                        <div style={{maxWidth: '300px', margin: '8%'}}>
                            <h2
                                style={{
                                    color: '#d36233',
                                    marginLeft: '-20px',
                                    cursor: 'pointer'
                                }}
                            >
                                <a
                                    onClick={() => {
                                        window.location.href =
                                            'mailto:contato@useopti.com.br';
                                    }}
                                >
                                    contato@useopti.com.br
                                </a>
                            </h2>

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <button
                                    type="button"
                                    style={{
                                        backgroundColor: 'transparent',
                                        border: 0
                                    }}
                                    onClick={() => handleLink('instagram')}
                                >
                                    <img
                                        src={insta}
                                        alt=""
                                        className={classes.midias}
                                        style={{cursor: 'pointer'}}
                                    />
                                </button>

                                <button
                                    type="button"
                                    style={{
                                        backgroundColor: 'transparent',
                                        border: 0
                                    }}
                                    onClick={() => handleLink('youtube')}
                                >
                                    <img
                                        src={youtube}
                                        alt=""
                                        className={classes.midias}
                                        style={{cursor: 'pointer'}}
                                    />
                                </button>

                                <button
                                    type="button"
                                    style={{
                                        backgroundColor: 'transparent',
                                        border: 0
                                    }}
                                    onClick={() => handleLink('tiktok')}
                                >
                                    <img
                                        src={twitter}
                                        alt=""
                                        className={classes.midias}
                                        style={{cursor: 'pointer'}}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
