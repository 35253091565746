/* eslint-disable react/no-array-index-key */
/* eslint-disable import/prefer-default-export */
import React, {Fragment} from 'react';

/**
 * @param {[string]} [messageList]
 */
export function makeHelperText(messageList = []) {
    return messageList && messageList.length > 1 ? (
        <>
            {messageList.map((msg, key) => (
                <Fragment key={key}>
                    {msg}
                    <br />
                </Fragment>
            ))}
        </>
    ) : (
        messageList[0]
    );
}
