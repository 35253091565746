import React from 'react';
import {Card} from 'react-bootstrap';
import moment from 'moment';
import {TextField, InputAdornment, Button} from '@material-ui/core';

import icone from 'src/img/OPTI_logo.avif';
// import cancel from 'src/img/icone-cancelLaranja.avif';
import check from 'src/img/icone-check.avif';
import './modalstyles.css';

export default function ReativarModal({classes, setOpen, setReativar, data}) {
    const handleOpen = () => {
        setReativar(true);
    };

    return (
        <>
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontFamily: 'Roboto'
                }}
            >
                <Card
                    style={{
                        backgroundColor: '#cac6c2',
                        width: '45%',
                        height: '90%',
                        display: 'flex',
                        flexDirection: 'row',
                        borderRadius: '15px',
                        fontSize: 26
                    }}
                >
                    <div style={{padding: '2.5rem', width: '100%'}}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '54%'
                            }}
                        >
                            <img src={icone} alt="" style={{width: '50px'}} />
                            <div
                                style={{
                                    fontWeight: 700,
                                    fontSize: 26
                                }}
                            >
                                ALTERAÇÃO DE CADASTRO
                            </div>
                        </div>
                        <div
                            style={{
                                marginTop: '1rem',
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div className="dadostext">
                                ID:{' '}
                                <span style={{color: '#d36233'}}>
                                    * {data?.id}
                                </span>
                            </div>
                            <div className="dadostext">
                                TEMPO CANCELADO:{' '}
                                <span style={{color: '#d36233'}}>
                                    *{' '}
                                    {moment().diff(
                                        moment(data?.data_cancelled),
                                        'days',
                                        false
                                    )}
                                </span>
                            </div>
                        </div>
                        <div className="dadostext">
                            NOME:{' '}
                            <span style={{color: '#d36233'}}>
                                * {data?.nome}
                            </span>
                        </div>
                        <div className="dadostext">
                            E-MAIL:{' '}
                            <span style={{color: '#d36233'}}>
                                * {data?.email}
                            </span>
                        </div>
                        <div>
                            <div className="dadostext">
                                DATA NASCIMENTO:{' '}
                                <span style={{color: '#d36233'}}>
                                    {data?.data_nascimento}
                                </span>
                            </div>

                            <div className="dadostext">
                                CPF:{' '}
                                <span style={{color: '#d36233'}}>
                                    {data?.cpf}
                                </span>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <div className="dadostext">
                                    PAÍS:{' '}
                                    <span style={{color: '#d36233'}}>
                                        {data?.pais}
                                    </span>
                                </div>

                                <div className="dadostext">
                                    CIDADE/ESTADO:{' '}
                                    <span style={{color: '#d36233'}}>
                                        {data?.cidade_estado}
                                    </span>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <div className="dadostext">
                                    CELULAR:
                                    <span style={{color: '#d36233'}}>
                                        {' '}
                                        {data?.celular}
                                    </span>
                                </div>

                                <div className="dadostext">
                                    GÊNERO:{' '}
                                    <span style={{color: '#d36233'}}>
                                        {data?.genero}
                                    </span>
                                </div>
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <div className="dadostext">
                                    DATA CADASTRO:{' '}
                                    <span style={{color: '#d36233'}}>
                                        {data?.data_cad}
                                    </span>
                                </div>

                                <div className="dadostext">
                                    PLANO:{' '}
                                    <span style={{color: '#d36233'}}>
                                        * {data?.plano}
                                    </span>
                                </div>
                            </div>
                            <div className="dadostext">
                                CONHECIMENTO:{' '}
                                <span style={{color: '#d36233'}}>
                                    {' '}
                                    {data?.conhecimento}
                                </span>
                            </div>

                            <div>
                                <TextField
                                    InputProps={{
                                        disableUnderline: true,
                                        className: classes.myInput,
                                        startAdornment: (
                                            <InputAdornment>
                                                OBS:
                                            </InputAdornment>
                                        )
                                    }}
                                    // defaultValue={profile?.name}
                                    variant="standard"
                                    className={classes.observation}
                                    // onBlur={(event) => handleChange(event, 'name')}
                                    // onKeyUp={handleKeyUp}
                                />
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    margin: '2rem 1rem 1rem 1rem'
                                }}
                            >
                                <Button className={classes.buttonModal}>
                                    EXPORTAR CSV
                                </Button>

                                <Button
                                    className={classes.buttonModal}
                                    onClick={handleOpen}
                                >
                                    REATIVAR CADASTRO
                                    <img
                                        src={check}
                                        alt=""
                                        style={{width: '30px'}}
                                    />
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div style={{margin: '-5px -5px 0px 0px'}}>
                        <ion-icon
                            style={{
                                fontSize: 32,
                                color: '#d36233',
                                backgroundColor: '#cac6c2',
                                borderRadius: '50%',
                                cursor: 'pointer'
                            }}
                            onClick={() => setOpen(false)}
                            name="close-circle-outline"
                        />
                    </div>
                </Card>
            </div>
        </>
    );
}
