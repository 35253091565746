export default {
    user: '/user',
    gateways: '/gateway',
    gatewayGroup: '/gatewayGroup',
    device: '/device',
    options: '/opcoes',
    charts: '/charts',
    cards: '/cards',
    wallet: '/wallet',
    users: '/user',
    login: '/login',
    preference: '/preference',
    planos: '/planos',
    mercado: '/mercado',
    roex: '/roex',
    calculator: '/calculator',
    admin: '/admin'
};
