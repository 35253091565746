/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable indent */
/* eslint-disable prefer-const */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable indent */
/* eslint-disable  no-plusplus */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-underscore-dangle */
import React, {useEffect, useState} from 'react';
import {Modal, Menu, MenuItem, TextField} from '@material-ui/core';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import {Card} from 'react-bootstrap';
import toast, {Toaster} from 'react-hot-toast';
import api from '@app/utils/api';
import './PlanosPayment.css';
import CheckoutMP from '@app/components/CheckoutMP';
import PaymentStatus from '@app/components/PaymentStatus';
import Loading from '@app/components/loading/Loading';
import app from '@app/firebase/base';
// import {MP} from './img/MercadoPago.png';
// import {Bandeiras} from './img/Bandeiras.png';

const useStyles = makeStyles(() =>
    createStyles({
        myTextField: {
            backgroundColor: '#cac6c2',
            width: '90%',
            height: '35px',
            borderRadius: '20px'
            // marginTop: '0.5rem'
        },
        smallTextField: {
            backgroundColor: '#cac6c2',
            width: '80%',
            height: '35px',
            borderRadius: '20px',
            textAlign: 'center',
            justifyContent: 'center'
        },
        myInput: {
            disableUnderline: true,
            height: '45px',
            color: '#313031',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 24,
            '& .MuiTypography-colorTextSecondary': {
                color: '#d36233',
                fontSize: 24,
                margin: '10px'
            },
            '& .MuiInputBase-input': {
                textAlign: 'center'
            }
        },
        menuPaper: {
            backgroundColor: '#b8b4ae'
        }
    })
);

export default function PlanosPayment({
    open,
    setOpenCheck,
    reRender,
    planoSelected
}) {
    const [user, setUser] = useState();
    const [email, setEmail] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [plano, setPlano] = useState();
    const [prossiga, setProssiga] = useState(false);
    const [errorlog, setErrorLog] = useState(
        'Identificamos um erro na transação, pagamento não efetuado.'
    );
    const [finish, setFinish] = useState(false);
    const [loading, setLoading] = useState(false);
    const [approved, setApproved] = useState(false);
    const [formdata, setFormdata] = useState({
        nome: '',
        email: '',
        cpf: '',
        fone: '',
        celular: '',
        cep: '',
        numeroend: ''
    });
    const [client, setClient] = useState(false);
    const openMenu = Boolean(anchorEl);

    const handleKeyUp = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            event.target.blur();
        }
    };

    const classes = useStyles();
    const [labels, setLabels] = useState([
        {
            name: 'crédito',
            selected: false,
            status: true
        },
        {
            name: 'debito',
            selected: false,
            status: true
        },
        {
            name: 'pix',
            selected: false,
            status: false
        }
    ]);
    const [type, setType] = useState();
    const [phone, setPhone] = useState('');
    const [mobile, setMobile] = useState('');
    const [document, setDocument] = useState('');
    const [isPending, setIsPending] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState('');

    const phoneMask = (value) => {
        if (!value) return '';
        value = value.replace(/\D/g, '');
        value = value.replace(/(\d{2})(\d)/, '($1) $2');
        value = value.replace(/(\d)(\d{4})$/, '$1-$2');
        return value;
    };

    const handleClose = () => {
        setProssiga(false);
        // setMethod(null);
        setOpenCheck(false);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const changePlano = (plan) => {
        setPlano(plan);
    };

    const advance = () => {
        const id = api.currentUser?.user_id;
        const find = Object.values(formdata);
        if (client) {
            api.assinatura
                .updateClient(formdata, email, id, client)
                .then(() => {
                    if (find.includes('')) {
                        toast.error('Dados Incompletos');
                    } else {
                        setProssiga(true);
                    }
                })
                .catch((e) => console.log(e));
        } else if (find.includes('')) {
            toast.error('Dados Incompletos');
        } else if (api.currentUser) {
            api.assinatura
                .createClient(formdata, email, api.currentUser?.user_id)
                .then((res) => {
                    setClient(res.data);
                    console.log('CLIENTE CRIADO');
                    setProssiga(true);
                })
                .catch((err) => console.log(err));
        } else {
            createAccount();
            toast.success('Conta registrada com sucesso!');
        }
    };

    function formatCnpjCpf(value) {
        const cnpjCpf = value.replace(/\D/g, '');

        if (cnpjCpf.length <= 11) {
            return value
                .replace(/\D/g, '')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d)/, '$1.$2')
                .replace(/(\d{3})(\d{1,2})/, '$1-$2')
                .replace(/(-\d{2})\d+?$/, '$1');
        }

        return cnpjCpf.replace(
            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
            '$1.$2.$3/$4-$5'
        );
    }

    const handleChange = (id, value) => {
        if (id === 'nome') {
            formdata.nome = value;
        } else if (id === 'email') {
            formdata.email = value;
        } else if (id === 'fone') {
            formdata.fone = value;
        } else if (id === 'cep') {
            formdata.cep = value;
        } else if (id === 'cpf') {
            formdata.cpf = value;
        } else if (id === 'numeroend') {
            formdata.numeroend = value;
        } else if (id === 'celular') {
            formdata.celular = value;
        }
    };

    const _password = {
        value: '',
        length: 6,
        characters: {
            numbers: '0123456789',
            lowercase: 'abcdefghijklmnopqrstuvwxyz',
            uppercase: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
            // special: '?/~^{}[]!@#$%&*()_-+=.,:;'
        },
        generate(chars) {
            let pwd = '';
            this.length =
                this.length < 5 || this.length > 50 ? 15 : this.length;
            chars = chars || Object.values(this.characters).join();
            for (let i = 0; i < this.length; i++) {
                pwd += chars[Math.floor(Math.random() * chars.length)];
            }
            this.value = pwd;
            return pwd;
        }
    };

    const createAccount = () => {
        setIsPending(true);
        setLoadingMsg('Criando conta...');
        try {
            const password = _password.generate(
                _password.characters.numbers +
                    _password.characters.lowercase +
                    _password.characters.uppercase
            );
            app.auth()
                .createUserWithEmailAndPassword(formdata.email, password)
                .then((resFire) => {
                    resFire.user
                        .updateProfile({displayName: formdata.nome})
                        .then(() => {
                            api.user
                                .createUserPlano({
                                    uid: resFire.user.uid,
                                    name: formdata.nome,
                                    email: formdata.email
                                })
                                .then(() => {
                                    api.user
                                        .sendEmail(
                                            formdata.nome,
                                            formdata.email,
                                            password
                                        )
                                        .then((res) => console.log(res))
                                        .catch((e) => console.log(e));
                                    api.assinatura
                                        .createClient(
                                            formdata,
                                            email,
                                            api.currentUser?.user_id
                                        )
                                        .then((res) => {
                                            setClient(res.data);
                                            console.log('CLIENTE CRIADO');
                                            setProssiga(true);
                                        })
                                        .catch((err) => console.log(err));
                                })
                                .catch((err) => {
                                    setLoadingMsg('');
                                    setIsPending(false);
                                    toast.error(err.message);
                                });
                        })
                        .catch(() => {
                            setLoadingMsg('');
                            setIsPending(false);
                        });
                })
                .catch((err) => {
                    setLoadingMsg('');
                    setIsPending(false);
                    if (err.code === 'auth/email-already-in-use') {
                        toast.error('Este email já está em uso.', {
                            position: 'top-center',
                            autoClose: false,
                            hideProgressBar: false,
                            closeOnClick: true
                        });
                    } else {
                        toast.error(err.message, {
                            position: 'top-center',
                            autoClose: false,
                            hideProgressBar: false,
                            closeOnClick: true
                        });
                    }
                });
        } catch (err) {
            setLoadingMsg('');
            setIsPending(false);
            toast.error(err.message);
        }
    };

    const select = (name) => {
        const listLabels = [];
        labels.forEach((l, i) => {
            if (l.status) {
                if (l.selected && l.name !== name) {
                    listLabels.push({
                        name: l.name,
                        selected: false,
                        status: true
                    });
                } else if (l.name === name) {
                    listLabels.push({
                        name: l.name,
                        selected: true,
                        status: true
                    });
                    // (l.name);
                } else {
                    listLabels.push({
                        name: l.name,
                        selected: false,
                        status: true
                    });
                }
            } else {
                // console.log(l);
                listLabels.push({name: l.name, selected: false, status: false});
            }
        });
        // console.log(listLabels);
        setLabels(listLabels);
    };

    useEffect(() => {
        api.assinatura
            .checkForClient(api.currentUser?.user_id)
            .then((res) => {
                if (res.status === 200) {
                    setFormdata({
                        nome: res.data.name,
                        email: res.data.email,
                        cpf: res.data.cpfCnpj,
                        fone: res.data.phone,
                        celular: res.data.mobilePhone,
                        cep: res.data.postalCode,
                        numeroend: res.data.addressNumber
                    });
                    setClient(res.data.id);
                }
            })
            .catch((e) => console.log(e));
    }, []);

    useEffect(() => {
        if (formdata.fone !== '') setPhone(formdata.fone);
        if (formdata.celular !== '') setMobile(formdata.celular);
        if (formdata.cpf !== '') setDocument(formdata.cpf);
    }, [client, formdata]);

    useEffect(() => {
        if (api.currentSession && api.currentUser) {
            setUser(api.currentUser.name);
            setEmail(api.currentUser.email);
        }
    }, [reRender]);

    useEffect(() => {
        const found = labels.find((l) => l.selected === true);
        setType(found?.name);
    }, [labels]);

    useEffect(() => {
        if (planoSelected) setPlano(planoSelected);
    }, [planoSelected]);

    return (
        <>
            <Loading open={loading} msg="Processando pagamento..." />
            <Modal
                open={open}
                // className=".modalOutline"
                // onClose={close}
                disableEnforceFocus
                style={{
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    backdropFilter: 'blur(5px)'
                }}
            >
                <div>
                    <Toaster />
                    {!finish ? (
                        <Card
                            style={{
                                borderRadius: '20px',
                                background: '#cac6c0'
                            }}
                        >
                            <div style={{display: 'flex'}}>
                                <Card.Body>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            // textAlign: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <div>
                                            <div
                                                style={{
                                                    transform: 'rotate(270deg)',
                                                    textAlign: 'center',
                                                    border: '0.2px solid #313031',
                                                    borderRadius: '30px',
                                                    marginLeft: !prossiga
                                                        ? '-300px'
                                                        : '-280px',
                                                    paddingRight: !prossiga
                                                        ? 190
                                                        : 135,
                                                    paddingLeft: !prossiga
                                                        ? 190
                                                        : 135,
                                                    fontSize: 26,
                                                    fontWeight: 700,
                                                    background: '#313031',
                                                    color: '#cac6c0'
                                                }}
                                            >
                                                {prossiga
                                                    ? 'FORMA DE PAGAMENTO'
                                                    : 'DADOS PESSOAIS'}
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                marginLeft: '-265px',
                                                marginBottom: '-30px'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    borderBottom:
                                                        '5px solid #e37039'
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        border: '0.2px solid #b8b4ae',
                                                        borderRadius: '30px',
                                                        margin: '20px 20px 20px 20px',
                                                        background: '#b8b4ae',
                                                        color: '#fff',
                                                        fontFamily: 'Roboto'
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            marginLeft: '15px'
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                fontSize: 18,
                                                                fontWeight: 700
                                                            }}
                                                        >
                                                            NOME
                                                        </div>
                                                        <div
                                                            style={{
                                                                marginTop:
                                                                    '-5px',
                                                                fontSize: 18
                                                            }}
                                                        >
                                                            {user}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        border: '0.2px solid #b8b4ae',
                                                        borderRadius: '30px',
                                                        margin: '20px 20px 20px 20px',
                                                        background: '#b8b4ae',
                                                        color: '#fff',
                                                        fontFamily: 'Roboto'
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            marginLeft: '15px'
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                fontSize: 18,
                                                                fontWeight: 700
                                                            }}
                                                        >
                                                            E-MAIL
                                                        </div>
                                                        <div
                                                            style={{
                                                                marginTop:
                                                                    '-5px',
                                                                fontSize: 18
                                                            }}
                                                        >
                                                            {email}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        border: '0.2px solid #b8b4ae',
                                                        borderRadius: '30px',
                                                        margin: '20px 20px 20px 20px',
                                                        background: '#b8b4ae',
                                                        color: '#fff',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        fontFamily: 'Roboto'
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            marginLeft: '15px'
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                fontSize: 18,
                                                                fontWeight: 700
                                                            }}
                                                        >
                                                            PLANO ESCOLHIDO
                                                        </div>
                                                        <div
                                                            style={{
                                                                marginTop:
                                                                    '-5px',
                                                                fontSize: 18
                                                            }}
                                                        >
                                                            {!plano
                                                                ? planoSelected ===
                                                                  'mensal'
                                                                    ? 'sardinha mensal - R$ 9,90'
                                                                    : 'sardinha anual - R$ 69,90'
                                                                : plano ===
                                                                  'mensal'
                                                                ? 'sardinha mensal - R$ 9,90'
                                                                : plano ===
                                                                  'FREE'
                                                                ? 'sardinha anual - 30 dias grátis'
                                                                : 'sardinha anual - R$ 69,90'}
                                                        </div>
                                                    </div>
                                                    {plano === 'FREE' ? null : (
                                                        <div
                                                            style={{
                                                                marginLeft:
                                                                    '40px',
                                                                marginTop:
                                                                    '10px'
                                                            }}
                                                        >
                                                            <ion-icon
                                                                name="chevron-down-outline"
                                                                style={{
                                                                    fontSize: 28,
                                                                    color: '#e37039',
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={
                                                                    handleClick
                                                                }
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div style={{textAlign: 'center'}}>
                                                <div
                                                    style={{marginTop: '-60px'}}
                                                >
                                                    <img
                                                        style={{
                                                            width: '350px'
                                                        }}
                                                        src="./img/MercadoPago.png"
                                                        alt="Mercado Pago"
                                                    />
                                                </div>
                                                <div
                                                    style={{
                                                        marginTop: '-130px'
                                                    }}
                                                >
                                                    <img
                                                        style={{width: '350px'}}
                                                        src="./img/Bandeiras.png"
                                                        alt="Bandeiras Aceitas"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{marginLeft: '50px'}}>
                                            {!prossiga ? (
                                                <Card
                                                    style={{
                                                        backgroundColor:
                                                            '#313031',
                                                        color: '#e37039',
                                                        width: '550px',
                                                        height: '500px',
                                                        borderRadius: '20px',
                                                        textAlign: 'center'
                                                        // marginBottom: '-20px'
                                                    }}
                                                >
                                                    <div
                                                        sytle={{
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                fontSize: 26,
                                                                marginTop:
                                                                    '30px',
                                                                fontWeight: 700
                                                            }}
                                                        >
                                                            CADASTRO DO CLIENTE
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                                alignItems:
                                                                    'center',
                                                                fontSize: 24
                                                            }}
                                                        >
                                                            NOME
                                                            <TextField
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    className:
                                                                        classes.myInput
                                                                    /* startAdornment:
                                                                        (
                                                                            <InputAdornment>
                                                                                NOME:*
                                                                            </InputAdornment>
                                                                        ) */
                                                                }}
                                                                placeholder="NOME COMPLETO"
                                                                defaultValue={
                                                                    formdata.nome
                                                                }
                                                                variant="standard"
                                                                onBlur={(
                                                                    event
                                                                ) =>
                                                                    handleChange(
                                                                        'nome',
                                                                        event
                                                                            .target
                                                                            .value
                                                                    )
                                                                }
                                                                onKeyUp={
                                                                    handleKeyUp
                                                                }
                                                                className={
                                                                    classes.myTextField
                                                                }
                                                            />
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                                alignItems:
                                                                    'center',
                                                                fontSize: 24
                                                            }}
                                                        >
                                                            E-MAIL
                                                            <TextField
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    className:
                                                                        classes.myInput
                                                                    /* startAdornment:
                                                                        (
                                                                            <InputAdornment>
                                                                                E-MAIL:*
                                                                            </InputAdornment>
                                                                        ) */
                                                                }}
                                                                placeholder="E-MAIL"
                                                                defaultValue={
                                                                    formdata.email
                                                                }
                                                                variant="standard"
                                                                onBlur={(
                                                                    event
                                                                ) =>
                                                                    handleChange(
                                                                        'email',
                                                                        event
                                                                            .target
                                                                            .value
                                                                    )
                                                                }
                                                                onKeyUp={
                                                                    handleKeyUp
                                                                }
                                                                className={
                                                                    classes.myTextField
                                                                }
                                                            />
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    'column',
                                                                alignItems:
                                                                    'center',
                                                                fontSize: 24
                                                            }}
                                                        >
                                                            DOCUMENTO
                                                            <TextField
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    className:
                                                                        classes.myInput
                                                                    /* startAdornment:
                                                                        (
                                                                            <InputAdornment>
                                                                                CPF:*
                                                                            </InputAdornment>
                                                                        ) */
                                                                }}
                                                                placeholder="CPF / CNPJ"
                                                                value={formatCnpjCpf(
                                                                    document
                                                                )}
                                                                variant="standard"
                                                                onChange={(
                                                                    event
                                                                ) =>
                                                                    setDocument(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    )
                                                                }
                                                                className={
                                                                    classes.myTextField
                                                                }
                                                                /* onChange={(
                                                                    event
                                                                ) =>
                                                                    setCpf(
                                                                        event
                                                                            .target
                                                                            .value
                                                                    )
                                                                } */
                                                                onBlur={(
                                                                    event
                                                                ) =>
                                                                    handleChange(
                                                                        'cpf',
                                                                        event
                                                                            .target
                                                                            .value
                                                                    )
                                                                }
                                                                onKeyUp={
                                                                    handleKeyUp
                                                                }
                                                            />
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'flex'
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'column',
                                                                    alignItems:
                                                                        'center',
                                                                    fontSize: 24
                                                                }}
                                                            >
                                                                FONE
                                                                <TextField
                                                                    InputProps={{
                                                                        disableUnderline: true,
                                                                        className:
                                                                            classes.myInput
                                                                        /* startAdornment:
                                                                        (
                                                                            <InputAdornment>
                                                                                FONE:*
                                                                            </InputAdornment>
                                                                        ) */
                                                                    }}
                                                                    placeholder="(DDD) ***** **** "
                                                                    value={phoneMask(
                                                                        phone
                                                                    )}
                                                                    variant="standard"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        setPhone(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    className={
                                                                        classes.smallTextField
                                                                    }
                                                                    onBlur={(
                                                                        event
                                                                    ) =>
                                                                        handleChange(
                                                                            'fone',
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    onKeyUp={
                                                                        handleKeyUp
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'column',
                                                                    alignItems:
                                                                        'center',
                                                                    fontSize: 24
                                                                }}
                                                            >
                                                                CELULAR
                                                                <TextField
                                                                    InputProps={{
                                                                        disableUnderline: true,
                                                                        className:
                                                                            classes.myInput
                                                                        /* startAdornment:
                                                                        (
                                                                            <InputAdornment>
                                                                                CELULAR:*
                                                                            </InputAdornment>
                                                                        ) */
                                                                    }}
                                                                    placeholder="(DDD) ***** **** "
                                                                    value={phoneMask(
                                                                        mobile
                                                                    )}
                                                                    variant="standard"
                                                                    onChange={(
                                                                        event
                                                                    ) =>
                                                                        setMobile(
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    onBlur={(
                                                                        event
                                                                    ) =>
                                                                        handleChange(
                                                                            'celular',
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    onKeyUp={
                                                                        handleKeyUp
                                                                    }
                                                                    className={
                                                                        classes.smallTextField
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: 'flex'
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'column',
                                                                    alignItems:
                                                                        'center',
                                                                    fontSize: 24
                                                                }}
                                                            >
                                                                CEP
                                                                <TextField
                                                                    InputProps={{
                                                                        disableUnderline: true,
                                                                        className:
                                                                            classes.myInput
                                                                        /* startAdornment:
                                                                        (
                                                                            <InputAdornment>
                                                                                CEP:*
                                                                            </InputAdornment>
                                                                        ) */
                                                                    }}
                                                                    placeholder="CEP"
                                                                    defaultValue={
                                                                        formdata.cep
                                                                    }
                                                                    onBlur={(
                                                                        event
                                                                    ) =>
                                                                        handleChange(
                                                                            'cep',
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    onKeyUp={
                                                                        handleKeyUp
                                                                    }
                                                                    variant="standard"
                                                                    className={
                                                                        classes.smallTextField
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    flexDirection:
                                                                        'column',
                                                                    alignItems:
                                                                        'center',
                                                                    fontSize: 24
                                                                }}
                                                            >
                                                                Nº DE ENDEREÇO
                                                                <TextField
                                                                    InputProps={{
                                                                        disableUnderline: true,
                                                                        className:
                                                                            classes.myInput
                                                                        /* startAdornment:
                                                                        (
                                                                            <InputAdornment>
                                                                                Nº
                                                                                DE
                                                                                ENDEREÇO:*
                                                                            </InputAdornment>
                                                                        ) */
                                                                    }}
                                                                    placeholder="Nº ENDEREÇO"
                                                                    defaultValue={
                                                                        formdata.numeroend
                                                                    }
                                                                    onBlur={(
                                                                        event
                                                                    ) =>
                                                                        handleChange(
                                                                            'numeroend',
                                                                            event
                                                                                .target
                                                                                .value
                                                                        )
                                                                    }
                                                                    onKeyUp={
                                                                        handleKeyUp
                                                                    }
                                                                    variant="standard"
                                                                    className={
                                                                        classes.smallTextField
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{
                                                            fontSize: 22,
                                                            marginLeft: '300px',
                                                            // marginTop: '-10px'
                                                            marginTop: '-15px'
                                                        }}
                                                    >
                                                        <button
                                                            type="button"
                                                            style={{
                                                                backgroundColor:
                                                                    'transparent',
                                                                border: 0
                                                            }}
                                                            onClick={advance}
                                                        >
                                                            <img
                                                                style={{
                                                                    width: '200px',
                                                                    cursor: 'pointer'
                                                                }}
                                                                src="./img/botaoP.png"
                                                                alt="prosseguir"
                                                            />
                                                        </button>
                                                    </div>
                                                </Card>
                                            ) : (
                                                <CheckoutMP
                                                    setFinish={setFinish}
                                                    plano={plano}
                                                    setLoading={setLoading}
                                                    setApproved={setApproved}
                                                    setErrorLog={setErrorLog}
                                                    setProssiga={setProssiga}
                                                    email={email}
                                                    type={type}
                                                    formdata={formdata}
                                                    client={client}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </Card.Body>
                                <div
                                    style={{
                                        fontSize: 36,
                                        marginLeft: '-20px',
                                        marginRight: '-18px',
                                        marginTop:
                                            window.innerWidth <= 1366
                                                ? '0px'
                                                : '-18px'
                                    }}
                                >
                                    <ion-icon
                                        style={{
                                            cursor: 'pointer',
                                            color: '#e37039',
                                            background: '#cac6c0',
                                            border: '0.2px solid #cac6c0',
                                            borderRadius: '20px'
                                        }}
                                        onClick={handleClose}
                                        name="close-circle-outline"
                                    />
                                </div>
                            </div>
                        </Card>
                    ) : (
                        <PaymentStatus
                            setOpenCheck={setOpenCheck}
                            approved={approved}
                            plano={plano}
                            setFinish={setFinish}
                            errorlog={errorlog}
                            setApproved={setApproved}
                        />
                    )}
                </div>
            </Modal>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleMenuClose}
                classes={{paper: classes.menuPaper}}
                MenuListProps={{
                    'aria-labelledby': 'basic-button'
                }}
                anchorOrigin={{
                    vertical: -35,
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: -35,
                    horizontal: 'left'
                }}
            >
                <MenuItem
                    onClick={() => {
                        changePlano('mensal');
                        handleMenuClose();
                    }}
                    style={{background: '#b8b4ae', color: '#fff'}}
                >
                    PLANO MENSAL
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        changePlano('anual');
                        handleMenuClose();
                    }}
                    style={{background: '#b8b4ae', color: '#fff'}}
                >
                    PLANO ANUAL
                </MenuItem>
            </Menu>
        </>
    );
}
