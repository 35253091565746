/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */

import React from 'react';
import {Card} from 'react-bootstrap';

import background1 from 'src/components/imgvendas/icones_leading.avif';
import background2 from 'src/components/imgvendas/background2.avif';
import background3cima from 'src/components/imgvendas/background3cima.avif';
import background3baixo from 'src/components/imgvendas/background3baixo.avif';
import icone from 'src/img/OPTI_logo1_escuro.avif';
import iconeClaro from 'src/img/OPTI_logo1_claro.avif';
import imagem1 from 'src/components/imgvendas/imgcharts.avif';
import sobre1 from 'src/components/imgvendas/papelrasgado.avif';
import sobre2 from 'src/components/imgvendas/papelrasgado180.avif';
import buttonExp from 'src/components/imgvendas/botaoexperimente.avif';
import warning from 'src/components/imgvendas/warning.avif';
import transition from 'src/components/imgvendas/transition.avif';
import mercado from 'src/components/imgvendas/mercado.avif';
import carteira from 'src/components/imgvendas/carteira.avif';
import carteirapage from 'src/components/imgvendas/carteirapage.avif';
import mapa from 'src/components/imgvendas/mapa.avif';
import alvo from 'src/components/imgvendas/alvo.avif';
import roex from 'src/components/imgvendas/roex.avif';
import direto from 'src/components/imgvendas/direto.avif';
import ativomercado from 'src/components/imgvendas/ativomercado.avif';
import personalizacao from 'src/components/imgvendas/personalizacao.avif';
import custom from 'src/components/imgvendas/config.avif';
import grade from 'src/components/imgvendas/grade.avif';
import faixa from 'src/components/imgvendas/faixastrike.avif';
import rapido from 'src/components/imgvendas/rapido.avif';
import video from 'src/components/imgvendas/video.webm';
import graficos from 'src/components/imgvendas/graficos.avif';
import buttonLaranja from 'src/components/imgvendas/botaohovered.avif';
import funcionalidades from 'src/components/imgvendas/funcionalidades.avif';
import check from 'src/components/imgvendas/check.avif';
import computador from 'src/components/imgvendas/gradecalculadora.avif';
import pensador from 'src/components/imgvendas/pensador.avif';
import charts from 'src/components/imgvendas/chart.avif';
import wallet1 from 'src/components/imgvendas/wallet.avif';
import wallet2 from 'src/components/imgvendas/wallet2.avif';
import quero from 'src/components/imgvendas/botaoquero.avif';
import painel from 'src/components/imgvendas/painel.avif';
import seta from 'src/components/imgvendas/seta.avif';
import stars from 'src/components/imgvendas/stars.avif';
import teste from 'src/components/imgvendas/botaoteste.avif';

import './Vendas.css';

export const Section1 = ({setOpen, size}) => {
    return (
        <div
            style={{
                backgroundImage: `url(${background1})`,
                backgroundSize: 'cover',
                height: /* window.innerWidth > 1000 ? '75vh' : */ '100vh',
                width: '100%'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <div style={{position: 'absolute', left: '10%', top: '0.5%'}}>
                    <img src={icone} alt="" style={{width: '20rem'}} />
                </div>
                <div
                    style={{
                        top: '2.5%',
                        fontSize: size < 1000 ? 90 : 64,
                        fontWeight: '700'
                    }}
                    className="textSection1"
                >
                    NUNCA FOI TÃO FACIL
                    <br />
                    LUCRAR COM OPÇÕES
                </div>
                <div
                    style={{
                        top: size > 1600 ? '5%' : ' 5%',
                        fontSize: size > 1600 ? 50 : size < 1000 ? 60 : 30
                    }}
                    className="textSection1"
                >
                    Faça operações mais simples e lucrativas!
                </div>
                <div>
                    <img
                        src={sobre1}
                        alt=""
                        style={{
                            position: 'absolute',
                            width: '110vw',
                            top: '5.5%',
                            left: '-3%'
                        }}
                    />
                    <img
                        src={buttonExp}
                        alt=""
                        style={{
                            width: window.innerWidth > 1400 ? 576 : 410,
                            position: 'absolute',
                            left: '35%',
                            top: '6.75%',
                            cursor: 'pointer'
                        }}
                        className="compra"
                        onClick={setOpen}
                    />
                </div>
            </div>
        </div>
    );
};

export const Section2 = () => {
    return (
        <div
            style={{
                backgroundImage: `url(${background2})`,
                backgroundSize: 'cover',
                height: '125vh',
                width: '100%',
                fontFamily: 'Roboto'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <div
                    style={{
                        color: '#d36233',
                        position: 'absolute',
                        top: '10.5%',
                        // left: '12%',
                        textAlign: 'center',
                        marginTop:
                            window.innerWidth > 1600 || window.innerWidth < 1001
                                ? '0px'
                                : '10px',
                        fontSize:
                            window.innerWidth > 1600 || window.innerWidth < 1001
                                ? 60
                                : 50,
                        fontFamily: 'Roboto',
                        fontWeight: 700
                    }}
                >
                    DEIXE OS GRÁFICOS CONFUSOS NO PASSADO!
                </div>
                <div
                    style={{
                        color: '#313031',
                        position: 'absolute',
                        top: '11%',
                        // left: '10.5%',
                        marginTop:
                            window.innerWidth > 1600 || window.innerWidth < 1001
                                ? '0px'
                                : '20px',
                        fontSize:
                            window.innerWidth > 1600 || window.innerWidth < 1001
                                ? 50
                                : 32,
                        textAlign: 'center',
                        fontWeight: 700,
                        fontFamily: 'Roboto'
                    }}
                >
                    Pare de desperdiçar tempo e energia com informações difíceis
                    e excedentes.
                </div>
                <div
                    style={{
                        color: '#313031',
                        position: 'absolute',
                        top:
                            window.innerWidth > 1600 || window.innerWidth < 1001
                                ? '12%'
                                : '12.5%',
                        left:
                            window.innerWidth > 1600 || window.innerWidth < 1001
                                ? '20%'
                                : '15%',
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center'
                    }}
                >
                    <img
                        src={imagem1}
                        alt=""
                        style={{width: window.innerWidth > 1400 ? 576 : 410}}
                    />
                    <div
                        style={{
                            marginLeft: '30px',
                            color: '#313031',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <div
                            style={{
                                color: '#d36233',
                                fontSize: 30,
                                fontWeight: 900
                            }}
                        >
                            Não perca seu dinheiro suado!
                        </div>
                        <div>
                            <img src={warning} alt="" style={{width: '5%'}} />
                            <span style={{marginLeft: '10px', fontSize: 24}}>
                                Por causa dos Gráficos confusos
                            </span>
                        </div>
                        <div>
                            <img src={warning} alt="" style={{width: '5%'}} />
                            <span style={{marginLeft: '10px', fontSize: 24}}>
                                Com planilhas complicadas
                            </span>
                        </div>
                        <div>
                            <img src={warning} alt="" style={{width: '5%'}} />
                            <span style={{marginLeft: '10px', fontSize: 24}}>
                                Por decisões erradas
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        top: '12.3%',
                        left: '-2%',
                        right: '-5%',
                        width: '100%'
                    }}
                >
                    <img src={transition} alt="" style={{width: '107vw'}} />
                </div>
            </div>
        </div>
    );
};

export const Section3 = () => {
    return (
        <div
            style={{
                backgroundColor: '#313031',
                height:
                    window.innerWidth > 1600 || window.innerWidth < 1001
                        ? '550vh'
                        : '600vh',
                width: '100%'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <div
                    style={{
                        color: '#fff',
                        position: 'absolute',
                        top: '19.5%',
                        left: '7%',
                        fontSize:
                            window.innerWidth > 1600 || window.innerWidth < 1001
                                ? 62
                                : 50,
                        textAlign: 'center',
                        width: '90%'
                    }}
                >
                    <div>
                        A OPTI TEM TUDO QUE VOCÊ PRECISA PARA TOMAR <br />
                        DECISÕES COM MAIS CLAREZA E FACILIDADE.
                    </div>
                    <div>
                        <img
                            src={mercado}
                            alt=""
                            style={{
                                width: window.innerWidth > 1400 ? 1100 : 800
                            }}
                        />
                    </div>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        top: '27.7%',
                        color: '#fff',
                        fontFamily: 'Roboto',
                        fontWeight: 700
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center'
                        }}
                    >
                        <div
                            style={{
                                fontSize:
                                    window.innerWidth > 1600 ||
                                    window.innerWidth < 1001
                                        ? 50
                                        : 40
                            }}
                        >
                            DIRETO
                        </div>
                        <div
                            style={{
                                fontSize:
                                    window.innerWidth > 1600 ||
                                    window.innerWidth < 1001
                                        ? 40
                                        : 30,
                                width: '90%'
                            }}
                        >
                            Chega de complicação! Com a Opti, é acesso direto e
                            descomplicado ao
                            <br /> mundo das operações com opções. Sem
                            enrolação. Foque no que importa:
                            <br /> informações e ferramentas essenciais para sua
                            estratégia.
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '5%',
                            // width: '100%',
                            marginLeft: '10%'
                        }}
                    >
                        <div style={{width: '100%'}}>
                            <img
                                src={carteira}
                                alt=""
                                style={{
                                    width: window.innerWidth > 1400 ? 865 : 620
                                }}
                            />
                        </div>
                        <div style={{width: '100%'}}>
                            <div>
                                <img
                                    src={carteirapage}
                                    alt=""
                                    style={{
                                        width:
                                            window.innerWidth > 1400
                                                ? 650
                                                : 460,
                                        marginLeft: '2.5%'
                                    }}
                                />
                            </div>
                            <div>
                                <img
                                    src={mapa}
                                    alt=""
                                    style={{
                                        width:
                                            window.innerWidth > 1400
                                                ? 305
                                                : 215,
                                        marginLeft: '2.5%',
                                        marginTop: '2.5%'
                                    }}
                                />
                                <img
                                    src={alvo}
                                    alt=""
                                    style={{
                                        width:
                                            window.innerWidth > 1400
                                                ? 260
                                                : 185,
                                        marginLeft: '8%',
                                        marginTop: '2.5%'
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        top:
                            window.innerWidth > 1600 || window.innerWidth < 1001
                                ? '35%'
                                : '34.5%',
                        color: '#fff',
                        marginLeft: '10%',
                        fontFamily: 'Roboto'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            marginLeft: '-10%',
                            marginTop:
                                window.innerWidth > 1600 ||
                                window.innerWidth < 1001
                                    ? '0%'
                                    : '5%',

                            fontWeight: 700
                        }}
                    >
                        <div
                            style={{
                                fontSize:
                                    window.innerWidth > 1600 ||
                                    window.innerWidth < 1001
                                        ? 50
                                        : 40
                            }}
                        >
                            DESCOMPLICADO
                        </div>
                        <div
                            style={{
                                fontSize:
                                    window.innerWidth > 1600 ||
                                    window.innerWidth < 1001
                                        ? 40
                                        : 30,
                                width: '90%'
                            }}
                        >
                            Simplifique sua experiência! Com uma interface
                            intuitiva e recursos <br /> simples, facilitamos
                            todo o processo. Assim, você pode focar no que
                            <br /> realmente importa: suas estratégias e
                            resultados.
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            marginTop:
                                window.innerWidth > 1600 ||
                                window.innerWidth < 1001
                                    ? '5%'
                                    : '8%'
                        }}
                    >
                        <div style={{width: '100%'}}>
                            <img src={roex} alt="" style={{width: '60vw'}} />
                        </div>
                        <div
                            style={{
                                width: '100%',
                                position: 'relative'
                                // left: '20%'
                            }}
                        >
                            <div>
                                <img
                                    src={direto}
                                    alt=""
                                    style={{
                                        width:
                                            window.innerWidth > 1400
                                                ? 220
                                                : 155,
                                        marginLeft: '7%'
                                    }}
                                />
                            </div>
                            <div>
                                <img
                                    src={ativomercado}
                                    alt=""
                                    style={{
                                        width:
                                            window.innerWidth > 1400
                                                ? 305
                                                : 215,
                                        marginLeft: '2.5%',
                                        marginTop: '2.5%'
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        top:
                            window.innerWidth > 1600 || window.innerWidth < 1001
                                ? '42.3%'
                                : '43%',
                        color: '#fff',
                        marginLeft: '10%',
                        fontFamily: 'Roboto'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            marginLeft: '-10%',
                            // marginTop: '10%',

                            fontWeight: 700
                        }}
                    >
                        <div
                            style={{
                                fontSize:
                                    window.innerWidth > 1600 ||
                                    window.innerWidth < 1001
                                        ? 50
                                        : 40
                            }}
                        >
                            CUSTOMIZÁVEL
                        </div>
                        <div
                            style={{
                                fontSize:
                                    window.innerWidth > 1600 ||
                                    window.innerWidth < 1001
                                        ? 40
                                        : 30,
                                width: '90%'
                            }}
                        >
                            Tudo do seu jeito! Aqui, você pode personalizar as
                            configurações e <br /> preferências de acordo com
                            suas necessidades. Adapte a plataforma às <br />{' '}
                            suas estratégias únicas e tenha controle total de
                            suas operações.
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            marginTop:
                                window.innerWidth > 1600 ||
                                window.innerWidth < 1001
                                    ? '5%'
                                    : '8%'
                        }}
                    >
                        <div style={{width: '100%'}}>
                            <img
                                src={grade}
                                alt=""
                                style={{
                                    width: window.innerWidth > 1400 ? 865 : 615
                                }}
                            />
                        </div>
                        <div style={{width: '100%'}}>
                            <div>
                                <img
                                    src={personalizacao}
                                    alt=""
                                    style={{
                                        width:
                                            window.innerWidth > 1400
                                                ? 650
                                                : 460,
                                        marginLeft: '2.5%'
                                    }}
                                />
                            </div>
                            <div>
                                <img
                                    src={faixa}
                                    alt=""
                                    style={{
                                        width:
                                            window.innerWidth > 1400
                                                ? 305
                                                : 215,
                                        marginLeft: '2.5%',
                                        marginTop: '2.5%'
                                    }}
                                />
                                <img
                                    src={custom}
                                    alt=""
                                    style={{
                                        width:
                                            window.innerWidth > 1400
                                                ? 260
                                                : 185,
                                        marginLeft: '8%',
                                        marginTop: '2.5%'
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        top:
                            window.innerWidth > 1600 || window.innerWidth < 1001
                                ? '49%'
                                : '50%',
                        color: '#fff',
                        marginLeft: '10%',
                        marginTop: '5%',
                        fontFamily: 'Roboto'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            marginLeft: '-10%',
                            marginTop: '1%',
                            fontWeight: 700
                        }}
                    >
                        <div
                            style={{
                                fontSize:
                                    window.innerWidth > 1600 ||
                                    window.innerWidth < 1001
                                        ? 50
                                        : 40
                            }}
                        >
                            RÁPIDO
                        </div>
                        <div
                            style={{
                                fontSize:
                                    window.innerWidth > 1600 ||
                                    window.innerWidth < 1001
                                        ? 40
                                        : 30,
                                width: '100%'
                            }}
                        >
                            Nossas ferramentas entregam informações digeridas,
                            agilizando e <br /> impulsionando suas operações e
                            permitindo que você aproveite cada <br /> momento no
                            mercado, maximizando seus resultados.
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        top:
                            window.innerWidth > 1600 || window.innerWidth < 1001
                                ? '52%'
                                : '53%',
                        color: '#fff',
                        marginTop: '5%',
                        fontFamily: 'Roboto'
                    }}
                >
                    <div>
                        <div
                            style={{
                                position: 'absolute',
                                left: '85%',
                                marginTop:
                                    window.innerWidth > 1600 ||
                                    window.innerWidth < 1001
                                        ? '0%'
                                        : '5%'
                            }}
                        >
                            <img
                                src={rapido}
                                alt=""
                                style={{
                                    width:
                                        window.innerWidth > 1600
                                            ? '100%'
                                            : '60%'
                                }}
                            />
                        </div>
                        <div
                            style={{
                                width: '100%',
                                marginLeft:
                                    window.innerWidth > 1600 ||
                                    window.innerWidth < 1001
                                        ? '0%'
                                        : '5%',
                                marginTop:
                                    window.innerWidth > 1600 ||
                                    window.innerWidth < 1001
                                        ? '0%'
                                        : '-5%'
                            }}
                        >
                            <video
                                style={{
                                    width:
                                        window.innerWidth > 1600
                                            ? '100%'
                                            : '90%',
                                    height: '600px'
                                }}
                                autoPlay
                                muted
                                loop
                                resizeMode="cover"
                            >
                                <source src={video} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const Section4 = ({setOpen}) => {
    return (
        <div
            style={{
                /* backgroundImage: `url(${background3cima})`,
                backgroundSize: 'cover', */
                height: '150vh',
                width: '100%'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top:
                            window.innerWidth > 1600 || window.innerWidth < 1001
                                ? '57.5%'
                                : '59%',
                        color: '#fff',
                        fontFamily: 'Roboto'
                    }}
                >
                    <img
                        src={background3cima}
                        alt=""
                        style={{width: window.innerWidth}}
                    />
                    <div style={{color: '#d36233', textAlign: 'center'}}>
                        <div style={{fontSize: 70}}>AQUI VOCÊ TEM</div>
                        <div style={{fontSize: 70}}>
                            CLAREZA NOS INVESTIMENTOS!
                        </div>
                        <div style={{fontSize: 40, color: '#313031'}}>
                            Com a OPTI você tem tudo que precisa de forma clara
                            e objetiva.
                            <br /> Assim fica fácil tomar decisões e garantir
                            seus lucros!
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        top:
                            window.innerWidth > 1600 || window.innerWidth < 1001
                                ? '59.5%'
                                : '62%',
                        color: '#fff',
                        fontFamily: 'Roboto'
                    }}
                >
                    <div style={{width: '100%', textAlign: 'center'}}>
                        <img
                            src={graficos}
                            alt=""
                            style={{
                                width:
                                    window.innerWidth > 1600 ||
                                    window.innerWidth < 1001
                                        ? 1115
                                        : 628
                            }}
                        />
                    </div>
                    <div
                        style={{
                            width: '100%',
                            marginTop: '-12%',
                            textAlign: 'center'
                        }}
                    >
                        <img
                            src={buttonLaranja}
                            alt=""
                            style={{
                                width: window.innerWidth > 1400 ? 580 : 410,
                                cursor: 'pointer'
                            }}
                            className="compra"
                            onClick={setOpen}
                        />
                    </div>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        top:
                            window.innerWidth > 1600 || window.innerWidth < 1001
                                ? '69.4%'
                                : '70%',
                        color: '#fff',
                        fontFamily: 'Roboto'
                    }}
                >
                    <img
                        src={background3baixo}
                        alt=""
                        style={{width: window.innerWidth}}
                    />
                </div>
            </div>
        </div>
    );
};

export const Section5 = () => {
    return (
        <div
            style={{
                backgroundColor: '#313031',
                height: '100vh',
                width: '100%'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top:
                            window.innerWidth > 1600 || window.innerWidth < 1001
                                ? '70.5%'
                                : '71%',
                        color: '#fff',
                        fontFamily: 'Roboto'
                    }}
                >
                    <div style={{textAlign: 'center'}}>
                        <img
                            src={funcionalidades}
                            alt=""
                            style={{
                                width: window.innerWidth > 1400 ? 1385 : 985
                            }}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '150%'
                        }}
                    >
                        <div
                            style={{
                                marginTop:
                                    window.innerWidth > 1600 ||
                                    window.innerWidth < 1001
                                        ? '20px'
                                        : '0px',
                                fontSize:
                                    window.innerWidth > 1600 ||
                                    window.innerWidth < 1001
                                        ? '30px'
                                        : '25px',
                                marginLeft: '10%',
                                width: '100%'
                            }}
                        >
                            <div>
                                <span>
                                    <img
                                        src={check}
                                        alt=""
                                        style={{
                                            width:
                                                window.innerWidth > 1400
                                                    ? 45
                                                    : 28
                                        }}
                                    />
                                </span>
                                <span>Layout customizável</span>
                            </div>
                            <div>
                                <span>
                                    <img
                                        src={check}
                                        alt=""
                                        style={{
                                            width:
                                                window.innerWidth > 1400
                                                    ? 45
                                                    : 28
                                        }}
                                    />
                                </span>
                                <span>Filtros por ativos</span>
                            </div>
                            <div>
                                <span>
                                    <img
                                        src={check}
                                        alt=""
                                        style={{
                                            width:
                                                window.innerWidth > 1400
                                                    ? 45
                                                    : 28
                                        }}
                                    />
                                </span>
                                <span>Carteira de opções</span>
                            </div>
                            <div>
                                <span>
                                    <img
                                        src={check}
                                        alt=""
                                        style={{
                                            width:
                                                window.innerWidth > 1400
                                                    ? 45
                                                    : 28
                                        }}
                                    />
                                </span>
                                <span>Grade de opções</span>
                            </div>
                            <div>
                                <span>
                                    <img
                                        src={check}
                                        alt=""
                                        style={{
                                            width:
                                                window.innerWidth > 1400
                                                    ? 45
                                                    : 28
                                        }}
                                    />
                                </span>
                                <span>Mapa de opções</span>
                            </div>
                            <div>
                                <span>
                                    <img
                                        src={check}
                                        alt=""
                                        style={{
                                            width:
                                                window.innerWidth > 1400
                                                    ? 45
                                                    : 28
                                        }}
                                    />
                                </span>
                                <span>Calculadora BLACK & SCHOLES</span>
                            </div>
                            <div>
                                <span>
                                    <img
                                        src={check}
                                        alt=""
                                        style={{
                                            width:
                                                window.innerWidth > 1400
                                                    ? 45
                                                    : 28
                                        }}
                                    />
                                </span>
                                <span
                                    style={{
                                        display: 'inline-flex',
                                        width:
                                            window.innerWidth > 1600
                                                ? '70%'
                                                : '95%'
                                    }}
                                >
                                    ROEX - Ferramenta exclusiva OPTI, facilita
                                    rolagens e remontagens de estruturas
                                </span>
                            </div>
                            <div>
                                <span>
                                    <img
                                        src={check}
                                        alt=""
                                        style={{
                                            width:
                                                window.innerWidth > 1400
                                                    ? 45
                                                    : 28
                                        }}
                                    />
                                </span>
                                <span
                                    style={{
                                        display: 'inline-flex',
                                        width:
                                            window.innerWidth > 1600
                                                ? '80%'
                                                : '95%'
                                    }}
                                >
                                    Grade EPM - Ferramenta exclusiva OPTI,
                                    facilita a visualização geral das opções nos
                                    vencimentos
                                </span>
                            </div>
                        </div>
                        <div>
                            <img
                                src={computador}
                                alt=""
                                style={{width: '30%'}}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const Section6 = ({setOpen}) => {
    return (
        <div
            style={{
                /* backgroundImage: `url(${background3})`,
                backgroundSize: 'cover', */
                height: '100vh',
                width: '100%'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top:
                            window.innerWidth > 1600 || window.innerWidth < 1001
                                ? '76.5%'
                                : '77.25%',
                        fontFamily: 'Roboto',
                        textAlign: 'center'
                    }}
                >
                    <img src={background3cima} alt="" style={{width: '100%'}} />
                    <div
                        style={{
                            color: '#d36233',
                            fontSize:
                                window.innerWidth > 1600 ||
                                window.innerWidth < 1001
                                    ? 64
                                    : 44,
                            fontWeight: 700,
                            marginTop:
                                window.innerWidth > 1600 ||
                                window.innerWidth < 1001
                                    ? '0px'
                                    : '-20px'
                        }}
                    >
                        A OPTI É IDEAL PARA VOCÊ:
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around',
                            margin:
                                window.innerWidth > 1600
                                    ? '40px 20px 20px 20px'
                                    : '5px 20px 20px 20px'
                        }}
                    >
                        <Card
                            style={{
                                backgroundColor: '#f4f5f7',
                                width: '20%',
                                height:
                                    window.innerWidth > 1600
                                        ? '400px'
                                        : '300px',
                                borderRadius: '20%'
                            }}
                        >
                            <div style={{margin: '20px'}}>
                                <img
                                    style={{
                                        width:
                                            window.innerWidth > 1400 ? 220 : 145
                                    }}
                                    src={wallet1}
                                    alt=""
                                />
                            </div>
                            <div
                                style={{
                                    fontSize:
                                        window.innerWidth > 1600 ||
                                        window.innerWidth < 1001
                                            ? 30
                                            : 28,
                                    color: '#d36233',
                                    margin: '0px 20px 0px 20px'
                                }}
                            >
                                PROTEGER SUA CARTEIRA DE INVESTIMENTOS
                            </div>
                        </Card>
                        <Card
                            style={{
                                backgroundColor: '#f4f5f7',
                                width: '20%',
                                height:
                                    window.innerWidth > 1600
                                        ? '400px'
                                        : '300px',
                                borderRadius: '20%'
                            }}
                        >
                            <div style={{margin: '20px'}}>
                                <img
                                    style={{
                                        width:
                                            window.innerWidth > 1400 ? 220 : 145
                                    }}
                                    src={charts}
                                    alt=""
                                />
                            </div>
                            <div
                                style={{
                                    fontSize: 30,
                                    color: '#d36233',
                                    margin: '0px 20px 0px 20px'
                                }}
                            >
                                LUCRAR COM <br /> OPÇÕES
                            </div>
                        </Card>
                        <Card
                            style={{
                                backgroundColor: '#f4f5f7',
                                width: '20%',
                                height:
                                    window.innerWidth > 1600
                                        ? '400px'
                                        : '300px',
                                borderRadius: '20%'
                            }}
                        >
                            <div style={{margin: '20px'}}>
                                <img
                                    style={{
                                        width:
                                            window.innerWidth > 1400 ? 220 : 145
                                    }}
                                    src={pensador}
                                    alt=""
                                />
                            </div>
                            <div
                                style={{
                                    fontSize: 30,
                                    color: '#d36233',
                                    margin: '0px 20px 0px 20px'
                                }}
                            >
                                ESPECULAR NO
                                <br /> MERCADO
                            </div>
                        </Card>
                        <Card
                            style={{
                                backgroundColor: '#f4f5f7',
                                width: '20%',
                                height:
                                    window.innerWidth > 1600
                                        ? '400px'
                                        : '300px',
                                borderRadius: '20%'
                            }}
                        >
                            <div style={{margin: '20px'}}>
                                <img
                                    style={{
                                        width:
                                            window.innerWidth > 1400 ? 220 : 145
                                    }}
                                    src={wallet2}
                                    alt=""
                                />
                            </div>
                            <div
                                style={{
                                    fontSize:
                                        window.innerWidth > 1600 ||
                                        window.innerWidth < 1001
                                            ? 30
                                            : 22.5,
                                    color: '#d36233',
                                    margin: '0px 20px 0px 20px'
                                }}
                            >
                                AUMENTAR O RENDIMENTO DA SUA CARTEIRA DE AÇÕES
                            </div>
                        </Card>
                    </div>
                    <div
                        style={{
                            marginTop:
                                window.innerWidth > 1600 ||
                                window.innerWidth < 1001
                                    ? '5rem'
                                    : '0rem'
                        }}
                    >
                        <img
                            src={quero}
                            alt=""
                            style={{
                                width: window.innerWidth > 1400 ? 580 : 410,
                                zIndex: 9999,
                                cursor: 'pointer'
                            }}
                            className="compra"
                            onClick={setOpen}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export const Section7 = ({setOpen}) => {
    return (
        <div
            style={{
                /*  backgroundImage: `url(${background2})`,
                backgroundSize: 'cover', */
                backgroundColor: '#313031',
                height: '200vh',
                width: '100%'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '83%',
                        fontFamily: 'Roboto',
                        textAlign: 'center'
                    }}
                >
                    <img
                        src={sobre2}
                        alt=""
                        style={{
                            // top: '-15%',
                            // transform: 'rotate(180deg)',
                            width: '100vw',
                            height: '60vh'
                        }}
                    />
                </div>
                <div
                    style={{
                        position: 'absolute',
                        top: '84.5%',
                        fontFamily: 'Roboto',
                        textAlign: 'center'
                    }}
                >
                    <div
                        style={{
                            zIndex: 9999,
                            color: '#fff',
                            fontSize: 60,
                            fontWeight: 700
                        }}
                    >
                        OPTI - OPÇÕES SIMPLIFICADAS PARA VOCÊ
                    </div>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        top:
                            window.innerWidth > 1600 || window.innerWidth < 1001
                                ? '87.5%'
                                : '87.5%',
                        fontFamily: 'Roboto',
                        textAlign: 'center'
                    }}
                >
                    <div>
                        <img
                            src={painel}
                            alt=""
                            style={{
                                width:
                                    window.innerWidth > 1600 ||
                                    window.innerWidth < 1001
                                        ? 1635
                                        : 1215
                            }}
                        />
                    </div>
                </div>
                <div
                    style={{
                        position: 'absolute',
                        top:
                            window.innerWidth > 1600 || window.innerWidth < 1001
                                ? '88%'
                                : '87.85%',
                        fontFamily: 'Roboto',
                        color: '#fff',
                        fontSize: 20
                    }}
                >
                    <div
                        style={{
                            textAlign: 'center'
                        }}
                    >
                        <img
                            src={iconeClaro}
                            alt=""
                            style={{
                                width: window.innerWidth > 1400 ? 290 : 205
                            }}
                        />
                    </div>
                    <div
                        style={{
                            color: '#fff',
                            fontSize:
                                window.innerWidth > 1600 ||
                                window.innerWidth < 1001
                                    ? 60
                                    : 50,
                            margin: '5px',
                            fontWeight: 700,
                            textAlign: 'center'
                        }}
                    >
                        EXPERIMENTE GRÁTIS POR 30 DIAS
                    </div>
                    <div
                        style={{
                            margin:
                                window.innerWidth > 1600 ||
                                window.innerWidth < 1001
                                    ? '5px'
                                    : '5px',
                            textAlign: 'center'
                        }}
                    >
                        <img
                            src={stars}
                            alt=""
                            style={{
                                width: window.innerWidth > 1400 ? 285 : 200
                            }}
                        />
                    </div>
                    <div
                        style={{
                            color: '#fff',
                            fontSize:
                                window.innerWidth > 1600 ||
                                window.innerWidth < 1001
                                    ? 30
                                    : 26,
                            margin:
                                window.innerWidth > 1600 ||
                                window.innerWidth < 1001
                                    ? '20px'
                                    : '5px',
                            textAlign: 'center'
                        }}
                    >
                        Tenha acesso a todos os benefícios da OPTI e <br />{' '}
                        comece a lucrar muito mais em suas operações!
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginLeft: '38%',
                            flexDirection: 'column',
                            fontSize:
                                window.innerWidth > 1600 ||
                                window.innerWidth < 1001
                                    ? 30
                                    : 26
                        }}
                    >
                        <div style={{margin: '10px'}}>
                            <span>
                                <img src={seta} alt="" style={{width: '5%'}} />
                            </span>
                            <span>TELAS PERSONALIZÁVEIS</span>
                        </div>
                        <div style={{margin: '10px'}}>
                            <span>
                                <img src={seta} alt="" style={{width: '5%'}} />
                            </span>
                            <span>ATIVOS FAVORITOS ILIMITADOS</span>
                        </div>
                        <div style={{margin: '10px'}}>
                            <span>
                                <img src={seta} alt="" style={{width: '5%'}} />
                            </span>
                            <span>CARTEIRA E ESTRUTURA ILIMITADAS</span>
                        </div>
                        <div style={{margin: '10px'}}>
                            <span>
                                <img src={seta} alt="" style={{width: '5%'}} />
                            </span>
                            <span>GRADE E MAPA DE OPÇÕES</span>
                        </div>
                        <div style={{margin: '10px'}}>
                            <span>
                                <img src={seta} alt="" style={{width: '5%'}} />
                            </span>
                            <span
                                style={{
                                    display: 'inline-flex',
                                    width:
                                        window.innerWidth > 1600 ||
                                        window.innerWidth < 1001
                                            ? '60%'
                                            : '55%'
                                }}
                            >
                                CALCULADORA BLACK & SCHOLES SEM SAIR DA PAGINA
                            </span>
                        </div>
                        <div style={{margin: '10px'}}>
                            <span>
                                <img src={seta} alt="" style={{width: '5%'}} />
                            </span>
                            <span
                                style={{
                                    display: 'inline-flex',
                                    width:
                                        window.innerWidth > 1600 ||
                                        window.innerWidth < 1001
                                            ? '60%'
                                            : '60%'
                                }}
                            >
                                FERRAMENTAS EXCLUSIVAS: ROEX E GRADE EPM
                            </span>
                        </div>
                    </div>
                    <div
                        style={{
                            textAlign: 'center',
                            marginTop:
                                window.innerWidth > 1600 ||
                                window.innerWidth < 1001
                                    ? '5%'
                                    : '0px'
                        }}
                    >
                        <img
                            style={{
                                width: window.innerWidth > 1400 ? 575 : 410,
                                cursor: 'pointer'
                            }}
                            src={teste}
                            alt=""
                            className="compra"
                            onClick={setOpen}
                        />
                        <div
                            style={{
                                color: '#fff',
                                fontFamily: 'Text me one',
                                fontSize:
                                    window.innerWidth > 1600 ||
                                    window.innerWidth < 1001
                                        ? 40
                                        : 26
                            }}
                        >
                            APÓS PERÍODO DE TESTE: R$69,90/ ANO
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
