import React from 'react';
import {cleanNumber} from '@app/utils/myaux';

export default function Roi({card, lucro}) {
    const calcAloc = (strategy) => {
        let totMont = 0;
        strategy.forEach((s) => {
            if (s.type === 'V') {
                totMont += cleanNumber(s.preco_mont) * cleanNumber(s.qtd) * -1;
            } else {
                totMont += cleanNumber(s.preco_mont) * cleanNumber(s.qtd);
            }
        });
        return totMont;
    };

    const calcRoi = (strategy) => {
        let capital = calcAloc(strategy);
        if (capital < 0) {
            capital *= -1;
        }
        // const lucro = calcLucro(calcAloc(endCard), calcDesmont(endCard));
        let tmp = 0;
        tmp = (lucro * 0.85) / capital;
        if (Number.isNaN(tmp)) {
            return 0;
        }
        return tmp;
    };

    if (calcRoi) {
        if (1 > 0) {
            return (
                (
                    <span
                        style={{
                            color: '#e37039',
                            paddingRight: 5,
                            paddingLeft: 2
                        }}
                    >
                        {/* + */}
                    </span>
                ),
                new Intl.NumberFormat('pt-BR', {
                    style: 'percent',
                    minimumFractionDigits: 2,
                    currency: 'BRL'
                }).format(calcRoi(card))
            );
        }
        return (
            (
                <span
                    style={{
                        color: '#e37039',
                        paddingRight: 5,
                        paddingLeft: 2
                    }}
                >
                    -
                </span>
            ),
            new Intl.NumberFormat('pt-BR', {
                style: 'percent',
                minimumFractionDigits: 2,
                currency: 'BRL'
            }).format(calcRoi(card))
        );
    }
}
