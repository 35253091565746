/* eslint-disable no-plusplus */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable no-lonely-if */

import moment from 'moment';
import {cleanNumber} from '@app/utils/myaux';
import _, {indexOf} from 'lodash';

const normCDF = (x) => {
    const t = 1 / (1 + 0.2316419 * Math.abs(x));
    const d = 0.3989423 * Math.exp((-x * x) / 2);

    let cdf =
        d *
        t *
        (0.3193815 +
            t * (-0.3565638 + t * (1.781478 + t * (-1.821256 + t * 1.330274))));

    if (x > 0) {
        cdf = 1 - cdf;
    }

    return cdf;
};

const d1Calc = (S, K, r, vol, T, t) => {
    return (
        (Math.log(S / K) + (r + 0.5 * vol ** 2) * (T - t)) /
        (vol * Math.sqrt(T - t))
    );
};

const d1CalcTeorico = (S, K, r, vol, T, t) => {
    return (
        (Math.log(S / K) + (r + 0.5 * vol ** 2) * (T - t)) /
        (vol * Math.sqrt(T - t))
    );
};

const BSCallTeorico = (S, K, r, vol, T, t) => {
    const d1 = d1CalcTeorico(S, K, r, vol, T, t);
    const d2 = d1 - vol * Math.sqrt(T);
    return S * normCDF(d1) - K * Math.exp(-r * T) * normCDF(d2);
};

const BSPutTeorico = (S, K, r, vol, T, t) => {
    return BSCallTeorico(S, K, r, vol, T, t) - S + Math.exp(-r * (T - t)) * K;
};

const BSCall = (S, K, r, vol, T, t) => {
    const d1 = d1Calc(S, K, r, vol, T, t);
    const d2 = d1 - vol * Math.sqrt(T);
    return S * normCDF(d1) - K * Math.exp(-r * T) * normCDF(d2);
};

const BSPut = (S, K, r, vol, T, t) => {
    return BSCall(S, K, r, vol, T, t) - S + Math.exp(-r * (T - t)) * K;
};

export const getChart = (
    array,
    index,
    cotacao,
    days,
    position,
    v,
    tickers,
    pattern,
    diffVenc,
    selic
) => {
    let soma = 0;
    let somaPayOff = 0;
    let result = 0;
    let resultTheorical = 0;
    if (array.activeType === 'C') {
        soma =
            Math.max(0, cotacao - cleanNumber(array.strike.value)) -
            cleanNumber(array.cotacao);
        somaPayOff =
            BSCallTeorico(
                cotacao,
                cleanNumber(array.strike.value),
                selic,
                tickers[index] <= 0.01
                    ? cleanNumber(array.vol)
                    : tickers[index],
                days[index],
                0
            ) - cleanNumber(array.cotacao);
    } else {
        soma =
            Math.max(0, cleanNumber(array.strike.value) - cotacao) -
            cleanNumber(array.cotacao);
        somaPayOff =
            BSPutTeorico(
                cotacao,
                cleanNumber(array.strike.value),
                selic,
                tickers[index] <= 0.01
                    ? cleanNumber(array.vol)
                    : tickers[index],
                days[index],
                0
            ) - cleanNumber(array.cotacao);
    }
    if (array.type === 'C') {
        if (!v && diffVenc) {
            result =
                cleanNumber(
                    array.activeType === 'C'
                        ? BSCall(
                              cotacao,
                              cleanNumber(array.strike.value),
                              selic,
                              tickers[index] === 0
                                  ? cleanNumber(array.vol)
                                  : tickers[index],
                              index === days.length - 1
                                  ? days[index] - days[index - 1]
                                  : days[index] - days[index + 1],
                              0
                          ) - cleanNumber(array.cotacao)
                        : BSPut(
                              cotacao,
                              cleanNumber(array.strike.value),
                              selic,
                              tickers[index] === 0
                                  ? cleanNumber(array.vol)
                                  : tickers[index],
                              index === days.length - 1
                                  ? days[index] - days[index - 1]
                                  : days[index] - days[index + 1],
                              0
                          ) - cleanNumber(array.cotacao)
                ) * cleanNumber(array.qtd);
        } else {
            result = cleanNumber(soma) * cleanNumber(array.qtd);
        }
        resultTheorical = cleanNumber(somaPayOff) * cleanNumber(array.qtd);
    } else if (array.type === 'V') {
        result = cleanNumber(soma) * cleanNumber(array.qtd) * -1;
        resultTheorical = cleanNumber(somaPayOff) * cleanNumber(array.qtd) * -1;
    } else {
        result = 0;
        result = 0;
    }
    return [result, resultTheorical];
};

export const getChartAtivo = (
    strategy,
    cotacao,
    days,
    tickers,
    diffVenc,
    indexOfAtivo,
    selic
) => {
    let soma = 0;
    let somaPayOff = 0;
    let result = 0;
    let resultTheorical = 0;
    strategy.forEach((array, index) => {
        if (array.activeType === 'C' /* Call */) {
            soma =
                Math.max(0, cotacao - cleanNumber(array?.strike?.value)) -
                cleanNumber(array?.cotacao);
            somaPayOff =
                BSCallTeorico(
                    cotacao,
                    cleanNumber(array?.strike?.value),
                    selic,
                    tickers[index],
                    days[index],
                    0
                ) - cleanNumber(array?.cotacao);
        } else if (array.activeType === 'P' /* Put */) {
            soma =
                Math.max(0, cleanNumber(array?.strike?.value) - cotacao) -
                cleanNumber(array?.cotacao);
            somaPayOff =
                BSPutTeorico(
                    cotacao,
                    cleanNumber(array?.strike?.value),
                    selic,
                    tickers[index],
                    days[index],
                    0
                ) - cleanNumber(array?.cotacao);
        } else {
            if (indexOfAtivo === index) {
                soma = cotacao - cleanNumber(array.cotacao);
                somaPayOff = cotacao - cleanNumber(array.cotacao);
            }
        }
        if (array.type === 'C') {
            result += cleanNumber(soma) * cleanNumber(array.qtd);
            resultTheorical += cleanNumber(somaPayOff) * cleanNumber(array.qtd);
        } else if (array.type === 'V') {
            result += cleanNumber(soma) * cleanNumber(array.qtd) * -1;
            resultTheorical +=
                cleanNumber(somaPayOff) * cleanNumber(array.qtd) * -1;
        }
    });
    return [result, resultTheorical];
};
