/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
// import {Link} from 'react-scroll';
import {useHistory} from 'react-router-dom';
// import Button from '@material-ui/core/Button';
// import {makeStyles, createStyles} from '@material-ui/core/styles';
import routing from '../routes/routing';
// import seta from '../img/arrowHome.gif';

export default function Termo() {
    const history = useHistory();
    const handleTermos = () => {
        window.location.reload();
    };
    /* const useStyles = makeStyles(() =>
        createStyles({
            setaDown: {
                margin: 'auto',
                display: 'flex',
                cursor: 'pointer',
                width: '60px',
                transform: 'rotate(180deg)'
            }
        })
    ); */
    // const classes = useStyles();
    return (
        <div>
            <div style={{display: 'flex'}}>
                <div>
                    <p>
                        Estes termos e condições gerais de uso aplicam-se aos
                        serviços prestados pela pessoa jurídica{' '}
                        <span style={{fontWeight: 'bold'}}>
                            Opti Investimentos Ltda
                        </span>
                        , até a presente data, gerenciada em todos os âmbitos
                        legais por JTO LTDA, esta, devidamente registrada sob o
                        CNPJ n. 46.022.140/0001-61, com sede na Av Shishima
                        Hifumi, no 2911 – Parque Tecnológico Univap - Urbanova –
                        CEP 12244-000, São José dos Campos - SP, por meio do
                        site useopti.com.br , com o seguinte endereço:{' '}
                        <span
                            style={{
                                fontWeight: 'bold',
                                cursor: 'pointer',
                                textDecoration: 'underline'
                            }}
                            onClick={() => history.push(routing.home)}
                        >
                            https://useopti.com.br/
                        </span>
                    </p>
                    <p style={{fontWeight: 'bold'}}>1. DO OBJETO</p>
                    <p>
                        O site{' '}
                        <span style={{fontWeight: 'bold'}}>useopti.com.br</span>{' '}
                        caracteriza-se pela disponibilização de ferramentas para
                        pesquisa de opções negociadas na B3, simulações de
                        estratégias de investimentos utilizando ações e opções,
                        geração de gráficos, tabelas, estudos e diversas outras
                        informações relevantes para montagem e calibração das
                        estratégias, compartilhamento de estratégias, interação
                        entre os usuários cadastrados e divulgação de conteúdos
                        relevantes sobre mercado e investimentos.s
                    </p>
                    <p style={{fontWeight: 'bold'}}>
                        2. DA ACEITAÇÃO DOS TERMOS E CONDIÇÕES GERAIS DE USO
                    </p>
                    <p>
                        Todos aqueles que desejarem ter acesso às informações e
                        serviços fornecidos através do site useopti.com.br
                        deverão, primeiramente, se informar sobre as regras que
                        compõem o presente instrumento, as quais ficarão
                        disponíveis para pronta e ampla consulta, em{' '}
                        <span
                            onClick={handleTermos}
                            style={{
                                color: '#d36233',
                                textDecorationLine: 'underline',
                                cursor: 'pointer'
                            }}
                        >
                            www.useopti.com.br/termos-de-uso-opti.
                        </span>
                    </p>
                    <p>
                        Ao utilizar o site{' '}
                        <span style={{fontWeight: 'bold'}}>useopti.com.br</span>
                        , o usuário aceita integralmente as presentes normas e
                        compromete-se a observá-las, sob risco de aplicação das
                        penalidades cabíveis. Antes de iniciar qualquer
                        navegação no site, o usuário deverá cientificar-se de
                        eventuais modificações ou atualizações ocorridas nestes
                        termos.
                    </p>
                    <p>
                        A{' '}
                        <span style={{fontWeight: 'bold'}}>
                            Opti Investimentos
                        </span>{' '}
                        poderá alterar periodicamente os termos de uso. Quando
                        essas alterações forem efetuadas a versão atualizada
                        ficará disponível no mesmo endereço indicado acima.
                    </p>
                    <p>
                        Caso não concorde com quaisquer das regras aqui
                        descritas, o usuário deve, imediatamente, abster-se de
                        utilizar o serviço. Se for de seu interesse, poderá,
                        ainda, entrar em contato com o serviço de atendimento ao
                        cliente, para apresentar-lhe as suas ressalvas.
                    </p>
                    <p style={{fontWeight: 'bold'}}>3. DA NAVEGAÇÃO</p>
                    <p>
                        A equipe do site{' '}
                        <span style={{fontWeight: 'bold'}}>useopti.com.br</span>{' '}
                        se compromete a utilizar todas as soluções técnicas à
                        sua disposição para permitir o acesso ao serviço 24
                        (vinte e quatro) horas por dia, 7 (sete) dias por
                        semana. Entretanto, ela poderá, a qualquer momento,
                        interromper, limitar ou suspender o acesso ao site ou a
                        algumas de suas páginas, a fim de realizar atualizações,
                        modificações de conteúdo ou qualquer outra ação julgada
                        necessária para o seu bom funcionamento.
                    </p>
                    <p>
                        Os presentes termos e condições gerais de uso se aplicam
                        a todas as extensões do site useopti.com.br em redes
                        sociais ou em comunidades, tanto as já existentes,
                        quanto aquelas ainda a serem implementadas.
                    </p>
                    <p style={{fontWeight: 'bold'}}>4. DA GESTÃO DO SITE</p>
                    <p>
                        Para a boa gestão, a equipe do site{' '}
                        <span style={{fontWeight: 'bold'}}>useopti.com.br</span>
                        poderá, a qualquer momento:
                    </p>
                    <p>
                        {'a)'} suspender, interromper ou limitar o acesso de um
                        usuário ou uma categoria específica de usuários a todo
                        ou a parte do site;
                    </p>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'end',
                        marginRight: '-30px',
                        marginLeft: '20px'
                        // marginBottom: '20px'
                    }}
                >
                    {/* <Link activeClass="active" to="second" spy smooth>
                        <img
                            src={seta}
                            // style={{marginTop: '-350px', marginLeft: '60%'}}
                            alt=""
                            className={classes.setaDown}
                        />
                    </Link> */}
                </div>
            </div>
            <div name="second" style={{display: 'flex'}}>
                <div>
                    <p>
                        {'b)'} remover toda informação que possa perturbar o
                        funcionamento do site ou que esteja em conflito com
                        normas de Direito brasileiro ou de Direito
                        internacional;
                    </p>
                    <p>
                        {'c)'} moderar ou remover qualquer conteúdo, estratégia,
                        link ou comentário compartilhado pelo usuário conforme
                        critérios próprios e particulares;
                    </p>
                    <p>
                        {'d)'} desativar o site temporariamente a fim de
                        realizar atualizações e modificações.
                    </p>
                    <p style={{fontWeight: 'bold'}}>5. DAS RESPONSABILIDADES</p>
                    <p>
                        Todo conteúdo do site, seja ele pago ou gratuito, tem
                        finalidade meramente educativa. Nenhuma informação
                        fornecida ou simulação deverá ser interpretada como
                        recomendação de investimento. É de inteira
                        responsabilidade do usuário qualquer operação ou
                        investimento que ele venha a realizar. O usuário deve
                        estar ciente que investimentos nos mercados de ações
                        e/ou opções podem trazer prejuízos financeiros
                        significativos, até mesmo falência.
                    </p>
                    <p>
                        Nenhum prejuízo causado por eventuais falhas no site,
                        erros de cálculo ou qualquer outro problema poderão ser
                        imputados à{' '}
                        <span style={{fontWeight: 'bold'}}>
                            Opti Investimentos
                        </span>
                        . A responsabilidade de validar todos dados e
                        informações antes de efetuar qualquer operação é do
                        usuário.
                    </p>
                    <p>
                        Nossas informações sobre cotações são obtidas de fontes
                        que consideramos confiáveis mas podem estar
                        desatualizadas e/ou incompletas em função do nível de
                        acesso do usuário, podendo inclusive estarem incorretas
                        devido à falhas nos processos de transmissão e/ou
                        importação dos dados. Não oferecemos, portanto, garantia
                        com relação à corretude e atualização dos dados. É
                        responsabilidade do usuário validar se os preços e
                        demais informações fornecidas no site estão de acordo
                        com os dados negociados na B3 e o usuário deve, para
                        isso, utilizar sua própria plataforma de negociação.
                    </p>
                    <p>O site não será responsável também</p>
                    <p>
                        {'a)'} pelas características intrínsecas da internet,
                        principalmente relativas à confiabilidade, velocidade e
                        interrupções das informações circulando nesta rede;
                    </p>
                    <p>
                        {'b)'} pelos conteúdos ou atividades ilícitas praticadas
                        através de suas páginas. O usuário concorda em acessar
                        as informações e serviços disponibilizados pelo site
                        única e exclusivamente por meio de um navegador de
                        internet tradicional, sendo proibido qualquer tipo de
                        acesso automatizado para coleta de informações,
                        realização de simulações ou qualquer outro tipo de
                        interação com o site.
                    </p>
                    <p>O usuário é responsável, ainda:</p>
                    <p>
                        {'a)'} por manter a confidencialidade da senha associada
                        à sua conta que utiliza para acessar os serviços e com
                        isso concorda que é o único responsável por todas as
                        atividades que ocorram na respectiva conta;
                    </p>
                    <p>
                        {'b)'} por notificar imediatamente através do e-mail{' '}
                        <span
                            style={{fontWeight: 'bold'}}
                            onClick={() => {
                                window.location.href =
                                    'mailto:contato@useopti.com.br';
                            }}
                        >
                            contato@useopti.com.br
                        </span>{' '}
                        caso suspeite que seus dados de acesso foram violados ou
                        estão sendo utilizados por terceiros;
                    </p>
                    <p>
                        {'c)'} pela correta utilização do site e de seus
                        serviços, prezando pela boa convivência, pelo respeito e
                        pela cordialidade no relacionamento com os demais
                        usuários;
                    </p>
                    <p>
                        {'d)'} pelo cumprimento das regras contidas neste
                        instrumento, bem como normas de Direito nacional e de
                        Direito internacional.
                    </p>
                    <p style={{fontWeight: 'bold'}}>6. DOS LINKS EXTERNOS</p>
                    <p>
                        O site useopti.com.br pode conter links externos
                        direcionando o usuário para outras páginas da internet,
                        sobre as quais não exerce controle. Apesar das
                        verificações prévias e regulares realizadas, a equipe se
                        isenta de qualquer responsabilidade sobre o conteúdo
                        encontrado nestes sites e serviços.
                    </p>
                    <p>
                        Poderão ser incluídos links nas seções de perguntas e
                        comentários do site{' '}
                        <span style={{fontWeight: 'bold'}}>useopti.com.br</span>
                        , desde que não sirvam para fins comerciais ou
                        publicitários. Esta inclusão dependerá de autorização ou
                        poderá ser moderada pela equipe do{' '}
                        <span style={{fontWeight: 'bold'}}>useopti.com.br</span>
                        .
                    </p>
                    <p>
                        Não será autorizada a inclusão de comentários ou links
                        para páginas que divulguem quaisquer tipos de
                        informações ilícitas, violentas, polêmicas,
                        pornográficas, xenofóbicas, discriminatórias ou
                        ofensivas. Tais conteúdos serão removidos e o usuário
                        responsável pela inclusão sofrerá as punições cabíveis.
                    </p>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'end',
                        marginRight: '-30px',
                        marginLeft: '20px'
                        // marginBottom: '20px'
                    }}
                >
                    {/* <Link activeClass="active" to="third" spy smooth>
                        <img
                            src={seta}
                            // style={{marginTop: '-350px', marginLeft: '60%'}}
                            alt=""
                            className={classes.setaDown}
                        />
                    </Link> */}
                </div>
            </div>
            <div name="third" style={{display: 'flex'}}>
                <div>
                    <p>
                        A equipe se reserva o direito de retirar a qualquer
                        momento um link levando ao seu próprio serviço, se a
                        página de origem não estiver conforme a sua política
                        editorial.
                    </p>
                    <p style={{fontWeight: 'bold'}}>7. DOS DIREITOS AUTORAIS</p>
                    <p>
                        A estrutura do site useopti.com.br, bem como os textos,
                        os gráficos, as imagens, as fotografias, os sons, os
                        vídeos e as demais aplicações informáticas que o compõem
                        são de propriedade da{' '}
                        <span style={{fontWeight: 'bold'}}>
                            Opti Investimentos
                        </span>{' '}
                        e são protegidas pela legislação brasileira e
                        internacional referente à propriedade intelectual.
                    </p>
                    <p>
                        Além da estrutura essencial do site{' '}
                        <span style={{fontWeight: 'bold'}}>useopti.com.br</span>
                        , nele poderão existir links, textos, imagens e vídeos
                        de outros sites sendo que nesses casos haverá um link
                        para o conteúdo original e os direitos autorais devem
                        ser verificados diretamente no site origem.
                    </p>
                    <p>
                        Qualquer representação, reprodução, adaptação ou
                        exploração parcial ou total dos conteúdos, marcas e
                        serviços propostos pelo site, por qualquer meio que
                        seja, sem autorização prévia, expressa e escrita da{' '}
                        <span style={{fontWeight: 'bold'}}>
                            Opti Investimentos
                        </span>
                        , é estritamente vedada, podendo-se recorrer às medidas
                        cíveis e penais cabíveis. Estão excluídos desta previsão
                        apenas os elementos que estejam expressamente designados
                        no site como livres de direitos autorais.
                    </p>
                    <p>
                        O acesso não gera para o usuário qualquer direito de
                        propriedade intelectual relativo a elementos do site, os
                        quais restam sob a propriedade exclusiva da{' '}
                        <span style={{fontWeight: 'bold'}}>
                            Opti Investimentos
                        </span>
                        .
                    </p>
                    <p>
                        É vedado ao usuário incluir no site dados que possam
                        modificar o seu conteúdo ou sua aparência, exceto quando
                        expressamente autorizado na página.
                    </p>
                    <p style={{fontWeight: 'bold'}}>
                        8. DO SERVIÇO DE ATENDIMENTO AO USUÁRIO
                    </p>
                    <p>
                        Em caso de dúvidas, sugestões ou problemas com a
                        utilização do site{' '}
                        <span style={{fontWeight: 'bold'}}>useopti.com.br</span>
                        , o usuário poderá contatar diretamente o seu serviço de
                        atendimento, através do endereço de e-mail{' '}
                        <span
                            style={{fontWeight: 'bold'}}
                            onClick={() => {
                                window.location.href =
                                    'mailto:contato@useopti.com.br';
                            }}
                        >
                            contato@useopti.com.br
                        </span>
                        . Estes serviços de atendimento ao usuário estarão
                        disponíveis de segunda a sexta, das 10h00 às 17h00. O
                        tempo de resposta poderá variar conforme o volume de
                        solicitações recebidas e caso o usuário utilize o site
                        gratuitamente não há garantia de prazo para atendimento.
                    </p>
                    <p style={{fontWeight: 'bold'}}>9. DAS SANÇÕES</p>
                    <p>
                        Sem prejuízo das demais medidas legais cabíveis, o
                        editor do site{' '}
                        <span style={{fontWeight: 'bold'}}>useopti.com.br</span>{' '}
                        poderá, a qualquer momento, advertir, suspender ou
                        impedir o acesso do usuário:
                    </p>
                    <p>
                        {'a)'} que descumprir quaisquer dos dispositivos
                        contidos no presente instrumento;
                    </p>
                    <p>{'b)'} que descumprir os seus deveres de usuário;</p>
                    <p>{'c)'} que praticar atos fraudulentos ou dolosos;</p>
                    <p>{'d)'} que fornecer qualquer informação incorreta;</p>
                    <p>
                        {'e)'} cujo comportamento constitua ou possa vir a
                        importar ofensa ou dano a terceiro ou ao próprio site.
                    </p>
                    <p style={{fontWeight: 'bold'}}>10. DAS ALTERAÇÕES</p>
                    <p>
                        A presente versão dos termos e condições gerais de uso
                        foi atualizada pela última vez em: 24//08/2023.
                    </p>
                    <p>
                        A{' '}
                        <span style={{fontWeight: 'bold'}}>
                            Opti Investimentos
                        </span>{' '}
                        se reserva o direito de modificar, a qualquer momento e
                        sem qualquer aviso prévio, o site e os serviços, bem
                        como as presentes normas, especialmente para adaptá-las
                        às evoluções do site{' '}
                        <span style={{fontWeight: 'bold'}}>useopti.com.br</span>
                        , seja pela disponibilização de novas funcionalidades,
                        seja pela supressão ou modificação daquelas já
                        existentes.
                    </p>
                    <p>
                        O usuário que utilizar o serviço, após realizadas
                        modificações nos termos e condições gerais, demonstra a
                        sua concordância com as novas normas. Caso discorde de
                        alguma das modificações, deverá interromper,
                        imediatamente, o acesso ao site e apresentar a sua
                        ressalva ao serviço de atendimento, se assim o desejar.
                    </p>
                    <p style={{fontWeight: 'bold'}}>
                        11. DO DIREITO APLICÁVEL E DO FORO
                    </p>
                    <p>
                        Para a solução das controvérsias decorrentes do presente
                        instrumento, será aplicado integralmente o Direito
                        brasileiro.
                    </p>
                    <p>
                        Os eventuais litígios deverão ser apresentados no foro
                        da comarca em que se encontra a sede da empresa
                        responsável pelo site
                    </p>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'end',
                        marginRight: '-30px',
                        marginLeft: '20px',
                        marginBottom: '60px'
                    }}
                >
                    {/* <Link activeClass="active" to="last" spy smooth>
                        <img
                            src={seta}
                            // style={{marginTop: '-350px', marginLeft: '60%'}}
                            alt=""
                            className={classes.setaDown}
                        />
                    </Link> */}
                </div>
            </div>
        </div>
    );
}
