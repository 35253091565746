/* eslint-disable no-unused-vars */

import React, {useEffect, useState} from 'react';
import {Card, Modal, Row, Col, Button} from 'react-bootstrap';
import {Skeleton} from '@material-ui/lab';

export default function EmptyCards() {
    return (
        <Card
            style={{
                width: '18rem',
                cursor: 'pointer',
                padding: 0,
                height: '12rem',
                marginBottom: '10px'
            }}
        >
            {/* titulo e roi */}
            <Card.Header
                variant="top"
                style={{
                    background: '#313031',
                    color: '#fff',
                    height: '52%',
                    paddingRight: 0,
                    paddingLeft: 0
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        marginLeft: '90%'
                    }}
                >
                    <ion-icon
                        style={{
                            fontSize: 18,
                            color: '#e37039',
                            cursor: 'pointer'
                        }}
                        name="lock-open-outline"
                    />
                </div>
                {/* cadeado */}
                {/* titulo */}
                <div style={{justifyContent: 'center', display: 'flex'}}>
                    {/* <Skeleton variant="text" sx={{fontSize: '16'}} /> */}
                    <Skeleton variant="rect" width={120} height={30} />
                </div>
                {/* titulo */}
                {/* circulo ROI */}
                <div
                    style={{
                        // background: '#cac6c7',
                        width: '80px',
                        height: '80px',
                        // borderRadius: '50%',
                        // color: '#000',
                        margin: 'auto',
                        // marginBottom: '10px',
                        textAlign: 'center'
                        // display: 'flex',
                    }}
                >
                    <Skeleton
                        sx={{backgroundColor: '#cac6c7'}}
                        variant="circle"
                        width={80}
                        height={80}
                    />
                </div>
                {/* circulo ROI */}
            </Card.Header>
            {/* titulo e roi */}
            {/* calculos a baixo */}
            <Card.Body
                style={{
                    maxHeight: '40px',
                    paddingRight: 0,
                    paddingLeft: 0
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        marginTop: '30px',
                        fontSize: '12px',
                        justifyContent: 'space-between',
                        padding: 0
                    }}
                >
                    <div
                        style={{
                            borderRight: '2px solid #c5c5c5',
                            textAlign: 'center'
                        }}
                    >
                        <div style={{margin: '2px 10px 2px 15px'}}>
                            <Skeleton variant="rect" width={50} height={15} />
                        </div>
                        <div style={{margin: '2px 10px 2px 5px'}}>
                            <Skeleton variant="rect" width={70} height={15} />
                        </div>
                    </div>
                    <div
                        style={{
                            borderRight: '2px solid #c5c5c5',
                            textAlign: 'center'
                        }}
                    >
                        <div style={{margin: '2px 10px 2px -20px'}}>
                            <Skeleton variant="rect" width={50} height={15} />
                        </div>
                        <div style={{margin: '2px 10px 2px -20px'}}>
                            <Skeleton variant="rect" width={50} height={15} />
                        </div>
                    </div>
                    <div
                        style={{
                            borderRight: '2px solid #c5c5c5',
                            textAlign: 'center'
                        }}
                    >
                        <div style={{margin: '2px 10px 2px -10px'}}>
                            <Skeleton variant="rect" width={20} height={15} />
                        </div>
                        <div style={{margin: '2px 10px 2px -20px'}}>
                            <Skeleton variant="rect" width={40} height={15} />
                        </div>
                    </div>
                    <div>
                        <div style={{margin: '2px 10px 2px -20px'}}>
                            <Skeleton variant="rect" width={40} height={15} />
                        </div>
                        <div style={{margin: '2px 10px 2px -20px'}}>
                            <Skeleton variant="rect" width={40} height={15} />
                        </div>
                    </div>
                </div>
            </Card.Body>
            {/* calculos a baixo */}
        </Card>
    );
}
