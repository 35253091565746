/* eslint-disable no-lonely-if */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import React, {useState, useEffect, useRef} from 'react';
import {Accordion, Card, Modal} from 'react-bootstrap';
import {useAccordionToggle} from 'react-bootstrap/AccordionToggle';
import {useLocation, Prompt, useHistory} from 'react-router-dom';
import Select from 'react-select';
import toast, {Toaster} from 'react-hot-toast';
import _, {filter, forEach, get, set} from 'lodash';
import moment from 'moment';
import api from '@app/utils/api';
import MobileDetecter from '@app/components/MobileDetecter';
import {Grid, Button} from '@material-ui/core';
import Calculadora from '@app/pages/Calculadora';
import WebFont from 'webfontloader';
import {createBrowserHistory} from 'history';
import Table from '../components/Table';
import AlertSave from '../components/AlertSave';
import Filters from '../components/Filters';
import Settings from '../components/SettingsTable';
import Loading from '../components/loading/Loading';
import ati from '../utils/ativos';
import 'bootstrap/js/src/collapse';

const Dashboard = ({setHeaderValues}) => {
    const [active, setActive] = useState();
    const [expand, setExpand] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [openSettings, setOpenSettings] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [sliderLabel, setSliderLabel] = useState('on');
    const [data, setData] = useState({});
    const [fechamento, setFechamento] = useState();
    const [dataTable, setDataTable] = useState([]);
    const [labels, setLabels] = useState([
        {name: 'PETR4'},
        {name: 'VALE3'},
        {name: 'BOVA11'}
    ]);
    const [userLabels, setUserLabels] = useState();
    const [numLabels, setNumLabels] = useState();
    const [userFilters, setUserFilter] = useState({});
    const [newUserLabels, setNewUserLabels] = useState();
    const [detectUnload, setDetectUnload] = useState(false);
    const [open, setOpen] = useState(false);
    const [preference, setPreference] = useState();
    const [ilimitado, setIlimitado] = useState(false);

    const location = useLocation();
    const user = api.currentUser;
    const ativos = ati;

    const defaultFilters = {
        btnType: {call: true, put: true},
        btnModel: {europe: true, america: true},
        btnMoneyness: {ITM: false, ATM: false, OTM: false},
        strike: {min: 0, start: 0, end: 5000, max: 5000},
        venc: []
    };

    const notify = (ticker) =>
        toast.success('Ticker copiado!', {
            iconTheme: {
                primary: '#d36233',
                secondary: '#fff'
            }
        }) && navigator.clipboard.writeText(ticker);

    const notifySave = () => {
        toast.success('Preferências Salvas!', {
            duration: 5000,
            position: 'top-center',
            iconTheme: {
                primary: '#d36233',
                secondary: '#fff'
            },
            style: {
                fontSize: '18px',
                minHeight: '60px',
                minWidth: '180px',
                color: '#fff',
                background: '#d36233'
            }
        });
    };

    const getHeaders = () => {
        setLoading(true);
        let volh = '';
        api.options
            .volTabela({ativo: active})
            .then((res) => {
                if (Number(Number(res.retorno.volH * 100).toFixed(2)) > 150.0)
                    volh = Number(res.retorno.volH * 10).toFixed(2);
                else volh = Number(res.retorno.volH * 100).toFixed(2);

                setHeaderValues({
                    ativo: active,
                    data: res.retorno.date,
                    valor: `R$${res.retorno.value}`,
                    desvio: '30%',
                    volT: volh,
                    call: Number(res.retorno.volIC * 100).toFixed(2),
                    put: Number(res.retorno.volIP * 100).toFixed(2),
                    min: `R$${res.retorno.min}`,
                    max: `R$${res.retorno.max}`,
                    fechD: res.retorno.fechD
                    // fechD: `${Number(res.retorno.fechD * 100).toFixed(2)}%`
                });
                setFechamento(res.retorno.value);
            })
            .catch((err) => {
                setLoading(false);
                console.log(err);
            });
    };

    function verifyDueDate(myFilter, dt, list) {
        myFilter.venc.forEach((ven) => {
            if (ven.status) {
                if (ven.label === dt.venc) {
                    list.push(dt);
                }
            }
        });
    }

    function verifyMoneyness(myFilter, dt, list) {
        // // ATM OTM ITM
        if (
            myFilter.btnMoneyness.ITM ||
            myFilter.btnMoneyness.ATM ||
            myFilter.btnMoneyness.OTM
        ) {
            if (myFilter.btnMoneyness.ATM && dt.money === 'ATM') {
                verifyDueDate(myFilter, dt, list);
            } else if (myFilter.btnMoneyness.ITM && dt.money === 'ITM') {
                verifyDueDate(myFilter, dt, list);
            } else if (myFilter.btnMoneyness.OTM && dt.money === 'OTM') {
                verifyDueDate(myFilter, dt, list);
            }
        }
    }

    function getFilteredData(myData, myFilter) {
        const list = [];
        myData.forEach((dt) => {
            // STRIKE
            if (
                myFilter.strike.start <= Number(dt.strike) &&
                myFilter.strike.end >= Number(dt.strike)
            ) {
                // AMERICANA OU EUROPÉIA
                if (myFilter.btnModel.europe && dt.mod === 'EUROPÉIA') {
                    // // PUT CALL
                    if (myFilter.btnType.call || myFilter.btnType.put) {
                        if (myFilter.btnType.call && dt.tipo === 'CALL') {
                            verifyMoneyness(myFilter, dt, list);
                        } else if (myFilter.btnType.put && dt.tipo === 'PUT') {
                            verifyMoneyness(myFilter, dt, list);
                        }
                    }
                } else if (
                    myFilter.btnModel.america &&
                    dt.mod === 'AMERICANA'
                ) {
                    // PUT CALL
                    if (myFilter.btnType.call || myFilter.btnType.put) {
                        if (myFilter.btnType.call && dt.tipo === 'CALL') {
                            verifyMoneyness(myFilter, dt, list);
                        } else if (myFilter.btnType.put && dt.tipo === 'PUT') {
                            verifyMoneyness(myFilter, dt, list);
                        }
                    }
                }
            }
        });
        return list;
    }

    function ContextAwareToggle({children, eventKey, callback}) {
        const decoratedOnClick = useAccordionToggle(
            eventKey,
            () => callback && callback(eventKey)
        );
        return (
            <button
                type="button"
                style={{backgroundColor: 'white', border: 'none'}}
                onClick={decoratedOnClick}
            >
                {children}
            </button>
        );
    }

    const getCotacao = () => {
        // console.log(' Willian - Buscar cotacacao');
        if (!data[active]) {
            setDataTable([]);
            // console.log(' Willian', active);
            api.options
                .getCotacoesPorAtivo(active)
                .then((res) => {
                    // console.log(' Willian cotacao', res);
                    const tmp = {
                        filters: userFilters[active]
                            ? userFilters[active]
                            : defaultFilters,
                        values: []
                    };
                    tmp.filters.strike.start = (
                        Number(fechamento) -
                        Number(fechamento) * (5 / 100)
                    ).toFixed(2);
                    tmp.filters.strike.end = (
                        Number(fechamento) +
                        Number(fechamento) * (5 / 100)
                    ).toFixed(2);
                    const listVenc = [];
                    const listMoneyness = [];
                    // const strikeFilters = [];
                    res.data.retorno.dados.forEach((resp, i) => {
                        // strikeFilters.push(parseFloat(resp.strike));
                        if (i === 0)
                            tmp.filters.strike.min = Number(resp.strike);
                        if (i + 1 === res.data.retorno.dados.length)
                            tmp.filters.strike.max = Number(resp.strike);
                        let vi;
                        let ve;
                        if (resp.tipo === 'PUT') {
                            if (Number(resp.strike) < Number(resp.cotacao)) {
                                vi = 0;
                                ve = Number(resp.pteorico);
                            } else {
                                vi = Number(resp.strike) - Number(resp.cotacao);
                                ve = Number(resp.pteorico) - Number(vi);
                            }
                        } else {
                            if (Number(resp.strike) > Number(resp.cotacao)) {
                                vi = 0;
                                ve = Number(resp.pteorico);
                            } else {
                                vi = Number(resp.cotacao) - Number(resp.strike);
                                ve =
                                    Number(resp.pteorico) +
                                    Number(resp.strike) -
                                    Number(resp.cotacao);
                            }
                        }
                        listVenc.push(resp.vencimentos);
                        if (
                            Number(resp.strike) >= tmp.filters.strike.start &&
                            Number(resp.strike) <= tmp.filters.strike.end
                        )
                            listMoneyness.push(resp.otm);
                        tmp.values.push({
                            ticker: resp.ticker.split('_')[0],
                            fm: resp.fm,
                            money: resp.otm,
                            mod: resp.mod === 'A' ? 'AMERICANA' : 'EUROPÉIA',
                            strike: resp.strike,
                            tipo: resp.tipo,
                            ultimo: resp.ultimo,
                            negocios: resp.num_neg,
                            vol: resp.vol_neg,
                            data: resp.data_hora,
                            implicita: (Number(resp.vol_impl) * 100).toFixed(2),
                            pteorico: resp.pteorico,
                            delta: resp.new_delta,
                            gamma: resp.new_gamma,
                            theta: resp.new_theta,
                            vega: resp.new_vega,
                            venc: resp.vencimentos,
                            cotacao: resp.cotacao,
                            name: active,
                            vi: vi.toFixed(2),
                            ve: ve.toFixed(2)
                        });
                    });
                    // strikeFilters.sort();
                    // console.log(strikeFilters);
                    const uniqueMoneyness = [...new Set(listMoneyness)];
                    const uniqueVenc = [...new Set(listVenc)];
                    uniqueMoneyness.forEach((m) => {
                        tmp.filters.btnMoneyness[m] = true;
                    });
                    uniqueVenc.sort();
                    if (tmp?.filters?.venc?.length < 1) {
                        uniqueVenc.forEach((u, i) => {
                            if (i === 0) {
                                tmp.filters.venc.push({label: u, status: true});
                            } else {
                                tmp.filters.venc.push({
                                    label: u,
                                    status: false
                                });
                            }
                        });
                    }
                    // console.log(tmp.filters.venc);
                    setData({...data, [active]: tmp});
                    setLoading(false);
                })
                .catch((err) => {
                    console.log(err);
                    setLoading(false);
                });
        } else {
            // console.log('Ja tenho. Não vou buscar...');
            const result = getFilteredData(
                data[active].values,
                data[active].filters
            );
            setDataTable(result);
            setLoading(false);
        }
    };

    const [tableLabel, setTableLabel] = useState({
        tipo: {
            value: 'TIPO',
            selected: true
        },
        mod: {
            value: 'MODALIDADE',
            selected: true
        },
        money: {
            value: 'MONEYNESS',
            selected: true
        },
        strike: {
            value: 'STRIKE',
            selected: true
        },
        intrinseco: {
            value: 'VI',
            selected: false
        },
        extriseco: {
            value: 'VE',
            selected: false
        },
        ultimo: {
            value: 'ÚLTIMO',
            selected: true
        },
        negocio: {
            value: 'NEGÓCIOS',
            selected: true
        },
        vol: {
            value: 'VOLUME',
            selected: false
        },
        data: {
            value: 'DATA',
            selected: false
        },
        implicita: {
            value: 'VOL. IMPLÍCITA',
            selected: false
        },
        ticker: {
            value: 'OPÇÃO',
            selected: true
        },
        pteorico: {
            value: 'P.TEÓRICO',
            selected: true
        },
        delta: {
            value: 'DELTA',
            selected: false
        },
        gamma: {
            value: 'GAMMA',
            selected: false
        },
        theta: {
            value: 'THETA',
            selected: false
        },
        vega: {
            value: 'VEGA',
            selected: false
        }
    });

    const savePreference = () => {
        if (api.currentUser && api.currentSession) {
            if (labels.length < 4 || ilimitado) {
                const list = [];
                labels.forEach((k) => {
                    if (data[k.name]) {
                        list.push({
                            name: k.name,
                            selected: {
                                tipo: data[k.name].filters.btnType,
                                mod: data[k.name].filters.btnModel,
                                moneyness: data[k.name].filters.btnMoneyness
                            }
                        });
                    } else {
                        const tmp = preference.find((p) => p.name === k.name);
                        console.log(tmp);
                        list.push({
                            name: k.name,
                            selected: {
                                tipo: tmp.selected.tipo,
                                mod: tmp.selected.mod,
                                moneyness: tmp.selected.moneyness
                            }
                        });
                    }
                });

                const userPreference = {
                    page: 'grid',
                    actives: list,
                    viewers: {
                        tipo: tableLabel.tipo,
                        mod: tableLabel.mod,
                        moneyness: tableLabel.money,
                        strike: tableLabel.strike,
                        vi: tableLabel.intrinseco,
                        ve: tableLabel.extriseco,
                        ultimo: tableLabel.ultimo,
                        negocios: tableLabel.negocio,
                        volume: tableLabel.vol,
                        data: tableLabel.data,
                        vol_imp: tableLabel.implicita,
                        delta: tableLabel.delta,
                        gamma: tableLabel.gamma,
                        theta: tableLabel.theta,
                        vega: tableLabel.vega,
                        p_teorico: tableLabel.pteorico
                    }
                };
                api.user
                    .createPreference({preference: userPreference})
                    .then((res) => {
                        setDetectUnload(false);
                        setOpen(false);
                        notifySave();
                        console.log(res);
                    })
                    .catch((err) => {
                        toast.error('Erro ao Salvar Preferências', {
                            duration: 6000,
                            position: 'top-center',
                            style: {
                                fontSize: '18px',
                                minHeight: '60px',
                                minWidth: '180px',
                                color: '#fff',
                                background: '#e84c3d'
                            }
                        });
                        console.log(err);
                    });
            } else {
                toast.error('Seu plano está limitado a três ativos salvos');
            }
        } else {
            toast.error('Você não está logado!');
        }
    };

    const tablePreference =
        api.currentSession && user.preference && user.preference.grid.viewers;

    const alterTab = (e) => {
        document.getElementById(e.target.id).classList.add('active');
        document.getElementById(active).classList.remove('active');
        setActive(e.target.id);
    };

    const collapse = () => {
        setExpand(!expand);
    };

    const deleteTab = (i, name) => {
        const origLabels = _.cloneDeep(labels);
        if (labels.length > 1) {
            const idx = origLabels.indexOf(
                origLabels.find((e) => e.name === name)
            );
            origLabels.splice(idx, 1);
            ativos.push({label: name, value: name});
            setLabels(origLabels);
            setActive(origLabels[0].name);
            setDetectUnload(true);
        } else {
            console.log('Não deixa excluir');
        }
    };

    const selectAtiv = (e) => {
        if (labels.find((el) => el.name === e)) {
            console.log('nada a fazer');
        } else {
            const search = e.value || e;
            const found = ativos.indexOf(
                ativos.find((el) => el.value === search)
            );
            ativos.splice(found, 1);
            setLabels((prev) => [...prev, {name: search}]);
            // console.log('data: ', labels.map(0, 1, 2, 3));
            setActive(search);
            setDetectUnload(true);
            setOpenModal(false);
        }
    };

    useEffect(() => {
        WebFont.load({
            google: {
                families: ['Roboto', 'Open Sans', 'Rubik']
            }
        });
        if (user) {
            setPreference(
                api.currentSession &&
                    user.preference &&
                    user.preference.grid.actives
            );
            if (
                user?.assinatura?.vencimento &&
                moment() <= moment(user?.assinatura?.vencimento)
            ) {
                setIlimitado(true);
            }
        } else {
            setActive('PETR4');
        }
    }, []);

    useEffect(() => {
        // console.log(preference);
        function getPreference(item, indice, array) {
            item = preference[indice];
            array[indice] = item;
        }
        if (preference) {
            preference.forEach(getPreference);
            const listFilters = {};
            const listName = [];
            preference.forEach((p) => {
                listName.push(p.name);
                listFilters[p.name] = {
                    btnType: {
                        call: p.selected.tipo.call,
                        put: p.selected.tipo.put
                    },
                    btnModel: {
                        europe: p.selected.mod.europe,
                        america: p.selected.mod.america
                    },
                    btnMoneyness: {
                        ITM: p.selected.moneyness.ITM,
                        ATM: p.selected.moneyness.ATM,
                        OTM: p.selected.moneyness.OTM
                    },
                    strike: {min: 0, start: 0, end: 5000, max: 5000},
                    venc: []
                };
            });
            // console.log(listFilters);
            setUserFilter(listFilters);
            setUserLabels(listName);
        }
    }, [preference]);

    useEffect(() => {
        if (user && userLabels) {
            // console.log(userLabels);
            const keys = userLabels;
            const prefLabels = [];
            keys.forEach((k) => {
                prefLabels.push({name: k});
            });
            const listUserLabels = [];
            userLabels.forEach((b) => {
                listUserLabels.push({name: b});
            });
            setNumLabels(userLabels.length);
            setLabels(prefLabels);
            setNewUserLabels(listUserLabels);
            setActive(userLabels[0]);
        }
    }, [userLabels]);

    useEffect(() => {
        if (active) {
            getHeaders();
        }
    }, [active]);

    useEffect(() => {
        if (fechamento) {
            getCotacao();
        }
    }, [fechamento]);

    useEffect(() => {
        if (user) {
            if (user.preference) {
                setTableLabel({
                    tipo: {
                        value: 'TIPO',
                        selected: user.preference.grid.viewers.tipo.selected
                    },
                    mod: {
                        value: 'MODALIDADE',
                        selected: user.preference.grid.viewers.mod.selected
                    },
                    money: {
                        value: 'MONEYNESS',
                        selected:
                            user.preference.grid.viewers.moneyness.selected
                    },
                    strike: {
                        value: 'STRIKE',
                        selected: user.preference.grid.viewers.strike.selected
                    },
                    intrinseco: {
                        value: 'VI',
                        selected: user.preference.grid.viewers.vi.selected
                    },
                    extriseco: {
                        value: 'VE',
                        selected: user.preference.grid.viewers.ve.selected
                    },
                    ultimo: {
                        value: 'ÚLTIMO',
                        selected: user.preference.grid.viewers.ultimo.selected
                    },
                    negocio: {
                        value: 'NEGÓCIOS',
                        selected: user.preference.grid.viewers.negocios.selected
                    },
                    vol: {
                        value: 'VOLUME',
                        selected: user.preference.grid.viewers.volume.selected
                    },
                    data: {
                        value: 'DATA',
                        selected: user.preference.grid.viewers.data.selected
                    },
                    implicita: {
                        value: 'VOL. IMPLÍCITA',
                        selected: user.preference.grid.viewers.vol_imp.selected
                    },
                    ticker: {
                        value: 'OPÇÃO',
                        selected: true
                    },
                    pteorico: {
                        value: 'P.TEÓRICO',
                        selected:
                            user.preference.grid.viewers.p_teorico.selected
                    },
                    delta: {
                        value: 'DELTA',
                        selected: user.preference.grid.viewers.delta.selected
                    },
                    gamma: {
                        value: 'GAMMA',
                        selected: user.preference.grid.viewers.gamma.selected
                    },
                    theta: {
                        value: 'THETA',
                        selected: user.preference.grid.viewers.theta.selected
                    },
                    vega: {
                        value: 'VEGA',
                        selected: user.preference.grid.viewers.vega.selected
                    }
                });
            }
        }
    }, [tablePreference]);

    useEffect(() => {
        if (labels.length > numLabels) {
            const found = labels[labels.length - 1];
            labels.forEach((l) => {
                document.getElementById(l.name).classList.remove('active');
            });
            document.getElementById(found.name).classList.add('active');
            setNumLabels(numLabels + 1);
        } else if (labels.length < numLabels) {
            const found = labels[0];
            labels.forEach((l) => {
                document.getElementById(l.name).classList.remove('active');
            });
            document.getElementById(found.name).classList.add('active');
            setNumLabels(numLabels - 1);
        }
    }, [labels]);

    useEffect(() => {
        // console.log(data);
        if (data[active]?.values.length > 0) {
            const result = getFilteredData(
                data[active].values,
                data[active].filters
            );
            // console.log(result);
            setDataTable(result);
        }
    }, [data]);

    useEffect(() => {
        if (expand === false) {
            setSliderLabel('off');
        } else {
            setSliderLabel('on');
        }
    }, [expand]);

    useEffect(() => {
        if (detectUnload && user && user.preference.show_alert_grid === 0) {
            if (JSON.stringify(newUserLabels) !== JSON.stringify(labels))
                setOpen(true);
            else setOpen(false);
        }
    }, [labels]);

    return (
        <>
            <Loading open={loading} msg="Buscando dados ativo..." />
            <Loading open={loadingSave} msg="Salvando preferencias..." />
            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <Accordion defaultActiveKey="1">
                                <Card>
                                    <Card.Header>
                                        <div className="container-fluid">
                                            <div
                                                className="row"
                                                style={{
                                                    alignItems: 'space-between',
                                                    justifyContent:
                                                        'space-between'
                                                }}
                                            >
                                                <ContextAwareToggle eventKey="0">
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            direction: 'row',
                                                            alignItems:
                                                                'center',
                                                            color: '#d36233'
                                                        }}
                                                    >
                                                        <ion-icon
                                                            size="large"
                                                            name="options-outline"
                                                            style={{
                                                                color: '#d36233',
                                                                marginRight: 15
                                                            }}
                                                            onClick={() =>
                                                                collapse()
                                                            }
                                                        />
                                                        FILTROS
                                                    </div>
                                                </ContextAwareToggle>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        direction: 'row',
                                                        justifyContent: 'end'
                                                    }}
                                                >
                                                    <ContextAwareToggle eventKey="0">
                                                        {expand ? (
                                                            <span
                                                                title=""
                                                                onClick={
                                                                    collapse
                                                                }
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    height: '100%'
                                                                }}
                                                            >
                                                                <ion-icon
                                                                    id="collapse"
                                                                    size="large"
                                                                    name="chevron-down-outline"
                                                                    style={{
                                                                        color: '#d36233',
                                                                        pointerEvents:
                                                                            'none'
                                                                    }}
                                                                />
                                                            </span>
                                                        ) : (
                                                            <span
                                                                title=""
                                                                onClick={
                                                                    collapse
                                                                }
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    height: '100%'
                                                                }}
                                                            >
                                                                <ion-icon
                                                                    id="collapse"
                                                                    size="large"
                                                                    name="chevron-back-outline"
                                                                    style={{
                                                                        color: '#d36233',
                                                                        pointerEvents:
                                                                            'none'
                                                                    }}
                                                                />
                                                            </span>
                                                        )}
                                                    </ContextAwareToggle>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <Filters
                                                data={data}
                                                setData={setData}
                                                active={active}
                                                sliderLabel={sliderLabel}
                                            />
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>

                        <div className="col-lg-12">
                            <div className="card">
                                <ul
                                    className="nav nav-tabs"
                                    id="myTab"
                                    role="tablist"
                                    style={{
                                        display: 'flex',
                                        marginLeft: 7,
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <div className="row">
                                        {labels.length > 0 &&
                                            labels.map((dt, i) =>
                                                i === 0 ? (
                                                    <li
                                                        key={i}
                                                        className="nav-item"
                                                    >
                                                        {active === dt.name && (
                                                            <span
                                                                style={{
                                                                    marginRight:
                                                                        -25,
                                                                    color: '#d36233',
                                                                    cursor: 'pointer',
                                                                    paddingLeft:
                                                                        '4rem'
                                                                }}
                                                                onClick={() =>
                                                                    deleteTab(
                                                                        i,
                                                                        dt.name
                                                                    )
                                                                }
                                                            >
                                                                x
                                                            </span>
                                                        )}
                                                        <span
                                                            className="nav-link active"
                                                            id={dt.name}
                                                            data-toggle="tab"
                                                            onClick={alterTab}
                                                            role="tab"
                                                            aria-controls="home"
                                                            aria-selected="false"
                                                            style={{
                                                                cursor: 'pointer',
                                                                marginTop:
                                                                    active ===
                                                                    dt.name
                                                                        ? -24
                                                                        : 0
                                                            }}
                                                        >
                                                            {dt.name}
                                                        </span>
                                                    </li>
                                                ) : (
                                                    <li
                                                        key={i}
                                                        className="nav-item"
                                                    >
                                                        {active === dt.name && (
                                                            <span
                                                                style={{
                                                                    marginRight:
                                                                        -25,
                                                                    color: '#d36233',
                                                                    cursor: 'pointer',
                                                                    paddingLeft:
                                                                        '4rem'
                                                                }}
                                                                onClick={() =>
                                                                    deleteTab(
                                                                        i,
                                                                        dt.name
                                                                    )
                                                                }
                                                            >
                                                                x
                                                            </span>
                                                        )}
                                                        <span
                                                            className="nav-link"
                                                            id={dt.name}
                                                            data-toggle="tab"
                                                            onClick={alterTab}
                                                            role="tab"
                                                            aria-controls="home"
                                                            aria-selected="false"
                                                            style={{
                                                                cursor: 'pointer',
                                                                marginTop:
                                                                    active ===
                                                                    dt.name
                                                                        ? -24
                                                                        : 0
                                                            }}
                                                        >
                                                            {dt.name}
                                                        </span>
                                                    </li>
                                                )
                                            )}

                                        <span
                                            // className="nav-link"
                                            id="plus"
                                            // data-toggle="tab"
                                            onClick={() => setOpenModal(true)}
                                            role="tab"
                                            style={{
                                                height:
                                                    labels.length === 0 && 60,
                                                cursor: 'pointer',
                                                fontWeight: 'bold',
                                                fontSize: 20,
                                                color: '#d36233',
                                                paddingTop: '0.4rem',
                                                paddingLeft: '1rem'
                                                // padding: '0.6rem 1rem'
                                            }}
                                        >
                                            +
                                        </span>
                                    </div>
                                    <div>
                                        <span
                                            title="Salvar"
                                            onClick={savePreference}
                                            role="tab"
                                            style={{
                                                height:
                                                    labels.length === 0 && 60,
                                                marginRight: 10,
                                                cursor: 'pointer',
                                                fontSize: 20,
                                                color: '#d36233',
                                                paddingTop: '0.6rem',
                                                paddingLeft: '1rem'
                                            }}
                                        >
                                            <ion-icon
                                                name="save-outline"
                                                style={{pointerEvents: 'none'}}
                                            />
                                        </span>
                                        <span
                                            onClick={() =>
                                                setOpenSettings(true)
                                            }
                                            role="tab"
                                            style={{
                                                cursor: 'pointer',
                                                fontWeight: 'bold',
                                                fontSize: 20,
                                                color: '#d36233',
                                                padding: '0.2rem 0.1rem',
                                                marginRight: 'auto'
                                            }}
                                        >
                                            <span
                                                title="Configurações"
                                                style={{cursor: 'pointer'}}
                                            >
                                                <ion-icon
                                                    name="settings"
                                                    style={{
                                                        color: '#d36233',
                                                        marginRight: 15,
                                                        cursor: 'pointer',
                                                        pointerEvents: 'none'
                                                    }}
                                                />
                                            </span>
                                        </span>
                                    </div>
                                </ul>
                                <div className="tab-content" id="myTabContent">
                                    <div
                                        className="tab-pane fade show active"
                                        role="tabpanel"
                                        aria-labelledby="profile-tab"
                                    >
                                        <div className="card-body">
                                            <div
                                                className="d-flex"
                                                style={{
                                                    direction: 'row',
                                                    alignItems: 'center',
                                                    marginBottom: 30
                                                }}
                                            >
                                                <div
                                                    className="card-body p-0"
                                                    style={{overflow: 'scroll'}}
                                                >
                                                    <div className="container-fluid">
                                                        <Table
                                                            active={active}
                                                            dt1={dataTable}
                                                            tableLabel={
                                                                tableLabel
                                                            }
                                                            notify={notify}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="d-flex justify-content-between align-items-center border-top"
                                    style={{marginTop: -15}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MobileDetecter />

            <Modal
                size="lg"
                show={openModal}
                backdrop="static"
                style={{zIndex: 9999}}
            >
                <form className="form-horizontal">
                    <Modal.Body>
                        <div className="container-fluid">
                            <div
                                className="row"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    color: '#d36233'
                                }}
                            >
                                <p>PERSONALIZAÇÃO GRADE DE OPÇÕES</p>

                                <p
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => setOpenModal(false)}
                                >
                                    X
                                </p>
                            </div>
                            <div
                                className="card-body"
                                style={{marginTop: -30, fontFamily: 'Roboto'}}
                            >
                                <div
                                    className="form-group row"
                                    id="dadosBasicos"
                                >
                                    <div className="col-sm-12">
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            placeholder="escreva aqui o ticker da ação"
                                            onChange={(e) => selectAtiv(e)}
                                            isSearchable
                                            options={ativos}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                className="row"
                                style={{
                                    marginTop: -10,
                                    display: 'flex',
                                    justifyContent: 'start',
                                    color: '#d36233'
                                }}
                            >
                                <p>MAIS LÍQUIDAS NO MOMENTO</p>
                            </div>
                            <div className="card-body">
                                <div
                                    className="form-group row"
                                    id="dadosBasicos"
                                >
                                    <Grid
                                        container
                                        spacing={1}
                                        style={{
                                            justifyContent: 'space-around',
                                            alignItems: 'center',
                                            marginBottom: -50,
                                            marginTop: -55
                                        }}
                                    >
                                        <Grid>
                                            <img
                                                style={{
                                                    width: 100,
                                                    cursor: labels.find(
                                                        (el) =>
                                                            el.name === 'VALE3'
                                                    )
                                                        ? 'not-allowed'
                                                        : 'pointer'
                                                }}
                                                src="./img/vale-sa.svg"
                                                alt="vale"
                                                onClick={() =>
                                                    selectAtiv('VALE3')
                                                }
                                            />
                                        </Grid>
                                        <Grid>
                                            <img
                                                style={{
                                                    width: 100,
                                                    cursor: labels.find(
                                                        (el) =>
                                                            el.name === 'PETR4'
                                                    )
                                                        ? 'not-allowed'
                                                        : 'pointer'
                                                }}
                                                src="./img/petrobras-logo.svg"
                                                alt="Petrobras"
                                                onClick={() =>
                                                    selectAtiv('PETR4')
                                                }
                                            />
                                        </Grid>
                                        <Grid>
                                            <img
                                                style={{
                                                    width: 100,
                                                    cursor: labels.find(
                                                        (el) =>
                                                            el.name === 'BBAS3'
                                                    )
                                                        ? 'not-allowed'
                                                        : 'pointer'
                                                }}
                                                src="./img/banco-do-brasil.svg"
                                                alt="Banco-Brasil"
                                                onClick={() =>
                                                    selectAtiv('BBAS3')
                                                }
                                            />
                                        </Grid>
                                        <Grid>
                                            <img
                                                style={{
                                                    width: 100,
                                                    cursor: labels.find(
                                                        (el) =>
                                                            el.name === 'MGLU3'
                                                    )
                                                        ? 'not-allowed'
                                                        : 'pointer'
                                                }}
                                                src="./img/magalu.svg"
                                                alt="Magalu"
                                                onClick={() =>
                                                    selectAtiv('MGLU3')
                                                }
                                            />
                                        </Grid>
                                        <Grid>
                                            <img
                                                style={{
                                                    width: 100,
                                                    cursor: labels.find(
                                                        (el) =>
                                                            el.name === 'B3SA3'
                                                    )
                                                        ? 'not-allowed'
                                                        : 'pointer'
                                                }}
                                                src="./img/bovespa1.svg"
                                                alt="Bovespa"
                                                onClick={() =>
                                                    selectAtiv('B3SA3')
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </form>
            </Modal>
            <AlertSave
                when={open}
                title="DESEJA SAIR SEM SALVAR?"
                pageAlert="grid"
            />
            <Modal
                size="xl"
                show={openSettings}
                backdrop="static"
                style={{zIndex: 9999}}
            >
                <Modal.Body>
                    <div
                        className="row"
                        style={{
                            display: 'flex',
                            marginTop: -5,
                            color: '#d36233'
                        }}
                    >
                        <div style={{marginLeft: '14.5%'}}>
                            <p>PERSONALIZAÇÃO GRADE DE OPÇÕES</p>
                        </div>

                        <div style={{marginLeft: '61%'}}>
                            <p
                                style={{
                                    cursor: 'pointer'
                                }}
                                onClick={() => setOpenSettings(false)}
                            >
                                X
                            </p>
                        </div>
                    </div>
                    <Settings
                        tableLabel={tableLabel}
                        setTableLabel={setTableLabel}
                    />
                </Modal.Body>
            </Modal>
            <Toaster />
            <Calculadora />
        </>
    );
};

export default Dashboard;
