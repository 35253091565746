import React from 'react';
import {Card} from 'react-bootstrap';
import {Button, Modal} from '@material-ui/core';
import iconAdd from 'src/img/addperfil.avif';
import iconCancel from 'src/img/cancelperfil.avif';

import ConfigModal from './modals/ConfigModal';
import PerfisCancelados from './modals/PerfisCancelados';

import './admin.css';

export default function AdminConfig({
    size,
    classes,
    perfiladm,
    perfilDesativado,
    setPerfilDesativado,
    setPerfiladm
    /* setCreate,
    setChange,
    setCancelados */
}) {
    const [cancelados, setCancelados] = React.useState(false);
    const [change, setChange] = React.useState(false);
    const [create, setCreate] = React.useState(false);
    const [profile, setProfile] = React.useState(false);

    return (
        <>
            {' '}
            <div
                style={{
                    display: 'flex',
                    justifyContent: size > 1600 ? 'space-around' : '',
                    width: size > 1600 ? '98%' : ''
                }}
            >
                <Card className={size > 1600 ? 'cardconfig1920' : 'cardconfig'}>
                    <Card.Header
                        style={{
                            border: 'none',
                            boxShadow: 'none',
                            color: '#000'
                        }}
                    >
                        <div
                            style={{
                                margin: '10px 0px 10px 20px',
                                fontSize: 20,
                                fontWeight: '700'
                            }}
                        >
                            PERFIS
                        </div>
                        <div
                            style={{
                                color: '#d36233',
                                display: 'flex',
                                alignItems: 'center',
                                borderBottom: '1px solid #e37039',
                                width: '90%',
                                marginLeft: '20px',
                                fontSize: '19px',
                                fontWeight: 700,
                                height: '40px'
                            }}
                        >
                            <div
                                className={
                                    window.innerWidth < 1600
                                        ? 'idconfighead1360'
                                        : 'idconfighead'
                                }
                            >
                                <span
                                    style={{
                                        display: 'block',
                                        textAlign: 'center',
                                        fontSize: '19px',
                                        backgroundColor: '#cac6c2',
                                        width: '60px',
                                        borderRadius: '15px'
                                    }}
                                >
                                    ID
                                </span>
                            </div>
                            <div
                                className={
                                    window.innerWidth < 1600
                                        ? 'nomeconfigtext1360'
                                        : 'nomeconfigtext'
                                }
                            >
                                NOME
                            </div>
                            <div
                                className={
                                    window.innerWidth < 1600
                                        ? 'perfiltext1360'
                                        : 'perfiltext'
                                }
                            >
                                PERFIL
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div>
                            {perfiladm.map((value) => (
                                <div
                                    // key={i}
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <ion-icon
                                        style={{
                                            color: '#d36233',
                                            cursor: 'pointer'
                                        }}
                                        name="settings-sharp"
                                        onClick={() => {
                                            setChange(true);
                                            setProfile(value);
                                        }}
                                    />
                                    <div
                                        style={{
                                            display: 'flex',
                                            borderBottom: '1.5px solid #cac6c2'
                                        }}
                                    >
                                        <div
                                            style={{marginLeft: '10px'}}
                                            className={
                                                window.innerWidth < 1600
                                                    ? 'idconfigtext1360'
                                                    : 'idconfigtext'
                                            }
                                        >
                                            {value.id}
                                        </div>
                                        <div
                                            className={
                                                window.innerWidth < 1600
                                                    ? 'nomeconfigtext1360'
                                                    : 'nomeconfigtext'
                                            }
                                        >
                                            {value.nome}
                                        </div>
                                        <div
                                            className={
                                                window.innerWidth < 1600
                                                    ? 'perfiltext1360'
                                                    : 'perfiltext'
                                            }
                                        >
                                            {value.perfil}
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div style={{margin: '15px'}}>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <img
                                        src={iconAdd}
                                        alt=""
                                        style={{width: '50px'}}
                                    />
                                    <Button
                                        className={
                                            size > 1600
                                                ? classes.configButton1920
                                                : classes.configButton
                                        }
                                        onClick={() => setCreate(true)}
                                    >
                                        ADD PERFIL
                                    </Button>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <img
                                        src={iconCancel}
                                        alt=""
                                        style={{width: '50px'}}
                                    />
                                    <Button
                                        className={
                                            size > 1600
                                                ? classes.configButton1920
                                                : classes.configButton
                                        }
                                        onClick={() => setCancelados(true)}
                                    >
                                        PERFIS CANCELADOS
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
                <Card className={size > 1600 ? 'cardplanos1920' : 'cardplanos'}>
                    <Card.Header
                        style={{
                            border: 'none',
                            boxShadow: 'none',
                            color: '#000'
                        }}
                    >
                        <div
                            style={{
                                margin: '10px 0px 10px 20px',
                                fontSize: 20,
                                fontWeight: '700'
                            }}
                        >
                            PLANOS
                        </div>
                        <div
                            style={{
                                color: '#d36233',
                                display: 'flex',
                                alignItems: 'center',
                                borderBottom: '1px solid #e37039',
                                width: '95%',
                                marginLeft: '20px',
                                fontSize: '19px',
                                fontWeight: 700,
                                height: '40px'
                            }}
                        >
                            <div
                                className={
                                    window.innerWidth < 1600
                                        ? 'planotext1360'
                                        : 'planotext'
                                }
                            >
                                PLANOS
                            </div>
                            <div
                                className={
                                    window.innerWidth < 1600
                                        ? 'planotext1360'
                                        : 'planotext'
                                }
                            >
                                DURAÇÃO
                            </div>
                            <div
                                className={
                                    window.innerWidth < 1600
                                        ? 'planotext1360'
                                        : 'planotext'
                                }
                            >
                                VALOR
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <ion-icon
                                style={{color: '#d36233'}}
                                name="settings-sharp"
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    borderBottom: '1.5px solid #cac6c2'
                                }}
                            >
                                <div
                                    style={{marginLeft: '10px'}}
                                    className={
                                        window.innerWidth < 1600
                                            ? 'planotext1360'
                                            : 'planotext'
                                    }
                                >
                                    SARDINHA - anual
                                </div>
                                <div
                                    className={
                                        window.innerWidth < 1600
                                            ? 'planotext1360'
                                            : 'planotext'
                                    }
                                >
                                    1 ano
                                </div>
                                <div
                                    className={
                                        window.innerWidth < 1600
                                            ? 'planotext1360'
                                            : 'planotext'
                                    }
                                >
                                    R$ 69,90
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </div>
            <Modal style={{zIndex: 9999}} open={create || change}>
                <ConfigModal
                    size={window.innerWidth}
                    classes={classes}
                    setCreate={setCreate}
                    setChange={setChange}
                    setPerfiladm={setPerfiladm}
                    perfiladm={perfiladm}
                    setPerfilDesativado={setPerfilDesativado}
                    profile={profile}
                    create={create}
                    change={change}
                />
            </Modal>
            <Modal style={{zIndex: 9999}} open={cancelados}>
                <PerfisCancelados
                    size={window.innerWidth}
                    perfilDesativado={perfilDesativado}
                    setPerfiladm={setPerfiladm}
                    setPerfilDesativado={setPerfilDesativado}
                    classes={classes}
                    setOpen={setCancelados}
                />
            </Modal>
        </>
    );
}
