import React from 'react';
import {Grid} from '@material-ui/core';
import {makeStyles, createStyles} from '@material-ui/core/styles';

export default function NextStrategies({card, notify, tipo}) {
    const useStyles = makeStyles(() =>
        createStyles({
            myGridVC: {
                minWidth: 15,
                maxWidth: 15,
                maxHeight: 15,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                textAlign: 'center',
                // cursor: 'pointer',
                border: '2px solid #c5c5c5'
            },
            myGridVCSelected: {
                minWidth: 15,
                maxWidth: 15,
                maxHeight: 15,
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                textAlign: 'center',
                // cursor: 'pointer',
                backgroundColor: '#e37039',
                color: '#fff'
            }
        })
    );
    const classes = useStyles();

    if (card) {
        return card.map((t, i) => (
            <tr
                key={t.id}
                style={{
                    height: '30px',
                    borderBottom: ' 0.5px solid lightgrey',
                    borderTop: '0.5px solid lightgrey',
                    // borderRight: '0.5px solid lightgrey',
                    alignItems: 'center',
                    fontFamily: 'Roboto'
                    // marginRight: -10
                }}
            >
                <td
                    key={t.id}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-evenly',
                        marginTop: '4px'
                    }}
                >
                    {tipo[i] === 'V' ? (
                        <Grid
                            className={classes.myGridVC}
                            style={{
                                fontSize: 12
                                // fontWeight: 700
                                // paddingTop: '2px'
                            }}
                        >
                            V
                        </Grid>
                    ) : (
                        <Grid
                            className={classes.myGridVCSelected}
                            style={{
                                fontSize: 12
                                // fontWeight: 700
                                // paddingTop: '2px'
                            }}
                        >
                            C
                        </Grid>
                    )}
                    <span
                        style={{
                            // marginRight: '10px ',
                            width: '96px',
                            fontSize: 14.5,
                            textAlign: 'center'
                            // fontWeight: 'bold'
                        }}
                    >
                        {t.ticker}
                        <ion-icon
                            onClick={() => notify(t.ticker)}
                            style={{
                                fontSize: 12,
                                color: '#d36233',
                                cursor: 'pointer',
                                marginLeft: 5
                            }}
                            name="copy-outline"
                        />
                    </span>
                    <span
                        style={{
                            // marginRight: '10px',
                            width: '50px',
                            fontSize: 14.5,
                            textAlign: 'center'
                            // fontWeight: 'bold'
                        }}
                    >
                        {t.strike ? t.strike.replace('.', ',') : null}
                    </span>
                </td>
            </tr>
        ));
    }
}
